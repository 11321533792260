<script setup lang="ts">
import { AppGateDeepLink } from '@configs'

defineOptions({
  inheritAttrs: false,
})

const { hide: modalHide } = useModal()
const { gtEvent } = useGoogleTag()
const { t } = useI18n()
const show = ref(false)

// 팝업 열릴때 GTag Event
watch(
  () => show.value,
  (cur) => {
    if (cur) {
      // gtEvent('view_app_link', {
      //   event_category: 'app_link',
      //   event_label: 'View app link',
      // })
      // gtEvent('viewEvent', {
      //   eventCategory: '콘텐츠 노출',
      //   eventAction: '모달-앱으로 보기',
      //   eventLabel: t('bottomSheet.title'),
      //   eventSlot: '모달',
      //   eventI18nAddr: useKoreanTranslation('bottomSheet.title'),
      //   eventComponent: 'NovaModalMobAppGate',
      // })
    }
  }
)

// 팝업 닫기
const handleOnClose = async () => {
  await modalHide(modalsName.MODAL_MOB_APP_GATE)
}

const handleOnGoAppLink = () => {
  // gtEvent('go_app_link', {
  //   event_category: 'app_link',
  //   event_label: 'Go app link',
  // })

  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '모달-앱으로 보기',
    eventLabel: t('bottomSheet.title'),
    eventSlot: '모달',
    eventI18nAddr: useKoreanTranslation('bottomSheet.title'),
    eventComponent: 'NovaModalMobAppGate',
  })
  window.open(AppGateDeepLink, '_blank')
}
</script>

<template>
  <VueFinalModal
    v-model="show"
    :name="modalsName.MODAL_MOB_APP_GATE"
    :content-class="'modal-mob-app-gate'"
    classes="modal-container"
    @click-outside="handleOnClose"
  >
    <ClientOnly>
      <div v-if="show" class="bottom-sheet">
        <div class="inner">
          <p v-dompurify-html="t('bottomSheet.title')" class="title-text"></p>
          <button type="button" class="btn-app-gate" @click="handleOnGoAppLink">
            <span class="app-icon">{{ t('bottomSheet.button') }}</span>
          </button>
        </div>
      </div>
    </ClientOnly>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
:deep(.vfm__overlay) {
  background-color: hex-to-rgba($color-black, 0);
}

.bottom-sheet {
  @include mobile {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 20px;
    border-radius: 30px 30px 0 0;
    box-shadow: 0 0px 4px rgba(0, 0, 0, 0.25);
    width: 100%;
    background-color: #fff;
    z-index: 200;
  }
  .title-text {
    display: block;
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    line-height: 1.2em;
    padding-bottom: 15px;
  }
  .btn-app-gate {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 46px;
    border-radius: 10px;
    background-color: $color-primary-blue;
    transition: opacity 0.2s ease-in-out;
    @include transition(opacity 0.2s ease-in-out, bottom 0.2s ease-in-out);
  }
  .app-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    &:before {
      display: inline-block;
      margin-right: 5px;
      content: '';
      width: 32px;
      height: 32px;
      background-size: 24px auto;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('assets/images/icon-nova-app.svg');
    }
  }
}
</style>
