import type { LocationQueryRaw } from 'vue-router'
import { bridgeToApp } from '@bridge'
import { locales } from '@configs'

type LocaleCode = (typeof locales)[number]['code']

interface UseNavigationsParams {
  external?: boolean
  url?: string
  query?: LocationQueryRaw
  type?: 'push' | 'replace' | 'back'
  localeCode?: LocaleCode
}

export const useNavigations = async ({
  external = false,
  url = '',
  query,
  type = 'push',
  localeCode,
}: UseNavigationsParams) => {
  const config = useRuntimeConfig()
  let _url = url.replace(/^(\/)/, '')

  if (external) {
    const params = query
      ? `?${new URLSearchParams(query as any).toString()}`
      : ''
    try {
      await bridgeToApp.openWeb({
        url: `${_url}${params}`,
        type: 'external',
      })
    } catch {
      window.open(`${_url}${params}`, '_blank')
    }
    return
  } else {
    try {
      const baseUrl = config.public.APP_URL
      const originUrl = new URL(url, baseUrl)

      if (originUrl.origin.includes(config.public.APP_URL)) {
        _url = originUrl.pathname.replace(/^(\/)/, '')
      }
    } catch (e) {
      console.log('Invalid url', url)
    }
  }

  const { $i18n, $router } = useNuxtApp()
  const clearedLocalePath = useClearRoutePathLocale(_url)
  const localePath =
    (localeCode || $i18n.locale.value) === 'ko'
      ? `/${clearedLocalePath}`
      : `/${localeCode || $i18n.locale.value}/${clearedLocalePath}`
  switch (type) {
    case 'push':
    case 'replace':
      await $router[type]({
        path: localePath,
        query,
      })
      break
    case 'back':
      $router.back()
      break
    default:
      break
  }
}
