import { subscribe } from './subscribe'
import { commonError } from './commonError'
import type { UserContentType } from '@components/NovaBoxUserTopHeader/NovaBoxUserTopHeader.types'
import type {
  DonationsOrderBy,
  MyCommentOrderBy,
  UserContentsOrderBy,
  MyRecommendPostType,
  RewardsOrderBy,
  ScrapPostsOrderBy,
  SetRecommendsOrderBy,
  ActivityFilter,
  CreatorContentsItemType,
  MyCollectionsFilter,
} from '@store'

export const mypage = {
  profile: {
    title: 'Profile Information',
    placeholder: {
      nickname: 'Set your nickname.',
      content: 'Profile content',
    },
    button: {
      cancel: 'Cancel',
      save: 'Save',
    },
    dialog: {
      title: 'Notifications',
      initImage:
        'Deleting the image will change it to your default avatar. Are you sure you want to delete it?',
      doubleNickname:
        'This username is already taken. Please choose a different username.',
      noImage: 'The user profile does not exist.',
    },
    toastMessage: {
      unavailableNickname:
        'Invalid characters in the text. Please use another nickname.',
      imgError2005: 'This extension cannot be registered.',
      imgError: 'The image upload failed. Please try again later.',
    },
  },
  tabMenu: {
    contents: 'Post',
    reward: 'Reward',
    donation: 'Donation',
    activity: 'My activity',
    collections: 'My Collection',
  } as { [Property in UserContentType]: string },
  sort: {
    reward: {
      newest: 'Newest',
      oldest: 'Oldest',
      rewardQty: 'Biggest',
    } as { [Property in RewardsOrderBy]: string },
    donation: {
      newest: 'Newest',
      oldest: 'Oldest',
      donationQty: 'Biggest',
    } as { [Property in DonationsOrderBy]: string },
    post: {
      newest: 'Newest',
      oldest: 'Oldest',
      hot: 'Hottest',
    } as { [Property in UserContentsOrderBy]: string },
    recommend: {
      newest: 'Newest',
      oldest: 'Oldest',
    } as { [Property in SetRecommendsOrderBy]: string },
    comment: {
      newest: 'Newest',
      oldest: 'Oldest',
      // hot: 'Hottest',
    } as { [Property in MyCommentOrderBy]: string },
    scrap: {
      newest: 'Newest',
      oldest: 'Oldest',
      // hot: 'Hottest',
    } as { [Property in ScrapPostsOrderBy]: string },
  },
  postTypes: {
    content: 'Content',
    free: 'Free',
    live: 'Live',
  } as { [Property in CreatorContentsItemType]: string },
  filter: {
    donation: {
      received: 'Received',
      sent: 'Sent',
    },
    activity: {
      recommend: 'Like',
      comment: 'Comment',
      scrap: 'Scrap',
    } as { [Property in ActivityFilter]: string },
    myCollections: {
      recentlyDesc: 'Recently Created',
      rarityDesc: 'Sort by Rarity',
    } as { [Property in MyCollectionsFilter]: string },
  },
  panel: {
    reward: {
      title: 'Total rewards',
      empty: "You haven't received any rewards yet.",
    },
    donationQty: {
      received: 'Total Received Donation',
      sent: 'Total Sent Donation',
    },
    post: {
      public: 'Public',
      private: 'Private',
    },
    comment: 'All comments',
    recommend: {
      get: 'Posts that received Likes',
      set: 'Posts that I Liked',
    } as { [Property in MyRecommendPostType]: string },
    scrap: '(All) saved posts',
  },
  reward: {
    active: 'Activities',
    messages: {
      activity: 'Weekly activity reward has been paid.',
      post: 'Weekly posting reward has been paid.',
      fixed: 'The monthly reward has been paid.',
      incentive: 'Incentive rewards have been paid.',
      monthly: 'The monthly bonus reward has been paid.',
      referral: 'The referral reward has been paid',
      novaPlus: `${subscribe.subscribeGrade.novaPlus} reward has been paid.`,
      nft: 'Weekly ecosystem participation activity reward is paid',
    },
    zeroGuide:
      'When the activity scores are offset against each other to reach zero, the reward will be 0LM.',
  },
  donation: {
    message: {
      received:
        'You have received a donation from <strong>{userNcnm}</strong>.',
      sent: 'You have sent a donation to <strong>{userNcnm}</strong>.',
      empty: {
        received: {
          title: 'There are no records of donations you have received.',
          message:
            'Try receiving donations from fans through various activities.',
        },
        sent: {
          title: 'There are no records of donations you have sent.',
          message: 'Send warm support to the creator.',
        },
      },
      error: {
        received:
          "We can't retrieve your donation records received. Please try again later.",
        sent: "We can't retrieve your donation records sent. Please try again later.",
        donationQty:
          "We can't retrieve donation information. Please try again later.",
      },
    },
  },
  myFollow: {
    tabs: {
      follower: 'Followers',
      block: 'Block List',
    },
    title: {
      follower: 'Followers',
      following: 'Following',
      block: 'Block List',
    },
    follower: {
      noDate: {
        title: 'Looking for followers?',
        message:
          "This account doesn't have any followers yet.<br /> You can create followers by creating posts and engaging with people.",
      },
      error: 'Cannot retrieve follower list. Please try again later.',
    },
    following: {
      state: 'Follows you',
      noDate: {
        title: "You haven't followed anyone yet.",
        button: 'Finding people to follow',
      },
      backFlag: 'Follows me',
      error: 'Cannot retrieve follower list. Please try again later.',
    },

    block: {
      title: 'Block List',
      noDate: {
        title: "You haven't blocked anyone.",
      },
      error: 'Cannot retrieve block list. Please try again later.',
    },
    button: {
      follow: 'Follow',
      following: 'Following',
      delete: 'Remove follower',
      search: 'Finding people to follow',
      cancel: 'Unblock',
    },
    message: {
      follow: {
        error: {
          2022: 'You already followed.',
          2034: 'You cannot follow {userNcnm}.',
          4002: 'This is an unregistered member.',
          4013: 'This is an unregistered member.',
          unknown: commonError.network.message,
        },
      },
      unfollow: {
        error: {
          2023: 'There are no followers.',
          2034: 'You cannot unfollow {userNcnm}.',
          4002: 'This is an unregistered member.',
          4013: 'This is an unregistered member.',
          unknown: commonError.network.message,
        },
      },
      followerRemoveConfirmMessage: 'Remove follower?',
      delSuccess: 'Removed',
      delError: 'Unable to remove a follower, please try again later.',
      followerError: 'Unable to follow, please try again later.',
      unfollowError: 'Unable to unfollow, please try again later.',
    },
  },
  myCollection: {
    message: {
      empty: {
        message: "There's no NFT to show.",
      },
    },
    button: {
      showMore: 'Show More',
      showLess: 'Show Less',
    },
    detail: {
      owner: 'Owner',
      rarity: 'Rarity',
      issueDate: 'Issue Date',
    },
  },
}
