import type { BaseInfinityScrollList, BooleanYn } from '@store/types'

/**
 * 라이브 생성 상태
 */
export enum CreateLiveStatus {
  ABLE_NEW_LIVE = 'ABLE_NEW_LIVE', // 라이브 생성 가능
  ALREADY_LIVE = 'ALREADY_LIVE', // 제한 시간내 라이브가 존재함
}

/**
 * 라이브 생성 권한 체크
 */
export interface CreateLivePermission {
  availableLiveEventId: string // 사용 가능한 라이브 이벤트 아이디
  detailStatus: CreateLiveStatus // 라이브 생성 가능 상태
  isPermission: boolean // 라이브 생성 가능 여부
}

/**
 * 라이브 NFT Collection
 */
export interface LiveNftCollection {
  colctNm: string // 컬렉션 명
  colctSn: number // 컬렉션 일련번호
}

/**
 * 라이브 생성
 */
export interface CreateLivePayload {
  colctSnList?: LiveNftCollection['colctSn'][] // 입장 가능 조건 목록 - 컬렉션 일련번호
  coverImgFile?: FormData // 라이브 커버 이미지 파일
  entryRestrictionsAt: BooleanYn // 입장 제한 여부 Y/N
  publishPostAt: BooleanYn // 라이브 종료 후 포스팅 발행 여부 Y/N
  title: string // 라이브 제목
}

/**
 * 라이브 이벤트
 */
export interface LiveEvent {
  liveEventId: string // 라이브 이벤트 아이디
  sendbirdUserId: string // sendbird 사용자 아이디
}

/**
 * 라이브 입장 가능 여부 확인: token & sndbrdLiveSn 조건
 */
export interface EnterLiveAvailablePayload {
  sndbrdLiveSn: number // 라이브 일련번호
}

/**
 * 라이브 입장 가능 여부 확인: sendbird user id & sendbird live event id 조건
 */
export interface EnterLiveAvailableBySbUserIdPayload {
  liveEventId: string // sendbird live event id
  userId: string // sendbird user id
}

/**
 * 라이브 입장 가능 여부
 */
export interface EnterLiveAvailable {
  availableTimes: number // 이용 가능 시간 (ms)
  colctNmList: LiveNftCollection['colctNm'][] // 입장 가능 조건 목록 - 컬렉션 명
  isPermission: boolean // 입장 가능 여부
  liveEventId: string // sendbird live event id
  sndbrdUserId: string // sendbird user id
}

/**
 * Sendbird userId 생성
 */
export interface CreateSendbirdUserId {
  sendbirdUserId: string
}

/**
 * 온에어 라이브 목록 조회 페이로드
 */
export interface OnAirLivesPayload {
  lastSndbrdLiveSn?: number // 마지막 라이브 일련번호
  pageSize: number // pageSize
}

/**
 * 온에어 라이브
 */
export interface OnAirLive {
  liveSj: string
  sndbrdLiveSn: number
  userNcnm: string
  userProfileUrl: string
  userSn: number
}

/**
 * 오픈 채널 생성 페이로드
 */
export interface CreateOpenChannelPayload {
  channelName: string // 오픈 채널 명
  coverImgFile?: string // 오픈 채널 커버 이미지 파일
}

/**
 * 오픈 채널
 */
export interface OpenChannel {
  channelUrl: string
  sendbirdUserId: string
}

/**
 * Sendbird 스토어 스테이트 타입
 */
export interface SendbirdState {
  onAirLives: BaseInfinityScrollList<OnAirLive[], OnAirLivesPayload> // W3CF 공지사항 리스트
}
