import AttendanceApi from './url'
import * as AttendanceTypes from './type'
import { api } from '@api'

export default {
  /**
   * 출석 체크
   */
  reqDailyCheck() {
    return api.post(AttendanceApi.REQ_DAILY_CHECK)
  },

  /**
   * 출석 체크 상태 조회
   */
  fetchDailyCheck(payload: AttendanceTypes.DailyCheckPayload) {
    return api.get<AttendanceTypes.DailyCheck>(
      AttendanceApi.FETCH_DAILY_CHECK,
      { params: payload }
    )
  },
}
