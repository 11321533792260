<script setup lang="ts">
import type { NovaSettingPopupProps } from './NovaSettingPopup.types'

const { gtEvent } = useGoogleTag()
const { onBack, onClose, title } = withDefaults(
  defineProps<NovaSettingPopupProps>(),
  {
    isBodyNoPadding: false,
    showBtnBack: false,
  }
)

const handleOnBack = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: `${title} 패널 > 설정 > 뒤로가기`,
    eventLabel: 'close-extend',
    eventSlot: '',
    eventI18nAddr: 'close-extend',
    eventComponent: 'Button',
  })
  onBack?.()
}

const handleOnClose = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: `${title} 패널 > 설정 > 닫기`,
    eventLabel: 'close-extend',
    eventSlot: '',
    eventI18nAddr: 'close-extend',
    eventComponent: 'Button',
  })
  onClose?.()
}
</script>

<template>
  <div
    :class="[
      'setting-popup',
      {
        on: isOpen,
        ready: isReady,
        'show-btn-back': showBtnBack,
        'body-no-padding': isBodyNoPadding,
      },
    ]"
  >
    <div class="dim" @click="handleOnClose" />

    <div class="setting-popup-header">
      <div class="header-group">
        <button class="btn-setting-popup-navigation-back" @click="handleOnBack">
          <NovaIcon :icon="{ type: 'outline', name: 'chev-left' }" :size="20" />
        </button>
        <h4 class="setting-popup-title">{{ title }}</h4>
      </div>

      <button class="btn-setting-popup-navigation-close" @click="handleOnClose">
        <NovaIcon
          :icon="{ type: 'outline', name: 'close-extend' }"
          :size="20"
        />
      </button>
    </div>

    <div class="setting-popup-body">
      <slot name="contents" />

      <slot name="error" />

      <NovaLoadingIndicator
        v-if="!isReady"
        class="loading-indicator"
        :bg-bright="'light'"
        :fill="true"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.setting-popup {
  position: absolute;
  top: calc(74px + 16px);
  right: 16px;
  width: 400px;
  z-index: $z-index-user-panel;
  background: #f7f7f7;
  border: 1px solid #eaeaea;
  box-shadow: 1px 2px 9px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
  @include transition(top 0.2s ease-in-out, opacity 0.2s ease-in-out);

  &.on {
    top: 74px;
    opacity: 1;
    pointer-events: auto;

    &.ready {
      .setting-popup-body {
        opacity: 1;
      }
    }
  }

  &.show-btn-back {
    .setting-popup-header {
      .btn-setting-popup-navigation {
        &-back {
          width: 48px;
        }

        &-close {
          width: 0;
        }
      }
    }
  }

  &.body-no-padding {
    .setting-popup-body {
      padding: 0;
    }
  }

  > .dim {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: hex-to-rgba($color-black, 0.65);
    opacity: 0;
    @include transition(opacity 0.2s ease-in-out);
    z-index: -1;
  }

  .setting-popup-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 0 8px;
    width: 100%;
    height: 64px;
    background-color: #fff;
    position: relative;

    .header-group {
      display: flex;
      align-items: center;
      height: 100%;
    }

    .setting-popup-title {
      position: relative;
      display: inline-flex;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      color: #347ae2;
      height: 100%;
      margin-left: 12px;

      &:before {
        display: block;
        content: '';
        width: 100%;
        height: 2px;
        background-color: #347ae2;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }

    .btn-setting-popup-navigation {
      @mixin base-btn {
        display: block;
        width: 48px;
        height: 48px;
        transition: all 0.2s ease-in-out;
        overflow: hidden;
      }

      &-back {
        @include base-btn;
        width: 0;
      }

      &-close {
        @include base-btn;
      }
    }
  }

  .setting-popup-body {
    position: relative;
    display: flex;
    //height: calc(65vh - 70px);
    flex-direction: column;
    gap: 18px;
    padding: 28px 18px 24px 18px;
    overflow: hidden;
    overflow-y: auto;
    opacity: 0.6;
    transition: all 0.2s ease-in-out;
  }

  @include mobile {
    position: fixed;
    top: 0 !important;
    right: 0 !important;
    width: 100%;
    height: 100%;
    transform: translateY(0);
    border-radius: 0;

    .setting-popup-body {
      display: block;
      padding-bottom: 156px;
      height: calc(100% - 64px);
      overflow-y: overlay;
    }
  }
}
</style>
