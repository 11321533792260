<script setup lang="ts">
import { Tippy } from 'vue-tippy'
import type { BooleanYn, LiveNftCollection } from '@store'
import type { Radio } from '@components/NovaRadio/NovaRadio.types'
import { useKoreanTranslation } from '@composables/useKoreanTranslation'

defineOptions({
  inheritAttrs: false,
})

const { gtEvent } = useGoogleTag()
const { hide: modalHide } = useModal()
const { t } = useI18n()
const sendbirdStore = useSendbirdStore()
const { createLive } = useSendbirdGate()
const show = ref(false)
const isLoading = ref(false)
const openLiveLoading = ref(false)
const isError = ref(false)

const title = ref('')
const titleError = ref('')

const entryRestrictionsAt = ref<BooleanYn>('N')
const entryRestrictions = computed<Radio<BooleanYn>[]>(() => [
  {
    label: computed(() =>
      t('sendbird.createLiveEvent.modal.sections.liveEnterLimit.radios.all')
    ).value,
    value: 'N',
    direction: 'row',
  },
  {
    label: computed(() =>
      t('sendbird.createLiveEvent.modal.sections.liveEnterLimit.radios.limit')
    ).value,
    value: 'Y',
    direction: 'row',
  },
])
const nftCollections = ref<LiveNftCollection[]>([])
const nftCollectionsCheckbox = computed(() =>
  nftCollections.value.map((item) => ({
    ...item,
    colctNm: `${item.colctNm} Holder`,
    check: checkChoiceNftCollection(item.colctSn),
  }))
)
const choiceNftCollections = ref<LiveNftCollection[]>([])
const checkAll = computed(
  () => nftCollections.value.length === choiceNftCollections.value.length
)
const choiceNftCollectionsError = ref(false)

watch(
  () => title.value,
  (cur) => {
    if (cur.length && cur.length <= 50) {
      titleError.value = ''
    }
  }
)

watch(
  () => entryRestrictionsAt.value,
  (cur) => {
    if (cur === 'N') {
      choiceNftCollections.value = []
      choiceNftCollectionsError.value = false
    }
  }
)

watch(
  () => choiceNftCollections.value.length,
  (cur) => {
    if (entryRestrictionsAt.value === 'Y' && cur > 0) {
      choiceNftCollectionsError.value = false
    }
  }
)

// 모달 오픈전 사전 작업 수행
const handleOnBeforeOpen = async () => {
  try {
    isLoading.value = true
    isError.value = false
    nftCollections.value = await sendbirdStore.fetchLiveNftCollections()
  } catch (err) {
    isError.value = true
  } finally {
    isLoading.value = false
  }
}

// 팝업 닫기
const handleOnClose = async () => {
  await modalHide(modalsName.MODAL_CREATE_LIVE)
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: 'LIVE 개설 모달 닫기',
    eventLabel: t('sendbird.createLiveEvent.modal.actions.cancel'),
    eventSlot: 'LIVE 개설하기 모달',
    eventI18nAddr: useKoreanTranslation(
      'sendbird.createLiveEvent.modal.actions.cancel'
    ),
    eventComponent: 'Button',
  })
}

// 팝업 닫은후 클리어
const handleOnClosed = () => {
  title.value = ''
  titleError.value = ''
  entryRestrictionsAt.value = 'N'
  nftCollections.value = []
  choiceNftCollections.value = []
  choiceNftCollectionsError.value = false
}

// 입장 제한 설정
const handleOnChangeEntryRestriction = (value: BooleanYn) => {
  entryRestrictionsAt.value = value
}

// 입장 제한 컬렉션 모두 선택
const handleOnCheckAll = () => {
  if (checkAll.value) {
    choiceNftCollections.value = []
  } else {
    choiceNftCollections.value = [...nftCollections.value]
  }
}

// nft collection 선택 여부 판단
const checkChoiceNftCollection = (value: LiveNftCollection['colctSn']) =>
  choiceNftCollections.value.findIndex((item) => item.colctSn === value) !== -1

// nft collection 선택
const handleOnClickNftCollection = ({
  check,
  collection,
}: {
  check: boolean
  collection: LiveNftCollection
}) => {
  if (check) {
    const targetIdx = choiceNftCollections.value.findIndex(
      (item) => item.colctSn === collection.colctSn
    )
    choiceNftCollections.value.splice(targetIdx, 1)
  } else {
    choiceNftCollections.value.push(collection)
  }
}

// 개설하기
const handleOnCreateLive = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: 'LIVE 개설하기 모달 > 개설하기 버튼 클릭',
    eventLabel: t('sendbird.createLiveEvent.modal.actions.create'),
    eventSlot: 'LIVE 개설하기',
    eventI18nAddr: useKoreanTranslation(
      'sendbird.createLiveEvent.modal.actions.create'
    ),
    eventComponent: 'Button',
  })
  if (!title.value.length) {
    titleError.value = t(
      'sendbird.createLiveEvent.modal.sections.liveTitle.errMin'
    )
  }

  if (title.value.length > 50) {
    titleError.value = t(
      'sendbird.createLiveEvent.modal.sections.liveTitle.errMax'
    )
  }

  if (entryRestrictionsAt.value === 'Y' && !choiceNftCollections.value.length) {
    choiceNftCollectionsError.value = true
  }

  if (titleError.value || choiceNftCollectionsError.value) return

  try {
    await createLive({
      title: title.value,
      publishPostAt: 'Y',
      entryRestrictionsAt: entryRestrictionsAt.value,
      colctSnList:
        entryRestrictionsAt.value === 'Y'
          ? choiceNftCollections.value.map((item) => item.colctSn)
          : [],
      onLoading: (loading: boolean) => {
        openLiveLoading.value = loading
      },
    })
    gtEvent('contentsAction', {
      eventCategory: '컨텐츠',
      eventAction: 'LIVE 개설',
      eventLabel: t('sendbird.createLiveEvent.modal.actions.create'),
      eventSlot: 'LIVE 개설',
      eventI18nAddr: useKoreanTranslation(
        'sendbird.createLiveEvent.modal.actions.create'
      ),
      eventComponent: 'Button',
    })
    await handleOnClose()
  } catch {}
}
</script>

<template>
  <VueFinalModal
    v-model="show"
    :name="modalsName.MODAL_CREATE_LIVE"
    :content-class="'modal-content'"
    classes="modal-container"
    @before-open="handleOnBeforeOpen"
    @click-outside="handleOnClose"
    @closed="handleOnClosed"
  >
    <ClientOnly>
      <NovaLoadingIndicator
        v-if="isLoading"
        :fill="true"
        :bg-bright="'dark'"
        class="async-status-message"
      />

      <NovaBoxBase v-if="!isLoading && show" class="modal-box">
        <div class="modal-header">
          <h4 class="title">
            <NovaBadgeLiveAction :available-click="false" />
            {{ t('sendbird.createLiveEvent.modal.title') }}
          </h4>

          <NovaButtonIcon
            :icon="{ type: 'outline', name: 'close-extend' }"
            :theme="'transparent'"
            :size="20"
            class="btn-close"
            @click="handleOnClose"
          />
        </div>

        <NovaLayoutScrollContainer class="modal-body">
          <div v-if="!isError" class="inner">
            <section class="guide">
              <p class="message primary">
                {{ t('sendbird.createLiveEvent.modal.guide.primary') }}
              </p>
              <p class="message secondary">
                {{ t('sendbird.createLiveEvent.modal.guide.secondary') }}
              </p>
            </section>

            <section class="section-wrap">
              <h4 class="section-title">
                {{
                  t('sendbird.createLiveEvent.modal.sections.liveTitle.label')
                }}
              </h4>
              <div class="section-contents">
                <NovaInput
                  v-model="title"
                  :placeholder="
                    t(
                      'sendbird.createLiveEvent.modal.sections.liveTitle.placeholder'
                    )
                  "
                  :theme="'destroy'"
                />
                <p v-if="titleError" class="error-message">
                  <span class="error-icon" />
                  {{ titleError }}
                </p>
              </div>
            </section>

            <section class="section-wrap">
              <h4 class="section-title">
                {{
                  t(
                    'sendbird.createLiveEvent.modal.sections.liveEnterLimit.label'
                  )
                }}

                <Tippy
                  :append-to="'parent'"
                  :interactive="true"
                  :theme="'gray'"
                  :placement="'right'"
                  :z-index="1100"
                >
                  <span class="tooltip-label" />

                  <template #content>
                    <p
                      v-dompurify-html="
                        t(
                          'sendbird.createLiveEvent.modal.sections.liveEnterLimit.guide'
                        )
                      "
                    />
                  </template>
                </Tippy>
              </h4>

              <div class="section-contents border padding">
                <NovaRadioGroup
                  :current="entryRestrictionsAt"
                  :radios="entryRestrictions"
                  :direction="'vertical'"
                  :equal-width="true"
                  @on-change-radio="handleOnChangeEntryRestriction"
                />

                <p v-if="choiceNftCollectionsError" class="error-message">
                  <span class="error-icon" />
                  {{
                    t(
                      'sendbird.createLiveEvent.modal.sections.liveEnterLimit.errorMessage'
                    )
                  }}
                </p>

                <div v-if="entryRestrictionsAt === 'Y'" class="nft-collections">
                  <NovaCheckbox
                    :label="
                      t(
                        'sendbird.createLiveEvent.modal.sections.liveEnterLimit.radios.choiceAll'
                      )
                    "
                    :is-check="checkAll"
                    :theme="'default'"
                    @click="handleOnCheckAll"
                  />

                  <div class="collections">
                    <NovaCheckbox
                      v-for="item in nftCollectionsCheckbox"
                      :key="item.colctSn"
                      :label="item.colctNm"
                      :is-check="item.check"
                      :theme="'default'"
                      @click="
                        () =>
                          handleOnClickNftCollection({
                            check: item.check,
                            collection: item,
                          })
                      "
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>

          <NovaBoxEmptyMessage
            v-else
            :message="
              t('sendbird.createLiveEvent.errors.fetchNftCollectionsFail')
            "
            :fill="true"
            class="async-status-message"
          />
        </NovaLayoutScrollContainer>

        <div v-if="!isError" class="actions">
          <NovaButtonText
            :label="t('sendbird.createLiveEvent.modal.actions.cancel')"
            :theme="'secondary-gray'"
            :width="140"
            :full-width="false"
            :loading="openLiveLoading"
            @click="handleOnClose"
          />
          <NovaButtonText
            :label="t('sendbird.createLiveEvent.modal.actions.create')"
            :theme="'primary-blue'"
            :width="140"
            :full-width="false"
            :loading="openLiveLoading"
            @click="handleOnCreateLive"
          />
        </div>
      </NovaBoxBase>
    </ClientOnly>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
:deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.vfm--overlay) {
  background-color: hex-to-rgba($color-black, 0.65);
}

:deep(.modal-content) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: none;

  .modal-box {
    width: 100%;
    height: auto;
    max-width: 670px;
    max-height: calc(100% - 24px - 24px);
    padding: 0;
    pointer-events: auto;
    overflow: hidden;

    .modal-header {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px 20px 10px;
      color: $color-text-2;
      background-color: $color-bg-3;

      .title {
        display: flex;
        align-items: center;
        gap: 13px;
        font-size: 25px;
        line-height: 36px;
        letter-spacing: -5%;
        font-weight: 700;
      }
    }

    .modal-body {
      padding: 0 20px 20px;

      > .inner {
        display: flex;
        flex-direction: column;

        section.guide {
          .message {
            color: #727272;
            font-weight: 400;

            &.primary {
              font-size: 14px;
              line-height: 23.59px;
              letter-spacing: -0.2px;
            }

            &.secondary {
              font-size: 10px;
              line-height: 17px;
              letter-spacing: -0.2px;
            }
          }
        }

        section.section-wrap {
          display: flex;
          flex-direction: column;
          gap: 8px;
          margin-top: 22px;

          .section-title {
            display: flex;
            align-items: center;
            gap: 14px;
            font-size: 16px;
            font-weight: 700;
            line-height: 29.36px;
            letter-spacing: -0.2px;
            color: $color-black;
          }

          .section-contents {
          }
        }
      }

      .async-status-message {
        min-height: 200px;
      }
    }

    .actions {
      flex-shrink: 0;
      display: flex;
      justify-content: flex-end;
      gap: 11px;
      width: 100%;
      padding: 20px;

      :deep(.btn) {
        display: inline-block;
        width: 140px;
      }
    }

    @include mobile {
      flex-grow: 1;
      border-radius: 0 !important;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
    }
  }
}

.border {
  border: 1px solid #727272;
  border-radius: 4px;
}

.padding {
  padding: 20px;
}

.error-message {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 10px;
  line-height: 12.1px;
  letter-spacing: -0.2px;
  color: $color-bg-custom-12;
  padding-left: 8px;
  margin-top: 4px;

  .error-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
    line-height: 1.2;
    border-radius: 50%;
    background-color: $color-bg-custom-12;
    color: $color-white;
    font-size: 9px;

    &:before {
      content: '!';
    }
  }
}

.tooltip-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border: 1px solid $color-bg-custom-10;
  border-radius: 50%;
  background-color: $color-bg-custom-11;

  &:after {
    content: '?';
    color: $color-bg-custom-10;
    font-size: 10px;
    line-height: 1;
  }
}

.nft-collections {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 12px;
  margin-left: 24px;
  border: 1px solid #727272;
  border-radius: 4px;
  margin-top: 4px;

  .collections {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-left: 24px;
  }
}
</style>
