<template>
  <div :class="['creator-grade-badge', `size-${size}`]">
    <Tippy
      :append-to="'parent'"
      :content="badge.tooltip"
      :interactive="true"
      :theme="'simple'"
      :placement="tooltipPosition"
      :tag="'button'"
      :role="'button'"
    >
      <div class="image-container">
        <img class="creator-badge-icon" :src="badge.url" :alt="badge.tooltip" />
      </div>
    </Tippy>
  </div>
</template>

<script setup lang="ts">
import { Tippy } from 'vue-tippy'
import type {
  CreatorBadge,
  NovaCreatorGradeBadgeProps,
} from './NovaCreatorGradeBadge.types'
import DefaultBadge from 'assets/images/creator/creator-badge-default.svg?url'
import BronzeBadge from 'assets/images/creator/creator-badge-bronze.svg?url'
import SilverBadge from 'assets/images/creator/creator-badge-silver.svg?url'
import GoldBadge from 'assets/images/creator/creator-badge-gold.svg?url'
import BusinessBadge from 'assets/images/creator/creator-badge-business.svg?url'

const props = withDefaults(defineProps<NovaCreatorGradeBadgeProps>(), {
  size: 'md',
  tooltipPosition: 'bottom',
})

const { t } = useI18n()
const badge = computed<CreatorBadge>(() => {
  // 1. 기업 크리에이터이
  if (props.creatorOrganization === 'BIZ')
    return { url: BusinessBadge, tooltip: t('creatorGrade.business') }

  // 2. 개인 크리에이터
  switch (props.creatorGrade) {
    case '0000':
    case null:
      return { url: DefaultBadge, tooltip: t('creatorGrade.default') }
    case '0020':
      return { url: BronzeBadge, tooltip: t('creatorGrade.bronze') }
    case '0040':
      return { url: SilverBadge, tooltip: t('creatorGrade.silver') }
    case '0060':
      return { url: GoldBadge, tooltip: t('creatorGrade.gold') }
    default:
      throw new Error('creator grade is not match')
  }
})
</script>

<style lang="scss" scoped>
.creator-grade-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.size-md {
    .image-container {
      width: 26px;
      height: 26px;
    }
  }

  &.size-lg {
    .image-container {
      width: 48px;
      height: 48px;
    }
  }

  .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .creator-badge-icon {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}
</style>
