<script setup lang="ts">
import type { NovaBoxNotificationSettingStackNotificationSoundProps } from './NovaBoxNotificationSettingStackNotificationSound.types'
import { NotificationPushSoundCode } from '@store'
import { CellGroupTheme } from '@components/NovaHeaderCellGroup/NovaHeaderCellGroup.types'
import type { Radio } from '@components/NovaRadio/NovaRadio.types'

const { pushSounds, onUpdateNotificationPush } =
  defineProps<NovaBoxNotificationSettingStackNotificationSoundProps>()

const isLoading = ref(false)
const notificationSounds = computed<Radio<NotificationPushSoundCode>[]>(() =>
  pushSounds.map((item) => ({
    label: item.ntcnChnnelAlarmTyCodeNm,
    value: item.ntcnChnnelAlarmTyCode,
    direction: 'row',
  }))
)

// 로딩 토글
const toggleIsLoading = (_loading: boolean) => {
  isLoading.value = _loading
}

// 푸시 알림 설정 업데이트
const handleOnUpdateNotificationPush = (value: NotificationPushSoundCode) => {
  onUpdateNotificationPush(value, toggleIsLoading)
}
</script>

<template>
  <NovaHeaderCellGroup :theme="CellGroupTheme.ON_WHITE_BOX">
    <template #cells>
      <NovaRadioGroup
        :radios="notificationSounds"
        :current="currentPushSound"
        :direction="'vertical'"
        :loading="isLoading"
        @on-change-radio="handleOnUpdateNotificationPush"
      />
    </template>
  </NovaHeaderCellGroup>
</template>
