<script setup lang="ts">
import { LottieAnimation } from 'lottie-web-vue'
import type {
  ScrollStatus,
  NovaScrollToTopButtonProps,
} from './NovaScrollToTopButton.types'
import * as scrollTopAnimation from 'assets/data/lottie/scroll-top.json'

const defaultBottomPosition = 16
const props = withDefaults(defineProps<NovaScrollToTopButtonProps>(), {
  bottomPosition: defaultBottomPosition,
})

const lastScrollTop = ref(0)
const scrollStatus = ref<ScrollStatus>({
  scrollTop: 0,
  direction: null,
})
const show = computed(() => scrollStatus.value.scrollTop !== 0)
const scrollEnd = ref(false)
const bottom = computed(
  () => `${scrollEnd.value ? props.bottomPosition : defaultBottomPosition}px`
)

onMounted(async () => {
  await nextTick()
  props.target?.addEventListener('scroll', calcScrollDirectionDetect)
})

onUnmounted(() => {
  props.target?.removeEventListener('scroll', calcScrollDirectionDetect)
})

const handleOnScrollToTop = () => {
  props.target?.scrollTo({ top: 0, behavior: 'smooth' })
}

const calcScrollDirectionDetect = (evt: Event) => {
  const _target = evt.target as Element
  const scrollTop = _target.scrollTop || 0

  if (scrollTop > lastScrollTop.value) {
    scrollStatus.value = {
      scrollTop,
      direction: 'down',
    }
  } else if (scrollTop < lastScrollTop.value) {
    scrollStatus.value = {
      scrollTop,
      direction: 'up',
    }
  }

  if (scrollTop === 0) {
    scrollStatus.value = {
      scrollTop,
      direction: null,
    }
  }

  scrollEnd.value =
    _target.scrollTop + _target.clientHeight + 1 >= _target.scrollHeight

  lastScrollTop.value = scrollTop <= 0 ? 0 : scrollTop
}
</script>

<template>
  <button
    type="button"
    :class="['btn-scroll-to-top', { on: show }]"
    :style="{ bottom }"
    @click="handleOnScrollToTop"
  >
    <LottieAnimation
      v-if="show"
      class="lottie"
      :loop="false"
      :animation-data="scrollTopAnimation"
    />
  </button>
</template>

<style lang="scss" scoped>
$size: 48px;

.btn-scroll-to-top {
  position: fixed;
  right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $size;
  height: $size;
  border-radius: calc($size / 2);
  background-color: $color-secondary-blue-light-70;
  opacity: 0;
  z-index: $z-index-scroll-top-button;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
  @include transition(opacity 0.2s ease-in-out, bottom 0.2s ease-in-out);

  &:before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      -45deg,
      $color-secondary-blue-light-40 0%,
      $color-secondary-blue-light-70 100%
    );
    transform: translate3d(0px, 6px, 0) scale(0.95);
    filter: blur(4px);
    opacity: 0.7;
    transition: opacity 0.3s;
    border-radius: inherit;
  }

  &::after {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
    border-radius: inherit;
  }

  .lottie {
    padding-top: 3px;
    width: $size;
    height: $size;
  }

  @include mobile {
    &.on {
      opacity: 1;
      pointer-events: auto;
    }
  }
}
</style>
