import type { AxiosInstance } from 'axios'

// export const setParamsInterceptor = (instance: AxiosInstance) => {
//   instance.interceptors.request.use((config) => {
//     if (!config.url?.includes('/:')) return config
//
//     const params = config.params || config.data || {}
//
//     Object.entries(params).forEach(([key, value]) => {
//       if (!config.url?.includes(`:${key}`)) return
//
//       config.url = config.url?.replace(
//         `:${key}`,
//         encodeURIComponent(String(value))
//       )
//       delete params[key]
//     })
//
//     return config
//   })
// }

export const setParamsInterceptor = (instance: AxiosInstance) => {
  instance.interceptors.request.use((config) => {
    if (!config.url?.includes('/:')) return config

    const params = config.params || config.data || {}
    const newParams = { ...params }

    Object.entries(params).forEach(([key, value]) => {
      if (config.url?.includes(`:${key}`)) {
        config.url = config.url?.replace(
          `:${key}`,
          encodeURIComponent(String(value))
        )
        delete newParams[key]
      }
    })

    config.params = newParams
    return config
  })
}
