/**
 * 에러 코드 관리
 */
export enum ErrorStatus {
  PAGE_NOT_FOUND = 404,
  REFERRAL_USER_NOT_FOUND = 900,
  BLOCK_ACCESS = 1999,
  UN_AUTHORIZATION = 401,
  ACCESS_TOKEN_EXPIRE = 403,
}
