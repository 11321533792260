import AuctionService from './service'
import { initAuctionState } from './init'

export * from './init'
export * from './type'
export const useAuctionStore = defineStore('auction', () => {
  const auctions = ref(initAuctionState.auctions)

  /**
   * Auction 목록 조회
   */
  const fetchAuctions = async () => {
    const { data } = await AuctionService.fetchAuctions()
    auctions.value = data
  }

  return {
    auctions,
    fetchAuctions,
  }
})
