import type { PostsPayload } from '@store'

export const useGetFeedQuery = (): Partial<PostsPayload> => {
  const postStore = usePostStore()
  const { categories, feedPosts } = storeToRefs(postStore)
  return {
    orderBy: feedPosts.value.payload.orderBy,
    cmtyNttCtgrySn:
      categories.value.find(
        (item) => item.cmtyNttCtgrySn === feedPosts.value.payload.cmtyNttCtgrySn
      )?.cmtyNttCtgrySn ||
      categories.value.find((item) => item.ctgryDfltCeckAt === 'Y')
        ?.cmtyNttCtgrySn ||
      categories.value[0]?.cmtyNttCtgrySn ||
      -1,
  }
}
