import apiPath from '@store/apiPath'

export default {
  /**
   * 통합검색
   * @method GET
   */
  FETCH_SEARCH_ALL: `${apiPath.SEARCH}/v1/all`,

  /**
   * 프리미엄(노바+) 목록 전체 조회
   */
  FETCH_SEARCH_PREMIUM_POSTS: `${apiPath.SEARCH}/v2/all/novaPlusPost`,

  /**
   * 통합검색: 댓글 목록 전체 조회
   * @method GET
   */
  FETCH_SEARCH_COMMENTS: `${apiPath.SEARCH}/v1/all/comment`,

  /**
   * 통합검색: 해시태그 목록 전체 조회
   * @method GET
   */
  FETCH_SEARCH_HASHTAGS: `${apiPath.SEARCH}/v1/all/hashtag`,

  /**
   * 통합검색: 크리에이터 목록 전체 조회
   * @method GET
   */
  FETCH_SEARCH_CREATORS: `${apiPath.SEARCH}/v1/all/creator`,
  /**
   * 통합검색: 사용자 목록 전체 조회
   * @method GET
   */
  FETCH_SEARCH_USERS: `${apiPath.SEARCH}/v1/all/users`,
}
