import type { PostState } from './type'

export const initPostState: PostState = {
  categories: [],
  baseHashtags: [],
  feedPosts: {
    items: [],
    payload: {
      pageNum: 0,
      pageSize: 10,
      nextPageSize: 10,
      orderBy: 'for-you',
    },
    refresh: false,
    last: false,
    loading: false,
  },
  posts: {
    items: [],
    payload: {
      pageNum: 0,
      pageSize: 10,
      nextPageSize: 10,
      orderBy: 'newest',
    },
    refresh: false,
    last: false,
    loading: false,
  },
  postsByHashtag: {
    items: [],
    payload: {
      pageNum: 0,
      pageSize: 10,
      nextPageSize: 10,
      orderBy: 'newest',
      searchHashtagNm: '',
      // cmtyNttCtgrySn: '',
      // searchNttSj: ''
    },
    refresh: false,
    last: false,
    loading: false,
  },
  postsBySearch: {
    items: [],
    payload: {
      pageNum: 0,
      pageSize: 10,
      nextPageSize: 10,
      orderBy: 'newest',
      // cmtyNttCtgrySn: '',
      // searchNttSj: ''
    },
    refresh: false,
    last: false,
    loading: false,
  },
  postsByHashtagRanking: [],
  userPosts: null,
  post: null,
  commentsAndReplies: {
    items: [],
    payload: {
      cmtyNttSn: -1,
      pageNum: 0,
      pageSize: 20,
      nextPageSize: 20,
      orderBy: 'newest',
    },
    refresh: false,
    last: false,
    loading: false,
  },
  previewPost: null,
  tempList: {
    items: [],
    payload: {
      pageNum: 0,
      pageSize: 20,
      nextPageSize: 10,
    },
    refresh: false,
    last: false,
    loading: false,
  },
  hashtagControl: {
    hashtag: { hashtagSn: -1, hashtagNm: '' },
  },
  creators: {
    items: [],
    payload: {
      pageNum: 0,
      pageSize: 30,
      nextPageSize: 20,
    },
    refresh: false,
    last: false,
    loading: false,
  },
  premiumPosts: {
    items: [],
    payload: {
      pageNum: 0,
      pageSize: 20,
      nextPageSize: 20,
      orderBy: 'newest',
      // lastCmtyNttSn: 0,
      // creatorUserSn: 0,
    },
    refresh: false,
    last: false,
    loading: false,
  },
}
