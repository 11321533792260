<script setup lang="ts">
import { NovaModalDialog } from '#components'

const { t, locale, messages } = useI18n()
const userStore = useUserStore()
const { show: modalShow, hideAll: modalHideAll } = useModal()
const state = reactive({
  text: {
    input: '',
    error: false,
  },
  email: {
    input: '',
    error: false,
  },
  isCheck: false,
})
const list = computed(() =>
  (messages.value[locale.value].retire as any).inquire.term.list.map(
    (_item: any, index: number) => {
      return {
        title: t(`retire.inquire.term.list[${index}].title`),
        detail: t(`retire.inquire.term.list[${index}].detail`),
      }
    }
  )
)

watch(
  () => state.email.input,
  (current) => {
    state.email.error = !useCheckEmail(current)
  }
)

onMounted(() => {
  state.email.input = userStore.userCommonInfo!.email
})

const isValidate = computed(
  () =>
    !state.text.error &&
    state.text.input.length !== 0 &&
    !state.email.error &&
    state.isCheck
)

const onValidate = (type: string) => {
  if (type === 'text') {
    state.text.error = state.text.input === ''
  } else {
    state.email.error = state.email.input === ''
  }
}
const showDialogModal = (content: string) => {
  return modalShow({
    component: NovaModalDialog,
    bind: {
      name: modalsName.MODAL_DIALOG,
      btns: [
        {
          label: t('confirm'),
          theme: 'primary-blue-light',
          size: 32,
          onClick: async () => {
            await modalHideAll()
          },
        },
      ],
    },
    slots: {
      content,
    },
  })
}

const onSubmitInquire = async () => {
  const payload = {
    nttCn: state.text.input,
    email: state.email.input,
  }
  await userStore.sendRetireInquired(payload)
  await showDialogModal(t('retire.inquire.modal'))
}
</script>

<template>
  <div class="retire-content">
    <NovaLayoutScrollContainer class="content-area">
      <div class="form-box">
        <span class="title required">{{
          $t('retire.inquire.text.title')
        }}</span>
        <div>
          <NovaTextarea
            v-model="state.text.input"
            :rows="3"
            :placeholder="$t('retire.inquire.text.placeholder')"
            @on-change="onValidate('text')"
          />
          <span v-if="state.text.error" class="validate error">{{
            $t('retire.inquire.text.validate')
          }}</span>
        </div>
      </div>
      <div class="form-box">
        <span class="title required">{{
          $t('retire.inquire.email.title')
        }}</span>
        <div>
          <NovaInput
            v-model="state.email.input"
            type="'email'"
            :placeholder="'email@example.com'"
            :theme="'search'"
            @on-change="() => onValidate('email')"
          />
          <span v-if="!state.email.error" class="validate">{{
            $t('retire.inquire.email.validate.text')
          }}</span>
          <span v-if="state.email.error" class="validate error">{{
            $t('retire.inquire.email.validate.error')
          }}</span>
        </div>
      </div>
      <div class="form-box">
        <span class="title required">{{
          $t('retire.inquire.term.title')
        }}</span>
        <div class="table-box">
          <span class="text">{{
            $t('retire.inquire.term.content.text1')
          }}</span>
          <dl class="table">
            <div v-for="(item, index) in list" :key="index">
              <dt>{{ item.title }}</dt>
              <dd>{{ item.detail }}</dd>
            </div>
          </dl>
          <span
            v-dompurify-html="$t('retire.inquire.term.content.text2')"
            class="text"
          />
          <NovaCheckbox
            :is-check="state.isCheck"
            :label="$t('retire.inquire.term.checkbox')"
            class="check-box"
            @click="state.isCheck = !state.isCheck"
          />
        </div>
      </div>
    </NovaLayoutScrollContainer>

    <div class="action-box">
      <NovaButtonText
        :label="$t('retire.inquire.button')"
        :full-width="true"
        :theme="'primary-blue'"
        :disabled="!isValidate"
        class="btn-action"
        @click="onSubmitInquire"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.retire-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.content-area {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.form-box {
  padding-top: 16px;

  &:first-child {
    padding-top: 0;
  }

  .title {
    display: block;
    padding-bottom: 16px;
    @include text-style($text-heading3-medium);

    &.required::after {
      content: '*';
      padding-left: 6px;
      color: $color-primary-red;
    }
  }

  .validate {
    display: block;
    @include text-style($text-body-12-regular);
    color: $color-primary-dark-3;
    margin-top: 8px;

    &.error {
      color: $color-primary-red;
    }
  }

  .table-box {
    .table {
      margin-top: 16px;

      > div {
        display: flex;
        gap: 8px;
        width: 100%;
        margin-top: 8px;

        &:first-child {
          margin-top: 0;
        }

        dt,
        dd {
          background-color: $color-secondary-blue-light-10;
          padding: 10px;
          @include text-style($text-body-12-regular);
        }
        dt {
          width: 80px;
          flex-shrink: 0;
        }
        dd {
          flex-grow: 1;
          @include text-style($text-body-12-bold);
          color: $color-primary;
        }
      }
    }

    :deep(.text) {
      display: block;
      margin-top: 16px;
      @include text-style($text-body-12-regular);

      &:first-child {
        margin-top: 0;
      }

      em {
        color: $color-primary;
      }
    }

    .check-box {
      margin-top: 16px;
      @include text-style($text-heading3-medium);
    }
  }
}

.action-box {
  flex-shrink: 0;
  width: 100%;
  padding-top: 16px;
}
</style>
