/**
 * RGB 컴포넌트를 기반으로 색상의 휘도 값 구함
 *
 * @param {number} r - 색상의 빨간색 컴포넌트 (0-255)
 * @param {number} g - 색상의 초록색 컴포넌트 (0-255)
 * @param {number} b - 색상의 파란색 컴포넌트 (0-255)
 * @returns {number} 색상의 휘도 값
 */
export const useGetLuminance = (r: number, g: number, b: number): number =>
  0.2126 * r + 0.7152 * g + 0.0722 * b
