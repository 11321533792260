import { useRuntimeConfig } from '#app'
import { type KakaoShareRootObject } from './useSendKakaoShare.types'
import env, { type Envs } from '@configs/env'

export const useSendKakaoShare = (payload: KakaoShareRootObject) => {
  if (typeof window === 'undefined')
    throw new Error('send kakao share is required window')

  if (!window.Kakao.isInitialized()) {
    const config = useRuntimeConfig()
    window.Kakao.init(
      env[(config.public.MODE || 'development') as Envs].KAKAO_APP_KEY
    )
  }

  window.Kakao.Share.sendDefault(payload)
}
