/**
 * 만나이 계산기
 * @param birthString - YYYYMMDD
 * @returns {number} - 만 나이
 */
export const useAgeCalculate = (birthString: string): number => {
  const regex = /(\d{4})(\d{2})(\d{2})/
  const match = birthString.match(regex)

  if (match) {
    const [, year, month, day] = match
    const birthDate = new Date(Number(year), Number(month) - 1, Number(day))
    const now = new Date()

    let age = now.getFullYear() - birthDate.getFullYear()

    if (
      now.getMonth() < birthDate.getMonth() ||
      (now.getMonth() === birthDate.getMonth() &&
        now.getDate() < birthDate.getDate())
    ) {
      age--
    }

    return age
  } else {
    throw new Error('Invalid input. Expected format: YYYYMMDD')
  }
}
