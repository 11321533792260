import apiPath from '@store/apiPath'

export default {
  /**
   * 외부 URL 크롤링
   * @method GET
   */
  FETCH_CRAWLING: `${apiPath.COMM}/v1/parser`,

  /**
   * 크리에이터 팔로우
   * @method POST
   */
  REQ_FOLLOW: `${apiPath.COMM}/v1/follow`,

  /**
   * 크리에이터 언팔로우
   * @method POST
   */
  REQ_UNFOLLOW: `${apiPath.COMM}/v1/unfollow`,

  /**
   * 크리에이터 후원하기
   * @method POST
   */
  REQ_CREATOR_DONATE: `${apiPath.COMM}/v1/creator-sponsor`,

  /**
   * 홈 URL 조회
   * @method GET
   */
  FETCH_HOME_URL: `${apiPath.COMM}/v1/home-url`,

  /**
   * 파일 등록: 이미지(멀티플)
   * @method POST
   */
  UPLOAD_FILE_IMAGES: `${apiPath.COMM}/img/v1/uploads`,

  /**
   * 파일 등록: 비디오
   * @method POST
   */
  UPLOAD_FILE_VIDEO: `${apiPath.COMM}/video/v2/upload`,

  /**
   * 앱 버전 조회
   * @method GET
   */
  FETCH_APP_VERSION: `${apiPath.COMM}/v1/app-version/final`,

  /**
   * 사용자 차단
   * @method POST
   */
  REQ_BLOCKING_USER: `${apiPath.COMM}/v1/block`,

  /**
   * 사용자 차단 해제
   * @method POST
   */
  REQ_UN_BLOCKING_USER: `${apiPath.COMM}/v1/unblock`,
}
