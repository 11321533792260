import { bridgeToApp, type Permission } from '@bridge'
import { NovaModalDialog } from '#components'

interface ShowRequirePermissionProps {
  content: string
  actions?: {
    // negative: string
    positive: string
  }
}

type RequestPermissionType =
  | 'post' // 포스트 작성
  | 'profileImage' // 프로필 이미지 변경
  | 'mainProfileImage' // 메인 프로필 이미지 변경
  | 'homeHeaderImage' // 사용자 홈 헤더 변경

const useAppPermission = () => {
  const { show: modalShow, hide: modalHide } = useModal()
  const { t } = useNuxtApp().$i18n

  const showRequirePermission = ({
    content,
    actions,
  }: ShowRequirePermissionProps) =>
    modalShow({
      component: NovaModalDialog,
      bind: {
        name: modalsName.MODAL_REQUIRE_PERMISSION,
        btns: !actions
          ? []
          : [
              // {
              //   label: actions.negative,
              //   theme: 'transparent',
              //   size: 32,
              //   onClick: async () => {
              //     await modalHide(modalsName.MODAL_REQUIRE_PERMISSION)
              //   },
              // },
              {
                label: actions.positive,
                theme: 'primary-blue-light',
                size: 32,
                onClick: async () => {
                  await modalHide(modalsName.MODAL_REQUIRE_PERMISSION)
                },
              },
            ],
      },
      slots: {
        content,
      },
    })

  const checkPermission = async ({
    permission,
    permissionType,
  }: {
    permission: Permission
    permissionType: RequestPermissionType
  }) => {
    let accessAble = false
    const { data: appPermission } = await bridgeToApp.checkPermission({
      permission,
    })
    // const appPermission: CheckPermissionResponse = {
    //   permission,
    //   status: 'blocked',
    // }

    if (appPermission && appPermission.permission === permission) {
      switch (appPermission.status) {
        case 'granted':
        case 'limited':
          accessAble = true
          break
        case 'blocked':
        case 'unavailable':
        case 'denied':
        default:
          accessAble = false
          await showRequirePermission({
            content: t(
              `requestAppPermission.requestModal.description.${permission}.${permissionType}`
            ),
            actions: {
              // negative: t(
              //   `requestAppPermission.requestModal.${permission}.actions.negative`
              // ),
              positive: t('confirm'),
            },
          })
          break
      }
    }

    return accessAble
  }

  const requestPermission = (permission: Permission) =>
    bridgeToApp.requestPermission({ permission })

  return { checkPermission, requestPermission }
}

export default useAppPermission
