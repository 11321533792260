interface UseGetFilesProps {
  accept: string
  multiple?: boolean
  formDataName: string
}

const useGetFiles = ({
  accept,
  multiple = false,
  formDataName,
}: UseGetFilesProps): Promise<{ formData: FormData; files: FileList | null }> =>
  new Promise((resolve) => {
    // 1. input element create
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', accept)
    if (multiple) {
      input.setAttribute('multiple', '')
    }

    // 2. input set hide
    input.style.cssText = `
     position: absolute;
     top: 0;
     left: 0;
     width: 0;
     height: 0;
     opacity: 0;   
    `

    // 3. input add listener: change
    input.addEventListener('change', (evt) => {
      const files = (evt.target as HTMLInputElement).files
      const formData = new FormData()

      Array.from(files!).forEach((file) => {
        formData.append(formDataName, file)
      })

      resolve({ formData, files })
    })

    // 4. fire
    input.click()
  })

export default useGetFiles
