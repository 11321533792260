import { AxiosError } from 'axios'

/**
 * 사용자의 홈 URL로 비동기적으로 가져오고 리다이렉트
 *
 * @param {number} userSn - 사용자 일련번호
 * @param {function} [onProgress] - 리다이렉트하기 전에 실행될 함수
 * @param {function} [exception] - 예외를 처리하는 함수
 *
 * @returns {Promise<void>} - 리다이렉트가 성공하면 처리된 프로미스를 반환
 *                           그렇지 않으면 오류와 함께 rejected
 */
export const useGoUserHome = async (
  userSn: number,
  onProgress?: () => any,
  exception?: () => void
): Promise<void> => {
  const commStore = useCommStore()
  const { t } = useNuxtApp().$i18n
  try {
    // 1.  레퍼럴 링크 조회 API 호출
    const target = await commStore.fetchHomeUrl({ userSn })

    // 2. 리다이렉트 전 수행할 함수 실행
    onProgress?.()

    // 3. 리다이렉트
    await useSleeper(100)
    await useNavigations({ url: `${target}` })
  } catch (err) {
    if (err instanceof AxiosError) {
      if (!exception) {
        switch (err.response?.status) {
          case 4002:
            useToast(t('commonError.4002.message'))
            break
          default:
            useToast(t('commonError.network.message'))
        }
      }
      exception?.()
    } else {
      return Promise.reject(err)
    }
  }
}
