/**
 * 크리에이터 등급
 */
export const creatorGrade = {
  default: 'Creator',
  bronze: 'Bronze',
  silver: 'Silver',
  gold: 'Gold',
  business: 'Brand·Project',
}
