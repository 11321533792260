export enum NotificationSettingViewType {
  SETTING = 'notificationSetting',
  PUSH_SOUND = 'notificationPushSoundSetting',
  SERVICE_DETAIL = 'notificationServiceDetailSetting',
}

export interface NotificationSettingViewStack {
  id: NotificationSettingViewType
  depth: number
  name: string
}
