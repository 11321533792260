import { AxiosError } from 'axios'
import { BlockAccessType } from '@components/NovaModalBlockAccess/NovaModalBlockAccess.types'

/**
 * KYC 인증 상태 (kycAt + kycCode 를 활용한 맵핑)
 */
export type KycAuthStatus =
  | 'tryYet' // 인증 미시도
  | 'pending' // 인증 대기
  | 'duplicate' // 인증 중복(이미 인증된 아이디가 있음)
  | 'reject' // 인증 거부
  | 'approve' // 인증 완료

/**
 * KYC 인증 목적
 */
export type KycAuthPurpose =
  | 'service' // 서비스
  | 'donate' // 후원
  | 'reward' // 보상

/**
 * KYC 프로세스 다이얼로그 파라미터
 */
export interface KycProcessParams {
  purpose: KycAuthPurpose
  kycStatus: KycAuthStatus
}

/**
 * KYC 인증이 필요한 프로세스 및 체커
 * @description Client side only
 */
export const useKyc = () => {
  const { t } = useNuxtApp().$i18n
  const userStore = useUserStore()
  const layoutStore = useLayoutStore()
  const { show: modalShow, hide: modalHide } = useModal()

  // KYC 인증을 최종 완료 했는지 체크
  const checkKycAuthStatus = async (): Promise<KycAuthStatus> => {
    if (userStore.user?.kycAt === 'N') {
      try {
        layoutStore.updateLoadingIndicatorGlobal({ show: true })
        await userStore.fetchKycStatus({
          userCmmnSn: userStore.user!.userCmmnSn,
        })
      } catch (err) {
        const _err = err as AxiosError
        switch (_err.response?.status) {
          case 4003:
            useToast(t('kyc.checkKycStatus.error.4003'))
            break
          default:
            useToast(t('kyc.checkKycStatus.error.default'))
        }
      } finally {
        layoutStore.updateLoadingIndicatorGlobal({ show: false })
      }
    }

    // return userStore.user?.kycAt === 'Y' && userStore.user?.kycCode === null

    if (userStore.user?.kycAt === 'N') {
      switch (userStore.user.kycCode) {
        case 'PEND':
          return 'pending'
        case '9001':
          return 'duplicate'
        case '9999':
          return 'reject'
        case null:
        default:
          return 'tryYet'
      }
    }

    return 'approve'
  }

  // KYC 인증 상태에 따른 다이얼로그 호출
  const openKycProcess = async (params: KycProcessParams) => {
    await modalShow(modalsName.MODAL_KYC_PROCESS, params)
  }

  // KYC 인증하기
  const certifiedKYC = async (purpose: KycAuthPurpose) => {
    if (userStore.user?.svcActSanctnsAt === 'Y') {
      modalShow(modalsName.MODAL_BLOCK_ACCESS, {
        blockAccessType: BlockAccessType.ACTIVITY,
        onClickConfirm: () => {
          modalHide(modalsName.MODAL_BLOCK_ACCESS)
        },
      })

      return
    }

    if (userStore.user?.kycAt === 'Y') return

    try {
      const kycStatus = await checkKycAuthStatus()
      if (kycStatus !== 'approve') {
        await openKycProcess({ purpose, kycStatus })
      }
    } catch (err) {}
  }

  return {
    checkKycAuthStatus,
    openKycProcess,
    certifiedKYC,
  }
}
