import {
  FcmActionHandler,
  FcmUrlHandler,
  FirebaseExceptionStatus,
} from './types'
import { ChatMode, LiveMode } from '~/sendbird/types'
import {
  useCommStore,
  useCreatorStore,
  useLayoutStore,
  useMyPageStore,
  useSendbirdStore,
} from '~/store'
import type { BooleanYn } from '~/store/types'
import { FirebaseException } from '~/firebase/exception'

type FcmAction = (url: string, doDone?: () => any) => any

type FcmActions = {
  [Key in FcmActionHandler]: FcmAction
}

interface FcmPayloadDataUrlConvert {
  originUrl: string
  urlHandler: FcmUrlHandler | string
  params?: { [Key: string]: any }
  hash?: string
}

/**
 * 주어진 URL을 경로명과 매개변수를 포함하는 구조화된 객체로 변환
 * @param {string} url - 변환할 URL.
 */
export const fcmPayloadDataUrlConverter = (url: string) => {
  // url pattern: '/userhome?userSn=123456#reward'
  // return pattern: { pathname: 'userhome', params }
  const config = useRuntimeConfig()
  const fullUrl = new URL(config.public.APP_URL + url)
  const params = Object.fromEntries(new URLSearchParams(fullUrl.search))
  const hash = fullUrl.hash.substring(1)

  const returnVal: FcmPayloadDataUrlConvert = {
    originUrl: url,
    urlHandler: fullUrl.pathname.replace('/', ''),
    ...(params && { params }),
    ...(hash && { hash }),
  }

  console.log('fcm payload data url convert before:', url)
  console.log('fcm payload data url convert after:', returnVal)

  return returnVal
}

/**
 * 프로필의 채팅 상태 업데이트
 * @param {Object} params - 채팅 상태를 업데이트하기 위한 매개변수들.
 * @param {number} params.createUserSn - 생성자의 사용자 일련번호.
 * @param {string} params.status - 채팅 상태 ('created' 또는 'removed').
 * @param {string} params.channelUrl - 채팅 채널의 URL.
 */
const updateChatStatus = (params: { [Key: string]: any }) => {
  const mypageStore = useMyPageStore()
  const creatorStore = useCreatorStore()

  const updatedInfo = {
    createdOpenChannelAt: (params.status === 'created'
      ? 'Y'
      : 'N') as BooleanYn,
    channelUrl: params.status === 'created' ? params.channelUrl : null,
  }

  // creator home profile
  if (creatorStore.profile?.userSn === Number(params.createUserSn)) {
    creatorStore.updateChatStatus({
      createdOpenChannelAt: updatedInfo.createdOpenChannelAt,
      channelUrl: updatedInfo.channelUrl,
    })
  }

  // user profile
  if (mypageStore.profile?.userSn === Number(params.createUserSn)) {
    mypageStore.updateChatStatus({
      createdOpenChannelAt: updatedInfo.createdOpenChannelAt,
      channelUrl: updatedInfo.channelUrl,
    })
  }
}

/**
 * 프로필의 생방송 상태를 업데이트
 * @param {Object} params - 생방송 상태를 업데이트하기 위한 매개변수.
 * @param {string} params.status - 생방송 이벤트의 상태 ('ongoing' 또는 'ended').
 * @param {string} params.liveEventId - 생방송 이벤트의 ID.
 * @param {number} params.createUserSn - 생성자의 사용자 일련번호.
 */
const updateLiveStatus = async (params: { [Key: string]: any }) => {
  const mypageStore = useMyPageStore()
  const creatorStore = useCreatorStore()
  const sendbirdStore = useSendbirdStore()

  const updatedInfo = {
    sndbrdLiveSn: params.status === 'ongoing' ? params.liveEventId : null,
    liveOnAirAt: (params.status === 'ongoing' ? 'Y' : 'N') as BooleanYn,
  }

  // creator home profile
  if (
    !(
      creatorStore.profile?.blockedAt === 'Y' ||
      creatorStore.profile?.blockingAt === 'Y'
    ) &&
    creatorStore.profile?.userSn === Number(params.createUserSn)
  ) {
    creatorStore.updateLiveStatus({
      sndbrdLiveSn: updatedInfo.sndbrdLiveSn,
      liveOnAirAt: updatedInfo.liveOnAirAt,
    })
  }

  // user profile
  if (mypageStore.profile?.userSn === Number(params.createUserSn)) {
    mypageStore.updateLiveStatus({
      sndbrdLiveSn: updatedInfo.sndbrdLiveSn,
      liveOnAirAt: updatedInfo.liveOnAirAt,
    })
  }

  await sendbirdStore.fetchOnAirLives(true)
}

/**
 * Firebase Cloud Messaging URL을 처리하고 해당하는 액션을 수행합니다.
 *
 * @param {ReturnType<typeof fcmPayloadDataUrlConverter>} url - 변환된 FCM 페이로드 URL.
 * @throws {FirebaseException} - Firebase Cloud Messaging에서 사용자를 찾을 수 없는 경우 예외를 발생시킵니다.
 * @returns {Promise<void>} - 액션 완료가 되면 처리되는 프로미스입니다.
 */
export const fcmUrlHandlerGating = async (
  url: ReturnType<typeof fcmPayloadDataUrlConverter>
) => {
  const layoutStore = useLayoutStore()
  const commStore = useCommStore()
  const { enterChat, enterLive } = useSendbirdGate()

  /**
   * 이 함수는 사용자의 홈 URL로 이동하는데 사용.
   *
   * @param {number} userSn - 사용자의 일련번호.
   * @param {string} [hash] - 사용자의 홈 URL 뒤에 붙일 해시값.
   *
   * @throws {FirebaseException} - Firebase Cloud Messaging에서 사용자를 찾을 수 없는 경우.
   */
  const goUserHome = async (userSn: number, hash?: string) => {
    try {
      let url = await commStore.fetchHomeUrl({ userSn })
      if (hash) {
        url = url + `/${hash}`
      }
      await useNavigations({ url })
    } catch (err) {
      throw new FirebaseException({
        message: "can't find user in firebase cloud messaging",
        cause: {
          status: FirebaseExceptionStatus.EXCEPTION_IN_FCM_ACTION,
          data: err,
        },
      })
    }
  }

  /**
   * 글로벌 레이아웃에서 로딩 표시를 설정.
   *
   * @param {boolean} loading - 로딩 표시기가 표시되어야 하는지 여부.
   */
  const commonOnLoading = (loading: boolean) => {
    layoutStore.updateLoadingIndicatorGlobal({ show: loading })
  }

  /**
   * FCM URL 핸들러에 대한 변수 핸들러.
   * @typedef {Object} Handlers
   * @property {Function} userHome - FcmUrlHandler.USER_HOME에 대한 핸들러.
   * @property {Function} chat - FcmUrlHandler.CHAT에 대한 핸들러.
   * @property {Function} live - FcmUrlHandler.LIVE에 대한 핸들러.
   * @property {Function} chatUpdate - FcmUrlHandler.CHAT_UPDATE에 대한 핸들러.
   * @property {Function} liveUpdate - FcmUrlHandler.LIVE_UPDATE에 대한 핸들러.
   */
  const handlers: { [Key in FcmUrlHandler | string]: () => any } = {
    [FcmUrlHandler.USER_HOME]: () => goUserHome(url.params!.userSn, url.hash),
    [FcmUrlHandler.CHAT]: async () =>
      await enterChat({
        mode: ChatMode.CHANNEL,
        channelUrl: url.params!.channelUrl,
        onLoading: commonOnLoading,
      }),
    [FcmUrlHandler.LIVE]: () =>
      enterLive({
        mode: LiveMode.GUEST,
        checkPermissionType: 'liveEventId',
        liveEventId: url.params!.liveEventId,
        liveOnAirAt: 'Y',
        onLoading: commonOnLoading,
      }),
    [FcmUrlHandler.CHAT_UPDATE]: () => updateChatStatus(url.params!),
    [FcmUrlHandler.LIVE_UPDATE]: () => updateLiveStatus(url.params!),
  }

  if (url.urlHandler in handlers) {
    await handlers[url.urlHandler]()
  } else {
    await useNavigations({ external: false, url: url.originUrl, type: 'push' })
  }
}

/**
 * Firebase Cloud Messaging (FCM)에서 직접 라우트 액션을 실행
 */
const fcmActionDirectRoute: FcmAction = async (url, doDone) => {
  await useNavigations({ external: false, url, type: 'push' })
  doDone?.()
}

/**
 * Firebase Cloud Messaging (FCM)에서 비로그인시 로그인 선행 작업을 수행하고 직접 라우트 액션을 처리.
 */
const fcmActionLoginDirectRoute: FcmAction = async (url, doDone) => {
  const { userStore, showSignInDialog } = useMembershipProcess()
  const _url = fcmPayloadDataUrlConverter(url)

  // 비로그인 시 로그인 유도 > 로그인 이후 프로세스에서 FcmUrlHandler 게이팅
  if (!userStore.user) {
    await showSignInDialog(url)
    return
  }

  // 로그인 시 FcmUrlHandler 게이팅
  await fcmUrlHandlerGating(_url)

  doDone?.()
}

/**
 * Firebase Cloud Messaging (FCM)에서 브라우징 액션을 처리.
 */
const fcmActionUrlBrowse: FcmAction = async (url, doDone) => {
  const _url = fcmPayloadDataUrlConverter(url)
  await fcmUrlHandlerGating(_url)
  doDone?.()
}

/**
 * Firebase Cloud Messaging (FCM)에서 비로그인시 로그인 선행 작업을 수행하고 직접 라우트 액션을 처리.
 */
const fcmActionLoginUrlBrowse: FcmAction = async (url, doDone) => {
  const { userStore, showSignInDialog } = useMembershipProcess()
  const _url = fcmPayloadDataUrlConverter(url)

  // 비로그인 시 로그인 유도 > 로그인 이후 프로세스에서 FcmUrlHandler 게이팅
  if (!userStore.user) {
    await showSignInDialog(url)
    return
  }

  // 로그인 시 FcmUrlHandler 게이팅
  await fcmUrlHandlerGating(_url)

  doDone?.()
}

/**
 * Firebase Cloud Messaging (FCM)에서 채팅 상테 업데이트 액션을 처리.
 */
const fcmActionChatUpdate: FcmAction = (url, doDone) => {
  const _url = fcmPayloadDataUrlConverter(url)
  updateChatStatus(_url.params!)
  doDone?.()
}

/**
 * Firebase Cloud Messaging (FCM)에서 라이브 상태 업데이트 액션을 처리.
 */
const fcmActionLiveUpdate: FcmAction = async (url, doDone) => {
  const _url = fcmPayloadDataUrlConverter(url)
  await updateLiveStatus(_url.params!)
  doDone?.()
}

/**
 * Executes Firebase Cloud Messaging (FCM) 액션.
 */
export const fcmActions: FcmActions = {
  /**
   * Action: direct route
   * @description 라우터 이동
   * @param url
   * @param doDone
   */
  [FcmActionHandler.DIRECT_ROUTE]: fcmActionDirectRoute,

  /**
   * Action: direct route after login
   * @description 로그인 절차 진행 후 라우터 이동
   * @param url
   */
  [FcmActionHandler.LOGIN_DIRECT_ROUTE]: fcmActionLoginDirectRoute,

  /**
   * Action: url browse
   * @description 메세지 수신 시 정해진 페이지로 이동할 때 선행 작업(서버 데이터 조회등)이 있다면 처리
   * @param url
   */
  [FcmActionHandler.URL_BROWSE]: fcmActionUrlBrowse,

  /**
   * Action: url browse after login
   * @description 메세지 수신 시 정해진 페이지로 이동할 때 선행 작업(서버 데이터 조회등)이 있다면 처리
   * @param url
   */
  [FcmActionHandler.LOGIN_URL_BROWSE]: fcmActionLoginUrlBrowse,

  /**
   * Action: live update
   * @description 라이브 시작 및 종료 등 상태 변경이 발생할 경우 데이터 업데이트 및 선행 작업 수행
   * @param url
   */
  [FcmActionHandler.LIVE_UPDATE]: fcmActionLiveUpdate,

  /**
   * Action: chat update
   * @description 채팅 개설 및 삭제 등 상태 변경이 발생할 경우 데이터 업데이트 및 선행 작업 수행
   * @param url
   */
  [FcmActionHandler.CHAT_UPDATE]: fcmActionChatUpdate,
}
