import type { BridgeExceptionStatus } from '@bridge/types'

/**
 * 브릿지 에러 예외 상태 코드 상수
 */
export const bridgeExceptionStatus = {
  TIMEOUT: 'timeout', // 설정된 시간내 응답 없음
  APP_HANDLER_NOT_FOUND: 'appHandlerNotFound', // 호출 헨들러가 없음
  HANDLER_KEY_NOT_FOUND: 'handlerKeyNotFound', // 호출 타겟 수신 대상은 있지만 등록된 헨들러 키가 없음
  BRIDGE_ONLY_USE_CSR: 'bridgeOnlyUseClientSide', // 브릿지 호출이 Server Side 에서 호출됨
  APP_TYPE_NOT_FOUND: 'appTypeNotFound', // 브릿지를 호출할 앱을 찾을 수 없음
  FAIL: 'fail', // 리스폰스는 받았지만 알 수 없는 이유로 FAIL
  DEV_TOOL_BRIDGE_DIRECTION_MISS_MATCH: 'devToolBridgeDirectionMissMatch', // 데브툴에서 브릿지 호출 방향이 일치 하지 않음
} as const

/**
 * 브릿지 에러 타입
 * @param message
 * @param cause
 */
export class BridgeException extends Error {
  name: 'BRIDGE_EXCEPTION'
  message: string
  cause: {
    status: BridgeExceptionStatus
    message: string
    data?: any
  }

  constructor({
    message,
    cause,
  }: {
    message: string
    cause: {
      status: BridgeExceptionStatus
      data?: any
    }
  }) {
    super()
    this.name = 'BRIDGE_EXCEPTION'
    this.message = message
    this.cause = {
      ...cause,
      message,
    }
  }
}
