<script setup lang="ts">
import { Tippy } from 'vue-tippy'
import { AxiosError } from 'axios'

const { gtEvent } = useGoogleTag()

const config = useRuntimeConfig()
const { t } = useI18n()

const userStore = useUserStore()
const mypageStore = useMyPageStore()

const referralLinkPath = `${config.public.APP_URL}/referral/`
const referralLinkPrefix = '@'
const referralCode = ref(userStore.user?.referralCode || '')
const editMode = ref(false)
const referralLinkPreview = computed(() =>
  useDeeplinkEntry(
    editMode.value
      ? `${referralLinkPath}${referralLinkPrefix}${referralCode.value}`
      : `${referralLinkPath}${referralLinkPrefix}${
          userStore.user?.referralCode || ''
        }`
  )
)
const loading = ref(false)
const isLock = computed(() => mypageStore.profile?.resistReferralCodeAt === 'Y')

// 수정 모드 변경시 초기화
watch(
  () => editMode.value,
  (cur) => {
    if (cur) {
      referralCode.value = userStore.user?.referralCode || ''
    }
  }
)

// 클립보드 복사
const handleOnCopyClipboard = () => {
  useCopyToClipboard(referralLinkPreview.value)
  useToast(t('creatorHomeLink.tools.clipboard.toastMessage.success'))
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '추천인 링크 복사 버튼 클릭',
    eventLabel: t('referral.clipboard.tooltip'),
    eventSlot: '프로필 수정 > 추천인 링크',
    eventI18nAddr: useKoreanTranslation('referral.clipboard.tooltip'),
    eventComponent: 'Button',
  })
}

// 에디트 모드 토글
const handleOnToggleEditMode = (action: string, slot: string) => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: `${t(action)} 버튼 클릭`,
    eventLabel: t(action),
    eventSlot: `프로필 수정 > 추천인 링크 > ${slot}`,
    eventI18nAddr: useKoreanTranslation(action),
    eventComponent: 'Button',
  })
  editMode.value = !editMode.value
}

// 크리에이터 홈 링크 업데이트
const handleOnUpdateReferralLink = async () => {
  gtEvent('userAction', {
    eventCategory: '사용자',
    eventAction: '저장 버튼 클릭',
    eventLabel: t('save'),
    eventSlot: '프로필 수정 > 추천인 링크 수정',
    eventI18nAddr: useKoreanTranslation('save'),
    eventComponent: 'Button',
  })
  // 1. https url 에서 사용하는 특수문자가 포함되어 있는지 여부 검사
  const isUnavailableNickname = useCheckUrlSpecialCharacter(referralCode.value)
  if (isUnavailableNickname) {
    useToast(t('referral.edit.toastMessage.unavailableRecommendLink'))
    return
  }

  // 2. 최소 글자수 검사
  if (!(referralCode.value.length >= 2)) {
    useToast(t('referral.edit.toastMessage.failLength', { min: 2 }))
    return
  }

  // 3. 영문 + 숫자 외 한글 혹은 특수문자 검사
  if (!/^[a-zA-Z0-9]*$/.test(referralCode.value)) {
    useToast(t('referral.edit.toastMessage.failCharacter'))
    return
  }

  // 4. 기존 레퍼럴 링크와 같음
  if (referralCode.value === userStore.user?.creatorLink) {
    useToast(t('referral.edit.toastMessage.failEqual'))
    return
  }

  // 5. 레퍼럴 코드 업데이트 api 호출(중복검사 + 업데이트)
  try {
    loading.value = true

    // 5-1. 레퍼럴 코드 업데이트
    await mypageStore.regReferralCode({ referralCode: referralCode.value })

    // 5-2. 사용자 프로필 재조회
    await mypageStore.fetchProfile()
    gtEvent('userAction', {
      eventCategory: '사용자',
      eventAction: '추천인 링크 저장',
      eventLabel: t('save'),
      eventSlot: '프로필 수정 > 추천인 링크',
      eventI18nAddr: useKoreanTranslation('save'),
      eventComponent: 'Button',
    })
    // 5-3. 성공 메세지 노출
    useToast(t('referral.edit.toastMessage.success'))
    // 5-6. 수정 모드 닫기
    editMode.value = false
  } catch (err) {
    const _err = err as AxiosError

    if (_err.response?.status) {
      switch (_err.response.status) {
        case 2010:
          useToast(t('referral.edit.toastMessage.failDuplicate'))
          break
        case 4017:
          useToast(t('referral.edit.toastMessage.failUpdate'))
          break
        default:
          useToast(t('referral.edit.toastMessage.failDefault'))
          break
      }
    }
  } finally {
    loading.value = false
  }
}
</script>

<template>
  <NovaBoxAccountSection :title="$t('referral.edit.label')">
    <template #contents>
      <div class="referral-link-wrap">
        <div class="preview-area">
          <p class="referral-link-path">{{ referralLinkPreview }}</p>

          <div v-if="editMode && !isLock" class="edit-area">
            <NovaInputSimple v-model="referralCode" :full-width="true" />

            <div class="tools">
              <NovaButtonText
                :size="32"
                :label="$t('cancel')"
                :theme="'secondary-gray'"
                @click="handleOnToggleEditMode('cancel', '추천인 링크 수정')"
              />

              <NovaButtonText
                :size="32"
                :label="$t('save')"
                :theme="'primary-blue'"
                @click="handleOnUpdateReferralLink"
              />
            </div>
          </div>
        </div>

        <div v-if="!editMode" class="edit-tools">
          <Tippy
            :append-to="'parent'"
            :content="$t('referral.clipboard.tooltip')"
            :interactive="true"
            :theme="'simple'"
            :placement="'bottom'"
          >
            <NovaButtonIcon
              :icon="{ type: 'outline', name: 'link' }"
              :size="24"
              :loading="loading"
              class="btn-clipboard"
              @click="handleOnCopyClipboard"
            />
          </Tippy>

          <Tippy
            v-if="!isLock"
            :append-to="'parent'"
            :content="$t('referral.edit.tooltip')"
            :interactive="true"
            :theme="'simple'"
            :placement="'bottom'"
          >
            <NovaButtonIcon
              :icon="{ type: 'outline', name: 'write' }"
              :size="16"
              :loading="loading"
              class="btn-edit"
              @click="
                handleOnToggleEditMode(
                  'referral.edit.tooltip',
                  '추천인 링크 수정'
                )
              "
            />
          </Tippy>
        </div>
      </div>
    </template>
  </NovaBoxAccountSection>
</template>

<style lang="scss" scoped>
.referral-link-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  .preview-area {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 6px;

    .referral-link-path {
      @include text-style($text-body-13-regular);
      color: $color-text-3;
    }

    .edit-area {
      display: flex;
      align-items: center;
      gap: 12px;

      > .tools {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }

  .edit-tools {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 0;

    .btn-clipboard {
      width: 16px;
      height: 16px;
    }
  }
}
</style>
