import type { BridgePayload, BridgeResponse } from '@bridge/types'
import { BridgeException, bridgeExceptionStatus } from '@bridge/utils/exception'
import { handlerToApp, handlerToWeb } from '@bridge/utils/handler'
import { useAppStore } from '@store'

/**
 * 브릿지 호출
 * @description WEB > APP
 */
export const toApp = <T, R>({
  payload,
  options,
  doDone,
}: BridgePayload<T, R>): Promise<BridgeResponse<R>> => {
  console.log('toApp payload:', payload)
  console.log('toApp options:', options)

  const appStore = useAppStore()
  const interval = options?.interval || 1000
  let timerCounter = options?.limit === 'infinity' ? 0 : options?.limit || 10000
  let timer: ReturnType<typeof setInterval>

  return new Promise((resolve, reject) => {
    // methods: reject
    const doReject = (error: Error) => {
      try {
        window.removeEventListener(payload.key, receiveMessage)
      } catch {
        console.log(`window event listeners has not [${payload.key}]`)
      }
      clearInterval(timer)
      reject(error)
    }

    // methods: resolve
    const doResolve = (data: BridgeResponse<R>) => {
      if (typeof options === 'undefined' || options?.callHandler) {
        window.removeEventListener(payload.key, receiveMessage)
      }
      if (doDone) {
        doDone(data)
      }
      clearInterval(timer)
      resolve(data)
    }

    // 1-1. process: check bridge call able
    if (process.server) {
      doReject(
        new BridgeException({
          message: 'bridge use only client side',
          cause: {
            status: bridgeExceptionStatus.BRIDGE_ONLY_USE_CSR,
          },
        })
      )
    }

    // 1-2. process: check app mode
    if (!appStore.isApp) {
      doReject(
        new BridgeException({
          message: `bridge handler [${payload.key}] app type(IOS | AOS) is not found`,
          cause: {
            status: bridgeExceptionStatus.APP_TYPE_NOT_FOUND,
          },
        })
      )
    }

    // 2-1. process: receive post message
    const receiveMessage = (evt: Event) => {
      console.log('receiveMessage', evt)
      const _evt = evt as CustomEvent<BridgeResponse<R>>

      if (_evt.detail.handlerKey === payload.key) {
        switch (_evt.detail.status) {
          case 'success':
            doResolve(_evt.detail)
            break
          default:
            doReject(
              new BridgeException({
                message: _evt.detail.message,
                cause: {
                  status: _evt.detail.status,
                  data: _evt.detail.data,
                },
              })
            )
            break
        }
      }
    }

    // 2-2 process: ready to receive message
    window.addEventListener(payload.key, receiveMessage, false)

    // 2-3. process: count time limit
    if (timerCounter !== 0) {
      timer = setInterval(() => {
        if (timerCounter <= interval) {
          doReject(
            new BridgeException({
              message: `bridge [${payload.key}] is timeout`,
              cause: {
                status: bridgeExceptionStatus.TIMEOUT,
              },
            })
          )
        }

        timerCounter -= interval
      }, interval)
    }

    // 3. process: send message
    if (typeof options === 'undefined' || options?.callHandler) {
      handlerToApp<T>(payload)
    }
  })
}

/**
 * 브릿지 호출
 * @description APP > WEB
 */
export const toWeb = <R>(message: string) => {
  const _message = handlerToWeb<R>(message)
  window.dispatchEvent(
    new CustomEvent(_message.handlerKey, { detail: _message })
  )
}
