<script setup lang="ts">
import { AxiosError } from 'axios'
import type { RouteParams } from 'vue-router'
import type {
  MyFollowerTab,
  MyFollowerTabType,
} from './NovaModalMyFollowers.types'
import type { MyBlockPayload, MyFollowPayload } from '@store'

defineOptions({
  inheritAttrs: false,
})

const { gtEvent } = useGoogleTag()
const { t, messages, locale } = useI18n()
const isError = ref(false)
const isLoading = ref(true)
const show = ref(false)
const { hide: modalHide } = useModal()
const followersRef = ref<HTMLElement | null>(null)
const userSn = ref(-1)
const mypageStore = useMyPageStore()
const { myFollowers, myBlocks } = storeToRefs(mypageStore)

/**
 * 모달 열릴때 팔로워 목록 조회
 */
watch(
  () => [show.value, userSn.value],
  async ([curShow, curUserSn], [_prevShow, prevUserSn]) => {
    if (curShow && !(curUserSn === prevUserSn)) {
      await useSleeper()
      followersRef.value = document.getElementById(
        'modal-follower-scroll-container'
      )
      await fetchMyFollowers(true)
    }
  }
)
const tabs = computed(() =>
  Object.keys((messages.value[locale.value].mypage as any).myFollow.tabs).map(
    (key) =>
      ({ id: key, name: t(`mypage.myFollow.tabs.${key}`) } as MyFollowerTab)
  )
)
const initTab = ref<MyFollowerTabType>(tabs.value[0].id)

/**
 * 팔로워 목록 조회
 */
const fetchMyFollowers = async (
  refresh = false,
  payload?: MyFollowPayload,
  isRecycle?: boolean
) => {
  try {
    isLoading.value = true
    isError.value = false
    await mypageStore.fetchMyFollowers(
      {
        ...(payload || { ...myFollowers.value.payload }),
        userSn: userSn.value,
      },
      refresh
    )
    if (isRecycle) {
      gtEvent('scrollEvent', {
        eventCategory: '더보기',
        eventAction: '팔로워 목록',
        eventLabel: 'loadMore',
        eventSlot: '팔로워',
        eventI18nAddr: '',
        eventComponent: 'RecyclerView',
        lastUserCmtyFlwSn: payload?.lastUserCmtyFlwSn,
        pageNum: payload?.pageNum,
        pageSize: payload?.pageSize,
      })
    }
  } catch (err) {
    const _err = err as AxiosError
    switch (_err.response?.status) {
      case 4002:
      default:
        useToast(t('mypage.myFollow.message.error.unknown'))
        break
    }
    console.log('error')
  } finally {
    isLoading.value = false
  }
}
/**
 * 차단 목록 조회
 */
const fetchMyBlocks = async (
  refresh = false,
  payload?: MyBlockPayload,
  isRecycle?: boolean
) => {
  try {
    isLoading.value = true
    isError.value = false
    /* await mypageStore.fetchMyBlocks(
      {
        ...(payload || { ...myBlocks.value.payload }),
        userSn: userSn.value,
      },
      refresh
    ) */
    await mypageStore.fetchMyBlocks(
      payload || { ...myBlocks.value.payload },
      refresh
    )
    if (isRecycle) {
      gtEvent('scrollEvent', {
        eventCategory: '더보기',
        eventAction: '차단 목록 목록',
        eventLabel: 'loadMore',
        eventSlot: '팔로워',
        eventI18nAddr: '',
        eventComponent: 'RecyclerView',
        lastUserCmtyFlwSn: payload?.lastUserCmtyFlwSn,
        pageNum: payload?.lastUserCmtyFlwSn,
        pageSize: payload?.lastUserCmtyFlwSn,
      })
    }
  } catch (err) {
    const _err = err as AxiosError

    switch (_err.response?.status) {
      case 4002:
      default:
        useToast(t('mypage.myFollow.message.error.unknown'))
        break
    }
    console.log('error')
  } finally {
    isLoading.value = false
  }
}
const onChangeTab = (tab: MyFollowerTab) => {
  initTab.value = tab.id
  if (tab.id === 'follower' && !mypageStore.myFollowers.items.length) {
    fetchMyFollowers(true)
  } else if (tab.id === 'block' && !mypageStore.myBlocks.items.length) {
    fetchMyBlocks(true)
  }
}

/**
 * 팝업 닫기: 버튼 클릭
 */
const handleOnClose = async () => {
  await modalHide(modalsName.MODAL_MY_FOLLOWERS)
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '팔로워/차단목록 모달 > 닫기',
    eventLabel: 'close-extend',
    eventSlot: 'Modal',
    eventI18nAddr: 'close-extend',
    eventComponent: 'Button',
  })
}

/**
 * 팝업 닫기전 훅
 */
const handleOnBeforeClose = async () => {
  mypageStore.clearFollow('followers')
  mypageStore.clearFollow('blocks')
  initTab.value = tabs.value[0].id
  await useSleeper(3000)

  return true
}

/**
 * 팝업 닫은 후 스토어에서 팔로우, 차단 목록, 탭 초기화
 */
const handleOnClosed = () => {
  userSn.value = -1
  mypageStore.clearFollow('followers')
  mypageStore.clearFollow('blocks')

  isLoading.value = true
}

// 팔로잉 목록 조회 대상자 userSn 받기
const handleOnGetParams = (evt: RouteParams) => {
  userSn.value = (evt.value as unknown as { userSn: number }).userSn
}
</script>

<template>
  <VueFinalModal
    v-model="show"
    :name="modalsName.MODAL_MY_FOLLOWERS"
    :content-class="'modal-content'"
    classes="modal-container"
    @before-close="handleOnBeforeClose"
    @closed="handleOnClosed"
    @before-open="(evt: any) => handleOnGetParams(evt.ref.params)"
    @click-outside="handleOnClose"
  >
    <ClientOnly>
      <NovaBoxBase v-if="show" class="modal-box">
        <div class="modal-header">
          <NovaTab
            v-if="userSn === mypageStore.profile?.userSn"
            :tab-value="tabs"
            :init-tab="initTab"
            :align="'center'"
            :size="40"
            @on-change-tab="onChangeTab"
          />
          <div v-else class="title">
            <span>{{ $t('mypage.myFollow.title.follower') }}</span>
          </div>
          <NovaButtonIcon
            :icon="{ type: 'outline', name: 'close-extend' }"
            :theme="'transparent'"
            :size="24"
            class="btn-close"
            @click="handleOnClose"
          />
        </div>

        <NovaLayoutScrollContainer
          id="modal-follower-scroll-container"
          class="modal-body"
        >
          <NovaLoadingIndicator
            v-if="isLoading"
            :fill="true"
            :bg-bright="'light'"
            class="loading-indicator"
          />

          <!-- 팔로워 목록 -->
          <NovaRecyclerGrid
            v-if="initTab === 'follower' && show && myFollowers.items.length"
            :items="myFollowers.items"
            :is-last="myFollowers.last"
            :is-loading="isLoading"
            :is-error="isError"
            :is-refresh="myFollowers.refresh"
            :key-name="'userSn'"
            :error-message="$t('mypage.myFollow.follower.error')"
            :responsive="{
              mobile: { columns: 1, rowGap: 20, columnGap: 0 },
              tablet: { columns: 1, rowGap: 20, columnGap: 0 },
              desktop: { columns: 1, rowGap: 20, columnGap: 0 },
            }"
            :page-mode="false"
            :bottom-white-space="0"
            @load-more="() => fetchMyFollowers(false, undefined, true)"
          >
            <template #item="{ item, index }">
              <NovaBoxFollowUser
                :source="item"
                :view-type="'follower'"
                :popover-append-target="followersRef"
                :show-toggle-follow-btn="
                  myFollowers.items[index].userSn !==
                  mypageStore.profile?.userSn
                "
                :show-block-btn="false"
                :show-more-btn="userSn === mypageStore.profile?.userSn"
              />
            </template>
          </NovaRecyclerGrid>

          <div
            v-if="
              initTab === 'follower' &&
              !isLoading &&
              !myFollowers.items.length &&
              !isError
            "
            :fill="true"
            class="no-data"
          >
            <p
              v-dompurify-html="$t('mypage.myFollow.follower.noDate.title')"
              class="title"
            />
            <p
              v-dompurify-html="$t('mypage.myFollow.follower.noDate.message')"
              class="message"
            />
          </div>
          <NovaBoxEmptyMessage
            v-if="
              initTab === 'follower' &&
              !isLoading &&
              !myFollowers.items.length &&
              isError
            "
            :message="t('mypage.myFollow.follower.error')"
            :fill="true"
          />

          <!-- 차단 목록 -->
          <NovaRecyclerGrid
            v-if="initTab === 'block' && myBlocks.items.length"
            :items="myBlocks.items"
            :is-last="myBlocks.last"
            :is-loading="isLoading"
            :is-error="isError"
            :is-refresh="myBlocks.refresh"
            :key-name="'userSn'"
            :page-mode="false"
            :error-message="$t('mypage.myFollow.block.error')"
            :responsive="{
              mobile: { columns: 1, rowGap: 20, columnGap: 0 },
              tablet: { columns: 1, rowGap: 20, columnGap: 0 },
              desktop: { columns: 1, rowGap: 20, columnGap: 0 },
            }"
            :bottom-white-space="0"
            @load-more="() => fetchMyBlocks(false, undefined, true)"
          >
            <template #item="{ item }">
              <NovaBoxFollowUser
                :source="item"
                :view-type="'follower'"
                :popover-append-target="followersRef"
                :show-toggle-follow-btn="false"
                :show-more-btn="false"
                :show-block-btn="userSn === mypageStore.profile?.userSn"
              />
            </template>
          </NovaRecyclerGrid>
          <!-- 데이터 없음 -->
          <NovaBoxEmptyMessage
            v-if="
              initTab === 'block' &&
              !isLoading &&
              !myBlocks.items.length &&
              !isError
            "
            :message="t('mypage.myFollow.block.noDate.title')"
            :fill="true"
          />
          <NovaBoxEmptyMessage
            v-if="
              initTab === 'block' &&
              !isLoading &&
              !myBlocks.items.length &&
              isError
            "
            :message="t('mypage.myFollow.block.error')"
            :fill="true"
          />
        </NovaLayoutScrollContainer>
      </NovaBoxBase>
    </ClientOnly>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
:deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.vfm--overlay) {
  background-color: hex-to-rgba($color-black, 0.65);
}

:deep(.modal-content) {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(30px + 24px + 12px) 32px 32px;
  width: 100%;
  height: 100%;
  pointer-events: none;

  .modal-box {
    width: 100%;
    padding: 0;
    max-width: 670px;
    height: 100%;
    min-height: 360px;
    max-height: 480px;
    border-radius: 20px !important;
    pointer-events: auto;
    padding-bottom: 20px;
    .modal-header {
      position: relative;
      padding: 24px;
      @include mobile {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 20px;
      }
      .title {
        @include text-style($text-heading2-bold);
      }

      .btn-close {
        position: absolute;
        top: 30px;
        right: 30px;
        color: $color-black;
        z-index: 50;
        @include mobile {
          top: 18px;
          right: 20px;
        }
      }
    }
    .modal-body {
      padding-top: 20px;
    }
  }
}
:deep(.tabs-container) .tabs {
  margin-left: -10px;
  .swiper .tab .tab-button > .label {
    @include text-style($text-heading2-bold);
  }
}

/* 노 데이터 */
.no-data {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  text-align: center;
  height: 100%;
  min-height: 260px;
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 60px;
    @include text-style($text-custom-24-bold);
  }

  .message {
    display: flex;
    justify-content: flex-start;
    padding: 0 40px;
    text-align: left;
    @include text-style($text-heading3-medium);
    @include mobile {
      padding: 0 20px;
    }
  }
}

@include mobile {
  :deep(.modal-content) {
    width: 100%;
    height: 100%;
    padding: 0;

    .modal-box {
      width: 100%;
      max-width: 100%;
      height: 100%;
      border-radius: 0 !important;
      max-height: none;
      .modal-header {
        flex-shrink: 0;
        height: 56px;
        .on-mobile {
          display: flex;
        }
        .on-desktop {
          .btn-close {
            display: none;
          }
        }
      }

      .modal-body {
        border-radius: 0;

        > .post {
          max-height: initial;
        }
      }
    }
  }
}
</style>
