<script setup lang="ts">
import type {
  NovaBoxPostCommentsAndRepliesFooterEmits,
  NovaBoxPostCommentsAndRepliesFooterProps,
} from './NovaBoxPostCommentsAndRepliesFooter.types'

const emit = defineEmits<NovaBoxPostCommentsAndRepliesFooterEmits>()
const props = defineProps<NovaBoxPostCommentsAndRepliesFooterProps>()

const postStore = usePostStore()

const handleOnToggleShowComments = () => {
  emit('update:modelValue', !props.modelValue)
}

const handleOnFetchMore = async () => {
  await postStore.fetchCommentsAndReplies({
    ...postStore.commentsAndReplies.payload,
  })
  emit('update:modelValue', false)
}
</script>

<template>
  <div class="comments-and-replies-footer">
    <div>
      <button
        v-if="count > limit"
        type="button"
        :class="['btn-toggle-show-comments', { on: modelValue }]"
        @click="handleOnToggleShowComments"
      >
        <NovaIcon
          :icon="{ type: 'outline', name: 'chev-top' }"
          :size="12"
          class="indicator"
        />
        <span class="label">
          {{ $t(modelValue ? 'commentsExpand' : 'commentsCollapse') }}
        </span>
      </button>
    </div>

    <NovaButtonText
      v-if="!postStore.commentsAndReplies.last"
      :label="$t('seeMore')"
      :theme="'primary-blue-ultra-light'"
      :size="32"
      @click="handleOnFetchMore"
    />
  </div>
</template>

<style lang="scss" scoped>
.comments-and-replies-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &:empty {
    display: none;
  }

  .btn-toggle-show-comments {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 8px 10px;
  }

  .btn-toggle-show-comments {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 32px;
    padding: 0 10px;

    &.on {
      > .indicator {
        transform: rotate(180deg);
        color: $color-secondary-red-light-80;
      }
    }

    > .label {
      @include text-style($text-body-14-bold);
      line-height: 16px;
      color: $color-text-2;
    }

    > .indicator {
      color: $color-text-2;
      @include transition(transform 0.2s ease-in-out, color 0.2s ease-in-out);
    }
  }
}
</style>
