<script setup lang="ts">
import type { NovaBoxPostCommentsAndRepliesContentsProps } from './NovaBoxPostCommentsAndRepliesContents.types'

const props = defineProps<NovaBoxPostCommentsAndRepliesContentsProps>()

const postStore = usePostStore()
const limited = computed(() =>
  props.collapse
    ? postStore.commentsAndReplies.items.slice(0, props.limit)
    : postStore.commentsAndReplies.items
)
</script>

<template>
  <div class="comments-and-replies-contents">
    <NovaBoxPostCommentReplayItem
      v-for="item in limited"
      :key="item.cmtyNttAnswerSn"
      :source="item"
      :view-type="viewType"
      :cmty-ntt-sn="cmtyNttSn"
      :answer-count="answerCount"
    />
  </div>
</template>

<style lang="scss" scoped>
.comments-and-replies-contents {
  display: flex;
  flex-direction: column;
  gap: $comment-and-reply-gap;
}
</style>
