import useGetFiles from './useGetFiles'
import type {
  FileImageContainer,
  FileVideoContainer,
  NttTypeCode,
} from '@store'

type UsePostFileUploaderNttTypeCode = Exclude<NttTypeCode, 'NORM'>
type ShowLoadingIndicator = boolean | undefined
interface UsePostFileUploaderProps {
  nttTypeCode: UsePostFileUploaderNttTypeCode
  showLoadingIndicator: ShowLoadingIndicator
}

/**
 * 카드형 파일 얻기
 * @param showLoadingIndicator
 */
export const uploadFileImages = async (
  showLoadingIndicator: ShowLoadingIndicator = false
): Promise<FileImageContainer> => {
  const commStore = useCommStore()
  const layoutStore = useLayoutStore()
  const { t } = useNuxtApp().$i18n
  const { gtEvent } = useGoogleTag()

  const { formData } = await useGetFiles({
    accept: 'image/*',
    multiple: true,
    formDataName: 'imgFiles',
  })
  if (showLoadingIndicator) {
    try {
      layoutStore.updateLoadingIndicatorGlobal({ show: true })
      const data = await commStore.uploadFileImages(formData)
      gtEvent('fileAction', {
        eventCategory: '파일',
        eventAction: '카드형 파일 업로드',
        eventLabel: '',
        eventSlot: '',
        eventI18nAddr: '',
        eventComponent: 'File Upload',
        fileId: data.fileId,
      })
      return data
    } catch {
      useToast(t('postCreate.toastMessage.imgError'))
    } finally {
      layoutStore.updateLoadingIndicatorGlobal({ show: false })
    }
  }
  return await commStore.uploadFileImages(formData)
}

/**
 * 비디오형 파일 얻기
 * @param showLoadingIndicator
 */
export const uploadFileVideo = async (
  showLoadingIndicator: ShowLoadingIndicator = false
): Promise<FileVideoContainer> => {
  const commStore = useCommStore()
  const layoutStore = useLayoutStore()
  const { t } = useNuxtApp().$i18n
  const { gtEvent } = useGoogleTag()

  const { formData } = await useGetFiles({
    accept: 'video/*',
    multiple: false,
    formDataName: 'mvFile',
  })

  if (showLoadingIndicator) {
    try {
      layoutStore.updateLoadingIndicatorGlobal({ show: true })
      const data = await commStore.uploadFileVideo(formData)
      gtEvent('fileAction', {
        eventCategory: '파일',
        eventAction: '비디오 파일 업로드',
        eventLabel: '',
        eventSlot: '',
        eventI18nAddr: '',
        eventComponent: 'File Upload',
        fileId: data.fileId,
      })
      return data
    } catch {
      useToast(t('postCreate.toastMessage.errors.default'))
    } finally {
      layoutStore.updateLoadingIndicatorGlobal({ show: false })
    }
  }

  return await commStore.uploadFileVideo(formData)
}

/**
 * 포스트 관련 파일 업로더
 * @description CARD, CVOD 형 파일만 사용
 * @param nttTypeCode
 * @param showLoadingIndicator
 */
const usePostFileUploader = async ({
  nttTypeCode,
  showLoadingIndicator,
}: UsePostFileUploaderProps) => {
  switch (nttTypeCode) {
    case 'CARD':
      return await uploadFileImages(showLoadingIndicator)
    case 'CVOD':
      return await uploadFileVideo(showLoadingIndicator)
    default:
      throw new Error('nttTypeCode is not match')
  }
}

export default usePostFileUploader
