import FundApi from './url'
import * as FundTypes from './type'
import { api } from '@api'

export default {
  /**
   * 펀드 이달의 프로젝트 배너 조회
   */
  fetchFundBanner() {
    return api.get<FundTypes.FundBanner>(FundApi.FETCH_FUND_BANNER)
  },

  /**
   * 펀드 이달의 프로젝트 조회
   */
  fetchFundSurvey(payload: FundTypes.FundSurveyPayload) {
    return api.get<FundTypes.FundSurvey>(FundApi.FETCH_FUND_SURVEY, {
      params: payload,
    })
  },

  /**
   * 펀드 이들의 프로젝트 상세 조회
   */
  fetchFundProject(payload: FundTypes.FundProjectPayload) {
    return api.get<FundTypes.FundProject>(FundApi.FETCH_FUND_SURVEY_DETAIL, {
      params: payload,
    })
  },

  /**
   * 펀드 프로젝트 투표
   */
  reqFundProjectVote(payload: FundTypes.FundProjectVotePayload) {
    return api.post<{}>(FundApi.REQ_FUND_PROJECT_VOTE, payload)
  },

  /**
   * 퍼드 히스토리 목록 조회
   */
  fetchFundHistories(payload: FundTypes.FundHistoriesPayload) {
    return api.get<FundTypes.FundHistory[]>(FundApi.FETCH_FUND_HISTORIES, {
      params: payload,
    })
  },
}
