<script setup lang="ts">
import { Tippy } from 'vue-tippy'
import type { NovaRecommendUserProps } from './NovaRecommendUser.types'

defineProps<NovaRecommendUserProps>()

const appendToRef = ref<Element | null>(null)
</script>

<template>
  <div ref="appendToRef" class="recommend-user">
    <Tippy
      :append-to="'parent'"
      :content="user.userNcnm"
      :interactive="true"
      :theme="'simple'"
      :placement="'bottom-end'"
    >
      <NovaPortraitContainer
        :image-url="user.profileImgUrl"
        :size="'xs'"
        :stroke="true"
      />
    </Tippy>
  </div>
</template>

<style lang="scss" scoped>
.recommend-user {
  width: 28px;
  height: 28px;
  margin-left: -4px;
}
</style>
