<script setup lang="ts">
import type { NovaIframeContainerProps } from './NovaIframeContainer.types'

const emit = defineEmits(['load'])
const props = withDefaults(defineProps<NovaIframeContainerProps>(), {
  scrollTarget: '',
  height: null,
})

const { height } = toRefs(props)
const iframeRef = ref<HTMLIFrameElement | null>(null)
// const state = reactive({
//   baseUrl: '',
//   iframeHeight: '100%',
// })

onMounted(async () => {
  await nextTick()
  setIframeHeight()
})

const setIframeHeight = () => {
  if (height.value) {
    iframeRef.value!.style.height = height.value + 'px'
  }
}

const handleOnLoad = () => {
  emit('load')
}
</script>

<template>
  <div :class="['iframe-wrap']">
    <ClientOnly>
      <iframe
        v-if="url"
        ref="iframeRef"
        :src="url"
        class="iframe-box"
        @load="handleOnLoad"
      />
    </ClientOnly>
  </div>
</template>

<style lang="scss" scoped>
.iframe-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  overflow: auto;

  .iframe-box {
    display: block;
    width: 100%;
    height: 100%;
  }
}
</style>
