import type { BridgeMessage } from '@bridge/types'
import { BridgeException, bridgeExceptionStatus } from '@bridge/utils/exception'
import { messageToParse, messageToString } from '@bridge/utils/messageConverter'
import { useAppStore } from '@store'

/**
 * bridge handler iOS
 * @description WEB > APP(iOS)
 * @return Promise<'done'>
 */
export const handlerIOS = <P>({ key, message }: BridgeMessage<P>) => {
  // 1. iOS 에서 등록한 헨들러 호출 가능 여부 확인
  if (!window.webkit?.messageHandlers) {
    throw new BridgeException({
      message: 'bridge [window.webkit.messageHandlers] is not found',
      cause: {
        status: bridgeExceptionStatus.APP_HANDLER_NOT_FOUND,
      },
    })
  }

  // 2. iOS 브릿지 헨들러 호출
  try {
    const _message = messageToString({ key, message })
    window.webkit.messageHandlers[key].postMessage(_message)
  } catch {
    throw new BridgeException({
      message: `bridge handler key [${key}] is not found`,
      cause: {
        status: bridgeExceptionStatus.HANDLER_KEY_NOT_FOUND,
      },
    })
  }
}

/**
 * bridge handler AOS
 * @description WEB > APP(AOS)
 * @return Promise<'done'>
 */
export const handlerAOS = <P>({ key, message }: BridgeMessage<P>) => {
  // 1. AOS 에서 등록한 헨들러 호출 가능 여부 확인
  if (!window.handlerToAos) {
    throw new BridgeException({
      message: 'bridge window.handlerToAos is not found',
      cause: {
        status: bridgeExceptionStatus.APP_HANDLER_NOT_FOUND,
      },
    })
  }

  // 2. AOS 헨들러 호출
  try {
    const _message = messageToString({ key, message })
    window.handlerToAos[key](_message)
  } catch (err) {
    throw new BridgeException({
      message: `bridge handler key [${key}] is not found`,
      cause: {
        status: bridgeExceptionStatus.HANDLER_KEY_NOT_FOUND,
        data: err,
      },
    })
  }
}

/**
 * handler call app
 */
export const handlerToApp = <P>(message: BridgeMessage<P>) => {
  const { forceAppMode, appType } = useAppStore()
  // const { cookieNames, getCookie } = useCookies()
  // const appType = getCookie<AppType>(cookieNames.APP_TYPE)

  // 1. 강제 앱 모드
  if (forceAppMode) return

  // 2. 앱 헨들러 호출
  switch (appType) {
    case 'IOS':
      return handlerIOS(message)
    case 'AOS':
      return handlerAOS(message)
    default:
      throw new BridgeException({
        message: `bridge handler [${message.key}] app type(IOS | AOS) is not found`,
        cause: {
          status: bridgeExceptionStatus.APP_TYPE_NOT_FOUND,
        },
      })
  }
}

/**
 * handler call web
 */
export const handlerToWeb = <P>(message: string) => {
  const _message = messageToParse<P>(message)
  console.log('toWeb payload:', _message)

  return _message
}
