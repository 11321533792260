/**
 * Boolean: 'Y', 'N'
 */
export type BooleanYn =
  | 'Y' // 긍정
  | 'N' // 부정

/**
 * Boolean: '0', '1'
 */
export type BooleanCode =
  | '0' // 부정
  | '1' // 긍정

/**
 * 사용자 타입
 */
export type UserCodeType =
  | 'P' // 일반 사용자
  | 'M' // 관리자
  | 'C' // 크리에이터

/**
 * 사용자 타입
 */
export type UserCodeTypeSecond =
  | UserCodeType // 기본 사용자 타입
  | 'S' // playNomm등의 LMC관리 Account

/**
 * 사용자 구분
 */
export type CommunityUserType =
  | 'G' // 일반
  | 'C' // 크리에이터

/**
 * 사용자 구분 유니온 코드
 *
 *  관리자 여부 및 크리에이터 여부를 판단할 수 있는 Union 모델
 *  acntTyCode + cmtyUserSeCode
 *  'PG' // 관리자 X & 크리에이터 X
 *  'PC' // 관리자 X & 크리에이터 O
 *  'MG' // 관리자 O & 크리에이터 X
 *  'MC' // 관리자 O & 크리에이터 O
 */
export type UnionUserType = `${UserCodeType}${CommunityUserType}`

/**
 * 추천 타겟 코드
 */
export type RecommendTargetType = 'post' | 'comment'

/**
 * 추천 추천취소 코드
 */
export type RecommendType = 'recommend' | 'unRecommend'

/**
 * 미디어 유형 코드
 */
export type MediaType =
  | 'I' // Image
  | 'V' // Video
/**
 * 미디어 유형 코드 + M ( IMAGE + VIDEO )
 */
export type MediaTypeSecond =
  | MediaType // 기본 미디어 타입
  | 'M' // IMAGE + VIDEO

/**
 * 크리에이터 등급
 */
export type CreatorGrade =
  | '0000' // 일반
  | null // 일반
  | '0020' // 브론즈
  | '0040' // 실버
  | '0060' // 골드

/**
 * 크리에이터 등록 상태 코드(인증 진행 상태 코드)
 */
export type CreatorRegStatus =
  | 'RQ01' // 인증요청            // 심사중
  | 'RQ02' // 승인요청중, 심사중    // 심사중
  | 'RJCT' // 승인불가 완료       // 재심사 요청이 가능함
  | 'APRV' // 승인완료           // 크리에이터로 변경됨(신청완료된 상태)
  | 'EXPD' // 인증만료           // 크리에이터로 변경됨(신청완료된 상태)

/**
 * 크리에이터 조직 구분
 */
export type CreatorOrganizationCode =
  | 'ALL'
  | 'IND' // 개인 크리에이터
  | 'BIZ' // 기업/프로젝트 그룹
  | null // 크리에이터가 아님 일반 유저

/**
 * 크리에이터 알림 일반, 프리미엄콘텐츠 구분 코드
 */
export type NotificationType =
  | 'NOT_PLS' // 일반 사용자 대상 알림
  | 'PLS' // 프리미엄 콘텐츠 알림

/**
 * 약관 그룹 유형 코드
 */
export type StplatGroupTyCode =
  | 'PA' // 프리미엄 멤버십 개설
  | 'PS' // 사용자 프리미엄 멤버십 가입

/**
 * 프리미엄 멤버십 혜택 목록(크리에이터 사용자가 직접 지정)
 */
export type PrmbrshPgmCnListByCreator = string[]

/**
 * 펀드 지원 설문조사 상태 코드: 1(현재상태) + 3(진행상태) /
 */
export type FundProcessStatusCode =
  | 'SPRE' // 준비중
  | 'PROP' // 제안중
  | 'PUIV' // 심사중
  | 'VOTE' // 전시/투표중
  | 'VSCT' // 선정중
  | 'TRMN' // 종료

/**
 * 투표 상태 코드
 */
export type VoteStatusCode =
  | 'CVTA' // 투표
  | 'CVTC' // 투표취소
  | 'NONE' // 투표안함

/**
 * 크리에이터펀딩 서베이 현황 코드
 */
export type CrfdSurveySttusCode =
  | 'S' // 준비중
  | 'P' // 제안중/제안심사중
  | 'V' // 전시,투표중/선정중
  | 'T' // 종료

export type ReportType =
  | 'N' // 게시물
  | 'A' // 댓글

/**
 * 모든 페이징을 사용하는 리스트의 마지막 페이지 여부
 */
export interface InfinityScrollExtend {
  refresh: boolean
  last: boolean
  loading?: boolean
}

/**
 * 모든 페이징을 사용하는 리스트 기본 타입(인피니티 스크롤)
 */
export interface BaseInfinityScrollList<T, P> extends InfinityScrollExtend {
  items: T
  payload: P
}

/**
 * 모든 페이징을 사용하는 리스트 페이징 정보
 */
export interface PaginationExtend {
  currentPage: number // 페이지네이션 활성화 페이지
  itemPerPage: number // 페이지네이션 아이템 마다 보여줄 아이템 개수
  pagePerScope: number // 페이지네이션 버튼 개수
  totals: number // 모든 아이템 수
  pageStartIdx: number // 페이지네이션 시작 인덱스
}

/**
 * 모든 페이징을 사용하는 리스트 기본 타입(페이지 네이션)
 */
export interface BasePaginationList<T, P> {
  items: T
  payload: P
  pagination: PaginationExtend
}

/**
 * 비동기 상태를 관리 하기 위한 타입
 */
export interface AsyncState<T> {
  data: T
  loading: boolean
  error: boolean
}

/**
 * 결제 수단
 */
export type PayType =
  | 'AT' // 계좌이체
  | 'CC' // 신용카드

/**
 * 반응형 타입
 */
export type Responsive = 'mobile' | 'tablet' | 'desktop'

/**
 * 파일: 이미지
 */
export interface FileImage {
  contentType: string // 파일 contentType
  deleteAt: string // 삭제 여부 Y/N
  expsrOrdr: number // 정렬 순서
  fileDetailId: string // 파일 상세 ID
  fileExtention: string // 파일 확장자
  fileName: string // S3 파일명
  fileSize: number // 파일 크기
  originFileNm: string // 업로드 파일명
  s3Url: string // s3 결과 URL
  tempAt: BooleanYn // 임시 파일 여부
}

/**
 * 파일: 비디오
 */
export interface FileVideo {
  deleteAt: BooleanYn // 삭제 여부 Y/N
  originFileNm: string // 업로드 파일명
  // M3u8
  s3M3u8Url: {
    fileDetailId: string // 파일 상세 ID
    fileUrl: string // file url
  }
  // MP4
  s3Mp4Url: {
    fileDetailId: string // 파일 상세 ID
    fileUrl: string // file url
  }
  // 썸네일
  s3ThumnailUrl: {
    fileDetailId: string // 파일 상세 ID
    fileUrl: string // file url
  }
}

/**
 * 파일: 이미지(멀티)
 */
export interface FileImageContainer {
  fileId: string // 파일 아이디
  images: FileImage[] // 파일 업로드 결과

  // 아래의 모델은 API 쪽에서는 주지만 사용하지 않는다
  video: null
}

/**
 * 파일: 비디오
 */
export interface FileVideoContainer {
  fileId: string // 파일ID
  video: FileVideo // 파일 비디오

  // 아래의 모델은 API 쪽에서는 주지만 사용하지 않는다
  images: null
}

/**
 * 파일: 이미지, 비디오
 */
export interface FileContainer {
  fileId: string // 파일ID
  images: FileImage[] | null
  video: FileVideo[] | null
}

/**
 * 현재 사용자, 다른 사용자간 차단
 */
export interface BlockStatusModel {
  blockedAt: BooleanYn // 다른 사용자가 현재 사용자 차단 여부 Y/N
  blockingAt: BooleanYn // 현재 사용자가 다른 사용자 차단 여부 Y/N
}

/**
 * 차단 상태 확인 대상을 정의하는 열거형
 * @enum {number}
 */
export enum BlockingTarget {
  USER,
  CREATOR,
}

/**
 * 배너 코드
 */
export enum BannerCode {
  MAIN_UPPER = 'CMA1', // 메인상단
  PICK = 'CMA2', // Pick
  NOVA_PLUS = 'CRT1', // 노바+
  FUND = 'CLM1', // 펀드
  DAILY_CHECK = 'CMA3', // 출석체크
}

/**
 * 포스트 컨텐츠
 */
export enum PostContentsType {
  LIST = 'list',
  DETAIL = 'detail',
}

/**
 * 리플리이서 포스트 컨텐츠
 */
export enum ReplacePostContentsType {
  VIDEO = 'video',
  LINK = 'link',
}

/**
 * 오픈 그래프
 */
export type Og = {
  [Property in string]: string
}

/**
 * 공유 콘텐츠 타입
 */
export type ShareContentsType =
  | 'post' // 일반 포스트 상세
  | 'w3cf' // w3cf 상세
  | 'fundProject' // 펀드 이달의 프로젝트 상세

/**
 * 공유 타입
 */
export type ShareType =
  | 'clipboard'
  | 'facebook'
  | 'twitter'
  | 'kakao'
  | 'discord'

/**
 * 사용자 패널 아이디
 */
export type UserPanelId = 'gate' | 'settings' | 'account' | 'services'

/**
 * 시스템 패널 아이디
 */
export type SystemPanelId = 'gate' | 'language' | 'policies' | 'services'

/**
 * 메뉴 패널 아이디
 */
export type MenuPanelId = 'gate' | 'services'
/**
 * 옵셔널 페이로드
 */
export type OptionalPayload<T, K extends keyof T> = Omit<T, K> &
  Partial<Pick<T, K>>
