<script setup lang="ts">
import type { InputHTMLAttributes } from 'vue'
import type {
  NovaCreateHashtagTagInputEmits,
  NovaCreateHashtagTagInputProps,
} from './NovaCreateHashtagTagInput.types'
import { NovaCreateHashtagMenu } from '#components'

const emit = defineEmits<NovaCreateHashtagTagInputEmits>()
const props = withDefaults(defineProps<NovaCreateHashtagTagInputProps>(), {
  tagSet: () => [],
})

const { t } = useI18n()
const inputRef = ref<HTMLInputElement | null>(null)
const inputFocus = ref(false)
const choiceTagsIndex = ref<number | null>(null)
const menuItemRef = ref<(typeof NovaCreateHashtagMenu)[]>([])
const menuContainer = ref<HTMLElement | null>(null)

const state = reactive({
  tags: [],
  preValue: '',
  activeIndex: 0,
  inputValue: '',
})
const filterTags = computed(() =>
  state.inputValue
    ? props.hashtagSet.filter((item) => item.includes(state.inputValue))
    : []
)
// const showDropDown = computed(() => filterTags.value.length && inputFocus.value)
const showDropDown = computed(() => filterTags.value.length && state.inputValue)

watch(
  showDropDown,
  (cur) => {
    if (!cur) {
      choiceTagsIndex.value = null
    }
  },
  { immediate: true }
)

// Keyup 이벤트
const handleOnKeyUp = (evt: KeyboardEvent) => {
  state.inputValue = (evt.target as InputHTMLAttributes)?.value.trim()
  /**
   * 1. Enter & Spacebar
   *  - 현재 Null값인지 체크
   *    - Null 값이면 Not Action
   *    - Null 값아니면 등록 이벤트
   * 2. Key
   *  - Key 입력
   *    - 특수문자, 중복 태그 체크
   *  - DropDown 메뉴 필터 기능 작동
   * 3. Delete
   *  - 앞에 글자 유무
   *    - 글자 있을때, 글자 삭제
   *    - 글자 없을때, 태그 삭제
   */

  // 유효성 검사
  handleOnValidate()

  // Comma, Enter
  if (evt.keyCode === 188 || evt.keyCode === 13) {
    if (state.inputValue !== '') {
      choiceTagsIndex.value !== null
        ? emit('update', filterTags.value[choiceTagsIndex.value])
        : emit('update', state.inputValue)
    }
    // 초기화
    // (evt.target as InputHTMLAttributes).value = ''
    handleOnReset()
  }
  // backspace 입력
  else if (evt.keyCode === 8) {
    if (state.inputValue === '' && !state.preValue) {
      emit('delete')
    }
  }
  // 드롭다운 메뉴에서 해시태그 선택: increase
  else if (evt.keyCode === 40) {
    handleOnChoiceTag('increase')
  }
  // 드롭다운 메뉴에서 해시태그 선택: decrease
  else if (evt.keyCode === 38) {
    handleOnChoiceTag('decrease')
  }
}

const handleOnUpdateValue = () => {
  state.preValue = inputRef.value!.value
}

const handleOnValidate = () => {
  const target = inputRef.value!.value

  if (useCheckSpecialCharacters(target)) {
    handleOnReset()
    useToast(t('postCreate.toastMessage.validateHashtagCharacter'))
  } else if (useCheckByte(target, 60, 'over')) {
    handleOnReset()
    useToast(t('postCreate.toastMessage.validateHashtagCheckByte'))
  } else if (props.tagSet.length >= 5) {
    handleOnReset()
    useToast(t('postCreate.toastMessage.validateHashtagCount'))
  }
}

const clickOnMenu = (item: string) => {
  props.tagSet.length >= 5
    ? useToast(t('postCreate.toastMessage.validateHashtagCount:'))
    : emit('update', item)
  handleOnReset()
}

const handleOnReset = () => {
  state.inputValue = ''
  inputRef.value!.value = ''
}

const handleOnChoiceTag = (type: 'increase' | 'decrease') => {
  if (!showDropDown.value) return
  if (choiceTagsIndex.value === null) {
    choiceTagsIndex.value = 0
    return
  }
  if (choiceTagsIndex.value === filterTags.value.length - 1) {
    choiceTagsIndex.value = null
    return
  }

  switch (type) {
    case 'increase':
      choiceTagsIndex.value =
        (choiceTagsIndex.value % filterTags.value.length) + 1
      if (
        !useDetectElementInViewport(
          menuItemRef.value[choiceTagsIndex.value].$el,
          menuContainer.value
        )
      ) {
        menuItemRef.value[choiceTagsIndex.value].$el.scrollIntoView()
      }
      break
    case 'decrease':
      choiceTagsIndex.value =
        choiceTagsIndex.value - 1 > 0 ? choiceTagsIndex.value - 1 : 0
      if (
        !useDetectElementInViewport(
          menuItemRef.value[choiceTagsIndex.value].$el,
          menuContainer.value,
          'top'
        )
      ) {
        menuItemRef.value[choiceTagsIndex.value].$el.scrollIntoView()
      }
      break
    default:
      throw new Error('handle on choice tag type is not match')
  }
}

const handleOnInputFocusBlur = (focus: boolean) => {
  inputFocus.value = focus
}

// Input Keyup Event
onMounted(() => {
  inputRef.value!.addEventListener(
    'keyup',
    (evt: KeyboardEvent) => handleOnKeyUp(evt),
    true
  )
  inputRef.value!.addEventListener('keydown', handleOnUpdateValue, true)
})

onUnmounted(() => {
  if (inputRef.value) {
    inputRef.value!.removeEventListener('keyup', (evt: KeyboardEvent) =>
      handleOnKeyUp(evt)
    )
    inputRef.value!.removeEventListener('keydown', handleOnUpdateValue)
  }
})
</script>

<template>
  <div class="hashtag-input-box">
    <label class="hashtag-input-label">
      <input
        ref="inputRef"
        type="text"
        class="hashtag-input"
        :placeholder="$t('postCreate.placeholder.hashtag')"
        @keydown.space.prevent
        @focus="handleOnInputFocusBlur(true)"
        @blur="handleOnInputFocusBlur(false)"
      />
    </label>
    <div :class="['hashtag-menu-wrap', { on: showDropDown }]">
      <ul ref="menuContainer" class="hashtag-menu-box">
        <NovaCreateHashtagMenu
          v-for="(item, index) in filterTags"
          :ref="
            (el: typeof NovaCreateHashtagMenu) => {
              menuItemRef[index] = el
            }
          "
          :key="index"
          :index="index"
          :item="item"
          :active-index="choiceTagsIndex"
          @click="clickOnMenu(item)"
        />
      </ul>
    </div>
  </div>
</template>

<style lang="scss">
.hashtag-input-box {
  position: relative;
  margin-left: 8px;
  flex-grow: 1;
  min-width: 50%;

  .hashtag-input-label {
    display: block;
    width: 100%;
    border-radius: 8px;
  }

  .hashtag-input {
    width: 100%;

    &::placeholder {
      color: $color-text-3;
    }
  }
}

.hashtag-menu-wrap {
  position: absolute;
  bottom: 40px;
  left: 0;
  width: 100%;
  height: auto;
  opacity: 0;
  @include transition(all 0.2s ease-in-out);
  pointer-events: none;
  z-index: $z-index-drop-down;

  &.on {
    opacity: 1;
    pointer-events: auto;
  }

  .hashtag-menu-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 150px;
    padding: 4px 0;
    border: 1px solid $color-black-5p;
    border-radius: 5px;
    background-color: #ffffff;
    overflow: auto;
  }
}
</style>
