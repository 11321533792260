import { DAY, HOUR, MINUTE, SECOND } from '@configs'

interface Cookie {
  name: string
  value: any
}

const useCookies = () => {
  // 쿠키 이름
  const cookieNames = {
    AUTH_TOKEN: 'authToken',
    USER_INFO: 'userInfo',
    SEARCHES: 'searches',
    OFF_NOTI_PREMIUM_EXPIRED: 'offNotiPremiumExpired',
    APP_TYPE: 'appType', // 앱 타입
    CURRENT_VERSION: 'currentVer', // 앱 현재 버전
    APP_BUILD_NO: 'appBuildNo', // 앱 빌드 넘버
    FCM_TOKEN_STATUS: 'fcmTokenStatus', // fcm 초기화 정보(fcmDevice, fcmAppToken, fcmWebToken)
    UN_SIGNIN_MARKETING_PUSH_AGREE: 'unSigninMarketingPushAgree', // 비 로그인 상태에서 마케팅 푸시 동의 여부
    REWARD_MONTHLY: 'rewardMonthly',
    NOTIFICATION_PERMISSION: 'notificationPermission',
  }

  // 쿠키 맵핑하여 가져오기
  const cookies = (cookie?: string): Cookie[] => {
    // const calcTarget = cookie || document?.cookie || ''
    let calcTarget = ''

    // 서버 사이드에서 request header 의 cookie를 참고하기 위한 직접 예외처리
    if (process.server) {
      calcTarget = cookie || ''
    }

    // 클라이언트 사이드에서 document.cookie 직접접근
    else {
      calcTarget = document.cookie
    }

    return calcTarget.split(';').map((cookie) => {
      const cookieSplit = cookie.split('=')

      return {
        name: cookieSplit[0]?.replace(' ', '') || '',
        value: cookieSplit[1] || null,
      }
    })
  }

  // 쿠키 가져오기
  const getCookie = <T = any>(
    cookieName: string,
    cookie?: string
  ): T | null => {
    // console.log(
    //   `CALL GET COOKIE FROM ${process.server ? '["SERVER"]' : '["CLIENT"]'} :`,
    //   cookie
    // )
    const findTarget = cookie ? cookies(cookie) : cookies()
    const value = findTarget.find((cookie) => cookie.name === cookieName)?.value

    if (!value) return null

    if (value === 'undefined') return null

    if (value === 'null') return null

    try {
      return JSON.parse(value)
    } catch (err) {
      return value
    }
  }

  // 쿠키 설정하기
  const setCookie = <T = any>(
    name: string,
    value: T,
    expires: number,
    expireUnitType:
      | 'days'
      | 'times'
      | 'minutes'
      | 'seconds'
      | 'milliseconds'
      | 'direct' = 'days'
  ) => {
    if (process.server) return

    const date = new Date()
    switch (expireUnitType) {
      case 'days':
        date.setTime(date.getTime() + expires * DAY)
        break
      case 'times':
        date.setTime(date.getTime() + expires * HOUR)
        break
      case 'minutes':
        date.setTime(date.getTime() + expires * MINUTE)
        break
      case 'seconds':
        date.setTime(date.getTime() + expires * SECOND)
        break
      case 'milliseconds':
        date.setTime(date.getTime() + expires)
        break
      case 'direct':
        date.setTime(expires)
        break
      default:
        break
    }

    if (name === 'appType') {
      document.cookie = `${name}=${value}`
      return
    }

    document.cookie = `${name}=${
      typeof value === 'string' ? value : JSON.stringify(value)
    };expires=${date.toUTCString()};path=/`
  }

  // 쿠키 삭제
  const deleteCookie = (name: string) => {
    const date = new Date()
    document.cookie = `${name}= ;expires=${date.toUTCString()};path=/`
  }

  return {
    cookieNames,
    cookies,
    getCookie,
    setCookie,
    deleteCookie,
  }
}

export default useCookies
