<script setup lang="ts">
import type { NovaKycContentTryYetProps } from './NovaKycContentTryYet.types'
import type { IconType } from '@components/NovaIcon/NovaIcon.types'

defineProps<NovaKycContentTryYetProps>()

const { t } = useI18n()
const guids = computed<{ icon: IconType; label: string }[]>(() => [
  {
    icon: { type: 'outline', name: 'credit-card' },
    label: t('kyc.unAuthKyc.tryYet.verifyKycProcess.readyId'),
  },
  {
    icon: { type: 'outline', name: 'user-square-smooth' },
    label: t('kyc.unAuthKyc.tryYet.verifyKycProcess.faceCapture'),
  },
  {
    icon: { type: 'outline', name: 'clock' },
    label: t('kyc.unAuthKyc.tryYet.verifyKycProcess.processingTime'),
  },
])
const cs = computed(() =>
  t('kyc.unAuthKyc.cs', {
    cs: `<a class="text-color-primary-blue" href="mailto:${t(
      'corporationInfo.infos.email'
    )}">${t('corporationInfo.infos.email')}</a>`,
  })
)
</script>

<template>
  <div class="guide">
    <div
      v-dompurify-html="t(`kyc.unAuthKyc.tryYet.primaryCopy.${purpose}`)"
      class="primary-copy"
    />

    <div
      v-dompurify-html="t('kyc.unAuthKyc.tryYet.secondaryCopy')"
      class="secondary-copy"
    />

    <div class="guid-body">
      <p class="guide-title">{{ t('kyc.unAuthKyc.tryYet.howToVerifyKyc') }}</p>

      <div class="guids">
        <div v-for="(guide, index) in guids" :key="index" class="guide-item">
          <NovaIcon :icon="guide.icon" />
          <span class="label">{{ guide.label }}</span>
        </div>
      </div>
    </div>

    <p class="description">{{ t('kyc.unAuthKyc.tryYet.verifyAfter') }}</p>
    <p v-dompurify-html="cs" class="description mg-t-3u" />
  </div>
</template>

<style lang="scss" scoped>
.guide {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 12px;

  .primary-copy {
    @include text-style($text-display-medium);
    color: $color-black;
  }

  .secondary-copy {
    @include text-style($text-body-10-medium);
    color: $color-text-3;
    line-height: 1.2;
  }

  .guid-body {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-top: 12px;

    .guide-title {
      @include text-style($text-display-medium);
      color: $color-primary-blue;
    }

    .guids {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .guide-item {
        display: flex;
        align-items: center;
        gap: 6px;
        padding: 12px;
        border-radius: 6px;
        background-color: $color-gray-1;

        .label {
          @include text-style($text-body-12-regular);
          color: $color-text-2;
        }
      }
    }
  }

  .description {
    @include text-style($text-body-10-regular);
    color: $color-text-2;
  }
}
</style>
