<script setup lang="ts">
import type { RouteParams } from 'vue-router'
import type { PostActionPanelShareProps } from '@components/NovaBoxPostActionSharePanel/NovaBoxPostActionSharePanel.types'

defineOptions({
  inheritAttrs: false,
})

const show = ref(false)
const shareActionProps = ref<PostActionPanelShareProps | null>(null)
const { hide: modalHide } = useModal()

// 팝업 닫기
const handleOnClose = async () => {
  await modalHide(modalsName.MODAL_SHARE_ACTION)
}

// 공유하기 액션 모델 받기
const handleOnGetParams = (evt: RouteParams) => {
  shareActionProps.value = evt.value as unknown as PostActionPanelShareProps
}
</script>

<template>
  <VueFinalModal
    v-model="show"
    :name="modalsName.MODAL_SHARE_ACTION"
    :content-class="'modal-content'"
    classes="modal-container"
    @before-open="(evt: any) => handleOnGetParams(evt.ref.params)"
    @click-outside="handleOnClose"
  >
    <ClientOnly>
      <div v-if="show" class="action-sheet">
        <div class="inner">
          <a
            href="javascript:void(0);"
            class="btn-close"
            @click="handleOnClose"
          />

          <NovaBoxPostActionSharePanel
            class="share"
            :cmty-ntt-sn="shareActionProps?.cmtyNttSn"
            :post-title="shareActionProps?.postTitle"
            :post-contents="shareActionProps?.postContents"
            :share-count="shareActionProps?.shareCount"
            @close="handleOnClose"
          />
        </div>
      </div>
    </ClientOnly>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
.action-sheet {
  position: relative;
  z-index: $z-index-action-sheet;

  > .inner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 20;
    padding: 0 0 30px 0;
    border-radius: 12px 12px 0 0;
    width: 100%;
    background: $color-bg-3;

    .btn-close {
      display: block;
      position: relative;
      width: 100%;
      height: 30px;
      text-indent: -999999px;

      &:before {
        display: block;
        content: '';
        width: 80px;
        height: 3px;
        background-color: rgba(0, 0, 0, 0.15);
        border-radius: 3px;
        position: absolute;
        top: 12px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .share {
      :deep(.share-item) {
        height: 48px;

        .label {
          @include text-style($text-body-14-medium);
        }
      }
    }
  }
}
</style>
