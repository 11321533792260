import type { AxiosInstance } from 'axios'
import { setTokenToRequestHeader, setMultipleRequestRefreshing } from './utils'

export const setJwtInterceptor = (instance: AxiosInstance) => {
  // request interceptor
  setTokenToRequestHeader(instance)

  // response interceptor
  setMultipleRequestRefreshing(instance)
}
