<template>
  <div class="live-badge">
    LIVE<NovaIcon :icon="{ type: 'outline', name: 'live' }" :size="16" />
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.live-badge {
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  color: $color-white;
  font-size: 16px;
  font-weight: 500;
  height: 28px;
  padding: 0 3px 0 4px;
  background-color: $color-bg-custom-9;
  border-radius: 5px;
}
</style>
