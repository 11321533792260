/**
 * Cleans the provided URL by removing the base APP_URL and any leading slash.
 *
 * @param {string} url - The URL to be cleaned.
 * @param {string} appUrl - The base APP_URL to be removed from the URL.
 * @returns {string} The cleaned URL.
 */
const cleanUrl = (url: string, appUrl: string): string => {
  // Remove leading slash
  return url
    .replace(appUrl, '') // Remove the base APP_URL from the provided url
    .replace(/^(\/)/, '')
}

/**
 * Returns the formatted deeplink entry URL for the given URL.
 *
 * @param {string} url - The URL to be formatted.
 * @returns {string} The formatted deeplink entry URL.
 */
export const useDeeplinkEntry = (url: string): string => {
  const config = useRuntimeConfig() // get runtime configuration
  const cleanedUrl = cleanUrl(url, config.public.APP_URL) // clean the given url
  // format and return the deeplink entry url
  // return `${config.public.DEEPLINK_ENTRY_URL}#${cleanedUrl}`
  // TODO: 2안 나오면 다시 수정 해야 함.
  // 2024-05-30 버그 픽스하면서 1안으로 딥링크 포기
  // 딥링크 사용하게 되면 env에서 DEEPLINK_ENTRY_URL 수정 필요
  return `${config.public.DEEPLINK_ENTRY_URL}/${cleanedUrl}`
}
