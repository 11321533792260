<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import type { SwiperOptions } from 'swiper/types'
import type { NovaTabEmits, NovaTabProps, TabData } from './NovaTab.types'

const { gtEvent } = useGoogleTag()

const emit = defineEmits<NovaTabEmits>()
const props = withDefaults(defineProps<NovaTabProps>(), {
  initTab: '',
  theme: 'blue',
  size: 62,
  align: 'left',
  horizonPadding: 0,
})
const { initTab, tabValue, align, size } = toRefs(props)
const route = useRoute()
const tabs = ref<HTMLElement | null>(null)
const swiper = ref<typeof Swiper | null>(null)
const indicator = ref<HTMLElement | null>(null)
const initIndex = computed(() =>
  initTab.value
    ? tabValue.value.findIndex((item) => item.id === initTab.value)
    : 0
)
const swiperBreakpoint = computed(() => {
  if (align.value === 'center') return {}

  return size.value === 62
    ? { 0: { spaceBetween: 20 }, 960: { spaceBetween: 50 } }
    : ({ 0: { spaceBetween: 20 } } as SwiperOptions['breakpoints'])
})
const currentTab = ref<TabData<string | number>>(
  tabValue.value[
    initTab.value
      ? tabValue.value.findIndex((item) => item.id === initTab.value)
      : 0
  ]
)

// change: set indicator position
watch(
  () => [currentTab.value, route.name],
  async () => {
    await nextTick()
    setIndicator()
  }
)

// change: initTab
watch(
  () => initTab.value,
  (cur) => {
    const changeToIdx = tabValue.value.findIndex((item) => item.id === cur)
    if (changeToIdx !== -1) currentTab.value = tabValue.value[changeToIdx]
  },
  { immediate: false }
)

// mounted:resize: set indicator position
onMounted(() => {
  setTimeout(() => setIndicator(), 200)
  window.addEventListener('resize', () => setIndicator())
})

// unmounted:resize: remove indicator position
onUnmounted(() => {
  window.removeEventListener('resize', () => setIndicator())
})

// set indicator
const setIndicator = (onTransition = true) => {
  if (!swiper.value || !tabs.value || !indicator.value) return

  const tabsRect = tabs.value!.getClientRects()[0]

  const tabRect = swiper.value.el
    .querySelector('.tab.on .tab-button')
    .getClientRects()[0]
  const offset = { left: tabRect.left - tabsRect.left }

  tabs.value!.classList[onTransition ? 'add' : 'remove']('on-transition')
  indicator.value!.style.left = `${offset.left}px`
  indicator.value!.style.width = `${tabRect.width}px`
}

// init swiper
const handleOnSetSwiper = (evt: typeof Swiper) => {
  swiper.value = evt
  setIndicator()
}

// change tab on
const handleOnTabClick = (tab: TabData<string | number>) => {
  currentTab.value = tab
  const label =
    tab.id === 'subscribeGrade'
      ? useKoreanTranslation('subscribe.subscribeGrade.novaPlus')
      : tab.name
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '메뉴 탭 클릭',
    eventLabel: `${label}`,
    eventSlot: '',
    eventI18nAddr: `${label}`,
    eventComponent: 'SwiperSlide',
  })
  emit('onChangeTab', tab)
}
</script>

<template>
  <div class="tabs-container">
    <div
      ref="tabs"
      :class="['tabs', `theme-${theme}`, `size-${size}`, `align-${align}`]"
    >
      <ClientOnly>
        <Swiper
          class="swiper"
          :initial-slide="initIndex"
          :slides-per-view="'auto'"
          :breakpoints="swiperBreakpoint"
          :style="{
            paddingLeft: `${horizonPadding}px`,
            paddingRight: `${horizonPadding}px`,
          }"
          @swiper="handleOnSetSwiper"
          @touch-move="() => setIndicator(false)"
          @touch-end="() => setIndicator(true)"
          @transition-end="() => setIndicator(true)"
        >
          <SwiperSlide
            v-for="item in tabValue"
            :key="item.id"
            :class="['tab', { on: currentTab.id === item.id }]"
          >
            <button
              type="button"
              class="tab-button"
              @click="() => handleOnTabClick(item)"
            >
              <span v-dompurify-html="item.name" class="label" />
            </button>
          </SwiperSlide>
        </Swiper>
        <span ref="indicator" class="indicator" />
      </ClientOnly>
    </div>
  </div>
</template>

<style lang="scss">
.tabs-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .tabs {
    position: relative;
    display: flex;

    &.on-transition {
      .indicator {
        @include transition(left 0.2s ease-in-out, width 0.2s ease-in-out);
      }
    }

    &.theme {
      &-blue {
        .swiper .tab.on .tab-button > .label {
          color: $color-primary-blue;
        }

        .indicator {
          background-color: $color-primary-blue;
        }
      }

      &-red {
        .swiper .tab.on .tab-button > .label {
          color: $color-secondary-red-light-80;
        }

        .indicator {
          background-color: $color-secondary-red-light-80;
        }
      }

      &-green {
        .swiper .tab.on .tab-button > .label {
          color: $color-primary-green;
        }

        .indicator {
          background-color: $color-primary-green;
        }
      }

      &-yellow {
        .swiper .tab.on .tab-button > .label {
          color: $color-primary-yellow;
        }

        .indicator {
          background-color: $color-primary-yellow;
        }
      }

      &-orange {
        .swiper .tab.on .tab-button > .label {
          color: $color-primary-orange;
        }

        .indicator {
          background-color: $color-primary-orange;
        }
      }

      &-purple {
        .swiper .tab.on .tab-button > .label {
          color: $color-primary-purple;
        }

        .indicator {
          background-color: $color-primary-purple;
        }
      }

      &-board {
        .swiper .tab .tab-button {
          border-radius: 24px;
          background-color: $color-bg-1;
          padding: 0 20px;
        }
        .swiper .tab.on .tab-button {
          background-color: $color-primary-orange;
        }
        .swiper .tab .tab-button > .label {
          color: $color-text-3;
        }
        .swiper .tab.on .tab-button > .label {
          color: $color-white;
        }

        .indicator {
          display: none;
        }
      }
    }

    &.size {
      //  ? { 0: { spaceBetween: 20 }, 960: { spaceBetween: 50 } }
      //: { 0: { spaceBetween: 20 } } as SwiperOptions['breakpoints']

      &-40 {
        height: 40px;

        &.align-center {
          .tab {
            padding-left: 10px;
            padding-right: 10px;
          }
        }
      }

      &-62 {
        height: 62px;

        &.align-center {
          .tab {
            padding-left: 25px;
            padding-right: 25px;

            @include mobile {
              padding-left: 10px;
              padding-right: 10px;
            }
          }
        }
      }
    }

    &.align {
      &-left {
        .swiper-wrapper {
          justify-content: flex-start;
        }
      }

      &-center {
        .swiper-wrapper {
          display: inline-flex;
          width: auto;
          //justify-content: center;
        }
      }

      &-right {
        .swiper-wrapper {
          justify-content: flex-end;
        }
      }
    }

    .swiper {
      width: 100%;

      .tab {
        width: auto;

        &.on {
          .tab-button > .label {
            color: $color-secondary-red-light-80;
          }
        }

        .tab-button {
          display: inline-flex;
          align-items: center;
          height: 100%;

          &.on {
            > .label {
              color: $color-secondary-red-light-80;
            }
          }

          > .label {
            @include text-style($text-display-bold);
            color: $color-text-2;
            @include transition(color 0.2s ease-in-out);
          }
        }
      }
    }

    .indicator {
      position: absolute;
      top: calc(100% - 2px);
      height: 2px;
      background-color: $color-secondary-red-light-80;
    }
  }
}

.creator-mypage {
  .tabs-container {
    .tabs.size-62 {
      height: 58px;
      &.align-center {
        .tab {
          padding: 0 14px;
        }
      }
    }
  }
}
</style>
