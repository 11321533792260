/**
 * 크리에이터 등급
 */
export const w3cf = {
  topBanner: {
    title: 'Web3 <br />Creator Festival',
    date: 'Appraisal Period 8/9 ~ 8/31',
  },
  allView: 'Total',
  publicPart: 'Ordinary Sector',
  studentPart: 'Student Sector',
  notice: 'Notice',
  search: {
    title: '* The order of the nominee works is randomly exposed',
    placeholder: 'Search for Web3 Creator Festival nominee works',
  },
  displayCount: 'Hits',
  w3cfError:
    'Unable to retrieve the list of nominee works. Please try again later.',
  w3cfEmpty: 'There is no nominee list.',
  w3cfLike: {
    fail: "It can't be liked. Please try again later.",
    success: 'Liked.',
  },
  w3cfUnlike: {
    fail: 'Unable to cancel the like. Please try again later.',
    success: 'Like has been canceled.',
  },
  w3cfLikeOrUnlikeLock: 'The voting period has passed.',
  w3cfDetailError:
    'Unable to retrieve the nominee works. Please try again later.',
  w3cfDetainNavigationBack: 'View list',
  abusingNotify:
    '[Announcement] We are monitoring abusing movements for fair voting. Please check the notice for details.',
  contestWinnersNotify:
    '[Announcement] Web3 Creator Festival 2023 Contest Winners Announcement.',
}
