/**
 * 디바운스 버전의 함수를 생성
 *
 * @param {Function} fn - 디바운스되는 함수
 * @param {number} delay - 디바운스 함수가 호출되기 전까지의 밀리초 단위의 지연 시간
 * @returns {Function} - 입력 함수의 디바운스 버전
 */
export const useDebounce = <T extends (...args: any[]) => any>(
  fn: T,
  delay: number
): ((...args: Parameters<T>) => void) => {
  let timeoutId: NodeJS.Timeout | null = null
  return (...args: Parameters<T>) => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(() => fn(...args), delay)
  }
}
