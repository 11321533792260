<template>
  <div
    ref="target"
    :class="[
      'drop-down-wrap',
      { on },
      directionClass,
      themeClass,
      `align-${align}`,
      `size-${size}`,
      { 'block-choice': blockChoice },
      { loading: isLoading },
    ]"
  >
    <div v-show="type === 'select'" class="drop-down" @click="handleOnToggle">
      <span class="select-text">
        {{ activeName || activeLabel || placeholder }}
      </span>

      <span v-if="showArrow" class="icon">
        <NovaIcon
          :icon="{ type: 'outline', name: 'chev-compact-bottom' }"
          :size="20"
        />
      </span>
    </div>

    <div
      v-show="type === 'button'"
      class="drop-down-action"
      @click="handleOnToggle"
    >
      <slot name="action" />
    </div>

    <div v-show="menus.length > 0" class="drop-down-menu-wrap">
      <div class="drop-down-menu">
        <NovaDropdownItem
          v-for="(item, index) in menus"
          :key="index"
          :item="item"
          :active-value="activeValue"
          class="drop-down-menu-item"
          @update="handleOnUpdate"
        />
      </div>
    </div>

    <NovaLoadingIndicator
      v-if="isLoading"
      :overlay="true"
      :bg-bright="'light'"
    />
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import type { NovaDropdownEmits, NovaDropdownProps } from './NovaDropdown.types'

const emit = defineEmits<NovaDropdownEmits>()
const props = withDefaults(defineProps<NovaDropdownProps>(), {
  direction: 'bottom',
  placeholder: '',
  activeValue: '',
  activeName: '',
  type: 'select',
  theme: 'default',
  showArrow: true,
  align: 'left',
  size: 40,
  blockChoice: false,
  isLoading: false,
})
const target = ref(null)
const on = ref<boolean>(false)
const activeLabel = computed(
  () =>
    props.menus.find((item) => item.value === props.activeValue)?.label ||
    props.menus
      .find((menu) =>
        menu.children?.some((item) => item.value === props.activeValue)
      )
      ?.children?.find((item) => item.value === props.activeValue)?.label ||
    ''
)
const directionClass = computed(() => `drop-down-${props.direction}`)
const themeClass = computed(() => `theme-${props.theme}`)

const handleOnUpdate = (value: string | number) => {
  if (props.blockChoice) return
  emit('update', value)
  on.value = false
}
const handleOnToggle = () => {
  on.value = !on.value
}

onClickOutside(target, () => {
  on.value = false
})
</script>

<style lang="scss" scoped>
.drop-down-wrap {
  position: relative;
  display: flex;
  flex-shrink: 0;
  width: 100%;
  height: auto;

  &.loading {
    pointer-events: none;
  }

  &.drop-down-bottom {
    .drop-down-menu-wrap {
      top: 100%;
      padding-top: 8px;
      transform: translateY(24px);
    }
  }

  &.drop-down-top {
    .drop-down-menu-wrap {
      bottom: 100%;
      padding-bottom: 8px;
      transform: translateY(calc(100% + 24px * -1));
    }
  }

  &:hover {
    .drop-down {
      border-color: $color-primary-blue;
    }
  }

  &:active {
    .drop-down {
      color: $color-black-90p;
      border-color: $color-primary-blue;
    }
  }

  &.on {
    .drop-down {
      color: $color-black-90p;
      border-color: $color-primary-blue;

      > .icon {
        transform: rotate(180deg);
        color: $color-primary-blue;
      }
    }

    .drop-down-menu-wrap {
      opacity: 1;
      transform: translateY(0);
      pointer-events: auto;
    }
  }

  &.align {
    &-left {
      .drop-down {
        justify-content: left;
      }
    }

    &-center {
      .drop-down {
        justify-content: center;
      }
    }

    &-right {
      .drop-down {
        justify-content: right;
      }
    }
  }

  &.block-choice {
    .drop-down-menu-wrap .drop-down-menu .drop-down-menu-item {
      cursor: default;
    }
  }

  .drop-down {
    position: relative;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    height: 40px;
    width: 100%;
    padding: 0 10px;
    font-size: 14px;
    color: $color-text-3;
    border-radius: 5px;
    border: 1px solid $color-ed;
    cursor: pointer;
    background-color: #ffffff;
    transition: all 0.2s;

    .select-text {
      flex-grow: 1;
      @include ellipsis(1);
    }

    > .icon {
      flex-shrink: 0;
      font-size: 0;
      line-height: 0;
      color: $color-text-2;
      @include transition(transform 0.15s ease-in-out);

      > i {
        font-size: 20px;
      }
    }
  }

  .drop-down-menu-wrap {
    position: absolute;
    left: 0;
    width: 100%;
    height: auto;
    opacity: 0;
    @include transition(all 0.2s ease-in-out);
    pointer-events: none;
    z-index: $z-index-drop-down;

    .drop-down-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      max-height: 260px;
      padding: 4px 0;
      border: 1px solid $color-black-5p;
      border-radius: 5px;
      background-color: #ffffff;
      overflow-y: overlay;
    }
  }

  &.size {
    &-32 {
      .drop-down {
        @include text-style($text-body-12-regular);
        height: 32px;
      }
    }

    &-40 {
      .drop-down {
        @include text-style($text-body-14-regular);
        height: 40px;
      }
    }
  }

  &.theme-dark {
    .drop-down {
      height: 30px;
      border: 0;
      background-color: $color-bg-1;
    }

    .drop-down-menu {
      border: 0;
      box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.08);
    }
  }
}
</style>
