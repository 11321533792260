import type { Post, PostsItem } from '@store'

/**
 * 게시물 내용에서 3개의 문단과 이미지 URL을 추출
 *
 * @param {string} postContents - 추출할 게시물 내용
 * @returns {object} - 추출된 설명과 썸네일을 포함하는 객체
 */
export const useExtractionPostContents = (
  postContents: Post['nttCn'] | PostsItem['nttCn']
): { description: string; thumbnail: string } => {
  const descriptions = useGetMaxThreeParagraphTexts(postContents || '')
  const firstImgUrl = useGetFirstImageSource(postContents || '')

  return {
    description: descriptions || '',
    thumbnail: firstImgUrl || '',
  }
}
