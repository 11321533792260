/**
 * 크리에이터 등급
 */
export const w3cf = {
  topBanner: {
    title: 'Web3 <br />Creator Festival',
    date: '심사기간 8/9 ~ 8/31',
  },
  allView: '전체',
  publicPart: '일반인 부문',
  studentPart: '학생 부문',
  notice: '공지사항',
  search: {
    title: '* 후보 작품의 순서는 랜덤으로 노출됩니다',
    placeholder: 'Web3 Creator Festival 후보작 검색',
  },
  displayCount: '조회수',
  w3cfError: '후보작 목록을 가져올 수 없습니다. 잠시 후 다시 시도해 주세요.',
  w3cfEmpty: '후보작 묵록이 없습니다.',
  w3cfLike: {
    fail: '좋아요 할 수 없습니다. 잠시 후 다시 시도해 주세요.',
    success: '좋아요 하였습니다.',
  },
  w3cfUnlike: {
    fail: '좋아요를 취소 할 수 없습니다. 잠시 후 다시 시도해 주세요.',
    success: '좋아요를 취소 하였습니다.',
  },
  w3cfLikeOrUnlikeLock: '투표 할 수 있는 기간이 지났습니다.',
  w3cfDetailError: '후보작을 조회 할 수 없습니다. 잠시 후 다시 시도해 주세요.',
  w3cfDetainNavigationBack: '목록 보기',
  abusingNotify:
    '[안내] 공정한 투표를 위해 부정행위 모니터링을 진행 중입니다. 자세한 내용은 공지사항을 참고해주세요.',
  contestWinnersNotify: '[안내] Web3 Creator Festival 2023 공모전 수상작 발표',
}
