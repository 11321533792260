/**
 * 크리에이터 메인 프로필
 */
export const creatorMainProfile = {
  editMainProfile: '메인 프로필 설정',
  profileImageGuide: '메인 홈의 프로필 이미지를 변경합니다.',
  categoryGuide: '메인 홈에 표기하는 카테고리를 변경합니다.',
  updateCreatorCategoryFail:
    '카테고리를 변경할 수 없습니다. 다시 시도해 주세요.',
  updateCreatorCategorySuccess: '카테고리를 {categoryName}으로 변경하였습니다.',
}
