import { commonError } from './commonError'

/**
 * KYC 인증 관련
 */
export const kyc = {
  // GNB 사용자 패널 KYC 인증 섹션
  userKycInfo: {
    certified: 'KYC Verification',
    certifiedAction: {
      yet: 'Verify Now',
      yetSimple: 'Verify',
      done: 'Completed',
    },
  },
  // kyc 상태 조회
  checkKycStatus: {
    error: {
      4003: '확인 할 수 없는 사용자입니다.', // 토큰 정보와 일치하지 않는 공통 일련번호 입니다.
      default: commonError.network.message,
    },
  },
  // certifiedKyc
  unAuthKyc: {
    title: 'Verify',
    cs: 'If KYC is not completed, please contact {cs}.',

    // NEW
    // 인증하기
    tryYet: {
      primaryCopy: {
        service:
          'If you want to check <strong class="text-color-primary-orange">your rewards</strong>,<br />please <strong class="text-color-primary-blue">complete KYC</strong>.',
        reward:
          'If you want to use <strong class="text-color-primary-orange">your rewards</strong>,<br />please <strong class="text-color-primary-blue">complete KYC</strong>.',
        donate:
          'If you want to use <strong class="text-color-primary-orange">donate</strong>,<br />please <strong class="text-color-primary-blue">complete KYC</strong>.',
      },
      secondaryCopy:
        '<p class="text-color-primary-blue mg-b-1u">What is KYC?</p><p>KYC is an abbreviation for Know Your Client, a verification method used by financial institutions to confirm the identity of their customers.</p>',
      howToVerifyKyc: 'How to do KYC?',
      verifyKycProcess: {
        readyId: 'Prepare your identity',
        faceCapture: 'Take a photo of your face to verify',
        processingTime: 'The process takes about 10 min!',
      },
      verifyAfter: 'Once the KYC completed, your information will be updated.',
      actions: {
        negative: 'Cancel',
        positive: 'Verify',
      },
    },

    // 승인거절
    reject: {
      primaryCopy:
        'Please retry <strong class="text-color-primary-blue">KYC verification.</strong>',
      secondaryCopy: {
        service:
          'If the <strong class="text-color-primary-blue">process is not completed</strong>,<br />you can’t use <strong class="text-color-primary-orange">the service</strong>.',
        reward:
          'If the <strong class="text-color-primary-blue">process is not completed</strong>,<br />you can’t check <strong class="text-color-primary-orange">your rewards</strong>.',
        donate:
          'If the <strong class="text-color-primary-blue">process is not completed</strong>,<br />you can’t make a <strong class="text-color-primary-orange">donation</strong>.',
      },
      rejectReason: 'KYC Rejection Reason',
      loadRejectReason: 'Load Rejection Reason',
      actions: {
        negative: 'Cancel',
        positive: 'Retry',
      },
    },

    // 중복
    duplicate: {
      primaryCopy: 'It’s duplicated KYC verification.',
      secondaryCopy:
        'KYC verification cannot be completed as it has already been done with another account.',
      guide:
        '※ After signing out, you can find your existing ID at ‘Forgot ID(E-mail)?’',
      confirm: 'Confirm',
    },

    // 진행중
    pending: {
      primaryCopy:
        'The <strong class="text-color-primary-blue">KYC verification process</strong> is currently in progress.',
      secondaryCopy: {
        service:
          'If the <strong class="text-color-primary-blue">process is completed</strong>,<br />you can use <strong class="text-color-primary-orange">service</strong>.',
        reward:
          'If the <strong class="text-color-primary-blue">process is completed</strong>,<br />you can check <strong class="text-color-primary-orange">your rewards</strong>.',
        donate:
          'If the <strong class="text-color-primary-blue">process is completed</strong>,<br />you can make a <strong class="text-color-primary-orange">donation</strong>.',
      },
      guide: 'Once the KYC completed, your information will be updated!',
      confirm: 'Confirm',
    },
  },
}
