import * as cheerio from 'cheerio'
import { useGetOpenGraphs } from './useGetOpenGraphs'
import type { Og } from '@store'

/**
 * 주어진 URL에 대한 Open Graph 데이터를 검색하고 Open Graph 내용을 표시하는 HTML 템플릿을 반환
 *
 * @param {string} url - Open Graph 데이터를 검색할 URL
 * @param {boolean} errException - true인 경우 오류가 발생하면 빈 Open Graph 템플릿 반환. 그렇지 않으면 Promise가 오류로 거부
 * @returns {Promise<string>} - Open Graph 내용을 표시하는 HTML 템플릿
 */
export const useCreateOpenGraph = async (
  url: string,
  errException: boolean
): Promise<string> => {
  const commStore = useCommStore()
  // const template = (og: Og) => `
  //   <a class="og-preview" href="${og.url}" target="_blank">
  //     <sapn class="og-image"><span class="image" style="background-image: url('${og.image}')"></span></sapn>
  //     <span class="og-contents">
  //       <span class="og-url">${og.url}</span>
  //       <span class="og-title">${og.title}</span>
  //       <span class="og-description">${og.description}</span>
  //     </span>
  //   </a>
  // `
  const ogPreview = (og: Og) => {
    // create root of HTML
    const dom = cheerio.load('')

    // create anchor tag
    const anchor = dom('<a>')
      .addClass('og-preview')
      .attr('href', og.url)
      .attr('target', '_blank')

    // create og-image
    const image = dom('<span>')
      .addClass('og-image')
      .append(
        dom('<span>')
          .addClass('image')
          .attr('style', og.image ? `background-image: url('${og.image}')` : '')
      )

    // create og-contents with children
    const contents = dom('<span>')
      .addClass('og-contents')
      .append(
        dom('<span>').addClass('og-url').text(og.url),
        dom('<span>').addClass('og-title').text(og.title),
        dom('<span>').addClass('og-description').text(og.description)
      )

    // append image + contents to anchor tag
    anchor.append(image, contents)

    return dom.html(anchor)
  }

  try {
    const { data } = await commStore.fetchCrawling({ callUrl: url })
    const og = useGetOpenGraphs(data, url)
    return ogPreview(og)
  } catch (err) {
    return errException
      ? ogPreview({
          url,
          image: '',
          title: '',
          description: '',
        })
      : Promise.reject(err)
  }
}
