interface useFindLocaleByNamespaceProps {
  nameSpace: string
  model: any
}

export const useFindLocaleByNamespace = ({
  nameSpace,
  model,
}: useFindLocaleByNamespaceProps) => {
  const { locale } = useNuxtApp().$i18n

  return model[
    // DB 쪽 기본 언어가 en 으로 설정되어 있음
    locale.value === 'en'
      ? nameSpace
      : nameSpace + locale.value.charAt(0).toUpperCase() + locale.value.slice(1)
  ]
}
