import * as AttendanceType from '@store/attendance/type'

/**
 * attendance store init state
 */
export const initAttendanceState: AttendanceType.AttendanceState = {
  dailyCheck: {
    attendanceDateList: [],
    consecutiveAttendanceDateCount: 0,
    totalAttendanceDateCount: 0,
    hasAttendanceToday: false,
    notification: false,
    ntcnSetupId: '',

    // 프론트사이드에서만 쓰는 모델
    checkedInDates: [],
  },
}
