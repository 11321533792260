<script setup lang="ts">
import type { NovaCommentContentsProps } from './NovaCommentContents.types'
import { POST_HTML_IMAGE_CONTAINER_CLASS } from '@configs'

const props = withDefaults(defineProps<NovaCommentContentsProps>(), {
  isResizingImages: false,
})

const { replaceHtmlStrImageWithContainer, attachListenerToImages } =
  useHtmlContentsImageLazyLoading()
const commentContentsRef = ref<HTMLDivElement>()
const replacedContents = ref(props.contents)

// 1. 댓글, 답글 내부에 앵커 태그를 찾아서 타겟 바꾸기
replacedContents.value = useReplaceStrHtmlAnchorTarget(replacedContents.value)

// 2. 댓글, 답글 이미지 태그를 이미지 컨테이너에 담기
replacedContents.value = replaceHtmlStrImageWithContainer({
  htmlStr: replacedContents.value,
  containerClassNames: [
    POST_HTML_IMAGE_CONTAINER_CLASS,
    props.isResizingImages ? 'is-resizing' : 'is-resized',
  ],
})

// 3. iframe 태그를 og-meta ui 로 변경
replacedContents.value = await useHtmlContentsIframeToOG({
  htmlStr: replacedContents.value,
})

onMounted(() => {
  const imageContainers = commentContentsRef.value?.querySelectorAll(
    '.' + POST_HTML_IMAGE_CONTAINER_CLASS
  )
  imageContainers &&
    attachListenerToImages({
      imageContainers,
      containerClassName: POST_HTML_IMAGE_CONTAINER_CLASS,
    })
})
</script>

<template>
  <div
    ref="commentContentsRef"
    class="comment-contents"
    v-html="replacedContents"
  />
</template>

<style scoped lang="scss">
.comment-contents {
  @include html-contents-image-loading;
}
</style>
