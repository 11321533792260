import type { SendbirdState } from './type'

export const initSendbirdState: SendbirdState = {
  onAirLives: {
    items: [],
    payload: {
      pageSize: 50,
    },
    loading: false,
    refresh: false,
    last: false,
  },
}
