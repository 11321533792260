<script setup lang="ts">
import type { NovaBoxPostAccessBlockProps } from './NovaBoxPostAccessBlock.types'

const { gtEvent } = useGoogleTag()
const props = defineProps<NovaBoxPostAccessBlockProps>()
const { t } = useI18n()
const { userStore, showSignInDialog } = useMembershipProcess()

const handleOnGoSubscribe = async () => {
  if (!userStore.isSignIn) {
    gtEvent('clickEvent', {
      eventCategory: '클릭',
      eventAction: '크리에이터 선택 > 로그인 요청',
      eventLabel: t('subscribe.premiumPost.blockMessageAction', {
        subscribeGrade: t('subscribe.subscribeGrade.novaPlus'),
      }),
      eventSlot: '해당 크리에이터 후원 페이지로 이동',
      eventI18nAddr: useKoreanTranslation(
        'subscribe.premiumPost.blockMessageAction',
        {
          subscribeGrade: useKoreanTranslation(
            'subscribe.subscribeGrade.novaPlus'
          ),
        }
      ),
      eventComponent: 'Button',
      creatorUserSn: props.creatorUserSn,
    })
    await showSignInDialog()
    return
  }
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '크리에이터 선택',
    eventLabel: t('subscribe.premiumPost.blockMessageAction', {
      subscribeGrade: t('subscribe.subscribeGrade.novaPlus'),
    }),
    eventSlot: '해당 크리에이터 후원 페이지로 이동',
    eventI18nAddr: useKoreanTranslation(
      'subscribe.premiumPost.blockMessageAction',
      {
        subscribeGrade: useKoreanTranslation(
          'subscribe.subscribeGrade.novaPlus'
        ),
      }
    ),
    eventComponent: 'Button',
    creatorUserSn: props.creatorUserSn,
  })
  useNavigations({
    external: false,
    url: `/creator/subscribe/support/${props.creatorUserSn}`,
  })
}
</script>

<template>
  <div class="post-access-block" @click.stop>
    <NovaImageContainer
      :empty-type="'default'"
      :image-url="blurImageUrl"
      :ratio="'auto'"
      class="blur-image"
    />

    <div class="guide-message-wrap">
      <p class="guide-message">
        {{
          t('subscribe.premiumPost.blockMessage', {
            subscribeGrade: t('subscribe.subscribeGrade.novaPlus'),
          })
        }}
      </p>

      <NovaButtonText
        :label="
          t('subscribe.premiumPost.blockMessageAction', {
            subscribeGrade: t('subscribe.subscribeGrade.novaPlus'),
          })
        "
        :theme="'primary-blue'"
        :full-width="false"
        @click.stop="handleOnGoSubscribe"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.post-access-block {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  min-height: 148px;

  .blur-image {
    position: relative;

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 100%;
      box-shadow: rgba(255, 255, 255, 1) 0 0 4px 4px inset;
    }
  }

  .guide-message-wrap {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    height: 100%;
    padding: 16px;
    background-color: hex-to-rgba($color-bg-2, 0.8);

    .guide-message {
      @include text-style($text-heading2-bold);
      text-align: center;

      @include mobile {
        @include text-style($text-heading3-bold);
      }
    }
  }
}
</style>
