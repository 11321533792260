import axios, {
  type AxiosRequestConfig,
  type AxiosResponse,
  type CreateAxiosDefaults,
} from 'axios'
import { setLogInterceptor } from './interceptors/log'
import { setBaseUrl } from './interceptors/baseUrl'
import { setErrorCodeCheck } from './interceptors/errorCodeCheck'
// import { setTestJwtError } from './interceptors/testJwtError'
import { setParamsInterceptor } from './interceptors/params'
import { setJwtInterceptor } from './interceptors/jwt'
import { setBlockAccessInterceptor } from './interceptors/blockUser'

const createAxiosInstance = (options: CreateAxiosDefaults) => {
  console.log(options)
  const axiosInstance = axios.create()

  // interceptors
  setBaseUrl(axiosInstance) // start : 1
  setParamsInterceptor(axiosInstance) // start : 2
  // setTestJwtError(axiosInstance) // jwt refresh access token test
  setErrorCodeCheck(axiosInstance) // start: 3
  setLogInterceptor(axiosInstance) // start: 4
  setJwtInterceptor(axiosInstance) // start: 5
  setBlockAccessInterceptor(axiosInstance) // start: 6

  return {
    ...axiosInstance,
    request: axiosInstance.request,
    get<T = any, R = AxiosResponse<T, any>>(
      url: string,
      config?: AxiosRequestConfig
    ) {
      return axiosInstance.request<T, R>({
        method: 'get',
        url,
        ...config,
      })
    },
    post<T = any, R = AxiosResponse<T, any>>(
      url: string,
      data?: any,
      config?: AxiosRequestConfig
    ) {
      return axiosInstance.request<T, R>({
        method: 'post',
        url,
        data,
        ...config,
      })
    },
    put<T = any, R = AxiosResponse<T, any>>(
      url: string,
      data: any,
      config?: AxiosRequestConfig
    ) {
      return axiosInstance.request<T, R>({
        method: 'put',
        url,
        data,
        ...config,
      })
    },
    patch<T = any, R = AxiosResponse<T, any>>(
      url: string,
      data: any,
      config?: AxiosRequestConfig
    ) {
      return axiosInstance.request<T, R>({
        method: 'patch',
        url,
        data,
        ...config,
      })
    },
    delete<T = any, R = AxiosResponse<T, any>>(
      url: string,
      config?: AxiosRequestConfig
    ) {
      return axiosInstance.request<T, R>({
        method: 'delete',
        url,
        ...config,
      })
    },
  }
}

// base api axios
export const api = createAxiosInstance({ baseURL: '/api' })
