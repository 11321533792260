import env, { type Envs } from '../../configs/env'

const mode = (process.env.MODE || 'development') as Envs

/**
 * nuxt config link
 */
export const nuxtLinks = [
  {
    rel: 'apple-touch-icon',
    href: `${env[mode].APP_URL}/favicon-on-light/apple-icon-57x57.png`,
    media: '(prefers-color-scheme: light)',
  },
  {
    rel: 'apple-touch-icon',
    href: `${env[mode].APP_URL}/favicon-on-light/apple-icon-60x60.png`,
    media: '(prefers-color-scheme: light)',
  },
  {
    rel: 'apple-touch-icon',
    href: `${env[mode].APP_URL}/favicon-on-light/apple-icon-72x72.png`,
    media: '(prefers-color-scheme: light)',
  },
  {
    rel: 'apple-touch-icon',
    href: `${env[mode].APP_URL}/favicon-on-light/apple-icon-76x76.png`,
    media: '(prefers-color-scheme: light)',
  },
  {
    rel: 'apple-touch-icon',
    href: `${env[mode].APP_URL}/favicon-on-light/apple-icon-114x114.png`,
    media: '(prefers-color-scheme: light)',
  },
  {
    rel: 'apple-touch-icon',
    href: `${env[mode].APP_URL}/favicon-on-light/apple-icon-120x120.png`,
    media: '(prefers-color-scheme: light)',
  },
  {
    rel: 'apple-touch-icon',
    href: `${env[mode].APP_URL}/favicon-on-light/apple-icon-144x144.png`,
    media: '(prefers-color-scheme: light)',
  },
  {
    rel: 'apple-touch-icon',
    href: `${env[mode].APP_URL}/favicon-on-light/apple-icon-152x152.png`,
    media: '(prefers-color-scheme: light)',
  },
  {
    rel: 'apple-touch-icon',
    href: `${env[mode].APP_URL}/favicon-on-light/apple-icon-180x180.png`,
    media: '(prefers-color-scheme: light)',
  },
  {
    rel: 'icon',
    type: 'image/png',
    href: `${env[mode].APP_URL}/favicon-on-light/android-icon-192x192.png`,
    media: '(prefers-color-scheme: light)',
  },
  {
    rel: 'icon',
    type: 'image/png',
    href: `${env[mode].APP_URL}/favicon-on-light/favicon-32x32.png`,
    media: '(prefers-color-scheme: light)',
  },
  {
    rel: 'icon',
    type: 'image/png',
    href: `${env[mode].APP_URL}/favicon-on-light/favicon-96x96.png`,
    media: '(prefers-color-scheme: light)',
  },
  {
    rel: 'icon',
    type: 'image/png',
    href: `${env[mode].APP_URL}/favicon-on-light/favicon-16x16.png`,
    media: '(prefers-color-scheme: light)',
  },
  {
    rel: 'apple-touch-icon',
    href: `${env[mode].APP_URL}/favicon-on-dark/apple-icon-57x57.png`,
    media: '(prefers-color-scheme: dark)',
  },
  {
    rel: 'apple-touch-icon',
    href: `${env[mode].APP_URL}/favicon-on-dark/apple-icon-60x60.png`,
    media: '(prefers-color-scheme: dark)',
  },
  {
    rel: 'apple-touch-icon',
    href: `${env[mode].APP_URL}/favicon-on-dark/apple-icon-72x72.png`,
    media: '(prefers-color-scheme: dark)',
  },
  {
    rel: 'apple-touch-icon',
    href: `${env[mode].APP_URL}/favicon-on-dark/apple-icon-76x76.png`,
    media: '(prefers-color-scheme: dark)',
  },
  {
    rel: 'apple-touch-icon',
    href: `${env[mode].APP_URL}/favicon-on-dark/apple-icon-114x114.png`,
    media: '(prefers-color-scheme: dark)',
  },
  {
    rel: 'apple-touch-icon',
    href: `${env[mode].APP_URL}/favicon-on-dark/apple-icon-120x120.png`,
    media: '(prefers-color-scheme: dark)',
  },
  {
    rel: 'apple-touch-icon',
    href: `${env[mode].APP_URL}/favicon-on-dark/apple-icon-144x144.png`,
    media: '(prefers-color-scheme: dark)',
  },
  {
    rel: 'apple-touch-icon',
    href: `${env[mode].APP_URL}/favicon-on-dark/apple-icon-152x152.png`,
    media: '(prefers-color-scheme: dark)',
  },
  {
    rel: 'apple-touch-icon',
    href: `${env[mode].APP_URL}/favicon-on-dark/apple-icon-180x180.png`,
    media: '(prefers-color-scheme: dark)',
  },
  {
    rel: 'icon',
    type: 'image/png',
    href: `${env[mode].APP_URL}/favicon-on-dark/android-icon-192x192.png`,
    media: '(prefers-color-scheme: dark)',
  },
  {
    rel: 'icon',
    type: 'image/png',
    href: `${env[mode].APP_URL}/favicon-on-dark/favicon-32x32.png`,
    media: '(prefers-color-scheme: dark)',
  },
  {
    rel: 'icon',
    type: 'image/png',
    href: `${env[mode].APP_URL}/favicon-on-dark/favicon-96x96.png`,
    media: '(prefers-color-scheme: dark)',
  },
  {
    rel: 'icon',
    type: 'image/png',
    href: `${env[mode].APP_URL}/favicon-on-dark/favicon-16x16.png`,
    media: '(prefers-color-scheme: dark)',
  },
]
