<script setup lang="ts">
import { Tippy } from 'vue-tippy'
import type { NovaBoxPostActionRewardProps } from './NovaBoxPostActionReward.types'

const props = withDefaults(defineProps<NovaBoxPostActionRewardProps>(), {
  popoverAppendTarget: null,
})

const handleOnBeforeShow = () => {
  if (props.totalReward === null) return false
}
</script>

<template>
  <Tippy
    :append-to="popoverAppendTarget || 'parent'"
    :interactive="true"
    :theme="'popover'"
    :on-show="handleOnBeforeShow"
    @click.stop="() => {}"
  >
    <NovaBoxPostAction
      :icon="{ type: 'outline', name: 'wallet' }"
      :display-number="totalReward || 0"
      :action-type="'reword'"
    />

    <template #content>
      <NovaBoxPostActionRewardPanel
        :total="totalReward"
        :expect="expectReward"
      />
    </template>
  </Tippy>
</template>
