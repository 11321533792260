<script setup lang="ts">
import type { NovaSwitchEmits, NovaSwitchProps } from './NovaSwitch.types'

const emit = defineEmits<NovaSwitchEmits>()
const props = withDefaults(defineProps<NovaSwitchProps>(), {
  loading: false,
  disabled: false,
  theme: 'AOS',
})
const handleOnUpdateValue = () => {
  emit('update', !props.modelValue)
}
</script>

<template>
  <div
    :class="[
      'switch-wrap',
      { on: modelValue, loading, disabled, 'theme-ios': theme === 'IOS' },
    ]"
    @click="handleOnUpdateValue"
  >
    <span class="rail" />
    <span class="button"></span>
    <NovaLoadingIndicator v-if="loading" :fill="false" />
  </div>
</template>

<style lang="scss" scoped>
.switch-wrap {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 16px;
  cursor: pointer;

  &.on {
    > .rail {
      background-color: $color-secondary-blue-light-30;
    }
    > .button {
      left: 14px;
      background-color: $color-primary-blue;
    }
  }

  &.loading {
    pointer-events: none;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.8;
  }

  &.theme-ios {
    width: 38px;
    height: 22px;
    border-radius: 18px;
    overflow: hidden;

    &.on {
      > .rail {
        background-color: #213acc;
      }
      > .button {
        left: 18px;
        background-color: $color-white;
      }
    }

    > .rail {
      width: 100%;
      height: 100%;
      background-color: #cfcfcf;
    }

    > .button {
      left: 2px;
      width: 18px;
      height: 18px;
      background-color: $color-white;
      box-shadow: none;
    }
  }

  :deep(.loading-indicator) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  > .rail {
    width: 100%;
    height: 10px;
    border-radius: 5px;
    background-color: $color-gray-1;
    //transition: all 2s ease-in-out;
    @include transition(background-color 0.15s ease-in-out);
  }

  > .button {
    position: absolute;
    top: 50%;
    left: 0;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: $color-text-3;
    box-shadow: 0 1px 4px 0 hex-to-rgba($color-black, 0.2);
    transform: translateY(-50%);
    //transition: all 2s ease-in-out;
    @include transition(
      background-color 0.15s ease-in-out,
      left 0.15s ease-in-out
    );
  }
}
</style>
