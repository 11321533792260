import type { UserState } from './type'

export const initUserState: UserState = {
  userCommonInfo: null,
  count: 0,
  accessToken: null,
  refreshToken: null,
  retireType: null,
  privateKey: null,
  kycStatus: null,
  blockAccessCount: 0,
}
