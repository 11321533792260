/**
 * 사용자가 모바일 디바이스에서 사이트에 접속하는지 확인
 *
 * @function useCheckMobile
 * @returns {boolean} 사용자가 모바일 디바이스에서 접속하면 true, 그렇지 않으면 false를 반환
 */
export const useCheckMobile = (): boolean => {
  const ua = navigator.userAgent
  const mobilePattern: { [Property in string]: RegExp } = {
    android: /Android/i,
    ios: /iPhone|iPad|iPod/i,
    opera: /Opera Mini/i,
    window: /IEMobile/i,
  }

  return (
    typeof Object.keys(mobilePattern).find((key) =>
      ua.match(mobilePattern[key])
    ) !== 'undefined'
  )
}
