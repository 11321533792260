import { AxiosError } from 'axios'
import * as SendbirdTypes from '@sendbird/types'
import { ChatMode, SendbirdExceptionStatus } from '@sendbird/types'
import { SendbirdException } from '@sendbird/exception'
import {
  CreateLiveStatus,
  type CreateLivePayload,
  type EnterLiveAvailable,
} from '@store'
import { bridgeToApp } from '~/bridge/bridges'

const useSendbirdGate = () => {
  const { t, locale: _locale } = useNuxtApp().$i18n
  const { show: modalShow } = useModal()
  const config = useRuntimeConfig()
  const userStore = useUserStore()
  const sendbirdStore = useSendbirdStore()
  const appStore = useAppStore()
  const { showSignInDialog } = useMembershipProcess()

  /**
   * Check create live available
   * @param userId
   * @param onLoading
   */
  const checkCreateLiveAvailable = async ({
    userId,
    onLoading,
  }: SendbirdTypes.CheckCreateLiveAvailableProps) => {
    try {
      onLoading?.(true)
      const res = await sendbirdStore.fetchCreateLivePermission()
      switch (res.detailStatus) {
        case CreateLiveStatus.ABLE_NEW_LIVE:
          await modalShow(modalsName.MODAL_CREATE_LIVE)
          break
        case CreateLiveStatus.ALREADY_LIVE:
          openLive({
            mode: SendbirdTypes.LiveMode.HOST,
            userId,
            liveEventId: res.availableLiveEventId,
          })
          break
        default:
          throw new SendbirdException({
            message: 'check create live event status is unknown',
            cause: {
              status:
                SendbirdTypes.SendbirdExceptionStatus
                  .CREATE_LIVE_EVENT_UNAVAILABLE,
            },
          })
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        switch (err.response?.status) {
          case 3004:
            useToast(
              t('sendbird.createLiveEvent.errors.checkCreateLiveAvailable.3004')
            )
            break
          case 3006:
            useToast(
              t('sendbird.createLiveEvent.errors.checkCreateLiveAvailable.3006')
            )
            break
          case 4010:
            useToast(
              t('sendbird.createLiveEvent.errors.checkCreateLiveAvailable.4010')
            )
            break
          default:
            useToast(t('commonError.network.message'))
            break
        }
      } else if (err instanceof SendbirdException) {
        useToast(t('commonError.default.title'))
      } else {
        useToast(t('commonError.network.message'))
      }

      return Promise.reject(err)
    } finally {
      onLoading?.(false)
    }
  }

  /**
   * Create live
   * @param title
   * @param publishPostAt
   * @param entryRestrictionsAt
   * @param colctSnList
   * @param onLoading
   */
  const createLive = async ({
    title,
    publishPostAt,
    entryRestrictionsAt,
    colctSnList,
    onLoading,
  }: SendbirdTypes.CreateLiveProps) => {
    try {
      onLoading?.(true)
      const payload: CreateLivePayload = {
        title,
        publishPostAt,
        entryRestrictionsAt,
      }

      if (entryRestrictionsAt === 'Y') {
        payload.colctSnList = colctSnList
      }

      const { liveEventId, sendbirdUserId } =
        await sendbirdStore.createLiveEvent(payload)
      openLive({
        mode: SendbirdTypes.LiveMode.HOST,
        userId: sendbirdUserId,
        liveEventId,
      })
    } catch (err) {
      if (err instanceof AxiosError) {
        switch (err.response?.status) {
          case 3007:
            useToast(t('sendbird.createLiveEvent.errors.createLive.3007'))
            break
          case 2005:
            useToast(t('sendbird.createLiveEvent.errors.createLive.2005'))
            break
          case 3002:
          case 3003:
          case 3004:
          case 3005:
          default:
            useToast(t('commonError.network.message'))
            break
        }
      }
      return Promise.reject(err)
    } finally {
      onLoading?.(false)
    }
  }

  /**
   * Enter live
   * @param mode
   * @param checkPermissionType
   * @param sndbrdLiveSn
   * @param liveEventId
   * @param liveOnAirAt
   * @param onLoading
   */
  const enterLive = async ({
    mode,
    checkPermissionType,
    sndbrdLiveSn,
    liveEventId,
    liveOnAirAt,
    onLoading,
  }: SendbirdTypes.EnterLiveByGuestProps) => {
    // CHECK enter live available 1. 라이브가 OnAir 상태 및 sendbird live sn 확인
    if (
      liveOnAirAt !== 'Y' ||
      (checkPermissionType === 'liveEventId' && !liveEventId)
    ) {
      useToast(t('sendbird.enterLiveEvent.errors.3010'))
      return
    }

    // CHECK enter live available 2. 로그인 여부 확인
    if (!userStore.user) {
      await showSignInDialog()
      return
    }

    try {
      onLoading?.(true)
      let userId = userStore.user.sndbrdUserId
      if (!userId) {
        const { sendbirdUserId } = await sendbirdStore.createSendbirdUserId()
        userId = sendbirdUserId
      }
      let enterLiveAvailable: EnterLiveAvailable | null = null

      if (checkPermissionType === 'sndbrdLiveSn' && sndbrdLiveSn) {
        enterLiveAvailable = await sendbirdStore.fetchEnterLiveAvailable({
          sndbrdLiveSn,
        })
      } else if (checkPermissionType === 'liveEventId' && liveEventId) {
        enterLiveAvailable =
          await sendbirdStore.fetchEnterLiveAvailableBySbUserId({
            userId,
            liveEventId,
          })
      }

      // 입장 가능
      if (enterLiveAvailable?.isPermission) {
        openLive({
          mode,
          liveEventId: enterLiveAvailable!.liveEventId,
          userId,
        })
      }

      // 입장 불가
      else {
        await modalShow(
          modalsName.MODAL_NOT_AVAILABLE_ENTER_LIVE,
          enterLiveAvailable
        )
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        switch (err.response?.status) {
          case 2011:
          case 2034:
          case 3010:
          case 3011:
          case 3012:
            useToast(t(`sendbird.enterLiveEvent.errors.${err.response.status}`))
            break
          case 2006:
          default:
            useToast(
              t('sendbird.enterLiveEvent.errors.checkEnterLiveAvailableFail')
            )
        }
      }
      return Promise.reject(err)
    } finally {
      onLoading?.(false)
    }
  }

  /**
   * Open live
   * @param mode
   * @param userId
   * @param liveEventId
   * @param locale
   */
  const openLive = ({
    mode,
    userId,
    liveEventId,
    locale = _locale.value,
  }: SendbirdTypes.OpenLiveProps) => {
    const params: Record<string, string> = {
      mode,
      userId,
      liveEventId,
      locale: locale as unknown as string,
    }
    const query = new URLSearchParams(params).toString()
    const url = `${config.public.LIVE_URL}?${query}`
    if (appStore.isApp) {
      bridgeToApp.openWeb({ url, type: 'external' })
    } else {
      window.open(url, '_blank')
    }

    console.log('open live params:', params)
    console.log('open live query:', query)
    console.log('open live url:', url)
  }

  /**
   * Create chat
   * @param mode
   * @param createOpenChannelPayload
   * @param onLoading
   */
  const createChat = async ({
    mode,
    createOpenChannelPayload,
    onLoading,
  }: SendbirdTypes.CreateChatChannelProps) => {
    try {
      const { gtEvent } = useGoogleTag()
      onLoading?.(true)
      // CHECK create chat channel available 1. 로그인 여부 확인
      if (!userStore.user) {
        await showSignInDialog()
        return
      }

      // CHECK create chat channel available 2. sendbird channelUrl 여부 확인
      if (userStore.user.channelUrl) {
        throw new SendbirdException({
          message:
            "can't not create sendbird open channel, creator already has open channel",
          cause: {
            status: SendbirdExceptionStatus.CREATE_OPEN_CHANNEL_UNAVAILABLE,
          },
        })
      }

      // CHECK create chat channel available 3. sendbird userId 여부 확인
      let userId = userStore.user.sndbrdUserId
      if (!userId) {
        const { sendbirdUserId } = await sendbirdStore.createSendbirdUserId()
        userId = sendbirdUserId
      }

      // Show create open channel modal
      if (mode === 'showCreateChannelModal') {
        await modalShow(modalsName.MODAL_CREATE_OPEN_CHANNEL)
        return
      }

      // create open channel
      if (mode === 'createOpenChannel') {
        const { channelUrl, sendbirdUserId } =
          await sendbirdStore.createOpenChannel(createOpenChannelPayload!)
        openChat({ mode: ChatMode.CHANNEL, userId: sendbirdUserId, channelUrl })
        gtEvent('userAction', {
          eventCategory: '사용자',
          eventAction: '팬 채팅방 개설',
          eventLabel: t('sendbird.createChatChannel.actions.create'),
          eventSlot: '크리에이터 홈 > 팬 채팅방 개설하기',
          eventI18nAddr: useKoreanTranslation(
            'sendbird.createChatChannel.actions.create'
          ),
          eventComponent: 'Button',
        })
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        switch (err.response?.status) {
          case 3008:
            useToast(t('sendbird.createChatChannel.errors.3008'))
            break
          case 4010:
            useToast(t('sendbird.createChatChannel.errors.4010'))
            break
          default:
            useToast(t('sendbird.createChatChannel.errors.network'))
            break
        }
      }
      return Promise.reject(err)
    } finally {
      onLoading?.(false)
    }
  }

  /**
   * Enter Chat
   * @param mode
   * @param channelUrl
   * @param onLoading
   */
  const enterChat = async ({
    mode,
    channelUrl,
    onLoading,
  }: SendbirdTypes.EnterChatProps) => {
    try {
      const { gtEvent } = useGoogleTag()
      // Check enter chat available 1. channel 직접 접근일때 channelUrl 유무 판단
      if (mode === SendbirdTypes.ChatMode.CHANNEL && !channelUrl) {
        throw new SendbirdException({
          message: 'channel url is no exist',
          cause: {
            status: SendbirdExceptionStatus.CHANNEL_URL_IS_NOT_EXIST,
          },
        })
      }

      // CHECK enter chat available 1. 로그인 여부 판단
      if (!userStore.user) {
        await showSignInDialog()
        return
      }

      onLoading?.(true)
      let userId = userStore.user.sndbrdUserId

      // CHECK enter chat available 2. 센드버드 아이디 여부 판단
      if (!userId) {
        const { sendbirdUserId } = await sendbirdStore.createSendbirdUserId()
        userId = sendbirdUserId
      }
      gtEvent('clickEvent', {
        eventCategory: '클릭',
        eventAction: '크리에이터 팬 채팅방 입장',
        eventLabel: t('sendbird.enterChatChannel.enterCreatorChannel'),
        eventSlot: '크리에이터 팬 채팅방 입장',
        eventI18nAddr: useKoreanTranslation(
          'sendbird.enterChatChannel.enterCreatorChannel'
        ),
        eventComponent: 'Button',
      })
      openChat({
        mode,
        userId,
        channelUrl,
      })

      return true
    } catch (err) {
      if (err instanceof AxiosError) {
        useToast(t('sendbird.enterChatChannel.errors.enterChatFail'))
      }

      return Promise.reject(err)
    } finally {
      onLoading?.(false)
    }
  }

  /**
   * Open chat
   * @param mode
   * @param userId
   * @param channelUrl
   * @param locale
   */
  const openChat = ({
    mode,
    userId,
    channelUrl = '',
    locale = _locale.value,
  }: SendbirdTypes.OpenChatProps) => {
    const params: Record<string, string> = {
      mode,
      userId,
      locale: locale as unknown as string,
    }

    if (mode === SendbirdTypes.ChatMode.CHANNEL) {
      if (!channelUrl)
        throw new SendbirdException({
          message: 'channel url is no exist',
          cause: {
            status:
              SendbirdTypes.SendbirdExceptionStatus.CHANNEL_URL_IS_NOT_EXIST,
          },
        })

      params.channelUrl = channelUrl
    }
    const query = new URLSearchParams(params).toString()
    const url = `${config.public.CHAT_URL}?${query}`

    if (appStore.isApp) {
      bridgeToApp.openWeb({ url, type: 'external' })
    } else {
      window.open(url, '_blank')
    }

    console.log('open chat params:', params)
    console.log('open chat query:', query)
    console.log('open chat url:', url)
  }

  return {
    // live
    checkCreateLiveAvailable,
    createLive,
    enterLive,
    openLive,

    // chat
    createChat,
    enterChat,
    openChat,
  }
}

export default useSendbirdGate
