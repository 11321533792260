<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import { Swiper, SwiperSlide } from 'swiper/vue'
import type { Panel, MenuPanelProps } from './NovaHeaderMenuPanel.types'
import { LayerType } from '@store'

const props = defineProps<MenuPanelProps>()

const { gtEvent } = useGoogleTag()
const layoutStore = useLayoutStore()
const panelContainer = ref<HTMLElement | null>(null)
const swiper = ref<typeof Swiper | null>(null)
const panels = ref<Panel[]>([
  { id: 'gate', depth: 0 },
  { id: 'services', depth: 1 },
])
const curPanelId = ref<Panel['id']>('gate')
const prevPanelId = ref<Panel['id']>('gate')
const isOpen = computed(() => layoutStore.layers.includes(LayerType.MENU_PANEL))

onClickOutside(panelContainer, (evt) => {
  const clickedToggleButton =
    (evt.target as HTMLElement)
      .closest('.toggle-panel-button')
      ?.getAttribute('toggle-panel') === props.panelName

  if (!isOpen.value || clickedToggleButton) return
  layoutStore['layer:close'](LayerType.MENU_PANEL)
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '메뉴 패널 닫기',
    eventLabel: 'onClickOutside',
    eventSlot: '',
    eventI18nAddr: '',
    eventComponent: 'Layer',
  })
})

// init swiper
const handleOnSetSwiper = (evt: typeof Swiper) => {
  swiper.value = evt
}

// go slide
const handleOnGoPanel = (panelId: Panel['id']) => {
  const panel = panels.value.find((item) => item.id === panelId)
  const interval = setInterval(() => {
    goPanel()
  }, 100)

  const goPanel = () => {
    if (swiper.value?.el) {
      swiper.value.slideTo(panel?.depth)
      prevPanelId.value = curPanelId.value
      curPanelId.value = panelId

      clearInterval(interval)
    }
  }
}

// back slide
const handleOnGoBack = () => {
  curPanelId.value = prevPanelId.value
  if (curPanelId.value === 'gate') {
    swiper.value?.slideTo(0)
  } else {
    swiper.value?.slideTo(swiper.value?.activeIndex - 1)
  }
}

const handleOnToggleUserPanel = () => {
  layoutStore['layer:close'](LayerType.MENU_PANEL)
}
</script>

<template>
  <div ref="panelContainer" :class="['menu-panel', { on: isOpen }]">
    <div
      class="dim"
      @click="() => layoutStore['layer:close'](LayerType.MENU_PANEL)"
    />

    <NovaBoxBase :floating="true" class="menu-panel-inner" :round="0">
      <Swiper
        class="swiper"
        :allow-touch-move="false"
        :auto-height="true"
        :observer="true"
        :observe-parents="true"
        @swiper="handleOnSetSwiper"
      >
        <!-- Slide Depth: 1 -->
        <SwiperSlide>
          <NovaHeaderMenuPanelGate
            @go-panel="handleOnGoPanel"
            @toggle-show-panel="handleOnToggleUserPanel"
          />
        </SwiperSlide>

        <!-- Slide Depth: 2 -->
        <SwiperSlide>
          <NovaHeaderUserPanelServices
            v-if="curPanelId === 'services'"
            @go-back="handleOnGoBack"
          />
        </SwiperSlide>
      </Swiper>
    </NovaBoxBase>
  </div>
</template>

<style lang="scss" scoped>
.menu-panel {
  position: absolute;
  top: calc(74px + 16px);
  right: 16px;
  display: flex;
  justify-content: flex-end;
  width: $right-side-bar-width;
  opacity: 0;
  @include transition(top 0.2s ease-in-out, opacity 0.2s ease-in-out);
  pointer-events: none;
  z-index: $z-index-system-panel;

  &.on {
    top: 74px;
    opacity: 1;
    pointer-events: auto;
  }

  > .dim {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: hex-to-rgba($color-black, 0.65);
    opacity: 0;
    pointer-events: none;
    @include transition(opacity 0.2s ease-in-out);
    z-index: -1;
  }

  .menu-panel-inner {
    width: 100%;
    height: 100%;
    padding: 0;

    > .swiper {
      width: 100%;
    }
  }

  @include mobile {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;

    &.on {
      top: 0;

      > .dim {
        opacity: 1;
        pointer-events: auto;
      }
    }

    > .menu-panel-inner {
      width: calc(100% - 50px);
      max-width: $mobile-side-panel-max-width;
      height: 100%;
      border-radius: 0;
      @include transition(all 0.2s ease-in-out);

      > .swiper {
        height: 100%;

        :deep(.swiper-wrapper) {
          height: 100% !important;
        }

        :deep(.swiper-slide) {
          height: 100%;
        }
      }
    }
  }
}
</style>
