<script setup lang="ts">
import type {
  NovaBoxSelectBankEmits,
  NovaBoxSelectBankProps,
} from './NovaBoxSelectBank.types'
import { type Bank } from '@store/etc/type'

const emit = defineEmits<NovaBoxSelectBankEmits>()
withDefaults(defineProps<NovaBoxSelectBankProps>(), {
  selectBank: null,
})

const handleOnSelectBank = (bank: Bank) => {
  emit('onSelectBank', bank)
}
</script>

<template>
  <div class="box-select-bank">
    <button
      v-for="bank in banks"
      :key="bank.bicCode"
      type="button"
      :class="['select-item', { on: selectBank?.bicCode === bank.bicCode }]"
      @click="() => handleOnSelectBank(bank)"
    >
      <span class="label">{{ bank.bicBankNm }}</span>
      <NovaIcon
        :icon="{ type: 'outline', name: 'chev-compact-right' }"
        class="chev"
      />
    </button>
  </div>
</template>

<style lang="scss" scoped>
.box-select-bank {
  display: flex;
  flex-direction: column;
  gap: 6px;

  .select-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 6px 2px 6px 6px;

    @media (hover: hover) {
      &:hover {
        > .label {
          color: $color-primary;
        }
      }
    }

    &.on {
      > .label {
        color: $color-primary;
      }
    }

    > .label {
      @include text-style($text-body-14-medium);
      color: $color-text-1;
      transition: all 0.2s;
    }

    > .chev {
      color: $color-text-3;
    }
  }
}
</style>
