<script setup lang="ts">
import { BlockAccessType } from '@components/NovaModalBlockAccess/NovaModalBlockAccess.types'

const { show, hide } = useModal()
const userStore = useUserStore()
const isShowBlockModal = ref(false)

watch(
  () => userStore.blockAccessCount,
  (blockAccessCount) => {
    if (blockAccessCount > 0 && !isShowBlockModal.value) {
      isShowBlockModal.value = true

      setTimeout(async () => {
        await show(modalsName.MODAL_BLOCK_ACCESS, {
          blockAccessType: BlockAccessType.ACTIVITY,
          onClickConfirm: async () => {
            await hide(modalsName.MODAL_BLOCK_ACCESS)
            isShowBlockModal.value = false
            userStore.blockAccessCount = 0
          },
        })
      }, 300)
    }
  },
  { immediate: true }
)
</script>

<template>
  <slot />
</template>
