import * as AppTypes from './type'
import { initAppState } from './init'
import type { AppType, OnAppStatusChangePayload } from '@bridge/types'
import type { FirebaseTokenStatus } from '@firebaseModule/types'

export * from './init'
export * from './type'
export const useAppStore = defineStore('app', () => {
  const commStore = useCommStore()
  const config = useRuntimeConfig()
  const { cookieNames } = useCookies()

  const appType = ref(initAppState.appType)
  const currentVer = ref(initAppState.currentVer)
  const appBuildNo = ref(initAppState.appBuildNo)
  const forceAppMode = ref(initAppState.forceAppMode)
  const appStorage = ref(initAppState.appStorage)
  const appStatus = ref(initAppState.appStatus)
  const fcmTokenStatus = ref(initAppState.fcmTokenStatus)
  const envMode = ref(config.public.MODE)

  /**
   * 앱 여부
   */
  const isApp = computed(() => {
    const apps: AppType[] = ['IOS', 'AOS']
    return apps.findIndex((item) => item === appType.value) !== -1
  })

  /**
   * 앱 정보
   */
  const appInfo = computed(() => ({
    appType: appType.value,
    currentVer: currentVer.value,
    appBuildNo: appBuildNo.value,
  }))

  /**
   * 앱 버전
   */
  const appVersion = computed(() => commStore.appVersion)

  /**
   * 앱 버전: 디바이스 매칭
   */
  const appVersionByDevice = computed(() => {
    switch (appType.value) {
      case 'IOS':
        return {
          recentVer: commStore.appVersion?.ios.finalAppVersion || '',
          requiredMinVer: commStore.appVersion?.ios.forceUpdateAppVersion || '',
          currentVer: currentVer.value || '',
        }
      case 'AOS':
        return {
          recentVer: commStore.appVersion?.android.finalAppVersion || '',
          requiredMinVer:
            commStore.appVersion?.android.forceUpdateAppVersion || '',
          currentVer: currentVer.value || '',
        }
      default:
        return {
          recentVer: '',
          requiredMinVer: '',
          currentVer: '',
        }
    }
  })

  /**
   * 앱 업데이트 필요
   */
  const requireAppUpdate = computed(() => {
    const { checkForceUpdateVersion } = useCheckVersion()

    if (!appType.value || !currentVer.value || !commStore.appVersion) {
      return false
    }

    console.log('currentVer', currentVer.value)
    console.log(commStore.appVersion)

    switch (appType.value) {
      case 'IOS':
        return !checkForceUpdateVersion(
          currentVer.value,
          commStore.appVersion.ios.forceUpdateAppVersion
        )
      case 'AOS':
        return !checkForceUpdateVersion(
          currentVer.value,
          commStore.appVersion.android.forceUpdateAppVersion
        )
      default:
        return false
    }
  })

  /**
   * 하이브리드 앱 정보 초기화
   * @description 앱 전역 레벨에서 쿠키에 저장된 앱 정보를 토대로 상태 관리 및 앱 모드 동작에 필요한 정보 조회
   * @description 강제 앱 모드를 끌때도 사용
   */
  const initApp = async () => {
    // 1. get app version
    const { fetchAppVersion } = commStore
    await fetchAppVersion()

    // 2. update
    appType.value = useCookie<AppType>(cookieNames.APP_TYPE).value || null
    currentVer.value = useCookie(cookieNames.CURRENT_VERSION).value || null
    appBuildNo.value = useCookie(cookieNames.APP_BUILD_NO).value || null
  }

  /**
   * 강제 앱 모드 켜기
   * @description 앱 없이 브릿지 테스트를 위한 앱 정보 오버라이드
   */
  const forceAppModeTurnOn = ({
    appType: _appType,
    currentVer: _currentVer,
    appBuildNo: _appBuildNo,
  }: AppTypes.ForceAppModePayload) => {
    appType.value = _appType
    currentVer.value = _currentVer
    appBuildNo.value = _appBuildNo

    forceAppMode.value = true
  }

  /**
   * 강제 앱 모드 끄기
   */
  const forceAppModeTurnOff = async () => {
    await initApp()
    forceAppMode.value = false
  }

  /**
   * 앱 상태 변경 업데이트
   */
  const updateAppStatus = (payload: OnAppStatusChangePayload) => {
    appStatus.value = payload
  }

  /**
   * 파이어베이스 토큰 상태 업데이트
   */
  const updateFcmTokenStatus = (payload: FirebaseTokenStatus) => {
    fcmTokenStatus.value = payload
  }

  return {
    appType,
    currentVer,
    appBuildNo,
    forceAppMode,
    appStorage,
    appStatus,
    fcmTokenStatus,
    envMode,
    isApp,
    appInfo,
    appVersion,
    appVersionByDevice,
    requireAppUpdate,
    initApp,
    forceAppModeTurnOn,
    forceAppModeTurnOff,
    updateAppStatus,
    updateFcmTokenStatus,
  }
})
