import { clientId } from './env'

export type MembershipService =
  | 'signin' // 로그인
  | 'signup' // 회원가입
  | 'kyc' // kyc 인증
  | 'agree' // 동의
  | 'searchid' // 아이디 찾기
  | 'searchpw' // 패스워드 찾기

export const authConfig = {
  AUTH_PARAMS: {
    CLIENT_ID: clientId,
    END_POINT: {
      SIGN_IN: 'signin',
      SIGN_UP: 'signup',
      KYC: 'kyc',
      AGREE: 'agree',
      SEARCH_ID: 'searchid',
      SEARCH_PW: 'searchpw',
    } as { [Properties in string]: MembershipService },
  },
}
