<script setup lang="ts">
import { LottieAnimation } from 'lottie-web-vue'
import type { NovaLoadingIndicatorProps } from './NovaLoadingIndicator.types'
import * as spinnerLineOnLight from 'assets/data/lottie/spinner-line-on-light.json'
import * as spinnerLineOnDark from 'assets/data/lottie/spinner-line-on-dark.json'
import * as spinnerIosOnLight from 'assets/data/lottie/spinner-ios-on-light.json'
import * as spinnerIosOnDark from 'assets/data/lottie/spinner-ios-on-dark.json'
import * as spinnerIosBlueLine from '@assets/data/lottie/spinner-ios-blue-line.json'

const props = withDefaults(defineProps<NovaLoadingIndicatorProps>(), {
  size: 48,
  bgBright: 'dark',
  fill: true,
  theme: 'default',
  overlay: false,
})

const animationData = computed(() => {
  switch (props.bgBright) {
    case 'light':
      return props.theme === 'ios' ? spinnerIosOnLight : spinnerLineOnLight
    case 'spinner-blue':
      return spinnerIosBlueLine
    case 'dark':
    default:
      return props.theme === 'ios' ? spinnerIosOnDark : spinnerLineOnDark
  }
})
</script>

<template>
  <div :class="['loading-indicator', { fill, overlay }]">
    <LottieAnimation
      ref="lottie-animation"
      class="lottie"
      :loop="true"
      :animation-data="animationData"
      :style="{
        width: `${size}px`,
        height: `${size}px`,
      }"
    />
  </div>
</template>

<style lang="scss" scoped>
.loading-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  @include transition(opacity 0.15s);
  pointer-events: none;

  &.fill {
    width: 100%;
    height: 100%;
    min-height: 260px;
  }

  &.overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
