<script setup lang="ts">
import type { CSSProperties } from 'vue'
import { ref, watch, onMounted } from 'vue'
import type {
  NovaRecyclerGridEmits,
  NovaRecyclerGridProps,
} from './NovaRecyclerGrid.types'

const emits = defineEmits<NovaRecyclerGridEmits>()
const props = withDefaults(defineProps<NovaRecyclerGridProps>(), {
  pageMode: true,
  responsive: () => ({
    mobile: {
      columns: 1,
      columnGap: 12,
      rowGap: 12,
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    tablet: {
      columns: 1,
      columnGap: 12,
      rowGap: 12,
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    desktop: {
      columns: 1,
      columnGap: 12,
      rowGap: 12,
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
  }),
  isLast: false,
  isLoading: false,
  isError: false,
  isRefresh: false,
  errorMessage: '',
  buffer: 300,
  bottomWhiteSpace: 60,
})

const layoutStore = useLayoutStore()
const { responsive } = storeToRefs(layoutStore)
const scrollContainer = ref<HTMLElement | null>(null)
const skeletonRefs = ref<Array<HTMLElement | undefined>>([])
const lastScrollTop = ref(0)
const gridStyle = computed<CSSProperties>(() => {
  const grid = props.responsive[responsive.value]
  return {
    display: 'grid !important',
    gridTemplateColumns: `repeat(${grid.columns}, minmax(0, 1fr))`,
    gridGap: `${useGetStyleSize(grid.rowGap)} ${useGetStyleSize(
      grid.columnGap
    )}`,
  }
})
const scrollContainerStyle = computed<CSSProperties>(() => {
  const grid = props.responsive[responsive.value]

  return {
    padding: `${useGetStyleSize(grid.padding?.top || 0)} ${useGetStyleSize(
      grid.padding?.right || 0
    )} ${
      props.isLoading ? 0 : useGetStyleSize(props.bottomWhiteSpace)
    } ${useGetStyleSize(grid.padding?.left || 0)}`,
  }
})

const detectScrollDirection = () => {
  const currentScrollTop = props.pageMode
    ? window.pageYOffset || document.documentElement.scrollTop
    : scrollContainer.value!.scrollTop

  if (currentScrollTop > lastScrollTop.value) {
    // 스크롤 방향이 아래로
    return 'down'
  } else if (currentScrollTop < lastScrollTop.value) {
    // 스크롤 방향이 위로
    return 'up'
  }

  return null // 스크롤 위치 변동 없음
}

const updatePositions = () => {
  let cumulativeTop = 0
  props.items.forEach((item, index) => {
    item.top = cumulativeTop
    // 아이템이 렌더링되었다면, 해당 아이템의 높이 사용
    if (item.rendered && scrollContainer.value?.children[index]) {
      cumulativeTop += (scrollContainer.value.children[index] as HTMLElement)
        .offsetHeight
    }
    // 아이템이 렌더링되지 않았다면, 스켈레톤의 높이 사용
    else if (skeletonRefs.value[index]) {
      cumulativeTop += skeletonRefs.value[index]!.offsetHeight
    }
  })
}

const loadMore = () => {
  if (props.isLast || props.isLoading || props.isError) return
  emits('loadMore')
}

const scrollEnd = () => {
  const direction = detectScrollDirection()

  if (direction === 'down') {
    // Unified scrolling detection
    const scrollInfo = props.pageMode
      ? {
          scrollHeight: document.body.offsetHeight,
          scrollTop: window.scrollY,
          clientHeight: window.innerHeight,
        }
      : {
          scrollHeight: scrollContainer.value!.scrollHeight,
          scrollTop: scrollContainer.value!.scrollTop,
          clientHeight: scrollContainer.value!.clientHeight,
        }

    if (
      scrollInfo.scrollHeight - scrollInfo.scrollTop <=
      scrollInfo.clientHeight + props.buffer
    ) {
      loadMore()
    }
  }

  // Updated current scroll position
  lastScrollTop.value = props.pageMode
    ? window.pageYOffset || document.documentElement.scrollTop
    : scrollContainer.value!.scrollTop
}

watch(
  () => props.items,
  () => {
    nextTick(() => {
      updatePositions()
    })
  },
  { deep: true }
)

onMounted(() => {
  if (!props.pageMode) {
    scrollContainer.value!.addEventListener('scroll', updatePositions)
    scrollContainer.value!.addEventListener('scroll', scrollEnd)
  } else {
    window.addEventListener('scroll', updatePositions)
    window.addEventListener('scroll', scrollEnd)
  }

  // 초기 위치 업데이트
  updatePositions()
})

onBeforeUnmount(() => {
  if (!props.pageMode) {
    scrollContainer.value!.removeEventListener('scroll', updatePositions)
    scrollContainer.value!.removeEventListener('scroll', scrollEnd)
  } else {
    window.removeEventListener('scroll', updatePositions)
    window.removeEventListener('scroll', scrollEnd)
  }
})
</script>

<template>
  <div
    ref="scrollContainer"
    :class="['nova-recycler-grid', { 'scroll-container-mode': !pageMode }]"
    :style="scrollContainerStyle"
  >
    <div :style="gridStyle">
      <template v-for="(item, index) in items" :key="item[keyName]">
        <div :style="{ top: item.top + 'px' }">
          <!-- 아이템이 렌더링되었는지 확인 -->
          <slot name="item" :item="item" :index="index" />

          <!-- 스켈레톤 슬롯을 렌더링하고 높이 측정 -->
          <div ref="skeletonRefs" class="skeleton">
            <slot name="skeleton" />
          </div>
        </div>
      </template>
    </div>

    <p v-if="!isLoading && isError" class="error-message">
      <NovaIcon :icon="{ type: 'outline', name: 'info' }" />
      {{ errorMessage }}
    </p>

    <NovaLoadingIndicator
      v-if="!isLast && !isError"
      ref="loadingIndicator"
      :size="48"
      :bg-bright="'light'"
      :fill="false"
    />
  </div>
</template>

<style scoped lang="scss">
.nova-recycler-grid {
  width: 100%;

  &.scroll-container-mode {
    height: 100%;
    overflow-y: overlay;
  }
}

.error-message {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  width: 100%;
  height: 48px;
  @include text-style($text-body-14-medium);
  text-align: center;
  color: $color-text-3;
}
</style>
