import W3cfApi from './url'
import * as W3cfTypes from './type'
import { api } from '@api'

export default {
  /**
   * W3CF 리스트 조회
   * @param payload
   */
  fetchW3cfs(payload: W3cfTypes.W3cfsPayload) {
    return api.get<W3cfTypes.W3cfsItem[]>(W3cfApi.FETCH_W3CFS, {
      params: payload,
    })
  },

  /**
   * W3CF 상세 조회
   * @param payload
   */
  async fetchW3cf(payload: W3cfTypes.W3cfPayload) {
    return await api.get<W3cfTypes.W3cf>(W3cfApi.FETCH_W3CF, {
      params: payload,
    })
  },

  /**
   * W3CF 좋아요
   * @param payload
   */
  async reqW3cfLike(payload: W3cfTypes.W3cfLikePayload) {
    return await api.post<{}>(W3cfApi.REQ_W3CF_LIKE, payload)
  },

  /**
   * W3CF 좋아요 취소하기
   * @param payload
   */
  async reqW3cfUnlike(payload: W3cfTypes.W3cfLikePayload) {
    return await api.put<{}>(W3cfApi.REQ_W3CF_LIKE, payload)
  },
}
