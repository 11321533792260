<script setup lang="ts">
import type { RouteParams } from 'vue-router'
import { modalsName, useModal } from '@composables/useModal'
import type { ShowOptionMenu, ShowOptionMenuInfo } from '@store/comm/type'

defineOptions({
  inheritAttrs: false,
})

const show = ref(false)
const menuType = ref('btn')
const menuList = ref<Array<ShowOptionMenuInfo>>([])
const { hide: modalHide } = useModal()
const checkbox = ref<HTMLInputElement[]>([])

// 팝업 닫기
const handleOnClose = async () => {
  await modalHide(modalsName.MODAL_OPTION_MENU)
}

const handleOnGetParams = (evt: RouteParams) => {
  if ((evt.value as unknown as ShowOptionMenu).isInit) {
    checkbox.value.forEach((item) => {
      item.checked = true
    })
  }
  menuType.value = (evt.value as unknown as ShowOptionMenu).menuType
  menuList.value = (evt.value as unknown as ShowOptionMenu).menuList
}
</script>

<template>
  <VueFinalModal
    v-model="show"
    :name="modalsName.MODAL_OPTION_MENU"
    :content-class="'modal-content'"
    classes="modal-container"
    @before-open="(evt: any) => handleOnGetParams(evt.ref.params)"
    @click-outside="handleOnClose"
  >
    <ClientOnly>
      <div v-if="show" class="option-wrap">
        <div class="option-container">
          <a
            href="javascript:void(0);"
            class="btn-close"
            @click="handleOnClose"
          />
          <div v-if="menuType === 'btn'" class="lists-options">
            <button
              v-for="(menu, index) in menuList"
              :key="index"
              @click="menu.fn"
            >
              <i v-if="menu.class" :class="menu.class" />
              <span>{{ menu.name }}</span>
            </button>
            <!-- <button><i class="icon-setting"></i><span>설정</span></button> -->
          </div>

          <div v-if="menuType === 'checkbox'" class="lists-options">
            <label v-for="(menu, index) in menuList" :key="index">
              <input
                ref="checkbox"
                type="checkbox"
                :class="menu.class"
                :value="menu.id"
                checked
                @click="menu.fn"
              />
              <span>{{ menu.name }}</span>
            </label>
          </div>
        </div>
      </div>
    </ClientOnly>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
.option-wrap {
  position: relative;
  z-index: 500;

  .option-container {
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 20;
    padding: 0 0 30px 0;
    border-radius: 12px 12px 0 0;
    width: 100%;

    .btn-close {
      display: block;
      position: relative;
      width: 100%;
      height: 30px;
      text-indent: -999999px;
      &:before {
        display: block;
        content: '';
        width: 80px;
        height: 3px;
        background-color: rgba(0, 0, 0, 0.15);
        border-radius: 3px;
        position: absolute;
        top: 12px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .lists-options {
    display: flex;
    flex-direction: column;

    button {
      width: 100%;
      height: 48px;
      padding: 0 19px;
      display: flex;
      align-items: center;
      gap: 19px;

      i {
        width: 20px;
        height: 20px;
        background-size: 100% 100%;

        &.icon-share {
          background-image: url('assets/images/icon-option-share.png');
        }
        &.icon-setting {
          background-image: url('assets/images/icon-option-setting.png');
        }
        &.icon-delete {
          background-image: url('assets/images/icon-option-delete.png');
        }
        &.icon-scrap {
          background-image: url('assets/images/icon-option-bookmark.png');
        }
        &.icon-edit {
          background-image: url('assets/images/icon-option-edit.png');
        }
        &.icon-donate {
          background-image: url('assets/images/icon-option-donate.png');
        }
        &.icon-lock {
          background-image: url('assets/images/icon-option-lock.png');
        }
        &.icon-media {
          background-image: url('assets/images/icon-option-media.png');
        }
        &.icon-notice {
          background-image: url('assets/images/icon-option-notice.png');
        }
        &.icon-post {
          background-image: url('assets/images/icon-option-post.png');
        }
        &.icon-postcard {
          background-image: url('assets/images/icon-option-postcard.png');
        }
        &.icon-removed {
          background-image: url('assets/images/icon-option-removed.png');
        }
        &.icon-report {
          background-image: url('assets/images/icon-option-report.png');
        }
        &.icon-unlock {
          background-image: url('assets/images/icon-option-unlock.png');
        }
        &.icon-upload {
          background-image: url('assets/images/icon-option-upload.png');
        }
      }

      span {
        @include text-style($text-body-14-medium);
      }
    }
    label {
      width: 100%;
      height: 52px;
      padding: 0 19px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 19px;
      input {
        width: 20px;
        height: 20px;
        display: none;
      }
      span {
        width: 100%;
        font-size: 15px;
        font-weight: bold;
        background-size: 24px 24px;
        background-image: url('assets/images/icon-checkbox.png');
        background-repeat: no-repeat;
        background-position: right center;
      }
      input:checked + span {
        background-image: url('assets/images/icon-checkbox-checked.png');
      }
    }
  }
}
</style>
