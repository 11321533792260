import * as CreatorTypes from './type'
import CreatorApi from './url'
import type {
  RewardCountPayload,
  RewardCount,
  RewardItem,
  RewardDetailPayload,
  RewardDetail,
} from '@store/mypage/type'
import { api } from '@api'

export default {
  /**
   * 프로필 정보 조회
   * @param payload
   */
  fetchProfile(payload: CreatorTypes.CreatorProfilePayload) {
    return api.get<CreatorTypes.CreatorProfile>(CreatorApi.FETCH_PROFILE, {
      params: payload,
    })
  },

  /**
   * 크리에이터 링크를 통한 프로필 정보 조회
   * @param payload
   */
  fetchProfileLink(payload: CreatorTypes.CreatorProfileByCreatorLinkPayload) {
    return api.get<CreatorTypes.CreatorProfile>(CreatorApi.FETCH_PROFILE_LINK, {
      params: payload,
    })
  },

  /**
   * 크리에이터 포스트 목록 조회
   * @param payload
   */
  fetchPosts(payload: CreatorTypes.CreatorContentsItemListPayload) {
    return api.get<CreatorTypes.CreatorContentsItem[]>(CreatorApi.FETCH_POSTS, {
      params: payload,
    })
  },

  /**
   * 크리에이터 총 보상 개수 조회
   * @param payload
   */
  fetchRewardCount(payload: RewardCountPayload) {
    return api.get<RewardCount>(CreatorApi.FETCH_REWARD_COUNT, {
      params: payload,
    })
  },

  /**
   * 내 보상 상세내역 조회
   * @param payload
   */
  fetchRewardDetail(payload: RewardDetailPayload) {
    return api.get<RewardDetail[]>(CreatorApi.FETCH_REWARD_DETAIL, {
      params: payload,
    })
  },

  /**
   * 크리에이터 보상 리스트 조회
   * @param payload
   */
  fetchRewardPosts(payload: CreatorTypes.RewardItemPayload) {
    return api.get<RewardItem[]>(CreatorApi.FETCH_REWARD_POSTS, {
      params: payload,
    })
  },

  /**
   * 크리에이터 링크 중복 조회
   * @param payload
   */
  fetchCheckDuplicateCreatorLink(
    payload: CreatorTypes.CreatorLinkCheckDuplicatePayload
  ) {
    return api.get(CreatorApi.FETCH_CHECK_DUPLICATE_CREATOR_LINK, {
      params: payload,
    })
  },

  /**
   * 크리에이터 링크 수정
   * @param payload
   */
  reqUpdateCreatorLink(payload: CreatorTypes.CreatorLinkUpdatePayload) {
    return api.post(CreatorApi.REQ_UPDATE_CREATOR_LINK, payload)
  },

  /**
   * 크리에이터 방명록 리스트 조회
   * @param payload
   */
  fetchCreatorGuestBooks(payload: CreatorTypes.CreatorGuestBooksPayload) {
    return api.get<CreatorTypes.CreatorGuestBook[]>(
      CreatorApi.FETCH_CREATOR_GUEST_BOOKS,
      { params: payload }
    )
  },

  /**
   * 크리에이터 방명록 상세 조회
   * @param payload
   */
  fetchCreatorGuestBook(payload: CreatorTypes.CreatorGuestBookPayload) {
    return api.get<CreatorTypes.CreatorGuestBook>(
      CreatorApi.FETCH_CREATOR_GUEST_BOOK.replace(
        '{gustSn}',
        String(payload.gustSn)
      )
    )
  },

  /**
   * 크리에이터 홈 방명록 등록
   * @param payload
   */
  regCreatorGuestBook(payload: CreatorTypes.RegCreatorGuestBookPayload) {
    return api.post<CreatorTypes.CreatorGuestBook>(
      CreatorApi.REG_CREATOR_GUEST_BOOK,
      payload
    )
  },

  /**
   * 크리에이터 홈 방명록 수정
   * @param payload
   */
  updateCreatorGuestBook(payload: CreatorTypes.UpdateCreatorGuestBookPayload) {
    return api.put<CreatorTypes.CreatorGuestBook>(
      CreatorApi.UPDATE_CREATOR_GUEST_BOOK,
      payload
    )
  },

  /**
   * 크리에이터 홈 방명록 삭제
   * @param payload
   */
  deleteCreatorGuestBook(payload: CreatorTypes.DeleteCreatorGuestBookPayload) {
    return api.delete(CreatorApi.DELETE_CREATOR_GUEST_BOOK, { data: payload })
  },

  /**
   * 크리에이터 홈탭 정보조회
   * @param payload
   */
  fetchCreatorHome(payload: CreatorTypes.CreatorHomePayload) {
    return api.get<CreatorTypes.CreatorHome>(CreatorApi.FETCH_CREATOR_HOME, {
      params: payload,
    })
  },

  /**
   * 인기 포스트 조회
   * @param payload
   * @returns
   */
  fetchCreatorHomeHotPost(payload: CreatorTypes.CreatorHomePayload) {
    return api.get<CreatorTypes.CreatorContentsItem[]>(
      CreatorApi.FETCH_CREATOR_HOME_HOT_POST,
      { params: payload }
    )
  },

  /**
   * 크리에이터 공지사항 리스트 조회
   * @param payload
   */
  fetchCreatorNoticeList(payload: CreatorTypes.CreatorNoticeListPayload) {
    return api.get<CreatorTypes.CreatorNotice[]>(
      CreatorApi.FETCH_CREATOR_NOTICE_LIST,
      { params: payload }
    )
  },

  /**
   * 크리에이터 임시저장 공지사항 리스트 조회
   * @param payload
   */
  fetchTempCreatorNoticeList(
    payload: CreatorTypes.TempCreatorNoticeListPayload
  ) {
    return api.get<CreatorTypes.CreatorNotice[]>(
      CreatorApi.FETCH_TEMP_CREATOR_NOTICE_LIST,
      {
        headers: {
          Authorization: `${payload.grantType} ${payload.accessToken}`,
        },
      }
    )
  },

  /**
   * 크리에이터 공지사항 상세 조회
   * @param payload
   */
  fetchCreatorNotice(payload: CreatorTypes.CreatorNoticePayload) {
    return api.get<CreatorTypes.CreatorNotice>(
      CreatorApi.FETCH_CREATOR_NOTICE.replace(
        '{crtrNttSn}',
        String(payload.crtrNttSn)
      )
    )
  },

  /**
   * 크리에이터 홈 공지사항 등록
   * @param payload
   */
  regCreatorNotice(payload: CreatorTypes.RegCreatorNoticePayload) {
    return api.post<CreatorTypes.CreatorNotice>(
      CreatorApi.REG_CREATOR_NOTICE,
      payload
    )
  },

  /**
   * 크리에이터 홈 공지사항 수정
   * @param payload
   */
  updateCreatorNotice(payload: CreatorTypes.UpdateCreatorNoticePayload) {
    return api.put<CreatorTypes.CreatorNotice>(
      CreatorApi.UPDATE_CREATOR_NOTICE,
      payload
    )
  },

  /**
   * 크리에이터 홈 공지사항 삭제
   * @param payload
   */
  deleteCreatorNotice(payload: CreatorTypes.DeleteCreatorNoticePayload) {
    return api.delete(
      CreatorApi.DELETE_CREATOR_NOTICE.replace(
        '{crtrNttSn}',
        String(payload.crtrNttSn)
      ),
      {
        headers: {
          Authorization: `${payload.grantType} ${payload.accessToken}`,
        },
        data: { creatorUserSn: payload.creatorUserSn },
      }
    )
  },

  /**
   * 크리에이터 소셜 목록 조회
   * @param payload
   */
  fetchCreatorSocials(payload: CreatorTypes.CreatorSnslinkPayload) {
    return api.get<CreatorTypes.CreatorSocial[]>(
      `${CreatorApi.FETCH_CREATOR_SNS_LINKS}?creatorUserSn=${payload.creatorUserSn}`
    )
  },

  /**
   * 크리에이터 소셜 목록 업데이트
   * @param payload
   */
  updateCreatorSocials(payload: CreatorTypes.RegCreatorSocial[]) {
    return api.post<CreatorTypes.CreatorSocial[]>(
      CreatorApi.FETCH_CREATOR_SNS_LINKS,
      payload
    )
  },

  /**
   * 크리에이터 통계탭 통계정보 조회
   * @param payload
   */
  fetchCreatorStatsInfo(payload: CreatorTypes.CreatorStatPayload) {
    return api.get<CreatorTypes.CreatorStatItem>(CreatorApi.FETCH_STAT_INFO, {
      params: payload,
    })
  },

  /**
   * 크리에이터 통계탭 공개포스터 조회
   * @param payload
   */
  fetchCreatorShareStatsList(payload: CreatorTypes.CreatorShareStatPayload) {
    return api.get<CreatorTypes.CreatorShareStatItem[]>(
      CreatorApi.FETCH_STAT_SHARE_LIST,
      {
        params: payload,
      }
    )
  },

  /**
   * 크리에이터 소개 등록/수정
   * @param payload
   */
  regCreatorIntoduction(payload: CreatorTypes.RegCreatorIntroductionPayload) {
    return api.post(CreatorApi.REG_CREATOR_INTRODUCTION, payload)
  },

  /**
   * 크리에이터 신청 요건 충족 정보 조회
   */
  fetchApplyCreatorConditions() {
    return api.get<CreatorTypes.ApplyCreatorCondition[]>(
      CreatorApi.FETCH_APPLY_CREATOR_CONDITIONS
    )
  },

  /**
   * 크리에이터 신청: 심사 요건중 하나 이상 충족시
   * @param payload
   */
  reqApplyCreatorSatisfyYes(
    payload: CreatorTypes.ApplyCreatorSatisfyYesPayload
  ) {
    return api.post(CreatorApi.REQ_APPLY_CREATOR_SATISFY_YES, payload)
  },

  /**
   * 크리에이터 신청
   */
  reqApplyCreator(payload: CreatorTypes.ApplyCreatorPayloadFormData) {
    return api.post(CreatorApi.REQ_APPLY_CREATOR, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },

  /**
   * 크리에이터 카테고리 목록 조회
   * @param payload
   */
  fetchCreatorCategories(payload: CreatorTypes.CreatorCategoriesPayload) {
    return api.get<CreatorTypes.CreatorCategory[]>(
      CreatorApi.FETCH_CREATOR_CATEGORIES,
      { params: payload }
    )
  },

  /**
   * 크리에이터 목록 조회: 홈
   * @param payload
   */
  fetchCreatorsByHome(payload: CreatorTypes.CreatorsByHomePayload) {
    return api.get<CreatorTypes.CreatorByHome[]>(
      CreatorApi.FETCH_CREATORS_BY_HOME,
      { params: payload }
    )
  },

  /**
   * 크리에이터 목록 조회: 전체
   * @param payload
   */
  fetchCreatorsByAll(payload: CreatorTypes.CreatorsByAllPayload) {
    return api.get<CreatorTypes.CreatorByAll[]>(
      CreatorApi.FETCH_CREATORS_BY_ALL,
      { params: payload }
    )
  },

  /**
   * 크리에이터 목록 조회: 노바+ (추천)
   * @param payload
   */
  fetchCreatorsByRecommended(
    payload: CreatorTypes.CreatorsByRecommendedPayload
  ) {
    return api.get<CreatorTypes.CreatorByRecommended[]>(
      CreatorApi.FETCH_CREATORS_BY_RECOMMENDED,
      { params: payload }
    )
  },

  /**
   * 크리에이터 대표 프로필 사진등록/변경
   * @param payload
   */
  regCreatorImageUpdate(payload: FormData) {
    return api.post<string>(CreatorApi.REQ_UPDATE_CREATOR_IMAGE, payload)
  },

  /**
   * 크리에이터 카테고리 변경
   * @param payload
   */
  updateCreatorCategory(payload: CreatorTypes.UpdateCreatorCategory) {
    return api.put<CreatorTypes.UpdateCreatorCategory>(
      CreatorApi.UPDATE_CREATOR_CATEGORY,
      payload
    )
  },

  /**
   * 크리에이터 프리미엄 약관 목록 조회
   */
  fetchPremiumTerms(payload: CreatorTypes.PremiumTermsPayload) {
    return api.get<CreatorTypes.PremiumTerm[]>(CreatorApi.FETCH_PREMIUM_TERMS, {
      params: payload,
    })
  },
  /**
   * 크리에이터 프리미엄 약관 상세 조회
   */
  fetchPremiumTerm(payload: CreatorTypes.PremiumTermPayload) {
    return api.get<CreatorTypes.PremiumTermDetail>(
      CreatorApi.FETCH_PREMIUM_TERM,
      {
        params: payload,
      }
    )
  },

  /**
   * 크리에이터 프리미엄 상품(구독) 정보 조회
   */
  fetchPremium(payload: CreatorTypes.PremiumPayload) {
    return api.get<CreatorTypes.Premium>(CreatorApi.FETCH_PREMIUM, {
      params: payload,
    })
  },
  /**
   * 크리에이터 프리미엄 개설(신청) 정보(혜택/유의사항) 조회
   */
  fetchPremiumApplication() {
    return api.get<CreatorTypes.PremiumApplication>(
      CreatorApi.FETCH_PREMIUM_APPLICATION
    )
  },

  /**
   * 크리에이터 프리미엄 개설(신청)
   */
  reqPremiumApplication(payload: CreatorTypes.PremiumApplicationOpenedPayload) {
    return api.post(CreatorApi.REQ_PREMIUM_APPLICATION, payload)
  },

  /**
   * 프리미엄 혜택 목록 수정
   */
  updatePremiumBenefits(payload: CreatorTypes.UpdatePremiumBenefitsPayload) {
    return api.put(CreatorApi.UPDATE_PREMIUM_ITEMS, payload)
  },

  /**
   * 크리에이터 프리미엄 혜택 목록 조회
   */
  fetchPremiumBenefits(payload: CreatorTypes.PremiumBenefitsPayload) {
    return api.get<CreatorTypes.PremiumBenefits>(
      CreatorApi.FETCH_PREMIUM_BENEFITS,
      { params: payload }
    )
  },

  /**
   * 크리에이터 프리미엄 혜택 목록 조회
   */
  fetchPremiumBenefitsAll(payload: CreatorTypes.PremiumBenefitsPayload) {
    return api.get<CreatorTypes.PremiumBenefit[]>(
      CreatorApi.FETCH_PREMIUM_BENEFITS_ALL,
      { params: payload }
    )
  },

  /**
   * 크리에이터 프리미엄 후원(구독) 신청
   */
  reqPremiumDonate(payload: CreatorTypes.PremiumDonatePayload) {
    return api.post<CreatorTypes.PremiumDonate>(
      CreatorApi.REQ_PREMIUM_DONATE,
      payload
    )
  },

  /**
   * 크리에이터 프리미엄 후원(구독) 결제 완료 여부, 주문 정보 조회
   */
  fetchPremiumPaymentTradeInfo(
    payload: CreatorTypes.PremiumPaymentTradeInfoPayload
  ) {
    return api.get<CreatorTypes.PremiumPaymentTradeInfo>(
      CreatorApi.FETCH_PREMIUM_PAYMENT_TRADE_INFO,
      { params: payload }
    )
  },

  /**
   * 크리에이터 정산 은행 정보 등록
   */
  createCreatorBank(payload: CreatorTypes.CreatorBankPayload) {
    return api.post(CreatorApi.CREATE_CREATOR_BANK, payload)
  },

  /**
   * 노바+ 후원자 목록 조회
   */
  fetchPremiumSupporters(payload: CreatorTypes.PremiumSupportersPayload) {
    return api.get<CreatorTypes.PremiumSupportersRes>(
      CreatorApi.FETCH_PREMIUM_SUPPORTERS,
      {
        params: payload,
      }
    )
  },

  /**
   * 나의 크리에이터 목록 조회
   */
  fetchMyCreators(payload: CreatorTypes.MyCreatorsPayload) {
    return api.get<CreatorTypes.MyCreators>(CreatorApi.FETCH_MY_CREATORS, {
      params: payload,
    })
  },

  /**
   * 크리에이터 목록 조회
   */
  fetchCreators(payload: CreatorTypes.AllCreatorsPayload) {
    return api.get<CreatorTypes.Creators>(CreatorApi.FETCH_CREATORS, {
      params: payload,
    })
  },
}
