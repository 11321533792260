import type { SuperMoonState } from './type'

export const initSuperMoonState: SuperMoonState = {
  superMoonTerms: [],
  applyStatus: {
    applcntReprsntLglrpsLmttAgeAt: 'N',
    applicationAvailableAt: 'N',
    fstvlApprtcActSttusUpdtDt: '',
    fstvlApprtcAt: 'N',
    fstvlApprtcEndDt: '',
    fstvlApprtcIntrCo: 0,
    fstvlApprtcSubmCo: 0,
    fstvlSectCode: 'P',
  },
}
