import {
  FcmHandler,
  type FcmPayload,
  type FcmPayloadData,
  FirebaseExceptionStatus,
} from './types'
import { FirebaseException } from './exception'
import { fcmActions } from './fcmActions'

/**
 * Firebase Cloud Messaging payload max
 */
export class FcmPayloadMax {
  name = 'FCM_PAYLOAD_MAX'
  data
  notification
  fcmOptions
  from
  collapseKey
  messageId

  constructor({
    data,
    notification,
    fcmOptions,
    from,
    collapseKey,
    messageId,
  }: FcmPayload) {
    this.data = data
    this.notification = notification
    this.fcmOptions = fcmOptions
    this.from = from
    this.collapseKey = collapseKey
    this.messageId = messageId
  }
}

/**
 * Firebase Cloud Messaging payload min
 */
export class FcmPayloadMin {
  name = 'FCM_PAYLOAD_MIN'
  type
  action
  url

  constructor({ type, action, url }: FcmPayloadData) {
    this.type = type
    this.action = action
    this.url = url
  }
}

/**
 * 페이로드를 확인하고 페이로드의 유형에 따라 적절한 데이터를 반환
 *
 * @param {FcmPayloadMax | FcmPayloadMin} payload - 확인할 FCM 페이로드.
 * @returns {Object} - 페이로드에서의 데이터 객체.
 * @throws {FirebaseException} - FCM 메시징 페이로드 모드가 일치하지 않는 경우.
 */
const checkPayload = (payload: FcmPayloadMax | FcmPayloadMin) => {
  if (payload instanceof FcmPayloadMax) {
    console.log('[FCM call handler payload(MAX)]:', payload)
    return payload.data
  }

  if (payload instanceof FcmPayloadMin) {
    console.log('[FCM call handler payload(MIN)]:', payload)
    return payload
  }

  throw new FirebaseException({
    message: 'fcm messaging payload mode is not match',
    cause: {
      status: FirebaseExceptionStatus.MESSAGING_PAYLOAD_MODE_IS_NOT_MATCH,
      data: payload,
    },
  })
}

/**
 * 주어진 페이로드를 기준으로 FCM 호출을 처리
 *
 * @param {FcmPayloadMax | FcmPayloadMin} payload - FCM 페이로드
 */
export const fcmCallHandler = (payload: FcmPayloadMax | FcmPayloadMin) => {
  let data: FcmPayloadData | null = null

  data = checkPayload(payload) // 검사된 payload 데이터로 초기화

  // 액션 타입에 따른 헨들러 겍체를 정의
  const handlers = {
    [FcmHandler.ALARM]: () =>
      console.log('[FCM call handler type]:', FcmHandler.ALARM),
    [FcmHandler.DEEP_LINK]: () =>
      console.log('[FCM call handler type]:', FcmHandler.DEEP_LINK),
    [FcmHandler.SYSTEM_EVENT]: () =>
      console.log('[FCM call handler type]:', FcmHandler.SYSTEM_EVENT),
  }

  if (handlers[data.type]) {
    handlers[data.type]()
    fcmActions[data.action](data.url)
  } else {
    throw new FirebaseException({
      message: 'fcm handler type is not match',
      cause: {
        status: FirebaseExceptionStatus.HANDLER_TYPE_IS_NOT_MATCH,
        data: payload,
      },
    })
  }
}
