import type {
  CreateLivePayload,
  CreateOpenChannelPayload,
  BooleanYn,
} from '@store'

/**
 * Sendbird error status codes
 */
export enum SendbirdExceptionStatus {
  LIVE_EVENT_ID_IS_NOT_EXIST = 'liveEventIdIsNotExist',
  CREATE_LIVE_EVENT_UNAVAILABLE = 'createLiveEventUnavailable',
  CHANNEL_URL_IS_NOT_EXIST = 'channelUrlIsNotExist',
  CREATE_OPEN_CHANNEL_UNAVAILABLE = 'createOpenChannelUnavailable',
}

/**
 * Sendbird locale
 */
export type SendbirdLocale = 'ko' | 'en' | 'th' | 'es'

/**
 * Live mode
 */
export enum LiveMode {
  HOST = 'host', // 호스트(크리에이터)
  GUEST = 'guest', // 게스트(후원자)
}

/**
 * Check create live available
 */
export interface CheckCreateLiveAvailableProps {
  userId: string
  onLoading?: (loading: boolean) => void
}

/**
 * Create live props
 */
export interface CreateLiveProps extends CreateLivePayload {
  onLoading?: (loading: boolean) => void
}

/**
 * Enter live by guest props
 */
export interface EnterLiveByGuestProps {
  mode: LiveMode // 라이브 진입 모드
  checkPermissionType: 'sndbrdLiveSn' | 'liveEventId' // 라이브 일련 번호 혹은, 라이브 이벤트 아이디로 접근 방법
  sndbrdLiveSn?: number | null // 라이브 일련 번호
  liveEventId?: string | null // 라이브 이벤트 아이디
  liveOnAirAt: BooleanYn | null // 라이브 OnAir 상태
  onLoading?: (loading: boolean) => void // 로딩
}

/**
 * Open live props
 */
export interface OpenLiveProps {
  mode: LiveMode // 모드
  userId: string // 사용자 일련번호(Sendbird)
  liveEventId: string // host 모드 진입시 필수
  locale?: SendbirdLocale // 언어
}

/**
 * Chat Mode
 */
export enum ChatMode {
  CHANNEL = 'channel',
  CHANNEL_LIST = 'channelList',
}

/**
 * Create chat channel
 */
export interface CreateChatChannelProps {
  mode: 'showCreateChannelModal' | 'createOpenChannel'
  createOpenChannelPayload?: CreateOpenChannelPayload // createOpenChannel 모드일때 필수
  onLoading?: (loading: boolean) => void
}

/**
 * Enter Chat
 */
export interface EnterChatProps {
  mode: ChatMode // 채널 직접 접근 혹은 채널 리스트 접근
  channelUrl?: string // 채널 직접 접근 필요한 채널 Url
  onLoading?: (loading: boolean) => void // 로딩
}

/**
 * Open chat props
 */
export interface OpenChatProps {
  mode: ChatMode
  userId: string
  channelUrl?: string
  locale?: SendbirdLocale
}
