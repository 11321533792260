import { api } from '@/api'
import type { AuthToken } from '~/store/user/type'

/**
 * 서비스 서버사이드에서 개인화된 정보를 조회하기 위해 API Request Header에 서버 리퀘스트 헤더의 토큰을 찾아 주입,
 * 서비스 서버사이드에서 api 호출 시 엑세스 획득 가능한 키 주입
 */
export default defineNuxtRouteMiddleware(() => {
  if (process.server) {
    const { cookieNames, getCookie } = useCookies()
    const config = useRuntimeConfig()
    const requestEvent = useRequestEvent()
    const authToken = getCookie<AuthToken>(
      cookieNames.AUTH_TOKEN,
      requestEvent?.node.req.headers.cookie
    )

    // 1. set access token
    if (authToken) {
      api.defaults.headers.common.Authorization = `${authToken.grantType} ${authToken.accessToken}`
    } else {
      delete api.defaults.headers.common.Authorization
    }

    // 2. set server only access secret key
    api.defaults.headers.common['Access-Secret-Key'] =
      config.serverApiAccessSecretKey
  }
})
