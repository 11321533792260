import {
  NotificationMarketingAgreeCode,
  type BooleanYn,
  type NotificationServiceSetting,
  type UpdateNotificationServicePayload,
} from '@store'
import { NovaModalDialog } from '#components'

export const useUpdateNotification = () => {
  const config = useRuntimeConfig()
  const { t } = useNuxtApp().$i18n
  const userStore = useUserStore()
  const etcStore = useEtcStore()
  const { show: modalShow, hide: modalHide } = useModal()
  const { dayjs } = useDayjs()

  // 마케팅 타입 다국어 추출 (push, email, sms)
  const getNotificationMarketingTypeStr = (
    code: NotificationMarketingAgreeCode
  ) => {
    const marketingCodeIdx = Object.values(
      NotificationMarketingAgreeCode
    ).indexOf(code)
    const marketingType = Object.keys(NotificationMarketingAgreeCode)[
      marketingCodeIdx
    ]
    const type = marketingType.toLowerCase()
    return {
      key: type,
      name: t(`notificationSetting.notificationType.${type}`),
    }
  }

  // 토스트 메세지 출력()
  const showChangeAgreeToastMessage = (agree: BooleanYn) => {
    // 타임존 데이트 받기
    const now = dayjs.utc().toDate()
    const diff = dayjs().utcOffset() / 60
    const _date = `${dayjs(now).format('YYYY.MM.DD')} GMT${
      diff >= 0 ? '+' : ''
    }${Math.abs(diff)}`

    // 동의 상태에 따른 키 값 도출
    const agreementKeySuffix = agree === 'Y' ? 'agree' : 'deny'

    const marketing = (marketingAgreeCode: NotificationMarketingAgreeCode) => {
      const { name } = getNotificationMarketingTypeStr(marketingAgreeCode)

      // 메세지에 사용될 데이터 정의
      const messageData = {
        appName: config.public.APP_NAME,
        type: name,
        date: _date,
      }

      // 메세지 생성 및 출력
      const message = t(
        `notificationSetting.toastMessage.marketing.${agreementKeySuffix}`,
        messageData
      )
      useToast(message)
    }

    return {
      marketing,
    }
  }

  // 서비스 알림 설정 업데이터: 베이스
  const updateNotificationService = async (
    payload: UpdateNotificationServicePayload,
    loader?: (
      _serviceId: NotificationServiceSetting['ntcnSetupId'] | undefined
    ) => void
  ) => {
    try {
      loader?.(payload.ntcnSetupId)
      await useSleeper()
      await etcStore.updateNotificationService(payload)
    } catch {
      useToast(t('commonError.network.message'))
    } finally {
      loader?.(undefined)
    }
  }

  // 서비스 알림 설정 업데이트
  const handleOnUpdateNotificationService = async (
    payload: UpdateNotificationServicePayload,
    loader?: (
      _serviceId: NotificationServiceSetting['ntcnSetupId'] | undefined
    ) => void
  ) => {
    if (payload.svcNtcnRecptnSetupAt === 'N') {
      await modalShow({
        component: NovaModalDialog,
        bind: {
          name: modalsName.MODAL_DIALOG,
          contentsTextAlign: 'center',
          btns: [
            {
              label: t('notificationSetting.serviceDialog.cancel'),
              theme: 'transparent',
              size: 32,
              onClick: async () => {
                await modalHide(modalsName.MODAL_DIALOG)
                await updateNotificationService(payload, loader)
              },
            },
            {
              label: t('notificationSetting.serviceDialog.confirm'),
              theme: 'primary-blue-light',
              size: 32,
              onClick: async () => {
                await modalHide(modalsName.MODAL_DIALOG)
              },
            },
          ],
        },
        slots: {
          title: t('notificationSetting.serviceDialog.title'),
          content: t('notificationSetting.serviceDialog.contents'),
        },
      })
    } else {
      await updateNotificationService(payload, loader)
    }
  }

  // 마케팅 알림 설정 업데이트: 베이스
  const updateNotificationMarketing = async (
    marketingCode: NotificationMarketingAgreeCode,
    value: BooleanYn,
    loader: (marketingCode: NotificationMarketingAgreeCode | undefined) => void
  ) => {
    try {
      loader?.(marketingCode)
      await userStore.updateNotificationMarketing({
        stplatAgreAt: value,
        stplatTyCode: marketingCode,
      })
    } catch {
      useToast(t('commonError.network.message'))
    } finally {
      loader?.(undefined)
    }
  }

  // 마케팅 알림 설정 업데이트
  const handleOnUpdateNotificationMarketing = async (
    marketingCode: NotificationMarketingAgreeCode,
    value: BooleanYn,
    loader: (marketingCode: NotificationMarketingAgreeCode | undefined) => void
  ) => {
    const { marketing: showMarketingTM } = showChangeAgreeToastMessage(value)

    if (value === 'N') {
      const marketingTypeStr =
        getNotificationMarketingTypeStr(marketingCode).name

      await modalShow({
        component: NovaModalDialog,
        bind: {
          name: modalsName.MODAL_DIALOG,
          contentsTextAlign: 'center',
          btns: [
            {
              label: t('notificationSetting.marketingDialog.cancel'),
              theme: 'transparent',
              size: 32,
              onClick: async () => {
                await modalHide(modalsName.MODAL_DIALOG)
                await updateNotificationMarketing(marketingCode, value, loader)
                showMarketingTM(marketingCode)
              },
            },
            {
              label: t('notificationSetting.marketingDialog.confirm'),
              theme: 'primary-blue-light',
              size: 32,
              onClick: async () => {
                await modalHide(modalsName.MODAL_DIALOG)
              },
            },
          ],
        },
        slots: {
          title: t('notificationSetting.marketingDialog.title', {
            type: marketingTypeStr,
          }),
          content: t('notificationSetting.marketingDialog.contents', {
            appName: config.public.APP_NAME,
          }),
        },
      })
    } else {
      await updateNotificationMarketing(marketingCode, value, loader)
      showMarketingTM(marketingCode)
    }
  }

  return {
    handleOnUpdateNotificationService,
    handleOnUpdateNotificationMarketing,
  }
}
