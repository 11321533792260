/**
 * Represents the block access information.
 */
export const blockAccess = {
  modal: {
    title: '활동 정지 안내',
    contents: {
      activity: '활동정지 회원은 서비스를 이용할 수 없습니다.',
      signin:
        '회원님께서는 현재 활동정지 상태입니다.<br /><br />활동 정지가 해제되기 전까지 글쓰기, 댓글, 좋아요 등의 활동이 정지되며, 활동 정지가 해제되면 커뮤니티를 이용하실 수 있습니다.<br /><br />활동 정지 기간: {period}',
    },
  },
  eternalBlocked: '영구정지',
}
