/**
 * 알림 권한
 */
export const notificationPermission = {
  actions: {
    cancel: 'Cancel',
    ok: 'OK',
    requestPermissions: 'Request',
    config: 'Settings',
    notNow: 'Later',
  },
  modals: {
    beforeRequest: {
      title: 'Notification Settings',
      message:
        'We would like to inform you about the new activity reaction on the website.',
    },
  },
}
