import * as SuperMoonTypes from './type'
import SuperMoonService from './service'
import { initSuperMoonState } from './init'

export * from './init'
export * from './type'
export const useSuperMoonStore = defineStore('supermoon', () => {
  const mypageStore = useMyPageStore()
  const superMoonTerms = ref(initSuperMoonState.superMoonTerms)
  const applyStatus = ref(initSuperMoonState.applyStatus)

  const superMoonApplyStatus = computed(() => {
    const profile = mypageStore.profile ? mypageStore.profile : undefined
    return useSuperMoonApplyAvailableStatus({
      status: applyStatus.value,
      profile,
    })
  })
  /**
   * 슈퍼문 참가 신청
   */
  const reqSuperMoonApply = async (
    payload: SuperMoonTypes.SuperMoonApplyFormPayload
  ) => {
    const formData = new FormData()
    const flattenPayload = useFlattenObject(payload)
    Object.keys(flattenPayload).forEach((key) => {
      const value = flattenPayload[key]
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          formData.append(`${key}[${index}]`, item)
        })
      } else if (value !== null && value !== undefined) {
        formData.append(key, value)
      }
    })
    await SuperMoonService.reqSuperMoonApply(formData)
  }

  /**
   * 슈퍼문 참가 신청 로그 등록
   */
  const reqSuperMoonApplyLog = async () => {
    await SuperMoonService.reqSuperMoonApplyLog()
  }

  /**
   * 슈퍼문 신청 요건 정보 조회
   */
  const fetchSuperMoonApplyStatus = async () => {
    const { data } = await SuperMoonService.fetchSuperMoonApplyStatus()
    applyStatus.value = data
    return data
  }

  /**
   * 슈퍼문 약관 상세 조회
   */
  const fetchSuperMoonTerm = async (payload: number) => {
    const { data } = await SuperMoonService.fetchSuperMoonTerm(payload)
    return data
  }

  /**
   * 슈퍼문 약관 목록 조회
   */
  const fetchSuperMoonTerms = async () => {
    const { data } = await SuperMoonService.fetchSuperMoonTerms()
    superMoonTerms.value = data
    return data
  }

  return {
    superMoonTerms,
    applyStatus,
    reqSuperMoonApply,
    reqSuperMoonApplyLog,
    fetchSuperMoonTerms,
    fetchSuperMoonTerm,
    fetchSuperMoonApplyStatus,
    superMoonApplyStatus,
  }
})
