import { useLayoutStore } from '@/store'

/**
 * 시스템 뒤로가기 모달 닫기
 */
export default defineNuxtRouteMiddleware(async (to, from) => {
  const layoutStore = useLayoutStore()
  const { hide: modalHide } = useModal(to)

  // console.log('TEST BACK from query modals', from.query.modals)
  // console.log('TEST BACK layoutStore.modals', layoutStore.modals)

  if (
    process.client &&
    from.query.modals &&
    (from.query.modals === layoutStore.modals[0]?.modalNameKey ||
      from.query.modals[0] === layoutStore.modals[0]?.modalNameKey)
  ) {
    if (layoutStore.modals[0]) {
      await modalHide(
        layoutStore.modals[0].modalNameKey,
        layoutStore.modals[0].routeSync,
        true
      )
    }
  }
})
