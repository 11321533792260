<script setup lang="ts">
import type { NovaBoxBaseProps } from './NovaBoxBase.types'

const props = withDefaults(defineProps<NovaBoxBaseProps>(), {
  floating: false,
  modal: () => ({ type: 'default', isMobileFull: false }),
  round: 16,
})

const roundClass = computed(() => `round-${props.round}`)
</script>

<template>
  <section
    :class="[
      'box-base',
      { floating: floating },
      { modal: modal.type === 'modal' },
      { 'is-mobile-full': modal.isMobileFull },
      roundClass,
    ]"
  >
    <slot />
  </section>
</template>

<style lang="scss" scoped>
.box-base {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  border-radius: 16px;
  background-color: $color-bg-3;

  &.floating {
    box-shadow: 0 4px 20px hex-to-rgba($color-black, 0.08);
  }

  &.modal {
    box-shadow: 5px 5px 10px hex-to-rgba($color-bg-custom-4, 0.05);

    @include mobile {
      padding: 0;
    }
  }

  &.round-16 {
    border-radius: 16px;
  }

  &.round-20 {
    border-radius: 20px;
  }

  &.is-mobile-full {
    @include mobile {
      border-radius: 0;
      height: 100%;
      width: 100%;
      max-width: 100%;
    }
  }
}
</style>
