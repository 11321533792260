import type {
  SignInMessageType,
  SignUpMessageType,
  SearchPwMessageType,
} from '@mixins'
import type { PayMessageType } from '@composables/usePay'

/**
 * 포스트 매세지 발신 종류
 */
export type PostMessageSendType =
  | 'opener' // 윈도우 팝업에서 포스트 메세지 발신
  | 'parent' // 아이프레임에서 포스트 메세지 발신

/**
 * 앱 전역에서 포스트 메세지 발신, 수신
 */
export default () => {
  const sender = (
    sendType: PostMessageSendType,
    payload:
      | SignInMessageType
      | SignUpMessageType
      | PayMessageType
      | SearchPwMessageType,
    selfClose = true
  ) => {
    if (!window[sendType] && sendType === 'opener') {
      window[sendType] = window.open('', payload.type)
    }

    window[sendType].postMessage(payload)

    if (sendType === 'opener' && selfClose) {
      window.close()
    }
  }

  const receiver = (
    payload: MessageEvent<
      | SignInMessageType
      | SignUpMessageType
      | PayMessageType
      | SearchPwMessageType
    >
  ) => {
    if (!payload.data.type) return
    const { signIn, signUp } = useMembershipProcess()
    const { payCheck } = usePay()

    switch (payload.data.type) {
      case 'signin':
        return signIn(payload.data.res)
      case 'signup':
        return signUp(payload.data.res)
      case 'pay':
        return payCheck(payload.data.res)
      case 'searchpw':
        break
      // return password(payload.data.res)
      default:
        throw new Error('receive message gate type is not match')
    }
  }

  return {
    sender,
    receiver,
  }
}
