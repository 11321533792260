import apiPath from '@/store/apiPath'

export default {
  /**
   * 시설물 입대 신청
   * @method POST
   */
  REQ_RENTAL: `${apiPath.FACILITY}/v1/rental`,

  /**
   * 시설물 임대 신청 요건 조회
   * @method GET
   */
  FETCH_RENTAL_CHECK_CONDITIONS: `${apiPath.FACILITY}/v1/rental/check-conditions`,

  /**
   * 시설물 임대 약관 상세 조회
   * @method GET
   */
  FETCH_RENTAL_TERM: `${apiPath.FACILITY}/v1/rental/term`,

  /**
   * 시설물 임대 약관 목록 조회
   * @method GET
   */
  FETCH_RENTAL_TERMS: `${apiPath.FACILITY}/v1/rental/terms`,
}
