import type { AxiosInstance } from 'axios'
import env, { type Envs } from '@configs/env'

export const createBaseUrl = () => {
  if (process.server) {
    const mode = process.env.MODE as Envs
    return `${env[mode].API_URL}/api`
  } else {
    const runtimeConfig = useRuntimeConfig()
    return `${runtimeConfig.public.API_URL}/api`
  }
}

export const setBaseUrl = (instance: AxiosInstance) => {
  instance.interceptors.request.use((config) => {
    const baseUrl = createBaseUrl()
    config.baseURL = baseUrl

    return config
  })
}
