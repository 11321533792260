import type { ByteScopeType } from './useCheckByte.types'
import { useCalcByte } from './useCalcByte'

/**
 * 주어진 텍스트의 바이트 길이가 특정 범위 요구사항을 충족하는지 여부를 확인
 *
 * @param {string} text - 바이트 길이를 확인할 텍스트
 * @param {number} length - 바이트 길이를 비교할 크기
 * @param {ByteScopeType} scope - 범위 요구사항: 'under'(미만), 'and_under'(이하), 'over'(초과), 'and_over'(이상), 'equal'(동일)
 * @returns {boolean} - 바이트 길이가 범위 요구사항을 충족하면 true를 반환하고, 그렇지 않으면 false를 반환
 * @throws {Error} - scope 파라미터가 필요한 값 중 하나가 아닌 경우 오류를 발생
 */
export const useCheckByte = (
  text: string,
  length: number,
  scope: ByteScopeType
): boolean => {
  switch (scope) {
    case 'under':
      return useCalcByte(text) < length
    case 'and_under':
      return useCalcByte(text) <= length
    case 'over':
      return useCalcByte(text) > length
    case 'and_over':
      return useCalcByte(text) >= length
    case 'equal':
      return useCalcByte(text) === length
    default:
      throw new Error(
        'check byte required scope of [under, and_under, over, and_over, equal]'
      )
  }
}
