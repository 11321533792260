import { api } from '@api'

/**
 * 언어 설정을 api 헤더에 주입
 */
export default defineNuxtRouteMiddleware(() => {
  const nuxtApp = useNuxtApp()
  api.defaults.headers.common['accept-language'] = String(
    (nuxtApp.$i18n as any).locale.value
  ).toUpperCase()
})
