import { useUserStore, useEtcStore, usePostStore } from '@/store'

/**
 * 앱구동에 필요한 필수 api를 호출
 */
export default defineNuxtRouteMiddleware(async (to, from) => {
  const userStore = useUserStore()
  const etcStore = useEtcStore()
  const postStore = usePostStore()
  const requiredApis = []
  const isFirstLanding = to.fullPath === from.fullPath
  const isLocaleChanged = (() => {
    const getLocale = (name: string) => name.split('___').pop()
    return getLocale(String(to.name)) !== getLocale(String(from.name))
  })()
  // 콜백 페이지에서는 필수 api 호출 금지
  if (to.name?.toString().includes('callback-')) {
    return
  }

  // 사용자에게 새로운 알림이 있는지 조회
  if (userStore.isSignIn && isFirstLanding)
    requiredApis.push(
      useAsyncData('newAlarmCount', () => etcStore.fetchNewAlarmCount())
    )

  // 카테고리 목록 조회 (etag 사용함)
  if (isFirstLanding || isLocaleChanged || !postStore.categories.length)
    requiredApis.push(
      useAsyncData('categories', () => postStore.fetchCategories(false))
    )

  // 어드민 관리 해시태그 목록 조회 (etag 사용함)
  if (isFirstLanding || isLocaleChanged || !postStore.baseHashtags.length)
    requiredApis.push(
      useAsyncData('baseHashtags', () => postStore.fetchBaseHashtags())
    )

  // 정책 목록 조회 (etag 사용하지 않지만 언어 변경시 조회)
  if (isFirstLanding || isLocaleChanged || !etcStore.terms.length)
    requiredApis.push(useAsyncData('terms', () => etcStore.fetchTerms()))

  // 문의 분류 리스트 조회 (etag 사용하지 않지만 언어 변경시 조회)
  if (isFirstLanding || isLocaleChanged || !etcStore.classifications.length)
    requiredApis.push(
      useAsyncData('classifications', () => etcStore.fetchClassifications())
    )

  // 신고 코드 리스트 조회 (etag 사용하지 않지만 언어 변경시 조회)
  /*  if (isFirstLanding || isLocaleChanged || !etcStore.reportCodes.length)
    requiredApis.push(
      useAsyncData('reportCodes', () => etcStore.fetchReportCodes())
    ) */

  try {
    await Promise.all(requiredApis)
  } catch {
    throw createError({
      statusCode: 500,
      statusMessage: 'Required api request fail',
    })
  }
})
