import numeral from 'numeral'

/**
 * 천 단위로 콤마 구분자를 추가하여 숫자/문자열 값을 포맷
 *
 * @param {number|string|null} value - 포맷할 값
 * @param {number} [fixed=0] - 값이 반올림될 소수점 자릿수
 * @param {boolean} [roundUp=false] - 값이 올림될지 여부
 * @returns {string} - 포맷된 값
 */
export const useFormatThousandsSeparators = (
  value: number | string | null,
  fixed = 0,
  roundUp = false
) => {
  if (!value) return 0

  // const format = `0,0.[${'0'.repeat(fixed)}]`
  const format = `0,0.${'0'.repeat(fixed)}`
  const pad = Number((0.1 ** fixed / 2).toFixed(fixed + 1))

  return numeral(value)
    .add(roundUp ? pad : 0)
    .format(format)

  // return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
