<script setup lang="ts">
import type { NovaMediaContainerProps } from './NovaMediaContainer.types'
import { bridgeToApp } from '@bridge'

withDefaults(defineProps<NovaMediaContainerProps>(), {
  autoPlay: false,
  preload: 'none',
  nft: false,
})

const appStore = useAppStore()
const { appType } = storeToRefs(appStore)
const videoControlRef = ref<Element | null>(null)
const videoRef = ref<HTMLVideoElement | null>(null)
const videoStatus = ref('pause')
const videoProgressBar = ref<HTMLVideoElement>()
const videoState = reactive({
  progressPercent: 0,
  currentTime: 0,
})
const showVideoControl = ref(true)
const showVideoControlInterval = ref<ReturnType<typeof setInterval>>()

const handleOnBeginFullscreen = () => {
  console.log('[fullscreen event hook] begin fullscreen')
  bridgeToApp.fullscreenStatus({ isFullScreen: true })
}

const handleOnEndFullscreen = () => {
  console.log('[fullscreen event hook] end fullscreen')
  bridgeToApp.fullscreenStatus({ isFullScreen: false })
}

watch(
  () => videoStatus.value,
  (cur) => {
    if (cur === 'pause') {
      showVideoControl.value = true
    }
  }
)

onMounted(() => {
  videoProgressBar.value?.addEventListener('click', handlerSeek)
  videoRef.value?.addEventListener('pause', () => {
    videoStatus.value = 'pause'
  })
  videoRef.value?.addEventListener('play', () => {
    videoStatus.value = 'play'
  })
  videoRef.value?.addEventListener('timeupdate', updateHeaderBarRect)

  // 비디오 컨트롤 마우스(터치)에 3초이상 움직임이 없으면 숨기기 리스너 등록
  videoControlRef.value?.addEventListener('mousemove', onShowVideoControl)
  videoControlRef.value?.addEventListener('mouseleave', onHideVideoControl)
  videoControlRef.value?.addEventListener('touchmove', onMouseMove)

  // IOS 풀스크린 상태갑 전달
  if (appType.value === 'IOS') {
    videoRef.value?.addEventListener(
      'webkitbeginfullscreen',
      handleOnBeginFullscreen
    )
    videoRef.value?.addEventListener(
      'webkitendfullscreen',
      handleOnEndFullscreen
    )
  }
})

onUnmounted(() => {
  videoRef.value?.removeEventListener('timeupdate', updateHeaderBarRect)
  videoProgressBar.value?.removeEventListener('click', handlerSeek)

  // 비디오 컨트롤 마우스(터치)에 3초이상 움직임이 없으면 숨기기 리스너 삭제

  videoControlRef.value?.removeEventListener('mousemove', onMouseMove)
  videoControlRef.value?.removeEventListener('mouseleave', onHideVideoControl)
  videoControlRef.value?.removeEventListener('touchmove', onMouseMove)

  // clearInterval(checkThumbInterval.value)
  clearInterval(showVideoControlInterval.value)

  videoRef.value?.removeEventListener(
    'webkitbeginfullscreen',
    handleOnBeginFullscreen
  )
  videoRef.value?.removeEventListener(
    'webkitendfullscreen',
    handleOnEndFullscreen
  )
})

const handlerSeek = (e: MouseEvent) => {
  e.stopPropagation()
  const percent = e.offsetX / videoProgressBar.value!.offsetWidth
  videoRef.value!.currentTime = percent * videoRef.value!.duration
  videoState.progressPercent = Math.floor(percent * 100)
}

const videoPlay = async () => {
  await videoRef.value?.play()
}

const videoPause = (e: Event) => {
  e.stopPropagation()
  videoRef.value?.pause()
}

const updateHeaderBarRect = () => {
  if (videoRef.value) {
    videoState.progressPercent = Math.floor(
      (100 / videoRef.value.duration) * videoRef.value.currentTime
    )
  }
}

const onShowVideoControl = () => {
  showVideoControl.value = true
  onMouseMove()
}

const onHideVideoControl = () => {
  showVideoControl.value = false
}

const onMouseMove = () => {
  if (showVideoControlInterval.value)
    clearInterval(showVideoControlInterval.value)

  showVideoControlInterval.value = setTimeout(
    () => (showVideoControl.value = false),
    3000
  )
}
</script>

<template>
  <div class="contents-viewer">
    <NovaImageContainer
      v-if="image"
      :image-url="image.source"
      :ratio="'auto'"
      :empty-type="'default'"
      class="image-viewer"
    />

    <div
      v-if="video"
      :class="['video-wrap', { 'show-video-control': showVideoControl }]"
    >
      <video
        ref="videoRef"
        :poster="video?.poster"
        :autoplay="autoPlay"
        loop
        muted
        none
        playsinline
        controlsList="nodownload nofullscreen"
        preload="none"
        class="video-viewer"
      >
        <source :src="video.source" />
        <progress />
      </video>

      <div v-if="!nft" ref="videoControlRef" class="control-wrap">
        <progress
          ref="videoProgressBar"
          class="progress-bar"
          max="100"
          :value="videoState.progressPercent || 0"
          @click="handlerSeek"
        />

        <button
          v-if="videoStatus === 'pause'"
          class="btn-play"
          @click="videoPlay"
        >
          Play
        </button>

        <button
          v-if="videoStatus === 'play'"
          class="btn-play pause"
          @click="videoPause"
        >
          Pause
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.contents-viewer {
  .image-viewer {
    border-radius: 8px;
    background-color: $color-bg-2;
  }

  .video-wrap {
    position: relative;
    font-size: 0;

    &.show-video-control {
      .control-wrap {
        opacity: 1;
      }
    }

    .video-viewer {
      width: 100%;
      height: auto;
      border-radius: 8px;
    }

    .control-wrap {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      height: 100%;
      opacity: 0;
      @include transition(opacity 0.2s ease-in-out);

      .progress-bar {
        position: absolute;
        left: 50%;
        bottom: 10px;
        z-index: 10;
        width: 95%;
        height: 8px;
        transform: translateX(-50%);
        border: none;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 10px;
        vertical-align: middle;
        cursor: pointer;
        overflow: hidden;
      }

      progress::-moz-progress-bar {
        color: #fff;
        background-color: rgba(255, 255, 255, 0.5);
      }

      progress[value]::-webkit-progress-bar {
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 10px;
      }

      progress[value]::-webkit-progress-value {
        background-color: #fff;
      }

      .btn-play {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 30px;
        height: 34px;
        background-image: url('assets/images/btn-contents-play.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        transform: translate(-50%, -50%);
        text-indent: -9999999px;

        &.pause {
          background-image: url('assets/images/btn-contents-pause.png');
        }
      }
    }
  }

  video::-webkit-media-controls-fullscreen-button {
    display: none !important;
  }
}
</style>
