import { commonError } from './commonError'
import type { FundTabType } from '@components/NovaLayoutFundHeader/NovaLayoutFundHeader.types'

/**
 * 펀드
 */
export const fund = {
  page: {
    header: {
      tabs: {
        comingSoon: 'Coming Soon',
        history: '펀드 History',
        gaudiFund: 'Gaudi Fund',
      } as { [Property in FundTabType]: string },
    },
  },
  project: {
    season: 'Web3 크리에이터 펀드_{year}년 {month}월',
    pick: 'Pick',
    support: 'SUPPORT',
    more: 'MORE',
    messages: {
      terminate: '이달의 프로젝트가 종료되었습니다.',
      ready: '이달의 프로젝트를 준비하고 있습니다.',
      empty: '이달의 프로젝트가 없습니다.',
      voteTally: '지금은 투표 집계 확인중입니다',
      error: commonError.network.message,
    },
  },
  start: {
    title: 'Web3 Creator Fund',
    prevProject: {
      fundNo: '제{fundNo}호 Web3 크리에이터 펀드',
      title: '{year} {month}월의 프로젝트',
      action: '프로젝트 내용보기',
    },
    nextProject: {
      title: '다음 펀드의 주인공은?',
      period: '신청 기간:',
      action: '프로젝트 신청하기',
    },
  },
  end: {
    title: 'Web3 Creator Fund',
    subTitle: '신청 프로젝트 심사중입니다',
  },

  histories: {
    fundNo: '제{fundNo}호',
    creatorNickname: '{creatorNickname}',
    message: {
      empty: '펀드 History 목록이 없습니다.',
      error: commonError.network.message,
    },
  },
  projectDetail: {
    message: {
      error: commonError.network.message,
    },
  },
  voteDialog: {
    title: '{creatorNickname}님께 투표하시겠습니까?',
    message: '*프로젝트당 1번만 투표할 수 있습니다.',
    actions: {
      negative: '취소',
      positive: '투표 하기',
    },
  },
  voteRequest: {
    error: {
      8045: '투표 처리중 오류가 발생하였습니다. 잠시 후 다시 시도해 주세요.',
      8046: '투표 취소 처리중 오륲가 발생했습니다. 잠시 후 다시 시도해 주세요.',
      8047: '투표 기간이 아닙니다.',
      default: commonError.network.message,
    },
    success: '투표가 완료되었습니다.',
  },
  gaudiFund: {
    gaudiheader: 'GAUDI FUND The Moon Labs X Gaudi Knowledge Association',
    gaudiFundImage: 'GAUDI FUND',
    fundMessage: {
      theMoonLabs: 'The Moon Labs X Gaudi Knowledge Association',
      theMoonLabsMessage:
        '더문랩스는 가우디 연구재단의 독점적 글로벌 디지털 파트너 계약을 체결하고, <span class="pc-br"></span>가우디와 관련한 다양한 분야의 웹3 디지털 비즈니스를 함께 진행하고 있습니다.',
      lmnova: 'GAUDI Fund by I LIKE LM',
      lmnovaMessage:
        'I LIKE LM에서는 매달 가우디 펀드를 적립하여, 가우디의 비전을 이어가는 크리에이터를 지원하고, <span class="pc-br"></span>다양한 창작 프로젝트를 추진할 예정입니다.<br/>또한 가우디 펀드를 통해 관련 전문가들과가우디에게 관심이 있는 사람들 이외에도 <span class="pc-br"></span>새로운 세대에게, 특히 젊은 학생들에게 가우디의 가치를 알릴 수 있는 다채로운 이벤트를 개최할 것입니다. <br/>많은 분들이 I LIKE LM에서 가우디의 비전을 느낄 수 있길 바랍니다. <br/><br/>진행되는 프로젝트들은 콘텐츠로 제작되어 I LIKE LM에 업로드될 예정이니, 크리에이터홈에서 확인하세요!',
      lmnovaGaudi: '가우디재단 크리에이터 홈 바로가기',
      plan: 'GAUDI Fund Operation Plan',
      planMessage: {
        planTitle1: {
          title: '가우디 전문 연구 및 교육 기관 설립',
          message1:
            '디지털 기술 도입을 통한 가우디 지식 협회의 교육지원 활동 확장 및 퀄리티 향상',
          message2:
            '가우디의 독창적인 방법론에 대한 온라인 강좌, 마스터클래스, 프로그램 제작 및 NFT화',
          message3:
            '가우디의 건축물, 일대기 및 자료 전체를 취합, 분류하는 가우디피디아 구축',
        },
        planTitle2: {
          title: '가우디의 미완성 건축과 디자인 연구 프로젝트',
          message1:
            'Bea Frois와 가우디 전문팀의 Colonia Güell 교회 디지털 랜더링 작업',
          message2:
            '가우디 전문가 Federico Luis의 hotel Attraction 연구 및 디지털 랜더링',
          message3: '가우디 전문가 David Afonso의 Misiones de Tanger 연구',
        },
        planTitle3: {
          title: '신세대에게 가우디 전파',
          message1:
            '가우디 전문가 Bethany Koby의 주도로 젊은 대중과 학생들을 위한 가우디 방법론 강좌와 교육 프로그램 개발\n',
        },
        planTitle4: {
          title: '가우디 AI 개발',
          message1: '가우디 전문가 Mariona Grau의 가우디 필적심리학 연구',
          message2:
            '천재적인 정신에 대한 구조적 연구를 통해 ‘가우디 AI’ 개발 기반 마련',
        },
      },
    },
    roadmap: "GAUDI FUND's ROADMAP",
  },
  comingSoon: {
    title: '펀드 리뉴얼 안내',
    message:
      '<strong>웹 3 크리에이터 펀드</strong>는 I LIKE LM 크리에이터들의 창작 생활에<span class="pc-br"></span> 보다 더 힘이 될 수 있도록 새롭게 <strong>리뉴얼하여 재오픈할</strong> 예정입니다.',
  },
}
