import { isString } from 'helper-js'

/**
 * 주어진 단위가 있는 경우, 그 단위와 함께 크기를 반환하고, 그렇지 않으면 아무런 수정도 없이 크기를 반환
 *
 * @param {string|number} size - 수정할 크기
 * @param {string} [unit='px'] - 크기에 추가할 단위
 * @returns string - 수정된 크기
 */

/**
 * Retrieves the string representation of the provided size, with an optional unit.
 *
 * @param {string|number} size - The size to retrieve the string representation for.
 * @param {string} [unit='px'] - The unit to append to the size.
 * @returns {string} The string representation of the size with the optional unit.
 */
export const useGetStyleSize = (
  size: string | number,
  unit: string = 'px'
): string => {
  return isString(size) && isNaN(Number(size)) ? size : size + unit
}
