import * as SearchType from './type'
import SearchService from './service'
import { initSearchState } from './init'

export * from './init'
export * from './type'
export const useSearchStore = defineStore('search', () => {
  const keyword = ref(initSearchState.keyword)
  const all = ref(initSearchState.all)
  const comments = ref(initSearchState.comments)
  const hashtags = ref(initSearchState.hashtags)
  const users = ref(initSearchState.users)
  const premiumPosts = ref(initSearchState.premiumPosts)

  /**
   * 통합검색
   * @param payload
   */
  const fetchSearchAll = async (payload: SearchType.SearchAllPayload) => {
    const { data } = await SearchService.fetchSearchAll(payload)
    all.value = data
  }

  /**
   * 통합검색: 댓글 목록 전체 조회
   * @param payload
   * @param refresh
   */
  const fetchSearchComments = async (
    payload: SearchType.SearchCommentsPayload,
    refresh = false
  ) => {
    const { data, headers } = await SearchService.fetchSearchComments({
      ...payload,
      pageNum: refresh ? 1 : payload.pageNum + 1,
    })

    comments.value = {
      items: refresh ? data.comment : comments.value.items.concat(data.comment),
      payload: { ...payload, pageNum: payload.pageNum + 1 },
      refresh,
      last: typeof headers['pagination-location'] === 'undefined',
    }
  }

  /**
   * 통합검색: 해시태그 목록 전체 조회
   * @param payload
   * @param refresh
   */
  const fetchSearchHashtags = async (
    payload: SearchType.SearchHashtagsPayload,
    refresh = false
  ) => {
    const { data, headers } = await SearchService.fetchSearchHashtags({
      ...payload,
      pageNum: refresh ? 1 : payload.pageNum + 1,
    })

    hashtags.value = {
      items: refresh ? data.hash : hashtags.value.items.concat(data.hash),
      payload: { ...payload, pageNum: payload.pageNum + 1 },
      refresh,
      last: typeof headers['pagination-location'] === 'undefined',
    }
  }

  /**
   * 통합검색: 크리에이터 목록 전체 조회
   * @param payload
   * @param refresh
   */
  /* const fetchSearchCreators = async (
    payload: SearchType.SearchCreatorsPayload,
    refresh = false
  ) => {
    const { data, headers } = await SearchService.fetchSearchCreators({
      ...payload,
      pageNum: refresh ? 1 : payload.pageNum + 1,
    })

    creators.value = {
      items: refresh
        ? data.creator
        : creators.value.items.concat(data.creator),
      payload: { ...payload, pageNum: payload.pageNum + 1 },
      refresh,
      last: typeof headers['pagination-location'] === 'undefined',
    }
  }, */

  /**
   * 통합검색: 사용자 목록 전체 조회
   * @param payload
   * @param refresh
   */
  const fetchSearchUsers = async (
    payload: SearchType.SearchUsersPayload,
    refresh = false
  ) => {
    const { data, headers } = await SearchService.fetchSearchUsers({
      ...payload,
      pageNum: refresh ? 1 : payload.pageNum + 1,
    })

    users.value = {
      items: refresh ? data.users : users.value.items.concat(data.users),
      payload: { ...payload, pageNum: payload.pageNum + 1 },
      refresh,
      last: typeof headers['pagination-location'] === 'undefined',
    }
  }

  /**
   * 통합검색: 노바+(프리미엄) 포스트 목록 전체 조회
   * @param payload
   * @param refresh
   */
  const fetchSearchPremiumPosts = async (
    payload: SearchType.SearchPremiumPostsPayload,
    refresh = false
  ) => {
    // 1. 중복 호출을 막기위해 로딩중이면 이벤트 막음
    if (premiumPosts.value.loading) return

    // 2. 최초 조회일 경우 필요없는 조회 조건 삭제 및 기본 조회 조건 세팅
    if (refresh) {
      delete payload.lastCmtyNttSn
      payload.pageNum = 1
    } else {
      payload.pageNum = payload.pageNum + 1
    }

    // 3. 중복 호출을 리턴시키기 위해 로딩상태 업데이트
    premiumPosts.value.loading = true

    // 4. 프리미엄 포스트 목록 조회
    const { data, headers } = await SearchService.fetchSearchPremiumPosts(
      payload
    )

    // 5. 조회 조건에 따라 프리미엄 포스트 목록 준비
    const items = refresh
      ? data
      : premiumPosts.value.items.concat(
          data.filter(
            (item) =>
              premiumPosts.value.items.findIndex(
                (origin) => origin.cmtyNttSn === item.cmtyNttSn
              ) === -1
          )
        )

    // 6. 프리미엄 포스트 목록 상태 업데이트
    premiumPosts.value = {
      items,
      payload: {
        ...payload,
        lastCmtyNttSn: items.length ? items[items.length - 1].cmtyNttSn : 0,
      },
      refresh,
      loading: false,
      last: typeof headers['pagination-location'] === 'undefined',
    }
  }

  /**
   * 검색 키워드 업데이트
   * @param payload
   */
  const updateKeyword = (payload: string) => {
    keyword.value = payload
  }

  /**
   * 검색 결과: 사용자 팔로우, 언팔로우 업데이트
   * @param payload
   */
  const updateSearchUserFollowUnfollow = (payload: {
    userSn: number
    follow: boolean
  }) => {
    const updater = (items: SearchType.SearchUser[]) => {
      const updateTarget = items.findIndex(
        (user) => user.userSn === payload.userSn
      )

      if (updateTarget !== -1) {
        items[updateTarget].followFlag = payload.follow ? '1' : '0'
        items[updateTarget].followerCount =
          items[updateTarget].followerCount + (payload.follow ? +1 : -1)
      }
    }

    // 1. 모든 검색 결과 사용자 카드 업데이트
    updater(all.value.users)

    // 2. 상세 검색 결과 사용자 카드 업데이트
    updater(users.value.items)
  }

  return {
    keyword,
    all,
    comments,
    hashtags,
    users,
    premiumPosts,
    fetchSearchAll,
    fetchSearchComments,
    fetchSearchHashtags,
    fetchSearchUsers,
    updateKeyword,
    fetchSearchPremiumPosts,
    updateSearchUserFollowUnfollow,
  }
})
