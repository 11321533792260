<script setup lang="ts">
import type { NovaMegaMenuHeaderProps } from './NovaMegaMenuHeader.types'

const props = defineProps<NovaMegaMenuHeaderProps>()

const themeClass = computed(() => `theme-${props.prop.theme}`)
</script>

<template>
  <div :class="['mega-menu-header', themeClass, { sticky: prop.sticky }]">
    <NovaIcon :icon="prop.icon" :size="16" />
    <span class="label">{{ prop.label }}</span>
  </div>
</template>

<style lang="scss" scoped>
.mega-menu-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  color: $color-text-2;
  padding: 12px;
  border-radius: 5px;

  &.sticky {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .label {
    @include text-style($text-body-12-bold);
    line-height: 13px;
  }

  &.theme {
    &-red {
      background-color: $color-secondary-red-light-10;
    }
    &-blue {
      background-color: $color-secondary-blue-light-10;
    }
    &-green {
      background-color: $color-secondary-green-light-10;
    }
    &-yellow {
      background-color: $color-secondary-yellow-light-10;
    }
    &-purple {
      background-color: $color-secondary-purple-light-10;
    }
    &-gray {
      background-color: $color-ea;
    }
  }
}
</style>
