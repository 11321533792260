/**
 * useSleeper
 *
 * @description
 * 프로그램의 실행을 특정 시간 동안 일시중지하는 데 사용할 수 있는 비동기 함수
 * 지연 시간을 밀리세컨드 단위로 지정하는 delay 인수를 받음
 * 지정된 지연 후에 해결되는 Promise를 반환
 *
 * @param {number} delay - 지연 시간(밀리세컨드)
 * @returns {Promise<void>} - 지정된 지연 후에 해결되는 Promise
 */
export const useSleeper = (delay: number = 300): Promise<unknown> =>
  new Promise((resolve) => setTimeout(resolve, delay))
