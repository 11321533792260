import type { CorporationInfoProperty } from '@components/NovaBoxCorporationInfo/NovaBoxCorporationInfo.types'

/**
 * 회사 정보
 */
export const corporationInfo = {
  title: '사업자 정보',
  copyright: 'The Moon Labs Inc. © {year} All rights reserved.',
  properties: {
    name: '상호',
    owner: '대표이사',
    address: '주소',
    businessNumber: '사업자등록번호',
    // corporationNumber: '법인등록번호',
    tel: '대표번호',
    fax: 'FAX',
    email: '이메일',
  } as { [Property in CorporationInfoProperty]: string },
  infos: {
    name: '(주) 더문랩스',
    owner: '문성억',
    address: '서울특별시 강남구 언주로 147길 9-11, 3층(논현동, 보전빌딩)',
    businessNumber: '372-86-02722',
    // corporationNumber: '110111-8145107',
    tel: '+82-2-3469-7100',
    fax: '+82-2-3452-1802',
    email: "cs{'@'}ilikelm.com",
  } as { [Property in CorporationInfoProperty]: string },
}
