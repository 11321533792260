import { PayType } from '~/store/types'

/**
 * 결제 모듈
 */
export const pay = {
  payType: {
    name: {
      CC: '카드결제', // 신용카드
      AT: '무통장 입금', // 계좌이체
    } as { [Property in PayType]: string },
  },
  iframe: {
    loadFail: '결제 모듈을 불러 올 수 없습니다.',
  },
}
