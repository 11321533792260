<script setup lang="ts">
import { Tippy } from 'vue-tippy'
import request from 'axios'
import { v4 } from 'uuid'
import {
  type CreatorProfileByCreatorLinkPayload,
  type CreatorProfileEditPayload,
  type ProfilePayload,
  LayerType,
} from '@store'
import {
  NovaModalCommon,
  NovaModalDialog,
  NovaModalRetireCheckPassword,
} from '#components'
import { useCheckEmpty } from '@composables/useCheckEmpty'
import { ROUTES } from '@configs'

const route = useRoute()
const { gtEvent } = useGoogleTag()
const { t } = useI18n()
const { show: modalShow, hide: modalHide } = useModal()
const { checkPermission, requestPermission } = useAppPermission()
const { certifiedKYC } = useKyc()
const { openMembershipServicePopup } = useMembershipProcess()

const userStore = useUserStore()
const creatorStore = useCreatorStore()
const mypageStore = useMyPageStore()
const layoutStore = useLayoutStore()
const appStore = useAppStore()

const isOpen = computed(() =>
  layoutStore.layers.includes(LayerType.USER_PROFILE_EDIT_PANEL)
)
const isReady = ref(userStore.user?.cmtyUserSeCode !== 'C')
const state = reactive({
  userNcnm: userStore.user?.userNcnm || '',
  nameMaxLength: 25,
  createLinkInput: userStore.user?.creatorLink || '',
  optionNcmInput: '',
  optionCharstValInput: '',
  isNickname: false,
})
const countLengthByUserNcnm = computed(() => state.userNcnm.length)
const kycCertified = computed(() => userStore.user?.kycAt === 'Y')
const isBgImgLoadSuccess = ref(false)

const initProfile = useDebounce(async () => {
  if (userStore.user?.cmtyUserSeCode === 'C') await fetchMyProfile()
  state.userNcnm = userStore.user?.userNcnm || ''
}, 1000)

watch(
  () => isOpen.value,
  (cur) => {
    if (cur) {
      initProfile()
      // if (userStore.user?.cmtyUserSeCode === 'C') await fetchMyProfile()
      // state.userNcnm = userStore.user?.userNcnm || ''
    }
  }
)

watch(
  () => state.userNcnm,
  (_cur, prev) => {
    if (countLengthByUserNcnm.value > state.nameMaxLength) {
      state.userNcnm = prev
    }
  }
)

const fetchMyProfile = async () => {
  try {
    isReady.value = false
    await creatorStore.fetchMyProfile({
      creatorLink: userStore.user!.creatorLink,
    })
    if (creatorStore.profile?.userSn === userStore.user?.userSn) {
      await creatorStore.fetchCreatorHome({
        creatorUserSn: userStore.user!.userSn,
      })
    }
  } catch {
  } finally {
    isReady.value = true
  }
}

const handleOnChangeUserNcnm = () => {
  state.isNickname = true
  state.userNcnm = userStore.user?.userNcnm || ''
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '닉네임 변경 버튼 클릭',
    eventLabel: t('modify'),
    eventSlot: '프로필 수정 > 닉네임',
    eventI18nAddr: useKoreanTranslation('modify'),
    eventComponent: 'Button',
  })
}

const onCancelName = () => {
  state.userNcnm = ''
  state.isNickname = false
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '닉네임 변경 취소',
    eventLabel: t('cancel'),
    eventSlot: '프로필 수정 > 닉네임 변경 > 취소',
    eventI18nAddr: useKoreanTranslation('cancel'),
    eventComponent: 'Button',
  })
}

const onSaveName = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '닉네임 변경 > 저장 버튼 클릭',
    eventLabel: t('save'),
    eventSlot: '닉네임 변경',
    eventI18nAddr: useKoreanTranslation('save'),
    eventComponent: 'Button',
  })
  // 입력 한 닉네임이 있는지 확인
  const isEmptyNickname = useCheckEmpty(state.userNcnm)
  if (isEmptyNickname) {
    useToast(t('mypage.profile.placeholder.nickname'))
    state.userNcnm = ''
    return
  }
  // 입력한 닉네임에 특수문자 있는지 확인
  const isUnavailableNickname = useCheckUrlSpecialCharacter(state.userNcnm)
  if (isUnavailableNickname) {
    useToast(t('mypage.profile.toastMessage.unavailableNickname'))
    return
  }
  if (userStore.user?.cmtyUserSeCode === 'C') {
    try {
      const payload: CreatorProfileEditPayload = {
        proflDc: mypageStore.profile!.proflDc,
        userNcnm: state.userNcnm,
        creatorLink: userStore.user!.creatorLink,
      }
      await creatorStore.editProfile(payload)
      await mypageStore.fetchProfile()
      state.isNickname = false
      gtEvent('userAction', {
        eventCategory: '사용자',
        eventAction: '닉네임 변경',
        eventLabel: t('save'),
        eventSlot: '닉네임 변경',
        eventI18nAddr: useKoreanTranslation('save'),
        eventComponent: 'Button',
        userNcnm: state.userNcnm,
      })
    } catch (err: any) {
      const errorData = err.response
      if (errorData.status === 2010) {
        await showDialogModal(
          t('mypage.profile.dialog.title'),
          t('mypage.profile.dialog.doubleNickname')
        )
        state.userNcnm = ''
      }
    }
  } else {
    try {
      const payload: ProfilePayload = {
        proflDc: mypageStore.profile!.proflDc,
        userNcnm: state.userNcnm,
      }
      await mypageStore.editProfile(payload)
      state.isNickname = false
      gtEvent('userAction', {
        eventCategory: '사용자',
        eventAction: '닉네임 변경',
        eventLabel: t('save'),
        eventSlot: '프로필 수정 > 닉네임 변경 > 저장',
        eventI18nAddr: useKoreanTranslation('save'),
        eventComponent: 'Button',
        userNcnm: state.userNcnm,
      })
      state.isNickname = false
      // 유저 홈에서 닉네임 변경이라면 라우터 리플레이스
      if (
        String(route.name).includes(ROUTES.USER.name) &&
        String(route.params.id) !== payload.userNcnm
      ) {
        const query = route.query
        const currentRoute = useGetRouteConst(String(route.name))
        if (currentRoute) {
          useNavigations({
            url: useRoutePathIdChange(currentRoute.path, {
              id: payload.userNcnm,
            }),
            query,
            type: 'replace',
          })
        }
      }
    } catch (err: any) {
      const errorData = err.response
      if (errorData.status === 2010) {
        await showDialogModal(
          t('mypage.profile.dialog.title'),
          t('mypage.profile.dialog.doubleNickname')
        )
        state.userNcnm = ''
      }
    }
  }
}

const showDialogModal = (title: string, content: string) => {
  return modalShow({
    component: NovaModalDialog,
    bind: {
      name: modalsName.MODAL_DIALOG,
      btns: [
        {
          label: t('confirm'),
          theme: 'primary-blue-light',
          size: 32,
          onClick: async () => {
            await modalHide(modalsName.MODAL_DIALOG)
          },
        },
      ],
    },
    slots: {
      title,
      content,
    },
  })
}

const onModifyProfile = async () => {
  // 앱 일때 스토리지 권한 체크
  if (appStore.isApp) {
    const { data: permission } = await requestPermission('storage')

    if (permission.status === 'off') {
      const accessAble = await checkPermission({
        permission: 'storage',
        permissionType: 'profileImage',
      })
      if (!accessAble) return
    }
  }
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '프로필 이미지 변경 버튼 클릭',
    eventLabel: '',
    eventSlot: '프로필 수정 > 프로필 이미지 변경',
    eventI18nAddr: '',
    eventComponent: 'Button',
  })
  try {
    const { formData } = await useGetFiles({
      accept: 'image/*',
      multiple: false,
      formDataName: 'profileImg',
    })

    const payload: CreatorProfileByCreatorLinkPayload = {
      creatorLink: userStore.user!.creatorLink,
    }
    await creatorStore.createProfilePhoto(formData, payload)
    gtEvent('fileAction', {
      eventCategory: '파일',
      eventAction: '프로필 이미지 파일 업로드',
      eventLabel: '',
      eventSlot: '프로필 수정 > 프로필 이미지 업로드',
      eventI18nAddr: '',
      eventComponent: 'File Upload',
      fileName: mypageStore.profile!.userProflUrl,
    })
    await mypageStore.fetchProfile()
    gtEvent('userAction', {
      eventCategory: '사용자',
      eventAction: '프로필 이미지 변경',
      eventLabel: '',
      eventSlot: '',
      eventI18nAddr: '',
      eventComponent: 'Button',
    })
  } catch (err) {
    if (request.isAxiosError(err) && err.response) {
      switch (err.response.status) {
        case 2005:
          useToast(t('mypage.profile.toastMessage.imgError2005'))
          break
        default:
          useToast(t('mypage.profile.toastMessage.imgError'))
          break
      }
    }
  }
}

const onUploadBgImg = async () => {
  // 앱 일때 스토리지 권한 체크
  if (appStore.isApp) {
    const { data: permission } = await requestPermission('storage')

    if (permission.status === 'off') {
      const accessAble = await checkPermission({
        permission: 'storage',
        permissionType: 'homeHeaderImage',
      })
      if (!accessAble) return
    }
  }
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '백그라운드 이미지 변경 버튼 클릭',
    eventLabel: '',
    eventSlot: '프로필 수정 > 백그라운드 이미지',
    eventI18nAddr: '',
    eventComponent: 'Button',
  })
  try {
    const { formData } = await useGetFiles({
      accept: 'image/*',
      multiple: false,
      formDataName: 'bgImg',
    })

    await mypageStore.regProfileBgImg(formData)
    gtEvent('fileAction', {
      eventCategory: '파일',
      eventAction: '백그라운드 이미지 파일 업로드',
      eventLabel: '',
      eventSlot: '프로필 수정 > 백그라운드 이미지 업로드',
      eventI18nAddr: '',
      eventComponent: 'File Upload',
      fileName: mypageStore.profile!.userProflUrl,
    })
    await mypageStore.fetchProfile()
    gtEvent('userAction', {
      eventCategory: '사용자',
      eventAction: '백그라운드 이미지 변경',
      eventLabel: '',
      eventSlot: '백그라운드 이미지 변경',
      eventI18nAddr: '',
      eventComponent: '',
    })
  } catch (err) {
    if (request.isAxiosError(err) && err.response) {
      switch (err.response.status) {
        case 2005:
          useToast(t('mypage.profile.toastMessage.imgError2005'))
          break
        default:
          useToast(t('mypage.profile.toastMessage.imgError'))
          break
      }
    }
  }
}

const handleOnSignOff = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '회원 탈퇴 신청 클릭',
    eventLabel: t('sign.off'),
    eventSlot: '유저 패널 > 설정 > 프로필 수정 > 회원 탈퇴',
    eventI18nAddr: useKoreanTranslation('sign.off'),
    eventComponent: 'Button',
  })
  await modalShow({
    component: NovaModalCommon,
    bind: {
      name: modalsName.MODAL_MEMBERSHIP_OFF,
      showCloseBtn: true,
      mobileFull: true,
    },
    on: {
      close: async () => {
        gtEvent('clickEvent', {
          eventCategory: '클릭',
          eventAction: '회원 탈퇴 모달 > 닫기',
          eventLabel: t('membershipOff'),
          eventSlot: '회원 탈퇴',
          eventI18nAddr: useKoreanTranslation('membershipOff'),
          eventComponent: 'Button',
        })
        await modalHide(modalsName.MODAL_MEMBERSHIP_OFF)
      },
    },
    slots: {
      title: t('membershipOff'),
      noScrollContents: {
        component: NovaModalRetireCheckPassword,
        on: {
          close: async () => {
            gtEvent('clickEvent', {
              eventCategory: '클릭',
              eventAction: '회원 탈퇴 모달 > 닫기',
              eventLabel: t('membershipOff'),
              eventSlot: '회원 탈퇴',
              eventI18nAddr: useKoreanTranslation('membershipOff'),
              eventComponent: 'Button',
            })
            await modalHide(modalsName.MODAL_MEMBERSHIP_OFF)
          },
        },
      },
    },
  })
}

const handleOnPasswordChangeProcess = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '비밀번호 변경하기 버튼 클릭',
    eventLabel: t('changePassword.change'),
    eventSlot: '프로필 수정 > 비밀번호 변경',
    eventI18nAddr: useKoreanTranslation('changePassword.change'),
    eventComponent: 'Button',
  })
  openMembershipServicePopup.searchPW()
}

const closeAccountSetting = () => {
  layoutStore['layer:close'](LayerType.USER_PROFILE_EDIT_PANEL)
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '프로필 수정 닫기 ',
    eventLabel: '',
    eventSlot: '헤더',
    eventI18nAddr: '',
    eventComponent: 'Button',
  })
}

const handleOnBgImageLoadSuccess = () => {
  isBgImgLoadSuccess.value = true
}
</script>

<template>
  <div
    :class="[
      'page-setting',
      'nova-box-account',
      { on: isOpen, ready: isReady },
    ]"
  >
    <div class="dim" @click="closeAccountSetting" />

    <div class="tab-box">
      <ul>
        <li class="active">
          <a>{{ $t('accountInfo') }}</a>
        </li>
      </ul>
      <a class="btn-close-layer" @click="closeAccountSetting">닫기</a>
    </div>

    <div class="setting-boxes">
      <!-- 사용자 프로필 이미지 -->
      <div class="profile-image-wrap">
        <div
          :class="[
            'profile-image-box',
            { 'is-load-success': isBgImgLoadSuccess },
          ]"
        >
          <NovaImageContainer
            :key="mypageStore.profile?.backgroundImageUrl || v4()"
            :image-url="mypageStore.profile?.backgroundImageUrl"
            :empty-type="'default'"
            :ratio="'5:1'"
            @on-img-load="handleOnBgImageLoadSuccess"
          />

          <div class="thumbnail">
            <NovaPortraitContainer
              :image-url="userStore.user?.userProflUrl"
              :size="'56'"
            />

            <button class="btn-img-setting" @click="onModifyProfile" />
          </div>

          <button class="btn-img-setting" @click="onUploadBgImg" />
        </div>
      </div>

      <!-- 사용자 기본 프로필 -->
      <div class="setting-box setting-user-info">
        <ul>
          <li>
            <strong>{{ $t('name') }}</strong>
            <span v-if="!(!kycCertified && !userStore.user?.userNm)">{{
              userStore.user?.userNm
            }}</span>
            <NovaButtonText
              v-else
              :label="t('kyc.userKycInfo.certifiedAction.yet')"
              :theme="'primary-red-ultra-light'"
              :size="20"
              @click="
                () => {
                  // certifiedKYC('service')
                  // 2024.02.21 릴리즈에서 무조건 보상 문구로 변경됨
                  certifiedKYC('reward')
                }
              "
            />
          </li>

          <li>
            <strong>{{ $t('nickName') }}</strong>

            <div v-if="!state.isNickname" class="box-right">
              <span>{{ userStore.user?.userNcnm }}</span>
              <Tippy
                :append-to="'parent'"
                :content="$t('modify')"
                :interactive="true"
                :theme="'simple'"
                :placement="'bottom'"
              >
                <NovaButtonIcon
                  :icon="{ type: 'outline', name: 'write' }"
                  :size="16"
                  @click="handleOnChangeUserNcnm"
                />
              </Tippy>
            </div>

            <!-- 수정 버튼 클릭시 아래 마크업 표출 -->
            <div v-if="state.isNickname" class="box-right-edit">
              <div class="input-box">
                <input v-model="state.userNcnm" type="text" />
                <span
                  >({{ countLengthByUserNcnm }}/{{ state.nameMaxLength }})</span
                >
              </div>

              <div class="box-btn">
                <NovaButtonText
                  :size="32"
                  :label="$t('cancel')"
                  @click="onCancelName"
                />

                <NovaButtonText
                  class="btn-save"
                  :size="32"
                  :label="$t('save')"
                  @click="onSaveName"
                />
              </div>
            </div>
          </li>

          <li>
            <strong>{{ $t('email') }}</strong>
            <span>{{ userStore.user?.email }}</span>
          </li>

          <li>
            <strong>{{ $t('birthday') }}</strong>
            <span v-if="!(!kycCertified && !userStore.user?.birth)">{{
              userStore.user?.birth || '-'
            }}</span>
            <NovaButtonText
              v-else
              :label="t('kyc.userKycInfo.certifiedAction.yet')"
              :theme="'primary-red-ultra-light'"
              :size="20"
              @click="
                () => {
                  // 2024.02.21 릴리즈에서 무조건 보상 문구로 변경됨
                  // certifiedKYC('service')
                  certifiedKYC('reward')
                }
              "
            />
          </li>

          <li>
            <strong>{{ $t('recommenderEmail') }}</strong>
            <span>{{ userStore.user?.recomendUserId || '-' }}</span>
          </li>
          <li>
            <strong>{{ $t('changePassword.title') }}</strong>
            <span>
              <NovaButtonText
                :label="$t('changePassword.change')"
                :theme="'primary-blue'"
                :size="28"
                class="btn-account"
                @click="handleOnPasswordChangeProcess"
              />
            </span>
          </li>
        </ul>
      </div>

      <!-- 230705 pjw0328 LMNOVA 링크 일반유저 숨김 -->
      <NovaBoxAccountSectionHomeLink
        v-if="userStore.user?.cmtyUserSeCode === 'C'"
      />

      <!-- 추천인 링크 -->
      <NovaBoxAccountSectionReferralLink />

      <!-- 휴대전화 번호 -->
      <NovaBoxAccountSectionPhoneNumber
        v-if="userStore.user?.cmtyUserSeCode === 'C'"
      />

      <!-- 계좌번호 -->
      <NovaBoxAccountSectionBankAccount
        v-if="userStore.user?.prmbrshAt === 'Y'"
      />

      <!-- 회원탈퇴 -->
      <div class="setting-box setting-withdraw">
        <div class="title">{{ $t('membershipOff') }}</div>

        <div class="box-input">
          <div class="line">
            <span>{{ $t('sign.off') }}</span>

            <NovaButtonText
              :label="$t('apply')"
              :theme="'secondary-gray'"
              :size="28"
              class="btn-account"
              @click="handleOnSignOff"
            />
          </div>
        </div>
      </div>

      <!-- 로딩 인디케이터 -->
      <NovaLoadingIndicator
        v-if="!isReady"
        class="account-loading-indicator"
        :bg-bright="'light'"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page-setting {
  position: absolute;
  top: calc(74px + 16px);
  right: 16px;
  width: 400px;
  z-index: $z-index-user-panel;
  background: #f7f7f7;
  border: 1px solid #eaeaea;
  box-shadow: 1px 2px 9px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
  @include transition(top 0.2s ease-in-out, opacity 0.2s ease-in-out);

  &.on {
    top: 74px;
    opacity: 1;
    pointer-events: auto;

    &.ready {
      .setting-boxes {
        opacity: 1;
      }
    }
  }

  > .dim {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: hex-to-rgba($color-black, 0.65);
    opacity: 0;
    @include transition(opacity 0.2s ease-in-out);
    z-index: -1;
  }

  .tab-box {
    padding: 0 20px;
    background-color: #fff;
    position: relative;

    .btn-close-layer {
      display: block;
      width: 18px;
      height: 18px;
      background: url('assets/images/icon-modal-close.png');
      background-size: 100% 100%;
      position: absolute;
      top: 50%;
      right: 20px;
      z-index: 10;
      transform: translateY(-50%);
      text-indent: -99999px;
      cursor: pointer;
    }

    ul {
      display: flex;
      align-items: center;

      li {
        a {
          display: block;
          position: relative;
          padding: 20px 0;
          font-size: 16px;
          font-weight: bold;
        }

        &.active {
          a {
            color: #347ae2;

            &:before {
              display: block;
              content: '';
              width: 100%;
              height: 2px;
              background-color: #347ae2;
              position: absolute;
              left: 0;
              bottom: 0;
            }
          }
        }
      }
    }
  }

  .setting-boxes {
    position: relative;
    display: flex;
    height: calc(65vh - 70px);
    flex-direction: column;
    gap: 18px;
    padding: 28px 18px 24px 18px;
    overflow: hidden;
    overflow-y: auto;
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;

    .btn-edit {
      width: 16px;
      height: 16px;
      background-image: url('assets/images/icon-edit.png');
      background-size: 100% 100%;
      text-indent: -99999px;
    }

    .setting-box {
      background-color: #fff;
      border-radius: 16px;
      padding: 28px 19px;

      .title {
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 15px;
      }

      .btn-account {
        font-weight: 400;
      }
    }

    .setting-user-info {
      ul {
        display: flex;
        flex-direction: column;
        gap: 12px;
        font-size: 14px;

        li {
          display: flex;
          align-items: center;
          justify-content: left;

          span {
            word-break: break-all;
          }

          strong {
            flex-shrink: 0;
            width: 114px;
            font-weight: bold;
            word-break: break-word;
          }

          .box-right {
            width: calc(100% - 90px);
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .box-right-edit {
            .input-box {
              border: 1px solid #ccc;
              height: 36px;
              padding: 0 5px;
              border-radius: 3px;
              display: flex;
              justify-content: space-between;
              align-items: center;

              input {
                width: 70%;
                height: 100%;
              }

              span {
                font-size: 11px;
              }
            }

            .box-btn {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              gap: 10px;
              margin-top: 10px;

              button {
                height: 28px;
                padding: 0 10px;
                font-size: 13px;
                font-weight: bold;
                letter-spacing: -0.3px;
                border-radius: 5px;
                color: $color-99;
                background-color: #f6f6f6;

                &.btn-save {
                  background: #347ae2;
                  color: #fff;
                }

                &.btn-cancel {
                  background: #347ae2;
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }

    .setting-link {
      .box-input {
        display: flex;
        align-items: center;
        gap: 5px;

        &.no-gap {
          gap: 0;
        }

        span {
          font-size: 13px;
          color: $color-99;
        }

        input {
          height: 28px;
          font-size: 13px;
          border: 1px solid #dddddd;
        }
      }
      .box-btn {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
        margin-top: 20px;

        button {
          padding: 0 10px;
          min-width: 44px;
          height: 27px;
          font-weight: 400;
          font-size: 13px;
          line-height: 19px;
          letter-spacing: -0.05em;
          color: #3f4354;
          background: #f4f6f8;
          border-radius: 5px;
          text-align: center;

          &.btn-save {
            background: #347ae2;
            color: #fff;
          }
        }
      }
    }

    .setting-withdraw {
      .line {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          color: $color-99;
          font-size: 13px;
        }

        //button {
        //  flex-shrink: 0;
        //  width: auto;
        //  height: 27px;
        //  font-weight: 400;
        //  font-size: 13px;
        //  line-height: 19px;
        //  letter-spacing: -0.05em;
        //  color: #3f4354;
        //  background: #f4f6f8;
        //  border-radius: 5px;
        //  text-align: center;
        //}
      }
    }

    .account-loading-indicator {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      height: 100%;
    }
  }
  .profile-image-wrap {
    border-radius: 16px 16px 0 0;

    .profile-image-box {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      border-radius: 16px 16px 0 0;
      background: linear-gradient(97.77deg, #5d95e8 -5.67%, #ffd008 126.11%);
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      overflow: hidden;

      &.is-load-success {
        background: inherit;
      }

      .btn-box,
      .btn-delete {
        position: absolute;
        right: 0;
        bottom: 0;
      }

      .btn-delete {
        color: $color-white;
        top: 0;
        bottom: auto;
      }

      .btn-box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;

        .input-file {
          width: 0;
          height: 0;
          visibility: hidden;
        }
      }

      .thumbnail {
        position: absolute;
        width: 56px;
        height: 56px;

        //.image-container {
        //  width: 100%;
        //  height: 100%;
        //
        //  img {
        //    width: 100%;
        //    height: 100%;
        //    border-radius: 50%;
        //  }
        //}

        .btn-img-setting {
          top: 50%;
          right: 50%;
          transform: translate(50%, -50%);
        }
      }

      .btn-img-setting {
        position: absolute;
        top: 16px;
        right: 16px;
        text-indent: -99999px;
        width: 24px;
        height: 24px;
        background-image: url('assets/images/icon-camera.png');
        background-size: 100% 100%;
        z-index: 10;
      }
    }
  }

  @include mobile {
    width: 100%;
    position: fixed;
    top: 0;
    transform: translateY(0);
    border-radius: 0;

    &.on {
      top: 0;
      left: 0;
    }

    .setting-boxes {
      padding-bottom: 156px;
      height: calc(100vh - 65px);
    }
  }
}
</style>
