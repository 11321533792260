<script setup lang="ts">
const layoutStore = useLayoutStore()
const userStore = useUserStore()
const etcStore = useEtcStore()
const { isSignIn } = storeToRefs(userStore)
const { newAlarmCount } = storeToRefs(etcStore)
const { gtEvent } = useGoogleTag()

const handleOnClick = () => {
  console.log(layoutStore.layers)
  layoutStore['layer:toggle'](LayerType.NOTIFICATION_PANEL)
  console.log(layoutStore.layers)
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: `알림 패널 ${layoutStore.layers.length ? '열기' : '닫기'}`,
    eventLabel: 'notification',
    eventSlot: '헤더',
    eventI18nAddr: '',
    eventComponent: 'Button',
  })
}
</script>

<template>
  <ClientOnly>
    <div v-if="isSignIn" class="nova-header-tools-notifications">
      <span
        class="icon-wrap toggle-panel-button"
        toggle-panel="notificationPanel"
        @click="handleOnClick"
      >
        <NovaButtonGNB
          :icon="{ type: 'fill', name: 'notification' }"
          class="icon"
        />

        <span v-if="newAlarmCount" :class="['bullet-count']">
          {{ newAlarmCount }}
        </span>
      </span>

      <NovaHeaderNotificationsPanel :panel-name="'notificationPanel'" />
    </div>
  </ClientOnly>
</template>

<style scoped lang="scss">
.nova-header-tools-notifications {
  display: inline-flex;
  width: 26px;
  height: 26px;

  @include mobile {
    width: 22px;
    height: 22px;
  }

  .icon-wrap {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .icon {
      :deep(.icon) {
        font-size: 22px !important;

        @include mobile {
          font-size: 20px !important;
        }
      }
    }

    .bullet-count {
      position: absolute;
      background-color: $color-secondary-red;
      border: 1px solid $color-bg-3;
      border-radius: 50%;
      pointer-events: none;
      transition: opacity 0.2s;

      top: -8px;
      right: -8px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      color: #ffffff;
      font-weight: 700;
      font-size: 8px;
      line-height: 14px;
      letter-spacing: -0.55px;
    }
  }
}
</style>
