import * as cheerio from 'cheerio'
import {
  LAZY_LOAD_IMAGE_DELAY_MS,
  LAZY_LOAD_IMAGE_MAX_RETRIES,
  POST_HTML_IMAGE_CONTAINER_CLASS,
} from '@configs'

interface ReplaceHtmlStrImageWithContainerParams {
  htmlStr: string // 이미지 태그가 포함된 HTML 문자열
  containerClassNames?: string[] // 이미지 태그를 대체할 컨테이너 클래스 이름의 배열
}

interface AttachListenersToImagesParams {
  imageContainers: NodeListOf<Element>
  containerClassName?: string
  retries?: number
  delay?: number
  imageLoadingType?: string // 이미지 로딩 방식
}

/**
 * HTML 내용에서 이미지를 지연 로딩하기 위한 유틸리티성 함수
 */
export const useHtmlContentsImageLazyLoading = () => {
  /**
   * 주어진 문자열 내의 모든 HTML 이미지 태그를 컨테이너 요소로 교체
   *
   * @description HTML 문자열을 변환하기 때문에 Element 접근하지 못하는 Life Cycle(process.server, onBeforeMount) 에서도 호출 가능
   *
   * @param {Object} params - 이미지를 바꾸는 매개변수
   * @param {string} params.htmlStr - 이미지가 포함된 HTML 문자열
   * @param {string[]} [params.containerClassNames=[POST_HTML_IMAGE_CONTAINER_CLASS]] - 컨테이너 요소에 대한 클래스 이름
   * @returns {string} - 이미지 태그가 컨테이너 요소로 대체된 수정된 HTML 문자열
   */
  const replaceHtmlStrImageWithContainer = ({
    htmlStr,
    containerClassNames = [POST_HTML_IMAGE_CONTAINER_CLASS],
  }: ReplaceHtmlStrImageWithContainerParams): string => {
    const dom = cheerio.load(htmlStr)
    const images = dom('img')

    images.each((_idx, img) => {
      const imgEl = dom(img)
      const imgAttrSrc = imgEl.attr('src')
      const classNames = containerClassNames.join(' ')
      const imgContainer = dom(`<span class="${classNames}" />`)
      imgContainer.attr('data-src', imgAttrSrc)
      imgEl.replaceWith(imgContainer)
    })

    return dom.html()
  }

  /**
   * 이미지에 대한 지연 로드를 위한 리스너를 추가
   *
   * @description Element 에 이벤트를 추가 하기 때문에 onMount 시점에서 호출 가능
   *
   * @param {Object} params - 이미지에 리스너를 첨부하는데 필요한 매개변수
   * @param {HTMLCollection} params.imageContainers - 리스너를 첨부할 이미지 컨테이너
   * @param {string} params.containerClassName - 컨테이너 요소의 클래스 이름
   * @param {number} [params.retries=3] - 이미지 로드를 시도하는 최대 횟수
   * @param {number} [params.delay=300] - 시도 사이의 지연 시간(밀리초)
   * @param {string} [params.imageLoadingType='lazy'] - 이미지에 대한 로딩 유형
   * @return void
   *
   * @example
   * attachListenerToImages({
   *   images: document.querySelectorAll('img'),
   *   containerClassName: 'image-container',
   *   retries: 5,
   *   delay: 500,
   * });
   */
  const attachListenerToImages = ({
    imageContainers,
    containerClassName = POST_HTML_IMAGE_CONTAINER_CLASS,
    retries = LAZY_LOAD_IMAGE_MAX_RETRIES,
    delay = LAZY_LOAD_IMAGE_DELAY_MS,
    imageLoadingType = 'auto',
  }: AttachListenersToImagesParams) => {
    // const removeTimestampFromUrl = (url: string) => {
    //   return url.replace(/\?t=\d+$/, '')
    // }
    // onload
    const handleOnImageOnLoad = (img: HTMLImageElement) => {
      img.onload = () => {
        const container = img.closest(`.${containerClassName}`)
        container?.classList.add('load-success')
      }
    }

    // onerror
    const handleOnImageOnError = (img: HTMLImageElement) => {
      const backupImg = img
      let retryCount = 0

      img.onerror = () => {
        retryCount++

        if (retryCount <= retries) {
          setTimeout(() => {
            const url = new URL(backupImg.src)
            url.searchParams.append('v', String(retryCount))
            img.src = url.href
          }, delay * retryCount)
        } else {
          const container = img.closest(`.${containerClassName}`)
          container?.classList.add('load-fail')
        }
      }
    }

    // add handler
    for (const imageContainer of Array.from(imageContainers)) {
      // init image tag
      const src = imageContainer.getAttribute('data-src')
      const image = document.createElement('img')
      image.setAttribute('loading', imageLoadingType)
      if (src) image.setAttribute('src', src)

      // add hook
      handleOnImageOnLoad(image)
      handleOnImageOnError(image)

      // rendering
      imageContainer.append(image)
    }
  }

  return {
    replaceHtmlStrImageWithContainer,
    attachListenerToImages,
  }
}
