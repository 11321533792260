export const SECOND = 1000
export const MINUTE = SECOND * 60
export const HOUR = MINUTE * 60
export const DAY = HOUR * 24
export const WEEK = 7 * DAY

export const LAZY_LOAD_IMAGE_MAX_RETRIES = 5
export const LAZY_LOAD_IMAGE_DELAY_MS = 1000

export const POST_HTML_IMAGE_CONTAINER_CLASS = 'image-container'
export const POST_HTML_VIDEO_IFRAME_CLASS = 'video'
