/**
 * 객체를 재귀적으로 평탄화하고 새로운 평탄화된 객체를 반환
 *
 * @param {Object} obj - 평탄화할 객체
 * @param {string} [prefix=''] - 평탄화된 객체의 각 키에 추가될 접두사
 */
export const useFlattenObject = (obj: any, prefix: string = '') =>
  Object.keys(obj).reduce((acc: any, k: string) => {
    const pre = prefix.length ? prefix + '[' + k + ']' : k

    if (
      !(obj[k] instanceof File) &&
      typeof obj[k] === 'object' &&
      !Array.isArray(obj[k]) &&
      obj[k] !== null
    ) {
      Object.assign(acc, useFlattenObject(obj[k], pre))
    } else {
      acc[pre] = obj[k]
    }
    return acc
  }, {})
