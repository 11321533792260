import { useCreateHtmlByString } from './useCreateHtmlByString'
import type { Og } from '@store'

/**
 * 주어진 HTML 문자열과 URL에서 Open Graph 메타데이터를 추출
 *
 * @param {string} html - 파싱할 HTML 문자열
 * @param {string} url - HTML 페이지의 URL
 * @returns {Og} - 추출된 Open Graph 메타데이터
 */
export const useGetOpenGraphs = (html: string, url: string) => {
  const dom = useCreateHtmlByString(html)!
  const ogs = dom.querySelectorAll('meta[property^="og:"]')

  const returnBase: Og = {}
  ;(Array.from(ogs) as HTMLMetaElement[]).forEach((og) => {
    const property = og.getAttribute('property')
    if (property) {
      returnBase[property.replace('og:', '')] = og.content
    }
  })

  // set: title
  if (!returnBase.title) {
    returnBase.title = dom.querySelector('title')?.innerText || ''
  }

  // set: description
  if (!returnBase.description) {
    returnBase.description =
      (dom.querySelector('meta[name="description"]') as HTMLMetaElement)
        ?.content || ''
  }

  // set: url 추출 가능한 url이 없는 경우
  if (!returnBase.url) {
    returnBase.url = url
  }

  // set: url에 http, https가 없는경우
  if (returnBase.url && !useCheckHttpProtocol(returnBase.url)) {
    returnBase.url = `https://${returnBase.url}`
  }

  return returnBase
}
