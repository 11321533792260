<script setup lang="ts">
const appStore = useAppStore()
const { appVersion, appType, currentVer } = storeToRefs(appStore)
const { checkForceUpdateVersion } = useCheckVersion()

// model: 앱 버전 비교
const compareAppVersion = computed(() => {
  const recentAppVersion = calcVersion('recent')
  const forceUpdateAppVersion = calcVersion('force')

  return {
    mode: appType?.value || 'Web',
    currentAppVersion: currentVer?.value || 'null',
    recentAppVersion: recentAppVersion.version,
    forceUpdateAppVersion: forceUpdateAppVersion.version,
    requireUpdate: forceUpdateAppVersion.requireUpdate,
  }
})

// model: iOS 앱 버전 정보
const iosAppVersion = computed(() => [
  {
    key: 'finalAppVersion',
    value: appVersion.value?.ios.finalAppVersion || 'null',
    description: '최신 앱 버전',
  },
  {
    key: 'forceUpdateAppVersion',
    value: appVersion.value?.ios.forceUpdateAppVersion || 'null',
    description: '강제 업데이트 최소 버전',
  },
])

// model: AOS 앱 버전 정보
const aosAppVersion = computed(() => [
  {
    key: 'finalAppVersion',
    value: appVersion.value?.android.finalAppVersion || 'null',
    description: '최신 앱 버전',
  },
  {
    key: 'forceUpdateAppVersion',
    value: appVersion.value?.android.forceUpdateAppVersion || 'null',
    description: '강제 업데이트 최소 버전',
  },
])

const appInfoDescriptions = ref([
  'AppStore, PlayStore에 배포된 앱의 최신 버전 및 최소 강제 업데이트 정보를 표시합니다.',
])

const calcVersion = (versionType: 'recent' | 'force') => {
  if (versionType === 'recent') {
    switch (appType.value) {
      case 'IOS':
        return {
          version: appVersion.value?.ios.finalAppVersion || 'null',
          requireUpdate: 'unknown',
        }
      case 'AOS':
        return {
          version: appVersion.value?.android.finalAppVersion || 'null',
          requireUpdate: 'unknown',
        }
      default:
        return {
          version: 'unknown',
          requireUpdate: 'unknown',
        }
    }
  } else if (versionType === 'force') {
    switch (appType.value) {
      case 'IOS':
        return {
          version: appVersion.value?.ios.forceUpdateAppVersion || 'null',
          requireUpdate: checkForceUpdateVersion(
            currentVer.value!,
            appVersion.value!.ios.forceUpdateAppVersion
          )
            ? '미필요'
            : '필요',
        }
      case 'AOS':
        return {
          version: appVersion.value?.android.forceUpdateAppVersion || 'null',
          requireUpdate: checkForceUpdateVersion(
            currentVer.value!,
            appVersion.value!.android.forceUpdateAppVersion
          )
            ? '미필요'
            : '필요',
        }
      default:
        return {
          version: 'unknown',
          requireUpdate: 'unknown',
        }
    }
  } else {
    return {
      version: 'unknown',
      requireUpdate: 'unknown',
    }
  }
}
</script>

<template>
  <section class="dev-section">
    <h4 class="dev-section-title">
      <span>
        앱 버전 <small>최신 버전 및 강제 업데이트 최소 버전 정보</small>
      </span>
    </h4>

    <div class="section-inner">
      <h4>앱 버전 비교</h4>

      <table class="dev-table">
        <colgroup>
          <col style="width: 160px" />
        </colgroup>
        <thead>
          <tr>
            <th>모드</th>
            <th>앱 버전</th>
            <th>최신 버전</th>
            <th>강제 업데이트 최소 버전</th>
            <th>업데이트</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ compareAppVersion.mode }}</td>
            <td>{{ compareAppVersion.currentAppVersion }}</td>
            <td>{{ compareAppVersion.recentAppVersion }}</td>
            <td>{{ compareAppVersion.forceUpdateAppVersion }}</td>
            <td>{{ compareAppVersion.requireUpdate }}</td>
          </tr>
        </tbody>
      </table>

      <h4>iOS</h4>

      <table class="dev-table">
        <colgroup>
          <col style="width: 160px" />
        </colgroup>
        <thead>
          <tr>
            <th>키</th>
            <th>값</th>
            <th>설명</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in iosAppVersion" :key="item.key">
            <td>{{ item.key }}</td>
            <td>{{ item.value }}</td>
            <td v-dompurify-html="item.description" />
          </tr>
        </tbody>
      </table>

      <h4>AOS</h4>

      <table class="dev-table">
        <colgroup>
          <col style="width: 160px" />
        </colgroup>
        <thead>
          <tr>
            <th>키</th>
            <th>값</th>
            <th>설명</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in aosAppVersion" :key="item.key">
            <td>{{ item.key }}</td>
            <td>{{ item.value }}</td>
            <td v-dompurify-html="item.description" />
          </tr>
        </tbody>
      </table>

      <NovaDescriptions :descriptions="appInfoDescriptions" />
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import '@styles/base/dev-tools';
@include dev-tools-section;

.section-inner {
  > h4 {
    @include text-style($text-body-14-bold);
  }
}
</style>
