<script setup lang="ts">
import type { NovaModalNoticeCreateTempSaveListEmits } from './NovaModalNoticeCreateTempSaveList.types'
import type {
  CreatorNotice,
  AuthToken,
  TempCreatorNoticeListPayload,
  DeleteCreatorNoticePayload,
} from '@store'
import { NovaModalDialog } from '#components'

const emit = defineEmits<NovaModalNoticeCreateTempSaveListEmits>()
const { gtEvent } = useGoogleTag()
const { t } = useI18n()
const { cookieNames, getCookie } = useCookies()
const { date } = useDateFormat()
const creatorStore = useCreatorStore()
const userStore = useUserStore()
const layoutStore = useLayoutStore()
const { show: modalShow, hide: modalHide } = useModal()
const state = reactive({
  isLoading: true,
  isError: false,
})
const authToken = getCookie<AuthToken>(cookieNames.AUTH_TOKEN)

onMounted(() => {
  fetchTempCreatorNoticeList(false)
})

// 임시 저장 목록 조회
const fetchTempCreatorNoticeList = async (isRecycle: boolean) => {
  try {
    state.isLoading = true
    state.isError = false

    const payload: TempCreatorNoticeListPayload = {
      grantType: authToken!.grantType,
      accessToken: authToken!.accessToken,
    }
    await creatorStore.fetchTempCreatorNoticeList(payload)
    if (isRecycle) {
      gtEvent('scrollEvent', {
        eventCategory: '더보기',
        eventAction: '임시 저장 목록 조회',
        eventLabel: 'loadMore',
        eventSlot: '',
        eventI18nAddr: '',
        eventComponent: 'RecyclerView',
        grantType: payload.grantType,
      })
    }
  } catch {
    state.isError = true
  } finally {
    state.isLoading = false
  }
}

// 임시 저장 아이템 삭제
const handleOnDeleteItem = async (crtrNttSn: CreatorNotice['crtrNttSn']) => {
  try {
    layoutStore.updateLoadingIndicatorGlobal({ show: true })
    const payload: DeleteCreatorNoticePayload = {
      crtrNttSn,
      creatorUserSn: userStore.user!.userSn,
      grantType: authToken!.grantType,
      accessToken: authToken!.accessToken,
    }
    await creatorStore.deleteCreatorNotice(payload)
    await fetchTempCreatorNoticeList(false)
    gtEvent('userAction', {
      eventCategory: '사용자',
      eventAction: '임시저장 된 내용 삭제 모달 > 삭제',
      eventLabel: t('dialog.confirmTempPostDelete.action.positive'),
      eventSlot: '공지사항 임시저장 모달',
      eventI18nAddr: useKoreanTranslation(
        'dialog.confirmTempPostDelete.action.positive'
      ),
      eventComponent: 'Button',
      creatorUserSn: userStore.user!.userSn,
    })
  } catch {
    useToast(t('statusMessages.tempPostRemove.error'))
  } finally {
    layoutStore.updateLoadingIndicatorGlobal({ show: false })
  }
}

// 임시 저장 아이템 삭제전 컨펌
const showDeleteConfirm = async (crtrNttSn: CreatorNotice['crtrNttSn']) => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '임시저장 된 내용 삭제 모달 > 삭제',
    eventLabel: t('dialog.confirmTempPostDelete.action.positive'),
    eventSlot: '공지사항 임시저장 모달',
    eventI18nAddr: useKoreanTranslation(
      'dialog.confirmTempPostDelete.action.positive'
    ),
    eventComponent: 'Button',
    creatorUserSn: userStore.user!.userSn,
  })
  await modalShow({
    component: NovaModalDialog,
    bind: {
      name: modalsName.MODAL_TEMP_PCREATOR_NOTICE_DELETE_CONFIRM,
      btns: [
        {
          label: t('dialog.confirmTempPostDelete.action.negative'),
          theme: 'transparent',
          size: 32,
          onClick: async () => {
            gtEvent('clickEvent', {
              eventCategory: '클릭',
              eventAction: '임시저장 된 내용 삭제 모달 > 삭제 확인 > 취소',
              eventLabel: t('dialog.confirmTempPostDelete.action.negative'),
              eventSlot: useKoreanTranslation(
                'dialog.confirmTempPostDelete.title'
              ),
              eventI18nAddr: useKoreanTranslation(
                'dialog.confirmTempPostDelete.action.negative'
              ),
              eventComponent: 'Button',
              creatorUserSn: userStore.user!.userSn,
            })
            await modalHide(
              modalsName.MODAL_TEMP_PCREATOR_NOTICE_DELETE_CONFIRM
            )
          },
        },
        {
          label: t('dialog.confirmTempPostDelete.action.positive'),
          theme: 'primary-blue-light',
          size: 32,
          onClick: async () => {
            gtEvent('clickEvent', {
              eventCategory: '클릭',
              eventAction: '임시저장 된 내용 삭제 모달 > 삭제 확인 > 삭제',
              eventLabel: t('dialog.confirmTempPostDelete.action.positive'),
              eventSlot: useKoreanTranslation(
                'dialog.confirmTempPostDelete.title'
              ),
              eventI18nAddr: useKoreanTranslation(
                'dialog.confirmTempPostDelete.action.positive'
              ),
              eventComponent: 'Button',
              creatorUserSn: userStore.user!.userSn,
            })
            await handleOnDeleteItem(crtrNttSn)
            await modalHide(
              modalsName.MODAL_TEMP_PCREATOR_NOTICE_DELETE_CONFIRM
            )
          },
        },
      ],
    },
    slots: {
      title: t('dialog.confirmTempPostDelete.title'),
      content: t('dialog.confirmTempPostDelete.description'),
    },
  })
}

// 임시 저장 아이템 선택
const selectTempItem = (id: number, data?: any) => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '임시저장 모달 > 선택',
    eventLabel: '',
    eventSlot: '공지사항 임시저장 모달',
    eventI18nAddr: '',
    eventComponent: 'Button',
    crtrNttSn: data.crtrNttSn,
    crtrUserSn: data.crtrUserSn,
    crtrNttSj: data.crtrNttSj,
    crtrNttCn: data.crtrNttCn,
  })
  emit('selectItem', id)
}
</script>

<template>
  <div class="temp-list-wrap">
    <NovaLoadingIndicator
      v-if="state.isLoading"
      :size="80"
      :fill="true"
      :bg-bright="'light'"
    />
    <NovaBoxEmptyMessage
      v-if="
        !state.isLoading &&
        !state.isError &&
        !creatorStore.tempCreatorNoticeList.length
      "
      :message="$t('statusMessages.tempList.empty')"
    />
    <NovaBoxEmptyMessage
      v-if="state.isError"
      :message="$t('statusMessages.tempList.error')"
    />

    <NovaRecyclerGrid
      v-if="
        !state.isLoading &&
        !state.isError &&
        creatorStore.tempCreatorNoticeList.length
      "
      :items="creatorStore.tempCreatorNoticeList"
      :is-last="true"
      :is-loading="state.isLoading"
      :is-error="state.isError"
      :is-refresh="false"
      :key-name="'crtrNttSn'"
      :responsive="{
        mobile: { columns: 1, rowGap: 0, columnGap: 0 },
        tablet: { columns: 1, rowGap: 0, columnGap: 0 },
        desktop: { columns: 1, rowGap: 0, columnGap: 0 },
      }"
      :page-mode="false"
      :bottom-white-space="0"
      @load-more="fetchTempCreatorNoticeList(true)"
    >
      <template #item="{ item }">
        <div class="temp-item" @click="selectTempItem(item.crtrNttSn, item)">
          <span class="title">{{ item.crtrNttSj }}</span>
          <div class="tools">
            <span class="date">
              {{ date({ date: item.sysRegistDt, format: 'YYYY-MM-DD' }) }}
            </span>
            <NovaButtonIcon
              :icon="{ type: 'outline', name: 'close' }"
              :size="20"
              :class="['btn-clear']"
              @click.stop="showDeleteConfirm(item.crtrNttSn)"
            />
          </div>
        </div>
      </template>
    </NovaRecyclerGrid>
  </div>
</template>

<style lang="scss" scoped>
.temp-list-wrap {
  display: flex;
  max-height: 100%;
  overflow: hidden;
}
.temp-list {
  display: flex;
  max-height: 100%;
  flex-direction: column;
  height: auto;

  :deep(.scroller) {
    padding: 0 20px;
  }

  .vue-recycle-scroller__item-view:last-of-type .temp-item {
    border-bottom: none;
  }
}

.temp-item {
  display: flex;
  flex-direction: row;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  border-bottom: 1px solid $color-gray-1;
  width: 100%;
  padding: 0 20px;

  .title {
    @include text-style($text-body-14-regular);
    @include ellipsis(1);
    flex-grow: 1;
  }
  .tools {
    display: flex;
    gap: 12px;
    flex-shrink: 0;

    .date {
      @include text-style($text-body-12-regular);
      color: $color-text-4;
      width: 64px;
      text-align: right;
    }
  }
}
</style>
