<script setup lang="ts">
import { Tippy } from 'vue-tippy'
import type { Placement } from 'tippy.js'
import {
  ChatButtonMode,
  type NovaChatButtonProps,
} from './NovaChatButton.types'
import { ChatMode } from '@sendbird'

const { mode } = withDefaults(defineProps<NovaChatButtonProps>(), {
  creator: null,
})
const { t } = useI18n()
const { createChat, enterChat } = useSendbirdGate()
const userStore = useUserStore()
const { showSignInDialog } = useMembershipProcess()
const tippyRef = ref<typeof Tippy | null>(null)
const gateParent = ref<Element>()
const gatePosition = computed<Placement>(() => {
  switch (mode) {
    case ChatButtonMode.FLOATING:
      return 'left-end'
    case ChatButtonMode.HEADER:
      return 'left-start'
    default:
      return 'left'
  }
})
const loading = ref(false)
const { gtEvent } = useGoogleTag()

onBeforeMount(() => {
  gateParent.value = document.body
  window.addEventListener('resize', hideTippy)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', hideTippy)
})

const hideTippy = () => {
  tippyRef.value?.hide()
}

const changeLoading = (_loading: boolean) => {
  loading.value = _loading
}

const handleOnCreateChatChannel = () => {
  if (loading.value) return
  createChat({ mode: 'showCreateChannelModal', onLoading: changeLoading })
  hideTippy()
}

const handleOnEnterChatChannelList = () => {
  if (loading.value) return
  enterChat({ mode: ChatMode.CHANNEL_LIST, onLoading: changeLoading })
  hideTippy()
}

const handleOnOpenChatGate = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '채팅 버튼 클릭',
    eventLabel: 'chat',
    eventSlot: '',
    eventI18nAddr: 'chat',
    eventComponent: 'Button',
  })
  // 1. 로딩중 중복 클릭 방지
  if (loading.value) {
    return false
  }

  // 2. 비 로그인 상태면 로그인 유도
  if (!userStore.user) {
    showSignInDialog()
    return false
  }

  if (userStore.user.cmtyUserSeCode === 'G') {
    enterChat({ mode: ChatMode.CHANNEL_LIST, onLoading: changeLoading })
    return false
  }

  // 3. 채널 리스트 모드로 챗 진입
  if (userStore.user.cmtyUserSeCode === 'C' && userStore.user.channelUrl) {
    enterChat({
      mode: ChatMode.CHANNEL,
      channelUrl: userStore.user.channelUrl,
      onLoading: changeLoading,
    })
    return false
  }
}
</script>

<template>
  <ClientOnly>
    <div :class="['chat-button-wrap', `${mode}`, { loading }]">
      <div class="inner">
        <Tippy
          ref="tippyRef"
          :append-to="gateParent!"
          :interactive="true"
          :theme="'chat-gate'"
          :placement="gatePosition"
          :trigger="'click'"
          :on-show="handleOnOpenChatGate"
          :role="'button'"
          :z-index="31"
        >
          <div class="button-wrap">
            <NovaIcon
              :icon="{ type: 'outline', name: 'chat' }"
              class="chat-icon"
            />

            <NovaLoadingIndicator
              v-if="loading"
              :size="60"
              :bg-bright="'dark'"
              :fill="false"
              class="loading"
            />
          </div>

          <template #content>
            <div class="chat-gate">
              <button
                type="button"
                class="chat-gate-item"
                @click="handleOnCreateChatChannel"
              >
                {{ t('sendbird.createChatChannel.actions.create') }}
              </button>
              <button
                type="button"
                class="chat-gate-item"
                @click="handleOnEnterChatChannelList"
              >
                {{ t('sendbird.enterChatChannel.actions.enter') }}
              </button>
            </div>
          </template>
        </Tippy>
      </div>
    </div>
  </ClientOnly>
</template>

<style lang="scss" scoped>
.chat-button-wrap {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &.floating {
    position: fixed;
    right: 40px;
    bottom: 40px;
    z-index: $z-index-floating-chat-gate-button;

    > .inner {
      .button-wrap {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: $color-text-5;

        .chat-icon {
          font-size: 28px !important;
          color: $color-white;
        }
      }
    }

    @include mobile {
      display: none;
    }
  }

  &.header {
    position: relative;
    display: none;

    > .inner {
      .button-wrap {
        background-color: transparent;
      }

      .chat-icon {
        font-size: 22px !important;
        color: #c8cad3;
      }
    }

    @include mobile {
      display: flex;
    }
  }

  &.loading {
    > .inner .button-wrap .chat-icon {
      opacity: 0.3;
    }
  }

  > .inner {
    .button-wrap {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.chat-gate {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .chat-gate-item {
    word-break: keep-all;
    transition: font-weight 0.12s ease-in-out;

    &:hover {
      //font-weight: 500;
    }
  }
}
</style>
