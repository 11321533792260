<script setup lang="ts">
import type {
  NovaModalPostCreatePreviewEmits,
  NovaModalPostCreatePreviewProps,
} from './NovaModalPostCreatePreview.types'

const emit = defineEmits<NovaModalPostCreatePreviewEmits>()
withDefaults(defineProps<NovaModalPostCreatePreviewProps>(), {
  isLivePost: false,
  imgOrVideoFile: null,
  createPostType: 'NORM',
})
</script>

<template>
  <div class="preview-wrap">
    <div class="post-title-wrap">
      <NovaBadgeLive v-if="isLivePost" />
      <h4 v-dompurify-html="title" class="post-title" />
    </div>

    <NovaBoxPostContentsSnapshotImage
      v-if="createPostType === 'CARD' && imgOrVideoFile?.images?.length"
      :images="imgOrVideoFile?.images"
    />

    <NovaBoxPostContentsSnapshotVideo
      v-if="createPostType === 'CVOD' && imgOrVideoFile?.video?.length"
      :videos="imgOrVideoFile?.video || []"
      :view-type="'detail'"
    />

    <div class="preview-box">
      <NovaBoxPostContentsSnapshotContents
        :contents="contents"
        :show-gradiant="false"
        :view-type="'detail'"
        class="post-contents"
      />
    </div>

    <NovaHashtags :hashtags="hashtags" :prefix="'#'" />

    <div class="action-box">
      <NovaButtonText
        :label="$t('postCreate.button.confirm')"
        :theme="'secondary-gray'"
        :size="32"
        class="btn-action"
        @click="emit('close')"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.preview-wrap {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;

  .post-title-wrap {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    gap: 4px;
    .post-title {
      width: 100%;
      flex-shrink: 0;
      @include text-style($text-heading2-bold);
      color: $color-text-2;
      @include ellipsis(2);
    }
  }

  .preview-box {
    height: 100%;
    overflow-y: overlay;
  }

  .post-contents {
    flex-grow: 1;
  }
  .action-box {
    text-align: right;
  }
}
</style>
