import { AxiosError } from 'axios'
import type { PostPrivateAction, PostsItem, Post } from '@store'
import { HOUR } from '@configs'
import type {
  DetailType,
  MenuSetType,
} from '@components/NovaModalReport/NovaModalReport.types'
import {
  NovaModalCommon,
  NovaModalDialog,
  NovaModalReport,
  NovaModalPostCreate,
} from '#components'

export type PrivateActionMutable<T> = {
  [Property in keyof T]: T[Property]
}

export type PrivateActionsItem<T> = {
  id: T
  name: string
}

export type PrivateAction = PrivateActionMutable<
  PrivateActionsItem<PostPrivateAction>
>

export interface UsePrivateActionsProps {
  source: PostsItem | Post // 포스트 모델
}

export const usePrivateActions = (
  props: ComputedRef<UsePrivateActionsProps>
) => {
  const { t, locale, messages } = useI18n()
  const { dayjs } = useDayjs()
  const userStore = useUserStore()
  const postStore = usePostStore()
  const etcStore = useEtcStore()
  const layoutStore = useLayoutStore()
  const appStore = useAppStore()
  const { gtEvent } = useGoogleTag()
  const { show: modalShow, hide: modalHide } = useModal()
  const loading = ref(false)
  const isWriterEqualUser = computed(
    () => props.value.source.userSn === userStore.user?.userSn
  )
  const privateActions = computed(
    () =>
      Object.keys(messages.value[locale.value].postPrivateAction)
        .map((key) => ({ id: key, name: t(`postPrivateAction.${key}`) }))
        .filter((item) =>
          actionFilterItem(item as PrivateAction)
        ) as PrivateAction[]
  )
  const reportCode = ref<string | null>(null)
  const reportContents = ref('')
  const reportMenuSet = computed<MenuSetType[]>(() =>
    etcStore.reportCodes.map((item) => ({
      label: item.stdCodeNm,
      value: item.stdCode,
      contentRequiredAt: item.contentRequiredAt,
    }))
  )
  const reportForm = computed<DetailType>(() => ({
    dropdownPlaceholder: t('reportForm.dropdownPlaceholder'),
    textareaPlaceholder: t('reportForm.textareaPlaceholder'),
    textareaMax: 4000,
    btnLabel: [t('reportAction.cancel'), t('reportAction.send')],
  }))

  // 게시물 상태에 따라 노출 되는 액션 아이템 필터
  const actionFilterItem = (action: PrivateAction): boolean => {
    const signInAllowActions: PostPrivateAction[] = [
      'scrap',
      'cancelScrap',
      'hide',
      'report',
      'modify',
      'remove',
      'donate',
    ]
    const eqUserAllowActions: PostPrivateAction[] = ['remove', 'edit']
    const noEqUserAllowActions: PostPrivateAction[] = [
      'hide',
      'report',
      'scrap',
      'cancelScrap',
      'donate',
    ]
    // const hour = 1000 * 60 * 60
    const createAtTimestamp = new Date(
      dayjs.utc(props.value.source.nttRegistDt).format()
    ).getTime()
    const nowTimestamp = new Date(dayjs.utc().format()).getTime()
    const isAbleEdit = nowTimestamp - createAtTimestamp < HOUR

    // 로그인이 필요한 액션일 경우 비로그인 상태에서 비노출
    if (!userStore.isSignIn && signInAllowActions.includes(action.id))
      return false

    // 프리미엄 멤버십 콘텐츠면 공유하기 비노출
    if (action.id === 'share' && props.value.source.prmbrshCntntsAt === 'Y')
      return false

    // 액션이 스크랩일때 포스트 스크랩 상태가 스크랩 상태이면 비노출
    if (action.id === 'scrap' && props.value.source.scFlag === '1') return false

    // 액션이 스크랩 취소일때 포스트 스크랩 상태가 비스크랩 상태이면 비노출
    if (action.id === 'cancelScrap' && props.value.source.scFlag === '0')
      return false

    // 로그인 상태에서 허용되지 않은 액션일 경우 비노출
    if (isWriterEqualUser.value && noEqUserAllowActions.includes(action.id))
      return false

    // 비 로그인 상태에서 허용되지 않은 액션일 경우 비노출
    if (!isWriterEqualUser.value && eqUserAllowActions.includes(action.id))
      return false

    // 포스트 작성자가 크리에이터가 아닌경우 비노출
    if (action.id === 'donate' && props.value.source.cmtyUserSeCode !== 'C')
      return false

    // TODO: 앱 버전 릴리즈를 위해 기능 숨김
    if (action.id === 'donate' && appStore.isApp) return false

    // 신고하기 노출 조건
    if (action.id === 'report' && props.value.source.reportableAt === 'N')
      return false

    // 일반 포스트(프리미엄 포스트X)이고 최초 작성 시간이 한시간 이후이면 수정 비노출
    if (
      action.id === 'edit' &&
      props.value.source.prmbrshCntntsAt === 'N' &&
      !isAbleEdit
    )
      return false

    // 위 조건을 충족하지 않는 액션은 노출
    return true
  }

  // private action wrapper
  const action = async (id: PostPrivateAction) => {
    switch (id) {
      case 'scrap':
      case 'cancelScrap':
        await scrap()
        break
      case 'hide':
        await hide()
        break
      case 'report':
        await report()
        break
      case 'remove':
        await remove()
        break
      case 'donate':
        await handleOnDonate()
        break
      case 'edit':
        await handleOnEdit()
        break
      default:
        throw new Error('post private action type is not match')
    }
  }

  // scrap: 포스트 스크랩, 스크랩 취소 하기
  const scrap = async () => {
    if (props.value.source.scFlag === '0') {
      try {
        loading.value = true
        await postStore.reqPostScrap({
          cmtyNttSn: props.value.source.cmtyNttSn,
        })
        useToast(t('statusMessages.postScrap.success'))
      } catch {
        useToast(t('statusMessages.postScrap.error'))
      } finally {
        loading.value = false
      }
    } else {
      try {
        loading.value = true
        await postStore.reqPostScrapCancel({
          cmtyNttSn: props.value.source.cmtyNttSn,
        })
        useToast(t('statusMessages.postScrapCancel.success'))
      } catch {
        useToast(t('statusMessages.postScrapCancel.error'))
      } finally {
        loading.value = false
      }
    }
  }

  // hide: 포스트 숨기기
  const hide = async () => {
    try {
      loading.value = true
      await postStore.reqPostHide({ cmtyNttSn: props.value.source.cmtyNttSn })
    } catch {
      useToast(t('statusMessages.postHide.error'))
    } finally {
      loading.value = false
    }
  }

  // report: 포스트 신고하기(팝업 호출)
  const report = async () => {
    // 1. 포스트 혹은 댓글 별 신고 분류 코드 리스트 조회
    await etcStore.fetchReportCodes({ nttTyCode: 'N' })

    // 2. 신고하기 모달 열기
    await modalShow({
      component: NovaModalCommon,
      bind: {
        name: modalsName.MODAL_REPORT_POST,
        showCloseBtn: true,
      },
      on: {
        close: async () => {
          await modalHide(modalsName.MODAL_REPORT_POST)
          gtEvent('clickEvent', {
            eventCategory: '클릭',
            eventAction: `${useKoreanTranslation(
              'reportForm.title'
            )} 모달 닫기`,
            eventLabel: '',
            eventSlot: '',
            eventI18nAddr: '',
            eventComponent: 'NovaModalCommon',
          })
          reportCode.value = null
        },
      },
      slots: {
        title: t('reportForm.title'),
        contents: {
          component: NovaModalReport,
          bind: {
            detail: reportForm.value,
            menuSet: reportMenuSet.value,
            message: t('postReportNotification'),
            where: t('reportForm.title'),
            cmtyNttSn: props.value.source.cmtyNttSn,
            title: useKoreanTranslation('reportForm.title'),
          },
          on: {
            close: async () => {
              await modalHide(modalsName.MODAL_REPORT_POST)
              reportCode.value = null
            },
            updateMenu: (value: string) => {
              reportCode.value = value
            },
            updateText: (value: string) => {
              reportContents.value = value
            },
            confirm: async () => {
              await handleOnReportSend()
            },
          },
        },
      },
    })
  }

  // 포스트 신고하기 API 호출
  const handleOnReportSend = async () => {
    const payload = {
      cmtyNttSn: props.value.source.cmtyNttSn,
      rptCn: reportContents.value,
      sanctnsRptClCode: reportCode.value!,
    }

    try {
      layoutStore.updateLoadingIndicatorGlobal({ show: true })
      await postStore.reqPostReport(payload)
      useToast(t('statusMessages.postReport.success'))
      await modalHide(modalsName.MODAL_REPORT_POST)
    } catch (err) {
      if (err instanceof AxiosError) {
        const status = err.response?.status
        switch (status) {
          case 2000:
          case 2006:
          case 2010:
          case 2012:
          case 4002:
            useToast(t(`statusMessages.postReport.errors.${status}`))
            break
          case 2020:
            await modalHide(modalsName.MODAL_REPORT_POST)
            await modalHide(modalsName.MODAL_POST_DETAIL)
            postStore.removePost({ cmtyNttSn: payload.cmtyNttSn })
            useToast(t('statusMessages.postReport.errors.2020'))
            break
          default:
            useToast(t('statusMessages.postReport.errors.unknown'))
            break
        }
      }
    } finally {
      layoutStore.updateLoadingIndicatorGlobal({ show: false })
    }
  }

  // 포스트 삭제하기(팝업 호출)
  const remove = async () => {
    await modalShow({
      component: NovaModalDialog,
      bind: {
        name: modalsName.MODAL_DIALOG,
        btns: [
          {
            label: t('cancel'),
            theme: 'transparent',
            size: 32,
            onClick: () => {
              gtEvent('clickEvent', {
                eventCategory: '클릭',
                eventAction: '포스트 삭제 > 취소 버튼 클릭',
                eventLabel: t('cancel'),
                eventSlot: '포스트 삭제 모달',
                eventI18nAddr: useKoreanTranslation('cancel'),
                eventComponent: 'Button',
              })
              modalHide(modalsName.MODAL_DIALOG)
            },
          },
          {
            label: t('confirm'),
            theme: 'primary-blue-light',
            size: 32,
            onClick: () => {
              gtEvent('clickEvent', {
                eventCategory: '클릭',
                eventAction: '포스트 삭제 > 확인 버튼 클릭',
                eventLabel: t('confirm'),
                eventSlot: '포스트 삭제 모달',
                eventI18nAddr: useKoreanTranslation('confirm'),
                eventComponent: 'Button',
              })
              handleOnPostRemove()
              modalHide(modalsName.MODAL_DIALOG)
            },
          },
        ],
      },
      slots: {
        title: t('confirm'),
        content: t('postRemoveConfirmMessage'),
      },
    })
  }

  // 포스트 숨기기
  const handleOnPostRemove = async () => {
    try {
      layoutStore.updateLoadingIndicatorGlobal({ show: true })
      await postStore.reqPostRemove({ cmtyNttSn: props.value.source.cmtyNttSn })
      gtEvent('userAction', {
        eventCategory: '사용자',
        eventAction: '포스트 삭제',
        eventLabel: '',
        eventSlot: '포스트 삭제',
        eventI18nAddr: '',
        eventComponent: 'Button',
        cmtyNttSn: props.value.source.cmtyNttSn,
      })
    } catch {
      useToast(t('statusMessages.postRemove.error'))
    } finally {
      layoutStore.updateLoadingIndicatorGlobal({ show: false })
    }
  }

  // 후원하기
  const handleOnDonate = async () => {
    await useDonate({
      userSn: props.value.source.userSn,
      userNcnm: props.value.source.userNcnm,
      cmtyNttSn: props.value.source.cmtyNttSn,
    })
  }

  // 게시글 수정하기
  const handleOnEdit = async () => {
    await modalShow({
      component: NovaModalCommon,
      bind: {
        name: modalsName.MODAL_CREATE_POST,
        showCloseBtn: true,
        mobileFull: true,
        hasInnerScroll: true,
        type: 'create',
      },
      on: {
        customClose: () => {
          useEvent('post:createClose')
        },
      },
      slots: {
        title: t('editPost'),
        contents: {
          component: NovaModalPostCreate,
          bind: {
            createPostType: props.value.source.nttTyCode,
            imgOrVideoFile: null,
            source: props.value.source,
            createPremiumPost: props.value.source.prmbrshCntntsAt === 'Y',
          },
          on: {
            close: async () => {
              await modalHide(modalsName.MODAL_CREATE_POST)
            },
          },
        },
      },
    })
  }

  return { privateActions, action }
}
