import { AxiosError } from 'axios'
import {
  BlockingTarget,
  type BlockStatusModel,
  type CreatorProfile,
  type HomeProfile,
} from '@store'
import type { BlockDialog } from '@components/NovaModalMyBlock/NovaModalMyBlock.types'
/**
 * 사용자간 차단 상태
 * @enum {string}
 */
export enum BlockStatus {
  // 사용자간 차단이 존재하지 않음
  NO_BLOCKING = 'NO_BLOCKING',

  // 현재 사용자가 다른 사용자를 차단함
  BLOCKED_BY_CURRENT_USER = 'BLOCKED_BY_CURRENT_USER',

  // 다른 사용자가 현재 사용자를 차단함
  BLOCKED_BY_OTHER_USER = 'BLOCKED_BY_OTHER_USER',

  // 두 사용자가 서로를 차단함
  MUTUAL_BLOCKING = 'MUTUAL_BLOCKING',
}

/**
 * 사용자 차단 모델
 */
interface BlockStatusExtendModel extends BlockStatusModel {
  userSn: number | null
  userNcnm: string
  target: BlockingTarget
}

/**
 * 주어진 매개변수를 기반으로 차단 상태 모델을 가져오기.
 *
 * @param {BlockingTarget} target - 차단 대상(USER 또는 CREATOR).
 * @param {HomeProfile|null} userProfile - 사용자의 프로필 정보.
 * @param {CreatorProfile|null} creatorProfile - 생성자의 프로필 정보.
 * @returns {BlockStatusExtendModel} - 차단 상태 모델.
 */
const getStatusModel = (
  target: BlockingTarget,
  userProfile: HomeProfile | null,
  creatorProfile: CreatorProfile | null
): BlockStatusExtendModel => {
  switch (target) {
    case BlockingTarget.USER:
      return userProfile
        ? {
            userSn: userProfile.userSn,
            userNcnm: userProfile.userNcnm,
            blockingAt: userProfile.blockingAt,
            blockedAt: userProfile.blockedAt,
            target: BlockingTarget.USER,
          }
        : {
            userSn: null,
            userNcnm: '',
            blockingAt: 'N',
            blockedAt: 'N',
            target: BlockingTarget.USER,
          }
    case BlockingTarget.CREATOR:
      return creatorProfile
        ? {
            userSn: creatorProfile.userSn,
            userNcnm: creatorProfile.userNcnm,
            blockingAt: creatorProfile.blockingAt,
            blockedAt: creatorProfile.blockedAt,
            target: BlockingTarget.CREATOR,
          }
        : {
            userSn: null,
            userNcnm: '',
            blockingAt: 'N',
            blockedAt: 'N',
            target: BlockingTarget.CREATOR,
          }
  }
}

/**
 * 제공된 상태 모델을 기반으로 차단 상태 얻기.
 *
 * @param {BlockStatusModel} statusModel - 차단 상태를 결정하기 위한 상태 모델.
 * @returns {BlockStatus} - 차단 상태.
 */
const getBlockStatus = (statusModel: BlockStatusModel): BlockStatus => {
  const { blockingAt, blockedAt } = statusModel

  if (blockingAt === 'Y' && blockedAt === 'Y')
    return BlockStatus.MUTUAL_BLOCKING

  if (blockedAt === 'Y') return BlockStatus.BLOCKED_BY_OTHER_USER

  if (blockingAt === 'Y') return BlockStatus.BLOCKED_BY_CURRENT_USER

  return BlockStatus.NO_BLOCKING
}

/**
 * 차단 유형을 나타냅니다.
 * @enum {string}
 */
export enum BlockType {
  BLOCKING,
  UNBLOCKING,
}

/**
 * 차단 대화 상자 모델을 검색하는 데 필요한 매개변수를 나타냅니다.
 * @interface
 */
export interface GetBlockDialogModelParams {
  target: BlockingTarget
  type: BlockType
  userSn: number
  userNcnm: string
  isDonation?: boolean
}

/**
 * BlockDialog 객체를 반환하는 함수를 나타냅니다.
 * @typedef {function} GetBlockDialogModel
 *
 * @param {Object} params - 함수의 매개변수
 * @param {string} params.target - 차단/차단 해제 동작의 대상
 * @param {string} params.type - 차단/차단 해제의 종류 (BLOCKING/UNBLOCKING)
 * @param {string} params.userSn - 사용자의 일련번호
 * @param {string} params.userNcnm - 사용자의 이름
 * @param {boolean} params.isDonation - 차단 동작이 기부를 위한 것인지 여부
 *
 * @returns {BlockDialog} 차단 대화상자 모델 객체
 */
const getBlockDialogModel = ({
  target,
  type,
  userSn,
  userNcnm,
  isDonation,
  onClose,
}: GetBlockDialogModelParams & { onClose: () => void }): BlockDialog => {
  const { t } = useNuxtApp().$i18n
  const { reqBlockingUser, reqUnBlockingUser } = useCommStore()
  const { gtEvent } = useGoogleTag()
  const showErrorMessage = (err: AxiosError) => {
    const status = err.response?.status
    switch (status) {
      case 2010:
      case 2011:
      case 4002:
      case 4013:
        useToast(t(`blockMessage.toastMessage.error.${status}`))
        break
      default:
        useToast(t('blockMessage.toastMessage.error.unknown'))
        break
    }
  }
  switch (type) {
    case BlockType.UNBLOCKING:
      return {
        title: t('blockMessage.modal.unblock.title'),
        contents: {
          title: t('blockMessage.modal.unblock.contents.title', { userNcnm }),
          message: t(
            `blockMessage.modal.unblock.contents.${
              isDonation ? 'novaPlusMessage' : 'message'
            }`
          ),
        },
        action: {
          label: t('blockMessage.actions.unBlock'),
          onClick: async () => {
            gtEvent('clickEvent', {
              eventCategory: '클릭',
              eventAction: '차단 해제',
              eventLabel: t('blockMessage.toastMessage.unBlocking'),
              eventSlot: '차단 해제',
              eventI18nAddr: useKoreanTranslation(
                'blockMessage.toastMessage.unBlocking'
              ),
              eventComponent: 'Button',
              unBlckgUserSn: userSn,
            })
            try {
              await reqUnBlockingUser({ blckgUserSn: userSn }, target)
              useToast(t('blockMessage.toastMessage.unBlocking'))
              gtEvent('userAction', {
                eventCategory: '사용자',
                eventAction: '차단해제',
                eventLabel: t('blockMessage.toastMessage.unBlocking'),
                eventSlot: '차단 해제',
                eventI18nAddr: useKoreanTranslation(
                  'blockMessage.toastMessage.unBlocking'
                ),
                eventComponent: 'Button',
                unBlckgUserSn: userSn,
              })
              onClose()
            } catch (err) {
              if (err instanceof AxiosError) {
                showErrorMessage(err)
              }
            }
          },
        },
      }
    case BlockType.BLOCKING:
      return {
        title: t('blockMessage.modal.block.title'),
        contents: {
          title: t('blockMessage.modal.block.contents.title', { userNcnm }),
          message: t('blockMessage.modal.block.contents.message'),
        },
        action: {
          label: t('blockMessage.actions.block'),
          onClick: async () => {
            gtEvent('clickEvent', {
              eventCategory: '클릭',
              eventAction: '차단하기',
              eventLabel: t('blockMessage.toastMessage.blocking'),
              eventSlot: '차단하기',
              eventI18nAddr: useKoreanTranslation(
                'blockMessage.toastMessage.blocking'
              ),
              eventComponent: 'Button',
              unBlckgUserSn: userSn,
            })
            try {
              await reqBlockingUser({ blckgUserSn: userSn }, target)
              useToast(t('blockMessage.toastMessage.blocking'))
              gtEvent('userAction', {
                eventCategory: '사용자',
                eventAction: '차단하기',
                eventLabel: t('blockMessage.toastMessage.blocking'),
                eventSlot: '사용자 차단',
                eventI18nAddr: useKoreanTranslation(
                  'blockMessage.toastMessage.blocking'
                ),
                eventComponent: 'Button',
                blckgUserSn: userSn,
              })
              onClose()
            } catch (err) {
              if (err instanceof AxiosError) {
                showErrorMessage(err)
              }
            }
          },
        },
      }
  }
}

/**
 * 모달을 호출하여 차단 대화 상자를 표시합니다.
 *
 * @param {GetBlockDialogModelParams} params - 차단 대화상자 모델을 검색하는데 필요한 매개변수입니다.
 */
export const callBlockDialog = (params: GetBlockDialogModelParams) => {
  const { show, hide } = useModal()
  const blockDialog = getBlockDialogModel({
    ...params,
    onClose: () => {
      hide(modalsName.MODAL_MY_BLOCK)
    },
  })
  show(modalsName.MODAL_MY_BLOCK, { messageWrap: blockDialog }, '', false)
}

/**
 * UserBlockStatus Props
 *
 * @interface UserBlockStatusProps
 * @property {BlockingTarget} target - 차단 혹은 차단해제 대상 (일반 사용자, 크리에이터)
 * @property {function} [onShowContents] - 차단된 내용을 표시할 때 실행될 콜백 함수
 * @property {boolean} isDonation - 차단 혹은 차단해제 대상의 후원 여부
 */
interface UserBlockStatusProps {
  target: BlockingTarget
  onShowContents?: () => void
  isDonation?: boolean
}

/**
 * 주어진 차단 대상에 대한 차단 상태를 반환.
 *
 * @param {BlockingTarget} checkBlockingTarget - 확인할 차단 대상.
 * @param onShowContents
 */
export const useBlockStatus = ({
  target,
  onShowContents,
  isDonation,
}: UserBlockStatusProps) => {
  const { t } = useNuxtApp().$i18n
  const mypageStore = useMyPageStore()
  const creatorStore = useCreatorStore()
  const blockStatusModel = computed<BlockStatusExtendModel>(() => {
    return getStatusModel(target, mypageStore.homeProfile, creatorStore.profile)
  })
  const blockStatus = computed<BlockStatus>(() =>
    getBlockStatus(blockStatusModel.value)
  )
  const blockHeaderMessage = computed(() => {
    const model = blockStatusModel.value

    if (!model.userSn) return null

    if (model.blockingAt === 'Y') {
      return {
        title: t('blockMessage.messages.blockByCurrentUser', {
          userNcnm: model.userNcnm,
        }),
        message: t('blockMessage.messages.unBlocking'),
        action: {
          label: t('blockMessage.actions.blocked'),
          theme: 'primary-red',
          onClick: () => {
            callBlockDialog({
              target,
              type: BlockType.UNBLOCKING,
              userSn: model.userSn!,
              userNcnm: model.userNcnm,
              isDonation,
            })
          },
        },
      }
    }

    if (model.blockedAt === 'Y') {
      return {
        title: t('blockMessage.messages.blockedByOtherUserPrimary', {
          userNcnm: model.userNcnm,
        }),
        message: '',
        action: null,
      }
    }

    return null
  })
  const blockContentsMessage = computed(() => {
    const model = blockStatusModel.value

    if (!model.userSn) return null

    if (model.blockingAt === 'Y') {
      if (model.blockedAt === 'Y')
        return {
          title: t('blockMessage.messages.blockedByOtherUserPrimary', {
            userNcnm: model?.userNcnm || '',
          }),
          message: t('blockMessage.messages.blockedByOtherUserSecondary', {
            userNcnm: model?.userNcnm || '',
          }),
          action: null,
        }
      else
        return {
          title: t('blockMessage.messages.showContentsPrimary'),
          message: t('blockMessage.messages.showContentsSecondary'),
          action: {
            label: t('blockMessage.actions.showContents'),
            theme: 'primary-blue',
            onClick: () => {
              onShowContents?.()
            },
          },
        }
    }

    if (model.blockedAt === 'Y')
      return {
        title: t('blockMessage.messages.blockedByOtherUserPrimary', {
          userNcnm: model.userNcnm,
        }),
        message: t('blockMessage.messages.blockedByOtherUserSecondary', {
          userNcnm: model.userNcnm,
        }),
        action: null,
      }

    return null
  })

  return {
    blockStatusModel,
    blockStatus,
    blockHeaderMessage,
    blockContentsMessage,
  }
}
