<script setup lang="ts">
import type { NovaRadioEmits, NovaRadioProps } from './NovaRadio.types'

const emit = defineEmits<NovaRadioEmits>()
const props = withDefaults(defineProps<NovaRadioProps>(), {
  label: '',
  direction: 'row',
  theme: 'radio',
})

const iconSize = computed(() => {
  switch (props.theme) {
    case 'lg-radio':
    case 'xl-radio':
      return 34
    case 'radio':
    default:
      return 24
  }
})

const handleOnChangeRadio = () => {
  emit('onChangeRadio', props.value)
}
</script>

<template>
  <button
    :class="[
      'radio-box',
      `dir-${direction}`,
      `theme-${theme}`,
      { on: isCheck },
    ]"
    @click="handleOnChangeRadio"
  >
    <NovaIcon
      class="radio-icon"
      :icon="{
        type: 'outline',
        name: isCheck ? 'radio-check' : 'radio-uncheck',
      }"
      :size="iconSize"
    />

    <span class="label">{{ label }}</span>
  </button>
</template>

<style lang="scss" scoped>
.radio-box {
  display: flex;
  //align-items: center;
  transition: all 0.14s ease-in-out;

  &.dir-row {
    flex-direction: row;
  }
  &.dir-column {
    flex-direction: column;
  }
  &.dir-row-reverse {
    flex-direction: row-reverse;
  }
  &.dir-column-reverse {
    flex-direction: column-reverse;
  }

  &.theme {
    &-xl-radio {
      @include text-style($text-custom-22-bold);
    }

    &-lg-radio > .label {
      @include text-style($text-custom-22-regular);
    }

    &-button {
      align-items: center;
      justify-content: center;
      min-height: 60px;
      border-radius: 6px;
      border: 1px solid $color-de;

      &.on {
        border-color: $color-primary-blue;

        > .label {
          color: $color-primary-blue;
        }
      }

      @media (hover: hover) {
        &:hover {
          border-color: $color-primary-blue;

          > .label {
            color: $color-primary-blue;
          }
        }
      }

      > .radio-icon {
        display: none;
      }

      > .label {
        margin: 0;
        text-align: center;
        color: $color-99;
      }
    }
  }

  > .label {
    text-align: left;
    margin-left: 4px;
    line-height: 24px;
    transition: color 0.14s ease-in-out;
  }
}
</style>
