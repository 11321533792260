import { type Route, ROUTES } from '@configs'

/**
 * 주어진 라우트 이름과 연관된 상수 값을 검색합니다.
 *
 * @param {string} routeName - 라우트의 이름
 * @returns {any} - 라우트 이름과 연관된 상수 값, 또는 찾을 수 없는 경우 null을 반환
 */
export const useGetRouteConst = (routeName: string): Route | null => {
  const _routeName = useGetRouteName(routeName)
  const key = (Object.keys(ROUTES) as Array<keyof typeof ROUTES>).find(
    (key) => ROUTES[key].name === _routeName
  )

  return key ? ROUTES[key] : null
}
