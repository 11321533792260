export const auction = {
  topBanner: {
    text: '※ 상단의 배너를 클릭하시면 폰비딩 신청하기로 이동합니다.',
    alt: '사치갤러리 전시 작품 NFT 비딩 일정 2023. 10. 26. ~ 11. 2.',
  },
  displayCount: '조회수',
  auctionError: '작품 목록을 가져올 수 없습니다. 잠시 후 다시 시도해 주세요.',
  auctionEmpty: '작품 목록이 없습니다.',
  auctionDetailError: '작품을 조회 할 수 없습니다. 잠시 후 다시 시도해 주세요.',
  auctionDetainNavigationBack: '목록 보기',
}
