import { commonError } from './commonError'

/**
 * KYC 인증 관련
 */
export const kyc = {
  // GNB 사용자 패널 KYC 인증 섹션
  userKycInfo: {
    certified: 'KYC 인증',
    certifiedAction: {
      yet: '지금 인증하기',
      yetSimple: '인증하기',
      done: '인증 완료',
    },
  },
  // kyc 상태 조회
  checkKycStatus: {
    error: {
      4003: '확인 할 수 없는 사용자입니다.', // 토큰 정보와 일치하지 않는 공통 일련번호 입니다.
      default: commonError.network.message,
    },
  },
  // certifiedKyc
  unAuthKyc: {
    title: '인증하기',
    cs: 'KYC가 완료되지 않는다면 {cs}으로 문의 바랍니다.',

    // NEW
    tryYet: {
      primaryCopy: {
        service:
          '<strong class="text-color-primary-orange">커뮤니티 서비스</strong>를 이용 하시려면<br /><strong class="text-color-primary-blue">KYC 인증</strong>이 필요해요.',
        reward:
          '<strong class="text-color-primary-orange">커뮤니티 활동 리워드</strong>를 사용하시려면<br /><strong class="text-color-primary-blue">KYC 인증</strong>이 필요해요.',
        donate:
          '<strong class="text-color-primary-orange">후원</strong> 하시려면<br /><strong class="text-color-primary-blue">KYC 인증</strong>이 필요해요.',
      },
      secondaryCopy:
        '<p class="text-color-primary-blue mg-b-1u">KYC 인증이란?</p><p>Know Your Client의 줄임말로<br />금융기관에서 고객의 신원을 확인하는 본인인증 방법이에요.</p>',
      howToVerifyKyc: 'KYC 인증은 어떻게 하나요?',
      verifyKycProcess: {
        readyId: '신분증을 준비해주세요',
        faceCapture: '신원 확인을 위해 얼굴 촬영이 필요해요',
        processingTime: '인증 과정은 10분 정도가 소요돼요',
      },
      verifyAfter: 'KYC 인증이 완료되면 회원 정보가 업데이트돼요!',
      actions: {
        negative: '취소',
        positive: '인증하기',
      },
    },

    // 승인거절
    reject: {
      primaryCopy:
        '<strong class="text-color-primary-blue">KYC 인증</strong>을 다시 시도해 주세요.',
      secondaryCopy: {
        service:
          '<strong class="text-color-primary-blue">인증 절차가 완료</strong>되지 않으면<br /><strong class="text-color-primary-orange">서비스</strong>를 이용할 수 없어요.',
        reward:
          '<strong class="text-color-primary-blue">인증 절차가 완료</strong>되지 않으면<br /><strong class="text-color-primary-orange">커뮤니티 활동 리워드</strong>를 확인할 수 없어요.',
        donate:
          '<strong class="text-color-primary-blue">인증 절차가 완료</strong>되지 않으면<br /><strong class="text-color-primary-orange">후원</strong>할 수 없어요.',
      },
      rejectReason: 'KYC 인증 거부 사유',
      loadRejectReason: '거부사유 불러오기',
      actions: {
        negative: '취소',
        positive: '재인증하기',
      },
    },

    // 중복
    duplicate: {
      primaryCopy: '중복된 KYC 인증입니다.',
      secondaryCopy:
        '사용자의 다른 계정으로 KYC 인증이 완료되어 KYC 인증을 완료할 수 없습니다.',
      guide: '※ 로그아웃 후 이메일 찾기에서 기존 아이디를 찾을 수 있습니다.',
      confirm: '확인',
    },

    // 진행중
    pending: {
      primaryCopy:
        '<strong class="text-color-primary-blue">KYC 인증 절차</strong>가 진행중이에요.',
      secondaryCopy: {
        service:
          '<strong class="text-color-primary-blue">인증 절차가 완료</strong>되면<br /><strong class="text-color-primary-orange">서비스</strong>를 이용할 수 있어요!',
        reward:
          '<strong class="text-color-primary-blue">인증 절차가 완료</strong>되면<br /><strong class="text-color-primary-orange">커뮤니티 활동 리워드</strong>를 확인할 수 있어요!',
        donate:
          '<strong class="text-color-primary-blue">인증 절차가 완료</strong>되면<br /><strong class="text-color-primary-orange">후원</strong>할 수 있어요!',
      },
      guide: 'KYC 인증이 완료되면 회원 정보가 업데이트돼요!',
      confirm: '확인',
    },
  },
}
