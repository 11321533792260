import { SendbirdExceptionStatus } from './types'

/**
 * Sendbird exception
 * @param message
 * @param cause
 */
export class SendbirdException extends Error {
  name: 'SENDBIRD_EXCEPTION'
  message: string
  cause: {
    status: SendbirdExceptionStatus
    message: string
    data?: any
  }

  constructor({
    message,
    cause,
  }: {
    message: string
    cause: {
      status: SendbirdExceptionStatus
      data?: any
    }
  }) {
    super()
    this.name = 'SENDBIRD_EXCEPTION'
    this.message = message
    this.cause = {
      ...cause,
      message,
    }
  }
}
