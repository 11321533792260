<script setup lang="ts">
import type { NovaModalBeforeRequestNotificationPermissionEmits } from './NovaModalBeforeRequestNotificationPermission.types'
defineOptions({
  inheritAttrs: false,
})
defineEmits<NovaModalBeforeRequestNotificationPermissionEmits>()

const config = useRuntimeConfig()
</script>

<template>
  <vue-final-modal
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    :click-to-close="false"
  >
    <ClientOnly>
      <div class="modal-body">
        <div class="icon-wrap">
          <NovaImageContainer
            :image-url="`${config.public.APP_URL}/favicon-on-light/apple-icon-180x180.png`"
            :ratio="'1:1'"
          />
        </div>

        <div class="message-wrap">
          <h4 class="title"><slot name="title" /></h4>
          <p class="message"><slot name="message" /></p>
        </div>
      </div>
    </ClientOnly>
    <div class="modal-actions">
      <button
        v-for="(item, index) in $attrs.btns || []"
        :key="index"
        type="button"
        :class="['action', { positive: item.type === 'positive' }]"
        @click="item.onClick"
      >
        <span class="action-label">{{ item.label }}</span>
      </button>
    </div>
  </vue-final-modal>
</template>

<style lang="scss" scoped>
::v-deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep(.modal-content) {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 390px;
  box-shadow: 5px 5px 10px hex-to-rgba($color-bg-custom-4, 0.05);
  border-radius: 20px;
  background: $color-white;
}

.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 30px;

  .icon-wrap {
    display: flex;
    width: 78px;
    height: 78px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 50%;
    background-color: $color-bg-custom-2;
    padding: 10px;
  }

  .message-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    .title {
      text-align: center;
      @include text-style($text-heading1-bold);
    }

    .message {
      text-align: center;
      @include text-style($text-body-14-medium);
    }
  }
}

.modal-actions {
  display: flex;
  flex-direction: column;

  .action {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 48px;
    border-top: 1px solid $color-bg-custom-3;

    &.positive {
      > .action-label {
        @include text-style($text-display-bold);
        color: $color-primary-blue;
      }
    }

    > .action-label {
      @include text-style($text-display-medium);
    }
  }
}
</style>
