import * as cheerio from 'cheerio'
import { POST_HTML_VIDEO_IFRAME_CLASS } from '@configs'

interface UseHtmlContentsIframeToOGParams {
  htmlStr: string // iframe 태그가 포함된 HTML 문자열
  replaceBlockClassNames?: string[] // OG UI 로 변경하면 안되는 iframe 의 클래스
}

/**
 * HTML 문자열 내의 iframe 요소를 Open Graph 미리보기 요소로 교체
 *
 * @param {object} options - 옵션 객체
 * @param {string} options.htmlStr - 처리할 HTML 문자열
 * @param {Array<string>} [options.replaceBlockClassNames] - Open Graph 미리보기 요소로 교체되어야 하는 iframe 요소의 클래스 이름
 * 제공되지 않은 경우, 기본 클래스 이름 [POST_HTML_VIDEO_IFRAME_CLASS]이 사용
 * @returns {Promise<string>} 처리된 HTML 문자열로 해결되는 프로미스
 */
export const useHtmlContentsIframeToOG = async ({
  htmlStr,
  replaceBlockClassNames = [POST_HTML_VIDEO_IFRAME_CLASS],
}: UseHtmlContentsIframeToOGParams): Promise<string> => {
  const dom = cheerio.load(htmlStr)
  const iframes = dom('iframe')
  const promises: Array<Promise<void>> = []

  iframes.each((_idx, iframe) => {
    const iframeEl = dom(iframe)
    const iframeSrc = iframeEl.attr('src')
    const hasReplaceBlockClass = !!replaceBlockClassNames?.find((name) =>
      iframeEl.hasClass(name)
    )

    if (!hasReplaceBlockClass && iframeSrc) {
      const replace = async () => {
        const ogPreview = await useCreateOpenGraph(iframeSrc, true)
        const ogPreviewEl = dom(ogPreview)
        iframeEl.replaceWith(ogPreviewEl)
      }
      promises.push(replace())
    }
  })

  await Promise.all(promises)

  return dom.html()
}
