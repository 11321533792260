import apiPath from '@store/apiPath'

export default {
  /**
   * 펀드 이달의 프로젝트 : 배너 조회
   * @method GET
   */
  FETCH_FUND_BANNER: `${apiPath.FUND}/v1/banner`,

  /**
   * 펀드 이달의 프로젝트 : 프로젝트 조회
   * @method GET
   */
  FETCH_FUND_SURVEY: `${apiPath.FUND}/v1/project`,

  /**
   * 펀드 이달의 프로젝트 : 프로젝트 상세 조회
   * @method GET
   */
  FETCH_FUND_SURVEY_DETAIL: `${apiPath.FUND}/v1/project/:crfdSurveyPrjctSn`,

  /**
   * 펀드 이달의 프로젝트 투표
   * @method POST
   */
  REQ_FUND_PROJECT_VOTE: `${apiPath.FUND}/v1/project/vote`,

  /**
   * 펀딩 히스토리 목록 조회
   * @method GET
   */
  FETCH_FUND_HISTORIES: `${apiPath.FUND}/v1/history`,
}
