import { initializeApp } from '@firebase/app'
import { getAnalytics } from '@firebase/analytics'
import { getMessaging, onMessage } from '@firebase/messaging'
import { getFirestore } from '@firebase/firestore'
import {
  getFcmAppToken,
  getFcmWebToken,
  requestNotificationPermission,
  sendFcmTokenToServer,
  useNotificationPermissionModals,
} from './utils'
import { FirebaseException } from './exception'
import { type FcmPayload, FirebaseExceptionStatus } from './types'
import { fcmCallHandler, FcmPayloadMax } from './fcmCallHandler'
// import { bridgeToApp } from '~/bridge/bridges'

export * from './exception'
export * from './fcmCallHandler'
export * from './fcmActions'
export * from './types'

/**
 * Firebase initialize
 */
export const initFirebase = async () => {
  const config = useRuntimeConfig()
  const { locale } = useNuxtApp().$i18n
  const appStore = useAppStore()
  const { beforeRequestPermission } = useNotificationPermissionModals()
  const fcmAppToken = ref('')
  const fcmWebToken = ref('')

  // STEP 1. check access system notification
  if (!appStore.isApp && !Notification) {
    console.error(
      new FirebaseException({
        message: 'system notification access unavailable',
        cause: {
          status: FirebaseExceptionStatus.NOTIFICATION_IS_UNAVAILABLE,
          data: Notification,
        },
      })
    )
    return
  }

  // STEP 2. app mode process
  if (appStore.isApp) {
    try {
      // STEP 3-1-1. get fcm app token
      const { fcmToken, langCode } = await getFcmAppToken()
      fcmAppToken.value = fcmToken
      console.log('fcmAppToken:', fcmAppToken.value)

      // STEP 3-1-2. send fcm token to server
      await sendFcmTokenToServer({
        fcmAppToken: fcmAppToken.value,
        langCode,
      })

      // TODO: fcmToken 모델 업데이트 할것
    } catch (err) {
      console.error(err)
    }

    // try {
    //   // STEP 3-1-1. check notification permission
    //   const { data } = await bridgeToApp.checkPermission({
    //     permission: 'notification',
    //   })
    //
    //   const onFcmTokenProcess = async () => {
    //     // STEP 3-1-1. get fcm app token
    //     fcmAppToken.value = await getFcmAppToken()
    //     console.log('fcmAppToken:', fcmAppToken.value)
    //
    //     // STEP 3-1-2. send fcm token to server
    //     // fcm app, web equal
    //     const result = await sendFcmTokenToServer({
    //       fcmAppToken: fcmAppToken.value,
    //       fcmWebToken: '',
    //     })
    //
    //     // STEP 3-1-3. save fcm token to cookie
    //     setCookie(cookieNames.FCM_TOKEN_STATUS, result, 30, 'days')
    //   }
    //
    //   switch (data.status) {
    //     case 'granted':
    //     case 'limited':
    //       await onFcmTokenProcess()
    //       break
    //     case 'denied':
    //     case 'blocked':
    //       throw new FirebaseException({
    //         message: `app notification permission is ${data.status}`,
    //         cause: {
    //           status: FirebaseExceptionStatus.PERMISSION_IS_DENIED,
    //         },
    //       })
    //     case 'unavailable':
    //     default:
    //       await bridgeToApp
    //         .requestPermission({ permission: 'notification' })
    //         .then(async ({ data }) => {
    //           if (data.status === 'on') {
    //             await onFcmTokenProcess()
    //           } else {
    //             throw new FirebaseException({
    //               message: 'app notification access unavailable',
    //               cause: {
    //                 status: FirebaseExceptionStatus.NOTIFICATION_IS_UNAVAILABLE,
    //               },
    //             })
    //           }
    //         })
    //   }
    // } catch (err) {
    //   console.error(err)
    // }

    return
  }

  // STEP 3. web mode process
  const app = initializeApp(config.public.FIREBASE_CONFIG)
  const db = getFirestore(app)
  const messaging = getMessaging(app)
  const analytics = getAnalytics(app)
  const notificationPermission = computed(() => {
    if (!('Notification' in window)) {
      return 'unavailable'
    }

    return Notification.permission
  })

  try {
    const onFcmTokenProcess = async () => {
      // STEP 3-2-1. get fcm app token
      fcmWebToken.value = await getFcmWebToken(messaging)
      console.log('fcmWebToken:', fcmWebToken.value)

      // STEP 3-2-2. send fcm token to server
      await sendFcmTokenToServer({
        fcmWebToken: fcmWebToken.value,
        langCode: locale.value.toUpperCase(),
      })

      // TODO: fcmToken 모델 업데이트 할것
    }
    switch (notificationPermission.value) {
      // Notification permission 허용
      case 'granted':
        await onFcmTokenProcess()
        break
      // Notification permission 설정 이력이 없음 > 허용 메세지 출력
      case 'default':
        await beforeRequestPermission(async () => {
          const permission = await requestNotificationPermission()
          if (permission === 'granted') {
            await onFcmTokenProcess()
          }
        })
        break
      // Notification permission 거부
      case 'denied':
        throw new FirebaseException({
          message: 'notification permission is denied',
          cause: {
            status: FirebaseExceptionStatus.PERMISSION_IS_DENIED,
          },
        })
      // Notification permission 사용 할 수 없는 디바이스
      case 'unavailable':
      default:
        throw new FirebaseException({
          message: 'notification permission is not granted',
          cause: {
            status: FirebaseExceptionStatus.NOTIFICATION_IS_UNAVAILABLE,
          },
        })
    }
  } catch (err) {
    console.error(err)
  }

  // STEP 4. on messaging listener
  onMessage(messaging, (payload) => {
    console.log('FCM onMessage', payload)
    fcmCallHandler(new FcmPayloadMax(payload as unknown as FcmPayload))
  })

  // STEP 5. fcm notification turn off
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    console.log('service workers', registrations)
  })

  return {
    app,
    analytics,
    db,
    messaging,
  }
}
