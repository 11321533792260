<script setup lang="ts">
import type { NovaBoxPostCategoryProps } from './NovaBoxPostCategory.types'
import type { CategoryListItem } from '@store'

const props = defineProps<NovaBoxPostCategoryProps>()

const postStore = usePostStore()
const category = computed(() =>
  findCategory(postStore.categories, props.cmtyNttCtgrySn || 1)
)

const findCategory = (
  categories: CategoryListItem[],
  cmtyNttCtgrySn: CategoryListItem['cmtyNttCtgrySn']
): { depthOne: CategoryListItem; depthTwo?: CategoryListItem } | undefined => {
  for (const depthOne of categories) {
    if (depthOne.cmtyNttCtgrySn === cmtyNttCtgrySn) {
      return { depthOne, depthTwo: undefined }
    }
    if (depthOne.lowerCategoryList) {
      const depthTwo = depthOne.lowerCategoryList.find(
        (item) => item.cmtyNttCtgrySn === cmtyNttCtgrySn
      )
      if (depthTwo) {
        return { depthOne, depthTwo }
      }
    }
  }
  return undefined
}
</script>

<template>
  <ul class="post-category">
    <li class="category-item">
      <NovaIcon
        :icon="{ type: 'outline', name: 'grid-line' }"
        :size="12"
        class="icon root"
      />

      <NovaIcon
        :icon="{ type: 'outline', name: 'chev-compact-right' }"
        :size="6"
        class="icon divider"
      />
    </li>

    <li
      :class="[
        'category-item',
        { 'is-last': typeof category?.depthTwo === 'undefined' },
      ]"
    >
      <span class="label">{{ category?.depthOne.nttCtgryNm }}</span>

      <NovaIcon
        :icon="{ type: 'outline', name: 'chev-compact-right' }"
        :size="6"
        class="icon divider"
      />
    </li>

    <li v-if="category?.depthTwo" class="category-item is-last">
      <span class="label">{{ category?.depthTwo.nttCtgryNm }}</span>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
.post-category {
  display: flex;
  gap: 10px;

  .category-item {
    display: flex;
    align-items: center;
    gap: 10px;
    color: $color-text-2;

    &.is-last {
      > .label {
        font-weight: 500;
      }

      > .icon.divider {
        display: none;
      }
    }

    > .label {
      @include text-style($text-body-12-regular);
    }

    > .icon.divider {
      color: $color-text-3;
    }
  }
}
</style>
