<script setup lang="ts">
const appStore = useAppStore()
const layoutStore = useLayoutStore()
const { isApp } = storeToRefs(appStore)
const { layers } = storeToRefs(layoutStore)

watch(
  () => layers.value,
  (cur) => {
    if (!(window && document.body)) return

    const responsive = useGetResponsive()

    if (responsive === 'mobile' && cur.length) {
      document.body.style.overflow = 'hidden'
      if (appStore.isApp && window.scrollY === 0) {
        window.scrollTo({ top: 1 })
      }
    } else {
      document.body.style.overflow = ''
    }
  },
  { deep: true }
)

onBeforeMount(() => {
  if (!isApp.value) window.addEventListener('resize', bodyControl)
})

onBeforeUnmount(() => {
  if (!isApp.value) window.removeEventListener('resize', bodyControl)
})

const bodyControl = () => {
  const responsive = useGetResponsive()
  if (responsive === 'mobile' && layers.value.length) {
    document.body.style.overflow = 'hidden'
  } else {
    document.body.style.overflow = ''
  }
}
</script>

<template>
  <slot />
</template>

<style scoped lang="scss"></style>
