import W3cfService from './service'
import * as W3cfType from './type'
import { initW3cfState } from './init'

export * from './init'
export * from './type'
export const useW3cfStore = defineStore('w3cf', () => {
  const w3cfsByAll = ref(initW3cfState.w3cfsByAll)
  const w3cfsByPublic = ref(initW3cfState.w3cfsByPublic)
  const w3cfsByStudent = ref(initW3cfState.w3cfsByStudent)
  const w3cf = ref(initW3cfState.w3cf)

  /**
   * w3cf 목록 조회
   * @param payload
   */
  const fetchW3cfs = async (payload: W3cfType.W3cfsPayload) => {
    const { data } = await W3cfService.fetchW3cfs(payload)

    switch (payload.w3cfSectCode) {
      case 'G':
        w3cfsByPublic.value = data
        break
      case 'S':
        w3cfsByStudent.value = data
        break
      case '':
        w3cfsByAll.value = data
        break
      default:
        throw new Error('fetch w3cfs sect code is not match')
    }
  }

  /**
   * w3cf 상세 조회
   * @param payload
   */
  const fetchW3cf = async (payload: W3cfType.W3cfPayload) => {
    const { data } = await W3cfService.fetchW3cf(payload)
    w3cf.value = data
    return data
  }

  /**
   * w3cf 상세 클리어
   */
  const clearW3cf = () => {
    w3cf.value = null
  }

  /**
   * w3cf 좋아요
   */
  const reqW3cfLike = async (
    payload: W3cfType.W3cfLikePayload,
    updateRcCount: number
  ) => {
    await W3cfService.reqW3cfLike(payload)
    updateW3cf(payload.w3cfNttSn, {
      rcFlag: '1',
      rcCount: updateRcCount,
    })
  }

  /**
   * w3cf 좋아요 취소
   * @param payload
   * @param updateRcCount
   */
  const reqW3cfUnlike = async (
    payload: W3cfType.W3cfLikePayload,
    updateRcCount: number
  ) => {
    await W3cfService.reqW3cfUnlike(payload)
    updateW3cf(payload.w3cfNttSn, {
      rcFlag: '0',
      rcCount: updateRcCount,
    })
  }

  /**
   * w3cf 스테이트 업데이트
   */
  const updateW3cf = (
    w3cfNttSn: W3cfType.W3cf['w3cfNttSn'],
    updateModel: Omit<W3cfType.W3cf, keyof W3cfType.W3cf>
  ) => {
    // 1-1. 목록 업데이트: 전체
    const w3cfsTargetIdxByAll = w3cfsByAll.value.findIndex(
      (item) => item.w3cfNttSn === w3cfNttSn
    )

    if (w3cfsTargetIdxByAll !== -1) {
      w3cfsByAll.value[w3cfsTargetIdxByAll] = {
        ...w3cfsByAll.value[w3cfsTargetIdxByAll],
        ...updateModel,
      }
    }
    // 1-2. 목록 업데이트: 일반
    const w3cfsTargetIdxByPublic = w3cfsByPublic.value.findIndex(
      (item) => item.w3cfNttSn === w3cfNttSn
    )

    if (w3cfsTargetIdxByPublic !== -1) {
      w3cfsByPublic.value[w3cfsTargetIdxByPublic] = {
        ...w3cfsByPublic.value[w3cfsTargetIdxByPublic],
        ...updateModel,
      }
    }

    // 1-3. 목록 업데이트: 학생
    const w3cfsTargetIdxByStudent = w3cfsByStudent.value.findIndex(
      (item) => item.w3cfNttSn === w3cfNttSn
    )

    if (w3cfsTargetIdxByStudent !== -1) {
      w3cfsByStudent.value[w3cfsTargetIdxByStudent] = {
        ...w3cfsByStudent.value[w3cfsTargetIdxByStudent],
        ...updateModel,
      }
    }

    // 2. 상세 업데이트
    if (w3cf.value) {
      w3cf.value = {
        ...w3cf.value,
        ...updateModel,
      }
    }
  }

  return {
    w3cfsByAll,
    w3cfsByPublic,
    w3cfsByStudent,
    w3cf,
    fetchW3cfs,
    fetchW3cf,
    clearW3cf,
    reqW3cfLike,
    reqW3cfUnlike,
  }
})
