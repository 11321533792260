import 'context-filter-polyfill'
import html2canvas from 'html2canvas'
import { useCreateHtmlByString } from './useCreateHtmlByString'
import type { ConvertSnapshotTemplateParams } from './useSnapshot.types'

const convertSnapshotTemplate = (params: ConvertSnapshotTemplateParams) => {
  const appendContents = (contents: string) => {
    if (!contents) return
    templateContents.appendChild(useCreateHtmlByString(contents))
  }

  // 1. 전역 스타일 적용을 위한 템플릿 컨테이너 생성
  const templateContents = document.createElement('div')
  templateContents.classList.add('post-contents-snapshot')

  // 2. 각 콘텐츠를 템플릿 컨테이너에 주입
  Object.keys(params).forEach((key) => {
    switch (key) {
      case 'videoCon':
      case 'cardsCon':
        appendContents(params[key])
        break
      case 'editorCon':
        appendContents(
          `<div class='post-contents-by-editor'><div class='post-contents'>${params[key]}</div></div>`
        )
        break
      default:
        break
    }
  })

  return templateContents
}

export const useSnapshot = async (
  contents: ConvertSnapshotTemplateParams,
  size: {
    width: number
    height?: number
  },
  filter: CSSStyleDeclaration['filter'] | null = null
): Promise<File> => {
  if (!process.client) {
    console.error('useSnapshot is call only client side')
  }

  let canvasContainer: HTMLCanvasElement | null = null

  // 1. 콘텐츠 엘리먼트를 추출 및 캔버스를 위한 보이지 않는 컨테이너 생성
  const contentsContainer = document.createElement('div')
  contentsContainer.setAttribute(
    'style',
    `position: fixed; top: -9999999px; left: -999999px; width: ${
      size.width
    }px; height: ${size.height ? size.height + 'px' : 'auto'};`
  )

  // 2-1. 내부 템플릿 스타일 적용을 위한 컨테이너 생성
  const templateContents = convertSnapshotTemplate(contents)

  // 2-2. 콘텐츠 엘리먼트에 템플릿 컨테이너 주입
  contentsContainer.appendChild(templateContents)

  // 2-3. Dom 에 콘텐츠 엘리먼트 주입
  document.body.appendChild(contentsContainer)

  // 3. 높이를 지정하지 않고 콘텐츠 높이가 378보다 크면 378으로 고정하고 최소 높이를 200으로 고정
  if (!size.height) {
    size.height = contentsContainer.clientHeight > 378 ? 378 : 200
  }

  // 4. 스타일 주입 완료된 템플릿 콘텐츠를 캔버스에 주입
  await html2canvas(templateContents, {
    ...size,
    windowWidth: size.width,
    windowHeight: size.height,
    useCORS: true,
  }).then((canvas) => {
    // add filter
    if (filter) {
      const filterCanvas = document.createElement('canvas')
      const filterCtx = filterCanvas.getContext('2d')
      filterCanvas.style.filter = filter
      filterCanvas.width = canvas.width
      filterCanvas.height = canvas.height

      filterCtx!.filter = filterCanvas.style.filter
      filterCtx!.drawImage(canvas, 0, 0)

      canvasContainer = filterCanvas
      console.log('filter canvas:', filterCanvas.toDataURL())
    }

    // no filter
    else {
      canvasContainer = canvas
      console.log('no filter canvas:', canvas.toDataURL())
    }
  })

  // 5. 스타일 때문에 그렸던 콘텐츠 컨테이너 삭제
  contentsContainer.remove()

  // 6. 캔버스에서 Blob 으로 이미지를 추출하고 file 반환
  return new Promise<File>((resolve) => {
    canvasContainer!.toBlob((blob) => {
      const file = new File([blob!], 'snapshot.jpg', {
        type: 'image/jpeg',
        lastModified: Date.now(),
      })
      console.log('blobFile', file)

      resolve(file)
    }, 'image/jpeg')
  })
}

export default useSnapshot
