import type { CorporationInfoProperty } from '@components/NovaBoxCorporationInfo/NovaBoxCorporationInfo.types'

/**
 * 회사 정보
 */
export const corporationInfo = {
  title: 'Corporation business information',
  copyright: 'The Moon Labs Inc. © {year} All rights reserved.',
  properties: {
    name: 'Corporation',
    owner: 'CEO',
    address: 'Address',
    businessNumber: 'Business Registration No.',
    // corporationNumber: 'Corporation Registration No.',
    tel: 'Main No.',
    fax: 'FAX',
    email: 'Email',
  } as { [Property in CorporationInfoProperty]: string },
  infos: {
    name: 'The Moon Labs Inc.',
    owner: 'Sung-Uk, Moon',
    address:
      '3th floor, 9-11, Eonju-ro 147-gil, Gangnam-gu, Seoul, Republic of Korea.',
    businessNumber: '372-86-02722',
    // corporationNumber: '110111-8145107',
    tel: '+82-2-3469-7100',
    fax: '+82-2-3452-1802',
    email: "cs{'@'}ilikelm.com",
  } as { [Property in CorporationInfoProperty]: string },
}
