import type { AxiosInstance } from 'axios'
import { ErrorStatus } from '@configs/error'

export const setBlockAccessInterceptor = (instance: AxiosInstance) => {
  instance.interceptors.response.use(undefined, (error) => {
    if (process.server) return Promise.reject(error)

    const { $pinia } = useNuxtApp()

    if (
      error.response.status === ErrorStatus.BLOCK_ACCESS &&
      $pinia.state.value.user.blockAccessCount === 0
    ) {
      $pinia.state.value.user.blockAccessCount += 1

      return Promise.reject(error)
    }

    return Promise.reject(error)
  })
}
