<script setup lang="ts">
import type { RouteParams } from 'vue-router'
import type { PremiumTermDetail } from '@store'

defineOptions({
  inheritAttrs: false,
})

const { hide: modalHide } = useModal()
const show = ref(false)
const termDetail = ref<PremiumTermDetail>()

// 모달 오픈전 사전 작업 수행
const handleOnGetParams = (evt: RouteParams) => {
  termDetail.value = evt.value as unknown as PremiumTermDetail
}

// 팝업 닫기
const handleOnClose = async () => {
  await modalHide(modalsName.MODAL_TERM_DETAIL)
}

// 팝업 닫은 후 초기화
const handleOnClosed = () => {
  termDetail.value = undefined
}
</script>

<template>
  <VueFinalModal
    v-model="show"
    :name="modalsName.MODAL_TERM_DETAIL"
    :content-class="'modal-content'"
    classes="modal-container"
    @before-open="(evt: any) => handleOnGetParams(evt.ref.params)"
    @click-outside="handleOnClose"
    @closed="handleOnClosed"
  >
    <ClientOnly>
      <NovaBoxBase v-if="show" class="modal-box">
        <div class="modal-header">
          <h4 class="title">{{ termDetail?.stplatNm }}</h4>

          <NovaButtonIcon
            :icon="{ type: 'outline', name: 'close-extend' }"
            :theme="'transparent'"
            :size="20"
            class="btn-close"
            @click="handleOnClose"
          />
        </div>

        <div class="modal-body">
          <NovaLayoutScrollContainer>
            <NovaModalTerm :term-detail="termDetail?.stplatDetailCn || ''" />
          </NovaLayoutScrollContainer>
        </div>
      </NovaBoxBase>
    </ClientOnly>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
:deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.vfm--overlay) {
  background-color: hex-to-rgba($color-black, 0.65);
}

:deep(.modal-content) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: none;

  .modal-box {
    width: 100%;
    height: 100%;
    max-width: 420px;
    max-height: calc(100% - 80px);
    padding: 0;
    pointer-events: auto;
    overflow: hidden;

    .modal-header {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 56px;
      padding: 0 20px;
      color: $color-text-2;
      background-color: $color-bg-3;
      border-bottom: 1px solid $border-1;

      .title {
        @include text-style($text-display-bold);
      }
    }

    .modal-body {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: hidden;
    }

    @include mobile {
      border-radius: 0 !important;
      max-width: 100%;
      max-height: 100%;
    }
  }
}
</style>
