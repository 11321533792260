import apiPath from '@/store/apiPath'

export default {
  /**
   * 슈퍼문 참가 신청
   * @method POST
   */
  REQ_SUPER_MOON_APPLY: `${apiPath.SUPERMOON}/v1/application`,

  /**
   * 슈퍼문 참가 신청 로그 등록
   * @method POST
   */
  REQ_SUPER_MOON_APPLY_LOG: `${apiPath.SUPERMOON}/v1/log`,

  /**
   * 슈퍼문 참가 신청 현황 조회
   * @method GET
   */
  FETCH_SUPER_MOON_APPLY_STATUS: `${apiPath.SUPERMOON}/v1/application/status`,

  /**
   * 슈퍼문 참가 신청 약관 상세 조회
   * @method GET
   */
  FETCH_SUPER_MOON_TERM: `${apiPath.SUPERMOON}/v1/application/term`,

  /**
   * 슈퍼문 참가 신청 약관 목록 조회
   * @method GET
   */
  FETCH_SUPER_MOON_TERMS: `${apiPath.SUPERMOON}/v1/application/terms`,
}
