<script setup lang="ts">
import type { NovaModalRetireGuideEmits } from './NovaModalRetireGuide.types'
import {
  NovaModalCommon,
  NovaModalDialog,
  NovaModalRetireInquire,
} from '#components'

const emit = defineEmits<NovaModalRetireGuideEmits>()

const { gtEvent } = useGoogleTag()
const { t, locale, messages } = useI18n()
const userStore = useUserStore()
const router = useRouter()
const { show: modalShow, hide: modalHide, hideAll: modalHideAll } = useModal()
const state = reactive({
  isLoading: false,
  isError: false,
  isCheck: false,
  type: '',
})

const list = computed(() =>
  (messages.value[locale.value].retire as any).guide.type1.list.map(
    (_item: any, index: number) => t(`retire.guide.type1.list[${index}]`)
  )
)

const onLoadData = async () => {
  await userStore.fetchCheckRetireType()
  state.type = userStore.retireType === null ? '' : userStore.retireType
}

onMounted(() => {
  onLoadData()
})

const onChangePage = () => {
  emit('close')
  router.push({ path: '/' })
}
const onSubmitRetire = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '회원 탈퇴 버튼 클릭',
    eventLabel: t('retire.title'),
    eventSlot: '회원 탈퇴',
    eventI18nAddr: useKoreanTranslation('retire.title'),
    eventComponent: 'Button',
  })
  await userStore.submitRetireProcess()
  gtEvent('userAction', {
    eventCategory: '사용자',
    eventAction: '회원 탈퇴 완료',
    eventLabel: t('retire.title'),
    eventSlot: '회원 탈퇴',
    eventI18nAddr: useKoreanTranslation('retire.title'),
    eventComponent: 'Button',
  })
  await showDialogModal(t('retire.guide.type1.complete'))
}

const showDialogModal = (content: string) => {
  return modalShow({
    component: NovaModalDialog,
    bind: {
      name: modalsName.MODAL_DIALOG,
      btns: [
        {
          label: t('confirm'),
          theme: 'primary-blue-light',
          size: 32,
          onClick: async () => {
            userStore.signOut()
            gtEvent('clickEvent', {
              eventCategory: '클릭',
              eventAction: '회원 탈퇴 안내 모달 > 완료 버튼 클릭',
              eventLabel: t('retire.guide.type1.complete'),
              eventSlot: '회원 탈퇴',
              eventI18nAddr: useKoreanTranslation(
                'retire.guide.type1.complete'
              ),
              eventComponent: 'Button',
            })
            await modalHideAll()
          },
        },
      ],
    },
    slots: {
      content,
    },
  })
}

const openModalInquire = async () => {
  await modalShow({
    component: NovaModalCommon,
    bind: {
      name: modalsName.MODAL_MEMBERSHIP_OFF_QNA,
      showCloseBtn: true,
      mobileFull: true,
      width: 400,
    },
    on: {
      close: async () => {
        await modalHide(modalsName.MODAL_MEMBERSHIP_OFF_QNA)
      },
    },
    slots: {
      title: t('retire.guide.type2.modal.title'),
      noScrollContents: {
        component: NovaModalRetireInquire,
        on: {
          close: async () => {
            await modalHide(modalsName.MODAL_MEMBERSHIP_OFF_QNA)
          },
        },
      },
    },
  })
}
</script>

<template>
  <div class="retire-content">
    <NovaBoxEmptyMessage
      v-if="!state.isLoading && state.isError"
      :message="$t('retire.error')"
    />
    <div v-if="state.isLoading" class="loading-area">
      <span>{{ $t('retire.loading') }}</span>
      <NovaLoadingIndicator :size="80" :fill="false" :bg-bright="'light'" />
    </div>
    <div
      v-if="
        !state.isLoading &&
        !state.isError &&
        (state.type === 'withdrawal01' || state.type === 'withdrawal02')
      "
      class="content-area"
    >
      <NovaLayoutScrollContainer class="content-inner type1">
        <span class="sub-title">{{ $t('retire.guide.type1.subTitle') }}</span>
        <ul class="text-list">
          <li v-for="(item, index) in list" :key="index">{{ item }}</li>
        </ul>
        <span class="text">{{ $t('retire.guide.type1.text') }}</span>
        <span class="text warning">{{ $t('retire.guide.type1.warning') }}</span>
        <NovaCheckbox
          :is-check="state.isCheck"
          :label="$t('retire.guide.type1.agree')"
          class="check-box"
          @click="state.isCheck = !state.isCheck"
        />
      </NovaLayoutScrollContainer>

      <div class="action-box">
        <NovaButtonText
          :label="$t('retire.title')"
          :full-width="true"
          :theme="'primary-blue'"
          :disabled="!state.isCheck"
          class="btn-action"
          @click="onSubmitRetire"
        />
      </div>
    </div>
    <div
      v-if="!state.isLoading && !state.isError && state.type === 'withdrawal04'"
      class="content-area"
    >
      <NovaLayoutScrollContainer class="content-inner type2">
        <div class="content-box">
          <div class="text-box">
            <span class="text warning">{{
              $t('retire.guide.type2.content.text1')
            }}</span>
            <span
              v-dompurify-html="$t('retire.guide.type2.content.text2')"
              class="text warning"
            />
          </div>
          <div class="button-box">
            <NovaButtonText
              :label="$t('retire.guide.type2.button.inquire')"
              :theme="'primary-blue-ultra-light'"
              class="btn-write"
              @click="openModalInquire"
            />
          </div>
        </div>
      </NovaLayoutScrollContainer>

      <div class="action-box">
        <NovaButtonText
          :label="$t('retire.guide.type2.button.home')"
          :full-width="true"
          :theme="'primary-blue'"
          class="btn-action"
          @click="onChangePage"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.retire-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;
  .loading-area {
    color: $color-text-4;
  }
  .content-area {
    display: flex;
    height: 100%;
    flex-direction: column;
    max-height: 500px;
    overflow: hidden;
  }
  .content-inner {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    &.type2 {
      align-items: center;
      justify-content: center;
    }

    .sub-title {
      @include text-style($text-heading3-medium);
      color: $color-primary;
    }

    .text-list {
      padding-top: 16px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      li {
        display: flex;
        @include text-style($text-body-14-regular);
        color: $color-text-8;

        &:before {
          content: '\e95c';
          font-family: 'lmnova';
          color: $color-text-4;
          padding-top: 4px;
          padding-right: 6px;
        }
      }
    }
    .check-box {
      margin-top: 16px;
      @include text-style($text-heading3-medium);
      cursor: pointer;
    }

    .content-box {
      span {
        display: block;
      }
    }

    :deep(.text) {
      margin-top: 16px;
      @include text-style($text-body-14-regular);

      &.warning {
        color: $color-primary-red;
      }
      em {
        color: $color-primary;
      }
    }
    .button-box {
      margin-top: 16px;
      text-align: center;
    }
  }

  .action-box {
    flex-shrink: 0;
    width: 100%;
    padding-top: 16px;
  }

  @include mobile {
    .content-area {
      max-height: 100%;

      .content-inner {
        padding: 16px 20px 0;
      }
    }

    .action-box {
      padding: 16px 20px 24px;
    }
  }
}
</style>
