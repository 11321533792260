import { commonError } from './commonError'

/**
 * 사용자 계정 정보
 */
export const accountPanel = {
  settings: {
    phoneNumber: {
      title: 'Mobile phone number',
      guide: 'Please include hyphens (-) while entering.',
      placeholder: '010-0000-0000',
      action: 'Save',
      valid: {
        empty: 'Please enter your mobile phone number.',
      },
      messages: {
        error: {
          2006: 'Required values are missing.',
          4000: 'Communication with the integrated authentication server has been disconnected.',
          4002: commonError['4002'].message,
          4003: 'The token information and the common user serial number do not match.',
          unknown: 'An unknown error has occurred.',
        },
        success: "The user's phone number has been changed to {phoneNumber}.",
      },
    },
    bankAccount: {
      title: '{subscribeGrade} donation settlement bank',
      forms: {
        bank: {
          label: 'Bank',
          guide: 'It must be an account in your own name.',
          placeholder: 'Bank Selection',
          valid: 'Please select a bank.',
        },
        account: {
          label: 'Account number',
          guide: 'Please include hyphens (-) while entering.',
          placeholder: '000-0000-0000-00',
          valid: 'Please enter your account number.',
        },
        owner: {
          label: "Account holder's name",
          guide: '',
          placeholder: 'Name',
          valid: "Please enter the account holder's name.",
        },
      },
      messages: {
        empty: {
          fetchBanks:
            'No bank is found for "{keyword}" in the search. Please enter another bank name.',
        },
        error: {
          fetchBanks: 'Unable to get bank list.',
          2006: 'Required values are missing.',
          4010: 'The token information and the common user serial number do not match.',
          unknown: 'An unknown error has occurred.',
        },
        success:
          'The bank information for your {subscribeGrade} donation settlement has been successfully registered.',
      },
    },
  },
}
