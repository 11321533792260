<script setup lang="ts">
import { Tippy } from 'vue-tippy'
import type {
  CommentAndReplyStatus,
  PrivateAction,
  NovaBoxPostCommentReplayItemProps,
} from './NovaBoxPostCommentReplayItem.types'
import {
  type CommentAndReplyPrivateActionType,
  type CommentEditPayload,
  type EditorImage,
} from '@store'
import { HOUR } from '@configs'
import type { TipTapPushEditorContents } from '@components/NovaTipTap/NovaTipTap.types'

const props = withDefaults(defineProps<NovaBoxPostCommentReplayItemProps>(), {
  isResizingImages: false,
})

const config = useRuntimeConfig()
const { gtEvent } = useGoogleTag()
const { t, locale, messages } = useI18n()
const { humanize } = useDateFormat()
const { dayjs } = useDayjs()
const { userStore, showSignInDialog } = useMembershipProcess()
const { hide: hideModal } = useModal()
const postStore = usePostStore()
const isLoading = ref(false)
const editMode = ref(false)
const editedComment = ref('')
const fileUrlList = ref<EditorImage[]>([])
const commentStatus = computed(
  () =>
    Object.keys(messages.value[locale.value].commentStatus)
      .map((key) => ({
        key,
        ...statusMapping(key as CommentAndReplyPrivateActionType),
        message: t(`commentStatus.${key}`),
      }))
      .sort((a, b) => a.order - b.order)
      .filter((item) => item.status) as CommentAndReplyStatus[]
)
const privateActions = computed(
  () =>
    Object.keys(messages.value[locale.value].commentAndReplyPrivateAction)
      .map((key) => ({
        id: key,
        name: t(`commentAndReplyPrivateAction.${key}`),
      }))
      .filter((item) =>
        actionsFilterItem(item as PrivateAction)
      ) as PrivateAction[]
)

// lifecycle: mounted
onMounted(() => {
  init()
})

watch(
  () => props.source,
  () => {
    init()
  },
  { deep: true }
)
const init = () => {
  editedComment.value = props.source.nttAnswerCn
  fileUrlList.value = []
  fileUrlList.value = getImagesFromContents(props.source.nttAnswerCn)
}

const statusMapping = (key: CommentAndReplyPrivateActionType) => {
  switch (key) {
    case 'delete':
      return { status: props.source.deleteAt === 'Y', order: 1 }
    case 'hide':
      return { status: props.source.bkFlag === '1', order: 2 }
    case 'report':
      return {
        status:
          props.source.userSn !== userStore.user?.userSn
            ? props.source.reportableAt === 'N'
            : props.source.sanctnsAt === 'Y',
        order: 3,
      }
    case 'signOff':
      return { status: props.source.userSttusCode === 'T', order: 4 }
    default:
      throw new Error(`comment status ${key} is not match`)
  }
}

// 로그인 및 댓글 상태에 따라 노출되는 액션 아이템 필터
const actionsFilterItem = (action: PrivateAction): boolean => {
  const createAtTimestamp = new Date(
    dayjs.utc(props.source.nttAnswerRegistDt).format()
  ).getTime()
  const nowTimestamp = new Date(dayjs.utc().format()).getTime()
  const isAbleEdit = nowTimestamp - createAtTimestamp < HOUR

  // 1. 댓글 상태가 삭제일때 모든 액션 비노출
  if (commentStatus.value.find((item) => item.key === 'delete')) return false

  // 1. 댓글 현재 상태를 제외한 액션 노출
  if (commentStatus.value.find((item) => item.key === action.id)) return false

  // 2. 작성자와 사용자가 불일치: 허용가능 액션 > [숨기기, 신고하기]
  if (
    props.source.userSn !== userStore.user!.userSn &&
    (action.id === 'delete' || action.id === 'edit')
  )
    return false

  // 3. 작성자와 사용자가 일치: 허용가능 액션 > [삭제]
  if (
    props.source.userSn === userStore.user!.userSn &&
    (action.id === 'hide' || action.id === 'report')
  )
    return false

  // 4. 댓글 작성 후 한시간이 지났다면 수정 숨김
  if (
    props.source.userSn === userStore.user!.userSn &&
    action.id === 'edit' &&
    !isAbleEdit
  )
    return false
  // 5. 내가 댓글 신고 후 댓글 신고 버튼 숨김
  if (action.id === 'report' && props.source.reportableAt === 'N') return false

  // 6. 위 조건을 충족하지 않는 액션은 노출
  return true
}

// 액션 수행시 로딩 인디케이터 표시 여부
const handleOnActionLoading = (loading: boolean) => {
  isLoading.value = loading
}

// 수정 모드 진입
const handleOnEditMode = (edit: boolean) => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: edit ? '수정' : '수정 취소',
    eventLabel: '',
    eventSlot: '댓글',
    eventI18nAddr: '',
    eventComponent: 'Button',
    cmtyNttAnswerSn: props.source.cmtyNttAnswerSn,
  })
  if (edit) {
    gtEvent('replyAction', {
      eventCategory: '댓글',
      eventAction: '수정 하기',
      eventLabel: '',
      eventSlot: '댓글 수정',
      eventI18nAddr: '',
      eventComponent: 'Button',
      cmtyNttAnswerSn: props.source.cmtyNttAnswerSn,
    })
  }
  editMode.value = edit
}
const handleOnEditModePanel = (edit: boolean) => {
  editMode.value = edit
}
// 댓글 & 답글 수정
const reqEditComment = async (
  push: TipTapPushEditorContents,
  successProcess: () => void
) => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '답글 수정 클릭',
    eventLabel: '',
    eventSlot: '댓글 > 답글 수정',
    eventI18nAddr: '',
    eventComponent: 'Button',
    cmtyNttAnswerSn: props.source.cmtyNttAnswerSn,
  })
  // 1-1. 로그인하지 않았다면 로그인 요청
  if (!userStore.isSignIn) {
    await showSignInDialog()
    return
  }

  // 1-2. 입력할 내용이 없다면 토스트 메세지 노출하고 멈춤
  if (!push.data.length) {
    useToast(
      t('statusMessages.commentsEdit.empty', {
        type: t(!props.source.nttParntsAnswerSn ? 'comments' : 'replies'),
      })
    )
    return
  }

  // 2-1. 수정 페이로드 준비
  const payload: CommentEditPayload = {
    cmtyNttAnswerSn: props.source.cmtyNttAnswerSn,
    fileUrlList: getImagesFromContents(push.data, true),
    nttAnswerCn: push.data,
  }

  // 2-2. 댓글 & 답글 등록
  try {
    isLoading.value = true
    await postStore.editComment(payload)
    successProcess()
    editMode.value = false
    gtEvent('replyAction', {
      eventCategory: '댓글',
      eventAction: '댓글 수정',
      eventLabel: t('editComment'),
      eventSlot: '',
      eventI18nAddr: useKoreanTranslation('editComment'),
      eventComponent: 'Button',
      cmtyNttAnswerSn: props.source.cmtyNttAnswerSn,
    })
  } catch {
    useToast(
      t('statusMessages.commentsEdit.error', {
        type: t(!props.source.nttParntsAnswerSn ? 'comments' : 'replies'),
      })
    )
  } finally {
    isLoading.value = false
  }
}

const getImagesFromContents = (contents: string, submit = false) => {
  // 1. 콘텐츠에서 이미지 추출(외부 이미지 제외)
  const container = document.createElement('div')
  container.innerHTML = contents
  const imageEls = container.querySelectorAll('img')
  const nextImages: EditorImage[] = Array.prototype.slice
    .call(imageEls)
    .filter((img) => {
      const fileUrl = img.getAttribute('src')
      return (
        fileUrl.includes(config.public.TEMP_IMAGE_URL) ||
        fileUrl.includes(config.public.POST_RELEASED_IMAGE_URL)
      )
    })
    .map((img) => {
      const fileUrl = img.getAttribute('src')

      return {
        deleteAt:
          !fileUrl.includes(config.public.TEMP_IMAGE_URL) &&
          fileUrlList.value.findIndex((item) => item.fileUrl === fileUrl) === -1
            ? 'Y'
            : 'N',
        fileUrl,
        tempAt: fileUrl.includes(config.public.TEMP_IMAGE_URL) ? 'Y' : 'N',
      }
    })

  // 2. 수정 전 이미지와 비교하여 삭제된 이미지 추출
  let deletedImages: EditorImage[] = []
  deletedImages = fileUrlList.value.filter(
    (prevImg) =>
      nextImages.findIndex((nextImg) => nextImg.fileUrl === prevImg.fileUrl) ===
      -1
  )

  // 3. 수정 하기 위한 페이로드 생성시 변경 사항 없는 이미지는 제외
  if (submit) {
    return [...nextImages, ...deletedImages].filter(
      (img) => !(img.deleteAt === 'N' && img.tempAt === 'N')
    )
  }

  return [...nextImages, ...deletedImages]
}

// 사용자 홈 가기
const handleOnGoUserHomePage = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '댓글 > 섬네일',
    eventLabel: '썸네일',
    eventSlot: `${props.source.userSn} 홈으로 이동`,
    eventI18nAddr: '',
    eventComponent: 'Button',
    targetUserSn: props.source.userSn,
  })
  await useGoUserHome(props.source.userSn, async () => {
    await hideModal(modalsName.MODAL_POST_DETAIL)
  })
}

// methods: 에디터에서 로딩 인디케이터 노출
const handleOnTipTapLoading = (loading: boolean) => {
  isLoading.value = loading
}
</script>

<template>
  <div
    :class="[
      'comment-wrap',
      { 'is-reply': source.nttParntsAnswerSn },
      { 'has-replies': source.hasReplies },
      { 'last-reply': source.lastReply },
    ]"
  >
    <div class="line-wrap">
      <span class="line"></span>
      <span class="curve"></span>
    </div>

    <div class="comment-item">
      <NovaPortraitContainer
        :image-url="source.userProfl"
        :size="'sm'"
        class="user-portrait"
        @click="handleOnGoUserHomePage"
      />

      <!--      sn: {{source.cmtyNttAnswerSn}} | parentSn: {{source.nttParntsAnswerSn || 'null'}}-->
      <div
        :class="[
          'contents',
          { 'is-loading': isLoading },
          { 'has-status': commentStatus.length },
        ]"
      >
        <div class="comments-box">
          <div v-if="commentStatus.length" class="status-message">
            {{ commentStatus[0].message }}
          </div>

          <div v-if="isLoading" class="loading-wrap">
            <NovaLoadingIndicator :bg-bright="'light'" />
          </div>

          <div class="metas">
            <div class="info">
              <span
                v-if="!commentStatus.length"
                class="user-name"
                @click="handleOnGoUserHomePage"
              >
                {{ source.userNcnm }}
              </span>
              <span class="create-at">
                {{ humanize(source.nttAnswerRegistDt) }}
              </span>
            </div>

            <Tippy
              v-if="userStore.isSignIn && privateActions.length"
              :append-to="'parent'"
              :interactive="true"
              :theme="'popover'"
              :placement="'bottom-end'"
            >
              <NovaButtonIcon
                :icon="{ type: 'outline', name: 'more-horizontal' }"
                :size="20"
                class="comment-private-action"
              />

              <template #content>
                <NovaBoxPostCommentPrivateActionPanel
                  :source="source"
                  :actions="privateActions"
                  :comment-status="commentStatus"
                  :cmty-ntt-sn="cmtyNttSn"
                  :answer-count="answerCount"
                  @on-loading="handleOnActionLoading"
                  @on-edit-mode="handleOnEditModePanel"
                />
              </template>
            </Tippy>
          </div>

          <div class="comment">
            <NovaMention
              v-if="source.mentnNickname && !commentStatus.length"
              :user-name="source.mentnNickname"
              class="mention"
            />

            <NovaCommentContents
              v-if="!editMode"
              :view-type="viewType"
              :contents="commentStatus.length ? '' : source.nttAnswerCn"
              :is-resizing-images="source.isUpdateBefore"
            />

            <NovaTipTap
              v-if="editMode && !commentStatus.length"
              :model-value="editedComment"
              :placeholder="$t('writeComment')"
              :mode="source.nttParntsAnswerSn ? 'editComment' : 'editReply'"
              :is-loading="isLoading"
              class="comment-add-editor"
              @on-loading="handleOnTipTapLoading"
              @on-edit-mode="handleOnEditMode"
              @push-editor-contents="reqEditComment"
            />
          </div>
        </div>

        <div class="comments-actions">
          <NovaBoxPostCommentReplayWithMetas
            :source="source"
            :force-hide-add-comment="!!commentStatus.length"
            :view-type="viewType"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.comment-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &.is-reply {
    padding-left: 50px;

    .line-wrap {
      display: flex;
    }
  }

  &.has-replies {
    .line-wrap {
      display: flex;
    }
  }

  &.last-reply {
    .line-wrap {
      display: flex;

      > .line {
        display: none;
      }
      > .curve {
        display: block;
      }
    }
  }

  .line-wrap {
    display: none;
    position: absolute;
    flex-direction: column;
    top: 0;
    left: 20px;
    width: 30px;
    height: calc(100% + $comment-and-reply-gap);

    > .line {
      flex-grow: 1;
      border-left: 1px solid $color-text-5;
    }

    > .curve {
      flex-shrink: 1;
      display: none;
      width: 100%;
      height: 30px;
      margin-top: -10px;
      border-left: 1px solid $color-text-5;
      border-bottom: 1px solid $color-text-5;
      border-bottom-left-radius: 15px;
    }
  }

  .comment-item {
    display: flex;
    align-items: flex-start;
    gap: 10px;

    .user-portrait {
      flex-shrink: 0;
    }

    > .contents {
      position: relative;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 10px;

      &.is-loading {
        .comments-box > .metas,
        .comments-box > .comment,
        .comments-actions {
          opacity: 0.4;
          pointer-events: none;
        }
      }

      &.has-status {
        .comments-box {
          border-color: hex-to-rgba($color-text-5, 0.65);
        }
        .comments-actions {
          opacity: 0.4;
          pointer-events: none;
        }
      }

      .loading-wrap {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
      }

      .comments-box {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 15px;
        border: 1px solid $color-text-5;
        border-radius: 16px;

        .status-message {
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          @include text-style($text-display-bold);
          text-align: center;

          border-radius: 16px;
          background-color: hex-to-rgba($color-bg-3, 0.65);
        }

        > .metas {
          display: flex;
          align-items: center;
          justify-content: space-between;
          @include transition(opacity 0.2s ease-in-out);

          > .info {
            display: flex;
            align-items: center;
            color: $color-text-2;

            .user-name {
              cursor: pointer;
              @include text-style($text-body-14-bold);
              word-break: break-all;
              &:after {
                content: '•';
              }
            }

            .create-at {
              @include text-style($text-body-12-regular);
              letter-spacing: 0;
              line-height: 22px;
            }
          }

          .comment-private-action {
            color: $color-text-3;
          }
        }

        > .comment {
          @include transition(opacity 0.2s ease-in-out);

          > .mention {
            margin-right: 10px;
          }
        }
      }

      .comments-actions {
        @include transition(opacity 0.2s ease-in-out);
      }
    }
  }
}
</style>
