import apiPath from '@/store/apiPath'

export default {
  /**
   * FCM 토큰 등록
   * @method POST
   */
  SEND_FCM_TOKEN_TO_SERVER: `${apiPath.FIREBASE}/v1/token`,

  /**
   * 마케팅 동의 여부 등록
   * @method POST
   */
  SAVE_MARKETING_AGREE: `${apiPath.FIREBASE}/v1/token/marketing-agree`,
}
