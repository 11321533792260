<script setup lang="ts">
import { AxiosError } from 'axios'
import type {
  NovaBoxPostCommentAddEmits,
  NovaBoxPostCommentAddProps,
} from './NovaBoxPostCommentAdd.types'
import type { CommentAddPayload } from '@store'
import type { TipTapPushEditorContents } from '@components/NovaTipTap/NovaTipTap.types'

const emit = defineEmits<NovaBoxPostCommentAddEmits>()
const props = withDefaults(defineProps<NovaBoxPostCommentAddProps>(), {
  parentComment: null,
})

const { gtEvent } = useGoogleTag()
const { t } = useI18n()
const { showSignInDialog } = useMembershipProcess()
const { hide: hideModal } = useModal()
const userStore = useUserStore()
const postStore = usePostStore()
const comment = ref('')
const isLoading = ref(false)

const reqCommentAdd = async (
  push: TipTapPushEditorContents,
  successProcess: () => void
) => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '댓글 달기 버튼 클릭',
    eventLabel: t('doComment'),
    eventSlot: '',
    eventI18nAddr: useKoreanTranslation('doComment'),
    eventComponent: 'Button',
  })
  // 1-1. 로그인하지 않았다면 로그인 요청
  if (!userStore.isSignIn) {
    await showSignInDialog()
    return
  }

  // 1-2. 입력할 내용이 없다면 토스트 메세지 노출하고 멈춤
  if (!push.data.length) {
    useToast(
      t('statusMessages.commentsAdd.empty', {
        type: t(!props.parentComment ? 'comments' : 'replies'),
      })
    )
    return
  }

  // 2-1. 댓글 & 답글 등록할 조건 생성
  const calcPayload = () => {
    const payload: CommentAddPayload = {
      cmtyNttSn: postStore.post!.cmtyNttSn,
      nttAnswerCn: push.data,
    }

    // 답글일 경우 조건 추가 & 답글에 대한 답글일 경우에만 멘션 정보 추가
    if (props.parentComment) {
      payload.nttParntsAnswerSn = props.parentComment.cmtyNttAnswerSn

      if (props.parentComment?.replyreAt === 'Y') {
        payload.nttParntsAnswerSn = props.parentComment.nttParntsAnswerSn!
        payload.mentnUserSn = props.parentComment.userSn
      }
    }

    // 이미지가 포함되어 있다면 이미지 조건 추가
    if (push.images.length) {
      payload.tempImgUrlList = push.images
    }
    return payload
  }

  // 2-2. 댓글 & 답글 등록
  try {
    isLoading.value = true
    await postStore.reqCommentAdd(calcPayload())
    successProcess()
    gtEvent('replyAction', {
      eventCategory: '댓글',
      eventAction: '댓글 달기 성공',
      eventLabel: t('doComment'),
      eventSlot: '댓글 달기',
      eventI18nAddr: useKoreanTranslation('doComment'),
      eventComponent: 'Button',
      cmtyNttSn: calcPayload().cmtyNttSn,
      nttAnswerCn: calcPayload().nttAnswerCn,
    })
    comment.value = ''
    emit('onCommentCancel')
  } catch (err) {
    const defaultMessage = t('statusMessages.commentsAdd.errors.default', {
      type: t(!props.parentComment ? 'comments' : 'replies'),
    })

    if (err instanceof AxiosError) {
      switch (err.response?.status) {
        case 8048:
          useToast(
            t('statusMessages.commentsAdd.errors.8048', {
              type: t(!props.parentComment ? 'comments' : 'replies'),
            })
          )
          break
        default:
          useToast(defaultMessage)
          break
      }
    } else {
      useToast(defaultMessage)
    }
  } finally {
    isLoading.value = false
  }
}

const handleOnCancelReply = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '댓글 취소',
    eventLabel: t('cancelReply'),
    eventSlot: '작성하던 댓글 초기화',
    eventI18nAddr: useKoreanTranslation('cancelReply'),
    eventComponent: 'Button',
  })
  comment.value = ''
  emit('onCommentCancel')
}

/**
 * 사용자 홈 가기
 * */
const handleOnGoUserHomePage = async () => {
  if (!userStore.user) return
  await useGoUserHome(userStore.user.userSn, async () => {
    await hideModal(modalsName.MODAL_POST_DETAIL)
  })
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '댓글 작성 > 썸네일',
    eventLabel: '썸네일',
    eventSlot: `${userStore.user.userNcnm} 홈으로 이동`,
    eventI18nAddr: '',
    eventComponent: 'Button',
  })
}
// methods: 에디터에서 로딩 인디케이터 노출
const handleOnTipTapLoading = (loading: boolean) => {
  isLoading.value = loading
}
</script>

<template>
  <div class="add-comment">
    <ClientOnly>
      <NovaPortraitContainer
        :image-url="userStore.user?.userProflUrl || ''"
        :size="'sm'"
        class="portrait"
        @click="handleOnGoUserHomePage"
      />
    </ClientOnly>

    <NovaTipTap
      :model-value="comment"
      :placeholder="$t('writeComment')"
      :mode="'comment'"
      :is-loading="isLoading"
      class="comment-add-editor"
      @on-loading="handleOnTipTapLoading"
      @push-editor-contents="reqCommentAdd"
      @cancel-reply="handleOnCancelReply"
    />
  </div>
</template>

<style lang="scss" scoped>
.add-comment {
  display: flex;
  gap: 10px;

  .portrait {
    flex-shrink: 0;
  }

  .comment-add-editor {
    flex-grow: 1;
    width: 100%;
  }
}
</style>
