/**
 * 주어진 메시지를 클립보드에 복사
 *
 * @param {string} message - 복사할 메시지
 * @returns {void}
 */
export const useCopyToClipboard = (message: string): void => {
  const el = document.createElement('textarea')

  el.value = message
  el.setAttribute('readonly', '')
  el.style.position = 'fixed'
  el.style.left = '-9999px'
  document.body.appendChild(el)

  const selected =
    document.getSelection()!.rangeCount! > 0
      ? document.getSelection()?.getRangeAt(0)
      : false

  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)

  if (selected) {
    document.getSelection()?.removeAllRanges()
    document.getSelection()?.addRange(selected)
  }
}
