/**
 * 앱 종료 안내
 */
export const terminateApp = {
  dialog: {
    contents: '{appName}을 종료 하시겠습니까?',
    actions: {
      negative: '취소',
      positive: '종료',
    },
  },
}
