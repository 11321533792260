import type { BooleanYn } from '@store'
import type { Radio } from '@components/NovaRadio/NovaRadio.types'
import type { Theme } from '@components/NovaButtonText/NovaButtonText.types'

/**
 * 슈퍼문 참가 신청 가능 상태
 */
export enum SuperMoonApplyAvailableStatus {
  UNAVAILABLE_REQUIRE_CONDITIONS = 'requireRentCheckConditions', // 슈퍼문 참가 정보 필요
  UNAVAILABLE_NOT_APP = 'notAnApp', // 슈퍼문 참가 정보 필요
  UNAVAILABLE_REQUIRE_SIGN_IN = 'requireLogin', // 로그인 필요
  UNAVAILABLE_ALREADY_APPLIED = 'alreadyApply', // 이미 신청 했음
  UNAVAILABLE_APPLIED = 'unavailable', // 서버에서 판단해서 보내주는 신청 불가
  AVAILABLE_APPLY = 'availableApply', // 참가 신청 가능
}

/**
 * 슈퍼문 약관 상세조회 payLoad
 */
export type SuperMoonTermPayload = number

/**
 * 슈퍼문 약관 상세조회 payLoad
 */
export type SuperMoonSectCode = 'P' | 'G'

export type SuperMoonApplyFormPayloadFormData = FormData

/**
 * 슈퍼문 참가 신청 폼
 */
export interface SuperMoonApplyFormPayload {
  agreeFstvlApprtcStplatSnList: number[] // 동의 약관 일련번호 목록
  agreementFile?: File | null // 법정 대리인 동의서
  applcntReprsntLglrpsLmttAgeAt: BooleanYn | null // 대표 14세 미만 여부
  applcntReprsntTelno: string // 대표 전화번호
  applcntTeamIntrcnCn: string // 팀 소개
  applcntTeamLglrpsLmttAgeAt?: BooleanYn | null // 팀 내 14세 미만 존재여부
  applcntTeamNm: string // 팀 명
  applcntTeamReqstResn: string // 신청 사유
  contentsFile?: File | null // 대표 영상 파일
  fstvlSectCode: SuperMoonSectCode // 부문 코드 P(개인) G(그룹)
}

/**
 * 슈퍼문 참가 신청서 > 참가 신청 유형 선택
 */
export interface SuperMoonTeamTypeOptional {
  value: SuperMoonSectCode
  conditions: Radio<string>[]
  changeCondition: (value: SuperMoonSectCode) => void
}

/**
 * 슈퍼문 참가 신청서 > 멤버 14세 미만 체크
 */
export interface SuperMoonMemberAgeCheckOptionOptional {
  value: BooleanYn
  conditions: Radio<string>[]
  changeCondition: (value: BooleanYn) => void
}

/**
 * 슈퍼문 참가 신청서 > 멤버 14세 미만 체크
 */
export interface SuperMoonLeaderAgeCheckOptionOptional {
  value: BooleanYn
  conditions: Radio<string>[]
  changeCondition: (value: BooleanYn) => void
}

/**
 * 슈퍼문 약관 목록 타입
 */
export interface SuperMoonTerms {
  fstvlApprtcStplatSn: number
  stplatEssntlAt: BooleanYn
  stplatNm: string
}

export interface SuperMoonVideo {
  count: number
  maxCount: number
  label: string
  theme: Theme
  loading: boolean
  value: File | null
  errorMsg: string
  validator: (showErrorMsg?: boolean) => boolean
  descriptions?: string[]
}

/**
 * 슈퍼문 약관 상세
 */
export interface SuperMoonTerm {
  fstvlApprtcStplatSn: number
  stplatCn: string
  stplatDetailCn: string
  stplatNm: string
}

/**
 * 슈퍼문 약관 상세
 */
export interface SuperMoonApplyStatus {
  applcntReprsntLglrpsLmttAgeAt: BooleanYn // 신청자 대표 14세미만 여부 Y/N
  applicationAvailableAt: BooleanYn // 신청 가능 여부 Y/N
  fstvlApprtcActSttusUpdtDt: string // 최근 업데이트 일시
  fstvlApprtcAt: BooleanYn // 참가 신청 여부 Y/N
  fstvlApprtcEndDt: string // 참가 신청 종료 일시
  fstvlApprtcIntrCo: number // 참가 신청에 관심 있는 팀 수
  fstvlApprtcSubmCo: number // 신청 완료 팀 수
  fstvlSectCode: SuperMoonSectCode // 개인, 팀
}
/**
 * 슈퍼문 스토어 스테이트 타입
 */
export interface SuperMoonState {
  superMoonTerms: Array<SuperMoonTerms> | null // 시설물 임대 약관 목록
  applyStatus: SuperMoonApplyStatus | null
}
