import apiPath from '@store/apiPath'

export default {
  /**
   * 라이브 생성 권한 체크
   * @method GET
   */
  FETCH_CREATE_LIVE_PERMISSION: `${apiPath.SENDBIRD}/v1/live/permission`,

  /**
   * 라이브 생성
   * @method POST
   */
  REQ_CREATE_LIVE: `${apiPath.SENDBIRD}/v1/live`,

  /**
   * 라이브 토큰 게이팅 대상 목록 조회 - 플레이놈 NFT Collection 목록 조회
   * @method GET
   */
  FETCH_LIVE_NFT_COLLECTIONS: `${apiPath.SENDBIRD}/v1/live/collections`,

  /**
   * 라이브 입장 가능 여부 체크: token & sndbrdLiveSn 조건
   * @method GET
   */
  FETCH_ENTER_LIVE_PERMISSION: `${apiPath.SENDBIRD}/v1/live/enter/permission`,

  /**
   * 라이브 입장 가능 여부 체크: sendbird user id & sendbird live event id 조건
   */
  FETCH_ENTER_LIVE_PERMISSION_BY_SB_USER_ID: `${apiPath.SENDBIRD}/v1/live/enter/permission/liveEventId`,

  /**
   * Sendbird userId 생성
   */
  CREATE_SENDBIRD_USER_ID: `${apiPath.SENDBIRD}/v1/user`,

  /**
   * onAir(진행중) 라이브 복록 조회
   */
  FETCH_ON_AIR_LIVES: `${apiPath.SENDBIRD}/v1/lives/onAir`,

  /**
   * 오픈 채널 생성
   */
  CREATE_OPEN_CHANNEL: `${apiPath.SENDBIRD}/v1/open-channel`,
}
