import type { ShareType } from '@store'

/**
 * 공유하기
 */
export const share = {
  label: 'Share',
  actions: {
    facebook: 'Share to Facebook',
    twitter: 'Share to Twitter',
    kakao: 'Share to Kakao Talk',
    clipboard: 'Copy links',
  } as { [Property in ShareType]: string },
}
