import { commonError } from './commonError'

/**
 * Sendbird
 */
export const sendbird = {
  createLiveEvent: {
    errors: {
      checkCreateLiveAvailable: {
        3004: 'User who is unable to connect to live. Please try again later.', // sendbird user id가 존재하지 않습니다.
        3006: 'You have exceeded your daily live streaming time (60 minutes).', // 라이브 시간초과 오류
        4010: 'You are not a Creator who can create a live.', // 크리에이터 회원이 아닙니다.
      },
      fetchNftCollectionsFail: commonError.network.message,
      createLive: {
        2005: 'An extension that cannot be registered as a cover image.',
        3007: "There is a live that hasn't ended yet.", // 라이브 시간초과 오류
      },
      alreadyOnAir: "It's already live streaming.",
    },
    modal: {
      title: 'Create',
      guide: {
        primary:
          'Creator! Communicate with your fans In real-time with a live streaming!',
        secondary:
          '*Upload the live streaming record after the live ends. (creator home>contents>live)',
      },
      sections: {
        liveTitle: {
          label: 'Live Title',
          placeholder: 'Please enter a Live title.',
          errMin: 'No title was entered.',
          errMax: 'It is available to input maximum 50 characters.',
        },
        liveEnterLimit: {
          label: 'LIVE entry requirements setting',
          guide:
            'When setting entry requirements, you can limit the number of users who can enter your live streaming. You can select the NFT holders who can enter.',
          placeholder: 'Select NFT holders who can enter.',
          errorMessage: 'No entry requirements have been selected.',
          radios: {
            all: 'Open to all',
            limit: 'Entry requirements setting',
            choiceAll: 'All',
          },
        },
      },
      actions: {
        cancel: 'Cancel',
        create: 'Create',
      },
    },
  },
  enterLiveEvent: {
    actions: {
      enterLive: 'Enter',
    },
    errors: {
      checkEnterLiveAvailableFail: commonError.network.message,
      2011: 'There is no live.',
      2034: 'The host blocked you, so you can’t access the live stream.',
      3010: 'This is not an ongoing live.',
      3011: 'You already enter the live.',
      3012: 'The number of people who can enter is already full.',
    },
    modal: {
      message: "Unable to enter the creator's live.",
      description: 'You can enter if you own the following NFTs',
      cause: 'NFT List',
    },
  },
  createChatChannel: {
    actions: {
      create: 'Create a fan chat room',
    },
    errors: {
      3008: 'Exceeded the number of channels that can be created.',
      4010: 'You are not a Creator who can create a channel.',
      network: commonError.network.message,
    },
    modal: {
      title: 'Create a fan chat room',
      guide: {
        primary:
          'Creator! Create a fan chat room and communicate with your fans In real-time!',
        secondary:
          "*One fan chat room per creator is allowed, and followers will be notified when it's first created.",
      },
      sections: {
        channelTitle: {
          label: 'Chat Room Title',
          placeholder: 'Please enter a chat room title for your fans and you.',
          errMin: 'No chat room title was entered.',
          errMax: 'It is available to input maximum 50 characters.',
        },
      },
      actions: {
        cancel: 'Cancel',
        create: 'Create',
      },
    },
  },
  enterChatChannel: {
    actions: {
      enter: 'Chat list',
    },
    errors: {
      createChannelNotYet:
        'There are no fan chat rooms created by the creator.', // 크리에이터에게 channelUrl이 아직 없음
      enterChatFail: 'Unable to enter the chat. Please try again later.',
    },
    enterCreatorChannel: "Enter a creator's fan chat room",
  },
}
