<script setup lang="ts">
defineOptions({
  inheritAttrs: false,
})

const { hide: modalHide } = useModal()
const show = ref(false)
const termData = ref()
const facilityStore = useFacilityStore()
const handleOnGetParams = async (evt: any) => {
  try {
    termData.value = await facilityStore.fetchFacilityRentalTerm(evt.value)
  } catch (err) {
    console.log(err)
  }
}
// 팝업 닫기
const handleOnClose = () => {
  modalHide(modalsName.MODAL_TERM_OF_FACILITY)
}
</script>

<template>
  <VueFinalModal
    v-model="show"
    :name="modalsName.MODAL_TERM_OF_FACILITY"
    :content-class="'modal-content'"
    classes="modal-container"
    @before-open="(evt: any) => handleOnGetParams(evt.ref.params)"
    @click-outside="handleOnClose"
  >
    <NovaBoxBase v-if="show" class="modal-box">
      <div class="modal-header">
        <h4 class="title">{{ termData?.stplatNm }}</h4>
        <NovaButtonIcon
          :icon="{ type: 'outline', name: 'close-extend' }"
          :theme="'transparent'"
          :size="20"
          class="btn-close"
          @click="handleOnClose"
        />
      </div>

      <div class="modal-body">
        <NovaLayoutScrollContainer>
          <NovaModalTerm :term-detail="termData?.stplatDetailCn || ''" />
        </NovaLayoutScrollContainer>
      </div>
      <div class="actions">
        <NovaButtonText
          :label="$t('confirm')"
          :theme="'primary-blue'"
          :full-width="true"
          @click="handleOnClose"
        />
      </div>
    </NovaBoxBase>
  </VueFinalModal>
</template>

<style scoped lang="scss">
:deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.vfm--overlay) {
  background-color: hex-to-rgba($color-black, 0.65);
}

:deep(.modal-content) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: none;

  .modal-box {
    width: 100%;
    height: auto;
    max-width: 420px;
    max-height: calc(100% - 24px - 24px);
    padding: 0;
    pointer-events: auto;
    overflow: hidden;

    .modal-header {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 56px;
      padding: 0 20px;
      color: $color-text-2;
      background-color: $color-bg-3;
      border-bottom: 1px solid $border-1;

      .title {
        @include text-style($text-display-bold);
      }
    }

    .modal-body {
      display: flex;
      padding: 20px;

      .contents-container {
        .terms_box_list {
          display: flex;
          flex-direction: column;
          gap: 16px;

          dt {
            @include text-style($text-body-14-bold);
          }

          dd {
            @include text-style($text-body-14-regular);
          }
        }
      }
    }

    .actions {
      flex-shrink: 0;
      width: 100%;
      padding: 0 20px 20px;
    }

    @include mobile {
      flex-grow: 1;
      border-radius: 0 !important;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
    }
  }
}
</style>
