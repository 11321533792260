<script setup lang="ts">
import { v4 } from 'uuid'
import type { NovaPortraitContainerProps } from './NovaPortraitContainer.types'

const props = withDefaults(defineProps<NovaPortraitContainerProps>(), {
  imageUrl: null,
  size: 'md',
  stroke: false,
  highlightStroke: false,
  hasAction: false,
  emptyType: 'portrait',
})

const sizeClass = computed(() => `size-${props.size}`)
const hasActionClass = computed(() => (props.hasAction ? 'has-action' : ''))
</script>

<template>
  <div :class="['portrait-container', sizeClass, hasActionClass]">
    <div
      :class="[
        'inner',
        { stroke },
        { 'highlight-stroke': highlightStroke },
        emptyType,
      ]"
    >
      <NovaImageContainer
        :key="imageUrl || v4()"
        :image-url="imageUrl"
        :empty-type="emptyType"
        :ratio="'1:1'"
        :stroke="stroke"
        :portrait-xs="size === 'xs'"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.portrait-container {
  position: relative;
  flex-shrink: 0;
  display: block;

  &.has-action {
    cursor: pointer;
  }

  @mixin size($size) {
    width: $size;
    height: $size;

    > .inner {
      border-radius: calc($size / 2);

      &.magnifier {
        border-radius: 0;
      }
    }
  }

  &.size-xs {
    @include size(28px);

    > .inner {
      &.stroke {
        border: 1px solid $color-bg-3;
      }

      &.highlight-stroke {
        box-shadow: 0 0 0 3px $color-bg-custom-12;
      }

      :deep(.empty-image.portrait) {
        background-size: 14px auto;
      }
    }
  }

  &.size-sm {
    @include size(40px);

    > .inner {
      &.stroke {
        border: 2px solid $color-bg-3;
      }

      &.highlight-stroke {
        box-shadow: 0 0 0 3px $color-bg-custom-12;
      }

      :deep(.empty-image.portrait) {
        background-size: 20px auto;
      }
    }
  }

  &.size-md {
    @include size(50px);

    > .inner {
      &.stroke {
        border: 2px solid $color-bg-3;
      }

      &.highlight-stroke {
        box-shadow: 0 0 0 3px $color-bg-custom-12;
      }
    }
  }

  &.size-lg {
    @include size(100px);

    > .inner {
      &.stroke {
        border: 4px solid $color-bg-3;
      }

      &.highlight-stroke {
        box-shadow: 0 0 0 3px $color-bg-custom-12;
      }
    }
  }

  &.size-xl {
    @include size(130px);

    > .inner {
      &.stroke {
        border: 4px solid $color-bg-3;
      }

      &.highlight-stroke {
        box-shadow: 0 0 0 3px $color-bg-custom-12;
      }
    }
  }

  &.size-60 {
    @include size(60px);

    > .inner {
      &.stroke {
        border: 4px solid $color-bg-3;
      }

      &.highlight-stroke {
        box-shadow: 0 0 0 3px $color-bg-custom-12;
      }
    }
  }

  &.size-56 {
    @include size(56px);

    > .inner {
      &.stroke {
        border: 2px solid $color-bg-3;
      }

      &.highlight-stroke {
        box-shadow: 0 0 0 3px $color-bg-custom-12;
      }
    }
  }

  > .inner {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: $color-bg-1;
  }
}
</style>
