<script setup lang="ts"></script>

<template>
  <ModalsContainer />

  <NovaModalW3cfDetail />
  <NovaModalTermDetail />
  <NovaModalSponsorDetail />
  <NovaModalShareAction />
  <NovaModalSelectBank />
  <NovaModalRegCreatorGuestBook />
  <NovaModalPremiumExpired />
  <NovaModalPostDetail />
  <NovaModalPay />
  <NovaModalOptionMenu />
  <NovaModalNotAvailableEnterLive />
  <NovaModalMyFollowings />
  <NovaModalMyFollowers />
  <NovaModalMyBlock />
  <NovaModalKycUnAuth />
  <NovaModalFundProject />
  <NovaModalDonate />
  <NovaModalCreatorNoticeDetail />
  <NovaModalCreateOpenChannel />
  <NovaModalCreateLive />
  <NovaModalBlockAccess />
  <NovaModalAvatar />
  <NovaModalReferral />
  <NovaModalMarketingAgreement />
  <NovaModalMobAppGate />
  <NovaModalTermOfFacility />
  <NovaModalRentCompleteMarketing />
  <NovaModalBonusRewardMonthly />
  <NovaModalMyCollectionNft />
  <NovaModalTermOfSuperMoon />
  <NovaModalFeedForYouUpdate />
</template>
