<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import BadgeAI from '@assets/images/badge-ai.svg?url'

const { t } = useI18n()
const layoutStore = useLayoutStore()
const { touchDevice } = storeToRefs(layoutStore)
const openTriggerRef = ref<HTMLDivElement>()
const isOpen = ref(false)

const handleOnMouseOver = () => {
  !touchDevice.value && (isOpen.value = true)
}
const handleOnMouseLeave = () => {
  !touchDevice.value && (isOpen.value = false)
}
const handleOnClick = () => {
  isOpen.value = !isOpen.value
}

onClickOutside(openTriggerRef, () => {
  isOpen.value = false
})
</script>

<template>
  <div :class="['nova-badge-ai', { on: isOpen }]">
    <div class="inner">
      <div
        ref="openTriggerRef"
        class="badge-wrap"
        @click.stop="handleOnClick"
        @mouseover="handleOnMouseOver"
        @mouseleave="handleOnMouseLeave"
      >
        <img :src="BadgeAI" class="badge" alt="ai badge" />
      </div>

      <div class="tooltip">
        {{ t('aiContents.based') }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.nova-badge-ai {
  width: 26px;
  height: 26px;

  @include mobile {
    width: 24px;
    height: 24px;
  }

  &.on {
    .inner {
      .tooltip {
        opacity: 1;
      }
    }
  }

  .inner {
    position: relative;

    .badge-wrap {
      font-size: 0;
    }

    .tooltip {
      position: absolute;
      top: 50%;
      right: calc(100% + 6px);
      transform: translateY(-50%);
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.2s ease-in-out;

      color: #213acc;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: -0.6px;
      width: max-content;
      padding: 6px 9px;
      word-break: keep-all;

      &:before {
        content: '';
        position: absolute;
        inset: 0;
        border-radius: 6px;
        padding: 1px;
        background: linear-gradient(90deg, #3c9ecf, #213acc);
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
      }
    }
  }
}
</style>
