<script setup lang="ts">
import type {
  NovaTipTapMenuAddLinkEmits,
  NovaTipTapMenuAddLinkProps,
} from './NovaTipTapMenuAddLink.types'
import { ReplacePostContentsType } from '@store'

const emit = defineEmits<NovaTipTapMenuAddLinkEmits>()
const props = defineProps<NovaTipTapMenuAddLinkProps>()

const linkUrl = ref('')
const hasHttpProtocol = computed(() => useCheckHttpProtocol(linkUrl.value))

watch(
  () => props.isOpen,
  (cur) => {
    if (cur) init()
  }
)

// init
const init = () => {
  linkUrl.value = ''
}

// add link
const handleOnSetLink = () => {
  props.editor
    .chain()
    .focus()
    .extendMarkRange('link')
    .setLink({
      href: linkUrl.value,
      // @ts-ignore
      class: ReplacePostContentsType.LINK,
    })
    .run()
  emit('onClose')
}
</script>

<template>
  <NovaBoxBase class="add-link-url" :floating="true">
    <NovaInput
      v-model="linkUrl"
      :label="$t('linkAddPanel.label')"
      :placeholder="$t('linkAddPanel.placeholder')"
    >
      <template #actions>
        <NovaButtonText
          :label="$t('linkAddPanel.addBtn')"
          :theme="'primary-blue'"
          :disabled="!hasHttpProtocol"
          @click="handleOnSetLink"
        />
      </template>
    </NovaInput>
  </NovaBoxBase>
</template>

<style lang="scss" scoped>
.add-link-url {
  display: flex;
  flex-direction: column !important;
  min-width: 340px;
  max-width: 100%;

  .video-url-wrap {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
}
</style>
