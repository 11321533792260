import {
  RecycleScroller,
  DynamicScroller,
  DynamicScrollerItem,
} from 'vue-virtual-scroller'
import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component(RecycleScroller)
  nuxtApp.vueApp.component(DynamicScroller)
  nuxtApp.vueApp.component(DynamicScrollerItem)
})
