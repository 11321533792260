import { commonError } from './commonError'

/**
 * Sendbird
 */
export const sendbird = {
  createLiveEvent: {
    errors: {
      checkCreateLiveAvailable: {
        3004: '라이브에 연결 할 수 없는 사용자입니다. 잠시 후 다시 시도해 주세요.', // sendbird user id가 존재하지 않습니다.
        3006: '하루에 방송 가능한 시간(60분)을 초과하였습니다.', // 라이브 시간초과 오류
        4010: '라이브를 개설할 수 있는 크리에이터 회원이 아닙니다.', // 크리에이터 회원이 아닙니다.
      },
      fetchNftCollectionsFail: commonError.network.message,
      createLive: {
        2005: '커버 이미지가 등록할 수 없는 확장자 입니다.',
        3007: '아직 종료되지 않은 라이브가 있습니다.', // 라이브 시간초과 오류
      },
      alreadyOnAir: '이미 라이브 진행중입니다.',
    },
    modal: {
      title: '개설하기',
      guide: {
        primary:
          '크리에이터님! 라이브 방송을 통해 팬들과 실시간으로 소통해보세요.',
        secondary:
          '*방송 종료 후, 크리에이터홈>콘텐츠>라이브 탭에 라이브 방송 영상이 업로드 됩니다.',
      },
      sections: {
        liveTitle: {
          label: 'LIVE 제목 설정',
          placeholder: 'LIVE 제목을 입력해주세요.',
          errMin: '제목을 입력하지 않았습니다.',
          errMax: '최대 50자까지 입력하실 수 있습니다.',
        },
        liveEnterLimit: {
          label: 'LIVE 입장 제한 설정',
          guide:
            '입장 제한 설정시,<br />나의 LIVE 방송에 입장할 수 있는 유저를 제한합니다.<br />입장 가능한 NFT홀더를 선택할 수 있습니다.',
          placeholder: '입장 가능한 NFT 홀더를 선택하세요.',
          errorMessage: '입장 조건을 선택하지 않았습니다.',
          radios: {
            all: '누구나 입장 가능',
            limit: '입장 제한 설정',
            choiceAll: '전체 선택',
          },
        },
      },
      actions: {
        cancel: '취소',
        create: '개설하기',
      },
    },
  },
  enterLiveEvent: {
    actions: {
      enterLive: '입장하기',
    },
    errors: {
      checkEnterLiveAvailableFail: commonError.network.message,
      2011: '라이브가 존재하지 않습니다.',
      2034: '호스트가 나를 차단하여 라이브에 접근할 수 없습니다.',
      3010: '진행 중인 라이브가 아닙니다.',
      3011: '이미 라이브에 입장하였습니다.',
      3012: '이미 입장할 수 있는 인원이 찼습니다.',
    },
    modal: {
      message: '크리에이터 님의<br />LIVE에 입장할 수 없습니다',
      description: '아래의 NFT를 보유하시면, 입장하실 수 있습니다.',
      cause: 'NFT 목록',
    },
  },
  createChatChannel: {
    actions: {
      create: '팬 채팅방 개설하기',
    },
    errors: {
      3008: '생성 가능한 채널 개수를 초과 하였습니다.',
      4010: '채널 생성 가능한 크리에이터가 아닙니다.',
      network: commonError.network.message,
    },
    modal: {
      title: '팬 채팅방 개설하기',
      guide: {
        primary:
          '크리에이터님! 팬 채팅방을 만들고 팬들과 실시간으로 소통해보세요.',
        secondary: '*크리에이터 당 1개의 팬 채팅방 개설이 가능합니다.',
      },
      sections: {
        channelTitle: {
          label: '채팅방 이름 설정',
          placeholder: '나와 팬들을 위한 채팅방 이름을 입력해주세요.',
          errorMessage: '채팅방 이름을 입력하지 않았습니다.',
          errMin: '채팅방 이름을 입력하지 않았습니다.',
          errMax: '최대 50자까지 입력하실 수 있습니다.',
        },
      },
      actions: {
        cancel: '취소',
        create: '개설하기',
      },
    },
  },
  enterChatChannel: {
    actions: {
      enter: '채팅 목록',
    },
    errors: {
      createChannelNotYet: '크리에이터가 개설한 팬 채팅방이 없습니다.', // 크리에이터에게 channelUrl이 아직 없음
      enterChatFail: '채팅에 진입할 수 없습니다. 잠시 후 다시 시도해 주세요.', // sendbird 사용자 아이디가 없음
    },
    enterCreatorChannel: '크리에이터의 팬 채팅방 참여하기',
  },
}
