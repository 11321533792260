<script setup lang="ts">
import type {
  NovaMegaMenuItemProps,
  MegaMenuItemUnit,
} from './NovaMegaMenuItem.types'

const props = withDefaults(defineProps<NovaMegaMenuItemProps>(), {
  forceExpand: false,
})
const postStore = usePostStore()
const showSubMenus = ref(true)
const { gtEvent } = useGoogleTag()
watch(
  () => props.forceExpand,
  (cur) => {
    if (props.prop.children && props.prop.children.length && cur) {
      showSubMenus.value = false
    }
  },
  { immediate: true }
)

const handleOnMenuItemClick = () => {
  if (props.prop.children && props.prop.children.length) {
    showSubMenus.value = !showSubMenus.value
    return
  }
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: `메뉴 패널 > ${props.prop.label}`,
    eventLabel: '',
    eventSlot: '모바일',
    eventI18nAddr: '',
    eventComponent: 'Button',
  })
  props.prop.onClick(props.prop)
}

const handleOnMenuChildItemClick = (
  child: MegaMenuItemUnit,
  el: Element[] = []
) => {
  if (child.children && child.children.length && el.length) {
    el[0].classList.toggle('extend')
    return
  }
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: `메뉴 패널 > ${child.label}`,
    eventLabel: '',
    eventSlot: '모바일',
    eventI18nAddr: '',
    eventComponent: 'Button',
  })
  child.onClick(child)
}
</script>

<template>
  <div :class="['mega-menu-item-container', { extend: showSubMenus }]">
    <button type="button" class="mega-menu-item" @click="handleOnMenuItemClick">
      <span class="label">{{ prop.label }}</span>
      <NovaIcon
        v-if="prop.children && prop.children.length"
        :icon="{ type: 'outline', name: 'carrot-bottom' }"
        class="sub-menu-indicator"
      />
    </button>

    <div v-if="prop.children && prop.children.length" class="children">
      <div
        v-for="(fstChild, fstChildIdx) in prop.children"
        :ref="`mega-menu-item-children-${fstChildIdx}`"
        :key="fstChildIdx"
        :class="[
          'mega-menu-item-children',
          {
            on:
              fstChild.cmtyNttCtgrySn &&
              fstChild.cmtyNttCtgrySn ===
                Number(postStore.posts.payload.cmtyNttCtgrySn),
          },
        ]"
      >
        <button
          class="mega-menu-item sub"
          @click.prevent="
            () =>
              handleOnMenuChildItemClick(
                fstChild,
                $refs[`mega-menu-item-children-${fstChildIdx}`]
              )
          "
        >
          <span class="label">{{ fstChild.label }}</span>

          <NovaIcon
            v-if="fstChild.children && fstChild.children.length"
            :icon="{ type: 'outline', name: 'chev-compact-bottom' }"
            class="sub-menu-indicator"
          />
        </button>

        <div
          v-if="fstChild.children && fstChild.children.length"
          class="children"
        >
          <button
            v-for="scdChild in fstChild.children"
            :key="scdChild.cmtyNttCtgrySn"
            :class="[
              'mega-menu-item sub',
              {
                on:
                  scdChild.cmtyNttCtgrySn &&
                  scdChild.cmtyNttCtgrySn ===
                    Number(postStore.posts.payload.cmtyNttCtgrySn),
              },
            ]"
            @click.prevent="() => handleOnMenuChildItemClick(scdChild)"
          >
            <span class="label">{{ scdChild.label }}</span>

            <NovaIcon
              v-if="scdChild.children && scdChild.children.length"
              :icon="{ type: 'outline', name: 'chev-compact-bottom' }"
              class="sub-menu-indicator"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.mega-menu-item-container {
  display: flex;
  flex-direction: column;

  &.extend {
    gap: 4px;

    > .mega-menu-item .sub-menu-indicator {
      color: $color-primary-blue;
      transform: rotate(180deg);
    }

    .children {
      display: flex;
    }
  }

  .mega-menu-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 8px 5px 12px;
    cursor: pointer;
    border-radius: 5px;
    @include transition(background-color 0.2s);

    @media (hover: hover) {
      &:hover {
        background-color: $color-bg-custom-2;
      }
    }

    &:active {
      background-color: $color-bg-custom-3;
    }

    &.sub {
      > .label {
        color: $color-text-3;
      }

      > .sub-menu-indicator {
        color: $color-text-3;
      }
    }

    > .label {
      @include text-style($text-body-12-regular);
      line-height: 20px;
    }

    .sub-menu-indicator {
      color: $color-text-4;
      @include transition(color 0.2s ease-in-out, transform 0.2s ease-in-out);
    }
  }

  .children {
    display: none;
    flex-direction: column;
    gap: 4px;

    .mega-menu-item-children {
      display: flex;
      flex-direction: column;
      gap: 4px;

      &.extend {
        .mega-menu-item {
          .sub-menu-indicator {
            transform: rotate(180deg);
          }
        }

        .children {
          display: flex;
        }
      }

      &.on {
        .mega-menu-item {
          background-color: $color-bg-1;
        }
      }
    }

    .children {
      display: none;

      .mega-menu-item {
        padding-left: 24px;

        &.on {
          background-color: $color-bg-1;
        }
      }
    }
  }
}
</style>
