/**
 * 공통 에러
 */
export const commonError = {
  401: {
    title: 'Authentication error',
    message:
      'The service connection has been terminated. Please try again later.',
  },
  403: {
    title: 'Access rights restricted',
    message:
      'You do not have permission to access this page. Please contact the administrator.',
  },
  404: {
    title: 'Access error',
    message:
      'The requested page cannot be found. Please check the page and try again.',
  },
  500: {
    title: 'The server is being checked',
    message: 'The server is being checked for a while. Please try again later.',
  },
  502: {
    title: 'The server is being checked',
    message: 'The server is being checked for a while. Please try again later.',
  },
  505: {
    title: 'The server is being checked',
    message: 'The server is being checked for a while. Please try again later.',
  },
  4002: {
    title: 'This is not a registered member.',
    message:
      'This member has either withdrawn or does not exist. Please try searching for a different member.',
  },
  1998: {
    title: '',
    message: 'Loading the updated feed.',
  },
  default: {
    title: 'An unknown error has occurred.',
    message: 'Please try again later.',
  },
  network: {
    title: 'Network error',
    message: 'The service is currently unavailable. Please try again later.',
  },
}
