import * as SuperMoonType from '@store/supermoon/type'
import SuperMoonApi from '@store/supermoon/url'
import { api } from '@api'

export default {
  /**
   * 슈퍼문 참가 신청
   */
  reqSuperMoonApplyLog() {
    return api.post<{}>(SuperMoonApi.REQ_SUPER_MOON_APPLY_LOG)
  },

  /**
   * 슈퍼문 참가 신청
   */
  reqSuperMoonApply(payload: SuperMoonType.SuperMoonApplyFormPayloadFormData) {
    return api.post<{}>(SuperMoonApi.REQ_SUPER_MOON_APPLY, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },

  /**
   * 슈퍼문 약관  상세 조회
   */
  fetchSuperMoonTerm(payload: SuperMoonType.SuperMoonTermPayload) {
    return api.get<Array<SuperMoonType.SuperMoonTerm>>(
      SuperMoonApi.FETCH_SUPER_MOON_TERM,
      { params: payload }
    )
  },

  /**
   * 슈퍼문 약관 목록 조회
   */
  fetchSuperMoonTerms() {
    return api.get<Array<SuperMoonType.SuperMoonTerms>>(
      SuperMoonApi.FETCH_SUPER_MOON_TERMS
    )
  },

  /**
   * 시설물 임대 신청 요건 조회
   */
  fetchSuperMoonApplyStatus() {
    return api.get<SuperMoonType.SuperMoonApplyStatus>(
      SuperMoonApi.FETCH_SUPER_MOON_APPLY_STATUS
    )
  },
}
