interface UseObserveElementVisibilityParams {
  element: HTMLElement
  callback: () => void
  threshold?: number
}

/**
 * DOM 요소의 가시성을 관찰하기 위한 교차 관찰자(Intersection Observer)를 생성
 *
 * @param {Object} params - 교차 관찰자를 생성하기 위한 매개변수
 * @property {Element} params.element - 관찰할 DOM 요소
 * @property {function} params.callback - 요소가 보이게 될 때 호출될 콜백 함수
 * @property {number} [params.threshold=0.5] - 콜백을 실행하기 위해 필요한 요소의 가시성 비율
 *
 * @returns {IntersectionObserver} - 생성된 교차 관찰자
 */
export const useObserveElementVisibility = ({
  element,
  callback,
  threshold = 0.5,
}: UseObserveElementVisibilityParams): IntersectionObserver => {
  // create observer
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio >= threshold) {
          callback()
        }
      })
    },
    {
      threshold,
    }
  )

  // observe start
  observer.observe(element)

  return observer
}
