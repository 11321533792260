<script setup lang="ts">
const on = ref(false)

// methods: 데브툴 토글
const toggleDevTools = () => {
  on.value = !on.value
}
</script>

<template>
  <div :class="['dev-tools', { on }]">
    <div class="dim" @click="toggleDevTools" />

    <div class="inner">
      <button type="button" class="toggle-dev-tools" @click="toggleDevTools">
        <NovaIcon :icon="{ type: 'outline', name: 'swap-horizon' }" />
      </button>

      <div v-if="on" class="panel">
        <!-- 앱 정보 -->
        <NovaDevToolsAppInfo />

        <div class="divider-wrapper">
          <NovaDivider :gap="12" />
        </div>

        <!-- 앱 버전 -->
        <NovaDevToolsAppVersion />

        <div class="divider-wrapper">
          <NovaDivider :gap="12" />
        </div>

        <!-- 앱 모드 변경 -->
        <NovaDevToolsAppMode />

        <div class="divider-wrapper">
          <NovaDivider :gap="12" />
        </div>

        <!-- 인터페이스 호출 -->
        <NovaDevToolsBridge />

        <div class="divider-wrapper">
          <NovaDivider :gap="12" />
        </div>

        <!-- FCM -->
        <NovaDevToolsFcm />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$toggle-size: 64px;
$toggle-left-off-set: 24px;

.dev-tools {
  position: fixed;
  top: 0;
  left: calc(100% - $toggle-size - $toggle-left-off-set);
  width: 100%;
  height: 100%;
  z-index: $z-index-dev-tools;
  pointer-events: none;
  @include transition(left 0.2s ease-in-out);

  .dim {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: hex-to-rgba($color-black, 0.65);
    opacity: 0;
    pointer-events: none;
    @include transition(opacity 0.2s ease-in-out);
    z-index: -1;
  }

  > .inner {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    padding-left: 24px;
    pointer-events: none;

    .toggle-dev-tools {
      flex-shrink: 0;
      width: $toggle-size;
      height: $toggle-size;
      margin-top: 50%;
      border-radius: 12px 0 0 12px;
      pointer-events: auto;
      background-color: $color-white;
      box-shadow: -4px 0 12px hex-to-rgba($color-black, 0.2);
      opacity: 0.2;
      @include transition(opacity 0.2s ease-in-out);
      @media (hover: hover) {
        &:hover {
          opacity: 1;
        }
      }
    }

    .panel {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      background-color: $color-white;
      overflow-y: auto;

      > .divider-wrapper {
        padding: 0 16px;
      }
    }
  }

  &.on {
    left: 0;

    .dim {
      pointer-events: auto;
      opacity: 1;
    }

    > .inner {
      .toggle-dev-tools {
        opacity: 1;
      }

      .panel {
        pointer-events: auto;
      }
    }
  }
}
</style>
