import * as cheerio from 'cheerio'

/**
 * HTML 문자열에서 처음 세 개의 단락에서 텍스트를 추출하고 연결
 *
 * @param {string} htmlString - 텍스트를 추출할 HTML 문자열
 * @returns {string} - 처음 세 단락에서 연결된 텍스트, 또는 유효하지 않은 경우 원래의 HTML 문자열을 반환
 */
export const useGetMaxThreeParagraphTexts = (
  htmlString: string | null
): string => {
  if (htmlString === null) return ''

  const isValid = useIsValidHTML(htmlString)
  if (!isValid) {
    return htmlString
  }
  const dom = cheerio.load(htmlString)
  const texts: Array<string> = []
  dom('p').each((i, el) => {
    if (i <= 3) texts.push(dom(el).text())
  })
  const text = texts.join(' ').replace(' ', '').replaceAll('\n', '')

  return text
}
