/**
 * 출석체크 관련 언어팩
 */
export const dailyCheck = {
  check: '출석체크',
  dailyCheckin: '출석체크',
  checkedIn: '출석완료!',
  notification: '출석체크 알림',
  totalCheckinDays: '총 출석일',
  consecutiveCheckinDays: '연속 출석일',
  guide: '유의사항',
  guides: [
    '출석체크 활동 점수는 5점입니다.',
    '출석체크 알림 설정 시 매일 오후 2시에 푸시 알림이 발송됩니다.',
    '매일 00시 00분부터 출석체크 가능합니다. (KST기준 1일 1회 한정)',
    '출석체크 버튼을 눌러야 출석체크가 인정됩니다.',
    '당사의 사정으로 사전 안내 없이 출석체크 운영 정책이 변경 혹은 중단될 수 있습니다.',
    '비정상적인 방법으로 출석체크를 할 경우 리워드 지급 대상에서 제외될 수 있습니다.',
    '출석체크 활동 점수는 매주 화요일 주간 리워드에 적용됩니다.',
  ],
  reqDailyCheck: {
    success: '출석이 완료되었습니다.',
    errors: {
      2010: '이미 출석을 완료하였습니다.',
    },
  },
}
