/**
 * 주어진 텍스트에 특수 문자가 포함되어 있는지 확인
 *
 * @param {string} text - 확인할 텍스트
 * @returns {boolean} - 텍스트에 특수 문자가 포함되어 있으면 true, 그렇지 않으면 false를 반환
 */
export const useCheckSpecialCharacters = (text: string): boolean => {
  const pattern = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/
  return pattern.test(text)
}
