import * as PostType from './type'
import PostService from './service'
import { initPostState } from './init'
import type { BaseInfinityScrollList } from '@store/types'
import type { Hashtag } from '@store/etc/type'

export * from './init'
export * from './type'
export const usePostStore = defineStore('post', () => {
  const myPageStore = useMyPageStore()
  const creatorStore = useCreatorStore()
  const searchStore = useSearchStore()

  const categories = ref(initPostState.categories)
  const baseHashtags = ref(initPostState.baseHashtags)
  const feedPosts = ref(initPostState.feedPosts)
  const posts = ref(initPostState.posts)
  const postsByHashtag = ref(initPostState.postsByHashtag)
  const postsBySearch = ref(initPostState.postsBySearch)
  const postsByHashtagRanking = ref(initPostState.postsByHashtagRanking)
  const userPosts = ref(initPostState.userPosts)
  const post = ref(initPostState.post)
  const commentsAndReplies = ref(initPostState.commentsAndReplies)
  const previewPost = ref(initPostState.previewPost)
  const tempList = ref(initPostState.tempList)
  const hashtagControl = ref(initPostState.hashtagControl)
  const creators = ref(initPostState.creators)
  const premiumPosts = ref(initPostState.premiumPosts)

  /**
   * 카테고리 조회
   * @param cashing
   */
  const fetchCategories = async (cashing = true) => {
    const { data } = await PostService.fetchCategories(cashing)
    categories.value = data
    return data
  }

  /**
   * 관리자 지정 해시태그 목록 조회
   * @param cashing
   */
  const fetchBaseHashtags = async (cashing = true) => {
    const { data } = await PostService.fetchBaseHashtags(cashing)
    baseHashtags.value = data
    return data
  }

  /**
   * 해시태그 조건으로 포스트 5건 조회
   * @param payload
   */
  const fetchPostsByHashtagRanking = async (
    payload: PostType.HashtagPostsPayload
  ) => {
    const { data } = await PostService.fetchPostsByHashtagRanking(payload)
    postsByHashtagRanking.value = data
  }

  /**
   * 포스트 상세 조회
   * @param payload
   * @param updateStore
   */
  const fetchPost = async (
    payload: PostType.PostPayload,
    updateStore = true
  ) => {
    const { data } = await PostService.fetchPost(payload)

    // 포스트 목록과 포스트 상세의 싱크를 맞추기 위해 목록 업데이트
    const replace = {
      rdCount: data.rdCount, // 조회수
      shCount: data.shCount, // 공유수
      rcCount: data.rcCount, // 좋아요수
      answerCount: data.answerCount, // 댓글수
      expectRward: data.expectRward, // 예상보상
      totalRward: data.totalRward, // 게시물 총 보상
    }
    updatePostModel({ cmtyNttSn: payload.cmtyNttSn, replace })

    if (updateStore) {
      post.value = data
    }

    return data
  }

  /**
   * 포스트 상세 모델 업데이트
   */
  const updatePost = (payload: PostType.Post) => {
    post.value = payload

    // 포스트 목록과 포스트 상세의 싱크를 맞추기 위해 목록 업데이트
    const replace = {
      rdCount: payload.rdCount, // 조회수
      shCount: payload.shCount, // 공유수
      rcCount: payload.rcCount, // 좋아요수
      answerCount: payload.answerCount, // 댓글수
      expectRward: payload.expectRward, // 예상보상
      totalRward: payload.totalRward, // 게시물 총 보상
    }
    updatePostModel({ cmtyNttSn: payload.cmtyNttSn, replace })
  }

  /**
   * 포스트 페이로드 업데이트
   * @param payload
   */
  const updatePostsPayload = (payload: PostType.PostsPayload) => {
    Object.keys(payload).forEach((key) => {
      if (typeof payload[key] === 'undefined' || payload[key] === null) {
        delete payload[key]
      }
    })

    posts.value.payload = payload
  }

  /**
   * 포스트 목록 조회
   * @description 페이징 처리 필요
   * @param payload
   * @param refresh
   * @param postsType
   */
  const fetchPosts = async (
    payload: PostType.PostsPayload,
    refresh = false,
    postsType: PostType.PostsListType = 'default'
  ) => {
    // method: 로딩
    const setLoading = (loading: boolean) => {
      switch (postsType) {
        case 'feed':
          feedPosts.value.loading = loading
          break
        case 'default':
          posts.value.loading = loading
          break
        case 'hashtag':
          postsByHashtag.value.loading = loading
          break
        case 'search':
          postsBySearch.value.loading = loading
          break
        default:
          throw new Error('fetchPosts action postsType is not match')
      }
    }

    // method: 오버라이더
    const overrider = async (
      _posts: BaseInfinityScrollList<
        PostType.PostsItem[],
        PostType.PostsPayload
      >
    ) => {
      // 1. set loading
      setLoading(true)

      try {
        // 2. fetch posts
        const { data, headers } = await PostService.fetchPosts({
          ...payload,
          pageNum: refresh ? 1 : payload.pageNum + 1,
        })
        let items: PostType.PostsItem[]

        // 3. fetch posts
        if (refresh) {
          items = data
        } else {
          items = _posts.items.concat(
            data.filter(
              (item) =>
                _posts.items.findIndex(
                  (origin) => origin.cmtyNttSn === item.cmtyNttSn
                ) === -1
            )
          )
        }

        // 4. create override
        const override = {
          items,
          payload: {
            ...payload,
            pageNum: refresh ? 1 : payload.pageNum + 1,
            lastCmtyNttSn: items.length ? items[items.length - 1].cmtyNttSn : 0,
          },
          refresh,
          last: typeof headers['pagination-location'] === 'undefined',
        }

        // 추천순
        // 5. override variable
        if (payload.orderBy === 'for-you') {
          override.payload.randSeed = items[items.length - 1]?.randSeed || ''
          override.payload.nttRecomendStdrDt =
            items[items.length - 1]?.nttRecomendStdrDt || ''
        }

        // 오더 순서에서 hot이 빠지면서 필요없게 됨: 추후 hot이 추가되면 아래의 소스 활용
        // 5. override variable
        // if (payload.orderBy === 'hot')
        //   override.payload.lastSumSvcActScore =
        //     items[items.length - 1]?.sumSvcActScore || 0

        // 6. set override to state
        switch (postsType) {
          case 'feed':
            feedPosts.value = override
            break
          case 'default':
            posts.value = override
            break
          case 'hashtag':
            postsByHashtag.value = override
            break
          case 'search':
            postsBySearch.value = override
            break
          default:
            throw new Error('fetchPosts action postsType is not match')
        }
      } catch (err) {
        return Promise.reject(err)
      } finally {
        // 7. set loading
        setLoading(false)
      }
    }

    // 1. 중복 호출을 막기위해 로딩중이면 이벤트 막음
    if (
      (postsType === 'feed' && feedPosts.value.loading) ||
      (postsType === 'default' && posts.value.loading) ||
      (postsType === 'hashtag' && postsByHashtag.value.loading) ||
      (postsType === 'search' && postsBySearch.value.loading)
    )
      return

    // 2. 포스트 리스트 조회시 refresh 일때 필요없는 조회 조건 삭제
    if (refresh) {
      delete payload.lastCmtyNttSn
      delete payload.lastSumSvcActScore
      delete payload.randSeed
      delete payload.nttRecomendStdrDt
    }

    // 3. 각 포스트별 포스트 리스트 조회 후 오버라이드 호출
    switch (postsType) {
      case 'feed':
        await overrider(feedPosts.value)
        break
      case 'default':
        await overrider(posts.value)
        break
      case 'hashtag':
        await overrider(postsByHashtag.value)
        break
      case 'search':
        await overrider(postsBySearch.value)
        break
      default:
        throw new Error('fetchPosts action postsType is not match')
    }
  }

  /**
   * 포스트 좋아요 하기
   * @param payload
   * @param successPostRecommend
   */
  const reqPostRecommend = async (
    payload: PostType.PostActionPayload,
    successPostRecommend: PostType.RecommendAndUnRecommendSuccess
  ) => {
    await PostService.reqPostRecommend(payload)
    updatePostModel({
      cmtyNttSn: payload.cmtyNttSn,
      replace: successPostRecommend,
    })

    // 좋아요 카운트 업데이트
    myPageStore.updateMyActivitiesCount({ target: 'recommend', increment: 1 })
  }

  /**
   * 포스트 좋아요 취소하기
   * @param payload
   * @param successPostRecommend
   */
  const reqPostRecommendCancel = async (
    payload: PostType.PostActionPayload,
    successPostRecommend: PostType.RecommendAndUnRecommendSuccess
  ) => {
    await PostService.reqPostRecommendCancel(payload)

    // 포스트 목록, 상세 업데이트
    updatePostModel({
      cmtyNttSn: payload.cmtyNttSn,
      replace: successPostRecommend,
    })

    // 좋아요 카운트 업데이트
    myPageStore.updateMyActivitiesCount({ target: 'recommend', increment: -1 })
  }

  /**
   * 포스트 싫어요 하기
   * @param payload
   * @param successPostRecommend
   */
  const reqPostUnRecommend = async (
    payload: PostType.PostActionPayload,
    successPostRecommend: PostType.RecommendAndUnRecommendSuccess
  ) => {
    await PostService.reqPostUnRecommend(payload)

    // 포스트 목록 및 상세 모델 업데이트
    updatePostModel({
      cmtyNttSn: payload.cmtyNttSn,
      replace: successPostRecommend,
    })

    // 카운트 업데이트
    myPageStore.updateMyActivitiesCount({ target: 'recommend', increment: 1 })
  }

  /**
   * 포스트 좋아요 취소하기
   * @param payload
   * @param successPostRecommend
   */
  const reqPostUnRecommendCancel = async (
    payload: PostType.PostActionPayload,
    successPostRecommend: PostType.RecommendAndUnRecommendSuccess
  ) => {
    await PostService.reqPostUnRecommendCancel(payload)
    updatePostModel({
      cmtyNttSn: payload.cmtyNttSn,
      replace: successPostRecommend,
    })
  }

  /**
   * 포스트 스크랩하기
   * @param payload
   */
  const reqPostScrap = async (payload: PostType.PostActionPayload) => {
    await PostService.reqPostScrap(payload)

    // 포스트 목록, 상세 업데이트
    updatePostModel({
      cmtyNttSn: payload.cmtyNttSn,
      replace: { scFlag: '1' },
    })

    // 스크랩 카운트 업데이트
    myPageStore.updateMyActivitiesCount({ target: 'scrap', increment: 1 })
  }

  /**
   * 포스트 스크랩 취소하기
   * @param payload
   */
  const reqPostScrapCancel = async (payload: PostType.PostActionPayload) => {
    await PostService.reqPostScrapCancel(payload)

    // 포스트 목록 상세 업데이트
    updatePostModel({
      cmtyNttSn: payload.cmtyNttSn,
      replace: { scFlag: '0' },
    })

    // 스크랩 카운트 업데이트
    myPageStore.updateMyActivitiesCount({ target: 'scrap', increment: -1 })
  }

  /**
   * 포스트 숨기기
   * @param payload
   */
  const reqPostHide = async (payload: PostType.PostActionPayload) => {
    await PostService.reqPostHide(payload)
    removePost(payload, true)
  }

  /**
   * 포스트 신고하기
   * @param payload
   */
  const reqPostReport = async (payload: PostType.PostReportPayload) => {
    const { status } = await PostService.reqPostReport(payload)

    // 1. 신고 완료
    if (status === 200) {
      updatePostModel({
        cmtyNttSn: payload.cmtyNttSn,
        replace: { reportableAt: 'N' },
      })
    }

    // 2. 신고 완료 + 포스트 제재 > 모든 스토어에서 해당 포스트 삭제
    if (status === 205) {
      removePost({ cmtyNttSn: payload.cmtyNttSn })
    }
  }

  /**
   * 포스트 공유하기
   * @param payload
   * @param updateCount
   */
  const reqPostShare = async (
    payload: PostType.PostActionPayload,
    updateCount: PostType.PostsItem['shCount'] | PostType.Post['shCount']
  ) => {
    await PostService.reqPostShare(payload)
    updatePostModel({
      ...payload,
      replace: { shCount: updateCount },
    })
  }

  /**
   * 포스트 삭제하기
   * @param payload
   */
  const reqPostRemove = async (payload: PostType.PostActionPayload) => {
    await PostService.reqPostRemove(payload)
    removePost(payload)
  }

  /**
   * 포스트 모델 변경(포스트 목록, 포스트 검색 결과 목록, 노바+(프리미엄) 검색 결과 목록, 포스트 해시태그 목록, 포스트 상세, 노바+(프리미엄) 목록, 마이페이지 내부 목록)
   * @param payload
   */
  const updatePostModel = (payload: {
    cmtyNttSn: PostType.PostsItem['cmtyNttSn']
    replace: any
  }) => {
    // 0. 피드 포스트 목록 업데이트
    const feedPostsItemIdx = feedPosts.value.items.findIndex(
      (item) => item.cmtyNttSn === payload.cmtyNttSn
    )
    if (feedPostsItemIdx > -1) {
      feedPosts.value.items[feedPostsItemIdx] = {
        ...feedPosts.value.items[feedPostsItemIdx],
        ...payload.replace,
      }
    }

    // 1. 포스트 목록 업데이트
    const postsItemIdx = posts.value.items.findIndex(
      (item) => item.cmtyNttSn === payload.cmtyNttSn
    )
    if (postsItemIdx > -1) {
      posts.value.items[postsItemIdx] = {
        ...posts.value.items[postsItemIdx],
        ...payload.replace,
      }
    }

    // 2. 검색 결과 포스트 목록 업데이트
    const postsSearchItemIdx = postsBySearch.value.items.findIndex(
      (item) => item.cmtyNttSn === payload.cmtyNttSn
    )
    if (postsSearchItemIdx > -1) {
      postsBySearch.value.items[postsSearchItemIdx] = {
        ...postsBySearch.value.items[postsSearchItemIdx],
        ...payload.replace,
      }
    }

    // 3. 해시태그 포스트 목록 업데이트
    const postsHashtagItemIdx = postsByHashtag.value.items.findIndex(
      (item) => item.cmtyNttSn === payload.cmtyNttSn
    )
    if (postsHashtagItemIdx > -1) {
      postsByHashtag.value.items[postsHashtagItemIdx] = {
        ...postsByHashtag.value.items[postsHashtagItemIdx],
        ...payload.replace,
      }
    }

    // 4. 크리에이터 홈(hot) 포스트 목록 업데이트
    const creatorPostsByHotItemIdx = creatorStore.postsByHot.items.findIndex(
      (item) => item.cmtyNttSn === payload.cmtyNttSn
    )
    if (creatorPostsByHotItemIdx > -1) {
      creatorStore.postsByHot.items[creatorPostsByHotItemIdx] = {
        ...creatorStore.postsByHot.items[creatorPostsByHotItemIdx],
        ...payload.replace,
      }
    }

    // 5. 크리에이터 홈(contents) 포스트 목록 업데이트
    const creatorPostsByContentsItemIdx =
      creatorStore.contentsList.items.findIndex(
        (item) => item.cmtyNttSn === payload.cmtyNttSn
      )
    if (creatorPostsByContentsItemIdx > -1) {
      creatorStore.contentsList.items[creatorPostsByContentsItemIdx] = {
        ...creatorStore.contentsList.items[creatorPostsByContentsItemIdx],
        ...payload.replace,
      }
    }

    // 6. 포스트 상세 업데이트
    if (post.value && post.value.cmtyNttSn === payload.cmtyNttSn) {
      post.value = {
        ...post.value,
        ...payload.replace,
      }
    }

    // 7. 프리미엄(노바+) 포스트 목록 업데이트
    const premiumPostsIdx = premiumPosts.value.items.findIndex(
      (item) => item.cmtyNttSn === payload.cmtyNttSn
    )
    if (premiumPostsIdx > -1) {
      premiumPosts.value.items[premiumPostsIdx] = {
        ...premiumPosts.value.items[premiumPostsIdx],
        ...payload.replace,
      }
    }

    // 8. 프리미엄(노바+) 검색 결과 포스트 목록 업데이트
    const premiumPostsSearchItemIdx = searchStore.premiumPosts.items.findIndex(
      (item) => item.cmtyNttSn === payload.cmtyNttSn
    )
    if (premiumPostsSearchItemIdx > -1) {
      searchStore.premiumPosts.items[premiumPostsSearchItemIdx] = {
        ...searchStore.premiumPosts.items[premiumPostsSearchItemIdx],
        ...payload.replace,
      }
    }

    // 8. 마이페이지 포스트 목록 업데이트 액션 훅
    myPageStore.updatePostModel(payload)
  }

  /**
   * 포스트 등록
   * @param payload
   */
  const createPost = async (payload: PostType.CreatePostPayload) => {
    const { data } = await PostService.createPost(payload)

    // 이미지 리사이즈를 기다리기 위한 상태 주입(클라이언트에서만 사용하는 모델)
    data.isUpdateBefore = true

    // 포스트 리스트의 카테고리가 등록된 포스트의 카테고리와 다를 경우 리스트에 추가하지 않음
    if (feedPosts.value.payload.cmtyNttCtgrySn !== data.cmtyNttCtgrySn) return

    // 일반 포스트는 피드에 추가
    if (data.prmbrshCntntsAt !== 'Y') {
      // 포스트 목록이 없을때
      if (!feedPosts.value.items.length) {
        feedPosts.value.items.unshift(data)
      }

      // 포스트 아이템중 상단 고정 아이템이 있을 경우 그 다음에 추가
      else {
        feedPosts.value.items.some((item, index) => {
          if (item.topFlag === '0' || item.topFlag === null) {
            feedPosts.value.items.splice(index, 0, data)
            return true
          }
          return false
        })
      }
    }

    // 프리미엄 포스트
    else {
      premiumPosts.value.items.unshift(data)
    }
  }

  /**
   * 포스트 수정
   * @param payload
   */
  const editPost = async (payload: PostType.EditPostPayload) => {
    const { data } = await PostService.editPost(payload)

    // 이미지 리사이즈를 기다리기 위한 상태 주입(클라이언트에서만 사용하는 모델)
    data.isUpdateBefore = true

    // 1. 포스트 목록에서 아이템 업데이트
    const postsItemIdx = posts.value.items.findIndex(
      (item) => item.cmtyNttSn === payload.cmtyNttSn
    )
    if (postsItemIdx > -1) {
      posts.value.items[postsItemIdx] = data
    }

    // 2. 포스트 검색 결과 목록에서 아이템 업데이트
    const postsSearchItemIdx = postsBySearch.value.items.findIndex(
      (item) => item.cmtyNttSn === payload.cmtyNttSn
    )
    if (postsSearchItemIdx > -1) {
      postsBySearch.value.items[postsSearchItemIdx] = data
    }

    // 3. 포스트 해시태그 목록에서 아이템 업데이트
    const postsHashtagItemIdx = postsByHashtag.value.items.findIndex(
      (item) => item.cmtyNttSn === payload.cmtyNttSn
    )
    if (postsHashtagItemIdx > -1) {
      postsByHashtag.value.items[postsHashtagItemIdx] = data
    }

    // 4. 마이페이지 하위 포스트 목록에서 아이템 삭제(마이페이지에서는 숨기기시 삭제 하지 않는다)
    myPageStore.updatePostModel({
      cmtyNttSn: data.cmtyNttSn,
      replace: data,
    })

    // 5. 크리에이터 홈(hot) 아이템 업데이트
    const postsCreatorItemByHotIdx = creatorStore.postsByHot.items.findIndex(
      (item) => item.cmtyNttSn === payload.cmtyNttSn
    )
    if (postsCreatorItemByHotIdx > -1) {
      creatorStore.postsByHot.items[postsCreatorItemByHotIdx] = data
    }

    // 6. 크리에이터 홈(콘텐츠) 아이템 업데이트
    const postsCreatorItemByContentsIdx =
      creatorStore.contentsList.items.findIndex(
        (item) => item.cmtyNttSn === payload.cmtyNttSn
      )
    if (postsCreatorItemByContentsIdx > -1) {
      creatorStore.contentsList.items[postsCreatorItemByContentsIdx] = data
    }

    // 7. 포스트 상세 업데이트
    if (post.value?.cmtyNttSn === payload.cmtyNttSn) {
      post.value = {
        ...post.value,
        ...data,
      } as PostType.Post
    }

    // 피드 목록 업데이트
    const feedPostsItemIdx = feedPosts.value.items.findIndex(
      (item) => item.cmtyNttSn === payload.cmtyNttSn
    )
    if (feedPostsItemIdx > -1) {
      feedPosts.value.items[feedPostsItemIdx] = data
    }
  }

  /**
   * 임시 포스트 등록
   * @param payload
   */
  const createTempPost = (payload: PostType.CreateTempPostPayload) =>
    PostService.createTempPost(payload)

  /**
   * 임시 포스트 목록 조회
   * @param payload
   * @param refresh
   */
  const fetchTempPosts = async (
    payload: PostType.TempPostsPayload,
    refresh = false
  ) => {
    const { data, headers } = await PostService.fetchTempPosts({
      ...payload,
      pageNum: refresh ? 1 : payload.pageNum + 1,
    })

    tempList.value = {
      items: refresh ? data : tempList.value.items.concat(data),
      payload: {
        ...payload,
        pageNum: refresh ? 1 : payload.pageNum + 1,
        pageSize: refresh
          ? initPostState.tempList.payload.pageSize
          : payload.nextPageSize,
      },
      refresh,
      last: typeof headers['pagination-location'] === 'undefined',
    }
  }

  /**
   * 임시 포스트 삭제
   * @param payload
   */
  const deleteTempPost = async (payload: PostType.TempPostDeletePayload) => {
    await PostService.deleteTempPost(payload)
    await fetchTempPosts(initPostState.tempList.payload, true)
  }

  /**
   * 임시 포스트 상세 조회
   * @param payload
   */
  const fetchTempPost = async (payload: PostType.TempPostPayload) => {
    const { data } = await PostService.fetchTempPost(payload)
    return data
  }

  /**
   * 이미지 업로드
   * @param payload
   */
  const uploadTempImg = async (payload: FormData) => {
    try {
      const { data } = await PostService.uploadTempImg(payload)
      return data
    } catch (err) {
      return Promise.reject(err)
    }
  }

  /**
   * 포스트 삭제 & 숨기기(스토어에서)
   * @param payload
   * @param hide
   */
  const removePost = (payload: PostType.PostActionPayload, hide = false) => {
    // 1. 포스트 목록에서 아이템 삭제
    const postsItemIdx = posts.value.items.findIndex(
      (item) => item.cmtyNttSn === payload.cmtyNttSn
    )
    if (postsItemIdx !== -1) {
      posts.value.items.splice(postsItemIdx, 1)
    }

    // 2. 포스트 검색 결과 목록에서 아이템 삭제
    const postsSearchItemIdx = postsBySearch.value.items.findIndex(
      (item) => item.cmtyNttSn === payload.cmtyNttSn
    )
    if (postsSearchItemIdx !== -1) {
      postsBySearch.value.items.splice(postsSearchItemIdx, 1)
    }

    // 3. 포스트 해시태그 목록에서 아이템 삭제
    const postsHashtagItemIdx = postsByHashtag.value.items.findIndex(
      (item) => item.cmtyNttSn === payload.cmtyNttSn
    )
    if (postsHashtagItemIdx !== -1) {
      postsByHashtag.value.items.splice(postsHashtagItemIdx, 1)
    }

    // 4. 마이페이지 하위 포스트 목록에서 아이템 삭제(마이페이지에서는 숨기기시 삭제 하지 않는다)
    if (!hide) {
      myPageStore.removePost(payload)
    }

    // 5. 크리에이터 홈(hot) 아이템 삭제
    const postsCreatorItemByHotIdx = creatorStore.postsByHot.items.findIndex(
      (item) => item.cmtyNttSn === payload.cmtyNttSn
    )
    if (postsCreatorItemByHotIdx !== -1) {
      creatorStore.postsByHot.items.splice(postsCreatorItemByHotIdx, 1)
    }

    // 6. 크리에이터 홈(콘텐츠) 아이템 삭제
    const postsCreatorItemByContentsIdx =
      creatorStore.contentsList.items.findIndex(
        (item) => item.cmtyNttSn === payload.cmtyNttSn
      )
    if (postsCreatorItemByContentsIdx !== -1) {
      creatorStore.contentsList.items.splice(postsCreatorItemByContentsIdx, 1)
    }

    // 7. 포스트 상세 삭제
    if (post.value?.cmtyNttSn === payload.cmtyNttSn) {
      initPost()
    }

    // 8. 프리미엄(노바+) 아이템 삭제
    const premiumPostItemIdx = premiumPosts.value.items.findIndex(
      (item) => item.cmtyNttSn === payload.cmtyNttSn
    )
    if (premiumPostItemIdx !== -1) {
      premiumPosts.value.items.splice(premiumPostItemIdx, 1)
    }

    const feedPostItemIdx = feedPosts.value.items.findIndex(
      (item) => item.cmtyNttSn === payload.cmtyNttSn
    )
    if (feedPostItemIdx !== -1) {
      feedPosts.value.items.splice(feedPostItemIdx, 1)
    }

    // 9. 마이페이지 스토에어 좋아요, 댓글, 스크랩 카운트 업데이트
    // 삭제 후 추천, 댓글, 스크랩 카운트 조회
    myPageStore.fetchRecommendCount()
    myPageStore.fetchCommentCount()
    myPageStore.fetchScrapCount()
  }

  /**
   * 포스트 상세 초기화(스토어에서)
   */
  const initPost = () => {
    post.value = initPostState.post
  }

  /**
   * 댓글 & 답글 목록 조회
   * @param payload
   * @param refresh
   */
  const fetchCommentsAndReplies = async (
    payload: PostType.CommentsAndRepliesPayload,
    refresh = false
  ) => {
    const { data, headers } = await PostService.fetchCommentsAndReplies({
      ...payload,
      pageNum: refresh ? 1 : payload.pageNum + 1,
    })

    // 댓글 & 답글 순서별로 필요한 렌더링 조건 추가
    let replace: PostType.CommentAndReply[] = []
    if (data.length) {
      replace = calcCommentsAndReplies(
        refresh ? data : commentsAndReplies.value.items.concat(data),
        payload.cmtyNttSn
      )
    }

    // 댓글 & 답글 목록 업데이트
    commentsAndReplies.value = {
      items: replace,
      payload: { ...payload, pageNum: refresh ? 1 : payload.pageNum + 1 },
      refresh,
      last: typeof headers['pagination-location'] === 'undefined',
    }

    return replace
  }

  /**
   * 댓글 & 답글 목록 초기화(스토어에서)
   */
  const initCommentsAndReplies = () => {
    commentsAndReplies.value = { ...initPostState.commentsAndReplies }
  }

  /**
   * 댓글 & 답글 작성
   * @param payload
   */
  const reqCommentAdd = async (payload: PostType.CommentAddPayload) => {
    const { data } = await PostService.reqCommentAdd(payload)
    const replace = [...commentsAndReplies.value.items]

    // 이미지 리사이즈 로딩을 지정하기 위한 모델 주입
    data.isUpdateBefore = true

    // 1. 댓글일때 배열 최상위 추가 및 카운터 변경
    if (!payload.nttParntsAnswerSn) {
      replace.unshift(data)

      updatePostModel({
        cmtyNttSn: payload.cmtyNttSn,
        replace: { answerCount: post.value!.answerCount + 1 },
      })
    }

    // 2. 부모 댓글의 답글 맨 마지막에 추가 혹은 답글일때 부모 댓글의 다음
    else {
      const replyLastIdx = replace
        .map((item) => item.nttParntsAnswerSn)
        .lastIndexOf(payload.nttParntsAnswerSn!)

      // 2-1. 부모 댓글의 답글들이 존재할때 맨 마지막에 추가
      if (replyLastIdx !== -1) {
        replace[replyLastIdx].replyreAt = 'Y'
        replace.splice(replyLastIdx + 1, 0, data)
      }

      // 2-2. 부모 댓글의 답글이 존재하지 않을때 부모 댓글
      else {
        const commentIdx = replace.findIndex(
          (item) => item.cmtyNttAnswerSn === payload.nttParntsAnswerSn
        )
        replace.splice(commentIdx + 1, 0, data)
      }
    }

    // 3. 최종 순서 변경한 후 업데이트
    commentsAndReplies.value.items = calcCommentsAndReplies(
      replace,
      payload.cmtyNttSn
    )

    // 4. 댓글 카운트 업데이트
    myPageStore.updateMyActivitiesCount({ target: 'comment', increment: 1 })
  }

  /**
   * 댓글 & 답글 삭제
   * @param payload
   * @param callApi - 삭제 API 호출 없이 모델만 업데이트 조건
   * @param updatePostPayload - 포스트 댓글 수 업데이트를 위한 페이로드
   */
  const deleteComment = async (
    payload: PostType.CommentRemovePayload,
    callApi = true,
    updatePostPayload?: PostType.CommentRemoveUpdatePostPayload
  ) => {
    if (!callApi) return
    const { data } = await PostService.deleteComment(payload)
    if (data.replyCount === 0) {
      const postItem = commentsAndReplies.value.items.find(
        (item) => item.cmtyNttAnswerSn === payload.cmtyNttAnswerSn
      )
      const mypageItem = myPageStore.commentPosts.items.find(
        (item) => item.cmtyNttSn === postItem?.cmtyNttSn
      )
      const index =
        typeof mypageItem === 'undefined'
          ? -1
          : myPageStore.commentPosts.items.indexOf(mypageItem)
      if (index !== -1) {
        myPageStore.commentPosts.items.splice(index, 1)
      }
    }
    commentsAndReplies.value.items.some((item) => {
      if (item.cmtyNttAnswerSn === payload.cmtyNttAnswerSn) {
        item.deleteAt = 'Y'
        return true
      }
      return false
    })
    // 포스트 목록, 상세 업데이트
    updatePostPayload &&
      updatePostModel({
        cmtyNttSn: updatePostPayload.cmtyNttSn,
        replace: { answerCount: updatePostPayload.answerCount - 1 },
      })
    // 댓글 카운트 업데이트
    myPageStore.updateMyActivitiesCount({ target: 'comment', increment: -1 })
  }

  /**
   * 댓글 & 답글 수정
   * @param payload
   */
  const editComment = async (payload: PostType.CommentEditPayload) => {
    const { data } = await PostService.editComment(payload)

    // 이미지 리사이즈 로딩을 지정하기 위한 모델 주입
    data.isUpdateBefore = true

    // 수정된 정보 업데이트
    const replaceTargetIdx = commentsAndReplies.value.items.findIndex(
      (item) => item.cmtyNttAnswerSn === data.cmtyNttAnswerSn
    )
    if (replaceTargetIdx !== -1) {
      commentsAndReplies.value.items[replaceTargetIdx] = {
        ...commentsAndReplies.value.items[replaceTargetIdx],
        ...data,
      }
    }
  }

  /**
   * 댓글 & 답글 좋아요
   * @param payload
   * @param successCommentRecommend
   */
  const reqCommentRecommend = async (
    payload: PostType.CommentRecommendPayload,
    successCommentRecommend: PostType.RecommendAndUnRecommendSuccess
  ) => {
    await PostService.reqCommentRecommend(payload)
    updateCommentRecommend(payload.cmtyNttAnswerSn, successCommentRecommend)
  }

  /**
   * 댓글 & 답글 좋아요 취소
   * @param payload
   * @param successCommentRecommend
   */
  const reqCommentRecommendCancel = async (
    payload: PostType.CommentRecommendPayload,
    successCommentRecommend: PostType.RecommendAndUnRecommendSuccess
  ) => {
    await PostService.reqCommentRecommendCancel(payload)
    updateCommentRecommend(payload.cmtyNttAnswerSn, successCommentRecommend)
  }

  /**
   * 댓글 & 답글 싫어요
   * @param payload
   * @param successCommentRecommend
   */
  const reqCommentUnRecommend = async (
    payload: PostType.CommentRecommendPayload,
    successCommentRecommend: PostType.RecommendAndUnRecommendSuccess
  ) => {
    await PostService.reqCommentUnRecommend(payload)
    updateCommentRecommend(payload.cmtyNttAnswerSn, successCommentRecommend)
  }

  /**
   * 댓글 & 답글 싫어요 취소
   * @param payload
   * @param successCommentRecommend
   */
  const reqCommentUnRecommendCancel = async (
    payload: PostType.CommentRecommendPayload,
    successCommentRecommend: PostType.RecommendAndUnRecommendSuccess
  ) => {
    await PostService.reqCommentUnRecommendCancel(payload)
    updateCommentRecommend(payload.cmtyNttAnswerSn, successCommentRecommend)
  }

  /**
   * 댓글 & 답글 좋아요, 싫어요 상태 업데이트
   */
  const updateCommentRecommend = (
    cmtyNttAnswerSn: PostType.CommentAndReply['cmtyNttAnswerSn'],
    updateValue: PostType.RecommendAndUnRecommendSuccess
  ) => {
    const replaceIdx = commentsAndReplies.value.items.findIndex(
      (item) => item.cmtyNttAnswerSn === cmtyNttAnswerSn
    )
    const replaceTarget = commentsAndReplies.value.items[replaceIdx]

    commentsAndReplies.value.items[replaceIdx] = {
      ...replaceTarget,
      ...updateValue,
      recommendCount: updateValue.rcCount,
    }
  }

  /**
   * 댓글 & 답글 숨기기
   * @param payload
   */
  const reqCommentHide = async (payload: PostType.CommentHidePayload) => {
    await PostService.reqCommentHide(payload)
    // this.updateCommentsAndRepliesUnit(payload.cmtyNttAnswerSn, { key: 'blind', value: 'Y' })

    commentsAndReplies.value.items.some((item) => {
      if (item.cmtyNttAnswerSn === payload.cmtyNttAnswerSn) {
        item.bkFlag = '1'
        return true
      }
      return false
    })
  }

  /**
   * 댓글 & 답글 신고하기
   * @param payload
   */
  const reqCommentReport = async (payload: PostType.CommentReportPayload) => {
    const { status } = await PostService.reqCommentReport(payload)

    // 신고 완료 상태 업데이트
    if (status === 200) {
      commentsAndReplies.value.items.some((item) => {
        if (item.cmtyNttAnswerSn === payload.cmtyNttAnswerSn) {
          item.reportableAt = 'N'
          return true
        }
        return false
      })
    }

    // 신고 완료 + 제재된 댓글 & 답글
    if (status === 205) {
      await deleteComment({ cmtyNttAnswerSn: payload.cmtyNttAnswerSn }, false)
    }
  }

  /**
   * 댓글 & 답글 리스트에서 동적으로 추가가 이뤄지면 순서별 렌더링 타입을 위한 모델 계산
   * @param comments
   * @param cmtyNttSn
   */
  const calcCommentsAndReplies = (
    comments: PostType.CommentAndReply[],
    cmtyNttSn: number
  ) => {
    // 댓글 & 답글 중 답글이 존재하는지 여부를 판단하고 답글 여부 추가
    const hasRepliesItemSns = [
      ...new Set(
        comments
          .filter((item) => item.nttParntsAnswerSn)
          .map((item) => item.nttParntsAnswerSn)
      ),
    ]

    comments.forEach((item) => {
      item.cmtyNttSn = cmtyNttSn
      item.lastReply = false
      item.hasReplies = hasRepliesItemSns.includes(item.cmtyNttAnswerSn)
    })

    // 답글중 마지막인지 판단하고 마지막 답글 여부 추가(배열의 뒤에서 부터 검사)
    comments.reduce((acc, cur, index) => {
      if (
        comments.length !== index + 1 &&
        acc.nttParntsAnswerSn &&
        !cur.nttParntsAnswerSn
      ) {
        acc.lastReply = true
      }

      if (comments.length === index + 1 && cur.nttParntsAnswerSn) {
        cur.lastReply = true
      }

      return cur
    })

    return comments
  }

  /**
   * 해시태그에 의한 포스트 목록을 조회 하기 위해 해시태그 변경
   * @param hashtag
   */
  const updateHashtagControl = (hashtag: Hashtag) => {
    hashtagControl.value.hashtag = hashtag
  }

  /**
   * 크리에이터 목록 조회
   */
  const fetchCreators = async (
    payload: PostType.CreatorsPayload,
    refresh = false
  ) => {
    if (refresh) {
      delete payload.lastOrderScore
      delete payload.lastUserSn
    }

    const { data, headers } = await PostService.fetchCreators({
      ...payload,
      pageNum: refresh ? 1 : payload.pageNum + 1,
    })

    creators.value = {
      items: refresh ? data : creators.value.items.concat(data),
      payload: {
        ...payload,
        pageNum: refresh ? 1 : payload.pageNum + 1,
        lastOrderScore: data.length ? data[data.length - 1].orderScore : 0,
        lastUserSn: data.length ? data[data.length - 1].userSn : 0,
      },
      refresh,
      last: typeof headers['pagination-location'] === 'undefined',
    }
  }

  /**
   * 팔로우 / 언팔로우 업데이트
   */
  const updatePostFollowUnfollow = (payload: {
    userSn: number
    follow: boolean
  }) => {
    // 1. 크리에이터 카드 업데이트
    const updateTarget = creators.value.items.findIndex(
      (creator) => creator.userSn === payload.userSn
    )

    if (updateTarget !== -1) {
      creators.value.items[updateTarget].followFlag = payload.follow ? '1' : '0'
      creators.value.items[updateTarget].followerCount =
        creators.value.items[updateTarget].followerCount +
        (payload.follow ? +1 : -1)
    }

    // 2. 포스트 목록 업데이트
    posts.value.items.forEach((item) => {
      if (item.userSn === payload.userSn) {
        item.followFlag = payload.follow ? '1' : '0'
      }
    })

    // 2. 검색 결과 포스트 목록 업데이트
    postsBySearch.value.items.forEach((item) => {
      if (item.userSn === payload.userSn) {
        item.followFlag = payload.follow ? '1' : '0'
      }
    })

    // 3. 해시태그 포스트 목록 업데이트
    postsByHashtag.value.items.forEach((item) => {
      if (item.userSn === payload.userSn) {
        item.followFlag = payload.follow ? '1' : '0'
      }
    })

    // 4. 포스트 상세 업데이트
    if (post.value && post.value.userSn === payload.userSn) {
      post.value = {
        ...post.value,
        followFlag: payload.follow ? '1' : '0',
      }
    }

    // 5. 프리미엄(노바+) 목록 업데이트
    premiumPosts.value.items.forEach((item) => {
      if (item.userSn === payload.userSn) {
        item.followFlag = payload.follow ? '1' : '0'
      }
    })

    // 6. 피드 목록 업데이트
    feedPosts.value.items.forEach((item) => {
      if (item.userSn === payload.userSn) {
        item.followFlag = payload.follow ? '1' : '0'
      }
    })
  }

  /**
   * check video thumbnail
   * @param url
   */
  const checkThumbnail = (url: string) => PostService.checkThumbnail(url)

  /**
   * 프리미엄 포스트 목록 조회
   * @param payload
   * @param refresh
   * @param getAllPremiumPosts
   */
  const fetchPremiumPosts = async (
    payload: PostType.PremiumPostsPayload,
    refresh = false,
    getAllPremiumPosts = false
  ) => {
    // 1. 중복 호출을 막기위해 로딩중이면 이벤트 막음
    if (!refresh && premiumPosts.value.loading) return

    // 2-1. 인기순 정렬 조회일때 마지막 인기순 기준 점수 삽입
    if (payload.orderBy === 'hot')
      payload.lastSumSvcActScore =
        premiumPosts.value.items[premiumPosts.value.items.length - 1]
          ?.sumSvcActScore || 0

    // 2-2. 포스트 리스트 조회시 refresh 일때 필요없는 조회 조건 삭제
    if (refresh) {
      delete payload.lastCmtyNttSn
      delete payload.lastSumSvcActScore
    }

    // 2-3. 특정 크리에이터가 작성한 프리미엄 콘텐츠를 조회할지 사용자가 구독중인 모든 크리에이터가 작성한 프리미엄 콘텐츠를 조회할지 조건 삭제
    if (getAllPremiumPosts) {
      delete payload.creatorUserSn
    }

    // 3. 중복 호출을 리턴시키기 위해 로딩상태 업데이트
    premiumPosts.value.loading = true

    // 4. 프리미엄 포스트 목록 조회
    const { data, headers } = await PostService.fetchPremiumPosts({
      ...payload,
      pageNum: refresh ? 1 : payload.pageNum + 1,
    })

    // 5. 조회 조건에 따라 프리미엄 포스트 목록 준비
    const items = refresh
      ? data
      : premiumPosts.value.items.concat(
          data.filter(
            (item) =>
              premiumPosts.value.items.findIndex(
                (origin) => origin.cmtyNttSn === item.cmtyNttSn
              ) === -1
          )
        )

    // 6. 프리미엄 포스트 목록 상태 업데이트
    premiumPosts.value = {
      items,
      payload: {
        ...payload,
        pageNum: refresh ? 1 : payload.pageNum + 1,
        lastCmtyNttSn: items.length ? items[items.length - 1].cmtyNttSn : 0,
      },
      refresh,
      loading: false,
      last: typeof headers['pagination-location'] === 'undefined',
    }
  }

  /**
   * 프리미엄 포스트 목록 초기화
   */
  const initPremiumPosts = () => {
    premiumPosts.value = initPostState.premiumPosts
  }

  /**
   * 포스팅 후원
   * @param payload
   */
  const reqPostingDonation = (payload: PostType.PostingDonationPayload) =>
    PostService.reqPostingDonation(payload)

  /**
   * 포스트 발행 가능 여부 조회(카테고리 기준)
   */
  const fetchPostCreateAvailable = async (
    payload: PostType.PostCreateAvailablePayload
  ) => {
    const { data } = await PostService.fetchPostCreateAvailable(payload)
    return data
  }

  return {
    categories,
    baseHashtags,
    feedPosts,
    posts,
    postsByHashtag,
    postsBySearch,
    postsByHashtagRanking,
    userPosts,
    post,
    commentsAndReplies,
    previewPost,
    tempList,
    hashtagControl,
    creators,
    premiumPosts,
    fetchCategories,
    fetchBaseHashtags,
    fetchPostsByHashtagRanking,
    fetchPost,
    updatePost,
    updatePostsPayload,
    fetchPosts,
    reqPostRecommend,
    reqPostRecommendCancel,
    reqPostUnRecommend,
    reqPostUnRecommendCancel,
    reqPostScrap,
    reqPostScrapCancel,
    reqPostHide,
    reqPostReport,
    reqPostShare,
    reqPostRemove,
    updatePostModel,
    createPost,
    editPost,
    createTempPost,
    fetchTempPosts,
    deleteTempPost,
    fetchTempPost,
    uploadTempImg,
    removePost,
    initPost,
    fetchCommentsAndReplies,
    initCommentsAndReplies,
    reqCommentAdd,
    deleteComment,
    editComment,
    reqCommentRecommend,
    reqCommentRecommendCancel,
    reqCommentUnRecommend,
    reqCommentUnRecommendCancel,
    updateCommentRecommend,
    reqCommentHide,
    reqCommentReport,
    calcCommentsAndReplies,
    updateHashtagControl,
    fetchCreators,
    updatePostFollowUnfollow,
    checkThumbnail,
    fetchPremiumPosts,
    initPremiumPosts,
    reqPostingDonation,
    fetchPostCreateAvailable,
  }
})
