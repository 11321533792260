import { commonError } from './commonError'

const notificationType = {
  push: 'Push',
  email: 'E-mail',
  sms: 'SMS',
}

const pushDialog = {
  title: 'Push Notification',
  contents:
    '<p style="font-size: 16px; font-weight: 500; line-height: 1.2; text-align: center;">You can see Service Notification<br />when you open the {appName} App,<br />while not receiving Push Notification.</p><p style="font-size: 12px; text-align: center; margin-top: 4px;">*Even if you turn off  notifications, essential service updates may still be sent to you.</p>',
  confirm: 'OK',
}

const serviceDialog = {
  title: 'Service Notification',
  contents:
    'Without turning Service Notification on,<br />new notifications will not be recorded<br />in the notification box.<br />Would you like to keep receiving?',
  cancel: 'No, Turn OFF',
  confirm: 'Yes',
}

const marketingDialog = {
  title: 'Marketing Notification({type})',
  contents:
    'You can get information of {appName}’s events<br />and benefits without missing it.<br />Would you like to maintain the consent?',
  cancel: 'No, Turn OFF',
  confirm: 'Yes',
}

const notificationMarketingAgreeDialog = {
  title: 'Would you like to receive Marketing Push Notification?',
  message:
    ' You can get information of events and benefits<br />without missing it by APP Push!',
  messageSm:
    '* Consent of receiving Marketing Push Notifications can be changed<br />in I LIKE LM App Settings > Notification Settings',
  actions: {
    action: 'Next time',
    positive: 'Yes, I will receive it',
  },
  errors: {
    7005: 'Unable to save acceptance of marketing push notification. Please try again later.',
    7006: 'Unable to save acceptance of marketing push notification. Please try again later.',
    default: commonError.network.message,
  },
}

const toastMessage = {
  marketing: {
    agree:
      'You consented to receive {appName} Marketing Notification({type}). ({date})',
    deny: 'You refused to receive {appName} Marketing Notification({type}). ({date})',
  },
}

export const notificationSetting = {
  notificationAndSetting: 'Notification Settings',
  selectNotificationSound: 'Select Notification Sound',
  goDetailSetting: 'Details',
  marketingAgreeY: 'Consent to receive marketing information ({date})',
  marketingAgreeN: 'Refuse to receive marketing information ({date})',
  marketingPushTime: 'Send Time: 8AM~9PM(KST)',
  notificationType,
  pushDialog,
  serviceDialog,
  marketingDialog,
  notificationMarketingAgreeDialog,
  toastMessage,
}
