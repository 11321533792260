/**
 * Represents the block access information.
 */
export const blockAccess = {
  modal: {
    title: 'Suspension Notice',
    contents: {
      activity: 'Users under suspension are unable to use the service.',
      signin:
        'Your account is currently suspended. During this suspension period, activities such as writing posts, comments, likes, etc., are restricted.<br /><br />Once the suspension is lifted, you will be able to resume using the community.<br /><br />Suspension period: {period}',
    },
  },
  eternalBlocked: 'Permanent suspension',
}
