<script setup lang="ts">
import type { NovaBoxPostCommentReplayWithMetasProps } from './NovaBoxPostCommentReplayWithMetas.types'

const props = withDefaults(
  defineProps<NovaBoxPostCommentReplayWithMetasProps>(),
  {
    forceHidAddComment: false,
  }
)
const showReply = ref(false)

watch(
  () => props.forceHidAddComment,
  (cur) => {
    if (cur) {
      showReply.value = false
    }
  }
)

const handleOnToggleShowReply = () => {
  if (props.forceHidAddComment) {
    showReply.value = false
    return
  }
  showReply.value = !showReply.value
}
</script>

<template>
  <div class="reply-with-metas">
    <div class="meta-bar">
      <div class="metas">
        <button
          type="button"
          :class="['btn-toggle-show-reply', { on: showReply }]"
          @click="handleOnToggleShowReply"
        >
          <span class="label">{{ $t('doReply') }}</span>
          <NovaIcon
            :icon="{ type: 'outline', name: 'chev-bottom' }"
            :size="12"
            class="indicator"
          />
        </button>

        <NovaBoxPostActionRecommend
          :recommend-target-type="'comment'"
          :recommend-target="source.cmtyNttAnswerSn"
          :rc-flag="source.rcFlag"
          :dv-flag="source.dvFlag"
          :recommend-count="source.recommendCount"
          :recommend-user-list="source.recommendUserList"
          :view-type="viewType"
        />
      </div>

      <div v-if="source.recommendUserList.length" class="metas">
        <NovaRecommendUsers
          :recommend-users="source.recommendUserList"
          :recommend-count="source.recommendCount"
          :view-type="'comment'"
        />
      </div>
    </div>

    <NovaBoxPostCommentAdd
      v-if="showReply"
      :parent-comment="source"
      @on-comment-cancel="handleOnToggleShowReply"
    />
  </div>
</template>

<style lang="scss" scoped>
.reply-with-metas {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .meta-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    height: 32px;

    .metas {
      display: flex;
      align-items: center;
      gap: 20px;

      @include mobile {
        gap: 4px;
      }

      .btn-toggle-show-reply {
        display: flex;
        align-items: center;
        gap: 10px;
        height: 32px;
        padding: 0 10px;

        &.on {
          > .indicator {
            transform: rotate(180deg);
            color: $color-secondary-red-light-80;
          }
        }

        > .label {
          @include text-style($text-body-12-medium);
          line-height: 16px;
          color: $color-text-2;
        }

        > .indicator {
          color: $color-text-2;
          @include transition(
            transform 0.2s ease-in-out,
            color 0.2s ease-in-out
          );
        }
      }
    }
  }
}
</style>
