<script setup lang="ts">
import type { NotificationType } from '@store'
import type { TabDataMutable, TabData } from '@components/NovaTab/NovaTab.types'

type NotificationTab = TabDataMutable<TabData<NotificationType>>

const { gtEvent } = useGoogleTag()
const { t, locale, messages } = useI18n()
const etcStore = useEtcStore()
const tabs = computed(
  () =>
    Object.keys(
      (messages.value[locale.value].notificationsPanel as any).tabs
    ).map((key) => ({
      id: key,
      name: t(`notificationsPanel.tabs.${key}`),
    })) as NotificationTab[]
)
const initTab = ref(tabs.value[0].id)
const fetchNotificationsStatus = reactive({
  NOT_PLS: {
    loading: false,
    error: false,
  },
  PLS: {
    loading: false,
    error: false,
  },
})
const ready = ref(false)

onBeforeMount(() => {
  fetchNotifications(true, initTab.value)
})

onMounted(async () => {
  await nextTick()
  setTimeout(() => {
    ready.value = true
  }, 1000)
})

// TODO: 앱 버전 릴리즈를 위해 기능 숨김
// tab 변경
// const onChangeTab = async (tab: NotificationTab) => {
//   initTab.value = tab.id
//   await fetchNotifications(true, tab.id)
// }

// 알림 목록 조회
const fetchNotifications = async (
  refresh = false,
  type: NotificationType,
  isRecycle?: boolean
) => {
  try {
    if (refresh) {
      ready.value = false
    }
    fetchNotificationsStatus[type].loading = true
    fetchNotificationsStatus[type].error = false
    console.log(etcStore.notifications[type].payload)
    if (isRecycle) {
      gtEvent('scrollEvent', {
        eventCategory: '더보기',
        eventAction: '알림 더보기',
        eventLabel: 'loadMore',
        eventSlot: '알림 패널',
        eventI18nAddr: 'loadMore',
        eventComponent: 'RecyclerView',
        ntcnSvcTyCode: etcStore.notifications[type].payload.ntcnSvcTyCode,
        pageSize: etcStore.notifications[type].payload.pageSize,
      })
    }
    await etcStore.fetchNotifications(
      etcStore.notifications[type].payload,
      refresh
    )
  } catch {
    fetchNotificationsStatus[type].error = true
    useToast(t('statusMessages.notification.error'))
  } finally {
    if (refresh) {
      ready.value = true
    }
    fetchNotificationsStatus[type].loading = false
  }
}
</script>

<template>
  <div class="notification-list-box">
    <!-- TODO: 앱 버전 릴리즈를 위해 기능 숨김 -->
    <!--    <NovaTab
      v-if="!(appStore.isApp || appStore.envMode === 'production')"
      :tab-value="tabs"
      :init-tab="initTab"
      :align="'left'"
      :horizon-padding="20"
      :size="40"
      class="tabs"
      @on-change-tab="onChangeTab"
    />-->

    <div class="notifications">
      <NovaBoxEmptyMessage
        v-if="
          !fetchNotificationsStatus[initTab].loading &&
          !etcStore.notifications[initTab].items.length
        "
        :message="$t('statusMessages.notification.empty')"
        :fill="true"
      />

      <NovaLoadingIndicator
        v-if="
          (fetchNotificationsStatus[initTab].loading &&
            etcStore.notifications[initTab].refresh) ||
          !ready
        "
        :size="80"
        :fill="true"
        :bg-bright="'light'"
      />

      <NovaRecyclerGrid
        v-if="ready"
        :items="etcStore.notifications[initTab].items"
        :is-last="etcStore.notifications[initTab].last"
        :is-loading="fetchNotificationsStatus[initTab].loading"
        :is-error="fetchNotificationsStatus[initTab].error"
        :is-refresh="etcStore.notifications[initTab].refresh"
        :key-name="'userNtcnSn'"
        :responsive="{
          mobile: { columns: 1, rowGap: 12, columnGap: 12 },
          tablet: { columns: 1, rowGap: 12, columnGap: 12 },
          desktop: { columns: 1, rowGap: 12, columnGap: 12 },
        }"
        :page-mode="false"
        :bottom-white-space="0"
        @load-more="() => fetchNotifications(false, initTab, true)"
      >
        <template #item="{ item, index }">
          <NovaHeaderNotificationTabsItem
            :notification="item"
            :is-last="
              etcStore.notifications[initTab].items.length - 1 === index
            "
            :notification-type-code="initTab"
          />
        </template>
      </NovaRecyclerGrid>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.notification-list-box {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 400px;

  .tabs {
    flex-shrink: 0;
    flex-grow: initial;
  }

  .notifications {
    flex-grow: 1;
    overflow: hidden;
  }

  @include mobile {
    .notification-list-box {
      flex-grow: 1;
      height: 100%;
      max-height: initial;
    }
  }
}
</style>
