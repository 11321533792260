import FundService from './service'
import * as FundType from './type'
import { initFundState } from './init'

export * from './init'
export * from './type'
export const useFundStore = defineStore('fund', () => {
  const fundBanner = ref(initFundState.fundBanner)
  const fundSurvey = ref(initFundState.fundSurvey)
  const fundProject = ref(initFundState.fundProject)
  const fundHistories = ref(initFundState.fundHistories)

  /**
   * 펀드 이달의 프로젝트 배너 조회
   */
  const fetchFundBanner = async () => {
    const { data } = await FundService.fetchFundBanner()
    fundBanner.value = data
  }

  /**
   * 펀드 이달의 프로젝트 프로젝트 조회
   */
  const fetchFundSurvey = async (
    payload: FundType.FundSurveyPayload,
    refresh = true
  ) => {
    // 중복 호출 막음
    if (fundSurvey.value.loading) return

    fundSurvey.value.loading = true

    const _payload = {
      ...payload,
      pageNum: refresh ? 1 : payload.pageNum + 1,
    }

    const { data } = await FundService.fetchFundSurvey(_payload)

    fundSurvey.value.data = data
    fundSurvey.value.payload = _payload

    fundSurvey.value.loading = false
    fundSurvey.value.last = true

    return fundSurvey.value
  }

  /**
   * 펀드 이달의 프로젝트 상세 조회
   */
  const fetchFundProject = async (payload: FundType.FundProjectPayload) => {
    const { data } = await FundService.fetchFundProject(payload)
    fundProject.value = data
    return data
  }

  /**
   * 펀드 프로젝트 투표
   */
  const reqFundProjectVote = async (
    payload: FundType.FundProjectVotePayload
  ) => {
    await FundService.reqFundProjectVote(payload)
    await fetchFundSurvey(fundSurvey.value.payload)

    // 상세 투표 상테 업데이트
    if (
      fundProject.value &&
      fundProject.value.crfdSurveyPrjctSn === payload.crfdSurveyPrjctSn
    ) {
      fundProject.value.lastSvcActCode = payload.svcActCode
    }

    // 목록 상태 업데이트
    if (fundSurvey.value.data) {
      fundSurvey.value.data.tbvaCrfdSuptSurveyPrjctInfoList.some(
        (item, index) => {
          if (item.crfdSurveyPrjctSn === payload.crfdSurveyPrjctSn) {
            fundSurvey.value.data!.tbvaCrfdSuptSurveyPrjctInfoList[
              index
            ].lastSvcActCode = payload.svcActCode
            return true
          }
          return false
        }
      )
    }
  }

  /**
   * 펀드 히스토리 목록 조회
   */
  const fetchFundHistories = async (
    payload: FundType.FundHistoriesPayload,
    refresh = false
  ) => {
    // 중복 호출 막음
    if (fundHistories.value.loading) return

    const _payload = {
      ...payload,
      pageNum: refresh ? 1 : payload.pageNum + 1,
    }
    const { data, headers } = await FundService.fetchFundHistories(_payload)
    fundHistories.value = {
      items: refresh ? data : fundHistories.value.items.concat(data),
      payload: _payload,
      refresh,
      last: typeof headers['pagination-location'] === 'undefined',
    }
  }

  return {
    fundBanner,
    fundSurvey,
    fundProject,
    fundHistories,
    fetchFundBanner,
    fetchFundSurvey,
    fetchFundProject,
    reqFundProjectVote,
    fetchFundHistories,
  }
})
