<script setup lang="ts">
import type { NovaModalPostCreateEditPlaceholderProps } from './NovaModalPostCreateEditPlaceholder.types'
import { SpecialCategoryCode } from '@store'

defineProps<NovaModalPostCreateEditPlaceholderProps>()

const { t } = useI18n()
</script>

<template>
  <div class="edit-custom-placeholder">
    <div v-if="postCategoryType === 'requireCategory'" class="inner">
      <p v-dompurify-html="t('postCreate.placeholder.edit.requireCategory')" />
    </div>

    <div
      v-else-if="postCategoryType === SpecialCategoryCode.CREATOR_CONTENTS"
      class="inner"
    >
      <p
        v-dompurify-html="t('postCreate.placeholder.edit.creatorContents.p18')"
        class="p-18"
      />
      <p
        v-dompurify-html="t('postCreate.placeholder.edit.creatorContents.p15')"
        class="p-15 mg-t-5u"
      />
      <p
        v-dompurify-html="t('postCreate.placeholder.edit.creatorContents.p12')"
        class="p-10 mg-t-10u"
      />
    </div>

    <div
      v-else-if="postCategoryType === SpecialCategoryCode.FREE_FORUM"
      class="inner"
    >
      <p v-dompurify-html="t('postCreate.placeholder.edit.free')" />
    </div>

    <div
      v-else-if="postCategoryType === SpecialCategoryCode.NOVA_CHALLENGE"
      class="inner"
    >
      <p v-dompurify-html="t('postCreate.placeholder.edit.novaChallenge')" />
    </div>

    <div v-else class="inner" />
  </div>
</template>

<style lang="scss" scoped>
.edit-custom-placeholder {
  @include text-style($text-body-14-regular);
  line-height: 20px;
  letter-spacing: -0.05em;
  color: $color-text-3;

  .inner {
    .p {
      &-18 {
        font-size: 18px;
      }
      &-15 {
        font-size: 15px;
      }
      &-12 {
        font-size: 12px;
      }
    }
  }
}
</style>
