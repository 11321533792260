/**
 * 주어진 URL이 HTTP 프로토콜을 사용하는지 확인
 *
 * @param {string} url - 확인할 URL
 * @returns {boolean} - URL이 HTTP 프로토콜을 사용하면 true, 그렇지 않은 경우 false를 반환
 */
export const useCheckHttpProtocol = (url: string): boolean => {
  const regex = /(http(s?)):\/\//i
  return regex.test(url)
}
