<script setup lang="ts">
import { EditorContent, useEditor } from '@tiptap/vue-3'
import { mergeAttributes, Node } from '@tiptap/core'
import Document from '@tiptap/extension-document'
import Heading from '@tiptap/extension-heading'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import TextStyle from '@tiptap/extension-text-style'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Underline from '@tiptap/extension-underline'
import Strike from '@tiptap/extension-strike'
import TextAlign from '@tiptap/extension-text-align'
import { Color } from '@tiptap/extension-color'
import Highlight from '@tiptap/extension-highlight'
import Image from '@tiptap/extension-image'
import Link from '@tiptap/extension-link'
import ListItem from '@tiptap/extension-list-item'
import OrderedList from '@tiptap/extension-ordered-list'
import BulletList from '@tiptap/extension-bullet-list'
import Placeholder from '@tiptap/extension-placeholder'
import CharacterCount from '@tiptap/extension-character-count'
import type {
  CommentAction,
  NovaTipTapEmits,
  NovaTipTapProps,
} from './NovaTipTap.types'
import type { TipTapExtractImage } from '@composables/useExtractElementFromString.types'
import VideoReadyImage from '@assets/images/image-uploading.png'
interface CustomImageAttrs {
  src: string
  alt?: string
  title?: string
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    customImage: {
      setCustomImage: (attributes: CustomImageAttrs) => ReturnType
    }
  }
}

const emit = defineEmits<NovaTipTapEmits>()
const props = withDefaults(defineProps<NovaTipTapProps>(), {
  modelValue: '',
  placeholder: '',
  mode: 'post',
  isLoading: false,
  disabled: false,
})

const slots = useSlots()
const { t } = useI18n()
const { modelValue, mode, placeholder } = toRefs(props)
const state = reactive({
  currentText: '',
  currentCount: 0,
  imageCount: 0,
})
const initComment = ref(props.modelValue)
const checkTextByte = computed(() =>
  useCheckByte(state.currentText, 5000, 'under')
)
const limitText = computed(() =>
  checkTextByte.value ? 10000 : state.currentCount
)
const commentActions = computed<CommentAction[]>(() => {
  switch (mode.value) {
    case 'comment':
      return [
        {
          label: t('cancelReply'),
          theme: 'transparent',
          action: handleOnCancelReply,
        },
        {
          label: t('doComment'),
          theme: 'primary-blue',
          action: handleOnPushEditorContent,
        },
      ]
    case 'reply':
      return [
        {
          label: t('cancelReply'),
          theme: 'transparent',
          action: handleOnCancelReply,
        },
        {
          label: t('doReply'),
          theme: 'primary-blue',
          action: handleOnPushEditorContent,
        },
      ]
    case 'editComment':
      return [
        {
          label: t('cancelComment'),
          theme: 'transparent',
          action: handleOnCancelEdit,
        },
        {
          label: t('editComment'),
          theme: 'primary-blue',
          action: handleOnPushEditorContent,
        },
      ]
    case 'editReply':
      return [
        {
          label: t('cancelReply'),
          theme: 'transparent',
          action: handleOnCancelEdit,
        },
        {
          label: t('editReply'),
          theme: 'primary-blue',
          action: handleOnPushEditorContent,
        },
      ]
    case 'post':
    default:
      return []
  }
})

const customImage = Node.create({
  name: 'customImage',

  inline: true,
  group: 'inline',
  draggable: true,

  parseHTML() {
    return [
      {
        tag: this.name,
      },
    ]
  },
  renderHTML({ HTMLAttributes }) {
    const newAttr = {
      ...HTMLAttributes,
    }
    return ['img', mergeAttributes(newAttr)]
  },

  addAttributes() {
    return {
      src: {
        default: null,
      },
      alt: {
        default: null,
      },
      title: {
        default: null,
      },
    }
  },

  addNodeView() {
    return ({ node }) => {
      let retryCount = 0
      const maxRetries = 4
      const retryDelay = 1000
      const container = document.createElement('p')
      const defaultImg = document.createElement('img')
      const img = document.createElement('img')
      defaultImg.src = VideoReadyImage
      img.src = node.attrs.src

      const removeTimestampFromUrl = (url: string) => {
        return url.replace(/\?t=\d+$/, '')
      }
      const updateLoadingState = (loading: boolean, error: boolean) => {
        handleOnTipTapLoading(loading)
        if (error) {
          useToast(t('postCreate.toastMessage.imgError'))
        }
      }
      const loadImage = () => {
        img.onload = () => {
          setDefaultImage(false)
          updateLoadingState(false, false)
          editor
            .value!.chain()
            .focus('end')
            .createParagraphNear()
            .insertContent('<p></p>')
            .run()
          setTimeout(() => editor.value!.commands.focus('end'), 300)
          const loadedImg = document.createElement('img')
          loadedImg.src = removeTimestampFromUrl(img.src)
          container.appendChild(loadedImg)
        }
        img.onerror = () => {
          retryCount++
          if (retryCount <= maxRetries) {
            setTimeout(() => {
              const newTimestamp = new Date().getTime()
              // 기존 이미지 소스에 타임 스탬프 추가 > 업데이트 실행 되도록
              img.src = `${node.attrs.src}?t=${newTimestamp}`
            }, retryDelay * retryCount)
          } else {
            setDefaultImage(false)
            updateLoadingState(false, true)
          }
        }
      }

      const setDefaultImage = (set: boolean) => {
        if (set && !container.contains(defaultImg)) {
          container.appendChild(defaultImg)
        } else if (!set && container.contains(defaultImg)) {
          container.removeChild(defaultImg)
        }
      }
      updateLoadingState(true, false)
      setDefaultImage(true)
      loadImage()

      return {
        dom: container,
        contentDOM: container,
        update: (updatedNode) => {
          if (updatedNode.attrs.src !== node.attrs.src) {
            loadImage()
          }
          return updatedNode.type === node.type
        },
      }
    }
  },

  addCommands() {
    return {
      setCustomImage:
        (attributes: CustomImageAttrs) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: attributes,
          })
        },
    }
  },
})

const editor = useEditor({
  content: modelValue.value,
  enableInputRules: false,
  enablePasteRules: false,
  extensions: [
    Document,
    Paragraph,
    Text,
    TextStyle,
    customImage,
    Italic.configure({
      HTMLAttributes: {
        class: 'editor-italic',
      },
    }),
    Heading.configure({
      levels: [1, 2, 3],
    }),
    Bold.configure({
      HTMLAttributes: {
        class: 'editor-bold',
      },
    }),
    Underline.configure({
      HTMLAttributes: {
        class: 'editor-underline',
      },
    }),
    Strike,
    TextAlign.configure({
      types: ['heading', 'paragraph'],
    }),
    Color,
    Highlight.configure({
      multicolor: true,
      HTMLAttributes: {
        class: 'editor-highlight',
      },
    }),
    Image.configure({
      inline: true,
    }),
    Link.configure({
      openOnClick: false,
    }),
    ListItem,
    OrderedList.configure({
      itemTypeName: 'listItem',
    }),
    BulletList.configure({
      itemTypeName: 'listItem',
    }),
    CharacterCount.configure({
      limit: limitText.value,
    }),
    Placeholder.configure({
      placeholder:
        (!slots['custom-placeholder'] && String(placeholder.value)) || '',
    }),
  ],
  onUpdate: (evt) => {
    emit('update:modelValue', evt.editor.getHTML())
    state.currentText = evt.editor.getText()
    state.currentCount = evt.editor.storage.characterCount.characters()
    evt.editor.options.extensions.find(
      (item) => item.name === 'characterCount'
    )!.options.limit = limitText.value

    const cntCount = useExtractElementFromString(evt.editor.getHTML(), 'count')
    state.imageCount = typeof cntCount === 'number' ? cntCount : 0
  },
})

watch(modelValue, (current) => {
  const html = editor.value?.getHTML()
  if (typeof html === 'string' && html !== current) {
    editor.value!.commands.setContent(current, true)
  }
})

watch(
  () => placeholder.value,
  (cur) => {
    const placeholderExtIdx = editor.value!.options.extensions.findIndex(
      (item) => item.name === 'placeholder'
    )
    editor.value!.options.extensions[placeholderExtIdx].options.placeholder =
      cur
  }
)

const handleOnPushEditorContent = () => {
  const data = editor.value!.getHTML()
  const isEmpty = editor.value!.isEmpty

  emit(
    'pushEditorContents',
    isEmpty
      ? { data: '', images: [] }
      : {
          data,
          images: (
            useExtractElementFromString(data, 'both') as TipTapExtractImage
          ).url,
        },
    () => {
      editor.value!.commands.setContent('', true)
    }
  )
}

const handleOnCancelReply = () => {
  editor.value!.commands.setContent('', true)
  emit('update:modelValue', '')
  emit('cancelReply')
}

const handleOnCancelEdit = () => {
  editor.value!.commands.setContent(initComment.value, true)
  emit('update:modelValue', initComment.value)
  emit('onEditMode', false)
}

const handleOnTipTapLoading = (loading: boolean) => {
  emit('onLoading', loading)
}
</script>

<template>
  <div
    :class="[
      'tip-tap',
      {
        'comment-mode':
          mode === 'comment' ||
          mode === 'reply' ||
          mode === 'editComment' ||
          mode === 'editReply',
      },
      { disabled },
    ]"
  >
    <div v-if="isLoading" class="dim">
      <NovaLoadingIndicator :bg-bright="'light'" />
    </div>

    <NovaTipTapMenu
      v-if="editor"
      :editor="editor"
      :image-count="state.imageCount"
      @on-loading="handleOnTipTapLoading"
    />

    <div class="inner">
      <EditorContent
        v-if="editor"
        :editor="editor"
        class="tip-tap-editor-content"
      />

      <div v-if="mode !== 'post'" class="actions">
        <NovaButtonText
          v-for="(commentAction, index) in commentActions"
          :key="index"
          :label="commentAction.label"
          :theme="commentAction.theme"
          :size="32"
          @click.stop="commentAction.action"
        />
      </div>

      <div
        v-if="slots['custom-placeholder'] && !state.currentText"
        class="custom-placeholder"
      >
        <slot name="custom-placeholder" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tip-tap {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  &.disabled {
    pointer-events: none;
    opacity: 0.6;
  }

  &.comment-mode {
    :deep(.editor-menu-bar) {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      border-top: 1px solid $border-1;
      border-left: 1px solid $border-1;
      border-right: 1px solid $border-1;
    }

    > .inner {
      height: 127px;
      padding: 12px 0;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      border-left: 1px solid $border-1;
      border-right: 1px solid $border-1;
      border-bottom: 1px solid $border-1;
      overflow: hidden;

      > .tip-tap-editor-content {
        padding: 0 12px;
        overflow-y: overlay;
      }

      .actions {
        padding: 0 12px;
      }
    }
  }

  > .dim {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: hex-to-rgba($color-bg-3, 0.65);
    z-index: 5;
  }

  .inner {
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px;
    background: $color-white;
    height: 250px;
    overflow-y: overlay;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    > .tip-tap-editor-content {
      flex-grow: 1;
      height: 100%;
    }

    .actions {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 4px;
    }

    .custom-placeholder {
      position: absolute;
      top: 0;
      left: 0;
      padding: 12px;
      pointer-events: none;
    }
  }
}
</style>
