/**
 * 후원하기
 */
export const donateAction = {
  title: '후원하기',
  balance: '보유 잔액',
  donation: '후원금',
  donationPlaceholder: '후원금을 입력해 주세요.',
  wallet: '내 지갑',
  success: '"{creator}"님에게 {donation}LM을 후원하였습니다.',
  error: {
    network: '요청 처리중 오류가 발생하였습니다. 잠시후 다시 시도해주세요.',
    lackOfBalance: '보유 잔액이 부족합니다.',
    codes: {
      2006: '필수갑 누락',
      2028: '잔액 부족',
      2029: 'Transfer 오류',
      2031: '소수점 이하의 LM은 후원할 수 없습니다.',
      4006: '후원하는 사용자가 KYC 미인증',
      4008: '후원하는 사용자가 지값 주소 없음',
      4010: '후원받는 대상자가 크리에이어터가 아님',
      4011: '후원받는 크리에이터가 KYC 미인증',
      4012: '후원바는 크리에이터가 지갑 주소 없음',
    },
  },
}
