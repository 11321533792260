import * as cheerio from 'cheerio'

interface UseReplacePostContentImgsToTempImgs {
  updatedHtml: string
  replaceImgs: Array<{
    idx: number
    orgSrc: string
    repSrc: string
    tempSrc: string
  }>
}

/**
 * 주어진 HTML 문자열 내의 이미지 URL들을 임시 이미지 URL로 교체
 *
 * @param {string} htmlStr - 이미지 태그를 포함한 HTML 문자열
 * @returns {Promise<UseReplacePostContentImgsToTempImgs>} 업데이트된 HTML 문자열과 교체된 이미지들의 배열을 포함하는 객체을 반환
 */
export const useReplacePostContentImgsToTempImgs = async (
  htmlStr: string
): Promise<UseReplacePostContentImgsToTempImgs> => {
  const nuxtApp = useNuxtApp()
  const config = nuxtApp.$config
  const postStore = usePostStore()
  const dom = cheerio.load(htmlStr)
  const imgTags = dom('img')
  const replaceImgs = []

  for (const [idx, img] of Array.from(imgTags).entries()) {
    const orgSrc = dom(img).attr('src') || ''
    let repSrc = ''

    // iOS 에서 사용되는 blob file path 이미지일때 TempImg 화
    if (orgSrc.startsWith('blob:')) {
      const response = await fetch(orgSrc)
      const blob = await response.blob()
      const file = new File([blob], 'blob-image.jpg', {
        type: 'image/jpeg',
        lastModified: Date.now(),
      })
      const formData = new FormData()
      formData.append('imgFile', file)
      repSrc = await postStore.uploadTempImg(formData)
    }

    const tempSrc =
      [orgSrc, repSrc].find((src) => src.includes(config.public.IMAGE_URL)) ||
      ''

    replaceImgs.push({ idx, orgSrc, repSrc, tempSrc })

    if (repSrc) {
      dom(img).attr('src', repSrc)
    }
  }

  return { updatedHtml: dom.html(), replaceImgs }
}
