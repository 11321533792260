import { corporationInfo } from './corporationInfo'
import type {
  CreatorSubscribeAccessCode,
  CreatorSubscribeAccessStatus,
  CreatorSubscribeErrorMessage,
} from '@mixins/useSubscribe'
import type { PremiumPostsOrderBy } from '@store/post/type'

export const subscribe = {
  premiumBrand: '<span class="premium-brand-logo">NOVA<sup>+</sup></span>',
  subscribeGrade: {
    premium: 'Premium',
    novaPlus: 'Nova+',
  },
  subscribeGate: {
    benefitSetting: '{subscribeGrade} Benefit Settings',
    balance: '{subscribeGrade} History',
  },
  subscribeAccessCode: {
    opened: '{subscribeGrade} Open',
    support: '{subscribeGrade} Donation',
    cancel: '{subscribeGrade} Cancellation',
    refund: '{subscribeGrade} Refund',
  } as {
    [Property in CreatorSubscribeAccessCode]: string
  },
  premiumCreators: {
    title: 'MY {premiumBrand} List',
    error:
      'Unable to retrieve the creators I donate to for {subscribeGrade} at the moment. Please try again later.',
    empty: 'There are no creators.',
  },
  creatorsByRecommended: {
    title: 'Recommended creators',
    error: 'Unable to retrieve recommended creators. Please try again.',
    empty: 'There are no recommended creators.',
  },
  premiumPosts: {
    orderBy: {
      newest: 'Newest',
      oldest: 'Oldest',
      hot: 'Hottest',
    } as { [Property in PremiumPostsOrderBy]: string },
    error:
      'The contents of the creators I donate to for {subscribeGrade} cannot be retrieved at the moment. Please try again later.',
    empty:
      'Donate to {subscribeGrade} for the creators you follow and enjoy special content exclusively for supporters!',
    emptyByNoPremium: 'There is no {subscribeGrade} content.',
    requireSignin:
      'Enjoy a variety of content from I LIKE LM creators. <br />{subscribeGrade} is available when logged in.',
  },
  premiumPost: {
    new: 'Publish special content for {subscribeGrade} supporters!',
    empty: 'After opening {subscribeGrade}, you can create posts.',
    blockMessage:
      'This content is only visible to {subscribeGrade} supporters.',
    blockMessageAction: 'Donate to {subscribeGrade}',
  },
  createPremiumPost: {
    requireCreator: {
      title: 'Notifications',
      message:
        'To write a {subscribeGrade} post, you need to switch to being a creator. Would you like to proceed with the switch?',
    },
    requirePremiumOpened: {
      title: 'Notifications',
      message:
        'After opening {subscribeGrade}, you can create posts. Would you like to proceed with opening it?',
      toastMessage: 'After opening {subscribeGrade}, you can create posts. ',
    },
  },
  sections: {
    subscribeSupportDetail: {
      title: '{premiumBrand} Donation Information',
      benefit: "{creatorNickname}'s {premiumBrand} Benefits",
      support: "{creatorNickname}'s {subscribeGrade} monthly donation amount",
      paymentPrice: 'Payment amount',
      vat: 'VAT',
    },
    subscribeCancelDetail: {
      title: '{subscribeGrade} Information',
      benefit: 'Benefits',
      guide:
        'Are you sure you want to cancel these benefits? This {subscribeGrade} is available until {date}!',
    },
    subscribeRefundDetail: {
      title: '{subscribeGrade} Information',
      benefit: 'Benefits',
      guide:
        'Would you like to proceed with a {subscribeGrade} refund? Please note that if you apply for a refund, {subscribeGrade} benefits will be immediately discontinued.',
    },
    cancelReasons: {
      title: 'Reason for Cancelling {subscribeGrade}',
      reasons: {
        expensive: 'The price is too high.',
        technical: 'There are technical issues.',
        interesting: "It's not the content I want to see.",
        useService: "I don't use this service frequently.",
        contentsQuality: 'I am dissatisfied with the content quality.',
        etc: 'Other',
      },
    },
    refundForm: {
      title: 'Refund information entry box',
      form: {
        bank: {
          label: 'Refund bank',
          placeholder: 'Please choose the refund bank.',
        },
        id: {
          label: 'ID',
          placeholder: '',
        },
        bankAccount: {
          label: 'Refund Account',
          placeholder: 'Please enter your refund account information.',
          description:
            'Please enter your account number including hyphens (-).',
        },
        nickname: {
          label: 'Nickname',
          placeholder: '',
          description:
            'You cannot change your nickname while the refund is being processed.',
        },
        bankAccountHolder: {
          label: "Account Holder's name",
          placeholder: "Please enter the account holder's name.",
        },
      },
    },
    openBenefit: {
      title: '{premiumBrand} Benefit Settings',
      benefit: 'Benefits',
      txt: "Please fill out the benefits to be provided to the creator's {premiumBrand} supporter. (1 or more)",
      buttonsAdd: 'Add Benefits',
      guideList: {
        example1: 'Example 1. Upload Original Comic Once a Week',
        example2:
          'Example 2. Serializing Columns Three Times a Week (Topics include videos, books, comics, etc.)',
      },
      benefitKor: 'Creating Benefits(Kor)',
      benefitEng: 'Creating Benefits(Eng)',
      benefitTha: 'Creating Benefits(Thai)',
      benefitSpa: 'Creating Benefits(Spa)',
    },
    payment: {
      title: 'Payment Method',
      subscribePrice: 'Donation Amount',
    },
    guide: {
      title: '{subscribeAccessCode} Notice',
    },
    terms: {
      title: 'Terms and Conditions',
    },
    openedBenefitGuide: {
      title: 'Benefits of Opening {premiumBrand}',
      guide:
        'When {creatorNickname} opens {subscribeGrade}, You can receive 90% of the donation amount from {subscribeGrade} as earnings.',
      benefits: [
        'Steady Income',
        'Communication with Fans',
        'Opportunity for NFT Creation',
      ],
    },
  },
  actions: {
    support: 'Donate to {subscribeGrade}',
    supporting: 'Donating {subscribeGrade}',
    open: 'Open {subscribeGrade}',
    modify: 'Save {subscribeGrade}',
    subscribeCancel: 'Request {subscribeGrade} Termination',
    subscribeContinue: 'Continue Using',
    refund: '{subscribeGrade} Refund Request',
    cancel: 'Cancel',
  },
  errorMessage: {
    requireCreatorInfo: {
      title: 'This creator has either withdrawn or does not exist.',
      message: 'Please try searching for a different creator.',
      actionLabel: 'Go Back',
    },
    requireSignIn: {
      title: 'This page requires login.',
      message: 'Would you like to log in?',
      actionLabel: 'Log In',
    },
    notEqualCreatorUserSn: {
      title: '{subscribeAccessCode} is not possible.',
      message: 'Only the creator themselves can open {subscribeGrade}.',
      actionLabel: 'Go Back',
    },
    yetSubscribeService: {
      title: '{subscribeAccessCode} is not possible.',
      message: 'The creator has not opened {subscribeGrade} yet.',
      actionLabel: 'Go Back',
    },
    requireInfo: {
      title: 'The required information could not be retrieved.',
      message: 'Please try again.',
      actionLabel: 'Please try again',
    },
    requireDifUser: {
      title: '{subscribeAccessCode} is not possible.',
      message: 'Creators cannot {subscribeAccessCode} to themselves.',
      actionLabel: 'Go Back',
    },
    alreadySubscribeSupport: {
      title: 'You are already donating {subscribeGrade}.',
      message: 'You can enjoy {subscribeGrade} until {expireDate}.',
      actionLabel: 'Go Back',
    },
    alreadySubscribeNext: {
      title: 'Cannot donate to {subscribeGrade}.',
      message: 'You are already donating {subscribeGrade} for the next term.',
      actionLabel: 'Go Back',
    },
    unableSubscribeNext: {
      title: 'You are already donating {subscriptionGrade}.',
      message:
        'You can start the next {subscribeGrade} donation from 3 days before the expiration.',
      actionLabel: 'Go Back',
    },
  } as {
    [Property in CreatorSubscribeAccessStatus]: CreatorSubscribeErrorMessage
  },
  success: {
    opened: {
      open: '{subscribeGrade} has been successfully opened.',
      modify: '{subscribeGrade} settings have been saved successfully.',
    },
  },
  apiError: {
    support: {
      5300: 'There is no information available for the {subscribeGrade} membership program of the creator.',
      6001: 'The NFT Checkout Page call has failed.',
    },
    opened: {
      passBenefits: 'The benefit content must be at least 2 characters long.',
      open: 'Failed to open {subscribeGrade}. Please try again later.',
      modify:
        'Failed to save {subscribeGrade} settings. Please try again later.',
    },
  },
  supportPayProcess: {
    success: {
      title: 'Notification',
      message: 'Your {subscribeGrade} donation has been completed.',
    },
    fail: {
      title: 'Notification',
      message:
        'Your {subscribeGrade} donation was not completed.</br>Please try again.</br>Reason: {failMessage}',
      failMessage: {
        fail: 'Your {subscribeGrade} donation was not completed.</br>Please try again.',
        requireRetry:
          'The service is currently unavailable. Please try again later.',
        serverTimeout:
          'The payment time has been exceeded. Please try again for the {subscribeGrade} donation.',
        clientTimeout: `There was a problem with the payment. Please try again.<br /><br />If you have any questions, please contact <a href="mailto:${corporationInfo.infos.email}">${corporationInfo.infos.email}</a>`,
      },
    },
    loading: 'Checking payment information.',
  },
  expiredNotification: {
    title:
      "{creatorNickname}'s {subscribeGrade} donation benefits have expired.",
    description:
      'If you are interested in donating to support {subscribeGrade} content for another month, please apply to donate again.',
    support: 'Donate to {subscribeGrade} ',
    confirm: 'Confirm',
  },
}
