export const home = {
  creator: '관심있는 크리에이터를 만나보세요!',
  myCreator: '나의 크리에이터',
  seeMore: '더보기',
  apply: '신청하기',
  details: '상세보기 ',
  novaPick: 'I LIKE LM’s P!CK',
  brandProject: 'NGO·브랜드·프로젝트 ',
  topBannerError: '배너 목록을 가져올 수 없습니다. 잠시 후 다시 시도해 주세요.',
  pickError:
    'I LIKE LM’s P!CK 목록을 가져올 수 없습니다. 잠시 후 다시 시도해 주세요.',
  creatorCategoriesError:
    '카테고리 목록을 가져올 수 없습니다. 잠시 후 다시 시도해 주세요.',
  creatorCategoriesEmpty: '카테고리 목록이 없습니다.',
  creatorError:
    '크리에이터 목록을 가져올 수 없습니다. 잠시 후 다시 시도해 주세요.',
}
