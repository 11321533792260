export const home = {
  creator: 'Explore Your Favorite Creators!',
  myCreator: 'My Creator',
  seeMore: 'More',
  apply: 'Apply',
  details: 'Details',
  novaPick: 'I LIKE LM’s P!CK',
  brandProject: 'NGO·Brand·Project',
  topBannerError:
    "We couldn't show the Banner right now. Please try again later.",
  pickError:
    "We couldn't show the 'I LIKE LM’s P!CK' right now. Please try again later.",
  creatorCategoriesError:
    "We couldn't show the Categorie right now. Please try again later.",
  creatorCategoriesEmpty: 'No categories exists.',
  creatorError:
    "We couldn't show the Creator right now. Please try again later.",
}
