/**
 * 값이 비어 있는지 확인
 *
 * @param {any} value - 확인할 값
 * @returns {boolean} - 값이 비어 있는지 나타내는 불리언 값
 */
export const useCheckEmpty = (value: any): boolean =>
  value === '' ||
  value === null ||
  value === undefined ||
  value.trim().length === 0 ||
  (typeof value === 'object' && !Object.keys(value).length)
