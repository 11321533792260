<script setup lang="ts">
import type { NovaUserCellEmits, NovaUserCellProps } from './NovaUserCell.types'

const emit = defineEmits<NovaUserCellEmits>()
const props = withDefaults(defineProps<NovaUserCellProps>(), {
  portrait: null,
  userName: '',
  lock: false,
  onUserPanel: false,
  responsive: false,
  postCreateAt: '',
  postViewCount: 0,
  userSn: 0,
  creatorLink: '',
  alignVertical: false,
  viewType: null,
  lockGoUserHome: false,
  cmtyUserSeCode: null,
})

const { gtEvent } = useGoogleTag()
const slots = useSlots()
const layoutStore = useLayoutStore()
const route = useRoute()
const { hide: modalHide } = useModal()

const handleOnToggleUserPanel = () => {
  emit('toggleUserPanel')
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: `유저 패널 ${layoutStore.layers.length ? '열기' : '닫기'}`,
    eventLabel: '',
    eventSlot: '헤더',
    eventI18nAddr: '',
    eventComponent: 'Button',
    userName: props.userName,
  })
}

const handleOnGoUserHome = async () => {
  if (props.lockGoUserHome) {
    return
  }

  if (
    (props.creatorLink && props.creatorLink === route.params.id) ||
    (!props.creatorLink && props.userName === route.params.id)
  ) {
    return
  }

  if (props.viewType === 'detail') {
    await modalHide(modalsName.MODAL_POST_DETAIL)
  }
  await useGoUserHome(props.userSn)
}
</script>

<template>
  <div
    :class="[
      'user-cell',
      { on: slots['user-panel'] && onUserPanel },
      { responsive },
    ]"
  >
    <div
      :class="['user-info-wrap', { 'click-able': slots['user-panel'] }]"
      @click.capture="handleOnToggleUserPanel"
    >
      <div class="user-info">
        <NovaPortraitContainer
          :image-url="portrait"
          :size="'sm'"
          @click.stop="handleOnGoUserHome"
        />

        <div class="more-info" @click.stop="handleOnGoUserHome">
          <div
            v-if="userName"
            :class="['user-name-wrap', { 'align-vertical': alignVertical }]"
          >
            <span class="user-name">
              {{ userName }}
            </span>
            <div v-if="lock" class="lock">
              <NovaIcon
                :icon="{ type: 'outline', name: 'lock' }"
                :size="14"
                class="icon"
              />
            </div>

            <span v-if="postCreateAt" class="create-at">
              {{ postCreateAt }}
            </span>
          </div>
        </div>
      </div>

      <NovaIcon
        v-show="slots['user-panel']"
        :icon="{ type: 'outline', name: 'carrot-bottom' }"
        :size="20"
        class="user-panel-indicator"
      />
    </div>

    <slot name="user-panel" />
  </div>
</template>

<style lang="scss" scoped>
.user-cell {
  display: flex;
  align-items: center;

  &.on {
    > .user-info-wrap > .user-panel-indicator {
      transform: rotate(180deg);
    }
  }

  .user-info-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    &.click-able {
      cursor: pointer;
    }

    > .user-info {
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;

      .more-info {
        .user-name-wrap {
          display: flex;
          align-items: center;
          gap: 6px;

          &.align-vertical {
            flex-direction: column;
            align-items: flex-start;
            gap: 2px;
          }

          .row {
            display: flex;
            align-items: center;
          }

          .user-name {
            flex-grow: 1;
            //@include ellipsis(1);
            @include text-style($text-body-14-bold);
            word-break: break-all;
          }

          .lock {
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 16px;
            height: 16px;
            border-radius: 100%;
            background-color: $color-secondary-blue-light-10;

            .icon {
              color: $color-secondary-blue-light-80;
            }
          }
        }

        .create-at {
          @include text-style($text-body-11-medium);
          color: $color-text-3;
        }
      }
    }

    .user-panel-indicator {
      color: $color-text-4;
      @include transition(transform 0.2s ease-in-out);
    }
  }

  @include tablet {
    &.responsive {
      > .user-info-wrap {
        > .user-info {
          .more-info {
            .user-name-wrap {
              display: none;
            }
          }
        }

        > .user-panel-indicator {
          display: none !important;
        }
      }
    }
  }

  @include mobile {
    &.responsive {
      > .user-info-wrap {
        > .user-info {
          .portrait-container {
            width: 25px;
            height: 25px;

            :deep(.image-container .empty-image.portrait) {
              background-size: 12px auto;
            }
          }
          > .user-name-wrap {
            display: none;
          }
        }
      }
    }
  }
}
</style>
