/**
 * auction
 */
export const auction = {
  topBanner: {
    text: "※ If you click on the top banner, it will take you to the 'Phone Bidding Application'",
    alt: 'The Oulim ART NFT BIDDING EVENT. DATE 2023. 10. 26. ~ 11.2.',
  },
  displayCount: 'Hits',
  auctionError: 'Unable to retrieve the list of works. Please try again later.',
  auctionEmpty: 'There is no list.',
  auctionDetailError: 'Unable to retrieve the works. Please try again later.',
  auctionDetainNavigationBack: 'View list',
}
