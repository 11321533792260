/**
 * 공통 에러
 */
export const commonError = {
  401: {
    title: '인증오류',
    message: '서비스 연결이 종료되었습니다.<br />잠시 후 다시 시도해 주세요.',
  },
  403: {
    title: '접근 권한 제한',
    message:
      '해당 페이지의 접근권한이 없습니다.<br />관리자에게 문의해 주세요.',
  },
  404: {
    title: '화면 접근 오류',
    message:
      '해당 페이지를 찾을 수 없습니다.<br />페이지를 확인 후 다시 이용해 주세요.',
  },
  500: {
    title: '서버 점검 중',
    message: '서버가 잠시 점검중입니다.<br />잠시 후 다시 시도해 주세요',
  },
  502: {
    title: '서버 점검 중',
    message: '서버가 잠시 점검중입니다.<br />잠시 후 다시 시도해 주세요',
  },
  505: {
    title: '서버 점검 중',
    message: '서버가 잠시 점검중입니다.<br />잠시 후 다시 시도해 주세요',
  },
  4002: {
    title: '찾을 수 없는 회원입니다.',
    message: '탈퇴 했거나 없는 회원입니다. 다른 회원을 찾아주시기 바랍니다.',
  },
  1998: {
    title: '',
    message: '추천순 피드를 업데이트 합니다.',
  },
  default: {
    title: '알수 없는 오류가 발생하였습니다.',
    message: '잠시 후 다시 시도해 주세요.',
  },
  network: {
    title: '네트워크 오류',
    message: '서비스가 원활하지 않습니다. 잠시 후에 다시 시도해 주세요.',
  },
}
