import type { SponsorSttusCode, PgPaymeantCode } from '@store'

/**
 * 후원자 목록, 후원 목록
 */
export const sponsors = {
  topButton: {
    creatorTitle: "Creator's supporter",
    userTitle: 'Donating {subscribeGrade}',
  },
  search: {
    creatorTitle: '{premiumBrand} Supporter List',
    userTitle: '{premiumBrand} Donation History',
    placeholder: 'Search by unique code and nickname',
    orderBy: {
      newest: 'Total',
      odrQty: 'Number of donations',
    },
    filterType: {
      all: 'Total',
      Day30: 'Recent 30days',
      error: 'Please select a date',
    },
  },
  creatorTable: {
    headerSn: 'Unique code',
    headerNcnm: 'Nickname',
    headerOdrSetleDt: 'First donation date',
    headerPymntDt: 'Recent donation date',
    headerOdr: 'Number of donations',
  },
  userTable: {
    headerSn: 'Unique code',
    headerNcnm: 'Nickname',
    headerOdrSetleDt: 'Donation date',
    headerOdrDt: 'Donation benefit period',
    headerCode: 'Donation status',
    headerDetail: {
      title: 'Detailed information',
      btn: 'View',
    },
  },
  table: {
    error: {
      message: "It's an unregistered member.",
    },
  },
  sponsorsDetail: {
    title: '{premiumBrand} Donation detailed information',
    userBenefit: "{creatorNickname}'s {premiumBrand} benefits",
    benefit: 'Benefits',
    sponsorsInfo: {
      title: 'Donation information',
      sponsorStatus: 'Donation status',
      userPrmbrshPgmSbscrptnSn: 'Unique code',
      sbscrptnOdrBeginDt: 'Donation date',
      sbscrptnOdrEndDt: 'Donation benefit period',
    },
    payment: {
      title: 'Payment information',
      pgPayment: 'Payment method',
      subscribeAmount: 'Payment amount',
      sponsorStatus: 'Payment status',
    },
  },
  sponsorStatus: {
    name: {
      RSCP: 'Donating',
      SBSC: 'Donating',
      SBED: 'Donation concluded',
      RQER: 'Error',
      TRMN: 'Donation concluded',
    } as { [Property in SponsorSttusCode]: string },
  },
  pgPayment: {
    name: {
      21001: 'Bank transfer',
      10009: 'Card payment',
    } as { [Property in PgPaymeantCode]: string },
  },

  creatorsList: {
    empty: 'There are no supporters.',
    error: 'Unable to retrieve the list of supporters. Please try again later.',
  },
  userList: {
    empty: 'There is no donation for {subscribeGrade}.',
    error:
      'Unable to retrieve the list of {subscribeGrade} donations. Please try again later.',
  },
  detail: {
    empty: 'There is no donation information.',
    error: 'Cannot find donation information. Please try again later.',
  },
  paymentStatus: {
    name: {
      RSCP: 'Payment completed',
      SBSC: 'Payment completed',
      SBED: 'Payment completed',
      RQER: 'Payment failed',
      TRMN: 'Payment completed',
    } as { [Property in SponsorSttusCode]: string },
  },
}
