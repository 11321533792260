import AttendanceService from './service'
import * as AttendanceType from './type'
import { initAttendanceState } from './init'

export * from './init'
export * from './type'
export const useAttendanceStore = defineStore('attendance', () => {
  const dailyCheck = ref(initAttendanceState.dailyCheck)

  /**
   * 출석 체크
   */
  const reqDailyCheck = async () => {
    await AttendanceService.reqDailyCheck()

    // 출석 체크 상태 변경
    dailyCheck.value.hasAttendanceToday = true

    // 연속 출석 체크 업데이트
    dailyCheck.value.consecutiveAttendanceDateCount += 1

    // 총 출석 체크 업데이트
    dailyCheck.value.totalAttendanceDateCount += 1
  }

  /**
   * 출석 체크 상태 조회
   */
  const fetchDailyCheck = async (payload: AttendanceType.DailyCheckPayload) => {
    const { data } = await AttendanceService.fetchDailyCheck(payload)
    const checkedInDates = data.attendanceDateList
      .filter((item) => item.hasAttendance)
      .map((item) => item.date)

    data.checkedInDates = checkedInDates

    dailyCheck.value = data
    return dailyCheck.value
  }

  return {
    dailyCheck,
    reqDailyCheck,
    fetchDailyCheck,
  }
})
