<script setup lang="ts">
import { Tippy } from 'vue-tippy'
import type { NovaBoxPostActionPrivateProps } from './NovaBoxPostActionPrivate.types'
import type { ShowOptionMenu, ShowOptionMenuInfo } from '@store'

const props = withDefaults(defineProps<NovaBoxPostActionPrivateProps>(), {
  popoverAppendTarget: null,
})

const { privateActions, action } = usePrivateActions(
  computed(() => ({
    source: props.source,
  }))
)
const tippyRef = ref<typeof Tippy | null>(null)
const isHide = ref(true)
const { show: modalShow, hide: modalHide } = useModal()

const clickHandler = async () => {
  if (useCheckMobile()) {
    isHide.value = false

    const menuList: ShowOptionMenuInfo[] = privateActions.value.map((item) => {
      const menu: ShowOptionMenuInfo = {
        id: item.id,
        name: item.name,
        class: '',
        fn: async () => {
          await modalHide(modalsName.MODAL_OPTION_MENU)
          item.id === 'share'
            ? await modalShow(modalsName.MODAL_SHARE_ACTION, {
                cmtyNttSn: props.source.cmtyNttSn,
                postTitle: props.source.nttSj,
                postContents: props.source.nttCn,
                shareCount: props.source.shCount,
              })
            : await action(item.id)
        },
      }
      return menu
    })

    await modalShow(modalsName.MODAL_OPTION_MENU, {
      menuType: 'btn',
      menuList,
    } as ShowOptionMenu)
  }
}

// 모바일 공유하기 액션 시트 호출

// 액션 실행 후 Tippy 닫기
const handleOnClose = () => {
  tippyRef.value?.hide()
}
</script>

<template>
  <Tippy
    ref="tippyRef"
    :append-to="popoverAppendTarget || 'parent'"
    :interactive="true"
    :placement="'bottom-end'"
    :theme="'popover'"
    @click.stop="clickHandler"
  >
    <NovaBoxPostAction
      :icon="{ type: 'outline', name: 'more-horizontal' }"
      :action-type="'privateActions'"
      :display-number="-1"
    />

    <template #content>
      <NovaBoxPostActionPrivatePanel
        v-if="isHide"
        :source="source"
        @close="handleOnClose"
      />
    </template>
  </Tippy>
</template>
