<script setup lang="ts">
import { fcmCallHandler, FcmPayloadMax, FcmPayloadMin } from '@firebaseModule'

const fcmPayloadMax = `
{
  // Not available
  notification: {
    title: '',
    body: '',
    image: '',
    icon: '',
  },
  // messaging payload
  data: {
    type: 'dlink', // type handler
    action: 'loginDirectRoute', // action handler
    url: '/userhome?userSn=20222355#reward', // url
  },
  // Not available
  fcmOptions: {
    link: '', // The link to open when the user clicks on the notification.
    analyticsLabel: '', // The label associated with the message's analytics data.
  },
  from: '', // The sender of this message.
  collapseKey: '', // The collapse key of the message.
  messageId: '', // The message ID of a message.
}
    `
const fcmPayloadMin = `
{
  type: 'dlink', // type handler
  action: 'loginDirectRoute', // action handler
  url: '/userhome?userSn=20222355#reward', // url
}
    `

const codeMax = ref(fcmPayloadMax)
const codeMin = ref(fcmPayloadMin)

const handleOnUpdateMax = (value: string) => {
  codeMax.value = value
}

const handleOnUpdateMin = (value: string) => {
  codeMin.value = value
}

const handleOnRequestMax = () => {
  const str = codeMax.value.toString()
  const value = str.replaceAll('\n', '')
  fcmCallHandler(new FcmPayloadMax(JSON.parse(value)))
}

const handleOnRequestMin = () => {
  const str = codeMin.value.toString()
  const value = str.replaceAll('\n', '')
  fcmCallHandler(new FcmPayloadMin(JSON.parse(value)))
}
</script>

<template>
  <section class="dev-section">
    <h4 class="dev-section-title">
      <span> FCM(Firebase Cloud Message) </span>
    </h4>

    <div class="section-inner">
      <h4>
        웹에서 FCM 호출

        <NovaButtonText
          :label="'request'"
          :theme="'primary-blue'"
          :size="32"
          @click="handleOnRequestMax"
        />
      </h4>

      <NovaCodeEditor
        :init-code="codeMax"
        :code-type="'javascript'"
        @update:code="handleOnUpdateMax"
      />

      <h4>
        브릿지 및 알림함에서 호출

        <NovaButtonText
          :label="'request'"
          :theme="'primary-blue'"
          :size="32"
          @click="handleOnRequestMin"
        />
      </h4>

      <NovaCodeEditor
        :init-code="codeMin"
        :code-type="'javascript'"
        @update:code="handleOnUpdateMin"
      />

      <!--      <NovaDescriptions :descriptions="appInfoDescriptions" />-->
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import '@styles/base/dev-tools';
@include dev-tools-section;

.section-inner {
  > h4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include text-style($text-body-14-bold);
  }
}
</style>
