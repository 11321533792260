import type { FacilityRentalApplyForm } from './type'
import * as FacilityTypes from './type'
import FacilityService from './service'
import { initFacilityState } from './init'

export * from './init'
export * from './type'
export const useFacilityStore = defineStore('facility', () => {
  const mypageStore = useMyPageStore()
  const rentCheckConditions = ref(initFacilityState.rentCheckConditions)
  const rentApplyForm = ref(initFacilityState.rentApplyForm)
  const rentApplyCreator = ref(initFacilityState.rentApplyCreator)
  const rentTerms = ref(initFacilityState.rentTerms)
  const rentalHistory = computed(
    () =>
      rentCheckConditions.value?.find((item) => item.fcltrntReqstRegistDt) ||
      null
  )
  const rentAvailableStatus = computed(() => {
    const profile = mypageStore.profile ? mypageStore.profile : undefined
    return useFacilityRentalAvailableStatus({
      conditions: rentCheckConditions.value,
      profile,
    })
  })

  /**
   * 시설물 임대 신청
   */
  const reqFacilityRental = (
    payload?: Partial<FacilityTypes.FacilityRentalPayload>
  ) => {
    // 1. 페이로드 준비(파일 리스트를 분리하기 위해 옵셔널 타입으로 변환)
    const _payload = {
      ...rentApplyCreator.value,
      ...rentApplyForm.value,
      ...payload,
    } as OptionalPayload<
      FacilityTypes.FacilityRentalPayload,
      'applcntPrufFileList'
    >

    // 2. 페이로드 평탄화 작업 후 폼데이터에 담기
    const formData = new FormData()

    // 2-1. 파일 리스트를 평탄화 하기 위해 페이로드에서 파일리스트 분리
    const fileList = [...(_payload?.applcntPrufFileList || [])]
    delete _payload.applcntPrufFileList

    // 2-2. 분리된 파일리스트를 평탄화 하고 폼데이터에 담기
    fileList.forEach((item, index) => {
      const flattendItem = useFlattenObject(item)
      Object.keys(flattendItem).forEach((key) => {
        formData.append(
          `applcntPrufFileList[${index}].${key}`,
          flattendItem[key]
        )
      })
    })

    // 2-3. 파일 리스트를 제외한 나머지 페이로드를 평탄화 하고 폼데이터에 담기
    const flattendedPayload = useFlattenObject(_payload)
    Object.keys(flattendedPayload).forEach((key) => {
      formData.append(key, String(flattendedPayload[key]))
    })
    for (const pair of formData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`)
    }
    // 3. 시설물 임대 신청
    // await FacilityService.reqFacilityRental(formData)
  }

  /**
   * 시설물 임대 약관 상세 조회
   */
  const fetchFacilityRentalTerm = async (
    payload: FacilityTypes.TheMoonEntTermId
  ) => {
    const { data } = await FacilityService.fetchFacilityRentalTerm(payload)
    return data
  }

  /**
   * 시설물 임대 약관 목록 조회
   */
  const fetchFacilityRentalTerms = async () => {
    const { data } = await FacilityService.fetchFacilityRentalTerms()
    rentTerms.value = data
    return data
  }

  /**
   * 시설물 임대 신청 요건 정보 조회(개인화 정보 포함 될 수 있음)
   */
  const fetchFacilityRentalCheckConditions = async () => {
    const { data } = await FacilityService.fetchFacilityRentalCheckConditions()
    rentCheckConditions.value = data
    return data
  }

  /**
   * 시설물 입주 신청 폼 모델 업데이트
   * @param payload
   */
  const updateRentApplyForm = (payload: Partial<FacilityRentalApplyForm>) => {
    rentApplyForm.value = {
      ...rentApplyForm.value,
      ...payload,
    }
  }

  /**
   * 시설물 임대 신청 크리에이터 모델 업데이트
   * @param payload
   */
  const updateRentApplyCreator = (
    payload: Partial<FacilityTypes.FacilityRentalApplyCreator>
  ) => {
    rentApplyCreator.value = {
      ...rentApplyCreator.value,
      ...payload,
    }
  }

  return {
    rentCheckConditions,
    rentApplyForm,
    rentApplyCreator,
    rentalHistory,
    rentTerms,
    rentAvailableStatus,
    reqFacilityRental,
    fetchFacilityRentalCheckConditions,
    fetchFacilityRentalTerm,
    fetchFacilityRentalTerms,
    updateRentApplyForm,
    updateRentApplyCreator,
  }
})
