import * as EtcType from '@/store/etc/type'
import EtcApi from '@/store/etc/url'
import { api } from '@/api'

export default {
  /**
   * 해시태그 랭킹 조회
   * @param payload
   */
  fetchHashtagRanking(payload: EtcType.HashtagRankPayload) {
    return api.get<EtcType.Hashtag[]>(EtcApi.FETCH_HASHTAG_RANKING, {
      params: payload,
    })
  },

  /**
   * 게시판 리스트 조회
   * @param payload
   */
  fetchBoardList(payload: EtcType.BoardPayload) {
    return api.get<EtcType.BoardItem[]>(EtcApi.FETCH_BOARD_LIST, {
      params: payload,
    })
  },

  /**
   * 게시판 상세조회
   * @param payload
   */
  fetchBoardDetail(payload: EtcType.BoardDetailPayload) {
    return api.get<EtcType.BoardItemDetail>(
      EtcApi.FETCH_BOARD_DETAIL.replace('{type}', payload.type).replace(
        '{nttGroupId}',
        payload.nttGroupId
      )
    )
  },

  /**
   * 게시판 필터 조회
   * @param payload
   */
  fetchBoardFilter(payload: EtcType.BoardFilterPayload) {
    return api.get<EtcType.BoardFilterItem[]>(EtcApi.FETCH_BOARD_FILTER, {
      params: payload,
    })
  },

  /**
   * 약관 및 정책 리스트 조회
   */
  fetchTerms() {
    return api.get<EtcType.TermsItem[]>(EtcApi.FETCH_TERMS)
  },

  /**
   * 약관 및 정책 상세 조회
   */
  fetchTerm(payload: EtcType.FetchTermDetailPayload) {
    return api.get<EtcType.TermDetail>(EtcApi.FETCH_TERMS_DETAIL, {
      params: payload,
    })
  },

  /**
   * 문의 분류 리스트 조회
   */
  fetchClassifications() {
    return api.get<EtcType.Classification[]>(EtcApi.FETCH_CLASSIFICATION)
  },
  /**
   * 문의하기
   */
  sendQnA(payload: EtcType.QnAPayload) {
    return api.post(EtcApi.SEND_QNA, payload)
  },

  /**
   * 신고 분류 코드 리스트 조회
   */
  fetchReportCodes(payload: EtcType.ReportCodePayload) {
    return api.get<EtcType.ReportCode[]>(EtcApi.FETCH_REPORT_CODES, {
      params: payload,
    })
  },

  /**
   * 신규 알림 카운트 조회
   */
  fetchNewAlarmCount() {
    return api.get<EtcType.AlarmCount>(EtcApi.FETCH_NEW_ALARM_COUNT)
  },

  /**
   * 알림 설정 리스트 조회
   */
  fetchNotificationSettings() {
    return api.get<EtcType.NotificationSetting>(
      EtcApi.FETCH_NOTIFICATION_SETTINGS
    )
  },

  /**
   * 서비스 알림 설정 하위 리스트 조회
   */
  fetchNotificationServiceDetail(
    payload: EtcType.NotificationServiceDetailPayload
  ) {
    return api.get<EtcType.NotificationServiceSetting>(
      EtcApi.FETCH_NOTIFICATION_SERVICE_DETAIL,
      { params: payload }
    )
  },

  /**
   * 서비스 알림 설정
   */
  updateNotificationService(payload: EtcType.UpdateNotificationServicePayload) {
    return api.post<EtcType.NotificationServiceSetting>(
      EtcApi.UPDATE_NOTIFICATION_SERVICE,
      payload
    )
  },

  /**
   * 푸시 알림음 목록 조회
   */
  fetchNotificationPushSounds() {
    return api.get<EtcType.NotificationPushSound[]>(
      EtcApi.FETCH_NOTIFICATION_PUSH_SOUNDS
    )
  },

  /**
   * 푸시 알림 설정
   */
  updateNotificationPush(payload: EtcType.UpdateNotificationPushPayload) {
    return api.post<EtcType.NotificationPushSetting>(
      EtcApi.UPDATE_NOTIFICATION_PUSH,
      payload
    )
  },

  /**
   * 미사용 API 파악하여 삭제할것
   *
   *
   *
   *
   *
   *
   */

  /**
   * 알림 설정
   */
  putNotificationSettings(payload: EtcType.AlarmPayload) {
    return api.post(EtcApi.SET_ALARM, payload)
  },

  /**
   * 알림 리스트 조회
   */
  fetchNotifications(payload: EtcType.NotificationsPayload) {
    return api.get<EtcType.Notification[]>(EtcApi.FETCH_ALARM_LIST, {
      params: payload,
    })
  },

  /**
   * 알림 읽기
   */
  putAlarmItem(payload: EtcType.NotificationsActionPayload) {
    return api.put(EtcApi.PUT_ALARM_READ, payload)
  },

  /**
   * 알림 삭제
   */
  deleteAlarmItem(payload: EtcType.NotificationsActionPayload) {
    return api.delete(EtcApi.DELETE_ALARM, { params: payload })
  },

  /**
   * 일일 첼린지 조회
   */
  fetchDailyChallenge() {
    return api.get(EtcApi.FETCH_DAILY_CHALLENGE)
  },

  /**
   * 이벤트 - 좋아요 받은 랭킹 목록 조회
   */
  fetchRecommenders(payload: EtcType.RecommendersRankingPayload) {
    return api.get<EtcType.Recommender[]>(EtcApi.FETCH_RECOMMENDER_RANK, {
      params: payload,
    })
  },

  /**
   * 이벤트 - 사용자의 좋아요 받은 랭킹 조회
   */
  fetchUserRecommendRank() {
    return api.get<EtcType.UserRecommendRank>(EtcApi.FETCH_USER_RANK)
  },

  /**
   * 메인 - 매인 배너
   */
  fetchMainBanners() {
    return api.get<EtcType.MainBanner[]>(EtcApi.FETCH_MAIN_BANNER)
  },
  /**
   * 메인 - 매인 Pick 목록
   */
  fetchMainPicks() {
    return api.get<EtcType.MainPick[]>(EtcApi.FETCH_MAIN_PICK)
  },
  /**
   * 프리미엄 - 프리미엄 메인 배너
   */
  fetchPremiumBanners() {
    return api.get<EtcType.PremiumBanner[]>(EtcApi.FETCH_PREMIUM_BANNER)
  },

  /**
   * 배너 목록 조회
   */
  fetchBanners(payload: EtcType.BannersPayload) {
    return api.get<EtcType.Banner[]>(EtcApi.FETCH_BANNERS, { params: payload })
  },

  /**
   * 마케팅 동의 설정
   */
  putMarketingSettings(marketingload: EtcType.AlarmPayload) {
    return api.post(EtcApi.SET_ALARM, marketingload)
  },

  /**
   * LM 펀딩 현황 조회
   */
  fetchFunding() {
    return api.get<EtcType.Funding>(EtcApi.FETCH_FUNDING)
  },

  /**
   * 정산 은행 목록 조회
   */
  fetchBanks(payload?: EtcType.BanksPayload) {
    return api.get<EtcType.Bank[]>(EtcApi.FETCH_BANKS, { params: payload })
  },
}
