import type { BridgeMessage, BridgeResponse } from '@bridge/types'

/**
 * message 개행 제거
 * @param message
 */
export const formatMessage = (message: string) =>
  message.replace(/(?:\\[rn]|[\r\n]+)+/g, '')

/**
 * bridge message 파싱
 * @description 브릿지 받는 쪽에서 파싱
 * @param message
 */
export const messageToParse = <R>(message: string): BridgeResponse<R> =>
  JSON.parse(formatMessage(message))

/**
 * bridge message 스트링
 * @description 브릿지 호출쪽에서 메세지 스트링으로 변경
 * @param message
 */
export const messageToString = <R>(message: BridgeMessage<R>): string =>
  formatMessage(JSON.stringify(message))
