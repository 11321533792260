import {
  getToken,
  type MessagePayload,
  type Messaging,
  onMessage,
} from '@firebase/messaging'
import { FirebaseException } from './exception'
import { FirebaseExceptionStatus, type FirebaseTokenStatus } from './types'
import { FirebaseDevice } from '@store'
import { bridgeToApp } from '@bridge'
import { NovaModalBeforeRequestNotificationPermission } from '#components'

interface SendFirebaseTokenToServerProps {
  fcmAppToken?: string
  fcmWebToken?: string
  langCode: string
}

/**
 * Send token to server
 * @description send firebase app, web token to server
 * @param token
 */
export const sendFcmTokenToServer = async ({
  fcmAppToken,
  fcmWebToken,
  langCode,
}: SendFirebaseTokenToServerProps): Promise<FirebaseTokenStatus> => {
  const { cookieNames, setCookie } = useCookies()
  const appStore = useAppStore()
  const firebaseStore = useFirebaseStore()
  let fcmDevice: FirebaseDevice = FirebaseDevice.WEB

  switch (appStore.appType) {
    case 'AOS':
      fcmDevice = FirebaseDevice.AOS
      break
    case 'IOS':
      fcmDevice = FirebaseDevice.IOS
      break
    default:
      fcmDevice = FirebaseDevice.WEB
      break
  }

  try {
    switch (fcmDevice) {
      case FirebaseDevice.WEB:
        if (fcmWebToken) {
          await firebaseStore.sendFcmTokenToServer({
            fcmDevice: FirebaseDevice.WEB,
            fcmToken: fcmWebToken,
            langCode,
          })

          setCookie<FirebaseTokenStatus>(
            cookieNames.FCM_TOKEN_STATUS,
            { fcmDevice, fcmAppToken: '', fcmWebToken },
            30,
            'days'
          )
        }
        break
      case FirebaseDevice.AOS:
      case FirebaseDevice.IOS:
        // 앱 모드일때는 fcmAppToken 을 fcmWebToken에 오버라이드 하여 사용
        if (fcmAppToken) {
          await Promise.all([
            firebaseStore.sendFcmTokenToServer({
              fcmDevice: FirebaseDevice.WEB,
              fcmToken: fcmAppToken,
              langCode,
            }),
            firebaseStore.sendFcmTokenToServer({
              fcmDevice,
              fcmToken: fcmAppToken,
              langCode,
            }),
          ])

          setCookie<FirebaseTokenStatus>(
            cookieNames.FCM_TOKEN_STATUS,
            { fcmDevice, fcmAppToken, fcmWebToken: fcmAppToken },
            30,
            'days'
          )
        }
        break
      default:
        break
    }

    const fcmTokenStatus = {
      fcmDevice,
      fcmAppToken: fcmAppToken || '',
      fcmWebToken: fcmWebToken || '',
    }
    appStore.updateFcmTokenStatus(fcmTokenStatus)

    return fcmTokenStatus
  } catch (err) {
    throw new FirebaseException({
      message: 'send fcm token to server fail',
      cause: {
        status: FirebaseExceptionStatus.SEND_FCM_TOKEN_TO_SERVER_FAIL,
      },
    })
  }
}

/**
 * Request notification permission
 * @description request & check notification permission
 */
export const requestNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission()
    return permission
  } catch (err) {
    const _err = err as Error
    throw new FirebaseException({
      message: _err.message,
      cause: {
        status: FirebaseExceptionStatus.REQUEST_PERMISSION_FAIL,
        data: _err,
      },
    })
  }
}

/**
 * Get fcm web token
 * @description get fcm web token from firebase sdk
 */
export const getFcmWebToken = async (messaging: Messaging) => {
  const config = useRuntimeConfig()

  try {
    const fcmWebToken = await getToken(messaging, {
      vapidKey: config.public.FIREBASE_VAPIDKEY,
    })
    return fcmWebToken
  } catch (err) {
    const _err = err as Error
    throw new FirebaseException({
      message: _err.message,
      cause: {
        status: FirebaseExceptionStatus.GET_FCM_WEB_TOKEN_FAIL,
        data: _err,
      },
    })
  }
}

/**
 * Get fcm app token
 * @description get fcm app token from app by bridge
 */
export const getFcmAppToken = async (): Promise<{
  fcmToken: string
  langCode: string
}> => {
  try {
    const { data: appStorage } = await bridgeToApp.getStorage()

    if (appStorage.fcmToken) {
      return {
        fcmToken: appStorage.fcmToken,
        langCode: appStorage.languageCode,
      }
    } else {
      throw new Error('fcm app token is empty')
    }
  } catch (err) {
    console.error(err)
    throw new FirebaseException({
      message: 'get fcm app token fail',
      cause: {
        status: FirebaseExceptionStatus.GET_FCM_APP_TOKEN_FAIL,
        data: err,
      },
    })
  }
}

/**
 * Notification permission modals
 * @description notification permission modals
 */
export const useNotificationPermissionModals = () => {
  const route = useRoute()
  const { t } = useNuxtApp().$i18n
  const { show: modalShow, hide: modalHide } = useModal()

  const beforeRequestPermission = async (callback: () => Promise<void>) => {
    const { cookieNames, getCookie, setCookie } = useCookies()
    const notificationPermission = getCookie<boolean>(
      cookieNames.NOTIFICATION_PERMISSION
    )
    if (notificationPermission) return
    await modalShow({
      component: NovaModalBeforeRequestNotificationPermission,
      bind: {
        name: modalsName.MODAL_BEFORE_REQUEST_NOTIFICATION_PERMISSION,
        btns: [
          {
            label: t('notificationPermission.actions.config'),
            type: 'positive',
            onClick: async () => {
              await modalHide(
                modalsName.MODAL_BEFORE_REQUEST_NOTIFICATION_PERMISSION
              )
              await callback()
              if (window.history.state.back === route.path) {
                await useNavigations({ type: 'back' })
              }
            },
          },
          {
            label: t('notificationPermission.actions.notNow'),
            type: 'negative',
            onClick: async () => {
              setCookie(cookieNames.NOTIFICATION_PERMISSION, true, 30, 'days')
              await modalHide(
                modalsName.MODAL_BEFORE_REQUEST_NOTIFICATION_PERMISSION
              )
              if (window.history.state.back === route.path) {
                await useNavigations({ type: 'back' })
              }
            },
          },
        ],
      },
      slots: {
        title: t('notificationPermission.modals.beforeRequest.title'),
        message: t('notificationPermission.modals.beforeRequest.message'),
      },
    })
  }

  return {
    beforeRequestPermission,
  }
}

/**
 * on fcm listener
 * @description fcm messaging listener
 */
export const onFcmMessagingListener = (
  messaging: Messaging
): Promise<MessagePayload> =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log('FCM onMessage', payload)
      resolve(payload)
    })
  })
