import * as cheerio from 'cheerio'

/**
 * 앵커의 href 값을 검사하여 내부 링크인지 외부 링크인지 판단하여 attribute 재설정
 *
 * @description 인앱에서 anchor tag 이벤트 발생시 whitelist 내 href가 새창으로 열리는 문제 발생하는 문제로 인한 이벤트 주입
 * @param {string} strHTML - 앵커 요소를 포함하는 string html
 * @returns {string}
 */
export const useReplaceStrHtmlAnchorTarget = (strHTML: string): string => {
  const config = useRuntimeConfig()
  const dom = cheerio.load(strHTML)
  const anchors = dom('a')

  anchors.each((_idx, anchor) => {
    const anchorEl = dom(anchor)
    const href = anchorEl.attr('href')
    let url: URL | null = null

    try {
      url = new URL(href || '')
    } catch (e) {
      console.log('Invalid URL', href)
    }

    if (url) {
      const isInternal =
        url.origin.includes(config.public.APP_URL) || !url.origin
      anchorEl.attr('target', isInternal ? '_self' : '_blank')
    }
  })

  return dom.html()
}
