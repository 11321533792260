/**
 * 크리에이터 방명록
 */
export const creatorNotice = {
  regCreatorNoticeModal: {
    title: '공지사항 작성',
    textareaPlaceholder: '내용을 입력하세요.',
    actions: {
      negative: '취소',
      positive: '작성',
    },
  },
  request: {
    actions: {
      delete: '삭제하기',
    },
    list: {
      empty: '등록된 공지사항이 없습니다.',
      error: '공지사항을 가져올 수 없습니다. 잠시 후 다시 시도해 주세요.',
    },
    delete: {
      error: '공지사항을 삭제할 수 없습니다. 잠시 후 다시 시도해 주세요.',
      success: '공지사항을 삭제하였습니다.',
    },
    reg: {
      stopReg:
        '저장되지 않은 상태에서 닫을 경우 입력하신 공지사항이 삭제됩니다. 저장하지 않고 삭제할까요?',
      validLength: '공지사항 내용을 입력해 주세요.',
      error: '공지사항을 작성할 수 없습니다. 잠시 후 다시 시도해 주세요.',
      success: '공지사항을 작성하였습니다.',
    },
  },
}
