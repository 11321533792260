import * as BlockchainType from './type'
import BlockchainApi from './url'
import { api } from '@api'

export default {
  /**
   * 사용자 LMC 잔고조회(벨런스조회)
   * @param payload
   */
  async fetchBalance(payload: BlockchainType.BalancePayload) {
    const result = await api.get<BlockchainType.Balance>(
      BlockchainApi.FETCH_BALANCE,
      {
        params: payload,
      }
    )
    if (result.data.totalAmount) {
      result.data.totalAmount = Number(result.data.totalAmount)
    }
    return result
  },
}
