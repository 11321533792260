import apiPath from '@store/apiPath'

export default {
  /**
   * W3CF 후보작 목록 조회
   * @method GET
   */
  FETCH_W3CFS: `${apiPath.W3CF}/v1/posts`,

  /**
   * W3CF 후보작 상세 조회
   * @method GET
   */
  FETCH_W3CF: `${apiPath.W3CF}/v1/post`,

  /**
   * W3CF 좋아요, 좋아요 취소
   * @method POST, PUT
   */
  REQ_W3CF_LIKE: `${apiPath.W3CF}/v1/post/recommend`,
}
