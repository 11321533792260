<script setup lang="ts">
import type { RouteParams } from 'vue-router'
import type { ModalFundProjectDetailParams } from './NovaModalFundProject.types'
import { NovaLayoutScrollContainer } from '#components'
import type { FundProject } from '@store'
import { ROUTES } from '@configs'

defineOptions({
  inheritAttrs: false,
})

const { gtEvent } = useGoogleTag()
const { hide: modalHide } = useModal()
const show = ref(false)
const fundProject = ref<FundProject>()
const fundProjectRef = ref<InstanceType<
  typeof NovaLayoutScrollContainer
> | null>(null)
const showVoteButton = ref(true)

// 팝업 열릴때 호출하는 쪽에서 파라미터 받아 이달의 프로젝트 상세 모델 업데이트
const handleOnGetParams = (evt: RouteParams) => {
  const payload = evt.value as unknown as ModalFundProjectDetailParams
  fundProject.value = payload.fundProject
  showVoteButton.value = payload.showVoteButton
}

/**
 * 팝업 닫기: 버튼 클릭
 */
const handleOnClose = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '펀드 프로젝트 상세보기 닫기',
    eventLabel: 'close-extend',
    eventSlot: '펀드 프로젝트 상세보기 모달',
    eventI18nAddr: 'close-extend',
    eventComponent: 'Button',
  })

  if (window.history.state.back) {
    await modalHide(modalsName.MODAL_FUND_PROJECT_DETAIL)
  } else {
    await modalHide(modalsName.MODAL_FUND_PROJECT_DETAIL, false)
    await useNavigations({ url: ROUTES.FUND_HISTORY.path, type: 'replace' })
  }
}

/**
 * 팝업 닫은 후 스토어에서 펀트 프로젝트 상세 초기화
 */
const handleOnClosed = () => {
  fundProject.value = undefined
}
</script>

<template>
  <VueFinalModal
    v-model="show"
    :name="modalsName.MODAL_FUND_PROJECT_DETAIL"
    :content-class="'modal-content'"
    classes="modal-container"
    @before-open="(evt: any) => handleOnGetParams(evt.ref.params)"
    @closed="handleOnClosed"
    @click-outside="handleOnClose"
  >
    <ClientOnly>
      <NovaBoxBase v-if="show" class="modal-box">
        <div class="modal-header">
          <div class="on-mobile">
            <NovaButtonIcon
              :icon="{ type: 'outline', name: 'arrow-left' }"
              :theme="'transparent'"
              :size="24"
              class="btn-close-on-mobile"
              @click="handleOnClose"
            />

            <NovaHeaderTools v-if="show" :on-popup="true" />
          </div>

          <div class="on-desktop">
            <NovaButtonIcon
              :icon="{ type: 'outline', name: 'close-extend' }"
              :theme="'transparent'"
              :size="24"
              :is-modal="true"
              class="btn-close"
              @click="handleOnClose"
            />
          </div>
        </div>

        <NovaLayoutScrollContainer ref="fundProjectRef" class="modal-body">
          <NovaBoxFundProjectDetail
            v-if="fundProject"
            :source="fundProject"
            :show-vote-button="showVoteButton"
          />

          <NovaScrollToTopButton
            :target="fundProjectRef?.$el || null"
            :bottom-position="64"
          />
        </NovaLayoutScrollContainer>
      </NovaBoxBase>
    </ClientOnly>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
:deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.vfm--overlay) {
  background-color: hex-to-rgba($color-black, 0.65);
}

:deep(.modal-content) {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(30px + 24px + 12px) 32px 32px;
  width: 100%;
  height: 100%;
  pointer-events: none;

  .modal-box {
    width: 100%;
    padding: 0;
    max-width: 670px;
    max-height: 100%;
    border-radius: 20px !important;
    pointer-events: auto;

    .modal-header {
      height: 0;
      padding: 0;
      background-color: $color-bg-3;

      .on-mobile {
        display: none;
        align-items: center;
        justify-content: space-between;
        height: 50px;

        .btn-close-on-mobile {
        }
      }

      .on-desktop {
        .btn-close {
          position: absolute;
          top: 30px;
          right: 30px;
          color: $color-bg-3;
        }
      }
    }

    .modal-body {
      border-radius: 20px;

      > .post {
        border-radius: 0;
        box-shadow: none;
      }

      .async-status-message {
        min-height: 200px;
      }
    }
  }
}

@include mobile {
  :deep(.modal-content) {
    width: 100%;
    height: 100%;
    padding: 0;

    .modal-box {
      width: 100%;
      max-width: 100%;
      height: 100%;
      border-radius: 0 !important;

      .modal-header {
        height: 56px;
        padding: 0 20px;

        .on-mobile {
          display: flex;
        }

        .on-desktop {
          .btn-close {
            display: none;
          }
        }
      }

      .modal-body {
        border-radius: 0;

        > .post {
          max-height: initial;
        }
      }
    }
  }
}
</style>
