<script setup lang="ts">
import type {
  CreateNoticeState,
  NovaModalNoticeCreateEmits,
} from './NovaModalNoticeCreate.types'
import {
  type RegCreatorNoticePayload,
  type UpdateCreatorNoticePayload,
  type CreatorNoticePayload,
  PostContentsType,
  type EditorImage,
} from '@store'
import {
  NovaModalCommon,
  NovaModalDialog,
  NovaModalNoticeCreatePreview,
  NovaModalNoticeCreateTempSaveList,
} from '#components'

const emit = defineEmits<NovaModalNoticeCreateEmits>()
const { gtEvent } = useGoogleTag()
const config = useRuntimeConfig()
const userStore = useUserStore()
const creatorStore = useCreatorStore()
const layoutStore = useLayoutStore()
const { show: modalShow, hide: modalHide } = useModal()
// const modalValue = ref(false).value
// const countByte = computed(() => useCalcByte(state.title))
const { t } = useI18n()
const state = reactive<CreateNoticeState>({
  title: '',
  tempSave: -1,
  bodyData: '',
  tempImg: [],
  fileUrlList: [],
})
const init = ref('')

onMounted(() => {
  if (creatorStore.creatorNotice) {
    state.title = creatorStore.creatorNotice.crtrNttSj
    state.bodyData = creatorStore.creatorNotice.crtrNttCn
    state.fileUrlList = getImagesFromContents(
      creatorStore.creatorNotice.crtrNttCn || ''
    )
  }
  init.value = JSON.stringify(state)
})

onUnmounted(() => {
  creatorStore.initCreatorNotice()
})

const handleOnTempSave = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '공지사항 작성 임시저장 클릭',
    eventLabel: t('postCreate.button.tempSave'),
    eventSlot: '공지사항 작성 모달',
    eventI18nAddr: useKoreanTranslation('postCreate.button.tempSave'),
    eventComponent: 'Button',
  })
  if (onValidate()) {
    try {
      layoutStore.updateLoadingIndicatorGlobal({ show: true })

      const imageURL = useExtractElementFromString(state.bodyData)
      const regPayload: RegCreatorNoticePayload = {
        crtrNttSj: state.title, // 공지사항 제목
        crtrNttCn: state.bodyData, // 공지사항 내용
        tmprNtbdAt: 'Y', // 임시저장 여부
      }
      const updatePayload: UpdateCreatorNoticePayload = {
        crtrNttSn: Number(creatorStore.creatorNotice?.crtrNttSn),
        crtrNttSj: state.title, // 공지사항 제목
        crtrNttCn: state.bodyData, // 공지사항 내용
        tmprNtbdAt: 'N', // 임시저장 여부
      }
      if (regPayload || updatePayload) {
        if (
          typeof imageURL !== 'number' &&
          imageURL.count > 0 &&
          !creatorStore.creatorNotice
        ) {
          regPayload.tempImgUrlList = imageURL.url
        }

        regPayload.crtrNttCn = await useReplacerPostContents(
          state.bodyData,
          PostContentsType.DETAIL,
          true
        )
        const { updatedHtml } = await useReplacePostContentImgsToTempImgs(
          state.bodyData
        )
        // 임시저장 불러와서 업데이트
        if (creatorStore.creatorNotice) {
          updatePayload.fileUrlList = getImagesFromContents(updatedHtml, true)
          await creatorStore.updateCreatorNotice(
            updatePayload as UpdateCreatorNoticePayload
          )
          gtEvent('userAction', {
            eventCategory: '사용자',
            eventAction: '공지사항 임시저장 업데이트',
            eventLabel: t('postCreate.button.tempSave'),
            eventSlot: 'Modal',
            eventI18nAddr: useKoreanTranslation('postCreate.button.tempSave'),
            eventComponent: 'Button',
            crtrNttSn: creatorStore.creatorNotice.crtrNttSn,
            creatorUserSn: userStore.user?.userSn, // 크리에이터 사용자 일련번호
            crtrNttSj: state.title, // 공지사항 제목
            crtrNttCn: state.bodyData, // 공지사항 내용
          })
        } else {
          // 최초 임시 저장
          await creatorStore.regCreatorNotice(
            regPayload as RegCreatorNoticePayload
          )
          gtEvent('userAction', {
            eventCategory: '사용자',
            eventAction: '공지사항 임시저장',
            eventLabel: t('postCreate.button.tempSave'),
            eventSlot: 'Modal',
            eventI18nAddr: useKoreanTranslation('postCreate.button.tempSave'),
            eventComponent: 'Button',
            creatorUserSn: userStore.user?.userSn, // 크리에이터 사용자 일련번호
            crtrNttSj: state.title, // 공지사항 제목
            crtrNttCn: state.bodyData, // 공지사항 내용
          })
        }
      }
      useToast(t('noticeCreate.toastMessage.tempSave'))
    } catch (err: any) {
      console.log('err', err)
    } finally {
      layoutStore.updateLoadingIndicatorGlobal({ show: false })
    }
  }
}

const handleOnPreview = async () => {
  try {
    layoutStore.updateLoadingIndicatorGlobal({ show: true })
    const contents = await useReplacerPostContents(
      state.bodyData,
      PostContentsType.DETAIL,
      true
    )
    gtEvent('clickEvent', {
      eventCategory: '클릭',
      eventAction: '미리보기 버튼 클릭',
      eventLabel: t('postCreate.button.preview'),
      eventSlot: 'Modal',
      eventI18nAddr: useKoreanTranslation('postCreate.button.preview'),
      eventComponent: 'Button',
    })
    await modalShow({
      component: NovaModalCommon,
      bind: {
        name: modalsName.MODAL_CREATOR_NOTICE_PREVIEW,
        showCloseBtn: true,
        maxHeight: 500,
      },
      on: {
        close: () => {
          gtEvent('clickEvent', {
            eventCategory: '클릭',
            eventAction: '미리보기 모달 > 닫기 버튼',
            eventLabel: t('noticeCreate.modal.previewTitle'),
            eventSlot: 'Modal',
            eventI18nAddr: useKoreanTranslation(
              'noticeCreate.modal.previewTitle'
            ),
            eventComponent: 'Button',
          })
          modalHide(modalsName.MODAL_CREATOR_NOTICE_PREVIEW)
        },
      },
      slots: {
        title: t('noticeCreate.modal.previewTitle'),
        contents: {
          component: NovaModalNoticeCreatePreview,
          bind: {
            title: state.title,
            contents,
          },
          on: {
            close() {
              gtEvent('postAction', {
                eventCategory: '포스트',
                eventAction: '미리보기 모달 > 확인 버튼',
                eventLabel: t('postCreate.button.confirm'),
                eventSlot: 'Modal',
                eventI18nAddr: useKoreanTranslation(
                  'postCreate.button.confirm'
                ),
                eventComponent: 'Button',
              })
              modalHide(modalsName.MODAL_CREATOR_NOTICE_PREVIEW)
            },
          },
        },
      },
    })
  } catch (err) {
    useToast(t('noticeCreate.toastMessage.error'))
  } finally {
    layoutStore.updateLoadingIndicatorGlobal({ show: false })
  }
}

const getImagesFromContents = (contents: string, submit = false) => {
  // 1. 콘텐츠에서 이미지 추출(외부 이미지 제외)
  const container = document.createElement('div')
  container.innerHTML = contents
  const imageEls = container.querySelectorAll('img')
  const nextImages: EditorImage[] = Array.prototype.slice
    .call(imageEls)
    .filter((img) => {
      const fileUrl = img.getAttribute('src')
      return (
        fileUrl.includes(config.public.TEMP_IMAGE_URL) ||
        fileUrl.includes(config.public.POST_RELEASED_IMAGE_URL)
      )
    })
    .map((img) => {
      const fileUrl = img.getAttribute('src')

      return {
        deleteAt:
          !fileUrl.includes(config.public.TEMP_IMAGE_URL) &&
          state.fileUrlList.findIndex((item) => item.fileUrl === fileUrl) === -1
            ? 'Y'
            : 'N',
        fileUrl,
        tempAt: fileUrl.includes(config.public.TEMP_IMAGE_URL) ? 'Y' : 'N',
      }
    })

  // 2. 수정 전 이미지와 비교하여 삭제된 이미지 추출
  let deletedImages: EditorImage[] = []
  deletedImages = state.fileUrlList.filter(
    (prevImg) =>
      nextImages.findIndex((nextImg) => nextImg.fileUrl === prevImg.fileUrl) ===
      -1
  )

  // 3. 수정 하기 위한 페이로드 생성시 변경 사항 없는 이미지는 제외
  if (submit) {
    return [...nextImages, ...deletedImages].filter(
      (img) => !(img.deleteAt === 'N' && img.tempAt === 'N')
    )
  }

  return [...nextImages, ...deletedImages]
}

const handleOnSave = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '공지사항 작성 모달 > 게시 버튼 클릭',
    eventLabel: t('postCreate.button.save'),
    eventSlot: 'Modal',
    eventI18nAddr: useKoreanTranslation('postCreate.button.save'),
    eventComponent: 'Button',
    creatorUserSn: userStore.user?.userSn, // 크리에이터 사용자 일련번호
    crtrNttSj: state.title, // 공지사항 제목
    crtrNttCn: state.bodyData, // 공지사항 내용
  })
  // 최초 게시, 게시물 수정 양쪽에서 쓰임
  if (onValidate()) {
    try {
      layoutStore.updateLoadingIndicatorGlobal({ show: true })

      const imageURL = useExtractElementFromString(state.bodyData)
      const regPayload: RegCreatorNoticePayload = {
        crtrNttSj: state.title, // 공지사항 제목
        crtrNttCn: state.bodyData, // 공지사항 내용
        tmprNtbdAt: 'N', //
      }
      const updatePayload: UpdateCreatorNoticePayload = {
        crtrNttSn: Number(creatorStore.creatorNotice?.crtrNttSn),
        crtrNttSj: state.title, // 공지사항 제목
        crtrNttCn: state.bodyData, // 공지사항 내용
        tmprNtbdAt: 'N',
      }
      if (regPayload || updatePayload) {
        // 최소 등록 일때만 payload.tempImgUrlList 추가 -> 기존 로직 유지
        if (
          typeof imageURL !== 'number' &&
          imageURL.count > 0 &&
          !creatorStore.creatorNotice
        ) {
          regPayload.tempImgUrlList = imageURL.url
        }

        regPayload.crtrNttCn = await useReplacerPostContents(
          state.bodyData,
          PostContentsType.DETAIL,
          true
        )
        const { updatedHtml } = await useReplacePostContentImgsToTempImgs(
          state.bodyData
        )
        if (creatorStore.creatorNotice) {
          updatePayload.fileUrlList = getImagesFromContents(updatedHtml, true)
          await creatorStore.updateCreatorNotice(
            updatePayload as UpdateCreatorNoticePayload
          )
          gtEvent('userAction', {
            eventCategory: '사용자',
            eventAction: '공지사항 수정',
            eventLabel: t('modifyNotice'),
            eventSlot: 'Modal',
            eventI18nAddr: useKoreanTranslation('modifyNotice'),
            eventComponent: 'Button',
            crtrNttSn: creatorStore.creatorNotice.crtrNttSn,
            creatorUserSn: userStore.user?.userSn, // 크리에이터 사용자 일련번호
            crtrNttSj: state.title, // 공지사항 제목
            crtrNttCn: state.bodyData, // 공지사항 내용
          })
        } else {
          await creatorStore.regCreatorNotice(
            regPayload as RegCreatorNoticePayload
          )
          gtEvent('userAction', {
            eventCategory: '사용자',
            eventAction: '공지사항 저장',
            eventLabel: t('postCreate.button.save'),
            eventSlot: 'Modal',
            eventI18nAddr: useKoreanTranslation('postCreate.button.save'),
            eventComponent: 'Button',
            creatorUserSn: userStore.user?.userSn, // 크리에이터 사용자 일련번호
            crtrNttSj: state.title, // 공지사항 제목
            crtrNttCn: state.bodyData, // 공지사항 내용
          })
        }
      }
      emit('close')
    } catch (err) {
      useToast(t('noticeCreate.toastMessage.error'))
    } finally {
      layoutStore.updateLoadingIndicatorGlobal({ show: false })
    }
  }
}

const openTempList = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '공지 사항 임시저장 불러오기 클릭',
    eventLabel: t('postCreate.placeholder.tempSave'),
    eventSlot: 'Modal',
    eventI18nAddr: useKoreanTranslation('postCreate.placeholder.tempSave'),
    eventComponent: 'Button',
  })
  modalShow({
    component: NovaModalCommon,
    bind: {
      name: modalsName.MODAL_CREATOR_NOTICE_TEMP_LIST,
      showCloseBtn: true,
      hasInnerScroll: true,
    },
    on: {
      close: () => {
        gtEvent('clickEvent', {
          eventCategory: '클릭',
          eventAction: '임시저장 목록 모달 > 닫기',
          eventLabel: t('noticeCreate.modal.tempTitle'),
          eventSlot: 'Modal',
          eventI18nAddr: useKoreanTranslation('noticeCreate.modal.tempTitle'),
          eventComponent: 'Button',
          creatorUserSn: userStore.user!.userSn,
        })
        modalHide(modalsName.MODAL_CREATOR_NOTICE_TEMP_LIST)
      },
    },
    slots: {
      title: t('noticeCreate.modal.tempTitle'),
      noScrollContents: {
        component: NovaModalNoticeCreateTempSaveList,
        on: {
          selectItem(id: number) {
            selectTempItem(id)
            modalHide(modalsName.MODAL_CREATOR_NOTICE_TEMP_LIST)
          },
          close() {
            modalHide(modalsName.MODAL_CREATOR_NOTICE_TEMP_LIST)
          },
        },
      },
    },
  })
}

const onValidate = () => {
  if (state.title.length < 2) {
    useToast(t('noticeCreate.toastMessage.validateTitle'))
    return false
  } else if (state.bodyData.length < 10) {
    useToast(t('noticeCreate.toastMessage.validateContents'))
    return false
  } else {
    return true
  }
}

const selectTempItem = async (tempSN: number) => {
  try {
    const payload: CreatorNoticePayload = {
      crtrNttSn: tempSN,
      creatorUserSn: userStore.user!.userSn,
    }
    const result = await creatorStore.fetchCreatorNotice(payload)
    state.title = result.crtrNttSj
    state.bodyData = result.crtrNttCn
    init.value = JSON.stringify(state)
  } catch (err) {
    console.log('error')
  }
}

// 이벤트 버스: 리스너: 팝업창 끄기
useListen('notice:createClose', async () => {
  const isCreate = init.value !== JSON.stringify(state)
  if (isCreate) {
    await modalShow({
      component: NovaModalDialog,
      bind: {
        name: modalsName.MODAL_DIALOG,
        btns: [
          {
            label: t('cancel'),
            theme: 'transparent',
            size: 32,
            onClick: async () => {
              gtEvent('clickEvent', {
                eventCategory: '클릭',
                eventAction: '공지사항 작성 모달 > 닫기 확인',
                eventLabel: t('cancel'),
                eventSlot: t('noticeCreate.modal.dialogTitle'),
                eventI18nAddr: useKoreanTranslation('cancel'),
                eventComponent: 'Button',
              })
              await modalHide(modalsName.MODAL_DIALOG)
            },
          },
          {
            label: t('confirm'),
            theme: 'primary-blue-light',
            size: 32,
            onClick: async () => {
              gtEvent('clickEvent', {
                eventCategory: '클릭',
                eventAction: '공지사항 작성 모달 > 닫기 확인',
                eventLabel: t('confirm'),
                eventSlot: t('noticeCreate.modal.dialogTitle'),
                eventI18nAddr: useKoreanTranslation('confirm'),
                eventComponent: 'Button',
              })
              await modalHide(modalsName.MODAL_DIALOG)
              await modalHide(modalsName.MODAL_REG_CREATOR_NOTICE)
            },
          },
        ],
      },
      slots: {
        title: t('noticeCreate.modal.dialogTitle'),
        content: t('noticeCreate.modal.dialogContent'),
      },
    })
  } else {
    await modalHide(modalsName.MODAL_DIALOG)
    await modalHide(modalsName.MODAL_REG_CREATOR_NOTICE)
  }
})

// models: 에디터 로딩
const isLoading = ref(false)
// methods: 에디터에서 로딩 인디케이터 노출
const handleOnTipTapLoading = (loading: boolean) => {
  isLoading.value = loading
}
</script>

<template>
  <div class="create-content-wrap">
    <NovaLayoutScrollContainer class="create-content">
      <div class="content-top">
        <span class="profile">
          <NovaUserCell
            :portrait="userStore.user?.userProflUrl"
            :user-name="userStore.user?.userNcnm"
            :user-sn="userStore.user?.userSn"
            :creator-link="userStore.user?.creatorLink"
          />
        </span>
      </div>

      <div class="select-box">
        <NovaDropdown
          :placeholder="$t('postCreate.placeholder.tempSave')"
          :menus="[]"
          @click="openTempList"
        />
      </div>

      <div class="content-title">
        <NovaInput
          v-model="state.title"
          class="input-title"
          :max-byte="200"
          :placeholder="$t('postCreate.placeholder.title')"
        />
      </div>

      <div class="editor-section">
        <NovaTipTap
          v-model="state.bodyData"
          :placeholder="$t('postCreate.placeholder.content')"
          :is-loading="isLoading"
          @on-loading="handleOnTipTapLoading"
        />
      </div>
    </NovaLayoutScrollContainer>

    <div class="content-action">
      <NovaButtonText
        :label="$t('postCreate.button.tempSave')"
        :theme="'transparent'"
        :size="32"
        class="btn-action"
        @click="handleOnTempSave"
      />

      <NovaButtonText
        :label="$t('postCreate.button.preview')"
        :theme="'secondary-gray'"
        :size="32"
        class="btn-action"
        @click="handleOnPreview"
      />

      <NovaButtonText
        :label="$t('postCreate.button.save')"
        :theme="'primary-blue-light'"
        :size="32"
        class="btn-action"
        @click="handleOnSave"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.create-content-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100%;
}

.create-content {
  padding: 0 20px;
}

.content-top {
  display: flex;
  align-items: center;

  .profile {
    display: flex;
    align-items: center;

    .text {
      margin-left: 10px;
      @include text-style($text-body-14-bold);
    }
  }
  .scope {
    margin-left: auto;
  }
}

.select-box {
  display: flex;
  margin-top: 20px;
  width: 100%;
}

.content-title,
.editor-section,
.hashtag-wrap {
  margin-top: 12px;
}

.editor-section {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid $color-bg-custom-2;
  border-radius: 8px;
}

.content-action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  padding: 20px 20px 51px;

  > .btn-action {
    width: 100px;
  }
}

@include mobile {
  .create-content-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;

    position: relative;
  }

  .create-content {
    flex-grow: 1;
  }
  .content-action {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    padding-bottom: 24px;

    :deep(.btn) {
      width: 100%;
    }
  }
}
</style>
