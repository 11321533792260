<script setup lang="ts">
import { LayerType } from '@store'

const emit = defineEmits(['goBack', 'toggleShowPanel'])
const { gtEvent } = useGoogleTag()
const { t } = useI18n()
const userStore = useUserStore()
const layoutStore = useLayoutStore()
// const appStore = useAppStore()

const handleOnGoBack = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '유저 패널 > 설정 > 뒤로 가기 버튼',
    eventLabel: t('settings'),
    eventSlot: '헤더',
    eventI18nAddr: useKoreanTranslation('settings'),
    eventComponent: 'Button',
  })
  emit('goBack')
}

const handleOnGoNotification = () => {
  layoutStore['layer:open'](LayerType.NOTIFICATION_SETTING_PANEL)
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '유저 패널 > 설정 > 알림 설정',
    eventLabel: t('notificationAndSetting'),
    eventSlot: '헤더',
    eventI18nAddr: useKoreanTranslation('notificationAndSetting'),
    eventComponent: 'Button',
  })
  emit('toggleShowPanel', null)
}

const handleOnGoAccountPage = () => {
  layoutStore['layer:open'](LayerType.USER_PROFILE_EDIT_PANEL)
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '유저 패널 > 설정 > 프로필 수정',
    eventLabel: t('account'),
    eventSlot: '헤더',
    eventI18nAddr: useKoreanTranslation('account'),
    eventComponent: 'Button',
  })
  emit('toggleShowPanel', null)
}

const handleOnGoMainProfilePage = () => {
  layoutStore['layer:open'](LayerType.USER_MAIN_PROFILE_EDIT_PANEL)
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '유저 패널 > 설정 > 메인 프로필 설정',
    eventLabel: t('creatorMainProfile.editMainProfile'),
    eventSlot: '헤더',
    eventI18nAddr: useKoreanTranslation('creatorMainProfile.editMainProfile'),
    eventComponent: 'Button',
  })
  emit('toggleShowPanel', null)
}

// const handleOnGoSubscribeOpen = () => {
//   gtEvent('clickEvent', {
//     eventCategory: '클릭',
//     eventAction: '유저 패널 > 설정 > 노바+ 혜택 설정',
//     eventLabel: t('subscribe.subscribeGate.benefitSetting'),
//     eventSlot: '유저 패널 > 설정',
//     eventI18nAddr: useKoreanTranslation(
//       'subscribe.subscribeGate.benefitSetting'
//     ),
//     eventComponent: 'Button',
//   })
//   useNavigations({
//     external: false,
//     url: `/creator/subscribe/opened/${userStore.user?.userSn}`,
//   })
//   emit('toggleShowPanel', null)
// }

// 노바+ 내역 이동 크리에이터 -> 후원자 목록 , 일반 > 후원중인 목록
// const handleOnGoSponsorsOpen = () => {
//   switch (userStore.user!.cmtyUserSeCode) {
//     case 'C':
//       useNavigations({ external: false, url: '/creator/sponsors' })
//       break
//     case 'G':
//       useNavigations({ external: false, url: '/user/sponsors' })
//       break
//     default:
//       break
//   }
//   gtEvent('clickEvent', {
//     eventCategory: '클릭',
//     eventAction: '유저 패널 > 설정 > 노바+ 내역',
//     eventLabel: t('subscribe.subscribeGate.balance', {
//       subscribeGrade: t('subscribe.subscribeGrade.novaPlus'),
//     }),
//     eventSlot: '유저 패널 > 설정',
//     eventI18nAddr: useKoreanTranslation('subscribe.subscribeGate.balance', {
//       subscribeGrade: useKoreanTranslation('subscribe.subscribeGrade.novaPlus'),
//     }),
//     eventComponent: 'Button',
//   })
//   emit('toggleShowPanel', null)
// }
</script>

<template>
  <NovaHeaderToolsPanelBase>
    <NovaHeaderCellsNavigationBar
      :label="t('settings')"
      @go-back="handleOnGoBack"
    />

    <NovaLayoutScrollContainer class="contents">
      <NovaHeaderMenuGroup :group-name="t('notification')">
        <template #menus>
          <NovaHeaderMenuItem
            :label="t('notificationAndSetting')"
            @click="handleOnGoNotification"
          />
        </template>
      </NovaHeaderMenuGroup>

      <NovaDivider />

      <NovaHeaderMenuGroup :group-name="t('profile')">
        <template #menus>
          <NovaHeaderMenuItem
            :label="t('account')"
            @click="handleOnGoAccountPage"
          />
          <NovaHeaderMenuItem
            v-if="userStore.user?.cmtyUserSeCode === 'C'"
            :label="t('creatorMainProfile.editMainProfile')"
            @click="handleOnGoMainProfilePage"
          />
        </template>
      </NovaHeaderMenuGroup>

      // TODO: 앱 버전 릴리즈를 위해 기능 숨김
      <!--      <NovaDivider
        v-if="
          !(appStore.isApp || appStore.envMode === 'production') &&
          userStore.user?.cmtyUserSeCode === 'C'
        "
      />-->

      <!--      <NovaHeaderMenuGroup
        v-if="
          !(appStore.isApp || appStore.envMode === 'production') &&
          userStore.user?.cmtyUserSeCode === 'C'
        "
        :group-name="t('subscribe.subscribeGrade.novaPlus')"
      >
        <template #menus>
          <NovaHeaderMenuItem
            :label="
              t('subscribe.subscribeGate.benefitSetting', {
                subscribeGrade: t('subscribe.subscribeGrade.novaPlus'),
              })
            "
            @click="handleOnGoSubscribeOpen"
          />

          <NovaHeaderMenuItem
            v-if="appStore.envMode !== 'production'"
            :label="
              t('subscribe.subscribeGate.balance', {
                subscribeGrade: t('subscribe.subscribeGrade.novaPlus'),
              })
            "
            @click="handleOnGoSponsorsOpen"
          />
        </template>
      </NovaHeaderMenuGroup>-->
    </NovaLayoutScrollContainer>
  </NovaHeaderToolsPanelBase>
</template>

<style lang="scss" scoped>
.contents {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px 20px;
}

.badge-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;

  .bedge-text {
    font-size: 12px;
    background-color: #347ae2;
    border-radius: 3px;
    color: #fff;
    padding: 2px 5px;
  }
}
</style>
