<script setup lang="ts">
const layoutStore = useLayoutStore()
const { gtEvent } = useGoogleTag()

const handleOnClickMenu = () => {
  layoutStore['layer:toggle'](LayerType.MENU_PANEL)
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: `메뉴 패널 ${layoutStore.layers.length ? '열기' : '닫기'}`,
    eventLabel: 'grid',
    eventSlot: '헤더',
    eventI18nAddr: '',
    eventComponent: 'Button',
  })
}
</script>

<template>
  <div class="nova-header-tools-menu">
    <NovaButtonGNB
      :icon="{ type: 'outline', name: 'menu' }"
      class="toggle-panel-button"
      toggle-panel="menu"
      @click="handleOnClickMenu"
    />

    <NovaHeaderMenuPanel :panel-name="'menu'" />
  </div>
</template>

<style scoped lang="scss"></style>
