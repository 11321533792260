<script setup lang="ts">
import type { NovaHashtagProps } from './NovaHashtag.types'

const props = withDefaults(defineProps<NovaHashtagProps>(), {
  on: false,
  ableAction: false,
  prefix: '',
})

const hashtagName = computed(() =>
  typeof props.hashtag === 'object' ? props.hashtag.hashtagNm : props.hashtag
)
</script>

<template>
  <div :class="['hashtag', { 'able-action': ableAction }, { on }]">
    <span class="label">{{ `${prefix} ` }}{{ hashtagName }}</span>
    <slot name="tools" />
  </div>
</template>

<style lang="scss" scoped>
.hashtag {
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  width: auto;
  max-width: 100%;
  height: 28px;
  padding: 3px 10px;
  border-radius: 14px;
  background-color: $color-bg-1;
  @include transition(background-color 0.2s ease-in-out);

  &.able-action {
    cursor: pointer;
  }

  &.on {
    background-color: $color-secondary-blue-light-10;

    > .label {
      color: $color-text-2;
    }
  }

  > .label {
    @include text-style($text-body-14-regular);
    color: $color-text-4;
    @include ellipsis(1);
    @include transition(color 0.2s ease-in-out);
  }
}
</style>
