import type { ShareType } from '@store'

/**
 * 공유하기
 */
export const share = {
  label: '공유하기',
  actions: {
    facebook: '페이스북에 공유',
    twitter: '트위터에 공유',
    kakao: '카카오톡에 공유',
    clipboard: '링크 복사',
  } as { [Property in ShareType]: string },
}
