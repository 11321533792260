import type { KycAuthStatus } from './useKyc'

interface UseDonateCreator {
  userSn: number
  userNcnm: string
  cmtyNttSn?: number
}

/**
 * 후원하기
 */
export const useDonate = async (creator: UseDonateCreator) => {
  const { t } = useNuxtApp().$i18n
  const { userStore, showSignInDialog, getPrivateKey } = useMembershipProcess()
  const layoutStore = useLayoutStore()
  const { show: modalShow } = useModal()
  const { checkKycAuthStatus, openKycProcess } = useKyc()
  const kycAuthStatus = ref<KycAuthStatus>('tryYet')
  const privateKey = ref<string | null>()

  // 1. 비로그인시 로그인 유도
  if (!userStore.isSignIn) {
    await showSignInDialog()

    return
  }

  // 2. KYC 인증 여부 조회
  kycAuthStatus.value = await checkKycAuthStatus()

  // 3. KYC 인증 여부 없으면 KYC 인증 유도
  if (kycAuthStatus.value !== 'approve') {
    await openKycProcess({ purpose: 'donate', kycStatus: kycAuthStatus.value })
    return
  }

  // 4. 프라이빗 키 가져오기
  try {
    layoutStore.updateLoadingIndicatorGlobal({ show: true })
    privateKey.value = await getPrivateKey(userStore.user!.userSn)
  } catch {
    useToast(t('donateAction.error.network'))
    return
  } finally {
    layoutStore.updateLoadingIndicatorGlobal({ show: false })
  }

  // 4-1. 프라이빗 키 있으면 후원하기 다이얼로그 모달 열기
  if (privateKey.value) {
    await modalShow(modalsName.MODAL_DONATE, {
      creator,
      privateKey: privateKey.value,
      cmtyNttSn: creator.cmtyNttSn || 0,
    })

    return
  }

  // 4-2. 프라이빗 키 없으면 에러 메세지 호출(kyc 인증이 되었다면 무조건 있는상태)
  console.log('privateKey 없음')
}
