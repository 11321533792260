<script setup lang="ts">
import { Codemirror } from 'vue-codemirror'
import { javascript } from '@codemirror/lang-javascript'
import { json } from '@codemirror/lang-json'
import { oneDark } from '@codemirror/theme-one-dark'
import type {
  CodeMirrorPayload,
  NovaCodeEditorProps,
} from './NovaCodeEditor.types'

const emit = defineEmits({
  'update:code': (_code: string) => true,
})
const props = withDefaults(defineProps<NovaCodeEditorProps>(), {
  codeType: 'javascript',
})

const code = ref(props.initCode)
const extensions = [oneDark]

// set code type
switch (props.codeType) {
  case 'javascript':
    extensions.push(javascript())
    break
  case 'json':
    extensions.push(json())
    break
}

// Codemirror EditorView instance ref
const view = shallowRef()

const handleOnReady = (payload: CodeMirrorPayload) => {
  view.value = payload.view
}

const handleOnChange = (code: string) => {
  emit('update:code', code)
}
</script>

<template>
  <div class="code-editor">
    <Codemirror
      v-model="code"
      placeholder="Code goes here..."
      :autofocus="true"
      :indent-with-tab="true"
      :tab-size="2"
      :extensions="extensions"
      @ready="handleOnReady"
      @change="handleOnChange"
    />
  </div>
</template>
