<script setup lang="ts">
import type { NovaDevToolsBridgeToolProps } from './NovaDevToolsBridgeTool.types'
import { BridgeException } from '@bridge'

const props = defineProps<NovaDevToolsBridgeToolProps>()

const appStore = useAppStore()
const { appType, currentVer } = storeToRefs(appStore)
const { checkForceUpdateVersion } = useCheckVersion()
const code = ref(props.bridge.message)
const loading = ref(false)

const updateRequireStatus = computed(() => {
  if (currentVer.value === null || appType?.value === null) {
    return 'Unknown'
  }

  return checkForceUpdateVersion(
    currentVer.value,
    props.bridge.version[appType.value]
  )
    ? '미필요'
    : '필요'
})
const isUpdateRequire = computed(() => updateRequireStatus.value === '필요')

const handleOnBridgeRequest = async () => {
  try {
    loading.value = true

    console.log(
      `DevTool Bridge request [${props.bridge.handlerKey}] [${props.bridge.direction}] request:`,
      code.value
    )

    useToast(
      `DevTool Bridge request [${props.bridge.handlerKey}] [${props.bridge.direction}] success`
    )

    // toApp
    if (props.bridge.direction === 'WEB → APP') {
      await props.bridge.bridge(code.value)
    }

    // toWeb
    else if (props.bridge.direction === 'APP → WEB') {
      props.bridge.bridge(JSON.stringify(code.value))
    }

    // console.log(
    //   `DevTool Bridge request [${props.bridge.handlerKey}] [${props.bridge.direction}] response:`,
    //   res
    // )

    // useToast(
    //   `DevTool Bridge response [${props.bridge.handlerKey}] [${props.bridge.direction}] success`
    // )
  } catch (err) {
    if (err instanceof BridgeException) {
      useToast(err.message)
    }
  } finally {
    loading.value = false
  }
}

const handleOnCodeUpdate = (value: string) => {
  code.value = JSON.parse(value)
}
</script>

<template>
  <div class="bridge-tool">
    <div class="bridge-handler-key">
      <h4>
        '{{ bridge.handlerKey }}' <small>({{ bridge.direction }})</small>
      </h4>
      <div class="tools">
        <NovaButtonText
          :label="'request'"
          :theme="isUpdateRequire ? 'primary-red' : 'primary-blue'"
          :size="32"
          :loading="loading"
          @click="handleOnBridgeRequest"
        />
      </div>
    </div>

    <div class="bridge-meta">
      <dl>
        <dt>호출 방향</dt>
        <dd>{{ bridge.direction }}</dd>
      </dl>

      <dl>
        <dt>설명</dt>
        <dd>{{ bridge.description }}</dd>
      </dl>

      <dl>
        <dt>버전</dt>
        <dd>
          <p>AOS {{ bridge.version.AOS }}</p>
          <p>iOS {{ bridge.version.IOS }}</p>
        </dd>
      </dl>

      <dl :class="{ alert: isUpdateRequire }">
        <dt>업데이트</dt>
        <dd>{{ updateRequireStatus }}</dd>
      </dl>

      <dl>
        <dt>메세지:</dt>
        <dd>
          <NovaCodeEditor
            :init-code="JSON.stringify(bridge.message || {})"
            :code-type="'json'"
            @update:code="handleOnCodeUpdate"
          />
        </dd>
      </dl>

      <!--      <dl>-->
      <!--        <dt>메세지(res):</dt>-->
      <!--        <dd>-->
      <!--          <NovaCodeEditor-->
      <!--            :init-code="JSON.stringify(bridge.message.res)"-->
      <!--            :code-type="'json'"-->
      <!--            @update:code="handleOnCodeUpdate($event, 'res')"-->
      <!--          />-->
      <!--        </dd>-->
      <!--      </dl>-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.bridge-tool {
  display: flex;
  flex-direction: column;

  .bridge-handler-key {
    position: sticky;
    top: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;
    @include text-style($text-body-14-bold);
    color: $color-primary-green;
    background-color: $color-white;
    z-index: 1;

    small {
      color: $color-text-4;
    }

    .tools {
      display: flex;
      gap: 4px;
    }
  }

  .bridge-meta {
    display: flex;
    flex-direction: column;
    gap: 4px;

    dl {
      display: flex;
      gap: 12px;

      @include mobile {
        flex-direction: column;
        gap: 0;

        dt {
          margin-top: 12px;
        }
      }

      &.alert {
        color: $color-primary-red;
      }

      dt {
        flex-shrink: 0;
        display: flex;
        align-items: flex-start;
        gap: 4px;
        width: 100px;
        @include text-style($text-body-14-bold);

        &:before {
          display: block;
          content: '•';
        }

        &:after {
          display: block;
          content: ':';
        }
      }

      dd {
        flex-grow: 1;
      }
    }
  }
}
</style>
