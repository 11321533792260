import { commonError } from './commonError'

export const blockMessage = {
  messages: {
    blockedByOtherUserPrimary: '<strong>{userNcnm}</strong> blocked me.',
    blockedByOtherUserSecondary:
      "You cannot follow <strong>{userNcnm}</strong> or see <strong>{userNcnm}</strong>'s contents.",
    blockByCurrentUser: '<strong>{userNcnm}</strong> is blocked.',
    showContentsPrimary: 'Do you want to view these contents?',
    showContentsSecondary: 'Viewing contents does not unblock {userNcnm}.',
    unBlocking: 'Do you want to unblock?',
  },
  modal: {
    block: {
      title: 'Block',
      contents: {
        title: 'Do you want to block <strong>{userNcnm}</strong>?',
        message: 'The user cannot follow me or see my contents.',
        novaPlusMessage:
          'The user is a supporter of your NOVA+.<br />Blocking NOVA+ supporters without the valid reasons(such as swearing/stalking) may be subjected to sanctions.',
      },
    },
    unblock: {
      title: 'Unblock',
      contents: {
        title: 'Do you want to unblock <strong>{userNcnm}</strong>?',
        message: 'The user can follow me and see my contents.',
      },
    },
  },
  toastMessage: {
    errors: {
      2010: 'The user is already blocked.', // already block error
      2011: 'The user is not blocked.', // already unblock error
      4002: 'This is an unregistered member.',
      4013: 'This is an unregistered member.',
      unknown: commonError.network.message,
    },
    blocking: 'Blocked',
    unBlocking: 'Unblocked',
  },
  actions: {
    blocked: 'Blocked',
    showContents: 'View Contents',
    unBlock: 'Unblock',
    block: 'Block',
    cancel: 'Cancel',
  },
}
