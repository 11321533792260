import { locales } from '@configs'

export const useClearRoutePathLocale = (path: string) => {
  const localeCodes = locales
    .filter((item) => !item.default)
    .map((item) => item.code)

  let result = path.replace(/^(\/)/, '')
  const matchCode = localeCodes.find((code) => result.startsWith(code))

  if (matchCode) {
    result = result.replace(matchCode, '').replace(/^(\/)/, '')
  }

  return result
}
