/**
 * 크리에이터 방명록
 */
export const creatorNotice = {
  regCreatorNoticeModal: {
    title: 'Write',
    textareaPlaceholder: 'Insert the content',
    actions: {
      negative: 'Cancel',
      positive: 'Write',
    },
  },
  request: {
    actions: {
      delete: 'Delete',
    },
    list: {
      empty: "There's no post here",
      error: "It can't be loaded currently. Please try again later",
    },
    delete: {
      error: 'You can not delete the post right now. Please try again later',
      success: 'The post has been deleted',
    },
    reg: {
      stopReg:
        'If you close the window before saving, the post will be deleted',
      validLength: 'Insert the content',
      error: 'The post has not been uploaded. Please try again later',
      success: 'The post has been successfully uploaded',
    },
  },
}
