<script setup lang="ts">
import type {
  NovaBoxPostCreateGatePanelEmits,
  NovaBoxPostCreateGatePanelProps,
} from './NovaBoxPostCreateGatePanel.types'
import type {
  FileImageContainer,
  FileVideoContainer,
  NttTypeCode,
} from '@store'
import { NovaModalCommon, NovaModalPostCreate } from '#components'

const emit = defineEmits<NovaBoxPostCreateGatePanelEmits>()
const props = defineProps<NovaBoxPostCreateGatePanelProps>()

const { gtEvent } = useGoogleTag()
const { createPremiumPost } = toRefs(props)
const { t } = useI18n()
const { userStore, showSignInDialog } = useMembershipProcess()
const { show: modalShow, hide: modalHide } = useModal()
const appStore = useAppStore()
const { checkPermission, requestPermission } = useAppPermission()

const handleOnProcess = async (processType: NttTypeCode) => {
  // 로그인 여부 확인
  if (!userStore.isSignIn) {
    await showSignInDialog()
    gtEvent('clickEvent', {
      eventCategory: '클릭',
      eventAction: '포스트 작성 버튼 클릭',
      eventLabel: t('createPost'),
      eventSlot: '로그인 필요',
      eventI18nAddr: useKoreanTranslation('createPost'),
      eventComponent: 'Button',
    })
    emit('onHide')
    return
  }

  // 프리미엄 포스트 생성 가능 여부 확인
  if (createPremiumPost.value && userStore.user?.prmbrshAt === 'N') {
    useToast(
      t('subscribe.premiumPosts.empty', {
        subscribeGrade: t('subscribe.subscribeGrade.novaPlus'),
      })
    )
    gtEvent('clickEvent', {
      eventCategory: '클릭',
      eventAction: '포스트 작성 버튼 클릭',
      eventLabel: t('createPost'),
      eventSlot: '프리미엄 포스트 생성 가능 여부 확인',
      eventI18nAddr: useKoreanTranslation('createPost'),
      eventComponent: 'Button',
    })
    emit('onHide')
    return
  }

  // 앱 일때 스토리지 권한 체크
  if (appStore.isApp) {
    const { data: permission } = await requestPermission('storage')

    if (permission.status === 'off') {
      const accessAble = await checkPermission({
        permission: 'storage',
        permissionType: 'post',
      })
      if (!accessAble) return
    }
  }

  // 포스트 개설 모달 열기
  switch (processType) {
    // 일반형 포스트 생성
    case 'NORM':
      gtEvent('clickEvent', {
        eventCategory: '클릭',
        eventAction: '포스트 작성 > 일반 포스트',
        eventLabel: t('postCreate.type.normal'),
        eventSlot: '',
        eventI18nAddr: useKoreanTranslation('postCreate.type.normal'),
        eventComponent: 'Button',
      })
      await handleOnOpenModalPostCreate({
        processType: 'NORM',
      })
      break
    // 카드형 포스트 생성
    case 'CARD':
      gtEvent('clickEvent', {
        eventCategory: '클릭',
        eventAction: '포스트 작성 > 카드형 포스트',
        eventLabel: t('postCreate.type.card'),
        eventSlot: '',
        eventI18nAddr: useKoreanTranslation('postCreate.type.card'),
        eventComponent: 'Button',
      })
      await handleOnOpenModalPostCreate({
        processType: 'CARD',
        imgOrVideoFile: await uploadFileImages(true),
      })
      break
    // 비디오형 포스트 생성
    case 'CVOD':
      gtEvent('clickEvent', {
        eventCategory: '클릭',
        eventAction: '포스트 작성 > 영상형 포스트',
        eventLabel: t('postCreate.type.vod'),
        eventSlot: '',
        eventI18nAddr: useKoreanTranslation('postCreate.type.vod'),
        eventComponent: 'Button',
      })
      await handleOnOpenModalPostCreate({
        processType: 'CVOD',
        imgOrVideoFile: await uploadFileVideo(true),
      })
      break
    default:
      throw new Error('create post gate type is not match')
  }

  emit('onHide')
}

const handleOnOpenModalPostCreate = async ({
  processType,
  imgOrVideoFile,
}: {
  processType: NttTypeCode
  imgOrVideoFile?: FileImageContainer | FileVideoContainer
}) => {
  await modalShow({
    component: NovaModalCommon,
    bind: {
      name: modalsName.MODAL_CREATE_POST,
      showCloseBtn: true,
      mobileFull: true,
      hasInnerScroll: true,
      type: 'create',
    },
    on: {
      customClose: () => {
        gtEvent('clickEvent', {
          eventCategory: '클릭',
          eventAction: '포스트 작성 모달 닫기',
          eventLabel: t('createPost'),
          eventSlot: '모달 닫기',
          eventI18nAddr: useKoreanTranslation('createPost'),
          eventComponent: 'Button',
        })
        useEvent('post:createClose')
      },
    },
    slots: {
      title: t('createPost'),
      contents: {
        component: NovaModalPostCreate,
        bind: {
          createPostType: processType,
          imgOrVideoFile,
          createPremiumPost: createPremiumPost.value,
        },
        on: {
          close: async () => {
            await modalHide(modalsName.MODAL_CREATE_POST)
          },
        },
      },
    },
  })
}
</script>

<template>
  <NovaBoxBase class="box-create-post-gate" :floating="true">
    <ul>
      <li>
        <button
          type="button"
          class="gate-item"
          @click.stop="() => handleOnProcess('NORM')"
        >
          <span class="label">{{ t('postCreate.type.normal') }}</span>
          <span class="description">
            {{ t('postCreate.typeDesc.normal') }}
          </span>
        </button>
      </li>

      <li>
        <button
          type="button"
          class="gate-item"
          @click.stop="() => handleOnProcess('CARD')"
        >
          <span class="label">{{ t('postCreate.type.card') }}</span>
          <span class="description">{{ t('postCreate.typeDesc.card') }}</span>
        </button>
      </li>

      <li>
        <button
          type="button"
          class="gate-item"
          @click.stop="() => handleOnProcess('CVOD')"
        >
          <span class="label">{{ t('postCreate.type.vod') }}</span>
          <span class="description">{{ t('postCreate.typeDesc.vod') }}</span>
        </button>
      </li>
    </ul>
  </NovaBoxBase>
</template>

<style lang="scss" scoped>
.box-create-post-gate {
  position: relative;
  min-width: 306px;
  border: 1px solid $color-ea;

  ul {
    display: flex;
    flex-direction: column;
    gap: 20px;

    li {
      .gate-item {
        display: flex;
        flex-direction: column;
        gap: 3px;
        @include text-style($text-body-12-regular);

        > .label {
          position: relative;
          display: block;
          padding-left: 28px;
          @include text-style($text-heading3-bold);
          line-height: 23px;

          &:before {
            flex-shrink: 0;
            display: block;
            content: '';
            width: 24px;
            height: 24px;
            background-size: 100% 100%;
            background-position: center center;
            background-repeat: no-repeat;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
          }
        }

        > .description {
          display: block;
          @include text-style($text-body-14-regular);
          color: $color-99;
          line-height: 20px;
          text-align: left;
          word-break: break-all;
        }
      }

      &:nth-child(1) {
        .gate-item > .label:before {
          background-image: url('assets/images/icon-post-default.png');
        }
      }

      &:nth-child(2) {
        .gate-item > .label:before {
          background-image: url('assets/images/icon-post-card.png');
        }
      }

      &:nth-child(3) {
        .gate-item > .label:before {
          background-image: url('assets/images/icon-post-media.png');
        }
      }
    }
  }
}
</style>
