import type { CreatorProfile } from '@store'

export enum ChatButtonMode {
  FLOATING = 'floating',
  HEADER = 'header',
  CREATOR_HOME = 'creator-home',
}

export interface NovaChatButtonProps {
  mode: ChatButtonMode
  creator?: CreatorProfile | null
}
