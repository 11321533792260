import * as SendbirdType from './type'
import SendbirdService from './service'
import { initSendbirdState } from './init'

export * from './init'
export * from './type'
export const useSendbirdStore = defineStore('sendbird', () => {
  const myPageStore = useMyPageStore()

  const onAirLives = ref(initSendbirdState.onAirLives)

  /**
   * 라이브 생성 권한 체크
   */
  const fetchCreateLivePermission = async () => {
    const { data } = await SendbirdService.fetchCreateLivePermission()
    return data
  }

  /**
   * 라이브 토큰 게이팅 대상 목록 조회 - 플레이놈 NFT Collection 목록 조회
   */
  const fetchLiveNftCollections = async () => {
    const { data } = await SendbirdService.fetchLiveNftCollections()
    return data
  }

  /**
   * 라이브 생성
   * @param payload
   */
  const createLiveEvent = async (payload: SendbirdType.CreateLivePayload) => {
    const { data } = await SendbirdService.createLiveEvent(payload)
    return data
  }

  /**
   * 라이브 입장 가능 여부 확인: token & sndbrdLiveSn 조건
   * @param payload
   */
  const fetchEnterLiveAvailable = async (
    payload: SendbirdType.EnterLiveAvailablePayload
  ) => {
    const { data } = await SendbirdService.fetchEnterLiveAvailable(payload)
    return data
  }

  /**
   * 라이브 입장 가능 여부 확인: sendbird user id & sendbird live event id 조건
   * @param payload
   */
  const fetchEnterLiveAvailableBySbUserId = async (
    payload: SendbirdType.EnterLiveAvailableBySbUserIdPayload
  ) => {
    const { data } = await SendbirdService.fetchEnterLiveAvailableBySbUserId(
      payload
    )
    return data
  }

  /**
   * Sendbird userId 생성
   */
  const createSendbirdUserId = async () => {
    const { data } = await SendbirdService.createSendbirdUserId()

    if (myPageStore.profile) {
      myPageStore.profile.sndbrdUserId = data.sendbirdUserId
    }

    return data
  }

  /**
   * OnAir 라이브 목록 조회
   * @param refresh
   */
  const fetchOnAirLives = async (refresh = false) => {
    onAirLives.value.loading = true
    const payload: SendbirdType.OnAirLivesPayload = {
      ...onAirLives.value.payload,
    }

    if (refresh) {
      delete payload.lastSndbrdLiveSn
    } else {
      payload.lastSndbrdLiveSn =
        onAirLives.value.items[onAirLives.value.items.length - 1].sndbrdLiveSn
    }

    const { data, headers } = await SendbirdService.fetchOnAirLives(payload)
    const items = refresh ? data : onAirLives.value.items.concat(data)
    onAirLives.value = {
      items,
      payload,
      refresh,
      loading: false,
      last: typeof headers['pagination-location'] === 'undefined',
    }
  }

  /**
   * 오픈 채널 생성
   */
  const createOpenChannel = async (
    payload: SendbirdType.CreateOpenChannelPayload
  ) => {
    const { data } = await SendbirdService.createOpenChannel(payload)

    if (myPageStore.profile) {
      myPageStore.profile.channelUrl = data.channelUrl
      myPageStore.profile.sndbrdUserId = data.sendbirdUserId
    }

    return data
  }

  return {
    onAirLives,
    fetchCreateLivePermission,
    fetchLiveNftCollections,
    createLiveEvent,
    fetchEnterLiveAvailable,
    fetchEnterLiveAvailableBySbUserId,
    createSendbirdUserId,
    fetchOnAirLives,
    createOpenChannel,
  }
})
