<script setup lang="ts">
import { useScriptTag } from '@vueuse/core'
import { kakaoSdkSource } from '@configs'

const config = useRuntimeConfig()

const initKakaoSdk = () => {
  if (!window.Kakao) {
    window.Kakao.init(config.public.KAKAO_APP_KEY)
  }
}

const { load, unload } = useScriptTag(kakaoSdkSource, initKakaoSdk)

onBeforeMount(load)
onBeforeUnmount(unload)
</script>

<template>
  <slot />
</template>
