<script setup lang="ts">
import type {
  NovaDropdownItemEmits,
  NovaDropdownItemProps,
  DropdownItemType,
} from './NovaDropdownItem.types'

const emit = defineEmits<NovaDropdownItemEmits>()
const props = withDefaults(defineProps<NovaDropdownItemProps>(), {
  activeValue: '',
})

const on = computed(() => props.activeValue === props.item.value)
const handleOnChangeValue = (value: DropdownItemType['value']) => {
  emit('update', value)
}
</script>

<template>
  <div class="drop-down-menu-item">
    <button
      type="button"
      :class="[
        'inner',
        { on },
        { 'has-children': item.children && item.children.length },
      ]"
      @click="() => handleOnChangeValue(item.value)"
    >
      <span class="label">{{ $props.item.label }}</span>
      <!-- <NovaIcon  :icon="{type:'outline', name: 'check'}" /> -->
    </button>

    <div class="children">
      <button
        v-for="child in item.children"
        :key="child.value"
        :class="['inner', { on }]"
        type="button"
        @click="() => handleOnChangeValue(child.value)"
      >
        <span class="label">{{ child.label }}</span>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.drop-down-menu-item {
  display: flex;
  flex-direction: column;
  width: 100%;

  .children {
    .inner {
      padding-left: 24px;
    }
  }

  .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 32px;
    @include text-style($text-body-14-regular);
    color: $color-black-60p;
    padding: 6px 12px;
    @include transition(all 0.15s ease-in-out);

    @media (hover: hover) {
      &:hover {
        background-color: $color-bg-1;
      }
    }

    &.on {
      background-color: $color-bg-1;

      > .label {
        color: $color-black-90p;
      }

      > i {
        opacity: 1;
      }
    }

    &.has-children {
      pointer-events: none;
      cursor: default;

      color: $color-text-5;
    }

    > .label {
      font-size: 14px;
      text-align: left;
    }

    > i {
      color: $color-black-60p;
      opacity: 0;
    }
  }
}
</style>
