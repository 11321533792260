<script setup lang="ts">
import { Tippy } from 'vue-tippy'

const { gtEvent } = useGoogleTag()
const { t } = useI18n()
const userStore = useUserStore()
const editMode = ref(false)
const phoneNumber = ref(userStore.user?.mobile || '')
const loading = ref(false)

watch(
  () => editMode.value,
  (cur) => {
    if (!cur) {
      initFormUnit()
    }
  }
)

const handleOnToggleEditMode = () => {
  editMode.value = !editMode.value
  gtEvent('userAction', {
    eventCategory: '사용자',
    eventAction: `휴대전화 번호 변경 ${editMode.value ? '열기' : '닫기'}`,
    eventLabel: t('accountPanel.settings.phoneNumber.title'),
    eventSlot: '프로필 수정 > 휴대전화 번호',
    eventI18nAddr: useKoreanTranslation(
      'accountPanel.settings.phoneNumber.title'
    ),
    eventComponent: 'Button',
  })
}

const initFormUnit = () => {
  phoneNumber.value = userStore.user?.mobile || ''
}

const handleOnValid = () => {
  const valid = !phoneNumber.value.length

  if (valid) {
    useToast(t('accountPanel.settings.phoneNumber.valid.empty'))
  }

  return valid
}

const handleOnCancel = () => {
  editMode.value = false
  gtEvent('userAction', {
    eventCategory: '사용자',
    eventAction: '휴대전화 번호 변경 취소 버튼 클릭',
    eventLabel: t('cancel'),
    eventSlot: '프로필 수정 > 닉네임 변경 > 취소',
    eventI18nAddr: useKoreanTranslation('cancel'),
    eventComponent: 'Button',
  })
}

const handleOnSavePhoneNumber = () => {
  const valid = handleOnValid()

  if (valid) return

  try {
    loading.value = true
    userStore.updatePhoneNumber({
      userCmmnSn: userStore.userCommonInfo!.userCmmnSn,
      modifyMobile: phoneNumber.value,
    })
    editMode.value = false
    gtEvent('userAction', {
      eventCategory: '사용자',
      eventAction: '저장 버튼 클릭',
      eventLabel: t('save'),
      eventSlot: '프로필 수정 > 휴대전화 번호 변경 > 저장',
      eventI18nAddr: useKoreanTranslation('save'),
      eventComponent: 'Button',
    })
    useToast(
      t('accountPanel.settings.phoneNumber.messages.success', {
        phoneNumber: phoneNumber.value,
      })
    )
  } catch (err) {
    const status = (err as any).response.status

    switch (status) {
      case 2006:
      case 4000:
      case 4002:
      case 4003:
        useToast(
          t(`accountPanel.settings.phoneNumber.messages.error.${status}`)
        )
        break
      default:
        useToast(t('accountPanel.settings.phoneNumber.messages.error.unknown'))
        break
    }
  } finally {
    loading.value = false
  }
}
</script>

<template>
  <NovaBoxAccountSection
    :title="t('accountPanel.settings.phoneNumber.title')"
    :guide="editMode ? t('accountPanel.settings.phoneNumber.guide') : ''"
  >
    <template #contents>
      <div class="phone-number-wrap">
        <div v-if="!editMode" class="inner">
          <p class="phone-number">{{ userStore.user?.mobile || '-' }}</p>
        </div>

        <div v-else class="inner">
          <NovaInputSimple
            v-model="phoneNumber"
            :full-width="true"
            :readonly="loading"
            :placeholder="t('accountPanel.settings.phoneNumber.placeholder')"
          />

          <div class="actions">
            <NovaButtonText
              :size="32"
              :label="$t('cancel')"
              :theme="'secondary-gray'"
              :loading="loading"
              @click="handleOnCancel"
            />

            <NovaButtonText
              :size="32"
              :label="$t('save')"
              :theme="'primary-blue'"
              :loading="loading"
              @click="handleOnSavePhoneNumber"
            />
          </div>
        </div>
      </div>
    </template>

    <template #tools>
      <Tippy
        :append-to="'parent'"
        :content="t('modify')"
        :interactive="true"
        :theme="'simple'"
        :placement="'bottom'"
      >
        <NovaButtonIcon
          :icon="{ type: 'outline', name: 'write' }"
          :size="16"
          :loading="loading"
          class="btn-clipboard"
          @click="handleOnToggleEditMode"
        />
      </Tippy>
    </template>
  </NovaBoxAccountSection>
</template>

<style lang="scss" scoped>
.phone-number-wrap {
  > .inner {
    display: flex;
    flex-direction: column;
    gap: 12px;

    > .actions {
      display: flex;
      justify-content: flex-end;
      gap: 12px;
    }
  }
}
</style>
