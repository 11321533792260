<script setup lang="ts">
import type { NovaModalDialogEmits } from './NovaModalDialog.types'

defineOptions({
  inheritAttrs: false,
})
const emit = defineEmits<NovaModalDialogEmits>()
</script>

<template>
  <vue-final-modal
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    :click-to-close="false"
  >
    <span class="modal__title">
      <slot name="title" />
    </span>
    <div
      class="modal__content"
      :style="{ textAlign: $attrs.contentsTextAlign || 'left' }"
    >
      <slot name="content" />
    </div>
    <div
      :class="[
        'modal__action',
        'grid-wrapper',
        `grid-${
          typeof $attrs.btns !== 'undefined'
            ? $attrs.btns.length
            : $attrs.btn.grid
        }`,
        'gap-4',
      ]"
    >
      <NovaButtonText
        v-if="typeof $attrs.btns === 'undefined' && $attrs.btn.isCancel"
        :label="$t('cancel')"
        :theme="'transparent'"
        :size="32"
        :full-width="true"
        @click="emit('cancel')"
      />

      <NovaButtonText
        v-if="typeof $attrs.btns === 'undefined'"
        :label="$t('confirm')"
        :theme="'primary-blue-light'"
        :size="32"
        :full-width="true"
        @click="emit('confirm')"
      />

      <NovaButtonText
        v-for="(item, index) in $attrs.btns || []"
        :key="index"
        :label="item.label"
        :theme="item.theme"
        :size="item.size"
        :full-width="true"
        @click="item.onClick"
      />
    </div>
  </vue-final-modal>
</template>

<style lang="scss" scoped>
::v-deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep(.modal-content) {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 240px;
  max-width: 350px;
  padding: 30px 20px;
  box-shadow: 5px 5px 10px hex-to-rgba($color-bg-custom-4, 0.05);
  border-radius: 20px;
  background: $color-white;
}
.modal__title {
  text-align: center;
  @include text-style($text-heading2-bold);
}
.modal__content {
  margin-top: 20px;
  flex-grow: 1;
  overflow-y: overlay;

  :deep(a) {
    color: $color-primary;
  }
}

.modal__action {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

@include mobile {
  ::v-deep(.modal-content) {
    padding: 20px;
  }
  .modal__title {
    text-align: center;
    @include text-style($text-heading3-bold);
  }
}
</style>
