import type { BooleanYn } from '@store/types'

/**
 * FCM Device
 */
export enum FirebaseDevice {
  AOS = 'AOS',
  IOS = 'IOS',
  WEB = 'WEB',
}

/**
 * Firebase Token
 */
export type FirebaseToken = string

/**
 * Send fcm token to server
 */
export interface SendFcmTokenToServerPayload {
  fcmDevice: FirebaseDevice
  fcmToken: FirebaseToken
  langCode: string
}

/**
 * Marketing agree
 */
export interface SaveMarketingAgreePayload extends SendFcmTokenToServerPayload {
  langCode: string
  marketingAgreeAt: BooleanYn
}

/**
 * Firebase api default response
 */
export interface FirebaseApiDefaultResponse {
  code: number
  message: string
}

/**
 * Firebase 스토어 스테이트 타입
 */
export interface FirebaseState {
  // firebaseWebToken: FirebaseToken
  // firebaseAppToken: FirebaseToken
}
