import * as FcmType from './type'
import FcmService from './service'

export * from './init'
export * from './type'
export const useFirebaseStore = defineStore('firebase', () => {
  const userStore = useUserStore()
  const { dayjs } = useDayjs()

  /**
   * FCM 토큰 등록
   * @param payload
   */
  const sendFcmTokenToServer = (payload: FcmType.SendFcmTokenToServerPayload) =>
    FcmService.sendFcmTokenToServer(payload)

  /**
   * 마케팅 동의 여부 등록
   */
  const saveMarketingPushAgree = async (
    payload: FcmType.SaveMarketingAgreePayload
  ) => {
    await FcmService.saveMarketingPushAgree(payload)

    // 사용자 정보 업데이트
    if (userStore.userCommonInfo) {
      userStore.userCommonInfo.marketingPushAgreeAt = payload.marketingAgreeAt
      userStore.userCommonInfo.marketingPushAgreeDt = dayjs
        .utc()
        .format() as unknown as Date
    }
  }

  return {
    sendFcmTokenToServer,
    saveMarketingPushAgree,
  }
})
