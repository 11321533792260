<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import type {
  NovaTipTapMenuColorPickerEmits,
  NovaTipTapMenuColorPickerProps,
} from './NovaTipTapMenuColorPicker.types'

const emit = defineEmits<NovaTipTapMenuColorPickerEmits>()
const props = withDefaults(defineProps<NovaTipTapMenuColorPickerProps>(), {
  label: '',
})

const target = ref(null)
const handleOnPickColor = (item: string) => {
  props.dataSet.sort === 'color'
    ? props.editor.chain().focus().setColor(item).run()
    : props.editor.chain().focus().toggleHighlight({ color: item }).run()
  emit('handleOnCloseBox', props.dataSet.sort, item)
}

onClickOutside(target, () => emit('handleOnCloseBox', props.dataSet.sort))
</script>

<template>
  <NovaBoxBase ref="target" :floating="true" class="color-picker">
    <h4 class="label">{{ label }}</h4>

    <div
      v-if="dataSet.sort === 'color'"
      class="color-chips grid-wrapper grid-7 gap-4"
    >
      <button
        v-for="(item, index) in dataSet.colors"
        :key="index"
        :style="{ backgroundColor: item }"
        :class="[
          'color-chip',
          { 'is-active': editor.isActive('textStyle', { color: item }) },
        ]"
        @click="handleOnPickColor(item)"
      />
    </div>

    <div
      v-else-if="dataSet.sort === 'highlight'"
      class="color-chips grid-wrapper grid-7 gap-4"
    >
      <button
        v-for="(item, index) in dataSet.colors"
        :key="index"
        :style="{ backgroundColor: item }"
        :class="['color-chip', { 'is-active': editor.isActive('highlight') }]"
        @click="handleOnPickColor(item)"
      />
    </div>
  </NovaBoxBase>
</template>

<style lang="scss">
.color-picker {
  display: flex;
  flex-direction: column !important;
  gap: 8px !important;

  > .label {
    @include text-style($text-body-14-bold);
    color: $color-text-2;

    &:empty {
      display: none;
    }
  }

  > .color-chips {
    display: flex;

    button {
      width: 24px;
      height: 24px;
    }
  }
}
</style>
