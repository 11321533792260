import type { FacilityState } from './type'

export const initFacilityState: FacilityState = {
  // 시설물 임대 신청 요건 정보(개인화 정보 포함)
  rentCheckConditions: null,
  // 시설물 임대 신청 폼
  rentApplyForm: {
    applcntNm: '', // 신청자 명
    applcntAdres: '', // 신청자 주소
    applcntCttpc: '', // 신청자 연락처
    applcntIntrcnCn: '', // 신청자 소개: 본인(팀) 소개와 활동 경력(200자 이상)
    applcntActPlanCn: '', // 신청자 활동 계획 내용: 입주 신청 사유 및 활동 계획
    applcntPrufFileList: [], // 포트폴리오 리스트
    applcntSnsUrl: '', // 신청자 SNS URL
    fcltrntCognpathCn: '', // 알게된 경로: 시설물임대 인지경로 내용
    privcyColctPrcuseAgreAt: 'N', // 개인정보 수집 활용 동의 여부(Y/N)
    privcyThptyProvdAgreAt: 'N', // 개인정보 제3자 제공 동의 여부(YN)
    fcltrntFcltsTyCode: '', // 배너 슬롯 시설물임대 시설물 분류 코드 : 시설물분류코드(FCLTS_CL_CODE): P1(개인연습실) C1(안무 연습실, 합주실, 스튜디오 (예약제)
  },
  // rentApplyForm: {
  //   applcntNm: '박현수', // 신청자 명
  //   applcntAdres: '경기도 남양주시', // 신청자 주소
  //   applcntCttpc: '010-1234-5678', // 신청자 연락처
  //   applcntIntrcnCn:
  //     '자기(팀) 소개와 활동 경력을 간단히 작성해 주세요.자기(팀) 소개와 활동 경력을 간단히 작성해 주세요.자기(팀) 소개와 활동 경력을 간단히 작성해 주세요.자기(팀) 소개와 활동 경력을 간단히 작성해 주세요.자기(팀) 소개와 활동 경력을 간단히 작성해 주세요.자기(팀) 소개와 활동 경력을 간단히 작성해 주세요.자기(팀) 소개와 활동 경력을 간단히 작성해 주세요.자기(팀) 소개와 활동 경력을 간단히 작성해 주세요.', // 신청자 소개: 본인(팀) 소개와 활동 경력(200자 이상)
  //   applcntActPlanCn:
  //     '입주 신청 사유 및 활동 계획을 작성해주세요입주 신청 사유 및 활동 계획을 작성해주세요입주 신청 사유 및 활동 계획을 작성해주세요입주 신청 사유 및 활동 계획을 작성해주세요입주 신청 사유 및 활동 계획을 작성해주세요입주 신청 사유 및 활동 계획을 작성해주세요입주 신청 사유 및 활동 계획을 작성해주세요입주 신청 사유 및 활동 계획을 작성해주세요입주 신청 사유 및 활동 계획을 작성해주세요입주 신청 사유 및 활동 계획을 작성해주세요입주 신청 사유 및 활동 계획을 작성해주세요입주 신청 사유 및 활동 계획을 작성해주세요입주 신청 사유 및 활동 계획을 작성해주세요', // 신청자 활동 계획 내용: 입주 신청 사유 및 활동 계획
  //   applcntPrufFileList: [
  //     {
  //       fcltrntRequstTyCode: 'CLK',
  //       fcltrntRequstCn: 'https://www.naver.com/0',
  //       fcltrntRequstRegistOrdr: 0,
  //     },
  //     {
  //       fcltrntRequstTyCode: 'CLK',
  //       fcltrntRequstCn: 'https://www.naver.com/1',
  //       fcltrntRequstRegistOrdr: 1,
  //     },
  //     {
  //       fcltrntRequstTyCode: 'CLK',
  //       fcltrntRequstCn: 'https://www.naver.com/2',
  //       fcltrntRequstRegistOrdr: 2,
  //     },
  //   ], // 포트폴리오 리스트
  //   applcntSnsUrl: 'https://www.naver.com', // 신청자 SNS URL
  //   fcltrntCognpathCn: '지인', // 알게된 경로: 시설물임대 인지경로 내용
  //   privcyColctPrcuseAgreAt: 'Y', // 개인정보 수집 활용 동의 여부(Y/N)
  //   privcyThptyProvdAgreAt: 'Y', // 개인정보 제3자 제공 동의 여부(YN)
  //   fcltrntFcltsTyCode: 'P1', // 배너 슬롯 시설물임대 시설물 분류 코드 : 시설물분류코드(FCLTS_CL_CODE): P1(개인연습실) C1(안무 연습실, 합주실, 스튜디오 (예약제)
  // },
  // 시설물 임대 신청 크리에이터
  rentApplyCreator: {
    creatorLink: '', // 크리에이터 링크
    crtrCnvrsReqstAt: 'N', // 크리에이터 전환 신청 여부(Y/N)
    crtrCtgrySn: 0, // 크리에이터 카테고리 일련번호
  },
  rentTerms: [],
}
