/**
 * 다이얼로그
 */
export const dialog = {
  requireSignIn: {
    title: 'Notification',
    description: 'Sign in is required. Do you want to Sign in?',
    action: {
      negative: 'Cancel',
      positive: 'Sign in',
    },
  },
  resultSignUp: {
    success: {
      title: 'Sign up success',
      content: 'Congratulations for your membership subscription!',
    },
    fail: {
      title: 'Sign up fail',
      content:
        'An error has occurred.<br> We apologize for any inconvenience in using the service.<br> Please check and try again.',
    },
    confirm: 'Confirm',
  },
  resultSignIn: {
    fail: {
      title: 'Sign in failed',
      content:
        'Sorry, an error occurred while processing your request. We apologize for the inconvenience. Please try again later.',
    },
  },
  confirmCommentDelete: {
    title: 'Notifications',
    description: 'Are you sure you want to delete the comment?',
    action: {
      negative: 'Cancel',
      positive: 'Delete',
    },
  },
  postDetailDrop: {
    title: 'Notification',
    description: 'This is a reported post.',
    action: {
      positive: 'Confirm',
    },
  },
  postDetailBlocked: {
    title: 'Notification',
    description: 'The host blocked you, so you can’t access the post.',
    action: {
      positive: 'Confirm',
    },
  },
  postUnSubscribed: {
    title: 'Notification',
    description: 'You do not have access to the {subscribeGrade} post.',
    action: {
      positive: 'Confirm',
    },
  },
  confirmTempPostDelete: {
    title: 'Notifications',
    description: 'Are you sure you want to delete the draft post?',
    action: {
      positive: 'Delete',
    },
  },
  multipleSignIn: {
    title: 'Notifications',
    description:
      'You are currently signed in with a different account on another browser. Logging in with this account will log you out from the other browser session.',
    action: {
      positive: 'OK',
    },
  },
  referralReg: {
    title: 'Referral Registration',
    placeholder: 'Enter email.',
    error: 'Not available to registration. Please try again later.',
    success: 'Referral registration completed',
    validate: 'Enter the valid e-mail.',
  },
  event: {
    confirm: 'OK',
    regist: {
      title: 'Notification',
      content: 'You can register on the app.',
    },
    apply: {
      title: 'Notification',
      content: 'You can apply on the app.',
    },
  },
}
