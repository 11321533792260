<script setup lang="ts">
import type { NovaNotificationTabsItemProps } from './NovaHeaderNotificationTabsItem.types'
import { fcmCallHandler, FcmPayloadMin } from '@firebaseModule'
import { LayerType } from '@store'

const props = withDefaults(defineProps<NovaNotificationTabsItemProps>(), {
  isLast: false,
})
const { gtEvent } = useGoogleTag()
const layoutStore = useLayoutStore()
const etcStore = useEtcStore()
const { humanize } = useDateFormat()

// const { show: modalShow } = useModal()
const dotColorClass = computed(() => {
  switch (props.notification.svcNtcnStyleCode) {
    case 'ORG1':
      return 'orange'
    case 'BLU1':
      return 'blue'
    default:
      return ''
  }
})

const onClickNotification = async () => {
  // 1. 알림 이벤트 열기
  openEvent()
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '알림 클릭',
    eventLabel: props.notification.ntcnCn,
    eventSlot: '알림 패널',
    eventI18nAddr: props.notification.ntcnCn,
    eventComponent: '',
    ntcnCn: props.notification.ntcnCn,
  })
  // 2. 알림 읽음 처리
  await readNotification()
}

const openEvent = () => {
  if (
    props.notification.ntcnMssageLinkAt === 'Y' &&
    props.notification.ntcnMssageLinkUrl
  ) {
    const payload = new FcmPayloadMin({
      type: props.notification.type,
      action: props.notification.action,
      url: props.notification.ntcnMssageLinkUrl,
    })
    fcmCallHandler(payload)
    layoutStore['layer:close'](LayerType.NOTIFICATION_PANEL)
  }
}

const removeNotification = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '알림 삭제',
    eventLabel: 'close-compact',
    eventSlot: '알림 패널',
    eventI18nAddr: 'close-compact',
    eventComponent: 'Button',
    ntcnCn: props.notification.ntcnCn,
  })
  await readNotification()

  await etcStore.deleteAlarmItem(
    {
      type: 'confirm',
      userNtcnGroupId: props.notification.userNtcnGroupId,
    },
    props.notificationTypeCode
  )
}

const readNotification = async () => {
  if (props.notification.ntcnCnfirmAt === 'Y') return
  await etcStore.putAlarmItem(
    {
      type: 'confirm',
      userNtcnGroupId: props.notification.userNtcnGroupId,
    },
    props.notificationTypeCode
  )
}
</script>

<template>
  <div
    :class="['notification-item', { last: isLast }]"
    @click="onClickNotification"
  >
    <div class="inner">
      <div class="contents">
        <div v-show="notification.ntcnCnfirmAt === 'N'" class="dot-wrap">
          <span :class="['dot', `dot-${dotColorClass}`]"></span>
        </div>

        <div class="notification">
          <p class="label">{{ notification.ntcnCn }}</p>
          <p class="create-at">
            {{ humanize(notification.ntcnRegistDt) }}
          </p>
        </div>
      </div>

      <NovaButtonIcon
        :icon="{ type: 'outline', name: 'close-compact' }"
        :size="20"
        class="remove-button"
        @click.stop="removeNotification"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.notification-item {
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 20px;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 20px;
    width: calc(100% - 40px);
    height: 1px;
    background-color: $color-gray-1;
  }

  &.last:after {
    content: '';
    display: none;
  }

  > .inner {
    display: flex;
    justify-content: space-between;
    gap: 12px;

    > .contents {
      flex-grow: 1;
      display: flex;

      .dot-wrap {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 12px;
        height: 20px;

        > .dot {
          display: inline-block;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: $color-secondary-red-light-80;

          &.dot-blue {
            background-color: $color-secondary-blue-light-80;
          }

          &.dot-orange {
            background-color: $color-secondary-red-light-80;
          }
        }
      }

      .notification {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 6px;

        > .label {
          @include text-style($text-body-14-regular);
          line-height: 20px;
          word-break: break-all;
        }

        > .create-at {
          @include text-style($text-body-12-medium);
          line-height: 12px;
          color: $color-text-3;
        }
      }
    }

    .remove-button {
      flex-shrink: 0;
    }
  }
}
</style>
