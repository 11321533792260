import {
  FacilityRentalAvailableStatus,
  type FacilityRentalCheckCondition,
  type Profile,
} from '@store'

interface UseFacilityRentalAvailableStatusParams {
  conditions: Array<FacilityRentalCheckCondition> | null | undefined
  profile?: Profile
}

export const useFacilityRentalAvailableStatus = ({
  conditions,
  profile,
}: UseFacilityRentalAvailableStatusParams): FacilityRentalAvailableStatus => {
  const userStore = useUserStore()
  const mypageStore = useMyPageStore()
  const _conditions = conditions || []
  // 1. 입주 신청 필수 정보 존재 여부 판단
  if (_conditions.length < 1) {
    return FacilityRentalAvailableStatus.UNAVAILABLE_REQUIRE_CONDITIONS
  }
  // 2. 로그인 여부 판단
  if (!profile) return FacilityRentalAvailableStatus.UNAVAILABLE_REQUIRE_SIGN_IN
  if (mypageStore.applyCreatorStatus === 'requireSignin')
    return FacilityRentalAvailableStatus.UNAVAILABLE_REQUIRE_SIGN_IN
  // 3. KYC 인증 여부 판단
  if (userStore.userCommonInfo?.kycAt !== 'Y')
    return FacilityRentalAvailableStatus.UNAVAILABLE_REQUIRE_KYC_AUTH

  // 4. 이전 입주 신청 이력이 있다면 조회 시점 기준 (입주 신청일 ~ 20일), (입주 신청일 ~ 60일) 여부 판단
  for (const condition of _conditions) {
    if (condition.applicationUnavailableCode === 'LESS_THAN_20') {
      return FacilityRentalAvailableStatus.UNAVAILABLE_APPLIED_LESS_THAN_20_DAYS
    } else if (condition.applicationUnavailableCode === 'LESS_THAN_60') {
      return FacilityRentalAvailableStatus.UNAVAILABLE_APPLIED_LESS_THAN_60_DAYS
    }
  }
  // 5. 입주 신청을 크리에이터 신청과 함께 가능 여부 판단
  if (mypageStore.applyCreatorStatus === 'available') {
    return FacilityRentalAvailableStatus.AVAILABLE_WITH_APPLY_CREATOR
  }

  // 6. 입주 신청을 크리에이터 신청 없이 가능 여부 판단
  if (
    mypageStore.applyCreatorStatus === 'approved' ||
    mypageStore.applyCreatorStatus === 'underReview' ||
    mypageStore.applyCreatorStatus === 'reject'
  ) {
    return FacilityRentalAvailableStatus.AVAILABLE_WITH_ALREADY_CREATOR
  }
  return FacilityRentalAvailableStatus.UNAVAILABLE_REQUIRE_CONDITIONS
}
