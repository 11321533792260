<script setup lang="ts">
import { useScriptTag } from '@vueuse/core'
import { naverAnalyticsSource } from '@configs'

type WindowWithNaverAnalytics = Window & {
  wcs: any
  wcs_add: { wa?: string }
  wcs_do: () => any
}

declare const window: WindowWithNaverAnalytics

const config = useRuntimeConfig()

const initNaverAnalytics = () => {
  if (!window.wcs_add) window.wcs_add = {}

  window.wcs_add.wa = config.public.NAVER_APP_KEY

  if (window.wcs) {
    window.wcs_do()
  }
}

const { load, unload } = useScriptTag(naverAnalyticsSource, initNaverAnalytics)

onBeforeMount(load)
onBeforeUnmount(unload)
</script>

<template>
  <slot />
</template>
