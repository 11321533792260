<script setup lang="ts">
import type { NovaKycContentRejectProps } from './NovaKycContentReject.types'

defineProps<NovaKycContentRejectProps>()

const { t } = useI18n()
const userStore = useUserStore()
const cs = computed(() =>
  t('kyc.unAuthKyc.cs', {
    cs: `<a class="text-color-primary-blue" href="mailto:${t(
      'corporationInfo.infos.email'
    )}">${t('corporationInfo.infos.email')}</a>`,
  })
)
</script>

<template>
  <div class="guide">
    <div
      v-dompurify-html="t('kyc.unAuthKyc.reject.primaryCopy')"
      class="primary-copy"
    />

    <div
      v-dompurify-html="$t(`kyc.unAuthKyc.reject.secondaryCopy.${purpose}`)"
      class="secondary-copy"
    />

    <div class="reject-reason">
      <p class="title">{{ $t('kyc.unAuthKyc.reject.rejectReason') }}</p>
      <div class="inner">
        <p class="reason">{{ userStore.user?.kycDetailMessage || '-' }}</p>
      </div>
    </div>

    <p v-dompurify-html="cs" class="description" />
  </div>
</template>

<style lang="scss" scoped>
.guide {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 12px;

  .primary-copy {
    @include text-style($text-display-medium);
    color: $color-black;
  }

  .secondary-copy {
    @include text-style($text-display-medium);
    color: $color-black;
  }

  .reject-reason {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-top: 12px;

    .title {
      @include text-style($text-display-medium);
    }

    .inner {
      min-height: 100px;
      padding: 12px;
      border-radius: 6px;
      background-color: $color-bg-2;
    }

    .reason {
      @include text-style($text-body-14-regular);
      word-break: break-word;
    }
  }

  .description {
    @include text-style($text-body-10-regular);
    color: $color-text-2;
  }
}
</style>
