<script setup lang="ts">
import { type RouteParams } from 'vue-router'
import { AxiosError } from 'axios'
import type { NovaModalMarketingAgreementEmits } from './NovaModalMarketingAgreement.types'
import type { BooleanYn } from '@store'
import { initFirebase, type FirebaseTokenStatus } from '@firebaseModule'

defineOptions({
  inheritAttrs: false,
})
defineEmits<NovaModalMarketingAgreementEmits>()

const { t } = useI18n()
const { hide: modalHide } = useModal()
const { saveMarketingPushAgree } = useMarketingPushAgree()
const appStore = useAppStore()
const show = ref(false)
const loading = ref(false)
const fcmTokenStatus = ref<FirebaseTokenStatus>()

// 팝업 닫기
const handleOnClose = async () => {
  await modalHide(modalsName.MODAL_MARKETING_PUSH_AGREEMENT)
  fcmTokenStatus.value = undefined
}

// 팝업 열기전 필요한 값 세팅
const handleOnBeforeOpen = (evt: RouteParams) => {
  fcmTokenStatus.value = evt.value as unknown as FirebaseTokenStatus
}

const handleOnSaveMarketingPushAgree = async (agree: BooleanYn) => {
  try {
    loading.value = true
    await initFirebase()
    await saveMarketingPushAgree({
      agree,
      fcmTokenStatus: appStore.fcmTokenStatus!,
    })

    handleOnClose()
  } catch (err) {
    if (err instanceof AxiosError) {
      switch (err.response?.status) {
        case 7005:
          useToast(
            t(
              'notificationSetting.notificationMarketingAgreeDialog.errors.7005'
            )
          )
          break
        case 7006:
          useToast(
            t(
              'notificationSetting.notificationMarketingAgreeDialog.errors.7006'
            )
          )
          break
        default:
          useToast(
            t(
              'notificationSetting.notificationMarketingAgreeDialog.errors.default'
            )
          )
          break
      }
    }
  } finally {
    loading.value = false
  }
}
</script>

<template>
  <!-- todo: 마케팅 동의 팝업 -->
  <VueFinalModal
    v-model="show"
    :name="modalsName.MODAL_MARKETING_PUSH_AGREEMENT"
    :content-class="'modal-content'"
    classes="modal-container"
    :click-to-close="false"
    @before-open="(evt: any) => handleOnBeforeOpen(evt.ref.params)"
  >
    <ClientOnly>
      <NovaBoxBase v-if="show" class="modal-box">
        <div class="modal-body">
          <div class="title">
            {{
              t('notificationSetting.notificationMarketingAgreeDialog.title')
            }}
          </div>
          <div class="message-box">
            <p
              v-dompurify-html="
                t(
                  `notificationSetting.notificationMarketingAgreeDialog.message`
                )
              "
              class="message"
            />
            <p
              v-dompurify-html="
                t(
                  `notificationSetting.notificationMarketingAgreeDialog.messageSm`
                )
              "
              class="message-sm"
            />
          </div>
        </div>

        <div class="modal-actions">
          <button
            type="button"
            :class="['action', { loading }]"
            @click="() => handleOnSaveMarketingPushAgree('N')"
          >
            <span class="action-label">
              {{
                t(
                  'notificationSetting.notificationMarketingAgreeDialog.actions.action'
                )
              }}
            </span>
            <NovaLoadingIndicator
              v-if="loading"
              :bg-bright="'light'"
              class="loading"
            />
          </button>

          <button
            type="button"
            :class="['action positive', { loading }]"
            @click="() => handleOnSaveMarketingPushAgree('Y')"
          >
            <span class="action-label">
              {{
                t(
                  'notificationSetting.notificationMarketingAgreeDialog.actions.positive'
                )
              }}
            </span>
            <NovaLoadingIndicator v-if="loading" class="loading" />
          </button>
        </div>
      </NovaBoxBase>
    </ClientOnly>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
::v-deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
  @include mobile {
    align-items: flex-end;
  }
}
::v-deep(.modal-content) {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 390px;
  box-shadow: 5px 5px 10px hex-to-rgba($color-bg-custom-4, 0.05);
  background: $color-white;
  .box-base {
    padding: 0;
  }
  @include mobile {
    max-width: none;
  }
}

.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 30px 20px 20px 20px;
  @include mobile {
    padding: 20px 20px 20px 20px;
  }
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: $color-primary-green;
    @include text-style($text-custom-24-bold);
    @include mobile {
      @include text-style($text-custom-22-bold);
    }
  }
  .message-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px 0 0 0;
    border-top: 1px solid $color-bg-custom-3;
    text-align: center;
    @include mobile {
      gap: 15px;
      padding: 15px 10px 0 10px;
    }
    .message {
      @include text-style($text-display-bold);
      @include mobile {
        @include text-style($text-body-14-bold);
      }
    }
    .message-sm {
      color: $color-bg-custom-10;
      @include text-style($text-body-11-regular);
      @include mobile {
        @include text-style($text-body-11-regular);
      }
    }
  }
}

.modal-actions {
  display: flex;
  justify-content: space-between;

  .action {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 56px;
    background-color: $color-bg-custom-8;

    &.loading {
      pointer-events: none;

      .action-label {
        opacity: 0.2;
      }
    }

    &.positive {
      background-color: $color-primary-green;
      > .action-label {
        @include text-style($text-display-bold);
        color: $color-bg-3;
        @include mobile {
          @include text-style($text-body-14-bold);
        }
      }
    }

    > .action-label {
      @include text-style($text-display-medium);
      @include mobile {
        @include text-style($text-body-14-medium);
      }
    }

    > .loading {
      position: absolute;
    }
  }
}
</style>
