<script setup lang="ts">
import { Tippy } from 'vue-tippy'
import { $vfm } from 'vue-final-modal'
import type { NovaBoxCreatorNoticeActionProps } from './NovaBoxCreatorNoticeAction.types'
import type {
  NoticeAction,
  AuthToken,
  ShowOptionMenu,
  ShowOptionMenuInfo,
  CreatorNoticePayload,
  DeleteCreatorNoticePayload,
} from '@store'
import {
  NovaModalCommon,
  NovaModalDialog,
  NovaModalNoticeCreate,
} from '#components'

const props = withDefaults(defineProps<NovaBoxCreatorNoticeActionProps>(), {
  popoverAppendTarget: null,
})

const { cookieNames, getCookie } = useCookies()
const isHide = ref(true)

// const handleOnDelete = async () => {
//   try {
//     await creatorStore.deleteCreatorGuestBook(props.deleteTarget)
//     useToast(t('creatorGuestBook.request.delete.success'))
//   } catch {
//     useToast(t('creatorGuestBook.request.delete.error'))
//   } finally {
//     await modalHide(modalsName.MODAL_OPTION_MENU)
//   }
// }

const { t, locale, messages } = useI18n()
const creatorStore = useCreatorStore()
const userStore = useUserStore()
const layoutStore = useLayoutStore()
const { show: modalShow, hide: modalHide } = useModal()
const privateActions = computed(() => {
  return Object.keys(messages.value[locale.value].noticeAction).map((key) => ({
    id: key,
    name: t(`noticeAction.${key}`),
  })) as PrivateAction[]
})
const authToken = getCookie<AuthToken>(cookieNames.AUTH_TOKEN)

// private action wrapper
const handleOnPrivateAction = async (id: NoticeAction) => {
  switch (id) {
    case 'modify':
      await modify()
      break
    case 'remove':
      remove()
      break
    default:
      throw new Error('post private action type is not match')
  }
}

// 포스트 삭제하기(팝업 호출)
const remove = () => {
  $vfm.show({
    component: NovaModalDialog,
    bind: {
      name: 'ConfirmPostRemove',
      btn: {
        grid: 2,
        isCancel: true,
      },
    },
    on: {
      async confirm() {
        await handleOnPostRemove()
        await $vfm.hide('ConfirmPostRemove')
        await fetchCreatorNoticeList(true)
      },
      cancel() {
        $vfm.hide('ConfirmPostRemove')
      },
    },
    slots: {
      title: t('confirm'),
      content: t('noticeRemoveConfirmMessage'),
    },
  })
}

const modify = async () => {
  const payload: CreatorNoticePayload = {
    crtrNttSn: props.postSn,
    creatorUserSn: props.postWriterSn,
  }
  await creatorStore.fetchCreatorNotice(payload)
  modalHide(modalsName.MODAL_OPTION_MENU)
  await modalShow(
    {
      component: NovaModalCommon,
      bind: {
        name: modalsName.MODAL_REG_CREATOR_NOTICE,
        showCloseBtn: true,
        mobileFull: true,
        hasInnerScroll: true,
        type: 'create',
      },
      on: {
        customClose: () => {
          useEvent('notice:createClose')
        },
      },
      slots: {
        title: t('modifyNotice'),
        contents: {
          component: NovaModalNoticeCreate,
          on: {
            close: async () => {
              await modalHide(modalsName.MODAL_REG_CREATOR_NOTICE)
              await fetchCreatorNoticeList(true)
            },
          },
        },
      },
    },
    { cmtyNttSn: props.postSn }
  )
}

// fetch my comments
const fetchCreatorNoticeList = async (refresh: boolean) => {
  await creatorStore.fetchCreatorNoticeList(
    {
      creatorUserSn: creatorStore.profile!.userSn,
    },
    refresh
  )
}

const handleOnPostRemove = async () => {
  try {
    layoutStore.updateLoadingIndicatorGlobal({ show: true })
    const payload: DeleteCreatorNoticePayload = {
      crtrNttSn: props.postSn,
      creatorUserSn: props.postWriterSn,
      grantType: authToken!.grantType,
      accessToken: authToken!.accessToken,
    }
    await creatorStore.deleteCreatorNotice(payload)
  } catch {
    useToast(t('statusMessages.postRemove.error'))
  } finally {
    layoutStore.updateLoadingIndicatorGlobal({ show: false })
    await modalHide(modalsName.MODAL_OPTION_MENU)
  }
}

const clickHandler = async () => {
  const isMobile = useCheckMobile()
  if (isMobile) {
    isHide.value = false
    const menuList: ShowOptionMenuInfo[] = privateActions.value.map((item) => {
      const menu: ShowOptionMenuInfo = {
        id: item.id,
        name: item.name,
        class: '',
        fn: async () => await handleOnPrivateAction(item.id),
      }
      return menu
    })

    await modalShow(modalsName.MODAL_OPTION_MENU, {
      menuType: 'btn',
      menuList,
    } as ShowOptionMenu)
  }
}
</script>

<template>
  <ClientOnly>
    <Tippy
      v-if="userStore.isSignIn"
      :append-to="popoverAppendTarget || 'parent'"
      :interactive="true"
      :placement="'bottom-end'"
      :theme="'popover'"
      @click.stop="clickHandler"
    >
      <NovaBoxCreatorNoticeActionButton
        :icon="{ type: 'outline', name: 'more-horizontal' }"
        :action-type="'privateActions'"
        :display-number="-1"
      />

      <template #content>
        <NovaBoxCreatorNoticeActionPanel
          v-if="isHide"
          :cmty-ntt-sn="postSn"
          :user-sn="postWriterSn"
        />
      </template>
    </Tippy>
  </ClientOnly>
</template>
