import type { AuthUserToken } from '@/store/user/type'
import type { FirebaseTokenStatus } from '~/firebase/types'
import { FirebaseDevice } from '~/store/firebase/type'

/**
 * 새로고침 시에도 사용자 정보를 가지고 있을 수 있도록 설정
 */
export default defineNuxtRouteMiddleware(async () => {
  const { userStore, fetchAllUserInfoByAccessToken } = useMembershipProcess()
  const { cookieNames, getCookie } = useCookies()

  if (process.client && !userStore.isSignIn) {
    const userInfo = getCookie<AuthUserToken>(cookieNames.USER_INFO)
    if (userInfo) {
      const fcmTokenStatus = getCookie<FirebaseTokenStatus>(
        cookieNames.FCM_TOKEN_STATUS
      ) || {
        fcmDevice: FirebaseDevice.WEB,
        fcmWebToken: '',
        fcmAppToken: '',
      }
      try {
        await fetchAllUserInfoByAccessToken({
          userCmmnSn: userInfo.userCmmnSn,
          fcmDevice: fcmTokenStatus.fcmDevice,
        })
      } catch (err) {
        console.error(err)
      }
    }
  }
})
