<script setup lang="ts">
import type {
  NovaRadioGroupEmits,
  NovaRadioGroupProps,
} from './NovaRadioGroup.types'

const emit = defineEmits<NovaRadioGroupEmits>()
withDefaults(defineProps<NovaRadioGroupProps>(), {
  label: '',
  loading: false,
  direction: 'horizon',
  equalWidth: false,
  theme: 'radio',
})

const handleOnChange = (value: string) => {
  emit('onChangeRadio', value)
}
</script>

<template>
  <div :class="['radio-group', { loading }]">
    <h4 v-if="label" class="label">{{ label }}</h4>

    <div
      :class="[
        'radios',
        direction,
        { 'grid-wrapper': equalWidth },
        equalWidth && radios.length ? `grid-${radios.length}` : '',
        { 'is-loading': loading },
      ]"
    >
      <NovaRadio
        v-for="item in radios"
        :key="item.value"
        :value="item.value"
        :is-check="item.value === current"
        :label="item.label"
        :direction="item.direction"
        :theme="theme"
        @on-change-radio="handleOnChange"
      />

      <NovaLoadingIndicator
        v-if="loading"
        :overlay="true"
        :bg-bright="'light'"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.radio-group {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &.loading {
    .radios {
      :deep(.radio-box) {
        pointer-events: none;
        opacity: 0.4;
      }
    }
  }

  > .label {
    @include text-style($text-body-14-bold);
    color: $color-text-2;

    &:empty {
      display: none;
    }
  }

  .radios {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 12px;

    &.horizon {
      flex-direction: row;
    }
    &.vertical {
      display: flex !important;
      flex-direction: column !important;
    }
  }
}
</style>
