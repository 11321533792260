<script setup lang="ts">
import type { CommentsAndRepliesOrderBy } from '@store'
import type { DropdownItemType } from '@components/NovaDropdownItem/NovaDropdownItem.types'

const { gtEvent } = useGoogleTag()
const { t, locale, messages } = useI18n()
const postStore = usePostStore()
const sorter = computed(
  () =>
    Object.keys(messages.value[locale.value].commentsAndRepliesSort).map(
      (key) => ({ label: t(`commentsAndRepliesSort.${key}`), value: key })
    ) as DropdownItemType[]
)

const handleOnUpdateOrderBy = async (value: CommentsAndRepliesOrderBy) => {
  try {
    const label: string | undefined = sorter.value.find(
      (item) => item.value === value
    )?.label
    await postStore.fetchCommentsAndReplies(
      { ...postStore.commentsAndReplies.payload, orderBy: value },
      true
    )
    gtEvent('selectEvent', {
      eventCategory: '선택',
      eventAction: '댓글 정렬 방식 선택',
      eventLabel: label!,
      eventSlot: '최신순, 과거순, 인기순',
      eventI18nAddr: '',
      eventComponent: 'DropDown',
    })
  } catch {
    useToast(t('statusMessages.comments.error'))
  }
}
</script>

<template>
  <div class="comments-and-replies-header">
    <h4 class="count">
      {{
        t('commentsCount', {
          count: useFormatThousandsSeparators(postStore.post?.answerCount || 0),
        })
      }}
    </h4>

    <NovaDropdown
      :menus="sorter"
      :active-value="postStore.commentsAndReplies.payload.orderBy"
      theme="dark"
      class="sorter"
      @update="(value) => {
        handleOnUpdateOrderBy(value as CommentsAndRepliesOrderBy)
      }"
    />
  </div>
</template>

<style lang="scss" scoped>
.comments-and-replies-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  .count {
    flex-shrink: 0;
    @include text-style($text-display-bold);
    line-height: 22px;
  }

  .sorter {
    width: auto;
  }
}
</style>
