import { DAY, ROUTES } from '@configs'
import type { BooleanYn } from '@store'
import type {
  FirebaseTokenStatus,
  UnSigninMarketingPushAgree,
} from '@firebaseModule'

/**
 * Represents the parameters for saving the marketing push agree status.
 * @interface
 */
interface SaveMarketingPushAgreeParam {
  agree: BooleanYn
  fcmTokenStatus: FirebaseTokenStatus
}

/**
 * 마케팅 푸시 동의를 핸들링
 * @function useMarketingPushAgree
 */
export const useMarketingPushAgree = () => {
  const config = useRuntimeConfig()
  const { dayjs } = useDayjs()
  const { t, locale } = useNuxtApp().$i18n
  const { show: modalShow } = useModal()
  const { cookieNames, getCookie, setCookie } = useCookies()
  const appStore = useAppStore()
  const userStore = useUserStore()
  const firebaseStore = useFirebaseStore()

  // console.log(over30days('2023-11-10 16:50:34'))
  /**
   * 주어진 날짜가 현재 날짜로부터 30일 이상 이전에 해당하는지 확인
   *
   * @param {Date} date - The date to compare.
   * @returns {boolean} - The result of the comparison.
   */
  const over30days = (date: Date): boolean => {
    const limit = DAY * 30
    const now = dayjs.utc()
    const compareDate = dayjs.utc(date)

    return now.diff(compareDate, 'millisecond') > limit
  }

  /**
   * 마케팅 푸시 알림 동의 모달 열기
   * 해당 함수는 firebase 초기화 이후 및 라우트가 변경될 때마다 호출 해야함
   * 아래의 비노출 조건이 하나도 충족되지 않는다면, modalShow 함수를 호출하여 마케팅 푸시 알림 동의 모달 열기
   * 비노출 조건: 앱모드가 아니라면 비노출
   * 비노출 조건: 마케팅 푸시 동의 여부 'Y' 일때 비노출
   * 비노출 조건: 마케팅 푸시 동의 여부 'N' 이고 마지막 업데이트가 30일이 지나지 않았다면 비노출
   * 비노출 조건: 마케팅 푸시 동의 여부 'N' 이고 마지막 업데이트가 30일이 지났으며, 이벤트 게시판이 아닌 라우트일때 비노출
   *
   * @async
   * @function openMarketingPushAgreeModal
   * @returns {Promise<void>}
   */
  const openMarketingPushAgreeModal = async (): Promise<void> => {
    const { user } = userStore
    const { fcmTokenStatus } = appStore
    const unSigninMarketingPushAgree = getCookie<UnSigninMarketingPushAgree>(
      cookieNames.UN_SIGNIN_MARKETING_PUSH_AGREE
    )

    if (!fcmTokenStatus) return

    if (!['AOS', 'IOS'].includes(String(fcmTokenStatus?.fcmDevice))) return

    const marketingPushAgreeAt = user
      ? user.marketingPushAgreeAt
      : unSigninMarketingPushAgree?.marketingPushAgreeAt

    const marketingPushAgreeDt = user
      ? user.marketingPushAgreeDt
      : unSigninMarketingPushAgree?.marketingPushAgreeDt

    if (marketingPushAgreeAt === 'Y') return
    if (marketingPushAgreeAt === 'N' && !over30days(marketingPushAgreeDt!))
      return

    const routeName = useGetRouteName()
    const filteredRoutes = [
      ROUTES.BOARD_EVENT.name,
      ROUTES.BOARD_EVENT_DETAIL.name,
    ]

    if (
      marketingPushAgreeAt === 'N' &&
      over30days(marketingPushAgreeDt!) &&
      !filteredRoutes.filter((item) => routeName.includes(item)).length
    )
      return

    await modalShow(modalsName.MODAL_MARKETING_PUSH_AGREEMENT, fcmTokenStatus)
  }

  /**
   * 사용자의 마케팅 푸시 동의 상태를 저장하고 데이터베이스를 업데이트
   * 만약 사용자가 로그인하지 않았다면, 동의 상태를 쿠키에도 저장
   *
   * @async
   * @param {SaveMarketingPushAgreeParam} params - 마케팅 푸시 동의 상태를 저장하기 위한 파라미터
   * @returns {Promise<void>} - 상태가 데이터베이스에 저장될 때 해결되는 프로미스
   */
  const saveMarketingPushAgree = async ({
    agree,
    fcmTokenStatus,
  }: SaveMarketingPushAgreeParam): Promise<void> => {
    const { user } = userStore
    const { saveMarketingPushAgree: save } = firebaseStore
    const now = dayjs.utc().toDate()

    await save({
      fcmDevice: fcmTokenStatus.fcmDevice,
      fcmToken:
        fcmTokenStatus.fcmDevice === 'WEB'
          ? fcmTokenStatus.fcmWebToken
          : fcmTokenStatus.fcmAppToken,
      langCode: locale.value.toUpperCase(),
      marketingAgreeAt: agree,
    })

    if (!user) {
      setCookie<UnSigninMarketingPushAgree>(
        cookieNames.UN_SIGNIN_MARKETING_PUSH_AGREE,
        {
          marketingPushAgreeAt: agree,
          marketingPushAgreeDt: now,
          fcmDevice: fcmTokenStatus.fcmDevice,
        },
        30,
        'days'
      )
    }

    // show toast message
    // 타임존 정보 받기
    const diff = dayjs().utcOffset() / 60
    const date = `${dayjs(now).format('YYYY.MM.DD')} GMT${
      diff >= 0 ? '+' : ''
    }${Math.abs(diff)}`

    // 동의 상태에 따라 키 값 도출
    const agreementKeySuffix = agree === 'Y' ? 'agree' : 'deny'

    // 메시지에 사용될 데이터 정의
    const messageData = {
      appName: config.public.APP_NAME,
      type: t('notificationSetting.notificationType.push'),
      date,
    }

    // 메시지 생성 및 출력
    const message = t(
      `notificationSetting.toastMessage.marketing.${agreementKeySuffix}`,
      messageData
    )

    useToast(message)
  }

  return {
    openMarketingPushAgreeModal,
    saveMarketingPushAgree,
  }
}
