export enum CellItemTheme {
  DEFAULT = 'default',
  ON_WHITE_BOX = 'on-white-box',
}
export interface CellItemProps {
  label: string
  labelSub?: string
  contents?: string | Array<string | null | undefined>
  clickAble?: boolean
  toolsPosition?: 'top' | 'middle' | 'bottom'
  theme?: CellItemTheme
  depth?: number
}
