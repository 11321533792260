/**
 * 주어진 문자열에서 HTML DOM 요소를 생성
 *
 * @param {string} string - HTML 코드를 포함하는 문자열
 * @returns {DocumentFragment} - 문자열로부터 생성된 HTML DOM 요소
 */
export const useCreateHtmlByString = (string: string): DocumentFragment => {
  const template = document.createElement('template')
  template.innerHTML = string.trim()
  return template.content
}
