<script setup lang="ts">
import type {
  NovaImageContainerEmits,
  NovaImageContainerProps,
} from './NovaImageContainer.types'
import { LAZY_LOAD_IMAGE_DELAY_MS, LAZY_LOAD_IMAGE_MAX_RETRIES } from '@configs'

const emits = defineEmits<NovaImageContainerEmits>()
const props = withDefaults(defineProps<NovaImageContainerProps>(), {
  imageUrl: '',
  ratio: '4:3',
  responsiveSize: () => ({}),
  emptyType: 'default',
  isFillContainer: false,
  stroke: false,
  portraitXs: false,
  alt: '',
})
const backupImageSrc = ref(props.imageUrl)
const imageSrc = ref(props.imageUrl)
const retryCount = ref(0)
const isLoadSuccess = ref(false)
const isLoadFail = ref(false)
const loadStatus = computed(() => {
  if (!props.imageUrl) return 'LOAD_FAIL'
  if (!isLoadFail.value && !isLoadSuccess.value) return 'LOADING'
  if (isLoadFail.value) return 'LOAD_FAIL'
  if (isLoadSuccess.value) return 'LOAD_SUCCESS'
})
const ratioClass = computed(
  () => `ratio-${String(props.ratio).replace(':', 'x')}`
)
const emptyImageClass = computed(() => `empty-${props.emptyType.toLowerCase()}`)
const responsiveSizeClass = computed(() => {
  const keys = Object.keys(props.responsiveSize) as Responsive[]
  return keys.map(
    (key) => `${key}-ratio-${props.responsiveSize[key]?.replace(':', 'x')}`
  )
})

const handleOnLoad = () => {
  isLoadSuccess.value = true
  emits('onImgLoad')
}

const handleOnError = (evt: Event) => {
  if (!backupImageSrc.value) {
    isLoadFail.value = true
    return
  }

  retryCount.value++

  // Loading image failed. Retrying...
  if (retryCount.value <= LAZY_LOAD_IMAGE_MAX_RETRIES) {
    setTimeout(() => {
      const url = new URL(backupImageSrc.value)
      url.searchParams.append('v', String(retryCount.value))
      imageSrc.value = url.href
    }, LAZY_LOAD_IMAGE_DELAY_MS * retryCount.value)
  }

  // Loading image failed after maximum retries
  else {
    isLoadFail.value = true
    emits('onImgError')
    ;(evt.target as HTMLImageElement).style.display = 'none'
  }
}
</script>

<template>
  <div
    :class="[
      'image-container',
      ratioClass,
      emptyImageClass,
      ...responsiveSizeClass,
      { 'is-load-success': loadStatus === 'LOAD_SUCCESS' },
      { 'is-load-fail': loadStatus === 'LOAD_FAIL' },
      { 'portrait-xs': portraitXs },
      { 'fill-container': isFillContainer },
    ]"
  >
    <ClientOnly>
      <img
        :src="imageSrc"
        :alt="alt"
        loading="lazy"
        @load="handleOnLoad"
        @error="handleOnError"
      />
    </ClientOnly>

    <ClientOnly>
      <NovaLoadingIndicator
        v-if="props.imageUrl && loadStatus === 'LOADING'"
        :bg-bright="'light'"
        :overlay="true"
      />
    </ClientOnly>
  </div>
</template>

<style lang="scss" scoped>
$responsive-items: ('', 'desktop-', 'tablet-', 'mobile-');

@mixin setRatio($prefix) {
  &.#{$prefix}ratio-16x9::before {
    padding-top: 56.25%;
  }
  &.#{$prefix}ratio-15x8::before {
    padding-top: 53.33333333%;
  }
  &.#{$prefix}ratio-1x1::before {
    padding-top: 100%;
  }
  &.#{$prefix}ratio-2x1::before {
    padding-top: 50%;
  }
  &.#{$prefix}ratio-3x2::before {
    padding-top: 66.6666666667%;
  }
  &.#{$prefix}ratio-4x1::before {
    padding-top: 25%;
  }
  &.#{$prefix}ratio-4x3::before {
    padding-top: 75%;
  }
  &.#{$prefix}ratio-4x5::before {
    padding-top: 125%;
  }
  &.#{$prefix}ratio-3x4::before {
    padding-top: 133.3333333333%;
  }
  &.#{$prefix}ratio-3x5::before {
    padding-top: 166.6666666667%;
  }
  &.#{$prefix}ratio-5x1::before {
    padding-top: 20%;
  }
  &.#{$prefix}ratio-5x2::before {
    padding-top: 40%;
  }
  &.#{$prefix}ratio-6x5::before {
    padding-top: 84.212%;
  }
  &.#{$prefix}ratio-auto {
    width: 100%;
    height: auto;

    &:before {
      display: none;
    }

    &.is-load-fail {
      min-height: 100px;
    }

    > img {
      position: relative;
      height: auto;
      object-fit: initial;
    }
  }
  &.#{$prefix}ratio-640x185::before {
    padding-top: 28.90625%;
  }
  &.#{$prefix}ratio-380x213::before {
    padding-top: 56.05263157894737%;
  }
  &.#{$prefix}ratio-20x7::before {
    padding-top: 35%;
  }
  &.#{$prefix}ratio-20x3::before {
    padding-top: 15%;
  }
  &.#{$prefix}ratio-100x85::before {
    padding-top: 85%;
  }
  &.#{$prefix}ratio-10x1::before {
    padding-top: 10%;
  }
  &.#{$prefix}ratio-10x3::before {
    padding-top: 30%;
  }
}

@mixin ratios() {
  //@each $responsive in $responsive-items {
  //  @include setRatio($responsive);
  //
  //  @include tablet {
  //    @if ($responsive == 'tablet-') {
  //      @include setRatio($responsive);
  //    }
  //  }
  //
  //  @include mobile {
  //    @if ($responsive == 'mobile-') {
  //      @include setRatio($responsive);
  //    }
  //  }
  //}

  &.ratio-16x9::before {
    padding-top: 56.25%;
  }
  &.ratio-15x8::before {
    padding-top: 53.33333333%;
  }
  &.ratio-1x1::before {
    padding-top: 100%;
  }
  &.ratio-2x1::before {
    padding-top: 50%;
  }
  &.ratio-3x2::before {
    padding-top: 66.6666666667%;
  }
  &.ratio-4x1::before {
    padding-top: 25%;
  }
  &.ratio-4x3::before {
    padding-top: 75%;
  }
  &.ratio-4x5::before {
    padding-top: 125%;
  }
  &.ratio-3x4::before {
    padding-top: 133.3333333333%;
  }
  &.ratio-3x5::before {
    padding-top: 166.6666666667%;
  }
  &.ratio-5x1::before {
    padding-top: 20%;
  }
  &.ratio-5x2::before {
    padding-top: 40%;
  }
  &.ratio-6x5::before {
    padding-top: 84.212%;
  }
  &.ratio-auto {
    width: 100%;
    height: auto;

    &:before {
      display: none;
    }

    &.is-load-fail {
      min-height: 100px;
    }

    > img {
      position: relative;
      height: auto;
      object-fit: initial;
    }
  }
  &.ratio-640x185::before {
    padding-top: 28.90625%;
  }
  &.ratio-380x213::before {
    padding-top: 56.05263157894737%;
  }
  &.ratio-20x7::before {
    padding-top: 35%;
  }
  &.ratio-20x3::before {
    padding-top: 15%;
  }
  &.ratio-100x85::before {
    padding-top: 85%;
  }
  &.ratio-10x1::before {
    padding-top: 10%;
  }
  &.ratio-10x3::before {
    padding-top: 30%;
  }
  // desktop
  &.desktop-ratio-16x9::before {
    padding-top: 56.25%;
  }
  &.desktop-ratio-15x8::before {
    padding-top: 53.33333333%;
  }
  &.desktop-ratio-1x1::before {
    padding-top: 100%;
  }
  &.desktop-ratio-2x1::before {
    padding-top: 50%;
  }
  &.desktop-ratio-3x2::before {
    padding-top: 66.6666666667%;
  }
  &.desktop-ratio-4x1::before {
    padding-top: 25%;
  }
  &.desktop-ratio-4x3::before {
    padding-top: 75%;
  }
  &.desktop-ratio-4x5::before {
    padding-top: 125%;
  }
  &.desktop-ratio-3x4::before {
    padding-top: 133.3333333333%;
  }
  &.desktop-ratio-3x5::before {
    padding-top: 166.6666666667%;
  }
  &.desktop-ratio-5x1::before {
    padding-top: 20%;
  }
  &.desktop-ratio-5x2::before {
    padding-top: 40%;
  }
  &.desktop-ratio-6x5::before {
    padding-top: 84.212%;
  }
  &.desktop-ratio-auto {
    width: 100%;
    height: 100%;
  }
  &.desktop-ratio-640x185::before {
    padding-top: 28.90625%;
  }
  &.desktop-ratio-380x213::before {
    padding-top: 56.05263157894737%;
  }
  &.desktop-ratio-20x7::before {
    padding-top: 35%;
  }
  &.desktop-ratio-20x3::before {
    padding-top: 15%;
  }
  &.desktop-ratio-100x85::before {
    padding-top: 85%;
  }
  &.desktop-ratio-10x1::before {
    padding-top: 10%;
  }
  &.desktop-ratio-10x3::before {
    padding-top: 30%;
  }
  @include tablet {
    &.tablet-ratio-16x9::before {
      padding-top: 56.25%;
    }
    &.tablet-ratio-15x8::before {
      padding-top: 53.33333333%;
    }
    &.tablet-ratio-1x1::before {
      padding-top: 100%;
    }
    &.tablet-ratio-2x1::before {
      padding-top: 50%;
    }
    &.tablet-ratio-3x2::before {
      padding-top: 66.6666666667%;
    }
    &.tablet-ratio-4x1::before {
      padding-top: 25%;
    }
    &.tablet-ratio-4x3::before {
      padding-top: 75%;
    }
    &.tablet-ratio-4x5::before {
      padding-top: 125%;
    }
    &.tablet-ratio-3x4::before {
      padding-top: 133.3333333333%;
    }
    &.tablet-ratio-3x5::before {
      padding-top: 166.6666666667%;
    }
    &.tablet-ratio-5x1::before {
      padding-top: 20%;
    }
    &.tablet-ratio-5x2::before {
      padding-top: 40%;
    }
    &.tablet-ratio-6x5::before {
      padding-top: 84.212%;
    }
    &.tablet-ratio-auto {
      width: 100%;
      height: 100%;
    }
    &.tablet-ratio-640x185::before {
      padding-top: 28.90625%;
    }
    &.tablet-ratio-380x213::before {
      padding-top: 56.05263157894737%;
    }
    &.tablet-ratio-20x7::before {
      padding-top: 35%;
    }
    &.tablet-ratio-20x3::before {
      padding-top: 15%;
    }
    &.tablet-ratio-100x85::before {
      padding-top: 85%;
    }
    &.tablet-ratio-10x1::before {
      padding-top: 10%;
    }
    &.tablet-ratio-10x3::before {
      padding-top: 30%;
    }
  }

  @include mobile {
    &.mobile-ratio-16x9::before {
      padding-top: 56.25%;
    }
    &.mobile-ratio-15x8::before {
      padding-top: 53.33333333%;
    }
    &.mobile-ratio-1x1::before {
      padding-top: 100%;
    }
    &.mobile-ratio-2x1::before {
      padding-top: 50%;
    }
    &.mobile-ratio-3x2::before {
      padding-top: 66.6666666667%;
    }
    &.mobile-ratio-4x1::before {
      padding-top: 25%;
    }
    &.mobile-ratio-4x3::before {
      padding-top: 75%;
    }
    &.mobile-ratio-4x5::before {
      padding-top: 125%;
    }
    &.mobile-ratio-3x4::before {
      padding-top: 133.3333333333%;
    }
    &.mobile-ratio-3x5::before {
      padding-top: 166.6666666667%;
    }
    &.mobile-ratio-5x1::before {
      padding-top: 20%;
    }
    &.mobile-ratio-5x2::before {
      padding-top: 40%;
    }
    &.mobile-ratio-6x5::before {
      padding-top: 84.212%;
    }
    &.mobile-ratio-auto {
      width: 100%;
      height: 100%;
    }
    &.mobile-ratio-640x185::before {
      padding-top: 28.90625%;
    }
    &.mobile-ratio-380x213::before {
      padding-top: 56.05263157894737%;
    }
    &.mobile-ratio-20x7::before {
      padding-top: 35%;
    }
    &.mobile-ratio-20x3::before {
      padding-top: 15%;
    }
    &.mobile-ratio-100x85::before {
      padding-top: 85%;
    }
    &.mobile-ratio-10x1::before {
      padding-top: 10%;
    }
    &.mobile-ratio-10x3::before {
      padding-top: 30%;
    }
  }
}

.image-container {
  position: relative;
  flex-shrink: 0;
  width: 100%;
  padding: 0;
  overflow: hidden;
  background-color: inherit;

  &::before {
    display: block;
    content: '';
    padding-top: 56.25%;
  }

  @include ratios;

  &.portrait-xs.empty-portrait:after {
    background-size: 12px auto;
  }

  &.fill-container {
    height: 100%;
    &::before {
      padding-top: 100%;
    }
  }

  &.is-load-success {
    > img {
      opacity: 1;
    }
  }

  &.is-load-fail {
    &.empty {
      @mixin base-empty-container {
        position: absolute;
        top: 50%;
        left: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
      }

      &-default {
        &:after {
          @include base-empty-container;
          content: '';
          width: 24px;
          height: 24px;
          background-repeat: no-repeat;
          background-image: url('assets/images/empty-image-default.svg');
        }
      }

      &-portrait {
        &:after {
          @include base-empty-container;
          content: '';
          background-size: 24px auto;
          background-repeat: no-repeat;
          background-position: center;
          background-color: $color-bg-1;
          background-image: url('assets/images/empty-image-portrait.svg');
        }
      }

      &-magnifier {
        &::after {
          @include base-empty-container;
          width: 100%;
          height: 100%;
          content: '\e97d';
          font-family: 'lmnova';
          font-size: 24px;
        }
      }

      &-user-circle {
        &::after {
          @include base-empty-container;
          width: 100%;
          height: 100%;
          content: '\e9a0';
          font-family: 'lmnova';
          font-size: 24px;
        }
      }
    }
  }

  > img {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
  }

  //.empty-image {
  //  position: absolute;
  //  top: 50%;
  //  left: 50%;
  //  transform: translate(-50%, -50%);
  //
  //  &.portrait {
  //    width: 100%;
  //    height: 100%;
  //    background-color: $color-bg-1;
  //    background-size: 24px auto;
  //    background-repeat: no-repeat;
  //    background-position: center;
  //    background-image: url('assets/images/empty-image-portrait.svg');
  //  }
  //
  //  &.default {
  //    width: 24px;
  //    height: 24px;
  //    background-size: 100%;
  //    background-image: url('assets/images/empty-image-default.svg');
  //  }
  //
  //  &.magnifier {
  //    display: flex;
  //    align-items: center;
  //    justify-content: center;
  //    width: 100%;
  //    height: 100%;
  //    &::after {
  //      content: '\e97d';
  //      font-family: 'lmnova';
  //      font-size: 24px;
  //    }
  //  }
  //}

  //.loading-indicator {
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  background-color: $color-bg-1;
  //
  //  &.done {
  //    opacity: 0;
  //  }
  //}
}
</style>
