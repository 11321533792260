<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import type {
  NovaBoxPostActionPrivatePanelEmits,
  NovaBoxPostActionPrivatePanelProps,
} from './NovaBoxPostActionPrivatePanel.types'
import type { PostPrivateAction } from '@store'

const emit = defineEmits<NovaBoxPostActionPrivatePanelEmits>()
const props = defineProps<NovaBoxPostActionPrivatePanelProps>()
const { gtEvent } = useGoogleTag()
const { privateActions, action } = usePrivateActions(
  computed(() => ({
    source: props.source,
  }))
)
const swiper = ref<typeof Swiper | null>(null)

watch(
  () => privateActions.value,
  () => {
    swiper.value?.update()
  }
)

// init swiper
const handleOnSetSwiper = (evt: typeof Swiper) => {
  swiper.value = evt
}

// 스와이프
const handleOnSlideTo = (slideIdx: number) => {
  swiper.value?.slideTo(slideIdx)
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: `공유하기 클릭 > ${slideIdx ? '슬라이드 이동' : '뒤로가기'}`,
    eventLabel: 'chev-compact-right',
    eventSlot: '어디로 공유할지 선택하는 슬라이드로 이동',
    eventI18nAddr: 'chev-compact-right',
    eventComponent: 'SwiperSlide',
  })
}

// 액션 이후 Tippy 닫기
const handleOnClose = () => {
  swiper.value?.slideTo(0)
  emit('close')
}
// 액션
const handleOnPrivateAction = async (id: PostPrivateAction, name: string) => {
  if (id === 'share') {
    handleOnSlideTo(1)
    return
  }
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: `${name}`,
    eventLabel: name,
    eventSlot: '포스트 상세 > 액션 패널',
    eventI18nAddr: name,
    eventComponent: 'Button',
    cmtyNttSn: props.source.cmtyNttSn,
    creatorLink: props.source.creatorLink,
    userNcnm: props.source.userNcnm,
  })
  await action(id)
  handleOnClose()
}
</script>

<template>
  <NovaBoxBase class="private-panel" :floating="true">
    <Swiper
      class="swiper"
      :allow-touch-move="false"
      :auto-height="true"
      @swiper="handleOnSetSwiper"
    >
      <SwiperSlide>
        <button
          v-for="item in privateActions"
          :key="item.id"
          type="button"
          class="action-item"
          @click.stop="handleOnPrivateAction(item.id, item.name)"
        >
          <span class="label">{{ item.name }}</span>
          <NovaIcon
            :icon="{ type: 'outline', name: 'chev-compact-right' }"
            class="chev"
          />
        </button>
      </SwiperSlide>

      <SwiperSlide>
        <NovaBoxPostActionSharePanel
          :cmty-ntt-sn="source.cmtyNttSn"
          :post-title="source.nttSj"
          :post-contents="source.nttCn || ''"
          :share-count="source.shCount || 0"
          :show-navigation="true"
          @go-back="handleOnSlideTo(0)"
          @close="handleOnClose"
        />
      </SwiperSlide>
    </Swiper>
  </NovaBoxBase>
</template>

<style lang="scss" scoped>
.private-panel {
  width: 200px !important;
  padding: 10px 0 !important;

  .swiper {
    width: 100%;
  }

  .action-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    width: 100%;
    height: 30px;
    padding: 0 15px 0 20px;

    > .label {
      flex-grow: 1;
      display: flex;
      align-items: center;
      @include text-style($text-body-14-regular);
      line-height: 18px;
      color: $color-text-2;
    }

    > .chev {
      flex-shrink: 0;
      color: $color-text-3;
    }
  }
}
</style>
