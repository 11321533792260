import type { SponsorSttusCode, PgPaymeantCode } from '@store'

/**
 * 후원자 목록, 후원 목록
 */
export const sponsors = {
  topButton: {
    creatorTitle: '크리에이터님의 후원자',
    userTitle: '후원 중인 {subscribeGrade}',
  },
  search: {
    creatorTitle: '{premiumBrand} 후원자 목록',
    userTitle: '{premiumBrand} 후원 내역',
    placeholder: '고유 번호와 닉네임으로 검색',
    orderBy: {
      newest: '전체',
      odrQty: '후원횟수',
    },
    filterType: {
      all: '전체',
      Day30: '최근 30일',
      error: '날짜를 선택해 주세요.',
    },
  },
  creatorTable: {
    headerSn: '고유 번호',
    headerNcnm: '닉네임',
    headerOdrSetleDt: '최초 후원일',
    headerPymntDt: '최근 후원일',
    headerOdr: '후원 횟수',
  },
  userTable: {
    headerSn: '고유 번호',
    headerNcnm: '닉네임',
    headerOdrSetleDt: '후원 날짜',
    headerOdrDt: '후원 혜택 기간',
    headerCode: '후원 상태',
    headerDetail: {
      title: '상세 내역',
      btn: '보기',
    },
  },
  table: {
    error: {
      message: '가입되지 않은 회원입니다.',
    },
  },
  sponsorsDetail: {
    title: '{premiumBrand} 후원 상세 내역',
    userBenefit: '{creatorNickname}님의 {premiumBrand} 혜택',
    benefit: '혜택',
    sponsorsInfo: {
      title: '후원 정보',
      sponsorStatus: '후원 상태',
      userPrmbrshPgmSbscrptnSn: '고유 번호',
      sbscrptnOdrBeginDt: '후원 날짜',
      sbscrptnOdrEndDt: '후원 혜택 기간',
    },
    payment: {
      title: '결제 정보',
      pgPayment: '결제 수단',
      subscribeAmount: '결제 금액',
      sponsorStatus: '결제 상태',
    },
  },

  sponsorStatus: {
    name: {
      RSCP: '후원 중',
      SBSC: '후원 중',
      SBED: '후원 종료',
      RQER: '처리오류',
      TRMN: '후원 종료',
    } as { [Property in SponsorSttusCode]: string },
  },
  pgPayment: {
    name: {
      21001: '무통장 입급',
      10009: '카드결제',
    } as { [Property in PgPaymeantCode]: string },
  },

  creatorsList: {
    empty: '후원자가 없습니다.',
    error: '후원자 목록을 가져올 수 없습니다. 잠시 후 다시 시도해 주세요.',
  },
  userList: {
    empty: '후원한 {subscribeGrade}가 없습니다',
    error:
      '후원한 {subscribeGrade} 목록을 가져올 수 없습니다. 잠시 후 다시 시도해 주세요.',
  },
  detail: {
    empty: '후원 정보가 없습니다.',
    error: '후원 정보를 찾을 수 없습니다. 잠시 후 다시 시도해 주세요.',
  },
  paymentStatus: {
    name: {
      RSCP: '결제 완료',
      SBSC: '결제 완료',
      SBED: '결제 완료',
      RQER: '결제 실패',
      TRMN: '결제 완료',
    } as { [Property in SponsorSttusCode]: string },
  },
}
