import * as cheerio from 'cheerio'
import type { TipTapExtractImage } from './useExtractElementFromString.types'

/**
 * 문자열에서 요소를 추출
 *
 * @param {string} htmlStr - HTML 태그를 포함한 문자열
 * @param {string} [type='both'] - 추출의 타입. 가능한 값은 'count' 또는 'both'
 *
 * @returns {number | TipTapExtractImage} - 요소의 카운트 또는 카운트와 URL을 포함하는 오브젝트
 */
export const useExtractElementFromString = (
  htmlStr: string,
  type: 'count' | 'both' = 'both'
): number | TipTapExtractImage => {
  const config = useRuntimeConfig()
  const result: TipTapExtractImage = { count: 0, url: [] }
  const dom = cheerio.load(htmlStr)
  const images = dom('img')

  if (type === 'count') {
    return images.length
  }

  result.count = images.length

  images.each((_idx, img) => {
    const imgEl = dom(img)
    const src = imgEl.attr('src')
    if (src?.includes(config.public.IMAGE_URL)) result.url.push(src)
  })

  return result
}
