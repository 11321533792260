import { commonError } from './commonError'
import type { ApplyCreatorStatus, ApplyCreatorConditionType } from '@store'
import type { BenefitKey } from '@pages/apply-creator/index.vue'

/**
 * 크리에이터 신청하기
 */
export const applyCreator = {
  entry: '크리에이터 신청하기',
  guide: {
    header: {
      target: '{nickname}님,',
      title: '크리에이터 신청해보세요',
      description: '수익창출, 크리에이터 지원 등의 혜택을 받아보세요.',
      benefit:
        '<strong>{appName}</strong>에서는 <strong>자신만의 콘텐츠 경쟁력</strong>을 가지신 분이라면 누구나!<br /><strong>Web3 크리에이터</strong>가 되어, 다양한 혜택을 받으며 창작 활동을 하실 수 있습니다.<br /><br />지속적인 콘텐츠 생산 능력을 갖추신 분, 매력적이고 개성 넘치는 콘텐츠를 갖고 계신 분, 자신만의 전문분야에 대해 나눌 이야기가 있으신 분 등 모두를 환영합니다.',
    },
    benefits: {
      label: '다양한 혜택',
      contents: {
        weekly: {
          label: '매주 활동 리워드 지급',
          description: '크리에이터만을 대상으로 더 큰 규모의 리워드 적용',
        },
        fix: {
          label: '월간 Bonus 리워드!',
          description:
            '매 월 우수한 콘텐츠와 활발한 소통으로 커뮤니티 활성화에 기여한 크리에이터에게 보너스 리워드 추가 지급!',
        },
        /* meeting: {
          label: 'Web3 Creator Fund 신청 가능',
          description:
            '투표를 통해 선정되는 크리에이터의 프로젝트에 1,000만원 상당의 LM 지원',
        }, */
        nft: {
          label: 'NFT 발행 기회',
          description: '프로젝트 진행 시, NFT 발행기회 제공!',
        },
        communication: {
          label: '팬들과의 소통',
          description: '크리에이터와 팬들간의 채팅방 및 라이브 지원',
        },
      } as { [Property in BenefitKey]: { label: string; description: string } },
    },
    requiredConditions: {
      label: '필수 조건',
      kyc: 'KYC 인증',
    },
    optionalConditions: {
      label: '전환 신청 요건을 선택하세요.',
      contents: {
        CUAR: '나의 대표 콘텐츠 3개 이상 제출',
        CUFW: '타 플랫폼(인스타그램, 유튜브, 틱톡 등) 기팔로워 1만 명 이상 보유',
      } as { [Property in ApplyCreatorConditionType]: string },
    },
    previewHomeLink: {
      label: '홈페이지 링크',
      action: '중복확인',
      message: {
        failLength: '최소 {min} 글자수 이상을 입력해 주세요.',
        failDuplicate: '이미 다른 크리에이터가 사용중인 링크입니다.',
        failCharacter: '영문 + 숫자만 입력해 주세요.',
        failDefault: '중복 확인 할 수 없습니다. 잠시 후 다시 시도해 주세요.',
        success: '사용할 수 있는 크리에이터 홈페이지 링크입니다.',
      },
    },
    notices: {
      label: '유의 사항',
      contents: [
        '타인의 SNS 계정 혹은 콘텐츠를 도용한 경우, 모든 법적 그리고 금전적 손실에 대한 책임은 신청자 본인에게 있습니다.',
        'I LIKE LM에 업로드하는 모든 콘텐츠는 반드시 저작권법을 준수하여야 합니다.',
        '크리에이터 신청에 대한 심사 결과는 영업일 기준 3일 이내에 안내 드립니다.',
        '심사는 내부 기준에 따라 이루어지며, 신청이 반려된 유저는 신청일 30일 이후부터 재신청할 수 있습니다.',
        '위의 크리에이터 전환 신청 요건과 혜택, 등급 유지조건은 커뮤니티 운영 상황에 따라 변동 및 중단될 수 있습니다.',
      ],
    },
    apply: {
      status: {
        available: '신청가능', // 추상적인 상태는 존재하지만 노출하지 않음
        approved: '승인완료',
        underReview: '심사중',
        reject: '승인거부',
      } as { [Property in ApplyCreatorStatus]: string },
      gateStatus: {
        underReview: '심사중',
        confirm: '결과확인',
      },
      action: {
        apply: '크리에이터 신청하기',
        review: '심사 요청',
        underReview: '심사 진행중',
      },
      notificationTitle: '알림',
      message: {
        failAlreadyCreator: '이미 크리에이터 전환이 완료되었습니다.',
        failUnknown: '가입되지 않은 회원입니다.',
        failUser: '신청 불가능한 사용자 입니다.',
        failCondition: '크리에이터 신청 요건을 충족하지 않습니다.',
        failCreatorLink: '확인이 필요한 크리에이터 링크입니다.',
        failDefault: '신청을 완료할 수 없습니다. 잠시 후 다시 시도해 주세요.',
        failReject:
          '{userNcnm} 님의 크리에이터 신청이 반려되었습니다.<br />신청일 30일 이후부터 재신청할 수 있습니다.',
        failUnderReview:
          '{userNcnm} 님의 크리에이터 신청이 심사중에 있습니다.<br />심사 결과는 영업일 기준 3일 이내에 안내 드립니다.',
        successApply:
          '크리에이터 신청이 완료되었습니다.<br />심사 결과는 영업일 기준 3일 이내에 안내 드립니다.',
        errors: {
          4014: '신청 불가능한 사용자입니다.',
          4016: '확인이 필요한 코드입니다.',
          4018: '이미 사용중인 크리에이터홈 주소 입니다.',
          default: commonError.network.message,
        },
      },
    },
  },
  requireKycDialog: {
    title: '알림',
    message: 'KYC 인증을 완료하셔야 크리에이터 신청이 가능합니다.',
    confirm: 'KYC 인증하러 가기',
  },
  // 크리에이터 신청: 대표 콘텐츠 3개
  conditionsContent: {
    pageTitle: '크리에이터 신청중',
    sections: {
      activity: {
        title: '{appName} 크리에이터 활동 계획을 간단히 작성해 주세요.',
        titleSub: '({count}자 이상)',
        textField: {
          placeholder: '',
          error: '{count}자 이상 입력하세요.',
        },
      },
      info: {
        title: '신청 정보 입력',
        representative: '대표 콘텐츠 제출({count}개 이상)',
        contentsLink: '콘텐츠 링크 첨부하기',
        contentsPlaceholder: {
          CLK: '콘텐츠 링크를 입력하세요.',
          CAT: '콘텐츠 파일을 업로드하세요.',
        },
        contentsFile: '콘텐츠 파일 첨부하기',
        contentsEmpty: '"+" 버튼을 눌러 대표 콘텐츠를 추가해 주세요.',
        error: '{count} 이상 대표 컨테츠를 입력하세요.',
        contentsLinkEmpty: '콘텐츠 링크를 입력하세요.',
        contentsLinkError: '유효한 콘텐츠 링크가 아닙니다.',
        contentsLinkNotMatch: '등록하신 포스트 링크를 입력하세요.',
        contentsLinkNotPostOwner:
          '등록하신 포스트는 직접 작성하신 포스트가 아닙니다.',
        contentsFileEmpty: '콘텐츠 파일을 업로드하세요.',
        contentsDuplicate: '이미 등록된 대표 콘텐츠입니다.',
        descriptions: [
          '링크 혹은 파일을 선택하여 대표 콘텐츠를 최소 3개, 최대 5개까지 제출해 주세요.',
          '파일은 jpg, png, mp4, word 파일등을 추가할 수 있습니다.',
          '"등록" 버튼을 누르면 대표 콘텐츠에 추가됩니다.',
          '"삭제" 버튼을 누르면 대표 콘텐츠에서 삭제됩니다.',
        ],

        sns: 'SNS 주소 입력',
        snsSubTitle: '(1만 팔로워 이상의 계정)',
        snsPlaceholder: 'SNS 주소를 입력하세요.',
        snsErrMsg: '잘못된 SNS 주소입니다.',
        verifiedSnsAccount: '본인의 계정임을 확인합니다.',
      },
      creatorCategory: {
        title: '크리에이터 카테고리를 선택하세요.',
        placeholder: '카테고리 선택',
        error: '카테고리를 선택하세요.',
      },
      creatorHomeLink: {
        title: '크리에이터홈 주소 설정',
        checkDuplicate: '중복확인',
        checkedDuplicate: '확인완료',
        errors: {
          unavailableCreatorHomeLink:
            '사용할 수 없는 문자가 포함되어 있습니다. 다른 크리에이터 홈 주소를 사용해 주세요.',
          checkDuplicate: '중복확인을 하세요.',
          failLength: '최소 {min} 글자수 이상을 입력하세요.',
          failDuplicate: '이미 다른 크리에이터가 사용중인 주소입니다.',
          failCharacter: '영문 + 숫자만 입력하세요.',
          failDefault: '중복 확인 할 수 없습니다. 잠시 후 다시 시도해 주세요.',
        },
        success: '사용할 수 있는 크리에이터홈 주소입니다.',
      },
      howToKnow: {
        title: '{appName}를 알게 된 경로',
        placeholder: '예시) 인스타광고, 지인추천 등',
      },
    },
    apply: '심사 요청',
  },
}
