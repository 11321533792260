import type { Route } from '@configs'

type GtagEventDefaultActionName =
  | 'add_payment_info'
  | 'add_to_cart'
  | 'add_to_wishlist'
  | 'begin_checkout'
  | 'checkout_progress'
  | 'exception'
  | 'generate_lead'
  | 'login'
  | 'page_view'
  | 'purchase'
  | 'refund'
  | 'remove_from_cart'
  | 'screen_view'
  | 'search'
  | 'select_content'
  | 'set_checkout_option'
  | 'share'
  | 'sign_up'
  | 'timing_complete'
  | 'view_item'
  | 'view_item_list'
  | 'view_promotion'
  | 'view_search_results'

type GtagEventCustomActionName =
  | 'view_app_link' // 웹에서 앱 링크 열림(모바일 일반 브라우저에서만 열림)
  | 'go_app_link' // 사용자가 앱 링크를 클릭
  | 'clickEvent'
  | 'selectEvent'
  | 'scrollEvent'
  | 'viewEvent'
  | 'postAction'
  | 'replyAction'
  | 'userAction'
  | 'contentsAction'
  | 'fileAction'
  | 'etcAction'

type GtagSetArgName = 'user_properties'

type UserGenderType = 'M' | 'F' | null

type GtagEventActionName =
  | GtagEventDefaultActionName
  | GtagEventCustomActionName

export interface GaEventParameter {
  eventCategory: string // 클릭, 포스트, 댓글, 사용자 등
  eventAction: string // 버튼 클릭, 링크 클릭, 포스트 좋아요, 포스트 좋아요 취소 등
  eventLabel: string // 버튼 또는 컨트롤 라벨명
  eventPage?: Route['pageID'] // 현재 화면을 정의하는 변수(ROUTE.pageID) > 주입받지 않으면 함수 내부에서 처리
  eventSlot: string // 페이지 내의 위치를 나타내는 변수(탑배너, 개인 크리에이터, 기업 크리에이터, 노바 Pick 등 한글로 표시)
  eventI18nAddr: string // i18n 주소 값(고유한 값)
  eventComponent: string // NovaButtonText, NovaButtonIcon, Swiper, NovaRecyclerView, NovaSelectBox 등
}
export type GaEventPartialParam<T> = GaEventParameter & T

export interface SetUserParam {
  userSn?: number | null
  userAge?: number | null
  userGender?: UserGenderType
}

export const useGoogleTag = () => {
  const nuxtApp = useNuxtApp()
  const { public: config } = nuxtApp.$config
  const { gtag } = useGtag()
  const gtConfig = (data: SetUserParam) => {
    gtag('config', config.GTAG_DEFAULT_APP_KEY, { ...data })
  }

  const gtSet = (actionName: GtagSetArgName, properties?: SetUserParam) => {
    gtag('set', actionName, { ...properties })
  }

  const gtEvent = <T = Record<string, any>>(
    actionName: GtagEventActionName,
    eventParam?: GaEventPartialParam<T>
  ) => {
    if (typeof eventParam !== 'undefined' && !eventParam?.eventPage) {
      eventParam.eventPage = useGetRoutePageID()
    }
    gtag('event', actionName, { ...eventParam })
  }

  return { gtEvent, gtConfig, gtSet }
}
