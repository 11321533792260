<script setup lang="ts">
import type { AvatarIframeParams } from './NovaModalAvatar.types'
import type { AuthToken } from '@store'
import { clientId } from '@configs'

defineOptions({
  inheritAttrs: false,
})

const { cookieNames, getCookie } = useCookies()
const { hide: modalHide } = useModal()
const config = useRuntimeConfig()
const userStore = useUserStore()
const mypageStore = useMyPageStore()
const layoutStore = useLayoutStore()
const show = ref(false)
const avatarUrl = ref('')

// 모달 오픈전 사전 작업 수행
const handleOnBeforeOpen = () => {
  createAvatarUrl()
}

// 아바타 아이프레임 url 생성
const createAvatarUrl = () => {
  const authToken = getCookie<AuthToken>(cookieNames.AUTH_TOKEN)
  const requiredCondition: AvatarIframeParams = {
    userSn: String(userStore.user?.userCmmnSn),
    accessToken: authToken?.accessToken || '',
    sysId: clientId,
  }
  const params = new URLSearchParams(requiredCondition).toString()
  avatarUrl.value = `${config.public.AVATAR_URL}?${params}`
}

// 팝업 닫기
const handleOnClose = async () => {
  try {
    layoutStore.updateLoadingIndicatorGlobal({ show: true })
    await mypageStore.fetchProfile()
  } catch {
  } finally {
    layoutStore.updateLoadingIndicatorGlobal({ show: false })
  }
  await modalHide(modalsName.MODAL_AVATAR)
}

// 팝업 닫은 후 초기화
const handleOnClosed = () => {
  avatarUrl.value = ''
}
</script>

<template>
  <VueFinalModal
    v-model="show"
    :name="modalsName.MODAL_AVATAR"
    :content-class="'modal-content'"
    classes="modal-container"
    @before-open="handleOnBeforeOpen"
    @click-outside="handleOnClose"
    @closed="handleOnClosed"
  >
    <ClientOnly>
      <NovaBoxBase v-if="show" class="modal-box">
        <div class="modal-header">
          <h4 class="title">{{ $t('profileControl.avatar') }}</h4>

          <NovaButtonIcon
            :icon="{ type: 'outline', name: 'close-extend' }"
            :theme="'transparent'"
            :size="20"
            class="btn-close"
            @click="handleOnClose"
          />
        </div>

        <div class="modal-body">
          <NovaLayoutScrollContainer>
            <NovaIframeContainer v-if="avatarUrl" :url="avatarUrl" />
          </NovaLayoutScrollContainer>
        </div>
      </NovaBoxBase>
    </ClientOnly>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
:deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.vfm--overlay) {
  background-color: hex-to-rgba($color-black, 0.65);
}

:deep(.modal-content) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: none;

  .modal-box {
    width: 100%;
    height: 100%;
    max-width: 420px;
    max-height: calc(100% - 80px);
    padding: 0;
    pointer-events: auto;
    overflow: hidden;

    .modal-header {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 56px;
      padding: 0 20px;
      color: $color-text-2;
      background-color: $color-bg-3;
      border-bottom: 1px solid $border-1;

      .title {
        @include text-style($text-display-bold);
      }
    }

    .modal-body {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: hidden;
    }

    @include mobile {
      border-radius: 0 !important;
      max-width: 100%;
      max-height: 100%;
    }
  }
}
</style>
