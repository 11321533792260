import { commonError } from './commonError'

const notificationType = {
  push: '푸시',
  email: 'E-mail',
  sms: 'SMS',
}

const pushDialog = {
  title: '푸시 알림',
  contents:
    '<p style="font-size: 16px; font-weight: 500; line-height: 1.2; text-align: center;">푸시 알림을 받지 않지만,<br />{appName} 앱을 열면<br />서비스 알림을 볼 수 있습니다.</p><p style="font-size: 12px; text-align: center;">*OFF 설정을 하여도 서비스 이용에 필수적인 공지사항은 푸시 알림이 갈 수 있습니다.</p>',
  confirm: '확인',
}

const serviceDialog = {
  title: '서비스 알림',
  contents:
    '서비스 알림을 받지 않으면<br />알림창에 새로운 알림이 기록되지 않습니다.<br />알림 수신을 유지하시겠습니까?',
  cancel: '해제',
  confirm: '유지',
}

const marketingDialog = {
  title: '마케팅 알림({type})',
  contents:
    '{appName}의 다양한 이벤트•광고성 정보를<br />놓치지 않고 받아볼 수 있습니다<br />수신 동의를 유지하시겠습니까?',
  cancel: '해제',
  confirm: '유지',
}

const notificationMarketingAgreeDialog = {
  title: '마케팅 Push 알림을 받아보시겠어요?',
  message:
    '다양한 이벤트/혜택 정보를<br />앱 푸시로 놓치지 않고 받으실 수 있어요!',
  messageSm:
    '* 마케팅 Push 알림 수신 동의는<br />I LIKE LM 앱 설정 > 알림 설정에서 변경할 수 있습니다.',
  actions: {
    action: '다음에요',
    positive: '알림 받을게요',
  },
  errors: {
    7005: '마케팅 Push 알림 수신 동의를 저장할 수 없습니다. 잠시 후 다시 시도해 주세요.',
    7006: '마케팅 Push 알림 수신 동의를 저장할 수 없습니다. 잠시 후 다시 시도해 주세요.',
    default: commonError.network.message,
  },
}

const toastMessage = {
  marketing: {
    agree: '{appName} 마케팅 알림({type}) 수신에 동의하셨습니다.({date})',
    deny: '{appName} 마케팅 알림({type}) 수신을 거부 하셨습니다.({date})',
  },
}

export const notificationSetting = {
  notificationAndSetting: '알림 설정',
  selectNotificationSound: '알림음 선택',
  goDetailSetting: '상세 설정',
  marketingAgreeY: '마케팅 정보 수신 동의 ({date})',
  marketingAgreeN: '마케팅 정보 수신 거부 ({date})',
  marketingPushTime: '발송 시간: 8AM~9PM(KST)',
  notificationType,
  pushDialog,
  serviceDialog,
  marketingDialog,
  notificationMarketingAgreeDialog,
  toastMessage,
}
