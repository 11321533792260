import * as cheerio from 'cheerio'

/**
 * 주어진 HTML 문자열에서 첫 번째 'img' 태그의 'src' 속성을 검색
 *
 * @param {string} htmlString - 첫 번째 'img' 태그를 검색하기 위한 HTML 문자열
 * @returns {string} - 첫 번째 'img' 태그의 'src' 속성값, 또는 찾지 못할 경우 빈 문자열을 반환
 */
export const useGetFirstImageSource = (htmlString: string | null): string => {
  const dom = cheerio.load(htmlString || '')
  return dom('img').first().attr('src') || ''
}
