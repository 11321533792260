<script setup lang="ts">
const layoutStore = useLayoutStore()
const { loadingIndicatorGlobal } = storeToRefs(layoutStore)
</script>

<template>
  <div
    :class="['loading-indicator-global', { on: loadingIndicatorGlobal.show }]"
  >
    <div class="dim" />
    <div class="inner">
      <NovaLoadingIndicator
        v-if="loadingIndicatorGlobal.show"
        :size="80"
        :fill="false"
      />
      <p v-if="loadingIndicatorGlobal.message" class="message">
        {{ loadingIndicatorGlobal.message }}
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.loading-indicator-global {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: $z-index-loading-indicator-global;

  &.on {
    pointer-events: auto;

    .dim {
      opacity: 1;
    }
  }

  .dim {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: hex-to-rgba($color-black, 0.65);
    @include transition(opacity 0.2s ease-in-out);
  }

  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;
    z-index: 1;

    .message {
      @include text-style($text-display-medium);
      text-align: center;
      color: $color-white;
    }
  }
}
</style>
