import mitt from 'mitt'
import { type Bank } from '@store/etc/type'

type ApplicationEvents = {
  'gnb:account': unknown
  'post:createClose': unknown
  'notice:createClose': unknown
  'option:createClose': unknown
  'userContents:editMode': boolean
  'bank:select': Bank
  'main:scrollLock': boolean
}

const emitter = mitt<ApplicationEvents>()

export const useEvent = emitter.emit
export const useListen = emitter.on
