import type { BooleanYn } from '@store'

/**
 * 시설물 임대 신청 포트폴리오 타입
 */
export enum FacilityRentalPortfolioType {
  LINK = 'CLK', // 링크
  FILE = 'CAT', // 파일
}

/**
 * 시설물 분류 코드
 */
export type FacilityCode = string

// 현수 매니저님이 추가 해주신 코드
/**
 * 시설물 임대 신청 후 경과 코드
 */
export enum FacilityRentalStatus {
  LESS_THAN_20 = 'LESS_THAN_20',
  LESS_THAN_60 = 'LESS_THAN_60',
}

/**
 * 시설물 임대 신청 가능 상태
 */
export enum FacilityRentalAvailableStatus {
  UNAVAILABLE_REQUIRE_CONDITIONS = 'requireRentCheckConditions', // 시설물 임대 조건 정보 필요
  UNAVAILABLE_REQUIRE_SIGN_IN = 'requireLogin', // 로그인 필요
  UNAVAILABLE_REQUIRE_KYC_AUTH = 'requireKYC', // KYC 인증 필요
  UNAVAILABLE_APPLIED_LESS_THAN_20_DAYS = 'unavailableUnder20', // 임대 신청 후 20일 지나지 않음
  UNAVAILABLE_APPLIED_LESS_THAN_60_DAYS = 'unavailableUnder60', // 임대 신청 후 60일 지나지 않음
  AVAILABLE_WITH_APPLY_CREATOR = 'availableWithCreator', // 크리에이터 신청과 함께 임대 신청 가능
  AVAILABLE_WITH_ALREADY_CREATOR = 'availableOnlyRent', // 이미 크리에이터이며 임대 신청 가능
}
// 현수 매니저님이 추가 해주신 코드

export type TheMoonEntTermId = 10003 | 10007 | undefined

/**
 * 시설물 임대 신청 포트폴리오
 */
export interface FacilityRentalPortfolio {
  fcltrntRequstTyCode: FacilityRentalPortfolioType // 포트폴리오 타입(링크, 파일)
  applcntPrufFile?: File // 포트폴리오 파일(타입이 파일이면 필수)
  fcltrntRequstCn?: string // 포트폴리오 링크(타입이 링크면 필수)
  fcltrntRequstRegistOrdr: number // 포트폴리오 순서
}

/**
 * 시설물 임대 신청 폼
 */
export interface FacilityRentalApplyForm {
  applcntNm: string // 신청자 명
  applcntAdres: string // 신청자 주소
  applcntCttpc: string // 신청자 연락처
  applcntIntrcnCn: string // 신청자 소개: 본인(팀) 소개와 활동 경력(200자 이상)
  applcntActPlanCn: string // 신청자 활동 계획 내용: 입주 신청 사유 및 활동 계획(300자 이상)
  applcntPrufFileList: Array<FacilityRentalPortfolio> // 포트폴리오 리스트
  applcntSnsUrl?: string // 신청자 SNS URL
  fcltrntCognpathCn?: string // 알게된 경로: 시설물임대 인지경로 내용
  privcyColctPrcuseAgreAt: BooleanYn // 개인정보 수집 활용 동의 여부(Y/N)
  privcyThptyProvdAgreAt: BooleanYn // 개인정보 제3자 제공 동의 여부(Y/N)

  // FormUI 에 존재하지는 않지만 상태관리(페이지 이동시 필요)
  fcltrntFcltsTyCode: FacilityCode // 배너 슬롯 시설물임대 시설물 분류 코드 : 시설물분류코드(FCLTS_CL_CODE): P1(개인연습실) C1(안무 연습실, 합주실, 스튜디오 (예약제)
}

/**
 * 시설물 임대 신청 크리에이터
 */
export interface FacilityRentalApplyCreator {
  creatorLink?: string // 크리에이터 링크
  crtrCnvrsReqstAt?: BooleanYn // 크리에이터 전환 신청 여부(Y/N)
  crtrCtgrySn?: number // 크리에이터 카테고리 일련번호
}

/**
 * 시설물 임대 신청 페이로드
 */
export interface FacilityRentalPayload
  extends FacilityRentalApplyForm,
    FacilityRentalApplyCreator {}

/**
 * 시설물 입대 신청 페이로드(폼데이터)
 * @description API 호출시 FacilityRentalPayload 를 평탄화 작업화 된 FormData 로 전송
 */
export type FacilityRentalPayloadFormData = FormData

/**
 * 시설물 입대 신청 요건
 * @description 시설물 임대를 위한 시설물 정보 및 신청시 신청 정보 포함
 */
export interface FacilityRentalCheckCondition {
  applicationAvailableAt: string //  시설물 임대 신청 가능 여부(자세한 기준 여부를 확인 할 수 없어 해당 코드는 사용하지 않음)
  // 개인화 정보
  applicationUnavailableCode: FacilityRentalStatus | null // 시설물 임대 신청 불가능 코드 LESS_THAN_20/LESS_THAN_60
  creatorApplicationAvailableAt: BooleanYn // 크리에이터 신청 가능 여부 Y/N
  creatorLink: string | null // 크리에이터 링크
  fcltrntReqstRegistDt?: string // 시설물임대 신청 등록 일시 (조회한 대상자가 시설물 사용 신청한 경우)
  // 시설물 정보
  fcltrntFcltsTyCode: FacilityCode // 시설물임대 시설물 분류 코드
  fcltrntFcltsTyCodeNm: string // 시설물임대 시설물 분류 코드 출력 명칭
}

/**
 * 시설물 임대 약관 상세조회 payLoad
 */
export type FacilityRentalTermPayload = TheMoonEntTermId

/**
 * 시설물 임대 약관 상세 타입
 */
export interface FacilityRentalTerm {
  fcltrntStplatSn: TheMoonEntTermId
  stplatNm: string
  stplatCn: string
  stplatDetailCn: string
}

/**
 * 시설물 임대 약관 목록 타입
 */
export interface FacilityRentalTerms {
  fcltrntStplatSn: TheMoonEntTermId
  stplatEssntlAt: BooleanYn
  stplatNm: string
}
/**
 * 시설물 스토어 스테이트 타입
 */
export interface FacilityState {
  rentCheckConditions: Array<FacilityRentalCheckCondition> | null // 시설물 임대 신청 요건 정보(개인화 정보 포함될 수 있음)
  rentApplyForm: FacilityRentalApplyForm // 시설물 임대 신청 폼
  rentApplyCreator: FacilityRentalApplyCreator // 시설물 임대 신청 크리에이터
  rentTerms: Array<FacilityRentalTerms> | null // 시설물 임대 약관 목록
}
