/**
 * 권한 요청
 */
export const requestAppPermission = {
  // permission: {
  //   camera: {
  //     IOS: '카메라(en)',
  //     AOS: '카메라(en)',
  //   },
  //   storage: {
  //     IOS: '사진(en)',
  //     AOS: '갤러리(en)',
  //   },
  //   address: {
  //     IOS: '주소록(en)',
  //     AOS: '주소록(en)',
  //   },
  //   location: {
  //     IOS: '위치(en)',
  //     AOS: '위치(en)',
  //   },
  // },
  requestModal: {
    description: {
      storage: {
        post: "There is no access permission for 'photo/gallery' to post. Please go to settings and allow access.",
        profileImage:
          "There is no access permission for 'photo/gallery' to change profile image. Please go to settings and allow access.",
        mainProfileImage:
          "There is no access permission for 'photo/gallery' to change main profile image. Please go to settings and allow access.",
        homeHeaderImage:
          "There is no access permission for 'photo/gallery' to change header image. Please go to settings and allow access.",
      },
    },
  },
}
