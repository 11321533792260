/**
 * 레퍼럴 회원가입
 */
export const referral = {
  signUp: {
    dialog: {
      referralNotAble: {
        title: 'You cannot register as a member as a referral.',
        description:
          'You have already registered as a member. Please sign up after sign out.',
        action: {
          positive: 'OK',
        },
      },
      referralAble: {
        title: 'Sign up as a referral',
        description: 'Please complete membership registration as a referral.',
        mobileGuide:
          "If you are accessing from an iPhone, please turn off the pop-up blocker in Safari's settings.",
      },
    },
    error: {
      title: 'User not found',
      message:
        'This user cannot be registered as a referral. Please check the user and try again.',
    },
  },
  clipboard: {
    tooltip: 'Copy the referral link',
    toastMessage: {
      success: 'Your referral link has been copied to your clipboard.',
    },
  },
  edit: {
    label: 'Referral link',
    tooltip: 'Modification',
    toastMessage: {
      success: 'Referral link change completed.',
      failDefault:
        'An error occurred while processing your request. Please try again later.',
      failEqual:
        'It has the same name as the existing referral link. Please enter another one.',
      failUpdate: 'You have already changed your referral link.',
      failDuplicate: 'This is a referral link already in use by another user.',
      failLength: 'Please enter at least {min} characters.',
      failCharacter: 'Please enter English + numbers only.',
      unavailableRecommendLink:
        'Invalid characters in the text. Please use another referral link.',
      checkDuplicateBeforeSave: 'Please save after checking duplicates.',
    },
  },
}
