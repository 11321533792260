import apiPath from '@/store/apiPath'

export default {
  /**
   * 해시테그 랭킹 조회
   * @method GET
   */
  FETCH_HASHTAG_RANKING: `${apiPath.ETC}/v1/hashtags/rank`,

  /**
   * 게시판 리스트 조회
   * @method GET
   */
  FETCH_BOARD_LIST: `${apiPath.ETC}/v1/bbs`,

  /**
   * 게시판 상세 페이지 조회
   * @method GET
   */
  FETCH_BOARD_DETAIL: `${apiPath.ETC}/v1/bbs/{type}/{nttGroupId}`,

  /**
   * 게시판 필터 조회
   * @method GET
   */
  FETCH_BOARD_FILTER: `${apiPath.ETC}/v1/bbs/filters`,

  /**
   * 이용약관 메뉴 목록 조회
   * @method GET
   */
  FETCH_TERMS: `${apiPath.ETC}/v1/terms`,

  /**
   * 이용약관 상세 조회
   * @method GET
   */
  FETCH_TERMS_DETAIL: `${apiPath.ETC}/v1/terms/detail`,

  /**
   * 문의 분류 리스트 조회
   * @method GET
   */
  FETCH_CLASSIFICATION: `${apiPath.ETC}/v1/qna/classification`,

  /**
   * 문의하기
   * @method POST
   */
  SEND_QNA: `${apiPath.ETC}/v1/qna`,

  /**
   * 신고 코드 리스트 조회
   * @method GET
   */
  FETCH_REPORT_CODES: `${apiPath.ETC}/v1/report/code`,

  /**
   * 신규 알림 카운트 조회
   */
  FETCH_NEW_ALARM_COUNT: `${apiPath.ETC}/v1/notified/new`,

  /**
   * 알림 설정 리스트 조회
   * @method GET
   */
  FETCH_NOTIFICATION_SETTINGS: `${apiPath.ETC}/v2/notified/setup`,

  /**
   * 서비스 알림 설정 하위 리스트 조회
   * @method GET
   */
  FETCH_NOTIFICATION_SERVICE_DETAIL: `${apiPath.ETC}/v1/notified/setup/sub`,

  /**
   * 서비스 알림 설정
   * @method POST
   */
  UPDATE_NOTIFICATION_SERVICE: `${apiPath.ETC}/v2/notified/setup`,

  /**
   * 알림 설정
   * @method POST
   */
  SET_ALARM: `${apiPath.ETC}/v2/notified/setup`,

  /**
   * 알림함 리스트
   * @method GET
   */
  FETCH_ALARM_LIST: `${apiPath.ETC}/v2/notified`,

  /**
   * 알림 읽기
   * @method PUT
   */
  PUT_ALARM_READ: `${apiPath.ETC}/v2/notified`,

  /**
   * 알림 삭제
   * @method DELETE
   */
  DELETE_ALARM: `${apiPath.ETC}/v2/notified`,

  /**
   * 일일 챌린지 정보 조회
   * @method DELETE
   */
  FETCH_DAILY_CHALLENGE: `${apiPath.ETC}/v1/daily-challenge`,

  /**
   * 이벤트 - 좋아요 받은 랭킹 리스트 조회
   */
  FETCH_RECOMMENDER_RANK: `${apiPath.ETC}/v1/event/recomends-rank`,

  /**
   * 이벤트 - 사용자별 좋아요 받은 랭킹 조회
   */
  FETCH_USER_RANK: `${apiPath.ETC}/v1/event/recomend`,

  /**
   * 메인 - 메인 배너 목록 조회
   */
  FETCH_MAIN_BANNER: `${apiPath.ETC}/v1/baners/main`,

  /**
   * 메인 - NOVA PICK 목록 조회
   */
  FETCH_MAIN_PICK: `${apiPath.ETC}/v1/baners/pick`,

  /**
   * 배너 목록 조회(공통)
   */
  FETCH_BANNERS: `${apiPath.ETC}/v1/banners`,

  /**
   * LM 펀딩 현황 조회
   */
  FETCH_FUNDING: `${apiPath.ETC}/v1/funding`,

  /**
   * 프리미엄 - 프리미엄 배너 목록 조회
   */
  FETCH_PREMIUM_BANNER: `${apiPath.ETC}/v1/baners/premium`,

  /**
   * 정산 은행 목록 조회
   */
  FETCH_BANKS: `${apiPath.ETC}/v1/banks`,

  /**
   * 푸시 알림음 목록 조회
   */
  FETCH_NOTIFICATION_PUSH_SOUNDS: `${apiPath.ETC}/v1/push/sounds`,

  /**
   * 푸시 알림 설정
   */
  UPDATE_NOTIFICATION_PUSH: `${apiPath.ETC}/v1/push`,
}
