/**
 * 크리에이터 등급
 */
export const creatorGrade = {
  default: '크리에이터',
  bronze: '브론즈',
  silver: '실버',
  gold: '골드',
  business: '브랜드·프로젝트',
}
