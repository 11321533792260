<script setup lang="ts">
import type { NovaKycContentDuplicateProps } from './NovaKycContentDuplicate.types'

defineProps<NovaKycContentDuplicateProps>()

const { t } = useI18n()
const descriptions = computed(() => [
  `<span>${t('kyc.unAuthKyc.cs', {
    cs: `<a href="mailto:${t('corporationInfo.infos.email')}">${t(
      'corporationInfo.infos.email'
    )}</a>`,
  })}</span>`,
])
</script>

<template>
  <div class="guide">
    <div
      v-dompurify-html="t(`kyc.unAuthKyc.duplicate.primaryCopy`)"
      class="primary-copy"
    />

    <div
      v-dompurify-html="t('kyc.unAuthKyc.duplicate.secondaryCopy')"
      class="secondary-copy"
    />

    <div class="guide-item">
      <span class="label">{{ t(`kyc.unAuthKyc.duplicate.guide`) }}</span>
    </div>

    <NovaDescriptions class="descriptions" :descriptions="descriptions" />
  </div>
</template>

<style lang="scss" scoped>
.guide {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 12px;

  .primary-copy {
    @include text-style($text-display-bold);
    color: $color-primary-orange;
  }

  .secondary-copy {
    @include text-style($text-display-medium);
    color: $color-black;
  }

  .guide-item {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 12px;
    border-radius: 6px;
    background-color: $color-gray-1;
    @include text-style($text-body-12-regular);
    color: $color-text-2;
  }

  .descriptions {
    margin-top: 10px;
  }
}
</style>
