import * as semver from 'semver'

/**
 * 시맨틱 버전 체크
 * @description https://semver.org
 * @param version
 */
const checkVersion = (version: string) => semver.valid(version) !== null

/**
 * 시맨틱 버전 강제 업데이트 체크
 * @description https://semver.org
 * @param currentVersion
 * @param forceUpdateVersion
 */
const checkForceUpdateVersion = (
  currentVersion: string,
  forceUpdateVersion: string
) => {
  // 비교 표현식 줄임말
  // eq - equal ( = )
  // ne - not equal ( <> )
  // lt - little ( < )
  // lte - little or equal ( <= )
  // gt - greater ( > )
  // gte - greater or equal ( >= )
  return semver.gte(currentVersion, forceUpdateVersion)
}

export const useCheckVersion = () => {
  return {
    checkVersion,
    checkForceUpdateVersion,
  }
}
