import apiPath from '@store/apiPath'

export default {
  /**
   * 서비스 신규가입
   * @method POST
   */
  POST_SIGNUP: `${apiPath.USER}/v3/signup`,

  /**
   * Auth Token 발급
   * @method POST
   */
  POST_SIGNIN: `${apiPath.USER}/v2/signin`,

  /**
   * 엑세스 토큰 갱신
   * @method POST
   */
  POST_ACCESS_TOKEN_REFRESH: `${apiPath.USER}/v1/refresh`,

  /**
   * 사용자 정보 조회
   * @method GET
   */
  FETCH_USER_COMMON_INFO: `${apiPath.USER}/v1/commInfo`,

  /**
   * 알림 설정 변경
   * @method PUT
   */
  PUT_NOTIFICATION_SETTINGS: `${apiPath.USER}/v1/{userId}/notification`,

  /**
   * 알림 목록 조회
   * @method GET
   */
  FETCH_NOTIFICATIONS: `${apiPath.USER}/v1/{userId}/notification`,

  /**
   * 읽지 않은 알림 여부 조회
   * @method GET
   */
  FETCH_UNREAD_NOTIFICATION: `${apiPath.USER}/v1/{userId}/unread-notification`,

  /**
   * 사용자 회원 가입
   * @method POST
   */
  REQ_USER: `${apiPath.USER}/v1`,

  /**
   * 회원 탈퇴 : 탈퇴 체크
   * @method GET
   */
  CHECK_RETIRE_USER: `${apiPath.USER}/v1/withdraw`,

  /**
   *  회원 탈퇴 : 유저 탈퇴 진행
   * @method POST
   */
  SUBMIT_RETIRE: `${apiPath.USER}/v1/withdraw`,

  /**
   *  회원 탈퇴 : 탈퇴 문의글 남기기
   * @method POST
   */
  SEND_RETIRE_INQUIRE: `${apiPath.USER}/v1/withdraw/email`,

  /**
   * 회원 탈퇴 : 비밀번호 체크
   * @method POST
   */
  CHECK_RETIRE_PASSWORD: `${apiPath.USER}/v1/withdraw/password`,

  /**
   * 프라이빗 키 발급
   * @method GET
   */
  FETCH_PRIVATE_KEY: `${apiPath.USER}/v1/private-key/{userSn}`,

  /**
   * 레퍼럴 코드로 프로필 정보 조회
   */
  FETCH_PROFILE_BY_REFERRAL_CODE: `${apiPath.USER}/v1/referralCode`,

  /**
   * 마케팅 동의 여부 변경
   */
  PUT_MARKETING_AGREE_SETTINGS: `${apiPath.USER}/v1/marketingAgreeAt`,

  /**
   * KYC 인증 상세(상태) 조회
   */
  FETCH_KYC_STATUS: `${apiPath.USER}/v1/kyc`,

  /**
   * 휴대전화 번호 수정
   */
  UPDATE_PHONE_NUMBER: `${apiPath.USER}/v1/mobile`,
}
