import { isNumber } from 'helper-js'
import { RESPONSIVE_SIZE } from '@configs'

type ResponsiveKey = keyof typeof RESPONSIVE_SIZE

/**
 * window 내부 너비를 기반으로 현재 반응형 키를 반환
 * @return {ResponsiveKey} - 반응형 키
 * @throws {Error} - 서버 측에서 호출 될 경우
 */
export const useGetResponsive = (): ResponsiveKey => {
  if (process.client) {
    const { innerWidth } = window

    return (
      (Object.keys(RESPONSIVE_SIZE) as Array<ResponsiveKey>).find((key) => {
        const value = RESPONSIVE_SIZE[key as ResponsiveKey]
        return isNumber(value) && value >= innerWidth
      }) || 'desktop'
    )
  } else {
    throw new Error('useGetResponsive call in server')
  }
}
