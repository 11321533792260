<script setup lang="ts">
import type { NovaKycContentPendingProps } from './NovaKycContentPending.types'

defineProps<NovaKycContentPendingProps>()

const { t } = useI18n()
const cs = computed(() =>
  t('kyc.unAuthKyc.cs', {
    cs: `<a class="text-color-primary-blue" href="mailto:${t(
      'corporationInfo.infos.email'
    )}">${t('corporationInfo.infos.email')}</a>`,
  })
)
</script>

<template>
  <div class="guide">
    <div
      v-dompurify-html="t(`kyc.unAuthKyc.pending.primaryCopy`)"
      class="primary-copy"
    />

    <div
      v-dompurify-html="t(`kyc.unAuthKyc.pending.secondaryCopy.${purpose}`)"
      class="secondary-copy"
    />

    <div class="guide-item">
      <span class="label">{{ t(`kyc.unAuthKyc.pending.guide`) }}</span>
    </div>

    <NovaLoadingIndicator
      :size="80"
      :bg-bright="'light'"
      :theme="'ios'"
      class="loading-indicator"
    />

    <p v-dompurify-html="cs" class="description" />
  </div>
</template>

<style lang="scss" scoped>
.guide {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 12px;

  .primary-copy {
    @include text-style($text-display-medium);
    color: $color-black;
  }

  .secondary-copy {
    @include text-style($text-display-medium);
    color: $color-black;
  }

  .guide-item {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 12px;
    border-radius: 6px;
    background-color: $color-gray-1;
    @include text-style($text-body-12-regular);
    color: $color-text-2;
  }

  .loading-indicator {
    min-height: 120px;
    height: 120px;
  }

  .description {
    @include text-style($text-body-10-regular);
    color: $color-text-2;
  }
}
</style>
