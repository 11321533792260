<script setup lang="ts">
import type { ScrollStatus, NovaFloatingProps } from './NovaFloating.types'
import { ROUTES } from '@configs'

const defaultBottomPosition = 16
const props = withDefaults(defineProps<NovaFloatingProps>(), {
  bottomPosition: defaultBottomPosition,
})

const userStore = useUserStore()
const lastScrollTop = ref(0)
const scrollStatus = ref<ScrollStatus>({
  scrollTop: 0,
  direction: null,
})
const show = computed(() => scrollStatus.value.scrollTop > 0)
const scrollEnd = ref(false)
const bottom = computed(
  () => `${scrollEnd.value ? props.bottomPosition : defaultBottomPosition}px`
)
const showCreatPostButton = computed(() => {
  const routeName = useGetRouteName()

  if (
    routeName.includes(ROUTES.NOVAPLUS.name) &&
    userStore.user?.cmtyUserSeCode === 'C'
  ) {
    return true
  } else if (routeName.includes(ROUTES.FEED.name)) {
    return true
  } else {
    return false
  }
})

onMounted(async () => {
  await nextTick()
  window.addEventListener('scroll', calcScrollDirectionDetect)
})

onUnmounted(() => {
  window.removeEventListener('scroll', calcScrollDirectionDetect)
})

const calcScrollDirectionDetect = () => {
  const scrollTop = window.scrollY || 0

  if (scrollTop > lastScrollTop.value) {
    scrollStatus.value = {
      scrollTop,
      direction: 'down',
    }
  } else if (scrollTop < lastScrollTop.value) {
    scrollStatus.value = {
      scrollTop,
      direction: 'up',
    }
  }

  if (scrollTop === 0) {
    scrollStatus.value = {
      scrollTop,
      direction: null,
    }
  }

  scrollEnd.value =
    window.innerHeight + window.scrollY >= document.body.scrollHeight

  lastScrollTop.value = scrollTop <= 0 ? 0 : scrollTop
}
</script>

<template>
  <div :class="['floating-button-wrap', { on: show }]" :style="{ bottom }">
    <NovaFloatingScrollToTopButton :show="show" />

    <ClientOnly>
      <NovaBoxPostCreateGate
        v-if="showCreatPostButton"
        :show="show"
        :show-in="'floating'"
        :popover-position="'middle-bottom-end'"
      />
    </ClientOnly>
  </div>
</template>

<style lang="scss" scoped>
.floating-button-wrap {
  position: fixed;
  right: 16px;
  bottom: 16px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 12px;
  align-items: center;
  justify-content: center;
  z-index: $z-index-scroll-top-button;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;

  @include mobile {
    &.on {
      opacity: 1;
      pointer-events: auto;
    }
  }
}
</style>
