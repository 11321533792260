import { commonError } from './commonError'
import type { ApplyCreatorStatus, ApplyCreatorConditionType } from '@store'
import type { BenefitKey } from '@pages/apply-creator/index.vue'

/**
 * 크리에이터 신청하기
 */
export const applyCreator = {
  entry: 'Creator apply',
  guide: {
    header: {
      target: '{nickname},',
      title: 'Apply to become a creator',
      description:
        'Receive benefits such as profit opportunity, creator support, and more.',
      benefit:
        'Anyone who has its <strong>own content competitiveness<strong></strong> at <strong>{appName}</strong>!<br />You can become a <strong>Web3 creator</strong> and engage in creative activities with a variety of benefits.<br /><br />Everyone with continuous content production capabilities, attractive and unique content and who has something to talk about their own specialties is welcome.',
    },
    benefits: {
      label: 'Various benefits',
      contents: {
        weekly: {
          label: 'Weekly reward',
          description: 'Larger rewards pool to creators only',
        },
        fix: {
          label: 'Monthly Bonus Reward!',
          description:
            'Additional bonus rewards to creators who contributed to community revitalization with excellent content and active communication every month!',
        },
        /* meeting: {
          label: 'Apply authority for Web3 Creator Fund',
          description:
            "Support LM token worth 10 million won for the creator's project selected through voting",
        }, */
        nft: {
          label: 'Opportunity for minting NFT',
          description:
            'Provide an opportunity to mint NFT when working on your project!',
        },
        communication: {
          label: 'Communication with Fandom',
          description:
            'Support Chat and Live feature between creators and fans',
        },
      } as { [Property in BenefitKey]: { label: string; description: string } },
    },
    requiredConditions: {
      label: 'Requirement',
      kyc: 'KYC Verification',
    },
    optionalConditions: {
      label: 'Choose the apply method',
      contents: {
        CUAR: 'Submit more than 3 representative contents',
        CUFW: 'Have more than 10,000 followers on other platforms (Instagram, YouTube, TikTok, etc.)',
      } as { [Property in ApplyCreatorConditionType]: string },
    },
    previewHomeLink: {
      label: 'Home page link',
      action: 'Duplicate check',
      message: {
        failLength: 'Please enter at least {min} characters.',
        failDuplicate: 'Link is already being used by another creator.',
        failCharacter: 'Please enter only English + numbers.',
        failDefault: 'Unable to duplicate check. Please try again later.',
        success: 'You can use this link.',
      },
    },
    notices: {
      label: 'Precautions',
      contents: [
        "If you use another person's social media account or content illegally, the applicant is responsible for all legal and financial losses.",
        'All content uploaded to I LIKE LM must comply with copyright law.',
        'The results of the review of the creator application will be notified within 3 business days.',
        'The review is conducted according to internal standards, and users who have been rejected can re-apply from 30 days after the application date.',
        "The requirements, benefits, and rating maintenance conditions for the above creator apply method may change or be suspended depending on the community's operations.",
      ],
    },
    apply: {
      status: {
        available: 'Available for application', // 추상적인 상태는 존재하지만 노출하지 않음
        approved: 'Approved',
        underReview: 'Under review',
        reject: 'Rejected',
      } as { [Property in ApplyCreatorStatus]: string },
      gateStatus: {
        underReview: 'Under review',
        confirm: 'Result',
      },
      action: {
        apply: 'Apply Creator',
        review: 'Request',
        underReview: 'Reviewing',
      },
      notificationTitle: 'Notification',
      message: {
        failAlreadyCreator: 'Your grade is already a creator.',
        failUnknown: 'You are an unregistered member.',
        failUser: 'This user cannot apply.',
        failCondition: 'You do not meet the creator application requirements.',
        failCreatorLink: 'This is the creator link that needs confirmation.',
        failDefault:
          'Your application could not be completed. Please try again later.',
        failReject:
          "{userNcnm}'s creator application has been rejected.<br />You can reapply 30 days after the application date.",
        failUnderReview:
          "{userNcnm}'s creator application is under review.<br />Results will be notified within 3 business days.",
        successApply:
          'Creator application has been completed.<br />Results will be notified within 3 business days.',
        errors: {
          4014: 'The user is not eligible to apply.',
          4016: 'The code needs to be verified.',
          4018: 'The Creator home address already in use.',
          default: commonError.network.message,
        },
      },
    },
  },
  requireKycDialog: {
    title: 'Notifications',
    message: 'You must complete KYC verification to apply for the creator.',
    confirm: 'Verify KYC',
  },
  // 크리에이터 신청: 대표 콘텐츠 3개
  conditionsContent: {
    pageTitle: 'Creator application in progress',
    sections: {
      activity: {
        title:
          'Write a simple plan for the contents activity as {appName} creator.',
        titleSub: '(more than {count} characters)',
        textField: {
          placeholder: '',
          error: 'Please enter at least {count} characters.',
        },
      },
      info: {
        title: 'Information for apply',
        representative: 'Submit representative content ({count} or more)',
        contentsLink: 'Attach Content Links',
        contentsPlaceholder: {
          CLK: 'Please enter the content link.',
          CAT: 'Please upload the content file.',
        },
        contentsFile: 'Attach content files',
        contentsEmpty: 'Please add the content by clicking on the "+" button.',
        error: 'Enter more than {count} representative contents.',
        contentsLinkEmpty: 'Please enter the content link.',
        contentsLinkError: 'This is not a valid content link.',
        contentsLinkNotMatch: 'Please enter the post link you uploaded.',
        contentsLinkNotPostOwner: 'The post is not a post you uploaded.',
        contentsFileEmpty: 'Please upload the content file.',
        contentsDuplicate: 'The content already registered one.',
        descriptions: [
          'Please select a link or file to submit. (at least 3 and up to 5)',
          'You can upload jpg, png, mp4, word files, and so on.',
          'Press "Register" to add a content.',
          'Press "Delete" to remove a content.',
        ],

        sns: 'Social Media Address',
        snsSubTitle: '(Have more than 10,000 followers)',
        snsPlaceholder: 'Enter the Social media address.',
        snsErrMsg: 'This Social media address is invalid.',
        verifiedSnsAccount: 'I confirm that this is my own account.',
      },
      creatorCategory: {
        title: 'Select a creator category.',
        placeholder: 'Select Category',
        error: 'Select a category.',
      },
      creatorHomeLink: {
        title: 'Set Your Creator Home Address',
        checkDuplicate: 'Duplication check',
        checkedDuplicate: 'Confirmed',
        errors: {
          unavailableCreatorHomeLink:
            'Invalid characters in the text. Please use another Creator Home link.',
          checkDuplicate: 'Please check for duplication.',
          failLength: 'Please enter at least {min} characters.',
          failDuplicate: 'The address is already in use.',
          failCharacter: 'Please enter only English + numbers.',
          failDefault:
            'Cannot check for duplication now. Please try again later.',
        },
        success: 'The address can be used.',
      },
      howToKnow: {
        title: 'How did you hear about {appName}?',
        placeholder: 'Ex) Instagram Ads, Referral etc.',
      },
    },
    apply: 'Request',
  },
}
