<script setup lang="ts">
import type { RouteParams } from 'vue-router'
import type { EnterLiveAvailable } from '@store'

defineOptions({
  inheritAttrs: false,
})

const { hide: modalHide } = useModal()
const { t } = useI18n()
const show = ref(false)
const enterLiveAvailable = ref<EnterLiveAvailable>()
const cause = computed(() =>
  enterLiveAvailable.value
    ? enterLiveAvailable.value.colctNmList.map((item) => `${item} Holder`)
    : []
)

// 모달 오픈전 사전 작업 수행
const handleOnBeforeOpen = (evt: RouteParams) => {
  enterLiveAvailable.value = evt.value as unknown as EnterLiveAvailable
}

// 팝업 닫기
const handleOnClose = async () => {
  await modalHide(modalsName.MODAL_NOT_AVAILABLE_ENTER_LIVE)
}
</script>

<template>
  <VueFinalModal
    v-model="show"
    :name="modalsName.MODAL_NOT_AVAILABLE_ENTER_LIVE"
    :content-class="'modal-content'"
    classes="modal-container"
    @before-open="(evt: any) => handleOnBeforeOpen(evt.ref.params)"
    @click-outside="handleOnClose"
  >
    <ClientOnly>
      <NovaBoxBase v-if="show" class="modal-box">
        <div class="modal-header">
          <NovaButtonIcon
            :icon="{ type: 'outline', name: 'close-extend' }"
            :theme="'transparent'"
            :size="20"
            class="btn-close"
            @click="handleOnClose"
          />
        </div>

        <NovaLayoutScrollContainer class="modal-body">
          <div class="inner">
            <h4
              v-dompurify-html="t('sendbird.enterLiveEvent.modal.message')"
              class="message"
            />

            <span class="badge">
              {{ t('sendbird.enterLiveEvent.modal.description') }}
            </span>

            <div class="cause-list">
              <p class="cause-list-title">
                {{ t('sendbird.enterLiveEvent.modal.cause') }}
              </p>

              <p v-for="(item, index) in cause" :key="index" class="cause-item">
                {{ item }}
              </p>
            </div>
          </div>
        </NovaLayoutScrollContainer>
      </NovaBoxBase>
    </ClientOnly>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
:deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.vfm--overlay) {
  background-color: hex-to-rgba($color-black, 0.65);
}

:deep(.modal-content) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: none;

  .modal-box {
    width: 100%;
    height: auto;
    max-width: 500px;
    max-height: calc(100% - 24px - 24px);
    padding: 0;
    pointer-events: auto;
    overflow: hidden;

    .modal-header {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 12px 16px;
      color: $color-text-2;
      background-color: $color-bg-3;

      .title {
        @include text-style($text-display-bold);
      }
    }

    .modal-body {
      .inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 20px 20px;

        .message {
          font-size: 20px;
          font-weight: 700;
          line-height: 28.96px;
          text-align: center;
          color: $color-text-2;
        }

        .badge {
          font-size: 12px;
          font-weight: 700;
          line-height: 14.52px;
          margin-top: 6px;
          color: $color-white;
          padding: 5px 8px;
          border-radius: 5px;
          background-color: $color-bg-custom-13;
        }

        .cause-list {
          display: flex;
          flex-direction: column;
          gap: 6px;
          width: 100%;
          margin-top: 16px;

          .cause-list-title {
            text-align: center;
            font-weight: 700;
          }

          .cause-item {
            display: flex;
            justify-content: center;
            padding: 16px;
            background-color: $color-bg-3;
            border-radius: 12px;
            border: 1px solid $color-primary-blue;
            font-weight: 600;
            color: $color-primary-blue;
            transition: all 0.16s ease-in-out;

            &:hover {
              transform: translateY(-4px);
              box-shadow: 0 6px 12px hex-to-rgba($color-black, 0.3);
            }
          }
        }
      }
    }

    @include mobile {
      flex-grow: 1;
      border-radius: 0 !important;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
    }
  }
}
</style>
