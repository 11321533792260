import * as CommType from './type'
import CommApi from './url'
import { api } from '@api'
import type { FileImageContainer, FileVideoContainer } from '@store/types'

export default {
  /**
   * 외부 URL 클롤링
   * @param payload
   */
  fetchCrawling(payload: CommType.CrawlingPayload) {
    return api.get<string>(CommApi.FETCH_CRAWLING, { params: payload })
  },

  /**
   * 크리에이터 팔로우
   * @param payload
   */
  reqFollow(payload: CommType.FollowPayload) {
    return api.post<{}>(CommApi.REQ_FOLLOW, payload)
  },

  /**
   * 크리에이터 언팔로우
   * @param payload
   */
  reqUnfollow(payload: CommType.UnfollowPayload) {
    return api.post<{}>(CommApi.REQ_UNFOLLOW, payload)
  },

  /**
   * 크리에이터 후원하기
   * @param payload
   */
  reqCreatorDonate(payload: CommType.CreatorDonatePayload) {
    return api.post<{}>(CommApi.REQ_CREATOR_DONATE, payload)
  },

  /**
   * 홈 URL 조회
   * @param payload
   */
  fetchHomeUrl(payload: CommType.HomeUrlPayload) {
    return api.get<string>(CommApi.FETCH_HOME_URL, { params: payload })
  },

  /**
   * 파일 등록: 이미지(멀티)
   * @param payload
   */
  uploadFileImages(payload: FormData) {
    return api.post<FileImageContainer>(CommApi.UPLOAD_FILE_IMAGES, payload)
  },

  /**
   * 파일 등록: 비디오
   * @param payload
   */
  uploadFileVideo(payload: FormData) {
    return api.post<FileVideoContainer>(CommApi.UPLOAD_FILE_VIDEO, payload)
  },

  /**
   * 앱 버전 조회
   */
  fetchAppVersion() {
    return api.get<CommType.AppVersion>(CommApi.FETCH_APP_VERSION)
  },

  /**
   * 사용자 차단
   */
  reqBlockingUser(payload: CommType.BlockingUserPayload) {
    return api.post(CommApi.REQ_BLOCKING_USER, payload)
  },

  /**
   * 사용자 차단 해제
   */
  reqUnBlockingUser(payload: CommType.UnBlockingUserPayload) {
    return api.post(CommApi.REQ_UN_BLOCKING_USER, payload)
  },
}
