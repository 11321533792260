import type { ScopeCodeType } from '@store/post/type'

/**
 * 포스트 발행(수정)
 */
export const postCreate = {
  type: {
    normal: '일반 포스트',
    card: '카드형 포스트',
    vod: '영상형 포스트',
  },
  typeDesc: {
    normal: '사진과 텍스트를 자유롭게 배치할 수 있는 포스트',
    card: '사진을 카드형태로 넘겨보는 카드형 포스트',
    vod: '동영상파일을 첨부해 올리는 영상형 포스트',
  },
  createAvailableCount:
    '한 주 동안 [크리에이터 콘텐츠] 게시판에 작성할 수 있는  포스트 개수입니다. (남은 포스트 수/최대 포스트 수)',
  placeholder: {
    public: '전체공개',
    tempSave: '임시저장 불러오기',
    category: '게시판 선택',
    title: '제목을 입력해주세요.',
    content: '내용을 입력해주세요.(필수)',
    hashtag: '#해시태그입력',
    edit: {
      requireCategory: '게시판을 먼저 선택하세요.',
      creatorContents: {
        p18: '안녕하세요. Web3 크리에이터님,',
        p15: '매력적이고 개성 넘치는 자신만의 콘텐츠를 공유해 주세요.<br />내가 창작한 그림, 음악, 영상, 글 등 다양한 콘텐츠를 게시하고 팬들과 자유롭게 소통할 수 있습니다.',
        p12: '-게시판 성격에 부합하지 않는 포스트의 경우, 신고 누적 혹은 관리자 판단 하에 무통보 노출제한될 수 있습니다.<br/>-중복이거나 타인의 콘텐츠를 도용한 포스트의 경우, 엄격히 규제되며 영구적으로 이용이 제한될 수 있습니다.',
      },
      free: '-도배, 도용, 불법 등 서비스 운영정책을 위반한 글은 엄격히 규제되며 영구적으로 이용제한이 될 수 있습니다.',
      novaChallenge:
        '당신의 이야기를 들려주세요!<br /><br />정해진 기간 내에 I LIKE LM에서 제시한 주제에 맞춰 정성스럽게 글을 작성해 주세요.<br/>(이벤트 회차는 주기적으로 공지될 예정이며 피드에서 I LIKE LM 상단고정 게시물로 확인하실 수 있습니다.)<br /><br />-노바 챌린지 카테고리에 챌린지와 상관없는 포스트 작성 시 무통보 노출제한될 수 있습니다.',
    },
  },
  button: {
    tempSave: '임시저장',
    preview: '미리보기',
    save: '게시',
    confirm: '확인',
  },
  postScope: {
    PUBL: '전체공개',
    PRIV: '나만보기',
    // PAID: '유료',
  } as { [Property in ScopeCodeType]: string },
  toastMessage: {
    tempSave: '임시저장이 되었습니다.',
    noLinkSelect: '링크할 텍스트를 선택해주세요.',
    havingLink: '이미 링크지정이 되었습니다.',
    validateCategory: '게시판을 선택해주세요.',
    validateTitle: '제목을 2자 이상 써주세요.',
    validateContents: '내용을 15자 이상 써주세요.',
    validateHashtagCharacter: '한글, 영문, 숫자만 입력 가능합니다.',
    validateHashtagCheckByte: '최대 30자까지 입력 가능합니다.',
    validateHashtagCount: '태그는 최대 5개까지 입력 가능합니다.',
    validateHashtagDuplicate: '중복된 태그가 있습니다.',
    imgError2005: '등록할 수 없는 확장자 입니다.',
    imgError: '이미지 업로드에 실패했습니다. 잠시후 다시 시도해주세요.',
    errors: {
      2011: '프리미엄 포스트를 발행할 수 없습니다. 먼저 프리미엄을 개설해 주세요.',
      2015: '허용 가능한 해시태그 갯수를 초과했습니다. 다시 시도해 주세요.',
      2016: '허용 가능한 이미지 파일 갯수를 초과했습니다. 다시 시도해 주세요.',
      2017: '허용 가능한 제목 글자수가 아닙니다. 다시 시도해 주세요.',
      2018: '허용 가능한 해시태그 글자수를 초과했습니다. 다시 시도해 주세요.',
      2019: '임시 이미지 파일을 찾을 수 없습니다. 다시 시도해 주세요.',
      2036: '해당 게시판에 포스트를 게시 할 수 있는 권한이 없습니다.',
      8048: '일정 시간 내에 동일 활동을 반복할 수 없습니다.',
      8049: '주간 최대 포스트 게시 가능 개수를 초과하였습니다.',
      default: '요청 처리중 오류가 발생하였습니다. 잠시후 다시 시도해주세요.',
    },
    validateLinkDuplicate: '중복된 링크가 있습니다.',
    availableLink: '사용가능한 링크 입니다.',
    nolastFileDelete: '마지막 파일은 삭제할 수 없습니다.',
    createPostSuccess: '"{postTitle}" 포스트를 발행했습니다.',
    editPostSuccess: '"{postTitle}" 포스트를 수정했습니다.',
    createPostUnavailable: {
      2011: '알수 없는 게시판입니다.',
      2036: '해당 게시판에 포스트를 게시 할 수 있는 권한이 없습니다.',
    },
  },
  modal: {
    tempTitle: '임시저장 목록',
    previewTitle: '미리보기',
    dialogTitle: '삭제 확인',
    dialogContent:
      '임시 저장되지 않은 상태에서 닫을 경우 입력하신 정보가 삭제됩니다. 저장하지 않고 삭제할까요?',
  },
}
