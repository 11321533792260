/**
 * 레퍼럴 회원가입
 */
export const referral = {
  signUp: {
    dialog: {
      referralNotAble: {
        title: '추천인으로 회원가입 할 수 없습니다.',
        description:
          '이미 회원가입 하셨습니다. 로그아웃 후 회원가입을 진행해 주세요.',
        action: {
          positive: '확인',
        },
      },
      referralAble: {
        title: '추천인으로 회원가입',
        description: '추천인으로 회원가입을 완료해 주세요.',
        mobileGuide:
          '아이폰에서 접속하신 경우 사파리의 설정에서 팝업 차단을 해제해주시기 바랍니다.',
      },
    },
    error: {
      title: '찾을 수 없는 사용자',
      message:
        '추천인으로 등록할 수 없는 사용자입니다.<br />사용자를 확인 후 다시 이용해 주세요.',
    },
  },
  clipboard: {
    tooltip: '추천인 링크 복사',
    toastMessage: {
      success: '클립보드에 추천인 링크가 복사되었습니다.',
    },
  },
  edit: {
    label: '추천인 링크',
    tooltip: '수정',
    toastMessage: {
      success: '추천인 링크가 변경되었습니다.',
      failDefault:
        '요청 처리중 오류가 발생하였습니다. 잠시후 다시 시도해주세요.',
      failEqual:
        '기존 추천인 링크와 이름이 같습니다. 다른 추천인 링크를 입력하세요.',
      failUpdate: '추천인 링크를 이미 변경하였습니다.',
      failDuplicate: '이미 다른 사용자가 사용중인 추천인 링크입니다.',
      failLength: '최소 {min} 글자수 이상을 입력해 주세요.',
      failCharacter: '영문 + 숫자만 입력해 주세요.',
      unavailableRecommendLink:
        '사용할 수 없는 문자가 포함되어 있습니다. 다른 추천인 링크를 사용해 주세요.',
      checkDuplicateBeforeSave: '중복체크 후 저장해 주세요.',
    },
  },
}
