<script setup lang="ts">
import type { NovaBadgeLiveActionProps } from './NovaBadgeLiveAction.types'

const props = withDefaults(defineProps<NovaBadgeLiveActionProps>(), {
  availableClick: false,
  onAir: false,
  tiny: false,
})

const { gtEvent } = useGoogleTag()
const userStore = useUserStore()
const { checkCreateLiveAvailable } = useSendbirdGate()
const loading = ref(false)
const { t } = useI18n()

const toggleLoading = (_loading: boolean) => {
  loading.value = _loading
}

const handleOnClick = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: 'LIVE 버튼 클릭',
    eventLabel: props.onAir ? 'On air' : 'LIVE',
    eventSlot: '크리에이터 페이지 탑섹션 LIVE 버튼',
    eventI18nAddr: '',
    eventComponent: 'Button',
  })
  if (!props.availableClick) return

  if (!userStore.user) return

  if (props.onAir) {
    useToast(t('sendbird.createLiveEvent.errors.alreadyOnAir'))
    return
  }

  checkCreateLiveAvailable({
    userId: userStore.user.sndbrdUserId!,
    onLoading: toggleLoading,
  })
}
</script>

<template>
  <button
    type="button"
    :class="[
      'badge-live',
      { 'available-click': availableClick },
      { 'on-air': onAir },
      { tiny },
      { loading },
    ]"
    @click="handleOnClick"
  >
    <span class="label">{{ onAir ? 'On air' : 'LIVE' }}</span>
    <NovaIcon
      v-if="!onAir"
      class="icon"
      :icon="{ type: 'outline', name: 'live' }"
    />
    <NovaLoadingIndicator v-if="loading" :bg-bright="'dark'" :fill="true" />
  </button>
</template>

<style lang="scss" scoped>
.badge-live {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  width: 100px;
  height: 40px;
  border-radius: 10px;
  background-color: $color-bg-custom-9;
  font-size: 0;
  cursor: default;

  &.available-click {
    cursor: pointer;
  }

  &.on-air {
    background-color: $color-bg-custom-12;
  }

  &.tiny {
    width: 54px;
    height: 24px;
    border-radius: 4px;

    > .label {
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;
    }
  }

  &.loading {
    pointer-events: none;

    > .label,
    > .icon {
      opacity: 0.4;
    }
  }

  > .label {
    font-size: 25px;
    font-weight: 500;
    line-height: 1.2;
    color: $color-white;
    transition: opacity 0.2s ease-in-out;
  }

  > .icon {
    font-size: 23px !important;
    color: $color-white;
    transition: opacity 0.2s ease-in-out;
  }

  @include mobile {
    width: 80px;
    height: 32px;

    > .label {
      font-size: 20px;
    }

    > .icon {
      font-size: 20px !important;
    }
  }

  :deep(.loading-indicator) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
