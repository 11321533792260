<script setup lang="ts">
import { watchOnce } from '@vueuse/core'
import { initBridgeToWeb, bridgeToApp } from '@bridge'
import {
  initFirebase,
  FirebaseException,
  FirebaseExceptionStatus,
} from '@firebaseModule'
import { ROUTES } from '@configs'

const route = useRoute()
const appStore = useAppStore()
const { openMarketingPushAgreeModal } = useMarketingPushAgree()
const isInitFirebase = ref(false)

watch(
  () => appStore.appType,
  (cur) => {
    // iOS 일때 바운스 이펙트 막음
    if (cur === 'IOS') {
      document.body.classList.add('app-mode-ios')
    }
  }
)

watch(
  () => [route.name, isInitFirebase.value],
  async ([curRouteName, curIsInitFirebase], [prevRouteName]) => {
    if (curRouteName !== prevRouteName && curIsInitFirebase) {
      await openMarketingPushAgreeModal()
    }
  }
)

watch(
  () => appStore.appStatus,
  async (cur) => {
    if (cur === 'notification') {
      await initFirebaseProcessWithNotification()
    }
  }
)

// 앱 랜딩시 FCM 초기화를 하지 않고 리다이렉트 된 후 FCM 초기화가 필요할때 최초 한번만 초기화
// 최초 앱 랜딩시에는 ROUTE > noRequireAppInit true 상태인 라우터는 onMounted 시점에서 초기화 하지 않고 리다이렉트 하는 시나리오
watchOnce(
  () => route.query,
  async (cur) => {
    if (cur.requireAppInit === 'Y' && !isInitFirebase.value) {
      // 브릿지 초기화
      // 네이티브이 브릿지 초기화를 위한 쿠키 세팅이 onBeforeMount 시점보다 늦을 수 있음을 감안하여 onMounted 시점으로 변경
      await initBridgeToWeb()

      // 파이어베이스 초기화
      await initFirebaseProcessWithNotification()

      // 로그인이 일때
      await openMarketingPushAgreeModal()

      // 앱에 인터페이스 받을 준비 되었다고 브릿지 호출
      if (appStore.isApp) {
        bridgeToApp.isReadyToBridge()
      }
    }
  }
)

onMounted(async () => {
  const routeName = useGetRouteName()

  const NO_REQUIRE_APP_INIT_ROUTES = Object.keys(ROUTES)
    .filter((key) => ROUTES[key as keyof typeof ROUTES]?.noRequireAppInit)
    .map((key) => ROUTES[key as keyof typeof ROUTES].name)

  if (
    NO_REQUIRE_APP_INIT_ROUTES.findIndex((name) => name === routeName) !== -1
  ) {
    return
  }

  // 브릿지 초기화
  // 네이티브이 브릿지 초기화를 위한 쿠키 세팅이 onBeforeMount 시점보다 늦을 수 있음을 감안하여 onMounted 시점으로 변경
  await initBridgeToWeb()

  // 파이어베이스 초기화
  await initFirebaseProcessWithNotification()

  // 로그인이 일때
  await openMarketingPushAgreeModal()

  // 앱에 인터페이스 받을 준비 되었다고 브릿지 호출
  if (appStore.isApp) {
    bridgeToApp.isReadyToBridge()
  }
})

// 파이어 베이스 초기화
const initFirebaseProcessWithNotification = async () => {
  try {
    await initFirebase()
    isInitFirebase.value = true
  } catch (err) {
    console.error(
      new FirebaseException({
        message: 'FCM init fail',
        cause: {
          status: FirebaseExceptionStatus.INIT_FAIL,
          data: err,
        },
      })
    )
  }
}
</script>

<template>
  <slot />
</template>
