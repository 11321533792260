<script setup lang="ts">
import type { NovaBoxNotificationSettingStackServiceDetailProps } from './NovaBoxNotificationSettingStackServiceDetail.types'
import type { BooleanYn, NotificationServiceSetting } from '@store'
import { CellGroupTheme } from '@components/NovaHeaderCellGroup/NovaHeaderCellGroup.types'
import { CellItemTheme } from '@components/NovaHeaderCellItem/NovaHeaderCellItem.types'

const { gtEvent } = useGoogleTag()
const { onUpdateNotificationService } =
  defineProps<NovaBoxNotificationSettingStackServiceDetailProps>()

const loadingServiceId = ref<NotificationServiceSetting['ntcnSetupId']>()

const toggleLoadingServiceId = (
  serviceId: NotificationServiceSetting['ntcnSetupId'] | undefined
) => {
  loadingServiceId.value = serviceId
}

const handleOnUpdateNotificationService = (
  serviceId: NotificationServiceSetting['ntcnSetupId'],
  value: boolean,
  label: string
) => {
  const payload = {
    ntcnSetupId: serviceId,
    svcNtcnRecptnSetupAt: (value ? 'Y' : 'N') as BooleanYn,
  }
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: `알림 설정 > 상세설정 > ${label} > ${value ? '켜기' : '끄기'}`,
    eventLabel: label,
    eventSlot: '',
    eventI18nAddr: '',
    eventComponent: 'Toggle',
  })
  onUpdateNotificationService(payload, toggleLoadingServiceId)
}
</script>

<template>
  <NovaHeaderCellGroup :theme="CellGroupTheme.ON_WHITE_BOX">
    <template #cells>
      <NovaHeaderCellItem
        v-for="item in serviceDetail.serviceSetupList"
        :key="item.ntcnSetupId"
        :theme="CellItemTheme.ON_WHITE_BOX"
        :label="item.ntcnNm"
        :contents="item.ntcnBrfDc"
      >
        <template #tools>
          <NovaSwitch
            :model-value="item.svcNtcnRecptnSetupAt === 'Y'"
            :loading="item.ntcnSetupId === loadingServiceId"
            @update="
              (value) =>
                handleOnUpdateNotificationService(
                  item.ntcnSetupId,
                  value,
                  item.ntcnNm
                )
            "
          />
        </template>
      </NovaHeaderCellItem>
    </template>
  </NovaHeaderCellGroup>
</template>
