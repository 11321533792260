<script setup lang="ts">
import { Tippy } from 'vue-tippy'
import type { FormUnitModel } from './NovaBoxAccountSectionBankAccount.types'
import type { Bank } from '@store'
import type { ShowModalSelectBankParams } from '@components/NovaModalSelectBank/NovaModalSelectBank.types'

const { gtEvent } = useGoogleTag()
const { t } = useI18n()
const { show: modalShow } = useModal()
const creatorStore = useCreatorStore()
const editMode = ref(false)
const loading = ref(false)
const bank = reactive<FormUnitModel<Bank | null>>({
  value: null,
  valid: false,
})
const account = reactive<FormUnitModel<string>>({
  value: creatorStore.myProfile?.bnkbAcnutno || '',
  valid: false,
})
const owner = reactive<FormUnitModel<string>>({
  value: creatorStore.myProfile?.bnkbDpstrNm || '',
  valid: false,
})

// event bus
useListen('bank:select', (param) => {
  bank.value = param
})

watch(
  () => creatorStore.myProfile,
  () => {
    initFormUnit()
  }
)

watch(
  () => editMode.value,
  (cur) => {
    if (!cur) {
      initFormUnit()
    }
  }
)

const handleOnToggleEditMode = () => {
  editMode.value = !editMode.value
  gtEvent('userAction', {
    eventCategory: '사용자',
    eventAction: `후원 정산 은행 변경 ${editMode.value ? '열기' : '닫기'}`,
    eventLabel: t('accountPanel.settings.bankAccount.title'),
    eventSlot: '프로필 수정 > 후원 정산 은행',
    eventI18nAddr: useKoreanTranslation(
      'accountPanel.settings.bankAccount.title'
    ),
    eventComponent: 'Button',
  })
}

// 폼 요소 초기화
const initFormUnit = () => {
  bank.value = creatorStore.myProfile?.bicCode
    ? {
        bicCode: creatorStore.myProfile?.bicCode || '',
        bicBankNm: creatorStore.myProfile?.bicBankNm || '',
      }
    : null
  bank.valid = false

  account.value = creatorStore.myProfile?.bnkbAcnutno || ''
  account.valid = false

  owner.value = creatorStore.myProfile?.bnkbDpstrNm || ''
  owner.valid = false
}

const handleOnValid = () => {
  // 1. set valid target
  const validTargets = {
    bank: bank.value,
    account: account.value,
    owner: owner.value,
  }

  // 2. set valid by target
  const valids: string[] = []

  // 3. set valid & message
  Object.keys(validTargets).forEach((key) => {
    let valid = false

    switch (key) {
      case 'bank':
        valid = validTargets.bank === null
        bank.valid = valid
        break
      case 'account':
        valid = !validTargets.account.length
        account.valid = valid
        break
      case 'owner':
        valid = !validTargets.owner.length
        owner.valid = valid
        break
      default:
        break
    }

    if (valid) {
      valids.push(t(`accountPanel.settings.bankAccount.forms.${key}.valid`))
    }
  })

  return valids
}

const handleOnCancel = () => {
  initFormUnit()
  editMode.value = false
  gtEvent('userAction', {
    eventCategory: '사용자',
    eventAction: '후원 정산 은행 변경 닫기',
    eventLabel: t('cancel'),
    eventSlot: '프로필 수정 > 후원 정산 은행',
    eventI18nAddr: useKoreanTranslation('cancel'),
    eventComponent: 'Button',
  })
}

const handleOnSave = async () => {
  const valids = handleOnValid()

  if (valids.length) {
    useToast(valids[0])

    return
  }

  try {
    loading.value = true

    await creatorStore.createCreatorBank({
      ...bank.value!,
      bnkbAcnutno: account.value,
      bnkbDpstrNm: owner.value,
    })

    editMode.value = false
    gtEvent('userAction', {
      eventCategory: '사용자',
      eventAction: '저장 버튼 클릭',
      eventLabel: t('save'),
      eventSlot: '프로필 수정 > 후원 정산 은행 변경 > 저장',
      eventI18nAddr: useKoreanTranslation('save'),
      eventComponent: 'Button',
    })
    useToast(
      t('accountPanel.settings.bankAccount.messages.success', {
        subscribeGrade: t('subscribe.subscribeGrade.novaPlus'),
      })
    )
  } catch (err) {
    const status = (err as any).response.status

    switch (status) {
      case 2006:
      case 4010:
        useToast(
          t(`accountPanel.settings.bankAccount.messages.error.${status}`)
        )
        break
      default:
        useToast(t('accountPanel.settings.bankAccount.messages.error.unknown'))
        break
    }
  } finally {
    loading.value = false
  }
}

const handleOnShowModalSelectBank = () => {
  gtEvent('selectEvent', {
    eventCategory: '선택',
    eventAction: '은행 선택 클릭',
    eventLabel: t('accountPanel.settings.bankAccount.forms.bank.placeholder'),
    eventSlot: '프로필 수정 > 후원 정산 은행 변경 > 은행 선택',
    eventI18nAddr: useKoreanTranslation(
      'accountPanel.settings.bankAccount.forms.bank.placeholder'
    ),
    eventComponent: 'Modal',
  })
  modalShow(
    modalsName.MODAL_SELECT_BANK,
    bank.value as ShowModalSelectBankParams
  )
}
</script>

<template>
  <NovaBoxAccountSection :title="t('accountPanel.settings.bankAccount.title')">
    <template #contents>
      <div class="phone-number-wrap">
        <div v-if="!editMode" class="inner">
          <div class="preview">
            <p class="label">
              {{ t('accountPanel.settings.bankAccount.forms.bank.label') }}
            </p>
            <p class="value">{{ bank.value?.bicBankNm || '-' }}</p>
          </div>

          <div class="preview">
            <p class="label">
              {{ t('accountPanel.settings.bankAccount.forms.account.label') }}
            </p>
            <p class="value">{{ account.value || '-' }}</p>
          </div>

          <div class="preview">
            <p class="label">
              {{ t('accountPanel.settings.bankAccount.forms.owner.label') }}
            </p>
            <p class="value">{{ owner.value || '-' }}</p>
          </div>
        </div>

        <div v-else class="inner">
          <NovaFormContainer
            :title="t('accountPanel.settings.bankAccount.forms.bank.label')"
            :guide="t('accountPanel.settings.bankAccount.forms.bank.guide')"
          >
            <template #default>
              <NovaDropdown
                :active-name="bank.value?.bicBankNm || ''"
                :placeholder="
                  t('accountPanel.settings.bankAccount.forms.bank.placeholder')
                "
                :menus="[]"
                :size="32"
                :show-arrow="false"
                @click="handleOnShowModalSelectBank"
              />
            </template>
          </NovaFormContainer>

          <NovaFormContainer
            :title="t('accountPanel.settings.bankAccount.forms.account.label')"
            :guide="t('accountPanel.settings.bankAccount.forms.account.guide')"
          >
            <template #default>
              <NovaInputSimple
                v-model="account.value"
                :full-width="true"
                :readonly="loading"
                :placeholder="
                  t(
                    'accountPanel.settings.bankAccount.forms.account.placeholder'
                  )
                "
              />
            </template>
          </NovaFormContainer>

          <NovaFormContainer
            :title="t('accountPanel.settings.bankAccount.forms.owner.label')"
          >
            <template #default>
              <NovaInputSimple
                v-model="owner.value"
                :full-width="true"
                :readonly="loading"
                :placeholder="
                  t('accountPanel.settings.bankAccount.forms.owner.placeholder')
                "
              />
            </template>
          </NovaFormContainer>

          <div class="actions">
            <NovaButtonText
              :size="32"
              :label="$t('cancel')"
              :theme="'secondary-gray'"
              :loading="loading"
              @click="handleOnCancel"
            />

            <NovaButtonText
              :size="32"
              :label="$t('save')"
              :theme="'primary-blue'"
              :loading="loading"
              @click="handleOnSave"
            />
          </div>
        </div>
      </div>
    </template>

    <template #tools>
      <Tippy
        :append-to="'parent'"
        :content="t('modify')"
        :interactive="true"
        :theme="'simple'"
        :placement="'bottom'"
      >
        <NovaButtonIcon
          :icon="{ type: 'outline', name: 'write' }"
          :size="16"
          :loading="loading"
          class="btn-clipboard"
          @click="handleOnToggleEditMode"
        />
      </Tippy>
    </template>
  </NovaBoxAccountSection>
</template>

<style lang="scss" scoped>
.phone-number-wrap {
  > .inner {
    display: flex;
    flex-direction: column;
    gap: 14px;

    .preview {
      display: flex;
      align-items: center;

      > .label {
        width: 114px;
        @include text-style($text-body-14-bold);
        color: $color-text-1;
      }
    }

    > .actions {
      display: flex;
      justify-content: flex-end;
      gap: 12px;
    }
  }
}
</style>
