<script setup lang="ts">
import type { PopupList } from '@store'
import { ROUTES } from '@configs'

defineOptions({
  inheritAttrs: false,
})
const { cookieNames, getCookie, deleteCookie } = useCookies()
const { hide: modalHide } = useModal()
const show = ref(false)
const userStore = useUserStore()
const bonusRewardMonthlyData = ref<PopupList>({
  popupCn: { bonus: '', detail_msg: '' },
  popupNtcnStdrId: '',
  popupSj: '',
})
const rewardToken = getCookie(cookieNames.REWARD_MONTHLY)

// 월간 리워드 팝업 노출 여부 초기화
const deleteMonthlyRewardCookie = () => {
  deleteCookie(cookieNames.REWARD_MONTHLY)
}
// 팝업 닫기
const handleOnClose = async () => {
  await modalHide(modalsName.MODAL_BONUS_REWARD_MONTHLY)
  await deleteMonthlyRewardCookie()
}

// 마이홈 > 리워드 로 이동
const goMyRewardPage = async () => {
  let routePath
  let routeId
  if (userStore.user?.cmtyUserSeCode === 'C') {
    routePath = ROUTES.CREATOR_REWARD.path
    routeId = userStore.user?.referralCode
  } else {
    routePath = ROUTES.USER_REWARD.path
    routeId = userStore.user?.userNcnm
  }
  await useNavigations({
    url: useRoutePathIdChange(String(routePath), {
      id: String('@' + routeId),
    }),
    type: 'replace',
  })
  await deleteMonthlyRewardCookie()
}

// 마운트 시점에 필요한 데이터 세팅
onMounted(() => {
  if (rewardToken) {
    bonusRewardMonthlyData.value = rewardToken.popupData
  }
})

onUnmounted(() => {
  deleteMonthlyRewardCookie()
})
</script>

<template>
  <VueFinalModal
    v-model="show"
    :name="modalsName.MODAL_BONUS_REWARD_MONTHLY"
    :content-class="'modal-content'"
    classes="modal-container"
    @click-outside="handleOnClose"
  >
    <NovaBoxBase v-if="show" class="modal-box">
      <div class="modal-header">
        <NovaButtonIcon
          :icon="{ type: 'outline', name: 'close-extend' }"
          :theme="'transparent'"
          :size="20"
          class="btn-close"
          @click="handleOnClose"
        />
      </div>

      <NovaLayoutScrollContainer class="modal-body">
        <div class="reward-title">
          <p class="message">{{ bonusRewardMonthlyData.popupSj }}</p>
        </div>
        <div class="reward-lm">
          <p class="message">{{ bonusRewardMonthlyData.popupCn.bonus }}</p>
        </div>
        <div class="reward-icon">
          <p class="message">
            <img src="@/assets/images/rent/reword-icon.jpg?url" alt="Reward" />
          </p>
        </div>
        <div class="reward-get-text">
          <p
            v-dompurify-html="bonusRewardMonthlyData.popupCn.detail_msg"
            class="message"
          ></p>
        </div>
      </NovaLayoutScrollContainer>
      <div class="actions">
        <NovaButtonText
          :label="$t('rewardPopup.monthly.button.name')"
          :theme="'primary-blue'"
          :full-width="true"
          @click="goMyRewardPage"
        />
      </div>
    </NovaBoxBase>
  </VueFinalModal>
</template>

<style scoped lang="scss">
:deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.vfm--overlay) {
  background-color: hex-to-rgba($color-black, 0.65);
}

:deep(.modal-content) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: none;

  .modal-box {
    width: 100%;
    height: auto;
    max-width: 320px;
    min-width: 320px;
    max-height: calc(100% - 24px - 24px);
    padding: 0;
    pointer-events: auto;
    overflow: hidden;

    .modal-header {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 20px 20px 0 20px;
      background-color: $color-bg-3;
    }

    .modal-body {
      display: flex;
      padding: 0 20px 20px 20px;

      .reward-title {
        display: flex;
        justify-content: center;

        .message {
          display: inline-block;
          padding: 3px 8px;
          color: $color-primary-blue;
          font-weight: 600;
          border: 2px solid $color-primary-blue;
          border-radius: 15px;
        }
      }

      .reward-lm {
        display: flex;
        justify-content: center;

        .message {
          font-size: 38px;
          line-height: 52px;
          font-weight: 700;
          letter-spacing: -1px;
          color: $color-black;
        }
      }

      .reward-icon {
        display: flex;
        justify-content: center;

        .message {
          padding: 5px 0;
        }
      }

      .reward-get-text {
        display: flex;
        justify-content: center;
        text-align: center;
        font-size: 14px;
        color: $color-black;
      }
    }

    .actions {
      flex-shrink: 0;
      width: 100%;
      padding: 0 20px 20px;
    }
    @include mobile {
      flex-grow: 1;
      max-width: 320px;
      height: auto;
      max-height: 100%;
    }
  }
}
</style>
