// 크리에이터 전환 가능 상태 코드
export const APPLY_CREATOR_AVAILABLE_STATE_CODE = {
  REQUIRE_SIGNIN: 'requireSignin',
  APPROVED: 'approved',
  AVAILABLE: 'available',
  UNDER_REVIEW: 'underReview',
  REJECT: 'reject',
}

// 크리에이터 전환 상태 코드
export const APPLY_CREATOR_STATE_CODE = {
  IS_CREATOR: 'C',
  IN_REVIEW: ['RQ01', 'RQ02'],
  APPROVED: ['APRV', 'EXPD'],
  REJECT: 'RJCT',
}
