/**
 * 크리에이터 등급
 */
export const creatorHome = {
  statistics: {
    menu: {
      views: 'views',
      follows: 'follows',
      followUnit: '',
      ranking: 'ranking',
      rankingUnit: '',
      reward: 'reward',
      sharedContents: 'Shared contents',
      notice: 'posts',
      video: 'videos',
      popularPosts: 'popular Posts',
      popularVideoPosts: 'popular Video Posts',
    },
    popularPosts: {
      error: {
        empty: "There's no post here",
        error: "It can't be loaded currently. Please try again later",
      },
    },
    popularVideoPosts: {
      error: {
        empty: "There's no post here",
        error: "It can't be loaded currently. Please try again later",
      },
    },
  },
}
