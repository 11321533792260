<script setup lang="ts">
defineOptions({
  inheritAttrs: false,
})

const { gtEvent } = useGoogleTag()
const { hide: modalHide } = useModal()
const { t } = useI18n()
const { createChat } = useSendbirdGate()
const show = ref(false)
const isLoading = ref(false)
const title = ref('')
const titleError = ref('')

watch(
  () => title.value,
  (cur) => {
    if (cur.length) {
      titleError.value = ''
    }
  }
)

// 팝업 닫기
const handleOnClose = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '팬 채팅방 개설 모달 닫기',
    eventLabel: 'close-extend',
    eventSlot: '크리에이터 홈 > 팬 채팅방 개설 모달 > 닫기',
    eventI18nAddr: 'close-extend',
    eventComponent: 'Button',
  })
  await modalHide(modalsName.MODAL_CREATE_OPEN_CHANNEL)
}

// 팝업 닫은후 클리어
const handleOnClosed = () => {
  title.value = ''
  titleError.value = ''
}

// 개설하기
const handleOnCreateOpenChannel = async () => {
  if (!title.value.length) {
    titleError.value = t(
      'sendbird.createChatChannel.modal.sections.channelTitle.errMin'
    )

    return
  }

  if (title.value.length > 50) {
    titleError.value = t(
      'sendbird.createChatChannel.modal.sections.channelTitle.errMax'
    )

    return
  }

  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '팬 채팅방 개설하기 > 개설하기 클릭',
    eventLabel: t('sendbird.createChatChannel.modal.actions.create'),
    eventSlot: '크리에이터 홈 > 팬 채팅방 개설 모달 > 닫기',
    eventI18nAddr: useKoreanTranslation(
      'sendbird.createChatChannel.modal.actions.create'
    ),
    eventComponent: 'Button',
  })
  await createChat({
    mode: 'createOpenChannel',
    createOpenChannelPayload: {
      channelName: title.value,
    },
    onLoading: (loading: boolean) => {
      isLoading.value = loading
    },
  })
  await handleOnClose()
}
</script>

<template>
  <VueFinalModal
    v-model="show"
    :name="modalsName.MODAL_CREATE_OPEN_CHANNEL"
    :content-class="'modal-content'"
    classes="modal-container"
    @click-outside="handleOnClose"
    @closed="handleOnClosed"
  >
    <ClientOnly>
      <NovaBoxBase v-if="show" class="modal-box">
        <div class="modal-header">
          <h4 class="title">
            <span class="chat-icon-wrap">
              <NovaIcon
                :icon="{ type: 'outline', name: 'chat' }"
                class="chat-icon"
              />
            </span>
            {{ t('sendbird.createChatChannel.modal.title') }}
          </h4>

          <NovaButtonIcon
            :icon="{ type: 'outline', name: 'close-extend' }"
            :theme="'transparent'"
            :size="20"
            class="btn-close"
            @click="handleOnClose"
          />
        </div>

        <NovaLayoutScrollContainer class="modal-body">
          <div class="inner">
            <section class="guide">
              <p class="message primary">
                {{ t('sendbird.createChatChannel.modal.guide.primary') }}
              </p>
              <p class="message secondary">
                {{ t('sendbird.createChatChannel.modal.guide.secondary') }}
              </p>
            </section>

            <section class="section-wrap">
              <h4 class="section-title">
                {{
                  t(
                    'sendbird.createChatChannel.modal.sections.channelTitle.label'
                  )
                }}
              </h4>
              <div class="section-contents">
                <NovaInput
                  v-model="title"
                  :placeholder="
                    t(
                      'sendbird.createChatChannel.modal.sections.channelTitle.placeholder'
                    )
                  "
                  :theme="'destroy'"
                />
                <p v-if="titleError" class="error-message">
                  <span class="error-icon" />
                  {{ titleError }}
                </p>
              </div>
            </section>
          </div>
        </NovaLayoutScrollContainer>

        <div class="actions">
          <NovaButtonText
            :label="t('sendbird.createChatChannel.modal.actions.cancel')"
            :theme="'secondary-gray'"
            :width="140"
            :full-width="false"
            :loading="isLoading"
            @click="handleOnClose"
          />
          <NovaButtonText
            :label="t('sendbird.createChatChannel.modal.actions.create')"
            :theme="'primary-blue'"
            :width="140"
            :full-width="false"
            :loading="isLoading"
            @click="handleOnCreateOpenChannel"
          />
        </div>
      </NovaBoxBase>
    </ClientOnly>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
:deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.vfm--overlay) {
  background-color: hex-to-rgba($color-black, 0.65);
}

:deep(.modal-content) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: none;

  .modal-box {
    width: 100%;
    height: auto;
    max-width: 670px;
    max-height: calc(100% - 24px - 24px);
    padding: 0;
    pointer-events: auto;
    overflow: hidden;

    .modal-header {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px 20px 10px;
      color: $color-text-2;
      background-color: $color-bg-3;

      .chat-icon-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        height: 44px;
        border-radius: 8px;
        background-color: $color-text-4;

        .chat-icon {
          font-size: 30px !important;
          color: $color-white;
        }
      }

      .title {
        display: flex;
        align-items: center;
        gap: 13px;
        font-size: 25px;
        line-height: 36px;
        letter-spacing: -5%;
        font-weight: 700;
      }
    }

    .modal-body {
      padding: 0 20px 20px;

      > .inner {
        display: flex;
        flex-direction: column;

        section.guide {
          .message {
            color: #727272;
            font-weight: 400;

            &.primary {
              font-size: 14px;
              line-height: 23.59px;
              letter-spacing: -0.2px;
            }

            &.secondary {
              font-size: 10px;
              line-height: 17px;
              letter-spacing: -0.2px;
            }
          }
        }

        section.section-wrap {
          display: flex;
          flex-direction: column;
          gap: 8px;
          margin-top: 22px;

          .section-title {
            display: flex;
            align-items: center;
            gap: 14px;
            font-size: 16px;
            font-weight: 700;
            line-height: 29.36px;
            letter-spacing: -0.2px;
            color: $color-black;
          }
        }
      }

      .async-status-message {
        min-height: 200px;
      }
    }

    .actions {
      flex-shrink: 0;
      display: flex;
      justify-content: flex-end;
      gap: 11px;
      width: 100%;
      padding: 20px;

      :deep(.btn) {
        display: inline-block;
        width: 140px;
      }
    }

    @include mobile {
      flex-grow: 1;
      border-radius: 0 !important;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
    }
  }
}

.border {
  border: 1px solid #727272;
  border-radius: 4px;
}

.padding {
  padding: 20px;
}

.error-message {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 10px;
  line-height: 12.1px;
  letter-spacing: -0.2px;
  color: $color-bg-custom-12;
  padding-left: 8px;
  margin-top: 4px;

  .error-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
    line-height: 1.2;
    border-radius: 50%;
    background-color: $color-bg-custom-12;
    color: $color-white;
    font-size: 9px;

    &:before {
      content: '!';
    }
  }
}

.tooltip-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border: 1px solid $color-bg-custom-10;
  border-radius: 50%;
  background-color: $color-bg-custom-11;

  &:after {
    content: '?';
    color: $color-bg-custom-10;
    font-size: 10px;
    line-height: 1;
  }
}

.nft-collections {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 12px;
  margin-left: 24px;
  border: 1px solid #727272;
  border-radius: 4px;
  margin-top: 4px;

  .collections {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-left: 24px;
  }
}
</style>
