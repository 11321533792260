import { AxiosError, type AxiosInstance, type AxiosResponse } from 'axios'

interface CustomErrorResponse extends AxiosResponse {
  originStatus: number
}

export interface CustomError extends AxiosError {
  response: CustomErrorResponse
}

export const setErrorCodeCheck = (instance: AxiosInstance) => {
  instance.interceptors.response.use((response) => {
    if (response.data.errorCode) {
      const customError = new AxiosError(
        `${response.data.errorCode} [${response.config.url}] api response error exception wrapper exist`
      ) as CustomError

      customError.code = 'Axios error exception override'
      customError.config = response.config
      customError.request = response.request
      customError.response = {
        ...response,
        originStatus: response.status,
        status: response.data.errorCode,
      }

      return Promise.reject(customError)
    }

    return Promise.resolve(response)
  })
}
