import { AxiosError } from 'axios'
import { BlockingTarget } from '@store'
import { NovaModalDialog } from '#components'

export const useFollow = () => {
  const { gtEvent } = useGoogleTag()
  const { t } = useNuxtApp().$i18n
  const { show: modalShow, hide: modalHide } = useModal()
  const layoutStore = useLayoutStore()
  const mypageStore = useMyPageStore()

  /**
   * 팔로워 삭제 모달을 표시.
   *
   * @param {number} flwUserSn - 팔로워 사용자의 일련번호.
   * @param {BlockingTarget} target - 팔로워 사용자 타입.
   * @returns {Promise<void>}
   */
  const showRemoveFollowerModal = async (
    flwUserSn: number,
    target: BlockingTarget
  ): Promise<void> => {
    gtEvent('clickEvent', {
      eventCategory: '클릭',
      eventAction: '팔로워 삭제하기 > 클릭',
      eventLabel: t('mypage.myFollow.button.delete'),
      eventSlot: '',
      eventI18nAddr: useKoreanTranslation('mypage.myFollow.button.delete'),
      eventComponent: 'Button',
      flwUserSn,
      target,
    })
    await modalShow({
      component: NovaModalDialog,
      bind: {
        name: modalsName.MODAL_DIALOG,
        btns: [
          {
            label: t('cancel'),
            theme: 'transparent',
            size: 32,
            onClick: async () => {
              await modalHide(modalsName.MODAL_DIALOG)
              gtEvent('clickEvent', {
                eventCategory: '클릭',
                eventAction: '팔로워 삭제하기 > 취소',
                eventLabel: t('cancel'),
                eventSlot: '',
                eventI18nAddr: useKoreanTranslation('cancel'),
                eventComponent: 'Button',
                flwUserSn,
                target,
              })
            },
          },
          {
            label: t('confirm'),
            theme: 'primary-blue-light',
            size: 32,
            onClick: async () => {
              gtEvent('clickEvent', {
                eventCategory: '클릭',
                eventAction: '팔로워 삭제하기 > 확인',
                eventLabel: t('confirm'),
                eventSlot: '',
                eventI18nAddr: useKoreanTranslation('confirm'),
                eventComponent: 'Button',
                flwUserSn,
                target,
              })
              await removeFollower(flwUserSn, target)
              gtEvent('userAction', {
                eventCategory: '사용자',
                eventAction: '팔로워 삭제',
                eventLabel: t('confirm'),
                eventSlot: '',
                eventI18nAddr: useKoreanTranslation('confirm'),
                eventComponent: 'Button',
                flwUserSn,
                target,
              })
              await modalHide(modalsName.MODAL_DIALOG)
            },
          },
        ],
      },
      slots: {
        title: t('confirm'),
        content: t('mypage.myFollow.message.followerRemoveConfirmMessage'),
      },
    })
  }

  /**
   * 팔로워 제거.
   * 글로벌 로딩 표시기를 업데이트하고, mypageStore의 deleteMyFollower 메소드를 사용하여 팔로워를 삭제
   * @param {number} flwUserSn - 제거될 팔로워 사용자의 일련번호.
   * @param {BlockingTarget} target - 제거될 팔로워 사용자 분류.
   * @returns {Promise<void>} - 팔로워가 성공적으로 제거되었을 때 해결되는 프로미즈.
   * @throws - 팔로워 삭제가 실패하면 오류를 발생시킵니다.
   */
  const removeFollower = async (
    flwUserSn: number,
    target: BlockingTarget
  ): Promise<void> => {
    try {
      layoutStore.updateLoadingIndicatorGlobal({ show: true })
      await mypageStore.deleteMyFollower({ flwUserSn }, target)
      gtEvent('userAction', {
        eventCategory: '사용자',
        eventAction: '팔로워 삭제 완료',
        eventLabel: '',
        eventSlot: '팔로워 모달 > more 버튼',
        eventI18nAddr: '',
        eventComponent: 'Button',
        flwUserSn,
        target,
      })
    } catch (err) {
      if (err instanceof AxiosError) {
        const status = err.response?.status
        switch (status) {
          case 2023:
          case 4002:
          case 4013:
            useToast(t(`mypage.myFollow.message.unfollow.error.${status}`))
            break
          default:
            useToast(t('mypage.myFollow.message.delError'))
            break
        }
      }
    } finally {
      layoutStore.updateLoadingIndicatorGlobal({ show: false })
    }
  }

  const showFollowErrorMessage = (err: AxiosError, userNcnm: string = '') => {
    const status = err.response?.status
    switch (status) {
      case 2022:
      case 2034:
      case 4002:
      case 4013:
        useToast(
          t(`mypage.myFollow.message.follow.error.${status}`, {
            userNcnm,
          })
        )
        break
      default:
        useToast(t('mypage.myFollow.message.follow.error.unknown'))
        break
    }
  }
  const showUnfollowErrorMessage = (err: AxiosError, userNcnm: string = '') => {
    const status = err.response?.status
    switch (status) {
      case 2023:
      case 2034:
      case 4002:
      case 4013:
        useToast(
          t(`mypage.myFollow.message.unfollow.error.${status}`, {
            userNcnm,
          })
        )
        break
      default:
        useToast(t('mypage.myFollow.message.unfollow.error.unknown'))
        break
    }
  }

  return {
    showRemoveFollowerModal,
    removeFollower,
    showFollowErrorMessage,
    showUnfollowErrorMessage,
  }
}
