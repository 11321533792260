/**
 * 앱 종료 안내
 */
export const terminateApp = {
  dialog: {
    contents: 're you sure you want to quit {appName}',
    actions: {
      negative: 'Cancel',
      positive: 'Quit',
    },
  },
}
