/**
 * 출석체크 관련 언어팩
 */
export const dailyCheck = {
  check: 'Check-in',
  dailyCheckin: 'Daily Check-in',
  checkedIn: 'Completed!',
  notification: 'Daily check-in push',
  totalCheckinDays: 'Total check-in day(s)',
  consecutiveCheckinDays: 'Consecutive check-in day(s)',
  guide: 'NOTICE',
  guides: [
    'Daily Check-in activity scores are 5 points.',
    'Push notifications for check-in are sent daily at 2:00 PM when the daily check-in alert is set.',
    'Check-in is available every day from 12:00 AM (KST, once a day).',
    'Press the check-in button to be recognized for check-in.',
    'Due to circumstances beyond our control, check-in operational policies may be changed or discontinued without prior notice.',
    'If check-in is performed using abnormal methods, you may be excluded from the reward eligibility.',
    'Daily Check-in activity scores are applied to the weekly rewards on Tuesdays.',
  ],
  reqDailyCheck: {
    success: 'Daily check-in has been completed.',
    errors: {
      2010: 'Daily check-in has already been completed.',
    },
  },
}
