<script setup lang="ts">
import type { NovaHeaderNavgationProps } from './NovaHeaderTools.types'
import NovaHeaderToolsMenu from '@components/NovaHeaderToolsMenu/NovaHeaderToolsMenu.vue'

const emit = defineEmits(['showSearchBar'])
withDefaults(defineProps<NovaHeaderNavgationProps>(), {
  onPopup: false,
})

const handleOnShowSearchBar = () => {
  emit('showSearchBar')
}
</script>

<template>
  <div class="tools">
    <NovaButtonGNB
      v-if="!onPopup"
      :icon="{ type: 'outline', name: 'search' }"
      class="btn-search"
      @click="handleOnShowSearchBar"
    />

    <NovaHeaderToolsLocaleChange />

    <NovaHeaderToolsNotifications v-if="!onPopup" />

    <NovaHeaderToolsUser />

    <NovaHeaderToolsMenu v-if="!onPopup" />
  </div>
</template>

<style lang="scss" scoped>
.tools {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 40px;
  width: 100%;
  font-size: 0;

  .btn-search {
    display: none;
  }

  @include mobile {
    gap: 24px;

    .btn-search {
      display: inline-flex;
    }
  }
}
</style>
