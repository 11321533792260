<script setup lang="ts">
import { Tippy } from 'vue-tippy'
import { AxiosError } from 'axios'

const config = useRuntimeConfig()
const { t } = useI18n()
const userStore = useUserStore()
const creatorStore = useCreatorStore()
const mypageStore = useMyPageStore()
const { gtEvent } = useGoogleTag()
const homeLinkPath = `${config.public.APP_URL}/creator/`
const homeLinkPrefix = '@'
const creatorId = ref(userStore.user?.creatorLink || '')
const editMode = ref(false)
const homeLinkPreview = computed(() =>
  useDeeplinkEntry(
    editMode.value
      ? `${homeLinkPath}${homeLinkPrefix}${creatorId.value}`
      : `${homeLinkPath}${homeLinkPrefix}${userStore.user?.creatorLink}`
  )
)
const loading = ref(false)
const isLock = computed(() => mypageStore.profile?.resistReferralCodeAt === 'Y')

// 수정 모드 변경시 초기화
watch(
  () => editMode.value,
  (cur) => {
    if (cur) {
      creatorId.value = userStore.user?.creatorLink || ''
    }
  }
)

// 클립보드 복사
const handleOnCopyClipboard = () => {
  useCopyToClipboard(homeLinkPreview.value)
  useToast(t('creatorHomeLink.tools.clipboard.toastMessage.success'))
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '추천인 링크 복사 버튼 클릭',
    eventLabel: t('creatorHomeLink.tools.clipboard.tooltip'),
    eventSlot: '프로필 수정 > 추천인 링크',
    eventI18nAddr: useKoreanTranslation(
      'creatorHomeLink.tools.clipboard.tooltip'
    ),
    eventComponent: 'Button',
  })
}

// 에디트 모드 토글
const handleOnToggleEditMode = () => {
  editMode.value = !editMode.value
}

// 크리에이터 홈 링크 업데이트
const handleOnUpdateHomeLink = async () => {
  // 1. https url 에서 사용하는 특수문자가 포함되어 있는지 여부 검사
  const isUnavailableNickname = useCheckUrlSpecialCharacter(creatorId.value)
  if (isUnavailableNickname) {
    useToast(t('creatorHomeLink.tools.edit.toastMessage.unavailableLmNovaLink'))
    return
  }

  // 2. 최소 글자수 검사
  if (!(creatorId.value.length >= 2)) {
    useToast(
      t('creatorHomeLink.tools.edit.toastMessage.failLength', { min: 2 })
    )
    return
  }

  // 3. 영문 + 숫자 외 한글 혹은 특수문자 검사
  if (!/^[a-zA-Z0-9]*$/.test(creatorId.value)) {
    useToast(t('creatorHomeLink.tools.edit.toastMessage.failCharacter'))
    return
  }

  // 4. 기존 크리에이터 링크와 같음
  if (creatorId.value === userStore.user?.creatorLink) {
    useToast(t('creatorHomeLink.tools.edit.toastMessage.failEqual'))
    return
  }

  // 5. 링크 업데이트 api 호출(중복검사 + 업데이트)
  try {
    loading.value = true

    // 5-1. 중복 검사
    await creatorStore.fetchCheckDuplicateCreatorLink({
      linkNm: creatorId.value,
    })

    // 5-2. 링크 업데이트
    await creatorStore.reqUpdateCreatorLink({
      creatorLinkNm: creatorId.value,
    })

    // 5-3. 사용자 프로필 재조회
    await mypageStore.fetchProfile()

    // 5-5. 성공 메세지 노출
    useToast(t('creatorHomeLink.tools.edit.toastMessage.success'))

    // 5-6. 수정 모드 닫기
    editMode.value = false
  } catch (err) {
    const _err = err as AxiosError
    if (_err.response?.status) {
      switch (_err.response.status) {
        case 5001:
          useToast(t('creatorHomeLink.tools.edit.toastMessage.failDuplicate'))
          break
        case 5002:
          useToast(t('creatorHomeLink.tools.edit.toastMessage.failUpdate'))
          break
        default:
          useToast(t('creatorHomeLink.tools.edit.toastMessage.failDefault'))
          break
      }
    }
  } finally {
    loading.value = false
  }
}
</script>

<template>
  <NovaBoxAccountSection :title="$t('creatorHomeLink.label')">
    <template #contents>
      <div class="home-link-wrap">
        <div class="preview-area">
          <p class="home-link-path">{{ homeLinkPreview }}</p>

          <div v-if="editMode && !isLock" class="edit-area">
            <NovaInputSimple v-model="creatorId" :full-width="true" />

            <div class="tools">
              <NovaButtonText
                :size="32"
                :label="$t('cancel')"
                :theme="'secondary-gray'"
                @click="handleOnToggleEditMode"
              />

              <NovaButtonText
                class="btn-save"
                :size="32"
                :label="$t('save')"
                :theme="'primary-blue'"
                @click="handleOnUpdateHomeLink"
              />
            </div>
          </div>
        </div>

        <div v-if="!editMode" class="edit-tools">
          <Tippy
            :append-to="'parent'"
            :content="$t('creatorHomeLink.tools.clipboard.tooltip')"
            :interactive="true"
            :theme="'simple'"
            :placement="'bottom'"
          >
            <NovaButtonIcon
              :icon="{ type: 'outline', name: 'link' }"
              :size="24"
              :loading="loading"
              class="btn-clipboard"
              @click="handleOnCopyClipboard"
            />
          </Tippy>

          <Tippy
            v-if="!isLock"
            :append-to="'parent'"
            :content="$t('creatorHomeLink.tools.edit.tooltip')"
            :interactive="true"
            :theme="'simple'"
            :placement="'bottom'"
          >
            <NovaButtonIcon
              :icon="{ type: 'outline', name: 'write' }"
              :size="16"
              :loading="loading"
              class="btn-edit"
              @click="handleOnToggleEditMode"
            />
          </Tippy>
        </div>
      </div>
    </template>
  </NovaBoxAccountSection>
</template>

<style lang="scss" scoped>
.home-link-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  .preview-area {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 6px;

    .home-link-path {
      @include text-style($text-body-13-regular);
      color: $color-text-3;
    }

    .edit-area {
      display: flex;
      align-items: center;
      gap: 12px;

      > .tools {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }

  .edit-tools {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 0;

    .btn-clipboard {
      width: 16px;
      height: 16px;
    }
  }
}
</style>
