<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation, Pagination } from 'swiper/modules'
import type { RouteParams } from 'vue-router'
import type { PremiumExpiredItem } from '@store'

defineOptions({
  inheritAttrs: false,
})

const { t } = useI18n()
const { hide: modalHide } = useModal()
const { cookieNames, setCookie } = useCookies()
const show = ref(false)
const appStore = useAppStore()
const offOpenOneDay = ref(false)
const premiumExpiredList = ref<PremiumExpiredItem[]>([])
const swiperOptions = {
  navigation: true,
  pagination: {
    clickable: true,
  },
  modules: [Navigation, Pagination],
}

// 팝업 열릴때 호출하는 쪽에서 파라미터 받아 프리미엄 후원 만료 리스트 갱신
const handleOnGetParams = (evt: RouteParams) => {
  premiumExpiredList.value = evt.value as unknown as PremiumExpiredItem[]
}

// 팝업 닫기
const handleOnClose = async () => {
  await modalHide(modalsName.MODAL_PREMIUM_EXPIRED)
}

// 팝업 닫은 후 프리미엄 후원 만료 초기화 및 하루동안 열지 않을지 세팅
const handleOnClosed = () => {
  offShowPremiumExpired()

  premiumExpiredList.value = []
  offOpenOneDay.value = false
}

// 오늘 하루 이 창을 열지 않음 체크 토글
const handleOnToggleOffOpenOneDay = () => {
  offOpenOneDay.value = !offOpenOneDay.value
}

// 오늘 하루 이창을 열지 않음
const offShowPremiumExpired = () => {
  if (offOpenOneDay.value) {
    setCookie(cookieNames.OFF_NOTI_PREMIUM_EXPIRED, true, 1, 'days')
  }
}

// 후원하러 가기
const handleOnGoSupport = async (
  creatorUserSn: PremiumExpiredItem['crtrUserSn']
) => {
  await handleOnClose()
  await useNavigations({
    external: false,
    url: `/creator/subscribe/support/${creatorUserSn}`,
  })
}
</script>

<template>
  <VueFinalModal
    v-model="show"
    :name="modalsName.MODAL_PREMIUM_EXPIRED"
    :content-class="'modal-content'"
    classes="modal-container"
    @before-open="(evt: any) => handleOnGetParams(evt.ref.params)"
    @closed="handleOnClosed"
    @click-outside="handleOnClose"
  >
    <ClientOnly>
      <NovaBoxBase v-if="show" class="modal-box">
        <div class="modal-header">
          <div class="on-desktop">
            <NovaButtonIcon
              :icon="{ type: 'outline', name: 'close-extend' }"
              :theme="'transparent'"
              :size="24"
              class="btn-close"
              @click="handleOnClose"
            />
          </div>
        </div>

        <div class="modal-body">
          <Swiper v-bind="swiperOptions">
            <SwiperSlide
              v-for="expiredItem in premiumExpiredList"
              :key="expiredItem.crtrUserSn"
              class="swiper-slide"
            >
              <div class="premium-expired-message">
                <div class="message">
                  <h4 class="title">
                    {{
                      t('subscribe.expiredNotification.title', {
                        creatorNickname: expiredItem.crtrUserNcnm,
                        subscribeGrade: t('subscribe.subscribeGrade.novaPlus'),
                      })
                    }}
                  </h4>

                  <p class="description">
                    {{
                      t('subscribe.expiredNotification.description', {
                        subscribeGrade: t('subscribe.subscribeGrade.novaPlus'),
                      })
                    }}
                  </p>
                </div>

                <NovaButtonText
                  v-if="!appStore.isApp"
                  :theme="'primary-blue-light'"
                  :label="
                    t('subscribe.expiredNotification.support', {
                      subscribeGrade: t('subscribe.subscribeGrade.novaPlus'),
                    })
                  "
                  @click="() => handleOnGoSupport(expiredItem.crtrUserSn)"
                />
                <NovaButtonText
                  v-else
                  :theme="'primary-blue-light'"
                  :label="t('subscribe.expiredNotification.confirm')"
                  @click="handleOnClose"
                />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>

        <div class="modal-footer">
          <NovaCheckbox
            :is-check="offOpenOneDay"
            :label="t('timeLimitPopup.offOpenOneDay')"
            @click="handleOnToggleOffOpenOneDay"
          />

          <NovaButtonSimpleText
            :label="t('timeLimitPopup.close')"
            :label-style="{ fontSize: '14px' }"
            @click="handleOnClose"
          />
        </div>
      </NovaBoxBase>
    </ClientOnly>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
:deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.vfm--overlay) {
  background-color: hex-to-rgba($color-black, 0.65);
}

:deep(.modal-content) {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(30px + 24px + 12px) 32px 32px;
  width: 100%;
  height: 100%;
  pointer-events: none;

  .modal-box {
    width: 100%;
    padding: 0;
    max-width: 670px;
    max-height: 100%;
    border-radius: 20px !important;
    pointer-events: auto;

    .modal-header {
      flex-shrink: 0;
      height: 0;
      padding: 0;
      background-color: $color-bg-3;

      .on-desktop {
        .btn-close {
          position: absolute;
          top: 30px;
          right: 30px;
          color: $color-bg-3;
        }
      }
    }

    .modal-body {
      .swiper-slide {
        height: auto;
      }

      .premium-expired-message {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 32px;
        height: 100%;
        padding: 40px 16px 36px;

        .message {
          display: flex;
          flex-direction: column;
          gap: 24px;
          text-align: center;

          .title {
            @include text-style($text-heading2-bold);
          }

          .description {
            @include text-style($text-display-bold);
          }
        }
      }
    }

    .modal-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px;
      border-top: 1px solid $color-bg-custom-1;
    }
  }
}
</style>
