<script setup lang="ts">
import type { NovaCreatorCellProps } from './NovaCreatorCell.types'

const emit = defineEmits(['toggleShowPanel'])
withDefaults(defineProps<NovaCreatorCellProps>(), {
  portrait: null,
  userName: '',
  lock: false,
  onUserPanel: false,
  responsive: false,
  postCreateAt: '',
  regate: '',
})

const slots = useSlots()

const handleOnToggleUserPanel = () => {
  emit('toggleShowPanel')
}
</script>

<template>
  <div
    :class="[
      'user-cell',
      { on: slots['user-panel'] && onUserPanel },
      { responsive },
    ]"
  >
    <div
      :class="['user-info-wrap', { 'click-able': slots['user-panel'] }]"
      @click="handleOnToggleUserPanel"
    >
      <div class="user-info">
        <NovaPortraitContainer :image-url="portrait" :size="'sm'" />

        <div class="more-info">
          <div v-if="userName" class="user-name-wrap">
            <span class="user-name">{{ userName }}</span>

            <!-- <div v-if="lock" class="lock">
              <NovaIcon
                :icon="{ type: 'outline', name: 'lock' }"
                :size="14"
                class="icon"
              />
            </div>

            <span v-if="postCreateAt" class="create-at">{{
              postCreateAt
            }}</span> -->
          </div>

          <div class="data-info">
            <span>{{ postCreateAt }}</span>
            <!--
            공지 사항 조회수 노출 제거(받아 오는 데이터 없음)
            <span>{{ $t('displayCount') }} {{ postViewCount }}</span>
            -->
          </div>

          <div class="post-info"></div>
        </div>
      </div>

      <NovaIcon
        v-show="slots['user-panel']"
        :icon="{ type: 'outline', name: 'carrot-bottom' }"
        :size="20"
        class="user-panel-indicator"
      />
    </div>

    <slot name="user-panel" />
  </div>
</template>

<style lang="scss" scoped>
.user-cell {
  display: flex;
  align-items: center;

  &.on {
    > .user-info-wrap > .user-panel-indicator {
      transform: rotate(180deg);
    }
  }

  .user-info-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    &.click-able {
      cursor: pointer;
    }

    > .user-info {
      display: flex;
      align-items: center;
      gap: 10px;

      .more-info {
        .user-name-wrap {
          display: flex;
          align-items: center;
          gap: 6px;

          .user-name {
            flex-grow: 1;
            @include ellipsis(1);
            @include text-style($text-body-14-bold);
          }

          .lock {
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 16px;
            height: 16px;
            border-radius: 100%;
            background-color: $color-secondary-blue-light-10;

            .icon {
              color: $color-secondary-blue-light-80;
            }
          }
        }

        .create-at {
          @include text-style($text-body-11-medium);
          color: $color-text-3;
        }

        .data-info {
          display: flex;
          gap: 10px;
          span {
            font-weight: 500;
            font-size: 11px;
            line-height: 16px;
            letter-spacing: -0.55px;
            color: #97989d;
          }
        }
      }
    }

    .user-panel-indicator {
      color: $color-text-4;
      @include transition(transform 0.2s ease-in-out);
    }
  }

  @include tablet {
    &.responsive {
      > .user-info-wrap {
        > .user-info {
          .more-info {
            .user-name-wrap {
              display: none;
            }
          }
        }

        > .user-panel-indicator {
          display: none !important;
        }
      }
    }
  }

  @include mobile {
    &.responsive {
      > .user-info-wrap {
        > .user-info {
          .portrait-container {
            width: 25px;
            height: 25px;

            :deep(.image-container .empty-image.portrait) {
              background-size: 12px auto;
            }
          }
          > .user-name-wrap {
            display: none;
          }
        }
      }
    }
  }
}
</style>
