<script setup lang="ts">
import type { NovaBoxMyCollectionNftDetailProps } from './NovaBoxMyCollectionNftDetail.types'

const props = withDefaults(defineProps<NovaBoxMyCollectionNftDetailProps>(), {
  isDetail: true,
})
const { t } = useI18n()
const seasonDescShowMore = ref(true)
const colctDescShowMore = ref(true)

const issueDate = computed(() => {
  return formatDateToGMT9(props.myCollectionNft.nftitmIssDt)
})
const formatDateToGMT9 = (issueDate: string) => {
  const date = new Date(issueDate)

  const dateInGMT9 = new Intl.DateTimeFormat('en-CA').format(date)
  return `${dateInGMT9} (GMT +9)`
}

const onHandleSeasonDc = () => {
  seasonDescShowMore.value = !seasonDescShowMore.value
}
const onHandleColctDc = () => {
  colctDescShowMore.value = !colctDescShowMore.value
}

// 지갑 주소 123...789 형식으로 처리
// const formatAddress = (address: string) => {
//   if (address.length <= 10) return address
//   return `${address.slice(0, 8)}...${address.slice(-6)}`
// }
//
// const formattedWalletAddress = computed(() => {
//   return props.myCollectionNft.userUuid
//     ? formatAddress(props.myCollectionNft.userUuid)
//     : ''
// })

const seasonDescOverFlow = ref(true)
const colDescOverFlow = ref(true)

onMounted(async () => {
  await nextTick()
  const elSeasonDesc = document.getElementsByClassName('message-season')
  const elColDesc = document.getElementsByClassName('message-col')
  if (elSeasonDesc[0].clientHeight >= elSeasonDesc[0].scrollHeight)
    seasonDescOverFlow.value = false
  if (elColDesc[0] && elColDesc[0].clientHeight >= elColDesc[0].scrollHeight)
    colDescOverFlow.value = false
})
</script>

<template>
  <div :class="['collections-detail', { 'on-detail': isDetail }]">
    <div class="body">
      <div class="contents media">
        <NovaMediaContainer
          :image="
            myCollectionNft.mediaTyCode === 'I'
              ? { source: myCollectionNft.fileUrl }
              : undefined
          "
          :video="
            myCollectionNft.mediaTyCode === 'V'
              ? {
                  source: myCollectionNft.fileUrl,
                  poster: myCollectionNft.fileUrl,
                }
              : undefined
          "
          :auto-play="true"
          :preload="'auto'"
          :nft="true"
        />
      </div>
      <div class="contents season-box">
        <div class="title">
          {{ myCollectionNft.seasonNm }}
        </div>
        <div class="message-box">
          <div :class="['message-season', { show: !seasonDescShowMore }]">
            {{ myCollectionNft.seasonDc }}
          </div>
          <div v-if="seasonDescOverFlow" class="btn-more">
            <NovaButtonText
              :label="
                seasonDescShowMore
                  ? t('mypage.myCollection.button.showMore')
                  : t('mypage.myCollection.button.showLess')
              "
              :size="32"
              class="btn-action"
              @click="onHandleSeasonDc"
            />
          </div>
        </div>
      </div>
      <hr />
      <div class="contents">
        <div class="title">
          {{ myCollectionNft.colctNm }}
        </div>
        <div v-if="myCollectionNft.nftitmSn" class="message-box">
          <div :class="['message-col', { show: !colctDescShowMore }]">
            {{ myCollectionNft.colctDc }}
          </div>
          <div v-if="colDescOverFlow" class="btn-more">
            <NovaButtonText
              :label="
                colctDescShowMore
                  ? t('mypage.myCollection.button.showMore')
                  : t('mypage.myCollection.button.showLess')
              "
              :size="32"
              class="btn-action"
              @click="onHandleColctDc"
            />
          </div>
        </div>
      </div>
      <hr />
      <div class="contents collections-data-box">
        <div class="collections-data">
          <dl>
            <dt>{{ $t('mypage.myCollection.detail.owner') }}</dt>
            <dd class="user-uu-id">
              {{ myCollectionNft.userNcnm }}({{ myCollectionNft.userUuid }})
            </dd>
          </dl>
        </div>
        <div v-if="myCollectionNft.nftitmRareCodeNm" class="collections-data">
          <dl>
            <dt>{{ $t('mypage.myCollection.detail.rarity') }}</dt>
            <dd>
              <span :class="[`on-${myCollectionNft.nftitmRareCodeNm}`]">{{
                myCollectionNft.nftitmRareCodeNm
              }}</span>
            </dd>
          </dl>
        </div>
        <div class="collections-data">
          <dl>
            <dt>{{ $t('mypage.myCollection.detail.issueDate') }}</dt>
            <dd>{{ issueDate }}</dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.collections-detail {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 15px 15px 30px 15px;
  background-color: $color-bg-3;
  hr {
    border: 1px solid $color-bg-custom-1;
  }
  &.on-detail {
    border-radius: 16px;
  }
  .contents {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 0;
    &.media {
      padding: 0 0 15px 0;
    }
    .title {
      @include text-style($text-custom-18-bold);
    }
    .message-box {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .message-season {
        @include text-style($text-display-medium);
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        word-break: break-all;
        &.show {
          display: block;
          height: auto;
          -webkit-line-clamp: none;
        }
        &.type {
          color: $color-text-12;
        }
      }
      .message-col {
        @include text-style($text-display-medium);
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        word-break: break-all;
        &.show {
          display: block;
          height: auto;
          -webkit-line-clamp: none;
        }
        &.type {
          color: $color-text-12;
        }
      }
      .btn-more {
        display: flex;
        justify-content: flex-end;
        color: $color-text-12;
        :deep(.btn-32) {
          padding: 0;
          @include text-style($text-display-medium);
          color: $color-text-12;
          &:hover {
            text-decoration: underline;
            text-underline-offset: 4px;
          }
        }
      }
    }
    &.season-box {
      .title {
        color: $color-primary-blue-dark;
      }
    }
    &.collections-data-box {
      gap: 24px;
    }
    .collections-data {
      dl {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        align-items: center;
        dt {
          @include text-style($text-display-bold);
        }
        dd {
          @include text-style($text-display-medium);
          word-break: break-all;
          &.user-uu-id {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            word-break: break-all;
            @include mobile {
              max-width: 70%;
            }
          }
          .on-Normal {
            color: #7c7c7c;
          }
          .on-Rare {
            color: #1a6642;
          }
          .on-Epic {
            color: #11336e;
          }
          .on-Unique {
            color: #68006a;
          }
          .on-Legendary {
            color: #e97132;
          }
        }
      }
    }
  }
}
</style>
