/**
 * 크리에이터 홈페이지 Url
 */
export const creatorHomeLink = {
  label: 'I LIKE LM link',
  edit: {
    placeholder: 'Please insert the address of your home',
  },
  tools: {
    clipboard: {
      tooltip: 'Copy the I LIKE LM link',
      toastMessage: {
        success: 'Link has been copied in the clipboard.',
      },
    },
    edit: {
      tooltip: 'Modify',
      toastMessage: {
        unavailableLmNovaLink:
          'Invalid characters in the text. Please use another I LIKE LM link.',
        success: 'The link of your home has been modified',
        failDefault:
          'Sorry, our service is currently unavailable. Please try again later',
        failEqual:
          'The link is the same with your current link. Please try another link',
        failUpdate: "It can't be saved. Please try again later",
        failDuplicate: "It's been taken",
        failLength: 'Please insert more than {min} letters',
        failCharacter:
          'Please insert combinations of english letters and numbers',
        checkDuplicateBeforeSave: 'Please check duplicate before Save.',
      },
    },
  },
}
