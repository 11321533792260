import * as FcmType from './type'
import FcmApi from './url'
import { api } from '@/api'

export default {
  /**
   * FCM 토큰 등록
   * @param payload
   */
  sendFcmTokenToServer(payload: FcmType.SendFcmTokenToServerPayload) {
    return api.post<FcmType.FirebaseApiDefaultResponse>(
      FcmApi.SEND_FCM_TOKEN_TO_SERVER,
      payload
    )
  },

  /**
   * 마케팅 동의 여부 등록
   * @param payload
   */
  saveMarketingPushAgree(payload: FcmType.SaveMarketingAgreePayload) {
    return api.post<FcmType.FirebaseApiDefaultResponse>(
      FcmApi.SAVE_MARKETING_AGREE,
      payload
    )
  },
}
