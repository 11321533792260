import { commonError } from './commonError'

export const blockMessage = {
  messages: {
    blockedByOtherUserPrimary:
      '<strong>{userNcnm}</strong>님이 나를 차단했습니다.',
    blockedByOtherUserSecondary:
      '<strong>{userNcnm}</strong> 님을 팔로우하거나 <strong>{userNcnm}</strong> 님의 콘텐츠를 볼 수 없습니다.',
    blockByCurrentUser: '<strong>{userNcnm}</strong>님은 차단되어 있습니다.',
    showContentsPrimary: '정말로 이 콘텐츠를 표시할까요?',
    showContentsSecondary:
      '콘텐츠를 표시해도 {userNcnm} 님에 대한 차단은 해제되지 않습니다.',
    unBlocking: '차단을 해제하시겠습니까?',
  },
  modal: {
    block: {
      title: '차단하기',
      contents: {
        title: '<strong>{userNcnm}</strong>님을 차단하시겠습니까?',
        message: '나를 팔로우하거나 내 콘텐츠를 볼 수 없습니다.',
        novaPlusMessage:
          '해당 유저는 당신의 노바플러스 후원자입니다. <br /> 노바플러스 후원자를 정당한 이유(욕설/스토킹 등) 없이 차단하는 행위는 제재를 받을 수 있습니다.',
      },
    },
    unblock: {
      title: '차단해제',
      contents: {
        title: '<strong>{userNcnm}</strong>님을 차단 해제 할까요?',
        message: '나를 팔로우하고 내 콘텐츠를 볼 수 있습니다.',
      },
    },
  },
  toastMessage: {
    errors: {
      2010: '이미 차단된 사용자입니다.', // already block error
      2011: '차단된 사용자가 아닙니다.', // already unblock error
      4002: '가입되지 않은 회원입니다.',
      4013: '가입되지 않은 회원입니다.',
      unknown: commonError.network.message,
    },
    blocking: '차단 되었습니다.',
    unBlocking: '차단 해제되었습니다.',
  },
  actions: {
    blocked: '차단됨',
    showContents: '콘텐츠 보기',
    unBlock: '차단 해제',
    block: '차단',
    cancel: '취소',
  },
}
