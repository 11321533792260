<script setup lang="ts">
const appStore = useAppStore()
const { appType, currentVer, appBuildNo } = storeToRefs(appStore)

// model: 앱 정보
const appInfo = computed(() => [
  {
    key: 'appType',
    value: appType.value || 'null',
    description:
      'IOS: 애플 디바이스<br />AOS: 안드로이드 디바이스<br />null: 웹',
  },
  {
    key: 'currentVer',
    value: currentVer.value || 'null',
    description: '앱 현재 버전',
  },
  {
    key: 'appBuildNo',
    value: appBuildNo.value || 'null',
    description: '앱 빌드 번호',
  },
])
const appInfoDescriptions = ref([
  '최초 앱 구동시 네이티브 엡에서 쿠키에 저장된 정보를 표시합니다.',
  '강제 앱 모드 구동시 쿠키 정보를 무시하고 상태 관리에 저장된 정보를 표시합니다.',
])
</script>

<template>
  <section class="dev-section">
    <h4 class="dev-section-title">앱 정보</h4>

    <div class="section-inner">
      <table class="dev-table">
        <colgroup>
          <col style="width: 100px" />
          <col style="width: 80px" />
        </colgroup>
        <thead>
          <tr>
            <th>키</th>
            <th>값</th>
            <th>설명</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in appInfo" :key="item.key">
            <td>{{ item.key }}</td>
            <td>{{ item.value }}</td>
            <td v-dompurify-html="item.description" />
          </tr>
        </tbody>
      </table>

      <NovaDescriptions :descriptions="appInfoDescriptions" />
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import '@styles/base/dev-tools';
@include dev-tools-section;
</style>
