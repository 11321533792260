import { type LocaleObject } from '@nuxtjs/i18n'
import { useUserStore } from '@/store'

/**
 * 로그인 권한이 필요한 페이지 접근 제어
 */
export default defineNuxtRouteMiddleware((to) => {
  const nuxtApp = useNuxtApp()
  const { cookieNames, getCookie } = useCookies()
  const userStore = useUserStore()
  const limitRoutesPrefix = ['creator-sponsors', 'user-sponsors']
  const locales: LocaleObject[] = nuxtApp.$i18n.locales.value
  const limitRouteNames: string[] = []
  let passTo = false

  // 2-1. 로그인이 필요한 라우트 리스트 추출
  limitRoutesPrefix.forEach((prefix) => {
    locales.forEach((locale) => {
      limitRouteNames.push(`${prefix}___${locale.code}`)
    })
  })

  // 2-2. 로그인이 필요한 라우트 접근시
  if (limitRouteNames.includes(String(to.name))) {
    // server side
    if (process.server) {
      const requestEvent = useRequestEvent()
      const cookie = requestEvent?.node.req.headers.cookie
        ? getCookie(
            cookieNames.AUTH_TOKEN,
            requestEvent.node.req.headers.cookie
          )
        : null
      const authToken = cookie || null
      passTo = !!authToken
    }

    // client side
    else {
      // 크리에이터 후원 목록 접근시 랜딩 조건
      passTo =
        String(to.name).includes('creator-sponsors') &&
        userStore.user?.cmtyUserSeCode !== 'C'
          ? false
          : userStore.isSignIn
    }

    if (!passTo) {
      throw createError({ statusCode: 401, statusMessage: 'Unauthorized' })
    }
  }
})
