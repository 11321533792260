<script setup lang="ts">
import { type TermsItem } from '@store'
import { ROUTES } from '@configs'
const emit = defineEmits(['goBack'])
const { gtEvent } = useGoogleTag()
const { t } = useI18n()
const layoutStore = useLayoutStore()
const etcStore = useEtcStore()
const services = computed(() => [
  { label: t('services.leisureMetaVerse'), url: 'https://leisuremeta.io' },
  { label: t('services.theMoonLabs'), url: 'https://themoonlabs.net' },
])
const handleOnGoBack = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '서비스 및 정책 > 뒤로 가기 버튼',
    eventLabel: t('servicesAndPolicies'),
    eventSlot: '헤더',
    eventI18nAddr: useKoreanTranslation('servicesAndPolicies'),
    eventComponent: 'Button',
  })
  emit('goBack')
}
const handleOnGoService = async (url: string, label: string) => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: `서비스 및 정책 > 서비스 > ${label}`,
    eventLabel: label,
    eventSlot: '헤더',
    eventI18nAddr: '',
    eventComponent: 'Button',
  })
  layoutStore['layer:close'](LayerType.USER_PANEL)
  layoutStore['layer:close'](LayerType.SYSTEM_PANEL)
  await useNavigations({ external: true, url })
}
const handleOnGoTerm = (id: TermsItem['stplatId'], label: string) => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: `서비스 및 정책 > 정책 > ${label}`,
    eventLabel: label,
    eventSlot: '헤더',
    eventI18nAddr: '',
    eventComponent: 'Button',
  })
  layoutStore['layer:close'](LayerType.USER_PANEL)
  layoutStore['layer:close'](LayerType.SYSTEM_PANEL)
  useNavigations({ url: useRoutePathIdChange(ROUTES.TERMS.path, { id }) })
}
</script>

<template>
  <NovaHeaderToolsPanelBase>
    <NovaHeaderCellsNavigationBar
      :label="t('servicesAndPolicies')"
      @go-back="handleOnGoBack"
    />

    <NovaLayoutScrollContainer class="contents">
      <NovaHeaderMenuGroup :group-name="t('service')">
        <template #menus>
          <NovaHeaderMenuItem
            v-for="(service, index) in services"
            :key="index"
            :label="service.label"
            @click="() => handleOnGoService(service.url, service.label)"
          />
        </template>
      </NovaHeaderMenuGroup>

      <NovaDivider v-show="etcStore.terms.length" />

      <NovaHeaderMenuGroup
        v-show="etcStore.terms.length"
        :group-name="t('policy')"
      >
        <template #menus>
          <NovaHeaderMenuItem
            v-for="term in etcStore.terms"
            :key="term.stplatId"
            :label="term.stplatNm"
            @click="() => handleOnGoTerm(term.stplatId, term.stplatNm)"
          />
        </template>
      </NovaHeaderMenuGroup>
    </NovaLayoutScrollContainer>
  </NovaHeaderToolsPanelBase>
</template>

<style lang="scss" scoped>
.contents {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px 20px;
}
</style>
