import { type Route, routeSplitPrefix } from '@configs'

/**
 * 제공된 라우트 이름 또는 현재 라우트에서 라우트 이름을 반환
 *
 * @param {string} [routeName] - 선택적인 라우트 이름
 * @returns {string} - 라우트 분할 접두사 없는 라우트 이름
 */
export const useGetRouteName = (routeName?: Route['name']): string => {
  let name = routeName || ''
  if (!name) {
    const route = useRoute()
    name = route.name ? String(route.name) : ''
  }
  return name.split(routeSplitPrefix)[0]
}
