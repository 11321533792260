import { ROUTES } from '@configs'

/**
 * 특정 페이지 접근 블럭
 */
export default defineNuxtRouteMiddleware((to) => {
  const config = useRuntimeConfig()
  const blockPageNames =
    config.public.MODE === 'production'
      ? [
          ROUTES.W3CF.name,
          ROUTES.W3CF_DETAIL.name,

          // TODO: 프로덕션에서 오픈 지시가 있을때 까지 노출 X
          ROUTES.APPLY_SUPER_MOON.name,
          ROUTES.APPLY_SUPER_MOON_FORM.name,

          // TODO: 앱 버전 릴리즈를 위해 기능 숨김
          ROUTES.CREATOR_NOVAPLUS.name,
          ROUTES.CREATOR_NOVAPLUS_DETAIL.name,
          ROUTES.NOVAPLUS.name,
          ROUTES.NOVAPLUS_DETAIL.name,
          ROUTES.SEARCH_DETAIL_NOVAPLUS.name,
          ROUTES.SEARCH_DETAIL_NOVAPLUS_DETAIL.name,
        ]
      : []

  blockPageNames.forEach((name) => {
    if (String(to.name).includes(name)) {
      throw showError({
        statusCode: 404,
        statusMessage: 'page not found',
      })
    }
  })
})
