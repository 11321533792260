import { corporationInfo } from './corporationInfo'
import type {
  CreatorSubscribeAccessCode,
  CreatorSubscribeAccessStatus,
  CreatorSubscribeErrorMessage,
} from '@mixins/useSubscribe'
import type { PremiumPostsOrderBy } from '@store/post/type'

export const subscribe = {
  premiumBrand: '<span class="premium-brand-logo">노바<sup>+</sup></span>',
  subscribeGrade: {
    premium: '프리미엄',
    novaPlus: '노바+',
  },
  subscribeGate: {
    benefitSetting: '{subscribeGrade} 혜택 설정',
    balance: '{subscribeGrade} 내역',
  },
  subscribeAccessCode: {
    opened: '{subscribeGrade} 개설',
    support: '{subscribeGrade} 후원',
    cancel: '{subscribeGrade} 해지',
    refund: '{subscribeGrade} 환불',
  } as {
    [Property in CreatorSubscribeAccessCode]: string
  },
  premiumCreators: {
    title: '나의 {premiumBrand} 목록',
    error:
      '내가 {subscribeGrade} 후원하는 크리에이터를 불러올 수 없습니다. 잠시 후 다시 시도해 주세요.',
    empty: '{subscribeGrade} 후원한 크리에이터가 없습니다.',
  },
  creatorsByRecommended: {
    title: '추천 크리에이터',
    error: '추천 크리에이터를 불러올 수 없습니다. 잠시 후 다시 시도해 주세요.',
    empty: '추천 크리에이터가 없습니다.',
  },
  premiumPosts: {
    orderBy: {
      newest: '최신순',
      oldest: '과거순',
      hot: '인기순',
    } as { [Property in PremiumPostsOrderBy]: string },
    error:
      '내가 {subscribeGrade} 후원하는 크리에이터의 콘텐츠를 불러올 수 없습니다. 잠시 후 다시 시도해 주세요.',
    empty:
      '내가 팔로우하는 크리에이터의 {subscribeGrade} 후원하고<br />후원자만을 위한 특별한 콘텐츠를 만나보세요!',
    emptyByNoPremium: '{subscribeGrade} 콘텐츠가 없습니다.',
    requireSignin:
      '다양한 I LIKE LM 크리에이터들의 콘텐츠를 즐겨보세요.<br />{subscribeGrade}는 로그인 시 이용할 수 있습니다.',
  },
  premiumPost: {
    new: '{subscribeGrade} 후원자들을 위한 특별한 콘텐츠를 발행해보세요!',
    empty: '{subscribeGrade} 개설 후, 포스트 작성이 가능합니다.',
    blockMessage: '{subscribeGrade} 후원자에게만 공개된 콘텐츠입니다.',
    blockMessageAction: '{subscribeGrade} 후원하기',
  },
  createPremiumPost: {
    requireCreator: {
      title: '알림',
      message:
        '{subscribeGrade} 포스트를 작성하려면 크리에이터로 전환하셔야 합니다. 전환 하시겠습니까?',
    },
    requirePremiumOpened: {
      title: '알림',
      message:
        '{subscribeGrade} 개설 후, 포스트 작성이 가능합니다. 개설 하시겠습니까?',
      toastMessage: '{subscribeGrade} 개설 후, 포스트 작성이 가능합니다.',
    },
  },
  sections: {
    subscribeSupportDetail: {
      title: '{premiumBrand} 후원 정보',
      benefit: '{creatorNickname}님의 {premiumBrand} 혜택',
      support: '크리에이터님의 {subscribeGrade} 월 후원 금액',
      paymentPrice: '결제금액',
      vat: 'VAT',
    },
    subscribeCancelDetail: {
      title: '{subscribeGrade} 정보',
      benefit: '혜택',
      guide:
        '이 혜택을 정말 해지하시겠어요?<br />해당 {subscribeGrade}은 {date}까지 이용 가능해요!',
    },
    subscribeRefundDetail: {
      title: '{subscribeGrade} 정보',
      benefit: '혜택',
      guide:
        '{subscribeGrade} 환불을 진행하시겠어요?<br />환불 신청 시 {subscribeGrade} 혜택이 즉시 중단돼요!',
    },
    cancelReasons: {
      title: '{subscribeGrade} 해지 사유',
      reasons: {
        expensive: '가격이 비싸다.',
        technical: '기술적 문제가 있다.',
        interesting: '보고싶은 콘텐츠가 아니다.',
        useService: '이 서비스를 자주 이용하지 않는다.',
        contentsQuality: '콘텐츠 품질에 불만족한다.',
        etc: '기타',
      },
    },
    refundForm: {
      title: '환불 정보 입력 란',
      form: {
        bank: {
          label: '환불은행',
          placeholder: '환불은행을 선택해주세요.',
        },
        id: {
          label: '아이디',
          placeholder: '',
        },
        bankAccount: {
          label: '환불계좌',
          placeholder: '환불계좌를 입력해 주세요.',
          description: '계좌 번호는 하이픈(-)을 포함하여 입력해 주세요.',
        },
        nickname: {
          label: '닉네임',
          placeholder: '',
          description: '환불이 진행되는 동안 닉네임을 변경할 수 없습니다.',
        },
        bankAccountHolder: {
          label: '예금주',
          placeholder: '예금주를 입력해 주세요.',
        },
      },
    },
    openBenefit: {
      title: '{premiumBrand} 혜택 설정',
      benefit: '혜택',
      txt: '크리에이터 님의 {premiumBrand} 후원자에게 제공할 혜택을 작성해 주세요.(1개 이상)',
      buttonsAdd: '혜택 추가하기',
      guideList: {
        example1: '예시 1. 주 1회 창작 만화 업로드',
        example2: '예시 2. 주 3회 칼럼 연재(영상, 책, 만화 등의 주제)',
      },
      benefitKor: '혜택 작성(한국어)',
      benefitEng: '혜택 작성(영어)',
      benefitTha: '혜택 작성(태국어)',
      benefitSpa: '혜택 작성(스페인어)',
    },
    payment: {
      title: '결제 수단',
      subscribePrice: '후원 금액',
    },
    guide: {
      title: '{subscribeAccessCode} 유의사항',
    },
    terms: {
      title: '약관 동의',
    },
    openedBenefitGuide: {
      title: '{premiumBrand} 개설 해택',
      guide:
        '{creatorNickname} 님이 {subscribeGrade}를 개설하면 {subscribeGrade}의 후원금액의 90%를 수익으로 얻을 수 있습니다.',
      benefits: ['안정적인 수입', '팬들과의 소통', 'NFT 제작 기회'],
    },
  },
  actions: {
    support: '{subscribeGrade} 후원하기',
    supporting: '{subscribeGrade} 후원중',
    open: '{subscribeGrade} 개설하기',
    modify: '{subscribeGrade} 설정 저장',
    subscribeCancel: '{subscribeGrade} 해지 신청',
    subscribeContinue: '계속 이용',
    refund: '{subscribeGrade} 환불 신청',
    cancel: '취소',
  },
  errorMessage: {
    requireCreatorInfo: {
      title: '탈퇴 했거나 없는 크리에이터입니다.',
      message: '다른 크리에이터를 찾아주시기 바랍니다.',
      actionLabel: '뒤로가기',
    },
    requireSignIn: {
      title: '로그인이 필요한 페이지 입니다.',
      message: '로그인 하시겠습니까?',
      actionLabel: '로그인하기',
    },
    notEqualCreatorUserSn: {
      title: '{subscribeAccessCode} 할 수 없습니다.',
      message: '크리에이터 본인만 {subscribeGrade} 개설 할 수 있습니다.',
      actionLabel: '뒤로가기',
    },
    yetSubscribeService: {
      title: '{subscribeAccessCode} 할 수 없습니다.',
      message: '크리에이터가 {subscribeGrade} 개설하지 않은 상태입니다.',
      actionLabel: '뒤로가기',
    },
    requireInfo: {
      title: '필수 정보를 조회 할 수 없습니다.',
      message: '다시 시도해 주세요.',
      actionLabel: '다시 시도하기',
    },
    requireDifUser: {
      title: '{subscribeAccessCode} 할 수 없습니다.',
      message: '크리에이터 본인 자신을 {subscribeAccessCode} 할 수 없습니다.',
      actionLabel: '뒤로가기',
    },
    alreadySubscribeSupport: {
      title: '이미 {subscribeGrade} 후원중입니다.',
      message: '{expireDate} 까지 {subscribeGrade} 이용 가능합니다.',
      actionLabel: '뒤로가기',
    },
    alreadySubscribeNext: {
      title: '{subscribeGrade} 후원 할 수 없습니다.',
      message: '이미 다음 회차의 {subscribeGrade} 후원중입니다.',
      actionLabel: '뒤로가기',
    },
    unableSubscribeNext: {
      title: '이미 {subscribeGrade} 중입니다.',
      message:
        '다음 회차의 {subscribeGrade} 후원은 만료 3일 전부터 가능합니다.',
      actionLabel: '뒤로가기',
    },
  } as {
    [Property in CreatorSubscribeAccessStatus]: CreatorSubscribeErrorMessage
  },
  success: {
    opened: {
      open: '{subscribeGrade} 개설 되었습니다.',
      modify: '{subscribeGrade} 설정 저장 되었습니다.',
    },
  },
  apiError: {
    support: {
      5300: '크리에이터 {subscribeGrade} 멤버십 프로그램 정보가 없습니다.',
      6001: 'NFT Checkout Page 호출이 실패 하였습니다.',
    },
    opened: {
      passBenefits: '혜택 내용은 2자 이상 입력 하셔야 합니다.',
      open: '{subscribeGrade} 개설하기에 실패했습니다. 잠시 후 다시 시도해 주세요.',
      modify:
        '{subscribeGrade} 설정 저장에 실패했습니다. 잠시 후 다시 시도해 주세요.',
    },
  },
  supportPayProcess: {
    success: {
      title: '알림',
      message: '{subscribeGrade} 후원이 완료되었습니다.',
    },
    fail: {
      title: '알림',
      message:
        '{subscribeGrade} 후원이 완료되지 않았습니다.</br>다시 시도하세요.</br>사유: {failMessage}',
      failMessage: {
        fail: '{subscribeGrade} 후원이 완료되지 않았습니다.</br>다시 시도하세요.',
        requireRetry: '서비스가 원할하지 않습니다. 잠시 후 다시 시도해 주세요.',
        serverTimeout:
          '결제 시간을 초과하였습니다. {subscribeGrade} 후원을 다시 시도해주세요.',
        clientTimeout: `결제 중 문제가 발생하였습니다. 다시 한 번 시도해주세요.<br /><br />문의사항은 <a href="mailto:${corporationInfo.infos.email}">${corporationInfo.infos.email}</a>으로 연락바랍니다.`,
      },
    },
    loading: '결제 정보 조회 중입니다.',
  },
  expiredNotification: {
    title:
      '{creatorNickname} 님의 {subscribeGrade} 후원 혜택이 만료되었습니다.',
    description:
      '{subscribeGrade} 콘텐츠를 다음달에도 계속 이용을 원하실 경우, {subscribeGrade} 후원을 다시 신청해주세요.',
    support: '{subscribeGrade} 후원하기',
    confirm: '확인',
  },
}
