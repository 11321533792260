<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import type { NotificationsPanelProps } from '@components/NovaHeaderNotificationsPanel/NovaHeaderNotificationsPanel.types'

const { gtEvent } = useGoogleTag()
const props = defineProps<NotificationsPanelProps>()
const etcStore = useEtcStore()
const layoutStore = useLayoutStore()
const panelContainer = ref<HTMLElement | null>(null)
const isOpen = computed(() =>
  layoutStore.layers.includes(LayerType.NOTIFICATION_PANEL)
)

onClickOutside(panelContainer, (evt) => {
  const clickedToggleButton =
    (evt.target as HTMLElement)
      .closest('.toggle-panel-button')
      ?.getAttribute('toggle-panel') === props.panelName

  if (!isOpen.value || clickedToggleButton) return
  layoutStore['layer:close'](LayerType.NOTIFICATION_PANEL)
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '알림 패널 닫기',
    eventLabel: 'onClickOutside',
    eventSlot: '',
    eventI18nAddr: '',
    eventComponent: 'Layer',
  })
})

const handleReadAllAlarms = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '알림 패널 > 새 알림 모두 확인',
    eventLabel: 'check',
    eventSlot: `${etcStore.newAlarmCount === 0 ? '새 알림 없음' : '모두 확인'}`,
    eventI18nAddr: 'check',
    eventComponent: 'Button',
  })
  if (etcStore.newAlarmCount === 0) return
  await etcStore.putAlarmItem({ type: 'all', userNtcnGroupId: '' })
}

const handleOnGoNotificationSetting = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '알림 패널 > 설정',
    eventLabel: 'setting',
    eventSlot: '알림 패널',
    eventI18nAddr: 'setting',
    eventComponent: 'Button',
  })
  layoutStore['layer:close'](LayerType.NOTIFICATION_PANEL)
  layoutStore['layer:open'](LayerType.NOTIFICATION_SETTING_PANEL)
}
</script>

<template>
  <div ref="panelContainer" :class="['notifications-panel', { on: isOpen }]">
    <div
      class="dim"
      @click="() => layoutStore['layer:close'](LayerType.NOTIFICATION_PANEL)"
    />

    <NovaBoxBase :floating="true" class="notifications-panel-inner" :round="0">
      <div class="notifications-control-bar">
        <span class="label">
          {{ $t('notifications') }}({{ etcStore.newAlarmCount }})
        </span>

        <div class="controls">
          <NovaButtonIcon
            :icon="{ type: 'outline', name: 'check' }"
            :theme="'square-bg'"
            :size="20"
            :fill-icon="true"
            @click="handleReadAllAlarms"
          />

          <NovaButtonIcon
            :icon="{ type: 'outline', name: 'setting' }"
            :theme="'square-bg'"
            :size="20"
            :fill-icon="true"
            @click="handleOnGoNotificationSetting"
          />
        </div>
      </div>

      <NovaHeaderNotificationTabs v-if="isOpen" />
    </NovaBoxBase>
  </div>
</template>

<style lang="scss" scoped>
.notifications-panel {
  position: absolute;
  top: calc(74px + 16px);
  right: 16px;
  display: flex;
  justify-content: flex-end;
  width: $right-side-bar-width;
  opacity: 0;
  @include transition(top 0.2s ease-in-out, opacity 0.2s ease-in-out);
  pointer-events: none;
  z-index: $z-index-system-panel;

  &.on {
    top: 74px;
    opacity: 1;
    pointer-events: auto;
  }

  > .dim {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: hex-to-rgba($color-black, 0.65);
    opacity: 0;
    pointer-events: none;
    @include transition(opacity 0.2s ease-in-out);
    z-index: -1;
  }

  .notifications-panel-inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
    overflow: hidden;

    .notifications-control-bar {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;

      > .label {
        flex-grow: 1;
        @include text-style($text-body-14-bold);
        color: $color-text-2;
      }

      > .controls {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        gap: 6px;
      }
    }

    .notification-list-box {
      overflow: hidden;
      height: 400px;
    }
  }

  @include mobile {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;

    &.on {
      top: 0;

      > .dim {
        opacity: 1;
        pointer-events: auto;
      }
    }

    > .notifications-panel-inner {
      max-width: $mobile-side-panel-max-width;
      height: 100%;
      border-radius: 0;
      @include transition(all 0.2s ease-in-out);

      .notification-list-box {
        flex-grow: 1;
        height: 100%;
        max-height: initial;
      }
    }
  }
}
</style>
