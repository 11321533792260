import { commonError } from './commonError'
import type { FundTabType } from '@components/NovaLayoutFundHeader/NovaLayoutFundHeader.types'

/**
 * 펀드
 */
export const fund = {
  page: {
    header: {
      tabs: {
        comingSoon: 'Coming Soon',
        history: 'Fund History',
        gaudiFund: 'Gaudi Fund',
      } as { [Property in FundTabType]: string },
    },
  },
  project: {
    season: 'Web3 Creator Fund_{month} {year}',
    pick: 'Pick',
    support: 'SUPPORT',
    more: 'MORE',
    messages: {
      terminate: 'Project of the Month has concluded.',
      ready: 'We are preparing for Project of the Month',
      empty: 'There are no Project of the Month.',
      voteTally: 'We are currently verifying the vote count.',
      error: commonError.network.message,
    },
  },
  histories: {
    fundNo: 'No.{fundNo}',
    creatorNickname: '{creatorNickname}',
    message: {
      empty: 'There is no Fund history list.',
      error: commonError.network.message,
    },
  },
  start: {
    title: 'Web3 Creator Fund',
    prevProject: {
      fundNo: 'No.{fundNo} Web3 Creator Fund',
      title: 'Project of {month} {year}',
      action: 'View Project Details',
    },
    nextProject: {
      title: "Who's the next star of the Fund?",
      period: 'Application Period:',
      action: 'Apply for the project',
    },
  },
  end: {
    title: 'Web3 Creator Fund',
    subTitle: 'The submitted project is currently under review.',
  },
  projectDetail: {
    message: {
      error: commonError.network.message,
    },
  },
  voteDialog: {
    title: 'Would you like to vote for {creatorNickname}?',
    message: '*You can only vote once per project.',
    actions: {
      negative: 'Cancel',
      positive: 'Vote',
    },
  },
  voteRequest: {
    error: {
      8045: 'An error occurred while processing your vote. Please try again later.',
      8046: 'An error occurred while processing the vote cancellation. Please try again later.',
      8047: "It's not the voting period.",
      default: commonError.network.message,
    },
    success: 'The vote has been completed.',
  },
  gaudiFund: {
    gaudiheader: 'GAUDI FUND The Moon Labs X Gaudi Knowledge Association',
    gaudiFundImage: 'GAUDI FUND',
    fundMessage: {
      theMoonLabs: 'The Moon Labs X Gaudi Knowledge Association',
      theMoonLabsMessage:
        'The Moon Labs has signed an exclusive global digital partnership agreement with Gaudi Knowledge Association and is collaborating on various web3 digital businesses related to Gaudi.',
      lmnova: 'GAUDI Fund by I LIKE LM',
      lmnovaMessage:
        "I LIKE LM will accumulate Gaudi funds every month to support creators who carry on Gaudi's vision and to drive various creative projects. Through the Gaudi Fund, I LIKE LM will organize diverse events not only for professionals and those interested in Gaudi but also for a new generation, especially young students, to introduce Gaudi's values. We hope that many people can feel Gaudi's vision through I LIKE LM.<br/><br/>The ongoing projects will be documented as content and uploaded to I LIKE LM, so please check them out on the Creator Home!",
      lmnovaGaudi: 'Go to Gaudi Knowledge’s Creator Home',
      plan: 'GAUDI Fund Operation Plan',
      planMessage: {
        planTitle1: {
          title:
            'Gaudi-Specific Research and Education Institution Establishment',
          message1:
            'Expanding Gaudi Knowledge Association’s educational outreach and improving quality through adopting digital technology',
          message2:
            "Developing online courses, masterclasses, and programs on Gaudi's unique methodology and converting them into NFTs.",
          message3:
            "Compiling and categorizing Gaudi's buildings, biography, and comprehensive resources to establish Gaudipedia.",
        },
        planTitle2: {
          title: 'Unfinished Gaudi’s work and design study project',
          message1:
            'Working on the digital rendering of the Colonia Güell church with Bea Frois and Gaudi expert team',
          message2:
            'Hotel Attraction study and digital rendering led by Gaudi expert, Federico Luis',
          message3:
            'Misiones de Tanger study guided by Gaudi expert, David Afonso',
        },
        planTitle3: {
          title: 'Bringing Gaudi to the newer generations',
          message1:
            "Led by Gaudi expert Bethany Koby, developing courses and educational programs on Gaudi's methodology for young audiences and students.",
        },
        planTitle4: {
          title: 'Gaudi’s AI',
          message1:
            'Gaudi Graph psychology study led by Gaudi expert, Mariona Grau',
          message2:
            'Laying the foundation for developing a ‘Gaudi AI’ through a structural study about the genius mind.',
        },
      },
    },
    roadmap: "GAUDI FUND's ROADMAP",
  },
  comingSoon: {
    title: 'Fund Renewal Notice',
    message:
      "<strong>Web3 Creator Fund will soon reopen after renewal</strong> with enhancements<span class='pc-br'></span> to better empower the creative journeys of I LIKE LM creators.",
  },
}
