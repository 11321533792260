// import { subscribe } from './subscribe'
import type {
  CreatorCommentOrderBy,
  CreatorContentsOrderBy,
  CreatorRewardsOrderBy,
  StatisticsOrderBy,
  MyRecommendPostType,
} from '@store'
import type { CreatorHomeTabType } from '@components/NovaBoxCreatorTopHeader/NovaBoxCreatorTopHeader.types'

/**
 * 크리에이터 홈
 */
export const creatorMyPage = {
  home: {
    desc: '소개',
    introductionPlaceholder: '내용을 입력하세요.',
    introductionEmpty: '소개 내용이 없습니다.',
    introductionModifyFail: '소개를 저장할 수 없습니다. 다시 시도해 주세요.',
    introductionModifySuccess: '소개를 저장하였습니다.',

    link: '링크',
    linkEmpty: '링크가 없습니다.',
    linkTitlePlaceholder: '링크 제목',
    linkUrlPlaceholder: '링크 추가',
    linkRemoveConfirmTitle: '링크 삭제',
    linkRemoveConfirmMessage: '링크를 삭제하시겠습니까?',

    linkValidFail: '링크 제목, 링크 추가 필드를 완성해 주세요.',
    linkAddSuccess: '링크를 추가하였습니다.',
    linkAddFail: '링크를 추가할 수 없습니다. 다시 시도해 주세요.',
    linkSaveSuccess: '링크를 저장하였습니다.',
    linkSaveFail: '링크를 저장할 수 없습니다. 다시 시도해 주세요.',
    linkRemoveSuccess: '링크를 삭제하였습니다.',
    linkRemoveFail: '링크를 삭제할 수 없습니다. 다시 시도해 주세요.',
  },
  profile: {
    title: '프로필 정보',
    placeholder: {
      nickname: '사용할 닉네임을 입력해주세요.',
      content: '프로필내용',
    },
    button: {
      cancel: '취소',
      save: '저장',
    },
    dialog: {
      title: '알림',
      initImage:
        '이미지 삭제 시 기본 설정 아바타로 이미지가 변경됩니다. 삭제하시겠습니까?',
      doubleNickname:
        '동일한 닉네임이 사용중입니다. 다른 닉네임을 사용해 주세요.',
      noImage: '사용자 프로필이 없습니다.',
    },
    toastMessage: {
      imgError2005: '등록할 수 없는 확장자 입니다.',
      imgError: '이미지 업로드에 실패했습니다. 잠시후 다시 시도해주세요.',
    },
  },
  tabMenu: {
    home: '홈',
    contents: '포스트',
    // TODO: 앱 버전 릴리즈를 위해 기능 숨김
    // subscribeGrade: subscribe.premiumBrand,
    guestbook: '방명록',
    notice: '공지사항',
    reward: '리워드',
    donation: '후원',
    activity: '내 활동',
    statistics: '통계',
    collections: '마이 컬렉션',
  } as { [Property in CreatorHomeTabType]: string },
  sort: {
    contents: {
      newest: '최신순',
      oldest: '과거순',
      hot: '인기순',
    } as { [Property in CreatorContentsOrderBy]: string },
    comment: {
      newest: '최신순',
      oldest: '과거순',
    } as { [Property in CreatorCommentOrderBy]: string },
    reward: {
      newest: '최신순',
      oldest: '과거순',
      rewardQty: '리워드크기순',
    } as { [Property in CreatorRewardsOrderBy]: string },
    stats: {
      D: '어제',
      W: '지난주',
      M: '지난달',
    } as { [Property in StatisticsOrderBy]: string },
  },
  panel: {
    reward: {
      title: '총 리워드',
      empty: '리워드 지급 금액이 없습니다.',
    },
    post: {
      public: '공개',
      private: '비공개',
    },
    comment: '총 댓글',
    recommend: {
      get: '추천받은 포스트',
      set: '추천 포스트',
    } as { [Property in MyRecommendPostType]: string },
    scrap: '총 스크랩',
  },
  posts: {
    active: '활동내역',
    reward: {
      user: '주간 활동 리워드가 지급되었습니다.',
      post: '주간 게시글 리워드가 지급되었습니다.',
    },
    toastMessage: {
      creatorContentsFilterLessOne: '하나 이상의 체크박스를 선택해야 합니다.',
    },
  },
}
