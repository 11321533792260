/**
 * 알림 권한
 */
export const notificationPermission = {
  actions: {
    cancel: '취소',
    ok: '확인',
    requestPermissions: '권한 요청',
    config: '설정',
    notNow: '나중에 하기',
  },
  modals: {
    beforeRequest: {
      title: '알림 설정',
      message: '사이트 내 새로운 활동 반응에 대해 알려드립니다.',
    },
  },
}
