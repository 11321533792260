import * as BlockchainType from './type'
import BlockchainService from './service'
import { initBlockchainState } from './init'

export * from './init'
export * from './type'
export const useBlockchainStore = defineStore('blockchain', () => {
  const balance = ref(initBlockchainState.balance)

  /**
   * 사용자 LMC 잔고조회(벨런스조회)
   * @param payload
   */
  const fetchBalance = async (payload: BlockchainType.BalancePayload) => {
    const { data } = await BlockchainService.fetchBalance(payload)
    balance.value = data
  }

  return {
    balance,
    fetchBalance,
  }
})
