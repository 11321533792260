import {
  SuperMoonApplyAvailableStatus,
  type Profile,
  type SuperMoonApplyStatus,
} from '@store'

interface useSuperMoonApplyAvailableStatus {
  status: SuperMoonApplyStatus | null | undefined
  profile?: Profile
}

export const useSuperMoonApplyAvailableStatus = ({
  status,
  profile,
}: useSuperMoonApplyAvailableStatus): SuperMoonApplyAvailableStatus => {
  const mypageStore = useMyPageStore()
  const _status = status || null
  // 1. 입주 신청 필수 정보 존재 여부 판단
  if (!_status) {
    return SuperMoonApplyAvailableStatus.UNAVAILABLE_REQUIRE_CONDITIONS
  }
  // 2. 로그인 여부 판단
  if (!profile) return SuperMoonApplyAvailableStatus.UNAVAILABLE_REQUIRE_SIGN_IN
  if (mypageStore.applyCreatorStatus === 'requireSignin')
    return SuperMoonApplyAvailableStatus.UNAVAILABLE_REQUIRE_SIGN_IN
  // 3. 이미 신청한 사용자
  if (_status.fstvlApprtcAt === 'Y')
    return SuperMoonApplyAvailableStatus.UNAVAILABLE_ALREADY_APPLIED
  // 4. 서버에서 판단해서 보내주는 신청 불가
  if (_status.applicationAvailableAt === 'N')
    return SuperMoonApplyAvailableStatus.UNAVAILABLE_APPLIED
  // 5. 신청 가능
  return SuperMoonApplyAvailableStatus.AVAILABLE_APPLY
}
