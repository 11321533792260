<script setup lang="ts">
import type { AppType } from '@bridge'

const appStore = useAppStore()
const { appType, currentVer, appBuildNo } = storeToRefs(appStore)
const { cookieNames, getCookie, setCookie, deleteCookie } = useCookies()
const { checkVersion } = useCheckVersion()

// const isApp = ref(!!appType.value)
const appMode = ref(!!appType.value)
const appInfoOverride = reactive({
  appType: {
    value: appType.value || 'null',
    error: false,
    errorMessage: 'appType 이 AOS, IOS 일때만 앱 모드로 전환할 수 있습니다.',
  },
  currentVer: {
    value: currentVer.value || 'null',
    error: false,
    errorMessage: 'Semantic Versioning 팬턴이 아닙니다.',
  },
  appBuildNo: {
    value: appBuildNo.value || 'null',
    error: false,
    errorMessage: 'Semantic Versioning 팬턴이 아닙니다.',
  },
})

// 앱 정보 유효성 검사
const validAppInfo = {
  appType: (appType: string) =>
    ['IOS', 'AOS'].findIndex((item) => item === appType) !== -1,
  currentVer: (currentVer: string) => checkVersion(currentVer),
  appBuildNo: (appBuildNo: string) => checkVersion(appBuildNo),
}

watch(
  () => [
    appInfoOverride.appType.value,
    appInfoOverride.currentVer.value,
    appInfoOverride.appBuildNo.value,
  ],
  ([appTypeCur, currentVerCur, appBuildNoCur]) => {
    appInfoOverride.appType.error = !validAppInfo.appType(appTypeCur)
    appInfoOverride.currentVer.error = !validAppInfo.currentVer(currentVerCur)
    appInfoOverride.appBuildNo.error = !validAppInfo.appBuildNo(appBuildNoCur)
  },
  { immediate: true }
)

const appModeDescriptions = [
  '이미 앱 모드가 확인되면 강제 앱 모드는 전환 할 수 없습니다.',
  '강제 앱 모드 전환은 appType이 IOS, AOS 일때 가능합니다.',
  '필수값을 모두 채운 후 강제 앱 모드로 변경 가능합니다.',
  '프리셋을 지정하면 사전 정의된 값으로 강제 앱 모드를 시작할 수 있습니다.',
  '강제 앱 모드로 변경되면 모든 인터페이스 호출시 웹에서 임의의 리스폰스값을 생성합니다.',
]

// 앱모드 + 앱버전을 쿠키에서 가져와 강제 앱 모드 상태 업데이트
onBeforeMount(() => {
  appInfoOverride.appType.value = getCookie(cookieNames.APP_TYPE) || 'null'
  appInfoOverride.currentVer.value =
    getCookie(cookieNames.CURRENT_VERSION) || 'null'
  appInfoOverride.appBuildNo.value =
    getCookie(cookieNames.APP_BUILD_NO) || 'null'
})

// methods: 앱모드 전환을 위한 프리셋 지정
const handleOnAppModePreset = () => {
  appInfoOverride.appType.value = 'IOS'
  appInfoOverride.currentVer.value = '1.0.0'
  appInfoOverride.appBuildNo.value = '1.0.0'
}

// methods: 앱모드 토글
const handleOnToggleAppMode = () => {
  appMode.value = !appMode.value

  if (appMode.value) {
    appStore.forceAppModeTurnOn({
      appType: appInfoOverride.appType.value as AppType,
      currentVer: appInfoOverride.currentVer.value,
      appBuildNo: appInfoOverride.appBuildNo.value,
    })

    // set appType to cookie
    setCookie(cookieNames.APP_TYPE, appInfoOverride.appType.value, 1, 'days')

    // set currentVer to cookie
    setCookie(
      cookieNames.CURRENT_VERSION,
      appInfoOverride.currentVer.value,
      1,
      'days'
    )

    // set appBuildNo to cookie
    setCookie(
      cookieNames.APP_BUILD_NO,
      appInfoOverride.appBuildNo.value,
      1,
      'days'
    )
  } else {
    appStore.forceAppModeTurnOff()

    // delete appType from cookie
    deleteCookie(cookieNames.APP_TYPE)

    // delete currentVer from cookie
    deleteCookie(cookieNames.CURRENT_VERSION)

    // delete appBuildNo from cookie
    deleteCookie(cookieNames.APP_BUILD_NO)
  }
}
</script>

<template>
  <section class="dev-section">
    <h4 class="dev-section-title">
      <span>강제 앱 모드 전환</span>
      <span class="tools">
        <NovaButtonText
          :label="'프리셋 지정'"
          :theme="'secondary-gray'"
          :size="32"
          @click="handleOnAppModePreset"
        />
        <NovaButtonText
          :label="appMode ? '강제 앱 모드 끄기' : '강제 앱 모드 켜기'"
          :theme="'primary-blue'"
          :size="32"
          @click="handleOnToggleAppMode"
        />
      </span>
    </h4>

    <div class="section-inner">
      <table class="dev-table">
        <colgroup>
          <col style="width: 100px" />
        </colgroup>
        <thead>
          <tr>
            <th>키</th>
            <th>값</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="key in Object.keys(appInfoOverride)" :key="key">
            <td>{{ key }}</td>
            <td>
              <NovaInput
                v-model="appInfoOverride[key].value"
                :placeholder="`${key}을 입력해 주세요.`"
              />
              <p v-if="appInfoOverride[key].error" class="error-message">
                {{ appInfoOverride[key].errorMessage }}
              </p>
            </td>
          </tr>
        </tbody>
      </table>

      <NovaDescriptions :descriptions="appModeDescriptions" />
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import '@styles/base/dev-tools';
@include dev-tools-section;
</style>
