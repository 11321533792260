export const local = {
  APP_URL: 'http://localhost:3000',
  NAVER_VERIFY_ID: 'naver7ed88c2737cf756dfe9dfecede38911b',
}

export const development = {
  APP_URL: 'https://dev.lmnova.com',
  NAVER_VERIFY_ID: 'naver7ed88c2737cf756dfe9dfecede38911b',
}

export const production = {
  APP_URL: 'https://ilikelm.com',
  NAVER_VERIFY_ID: 'naver41628322d3c4f04b70b1cc90a6a5501c',
}
