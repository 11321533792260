import * as UserType from './type'
import UserApi from './url'
import { api } from '@api'
import type { NotificationMarketingSetting } from '@store/etc/type'

export default {
  /**
   * 서비스 신규가입: access token 발급 요청
   */
  postSignUp(payload: UserType.SignUpPayload) {
    return api.post<UserType.AuthToken>(UserApi.POST_SIGNUP, payload)
  },

  /**
   * 서비스 로그인: access token 발급 요청
   */
  postSignin(payload: UserType.SigninPayload) {
    return api.post<UserType.AuthToken>(UserApi.POST_SIGNIN, payload)
  },

  /**
   * 엑세스 토큰 갱신
   */
  postAccessTokenRefresh(payload: UserType.AuthToken) {
    return api.post<UserType.AuthToken>(
      UserApi.POST_ACCESS_TOKEN_REFRESH,
      null,
      {
        headers: {
          Authorization: `${payload.grantType} ${payload.refreshToken}`,
          'Auth-Status': 'REFRESH',
        },
      }
    )
  },

  /**
   * 사용자 통합정보 조회
   */
  fetchUserCommonInfo(payload: UserType.UserCommonInfoPayload) {
    return api.get<UserType.UserCommonInfo>(UserApi.FETCH_USER_COMMON_INFO, {
      params: payload,
    })
  },

  /**
   * 회원 탈퇴 : 탈퇴 가능한 회원 체크
   */
  fetchCheckRetireType() {
    return api.get<UserType.RetireType>(UserApi.CHECK_RETIRE_USER)
  },

  /**
   * 회원 탈퇴 : 문의글 남기기
   */
  sendRetireInquired(payload: UserType.RetireInquiredPayload) {
    return api.post(UserApi.SEND_RETIRE_INQUIRE, payload)
  },

  /**
   * 회원 탈퇴 : 비밀번호 체크
   */
  checkRetirePassword(payload: UserType.PasswordCheckPayload) {
    return api.post(UserApi.CHECK_RETIRE_PASSWORD, payload)
  },

  /**
   * 회원 탈퇴 : 탈퇴 진행
   */
  submitRetireProcess() {
    return api.post(UserApi.SUBMIT_RETIRE)
  },

  /**
   * 프라이빗 키 발급
   */
  fetchPrivateKey(payload: UserType.PrivateKeyPayload) {
    return api.get<UserType.PrivateKey>(
      UserApi.FETCH_PRIVATE_KEY.replace('{userSn}', String(payload.userSn))
    )
  },

  /**
   * 레퍼럴 코드로 프로필 정보 조회
   * @param payload
   */
  fetchProfileByReferral(payload: UserType.ProfileByReferralPayload) {
    return api.get<UserType.ProfileByReferral>(
      UserApi.FETCH_PROFILE_BY_REFERRAL_CODE,
      { params: payload }
    )
  },

  /**
   * 마케팅 동의 여부 변경
   */
  updateNotificationMarketing(payload: UserType.UpdateMarketingAgreePayload) {
    return api.put<NotificationMarketingSetting>(
      UserApi.PUT_MARKETING_AGREE_SETTINGS,
      payload
    )
  },

  /**
   * KYC 인증 상세(상태) 조회
   */
  fetchKycStatus(payload: UserType.KycStatusPayload) {
    return api.get<UserType.KycStatus>(UserApi.FETCH_KYC_STATUS, {
      params: payload,
    })
  },

  /**
   * 휴대전화 번호 수정
   */
  updatePhoneNumber(payload: UserType.UpdatePhoneNumberPayload) {
    return api.put(UserApi.UPDATE_PHONE_NUMBER, payload)
  },
}
