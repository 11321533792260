<script setup lang="ts">
import type { CellItemProps } from '@components/NovaHeaderCellItem/NovaHeaderCellItem.types'
import { CellItemTheme } from '@components/NovaHeaderCellItem/NovaHeaderCellItem.types'

const props = withDefaults(defineProps<CellItemProps>(), {
  labelSub: '',
  contents: '',
  clickAble: false,
  toolsPosition: 'middle',
  theme: CellItemTheme.DEFAULT,
  depth: 1,
})
const slots = useSlots()
const arrContents = computed(() => {
  // 1. contents is string
  if (typeof props.contents === 'string' && props.contents)
    return [props.contents]

  // 2. contents is array
  if (Array.isArray(props.contents) && props.contents.length)
    return props.contents.filter((content) => !!content)

  return []
})
</script>

<template>
  <div
    :class="[
      'cell-item',
      { 'click-able': clickAble },
      `tools-position-${toolsPosition}`,
      `theme-${theme}`,
      `depth-${depth}`,
    ]"
  >
    <div class="contents-wrap">
      <p class="label">
        {{ label }}<small v-if="labelSub">{{ labelSub }}</small>
      </p>

      <div v-if="slots['contents']" class="contents">
        <slot name="contents" />
      </div>

      <template v-if="contents">
        <template v-if="arrContents.length">
          <p
            v-for="(content, index) in arrContents"
            :key="index"
            v-dompurify-html="content"
            class="contents"
          />
        </template>
        <p v-else>-</p>
      </template>
    </div>

    <div v-show="slots['tools']" class="tools">
      <slot name="tools" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cell-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
  padding: 10px 0;

  &.theme {
    &-on-white-box {
      .contents-wrap {
        > .label,
        > .contents {
          color: $color-text-2;
        }
      }
    }
  }

  &.depth {
    &-1 {
    }

    &-2 {
      position: relative;
      padding-left: 24px;
      padding-top: 0;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        content: '⌞';
        font-size: 30px;
        width: 20px;
        height: 20px;
        color: $color-text-3;
      }
    }
  }

  &.click-able {
    cursor: pointer;
  }

  &.tools-position {
    &-top {
      align-items: flex-start;
    }
    &-middle {
      align-items: center;
    }
    &-bottom {
      align-items: flex-end;
    }
  }

  .contents-wrap {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    overflow: hidden;

    > .label {
      @include text-style($text-display-bold);
      line-height: 20px;
      color: $color-text-2;

      > small {
        font-weight: 400;
        margin-left: 6px;
        color: $color-text-3;
      }
    }

    > .contents {
      @include text-style($text-body-13-regular);
      line-height: 18px;
      color: $color-text-3;
      word-break: break-word;
    }
  }

  .tools {
    flex-shrink: 0;
    display: flex;
    gap: 6px;
  }
}
</style>
