import { commonError } from './commonError'

/**
 * 사용자 계정 정보
 */
export const accountPanel = {
  settings: {
    phoneNumber: {
      title: '휴대전화 번호',
      guide: '하이픈(-)을 포함하여 작성해 주세요.',
      placeholder: '010-0000-0000',
      action: '저장',
      valid: {
        empty: '휴대전화 번호를 입력해 주세요.',
      },
      messages: {
        error: {
          2006: '필수값이 누락되었습니다.',
          4000: '통합인증 서버와 통신이 끊어졌습니다.',
          4002: commonError['4002'].message,
          4003: '토큰 정보와 공통 사용자 일련번호가 일치하지 않습니다.',
          unknown: '알수 없는 오류가 발생하였습니다.',
        },
        success: '사용자의 휴대전화 번호가 {phoneNumber}로 변경되었습니다.',
      },
    },
    bankAccount: {
      title: '{subscribeGrade} 후원 정산 은행',
      forms: {
        bank: {
          label: '은행',
          guide: '본인 명의의 통장이어야 합니다.',
          placeholder: '은행 선택',
          valid: '은행을 선택해주세요.',
        },
        account: {
          label: '계좌 번호',
          guide: '하이픈(-)을 포함하여 작성해 주세요.',
          placeholder: '000-0000-0000-00',
          valid: '계좌 번호를 입력해 주세요.',
        },
        owner: {
          label: '예금주',
          guide: '',
          placeholder: '홍길동',
          valid: '예금주를 입력해 주세요.',
        },
      },
      messages: {
        empty: {
          fetchBanks:
            '"{keyword}"로 검색된 은행이 없습니다.<br />다른 은행명을 입력해 주세요.',
        },
        error: {
          fetchBanks: '은행 목록을 가져올 수 없습니다.',
          2006: '필수값이 누락되었습니다.',
          4010: '토큰 정보와 공통 사용자 일련번호가 일치하지 않습니다.',
          unknown: '알수 없는 오류가 발생하였습니다.',
        },
        success:
          '사용자의 {subscribeGrade} 후원 정산 은행 정보가 등록되었습니다.',
      },
    },
  },
}
