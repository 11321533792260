import { initUserState } from './init'
import * as UserType from './type'
import UserService from './service'
import type { UnionUserType } from '@store/types'
import { NotificationMarketingAgreeCode } from '@store/etc/type'

export * from './init'
export * from './type'
export const useUserStore = defineStore('user', () => {
  const blockchainStore = useBlockchainStore()
  const etcStore = useEtcStore()
  const myPageStore = useMyPageStore()
  const { $i18n } = useNuxtApp()
  const userCommonInfo = ref(initUserState.userCommonInfo)
  const count = ref(initUserState.count)
  const accessToken = ref(initUserState.accessToken)
  const refreshToken = ref(initUserState.refreshToken)
  const retireType = ref(initUserState.retireType)
  const privateKey = ref(initUserState.privateKey)
  const kycStatus = ref(initUserState.kycStatus)
  const blockAccessCount = ref(initUserState.blockAccessCount)
  const isSignIn = computed(
    () => !!userCommonInfo.value && !!myPageStore.profile
  )
  const user = computed(() =>
    !!userCommonInfo.value && !!myPageStore.profile
      ? {
          ...(userCommonInfo.value as UserType.UserCommonInfo),
          ...myPageStore.profile,
          ...(kycStatus.value as UserType.KycStatus),
          balance: blockchainStore.balance,
          unionUserType: (myPageStore.profile.acntTyCode +
            myPageStore.profile.cmtyUserSeCode) as UnionUserType,
        }
      : null
  )

  /**
   * @description 서비스 신규가입
   * @param payload
   */
  const postSignUp = async (payload: UserType.SignUpPayload) => {
    const { data } = await UserService.postSignUp(payload)
    const { cookieNames, setCookie } = useCookies()
    setCookie(cookieNames.AUTH_TOKEN, data, 30, 'days')
    setCookie(cookieNames.USER_INFO, payload, 30, 'days')
    return data
  }

  /**
   * @description access token 발급
   * @param payload
   */
  const postSignin = async (payload: UserType.SigninPayload) => {
    const { data } = await UserService.postSignin(payload)
    const { cookieNames, setCookie } = useCookies()
    setCookie(cookieNames.AUTH_TOKEN, data, 30, 'days')
    setCookie(cookieNames.USER_INFO, payload, 30, 'days')
    return data
  }

  /**
   * @description access token 재발급
   */
  const postAccessTokenRefresh = async (payload: UserType.AuthToken) => {
    const { data } = await UserService.postAccessTokenRefresh(payload)
    const { cookieNames, setCookie } = useCookies()
    setCookie(
      cookieNames.AUTH_TOKEN,
      data,
      data.accessTokenExpireTimestamp,
      'direct'
    )
    return data
  }

  /**
   * 사용자 통합정보 조회
   */
  const fetchUserCommonInfo = async (
    payload: UserType.UserCommonInfoPayload
  ) => {
    const { cookieNames, setCookie } = useCookies()
    const { data } = await UserService.fetchUserCommonInfo(payload)
    userCommonInfo.value = data
    if (data.popupList.length > 0) {
      const monthlyData = data.popupList.find(
        (popup) => popup.popupNtcnStdrId === 'CRW1'
      )
      const cookieData = {
        popupData: monthlyData,
        userSn: userCommonInfo.value.userSn,
      }
      if (monthlyData) {
        setCookie(cookieNames.REWARD_MONTHLY, cookieData, 30, 'days')
      }
    }
    return data
  }

  /**
   * @description 로그아웃시 사용자 정보 초기화 액션
   */
  const signOut = () => {
    const { deleteCookie } = useCookies()
    const { gtEvent } = useGoogleTag()
    gtEvent('userAction', {
      eventCategory: '사용자',
      eventAction: '로그 아웃',
      eventLabel: $i18n.t('sign.out'),
      eventSlot: '로그 아웃',
      eventI18nAddr: useKoreanTranslation('sign.out'),
      eventComponent: 'Button',
      userSn: userCommonInfo.value?.userSn,
      userCmmnSn: userCommonInfo.value?.userCmmnSn,
    })
    userCommonInfo.value = null
    myPageStore.updateProfile(null)
    deleteCookie('authToken')
    deleteCookie('userInfo')
    deleteCookie('refreshed')
    window.location.href = window.location.origin
  }

  /**
   * @description 사용자 탈퇴
   */
  // const signOff = () => {
  //   myPageStore.updateProfile(null)
  // }

  /**
   * @description 회원 탈퇴 : 탈퇴 가능한 회원 체크
   */
  const fetchCheckRetireType = async () => {
    try {
      const { data } = await UserService.fetchCheckRetireType()
      retireType.value = data.type
    } catch (err) {
      return Promise.reject(err)
    }
  }

  /**
   * @description 회원 탈퇴 : 문의글 남기기
   */
  const sendRetireInquired = async (
    payload: UserType.RetireInquiredPayload
  ) => {
    const value = {
      ...payload,
      bbsNttClSn: 3212,
      nttClNm: '회원탈퇴',
      userCmmnSn: userCommonInfo.value!.userCmmnSn,
    }
    await etcStore.sendQnA(value)
  }

  /**
   * @description 회원 탈퇴 : 비밀번호 체크
   */
  const checkRetirePassword = async (
    payload: UserType.PasswordCheckPayload
  ) => {
    try {
      const { data } = await UserService.checkRetirePassword(payload)
      retireType.value = data.type
    } catch (err) {
      return Promise.reject(err)
    }
  }

  /**
   * @description 회원 탈퇴 : 탈퇴 진행
   */
  const submitRetireProcess = () => UserService.submitRetireProcess()

  /**
   * 프라이빗 키 발급
   * @param payload
   */
  const fetchPrivateKey = async (payload: UserType.PrivateKeyPayload) => {
    const { data } = await UserService.fetchPrivateKey(payload)
    return data
  }

  /**
   * 레퍼럴 코드로 프로필 정보 조회
   */
  const fetchProfileByReferral = async (
    payload: UserType.ProfileByReferralPayload
  ) => {
    const { data } = await UserService.fetchProfileByReferral(payload)
    return data
  }

  /**
   * 마케팅 동의 여부 변경
   */
  const updateNotificationMarketing = async (
    payload: Omit<UserType.UpdateMarketingAgreePayload, 'userCmmnSn'>
  ) => {
    const { data } = await UserService.updateNotificationMarketing({
      ...payload,
      userCmmnSn: userCommonInfo.value?.userCmmnSn || -1,
    })

    // 1. 알림 설정 업데이트
    if (etcStore.notificationSetting?.marketing) {
      etcStore.notificationSetting.marketing = data
    }

    // 2. 사용자 정보 업데이트
    const updateItem = data.marketingAgreeList.find(
      (item) => item.stplatTyCode === payload.stplatTyCode
    )
    if (updateItem) {
      switch (payload.stplatTyCode) {
        case NotificationMarketingAgreeCode.EMAIL:
          userCommonInfo.value!.marketingEmailAgreeAt = updateItem.stplatAgreAt
          userCommonInfo.value!.marketingEmailAgreeDt = updateItem.stplatAgreDt
          break
        case NotificationMarketingAgreeCode.PUSH:
          userCommonInfo.value!.marketingPushAgreeAt = updateItem.stplatAgreAt
          userCommonInfo.value!.marketingPushAgreeDt = updateItem.stplatAgreDt
          break
        case NotificationMarketingAgreeCode.SMS:
          userCommonInfo.value!.marketingSMSAgreeAt = updateItem.stplatAgreAt
          userCommonInfo.value!.marketingSMSAgreeDt = updateItem.stplatAgreDt
          break
        default:
          break
      }
    }

    return payload
  }

  /**
   * KYC 인증 상세(상태) 조회
   */
  const fetchKycStatus = async (
    payload?: UserType.KycStatusPayload = { userCmmnSn: user.value?.userCmmnSn }
  ) => {
    const { data } = await UserService.fetchKycStatus(payload)
    kycStatus.value = data
    return data
  }

  /**
   * 휴대전화 번호 수정
   */
  const updatePhoneNumber = async (
    payload: UserType.UpdatePhoneNumberPayload
  ) => {
    try {
      await UserService.updatePhoneNumber(payload)
      userCommonInfo.value = {
        ...userCommonInfo.value!,
        mobile: payload.modifyMobile,
        mobileAt: 'Y',
        mobileNationCode: '',
        mobileNationNo: '',
      }
    } catch (err) {
      return Promise.reject(err)
    }
  }

  return {
    userCommonInfo,
    count,
    accessToken,
    refreshToken,
    retireType,
    privateKey,
    kycStatus,
    blockAccessCount,
    isSignIn,
    user,
    postSignUp,
    postSignin,
    postAccessTokenRefresh,
    fetchUserCommonInfo,
    signOut,
    fetchCheckRetireType,
    sendRetireInquired,
    checkRetirePassword,
    submitRetireProcess,
    fetchPrivateKey,
    fetchProfileByReferral,
    updateNotificationMarketing,
    fetchKycStatus,
    updatePhoneNumber,
  }
})
