<script setup lang="ts">
import type { ErrorProps } from './error.types'

const props = defineProps<ErrorProps>()
</script>

<template>
  <ClientOnly>
    <NovaBoxError :error="props.error" />
  </ClientOnly>
</template>
