/**
 * 크리에이터 메인 프로필
 */
export const creatorMainProfile = {
  editMainProfile: 'Edit Main Profile',
  profileImageGuide: 'Change the profile image of the main home.',
  categoryGuide: 'Change the category of the main home.',
  updateCreatorCategoryFail: 'Unable to change the category. Please try again.',
  updateCreatorCategorySuccess: 'Category is changed to {categoryName}.',
}
