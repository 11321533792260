<script setup lang="ts">
import type { NovaBoxPostContentsSnapshotContentsProps } from './NovaBoxPostContentsSnapshotContents.types'
import { bridgeToApp } from '@bridge'
import {
  iOSWhiteListException,
  POST_HTML_IMAGE_CONTAINER_CLASS,
} from '@configs'

const props = withDefaults(
  defineProps<NovaBoxPostContentsSnapshotContentsProps>(),
  {
    isResizingImages: false,
  }
)

const appStore = useAppStore()
const postContentsRef = ref<HTMLDivElement>()
const postContents = ref('')
const { replaceHtmlStrImageWithContainer, attachListenerToImages } =
  useHtmlContentsImageLazyLoading()

const replacePostContents = async () => {
  let replacedPostContents = String(props.contents)

  // 1. 전달 받은 포스트 콘텐츠의 앵커의 internal, external 타입 지정
  if (props.viewType === 'detail') {
    replacedPostContents = useReplaceStrHtmlAnchorTarget(replacedPostContents)
  }

  // 2. 전달 받은 포스트 컨텐츠의 이미지를 이미지 콘테이너에 담기
  replacedPostContents = replaceHtmlStrImageWithContainer({
    htmlStr: replacedPostContents,
    containerClassNames: [
      POST_HTML_IMAGE_CONTAINER_CLASS,
      props.isResizingImages ? 'is-resizing' : 'is-resized',
    ],
  })

  // 3. 전달 받은 포스트 콘텐츠의 iframe 을 og-preview 로 변경
  replacedPostContents = await useHtmlContentsIframeToOG({
    htmlStr: replacedPostContents,
  })

  // 4. 리플레이스된 콘텐츠 반영
  postContents.value = replacedPostContents
}

await replacePostContents()

onMounted(() => {
  // 1. 렌더링 하는 포스트가 상세 포스트이고 인앱(IOS) 일때 클릭 이벤트 인터셉트
  if (props.viewType === 'detail' && appStore.appType === 'IOS') {
    postContentsRef.value?.addEventListener('click', interceptClickEvent)
  }

  // 2. 이미지 load, error 헨들러 등록
  const imageContainers = postContentsRef.value?.querySelectorAll(
    '.' + POST_HTML_IMAGE_CONTAINER_CLASS
  )

  imageContainers &&
    attachListenerToImages({
      imageContainers,
      containerClassName: POST_HTML_IMAGE_CONTAINER_CLASS,
    })
})

onBeforeUnmount(() => {
  if (props.viewType === 'detail' && appStore.appType === 'IOS') {
    postContentsRef.value?.removeEventListener('click', interceptClickEvent)
  }
})

const interceptClickEvent = async (evt: Event) => {
  const target = (evt.target as Element)?.closest('a')
  const href = target?.getAttribute('href')

  if (href) {
    let incluedeWhiteList = false

    iOSWhiteListException.forEach((url) => {
      if (href.includes(url)) {
        incluedeWhiteList = true
      }
    })

    if (incluedeWhiteList) {
      evt.preventDefault()
      await bridgeToApp.openWeb({ url: href, type: 'external' })
    }
  }
}
</script>

<template>
  <div class="post-contents-by-editor">
    <div
      ref="postContentsRef"
      :class="['post-contents', { 'disabled-click': viewType !== 'detail' }]"
      v-html="postContents"
    />
  </div>
</template>

<style lang="scss" scoped>
@import '@styles/base/post-contents-snapshot';

// 스냅샷 기능으로 인해 전역 인스턴스화: post-contents-snapshot > mixin 스타일 수정할것
@include post-contents-by-editor;
</style>
