<script setup lang="ts">
import { ROUTES } from '@configs'

const { cookieNames, getCookie } = useCookies()
const route = useRoute()
const userStore = useUserStore()
const mypageStore = useMyPageStore()
const { show: modalShow } = useModal()

watch(
  () => [route.name, userStore.isSignIn],
  async ([curRouteName, curIsSignIn], [prevRouteName]) => {
    const routeName = useGetRouteName(String(curRouteName))
    if (
      routeName.includes(ROUTES.HOME.name) &&
      prevRouteName !== curRouteName &&
      curIsSignIn
    ) {
      await showNotiPremiumExpired()
    }
  }
)

onMounted(async () => {
  const routeName = useGetRouteName()
  if (routeName.includes(ROUTES.HOME.name) && userStore.isSignIn) {
    await showNotiPremiumExpired()
  }
})

const showNotiPremiumExpired = async () => {
  const isOffOneDay = getCookie<boolean>(cookieNames.OFF_NOTI_PREMIUM_EXPIRED)

  if (!isOffOneDay) {
    try {
      const { data } = await mypageStore.fetchPremiumExpiredList()

      if (data.length) {
        await modalShow(modalsName.MODAL_PREMIUM_EXPIRED, data)
      }
    } catch {}
  }
}
</script>

<template>
  <slot />
</template>
