/**
 * 엘리먼트가 뷰포트 내에서 완전히 또는 부분적으로 보이는지 확인
 *
 * @param {Element} el - 확인할 엘리먼트
 * @param {HTMLElement|null} scrollContainer - 오버플로우 스크롤이 있는 컨테이너 엘리먼트
 * @param {string} [direction='bottom'] - 확인할 방향, 'top' 또는 'bottom'
 * @returns {boolean} - 엘리먼트가 뷰포트 내에 있으면 true를 반환하고, 그렇지 않으면 false를 반환
 */
export const useDetectElementInViewport = (
  el: Element,
  scrollContainer: HTMLElement | null,
  direction: 'top' | 'bottom' = 'bottom'
): boolean => {
  const rect = el.getBoundingClientRect()
  const scrollRect = scrollContainer?.getBoundingClientRect()
  let result = false
  if (direction === 'top') {
    result =
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom < scrollRect!.top &&
      rect.right < scrollRect!.right
  } else {
    result =
      rect.top >= 0 &&
      rect.left >= 0 &&
      // rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      // rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      rect.bottom <= scrollContainer!.clientHeight + scrollRect!.top &&
      rect.right <= scrollContainer!.clientWidth + scrollRect!.right
  }

  return result
}
