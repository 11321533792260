import { default as applyC6JO9o5aafMeta } from "/codebuild/output/src2404643912/src/pages/apply-creator/apply.vue?macro=true";
import { default as indexRUK94OGtqCMeta } from "/codebuild/output/src2404643912/src/pages/apply-creator/index.vue?macro=true";
import { default as additionalfJkr6HqbL7Meta } from "/codebuild/output/src2404643912/src/pages/apply-rent/facility-[id]/additional.vue?macro=true";
import { default as complete88rrWscldiMeta } from "/codebuild/output/src2404643912/src/pages/apply-rent/facility-[id]/complete.vue?macro=true";
import { default as index7rXSRqf8OXMeta } from "/codebuild/output/src2404643912/src/pages/apply-rent/facility-[id]/index.vue?macro=true";
import { default as index1mkSKkKK3pMeta } from "/codebuild/output/src2404643912/src/pages/apply-rent/index.vue?macro=true";
import { default as formQeXJvV79lcMeta } from "/codebuild/output/src2404643912/src/pages/apply-super-moon/form.vue?macro=true";
import { default as index9kIoGDJgNRMeta } from "/codebuild/output/src2404643912/src/pages/apply-super-moon/index.vue?macro=true";
import { default as _91id_93Qtx2xzoB9aMeta } from "/codebuild/output/src2404643912/src/pages/board/event/[id].vue?macro=true";
import { default as index1Rs97Em1SVMeta } from "/codebuild/output/src2404643912/src/pages/board/event/index.vue?macro=true";
import { default as _91id_93bbkcQxCp6dMeta } from "/codebuild/output/src2404643912/src/pages/board/faq/[id].vue?macro=true";
import { default as indexeQQdATbzxZMeta } from "/codebuild/output/src2404643912/src/pages/board/faq/index.vue?macro=true";
import { default as _91id_93yApZWf92PPMeta } from "/codebuild/output/src2404643912/src/pages/board/news/[id].vue?macro=true";
import { default as index9Ena72Ws65Meta } from "/codebuild/output/src2404643912/src/pages/board/news/index.vue?macro=true";
import { default as _91id_93CaO9iMNG2EMeta } from "/codebuild/output/src2404643912/src/pages/board/notice/[id].vue?macro=true";
import { default as indexOi3chrOPTBMeta } from "/codebuild/output/src2404643912/src/pages/board/notice/index.vue?macro=true";
import { default as pay8GxO9ZAZzEMeta } from "/codebuild/output/src2404643912/src/pages/callback/pay.vue?macro=true";
import { default as signinKYkKl1W3GhMeta } from "/codebuild/output/src2404643912/src/pages/callback/signin.vue?macro=true";
import { default as signupXIx4enNaq9Meta } from "/codebuild/output/src2404643912/src/pages/callback/signup.vue?macro=true";
import { default as _91postId_93066ReIrNV4Meta } from "/codebuild/output/src2404643912/src/pages/creator/[id]/activities/[postId].vue?macro=true";
import { default as activities1R974IHzr0Meta } from "/codebuild/output/src2404643912/src/pages/creator/[id]/activities.vue?macro=true";
import { default as _91collectionId_93AoEhjKDyN8Meta } from "/codebuild/output/src2404643912/src/pages/creator/[id]/collections/[collectionId].vue?macro=true";
import { default as collectionsbKDmAqw4cEMeta } from "/codebuild/output/src2404643912/src/pages/creator/[id]/collections.vue?macro=true";
import { default as donationREraYA5d0OMeta } from "/codebuild/output/src2404643912/src/pages/creator/[id]/donation.vue?macro=true";
import { default as guestbookoNM6Wbnys3Meta } from "/codebuild/output/src2404643912/src/pages/creator/[id]/guestbook.vue?macro=true";
import { default as _91postId_937Tb8Mkrq2YMeta } from "/codebuild/output/src2404643912/src/pages/creator/[id]/index/[postId].vue?macro=true";
import { default as index4CcKzPLxflMeta } from "/codebuild/output/src2404643912/src/pages/creator/[id]/index.vue?macro=true";
import { default as _91noticeId_938rkKY5tfmbMeta } from "/codebuild/output/src2404643912/src/pages/creator/[id]/notice/[noticeId].vue?macro=true";
import { default as noticeNxgcdjuNqcMeta } from "/codebuild/output/src2404643912/src/pages/creator/[id]/notice.vue?macro=true";
import { default as _91postId_93J1jre1i1qMMeta } from "/codebuild/output/src2404643912/src/pages/creator/[id]/nova-plus/[postId].vue?macro=true";
import { default as indexNNtvyEGRhFMeta } from "/codebuild/output/src2404643912/src/pages/creator/[id]/nova-plus/index.vue?macro=true";
import { default as _91postId_93wghM5k281sMeta } from "/codebuild/output/src2404643912/src/pages/creator/[id]/posts/[postId].vue?macro=true";
import { default as indexcJrMOYyKD7Meta } from "/codebuild/output/src2404643912/src/pages/creator/[id]/posts/index.vue?macro=true";
import { default as rewardYvxiWTQ4HZMeta } from "/codebuild/output/src2404643912/src/pages/creator/[id]/reward.vue?macro=true";
import { default as statisticspNvPyzk9F9Meta } from "/codebuild/output/src2404643912/src/pages/creator/[id]/statistics.vue?macro=true";
import { default as sponsorshEIUcGX56nMeta } from "/codebuild/output/src2404643912/src/pages/creator/sponsors.vue?macro=true";
import { default as _91creatorUserSn_93UW3kt7OJCUMeta } from "/codebuild/output/src2404643912/src/pages/creator/subscribe/cancel/[creatorUserSn].vue?macro=true";
import { default as _91creatorUserSn_93DukGOFgaIKMeta } from "/codebuild/output/src2404643912/src/pages/creator/subscribe/opened/[creatorUserSn].vue?macro=true";
import { default as _91creatorUserSn_93cQxnH6mEwLMeta } from "/codebuild/output/src2404643912/src/pages/creator/subscribe/refund/[creatorUserSn].vue?macro=true";
import { default as _91creatorUserSn_933yno5KF3A5Meta } from "/codebuild/output/src2404643912/src/pages/creator/subscribe/support/[creatorUserSn].vue?macro=true";
import { default as alluM4r9Ua0fMMeta } from "/codebuild/output/src2404643912/src/pages/creators/all.vue?macro=true";
import { default as myzzdD9zvF4aMeta } from "/codebuild/output/src2404643912/src/pages/creators/my.vue?macro=true";
import { default as indexnKssUqOVROMeta } from "/codebuild/output/src2404643912/src/pages/daily-check/index.vue?macro=true";
import { default as _91id_93DEdwPijrN2Meta } from "/codebuild/output/src2404643912/src/pages/feed/[id].vue?macro=true";
import { default as indexjvrGrUJhNQMeta } from "/codebuild/output/src2404643912/src/pages/feed/index.vue?macro=true";
import { default as index1Ra92cKAKGMeta } from "/codebuild/output/src2404643912/src/pages/fund/gaudi-fund/index.vue?macro=true";
import { default as gaudi7HPWwVcUOoMeta } from "/codebuild/output/src2404643912/src/pages/fund/gaudi.vue?macro=true";
import { default as _91id_93X9PZvRY67FMeta } from "/codebuild/output/src2404643912/src/pages/fund/history/[id].vue?macro=true";
import { default as history4dKPj4YPyXMeta } from "/codebuild/output/src2404643912/src/pages/fund/history.vue?macro=true";
import { default as _91id_93vC6NLQXSntMeta } from "/codebuild/output/src2404643912/src/pages/fund/index/[id].vue?macro=true";
import { default as indexnY3X0YsHLZMeta } from "/codebuild/output/src2404643912/src/pages/fund/index.vue?macro=true";
import { default as _91id_93Qc98Tv58CgMeta } from "/codebuild/output/src2404643912/src/pages/fund/notice/[id].vue?macro=true";
import { default as indexIr0bASC4EJMeta } from "/codebuild/output/src2404643912/src/pages/fund/notice/index.vue?macro=true";
import { default as _91id_931wRAQOJgpOMeta } from "/codebuild/output/src2404643912/src/pages/hashtag/[id].vue?macro=true";
import { default as indexqCHNqwRkXRMeta } from "/codebuild/output/src2404643912/src/pages/hashtag/index.vue?macro=true";
import { default as index3jcqk7rYV8Meta } from "/codebuild/output/src2404643912/src/pages/index.vue?macro=true";
import { default as _91id_93GzsQN5EdlWMeta } from "/codebuild/output/src2404643912/src/pages/nova-plus/[id].vue?macro=true";
import { default as index697rPixpa5Meta } from "/codebuild/output/src2404643912/src/pages/nova-plus/index.vue?macro=true";
import { default as index0f5S7emcnrMeta } from "/codebuild/output/src2404643912/src/pages/oulim/index.vue?macro=true";
import { default as _91id_93Mk8FCcdyH7Meta } from "/codebuild/output/src2404643912/src/pages/post/[id].vue?macro=true";
import { default as _64_91id_93hqkWEIUxolMeta } from "/codebuild/output/src2404643912/src/pages/referral/@[id].vue?macro=true";
import { default as _91id_93iOcdjT3Xg2Meta } from "/codebuild/output/src2404643912/src/pages/search/detail/comment/[id].vue?macro=true";
import { default as indexQAEx4bd55FMeta } from "/codebuild/output/src2404643912/src/pages/search/detail/comment/index.vue?macro=true";
import { default as hashtagd3EkpgZk6wMeta } from "/codebuild/output/src2404643912/src/pages/search/detail/hashtag.vue?macro=true";
import { default as _91id_934zAfAMy8v0Meta } from "/codebuild/output/src2404643912/src/pages/search/detail/index/[id].vue?macro=true";
import { default as indexRzFI8bhJRMMeta } from "/codebuild/output/src2404643912/src/pages/search/detail/index/index.vue?macro=true";
import { default as _91id_93xZe47JHRKyMeta } from "/codebuild/output/src2404643912/src/pages/search/detail/nova-plus/[id].vue?macro=true";
import { default as indexgk5uK9330MMeta } from "/codebuild/output/src2404643912/src/pages/search/detail/nova-plus/index.vue?macro=true";
import { default as userOU2QDmKuEkMeta } from "/codebuild/output/src2404643912/src/pages/search/detail/user.vue?macro=true";
import { default as _91id_93RIiFevaZTbMeta } from "/codebuild/output/src2404643912/src/pages/search/index/[id].vue?macro=true";
import { default as indexJJTRT1DpIUMeta } from "/codebuild/output/src2404643912/src/pages/search/index.vue?macro=true";
import { default as _91id_93xw62aMHv6EMeta } from "/codebuild/output/src2404643912/src/pages/terms/[id].vue?macro=true";
import { default as _91postId_93GGzcpImcEhMeta } from "/codebuild/output/src2404643912/src/pages/user/[id]/activities/[postId].vue?macro=true";
import { default as activitiesD7Y4R3CaQOMeta } from "/codebuild/output/src2404643912/src/pages/user/[id]/activities.vue?macro=true";
import { default as _91collectionId_93lFa2uf7Z7HMeta } from "/codebuild/output/src2404643912/src/pages/user/[id]/collections/[collectionId].vue?macro=true";
import { default as collectionsK8bWUYkJY4Meta } from "/codebuild/output/src2404643912/src/pages/user/[id]/collections.vue?macro=true";
import { default as donationM5UbjiPl3dMeta } from "/codebuild/output/src2404643912/src/pages/user/[id]/donation.vue?macro=true";
import { default as _91postId_93vA7sMxDr7YMeta } from "/codebuild/output/src2404643912/src/pages/user/[id]/index/[postId].vue?macro=true";
import { default as indexr8n0WeqODhMeta } from "/codebuild/output/src2404643912/src/pages/user/[id]/index/index.vue?macro=true";
import { default as rewardSDlRBEdGZ7Meta } from "/codebuild/output/src2404643912/src/pages/user/[id]/reward.vue?macro=true";
import { default as indexml38Nc9A5FMeta } from "/codebuild/output/src2404643912/src/pages/user/sponsors/index.vue?macro=true";
import { default as _91id_93sIfnqsjRV0Meta } from "/codebuild/output/src2404643912/src/pages/w3cf/detail/[id].vue?macro=true";
import { default as index0eWe0JHz6vMeta } from "/codebuild/output/src2404643912/src/pages/w3cf/index.vue?macro=true";
export default [
  {
    name: "apply-creator-apply___ko",
    path: "/apply-creator/apply",
    meta: applyC6JO9o5aafMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/apply-creator/apply.vue").then(m => m.default || m)
  },
  {
    name: "apply-creator-apply___en",
    path: "/en/apply-creator/apply",
    meta: applyC6JO9o5aafMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/apply-creator/apply.vue").then(m => m.default || m)
  },
  {
    name: "apply-creator-apply___th",
    path: "/th/apply-creator/apply",
    meta: applyC6JO9o5aafMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/apply-creator/apply.vue").then(m => m.default || m)
  },
  {
    name: "apply-creator-apply___es",
    path: "/es/apply-creator/apply",
    meta: applyC6JO9o5aafMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/apply-creator/apply.vue").then(m => m.default || m)
  },
  {
    name: "apply-creator___ko",
    path: "/apply-creator",
    meta: indexRUK94OGtqCMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/apply-creator/index.vue").then(m => m.default || m)
  },
  {
    name: "apply-creator___en",
    path: "/en/apply-creator",
    meta: indexRUK94OGtqCMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/apply-creator/index.vue").then(m => m.default || m)
  },
  {
    name: "apply-creator___th",
    path: "/th/apply-creator",
    meta: indexRUK94OGtqCMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/apply-creator/index.vue").then(m => m.default || m)
  },
  {
    name: "apply-creator___es",
    path: "/es/apply-creator",
    meta: indexRUK94OGtqCMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/apply-creator/index.vue").then(m => m.default || m)
  },
  {
    name: "apply-rent-facility-id-additional___ko",
    path: "/apply-rent/facility-:id()/additional",
    meta: additionalfJkr6HqbL7Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/apply-rent/facility-[id]/additional.vue").then(m => m.default || m)
  },
  {
    name: "apply-rent-facility-id-additional___en",
    path: "/en/apply-rent/facility-:id()/additional",
    meta: additionalfJkr6HqbL7Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/apply-rent/facility-[id]/additional.vue").then(m => m.default || m)
  },
  {
    name: "apply-rent-facility-id-additional___th",
    path: "/th/apply-rent/facility-:id()/additional",
    meta: additionalfJkr6HqbL7Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/apply-rent/facility-[id]/additional.vue").then(m => m.default || m)
  },
  {
    name: "apply-rent-facility-id-additional___es",
    path: "/es/apply-rent/facility-:id()/additional",
    meta: additionalfJkr6HqbL7Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/apply-rent/facility-[id]/additional.vue").then(m => m.default || m)
  },
  {
    name: "apply-rent-facility-id-complete___ko",
    path: "/apply-rent/facility-:id()/complete",
    meta: complete88rrWscldiMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/apply-rent/facility-[id]/complete.vue").then(m => m.default || m)
  },
  {
    name: "apply-rent-facility-id-complete___en",
    path: "/en/apply-rent/facility-:id()/complete",
    meta: complete88rrWscldiMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/apply-rent/facility-[id]/complete.vue").then(m => m.default || m)
  },
  {
    name: "apply-rent-facility-id-complete___th",
    path: "/th/apply-rent/facility-:id()/complete",
    meta: complete88rrWscldiMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/apply-rent/facility-[id]/complete.vue").then(m => m.default || m)
  },
  {
    name: "apply-rent-facility-id-complete___es",
    path: "/es/apply-rent/facility-:id()/complete",
    meta: complete88rrWscldiMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/apply-rent/facility-[id]/complete.vue").then(m => m.default || m)
  },
  {
    name: "apply-rent-facility-id___ko",
    path: "/apply-rent/facility-:id()",
    meta: index7rXSRqf8OXMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/apply-rent/facility-[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "apply-rent-facility-id___en",
    path: "/en/apply-rent/facility-:id()",
    meta: index7rXSRqf8OXMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/apply-rent/facility-[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "apply-rent-facility-id___th",
    path: "/th/apply-rent/facility-:id()",
    meta: index7rXSRqf8OXMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/apply-rent/facility-[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "apply-rent-facility-id___es",
    path: "/es/apply-rent/facility-:id()",
    meta: index7rXSRqf8OXMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/apply-rent/facility-[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "apply-rent___ko",
    path: "/apply-rent",
    meta: index1mkSKkKK3pMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/apply-rent/index.vue").then(m => m.default || m)
  },
  {
    name: "apply-rent___en",
    path: "/en/apply-rent",
    meta: index1mkSKkKK3pMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/apply-rent/index.vue").then(m => m.default || m)
  },
  {
    name: "apply-rent___th",
    path: "/th/apply-rent",
    meta: index1mkSKkKK3pMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/apply-rent/index.vue").then(m => m.default || m)
  },
  {
    name: "apply-rent___es",
    path: "/es/apply-rent",
    meta: index1mkSKkKK3pMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/apply-rent/index.vue").then(m => m.default || m)
  },
  {
    name: "apply-super-moon-form___ko",
    path: "/apply-super-moon/form",
    meta: formQeXJvV79lcMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/apply-super-moon/form.vue").then(m => m.default || m)
  },
  {
    name: "apply-super-moon-form___en",
    path: "/en/apply-super-moon/form",
    meta: formQeXJvV79lcMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/apply-super-moon/form.vue").then(m => m.default || m)
  },
  {
    name: "apply-super-moon-form___th",
    path: "/th/apply-super-moon/form",
    meta: formQeXJvV79lcMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/apply-super-moon/form.vue").then(m => m.default || m)
  },
  {
    name: "apply-super-moon-form___es",
    path: "/es/apply-super-moon/form",
    meta: formQeXJvV79lcMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/apply-super-moon/form.vue").then(m => m.default || m)
  },
  {
    name: "apply-super-moon___ko",
    path: "/apply-super-moon",
    meta: index9kIoGDJgNRMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/apply-super-moon/index.vue").then(m => m.default || m)
  },
  {
    name: "apply-super-moon___en",
    path: "/en/apply-super-moon",
    meta: index9kIoGDJgNRMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/apply-super-moon/index.vue").then(m => m.default || m)
  },
  {
    name: "apply-super-moon___th",
    path: "/th/apply-super-moon",
    meta: index9kIoGDJgNRMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/apply-super-moon/index.vue").then(m => m.default || m)
  },
  {
    name: "apply-super-moon___es",
    path: "/es/apply-super-moon",
    meta: index9kIoGDJgNRMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/apply-super-moon/index.vue").then(m => m.default || m)
  },
  {
    name: "board-event-id___ko",
    path: "/board/event/:id()",
    meta: _91id_93Qtx2xzoB9aMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/board/event/[id].vue").then(m => m.default || m)
  },
  {
    name: "board-event-id___en",
    path: "/en/board/event/:id()",
    meta: _91id_93Qtx2xzoB9aMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/board/event/[id].vue").then(m => m.default || m)
  },
  {
    name: "board-event-id___th",
    path: "/th/board/event/:id()",
    meta: _91id_93Qtx2xzoB9aMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/board/event/[id].vue").then(m => m.default || m)
  },
  {
    name: "board-event-id___es",
    path: "/es/board/event/:id()",
    meta: _91id_93Qtx2xzoB9aMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/board/event/[id].vue").then(m => m.default || m)
  },
  {
    name: "board-event___ko",
    path: "/board/event",
    meta: index1Rs97Em1SVMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/board/event/index.vue").then(m => m.default || m)
  },
  {
    name: "board-event___en",
    path: "/en/board/event",
    meta: index1Rs97Em1SVMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/board/event/index.vue").then(m => m.default || m)
  },
  {
    name: "board-event___th",
    path: "/th/board/event",
    meta: index1Rs97Em1SVMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/board/event/index.vue").then(m => m.default || m)
  },
  {
    name: "board-event___es",
    path: "/es/board/event",
    meta: index1Rs97Em1SVMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/board/event/index.vue").then(m => m.default || m)
  },
  {
    name: "board-faq-id___ko",
    path: "/board/faq/:id()",
    meta: _91id_93bbkcQxCp6dMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/board/faq/[id].vue").then(m => m.default || m)
  },
  {
    name: "board-faq-id___en",
    path: "/en/board/faq/:id()",
    meta: _91id_93bbkcQxCp6dMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/board/faq/[id].vue").then(m => m.default || m)
  },
  {
    name: "board-faq-id___th",
    path: "/th/board/faq/:id()",
    meta: _91id_93bbkcQxCp6dMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/board/faq/[id].vue").then(m => m.default || m)
  },
  {
    name: "board-faq-id___es",
    path: "/es/board/faq/:id()",
    meta: _91id_93bbkcQxCp6dMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/board/faq/[id].vue").then(m => m.default || m)
  },
  {
    name: "board-faq___ko",
    path: "/board/faq",
    meta: indexeQQdATbzxZMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/board/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "board-faq___en",
    path: "/en/board/faq",
    meta: indexeQQdATbzxZMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/board/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "board-faq___th",
    path: "/th/board/faq",
    meta: indexeQQdATbzxZMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/board/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "board-faq___es",
    path: "/es/board/faq",
    meta: indexeQQdATbzxZMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/board/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "board-news-id___ko",
    path: "/board/news/:id()",
    meta: _91id_93yApZWf92PPMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/board/news/[id].vue").then(m => m.default || m)
  },
  {
    name: "board-news-id___en",
    path: "/en/board/news/:id()",
    meta: _91id_93yApZWf92PPMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/board/news/[id].vue").then(m => m.default || m)
  },
  {
    name: "board-news-id___th",
    path: "/th/board/news/:id()",
    meta: _91id_93yApZWf92PPMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/board/news/[id].vue").then(m => m.default || m)
  },
  {
    name: "board-news-id___es",
    path: "/es/board/news/:id()",
    meta: _91id_93yApZWf92PPMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/board/news/[id].vue").then(m => m.default || m)
  },
  {
    name: "board-news___ko",
    path: "/board/news",
    meta: index9Ena72Ws65Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/board/news/index.vue").then(m => m.default || m)
  },
  {
    name: "board-news___en",
    path: "/en/board/news",
    meta: index9Ena72Ws65Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/board/news/index.vue").then(m => m.default || m)
  },
  {
    name: "board-news___th",
    path: "/th/board/news",
    meta: index9Ena72Ws65Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/board/news/index.vue").then(m => m.default || m)
  },
  {
    name: "board-news___es",
    path: "/es/board/news",
    meta: index9Ena72Ws65Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/board/news/index.vue").then(m => m.default || m)
  },
  {
    name: "board-notice-id___ko",
    path: "/board/notice/:id()",
    meta: _91id_93CaO9iMNG2EMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/board/notice/[id].vue").then(m => m.default || m)
  },
  {
    name: "board-notice-id___en",
    path: "/en/board/notice/:id()",
    meta: _91id_93CaO9iMNG2EMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/board/notice/[id].vue").then(m => m.default || m)
  },
  {
    name: "board-notice-id___th",
    path: "/th/board/notice/:id()",
    meta: _91id_93CaO9iMNG2EMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/board/notice/[id].vue").then(m => m.default || m)
  },
  {
    name: "board-notice-id___es",
    path: "/es/board/notice/:id()",
    meta: _91id_93CaO9iMNG2EMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/board/notice/[id].vue").then(m => m.default || m)
  },
  {
    name: "board-notice___ko",
    path: "/board/notice",
    meta: indexOi3chrOPTBMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/board/notice/index.vue").then(m => m.default || m)
  },
  {
    name: "board-notice___en",
    path: "/en/board/notice",
    meta: indexOi3chrOPTBMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/board/notice/index.vue").then(m => m.default || m)
  },
  {
    name: "board-notice___th",
    path: "/th/board/notice",
    meta: indexOi3chrOPTBMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/board/notice/index.vue").then(m => m.default || m)
  },
  {
    name: "board-notice___es",
    path: "/es/board/notice",
    meta: indexOi3chrOPTBMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/board/notice/index.vue").then(m => m.default || m)
  },
  {
    name: "callback-pay___ko",
    path: "/callback/pay",
    meta: pay8GxO9ZAZzEMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/callback/pay.vue").then(m => m.default || m)
  },
  {
    name: "callback-pay___en",
    path: "/en/callback/pay",
    meta: pay8GxO9ZAZzEMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/callback/pay.vue").then(m => m.default || m)
  },
  {
    name: "callback-pay___th",
    path: "/th/callback/pay",
    meta: pay8GxO9ZAZzEMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/callback/pay.vue").then(m => m.default || m)
  },
  {
    name: "callback-pay___es",
    path: "/es/callback/pay",
    meta: pay8GxO9ZAZzEMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/callback/pay.vue").then(m => m.default || m)
  },
  {
    name: "callback-signin___ko",
    path: "/callback/signin",
    meta: signinKYkKl1W3GhMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/callback/signin.vue").then(m => m.default || m)
  },
  {
    name: "callback-signin___en",
    path: "/en/callback/signin",
    meta: signinKYkKl1W3GhMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/callback/signin.vue").then(m => m.default || m)
  },
  {
    name: "callback-signin___th",
    path: "/th/callback/signin",
    meta: signinKYkKl1W3GhMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/callback/signin.vue").then(m => m.default || m)
  },
  {
    name: "callback-signin___es",
    path: "/es/callback/signin",
    meta: signinKYkKl1W3GhMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/callback/signin.vue").then(m => m.default || m)
  },
  {
    name: "callback-signup___ko",
    path: "/callback/signup",
    meta: signupXIx4enNaq9Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/callback/signup.vue").then(m => m.default || m)
  },
  {
    name: "callback-signup___en",
    path: "/en/callback/signup",
    meta: signupXIx4enNaq9Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/callback/signup.vue").then(m => m.default || m)
  },
  {
    name: "callback-signup___th",
    path: "/th/callback/signup",
    meta: signupXIx4enNaq9Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/callback/signup.vue").then(m => m.default || m)
  },
  {
    name: "callback-signup___es",
    path: "/es/callback/signup",
    meta: signupXIx4enNaq9Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/callback/signup.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-activities___ko",
    path: "/creator/:id()/activities",
    meta: activities1R974IHzr0Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/activities.vue").then(m => m.default || m),
    children: [
  {
    name: "creator-id-activities-postId___ko",
    path: ":postId()",
    meta: _91postId_93066ReIrNV4Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/activities/[postId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "creator-id-activities___en",
    path: "/en/creator/:id()/activities",
    meta: activities1R974IHzr0Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/activities.vue").then(m => m.default || m),
    children: [
  {
    name: "creator-id-activities-postId___en",
    path: ":postId()",
    meta: _91postId_93066ReIrNV4Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/activities/[postId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "creator-id-activities___th",
    path: "/th/creator/:id()/activities",
    meta: activities1R974IHzr0Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/activities.vue").then(m => m.default || m),
    children: [
  {
    name: "creator-id-activities-postId___th",
    path: ":postId()",
    meta: _91postId_93066ReIrNV4Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/activities/[postId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "creator-id-activities___es",
    path: "/es/creator/:id()/activities",
    meta: activities1R974IHzr0Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/activities.vue").then(m => m.default || m),
    children: [
  {
    name: "creator-id-activities-postId___es",
    path: ":postId()",
    meta: _91postId_93066ReIrNV4Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/activities/[postId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "creator-id-collections___ko",
    path: "/creator/:id()/collections",
    meta: collectionsbKDmAqw4cEMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/collections.vue").then(m => m.default || m),
    children: [
  {
    name: "creator-id-collections-collectionId___ko",
    path: ":collectionId()",
    meta: _91collectionId_93AoEhjKDyN8Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/collections/[collectionId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "creator-id-collections___en",
    path: "/en/creator/:id()/collections",
    meta: collectionsbKDmAqw4cEMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/collections.vue").then(m => m.default || m),
    children: [
  {
    name: "creator-id-collections-collectionId___en",
    path: ":collectionId()",
    meta: _91collectionId_93AoEhjKDyN8Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/collections/[collectionId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "creator-id-collections___th",
    path: "/th/creator/:id()/collections",
    meta: collectionsbKDmAqw4cEMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/collections.vue").then(m => m.default || m),
    children: [
  {
    name: "creator-id-collections-collectionId___th",
    path: ":collectionId()",
    meta: _91collectionId_93AoEhjKDyN8Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/collections/[collectionId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "creator-id-collections___es",
    path: "/es/creator/:id()/collections",
    meta: collectionsbKDmAqw4cEMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/collections.vue").then(m => m.default || m),
    children: [
  {
    name: "creator-id-collections-collectionId___es",
    path: ":collectionId()",
    meta: _91collectionId_93AoEhjKDyN8Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/collections/[collectionId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "creator-id-donation___ko",
    path: "/creator/:id()/donation",
    meta: donationREraYA5d0OMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/donation.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-donation___en",
    path: "/en/creator/:id()/donation",
    meta: donationREraYA5d0OMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/donation.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-donation___th",
    path: "/th/creator/:id()/donation",
    meta: donationREraYA5d0OMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/donation.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-donation___es",
    path: "/es/creator/:id()/donation",
    meta: donationREraYA5d0OMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/donation.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-guestbook___ko",
    path: "/creator/:id()/guestbook",
    meta: guestbookoNM6Wbnys3Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/guestbook.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-guestbook___en",
    path: "/en/creator/:id()/guestbook",
    meta: guestbookoNM6Wbnys3Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/guestbook.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-guestbook___th",
    path: "/th/creator/:id()/guestbook",
    meta: guestbookoNM6Wbnys3Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/guestbook.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-guestbook___es",
    path: "/es/creator/:id()/guestbook",
    meta: guestbookoNM6Wbnys3Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/guestbook.vue").then(m => m.default || m)
  },
  {
    name: "creator-id___ko",
    path: "/creator/:id()",
    meta: index4CcKzPLxflMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/index.vue").then(m => m.default || m),
    children: [
  {
    name: "creator-id-index-postId___ko",
    path: ":postId()",
    meta: _91postId_937Tb8Mkrq2YMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/index/[postId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "creator-id___en",
    path: "/en/creator/:id()",
    meta: index4CcKzPLxflMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/index.vue").then(m => m.default || m),
    children: [
  {
    name: "creator-id-index-postId___en",
    path: ":postId()",
    meta: _91postId_937Tb8Mkrq2YMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/index/[postId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "creator-id___th",
    path: "/th/creator/:id()",
    meta: index4CcKzPLxflMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/index.vue").then(m => m.default || m),
    children: [
  {
    name: "creator-id-index-postId___th",
    path: ":postId()",
    meta: _91postId_937Tb8Mkrq2YMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/index/[postId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "creator-id___es",
    path: "/es/creator/:id()",
    meta: index4CcKzPLxflMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/index.vue").then(m => m.default || m),
    children: [
  {
    name: "creator-id-index-postId___es",
    path: ":postId()",
    meta: _91postId_937Tb8Mkrq2YMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/index/[postId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "creator-id-notice___ko",
    path: "/creator/:id()/notice",
    meta: noticeNxgcdjuNqcMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/notice.vue").then(m => m.default || m),
    children: [
  {
    name: "creator-id-notice-noticeId___ko",
    path: ":noticeId()",
    meta: _91noticeId_938rkKY5tfmbMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/notice/[noticeId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "creator-id-notice___en",
    path: "/en/creator/:id()/notice",
    meta: noticeNxgcdjuNqcMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/notice.vue").then(m => m.default || m),
    children: [
  {
    name: "creator-id-notice-noticeId___en",
    path: ":noticeId()",
    meta: _91noticeId_938rkKY5tfmbMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/notice/[noticeId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "creator-id-notice___th",
    path: "/th/creator/:id()/notice",
    meta: noticeNxgcdjuNqcMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/notice.vue").then(m => m.default || m),
    children: [
  {
    name: "creator-id-notice-noticeId___th",
    path: ":noticeId()",
    meta: _91noticeId_938rkKY5tfmbMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/notice/[noticeId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "creator-id-notice___es",
    path: "/es/creator/:id()/notice",
    meta: noticeNxgcdjuNqcMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/notice.vue").then(m => m.default || m),
    children: [
  {
    name: "creator-id-notice-noticeId___es",
    path: ":noticeId()",
    meta: _91noticeId_938rkKY5tfmbMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/notice/[noticeId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "creator-id-nova-plus-postId___ko",
    path: "/creator/:id()/nova-plus/:postId()",
    meta: _91postId_93J1jre1i1qMMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/nova-plus/[postId].vue").then(m => m.default || m)
  },
  {
    name: "creator-id-nova-plus-postId___en",
    path: "/en/creator/:id()/nova-plus/:postId()",
    meta: _91postId_93J1jre1i1qMMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/nova-plus/[postId].vue").then(m => m.default || m)
  },
  {
    name: "creator-id-nova-plus-postId___th",
    path: "/th/creator/:id()/nova-plus/:postId()",
    meta: _91postId_93J1jre1i1qMMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/nova-plus/[postId].vue").then(m => m.default || m)
  },
  {
    name: "creator-id-nova-plus-postId___es",
    path: "/es/creator/:id()/nova-plus/:postId()",
    meta: _91postId_93J1jre1i1qMMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/nova-plus/[postId].vue").then(m => m.default || m)
  },
  {
    name: "creator-id-nova-plus___ko",
    path: "/creator/:id()/nova-plus",
    meta: indexNNtvyEGRhFMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/nova-plus/index.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-nova-plus___en",
    path: "/en/creator/:id()/nova-plus",
    meta: indexNNtvyEGRhFMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/nova-plus/index.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-nova-plus___th",
    path: "/th/creator/:id()/nova-plus",
    meta: indexNNtvyEGRhFMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/nova-plus/index.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-nova-plus___es",
    path: "/es/creator/:id()/nova-plus",
    meta: indexNNtvyEGRhFMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/nova-plus/index.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-posts-postId___ko",
    path: "/creator/:id()/posts/:postId()",
    meta: _91postId_93wghM5k281sMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/posts/[postId].vue").then(m => m.default || m)
  },
  {
    name: "creator-id-posts-postId___en",
    path: "/en/creator/:id()/posts/:postId()",
    meta: _91postId_93wghM5k281sMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/posts/[postId].vue").then(m => m.default || m)
  },
  {
    name: "creator-id-posts-postId___th",
    path: "/th/creator/:id()/posts/:postId()",
    meta: _91postId_93wghM5k281sMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/posts/[postId].vue").then(m => m.default || m)
  },
  {
    name: "creator-id-posts-postId___es",
    path: "/es/creator/:id()/posts/:postId()",
    meta: _91postId_93wghM5k281sMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/posts/[postId].vue").then(m => m.default || m)
  },
  {
    name: "creator-id-posts___ko",
    path: "/creator/:id()/posts",
    meta: indexcJrMOYyKD7Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/posts/index.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-posts___en",
    path: "/en/creator/:id()/posts",
    meta: indexcJrMOYyKD7Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/posts/index.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-posts___th",
    path: "/th/creator/:id()/posts",
    meta: indexcJrMOYyKD7Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/posts/index.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-posts___es",
    path: "/es/creator/:id()/posts",
    meta: indexcJrMOYyKD7Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/posts/index.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-reward___ko",
    path: "/creator/:id()/reward",
    meta: rewardYvxiWTQ4HZMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/reward.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-reward___en",
    path: "/en/creator/:id()/reward",
    meta: rewardYvxiWTQ4HZMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/reward.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-reward___th",
    path: "/th/creator/:id()/reward",
    meta: rewardYvxiWTQ4HZMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/reward.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-reward___es",
    path: "/es/creator/:id()/reward",
    meta: rewardYvxiWTQ4HZMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/reward.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-statistics___ko",
    path: "/creator/:id()/statistics",
    meta: statisticspNvPyzk9F9Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/statistics.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-statistics___en",
    path: "/en/creator/:id()/statistics",
    meta: statisticspNvPyzk9F9Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/statistics.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-statistics___th",
    path: "/th/creator/:id()/statistics",
    meta: statisticspNvPyzk9F9Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/statistics.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-statistics___es",
    path: "/es/creator/:id()/statistics",
    meta: statisticspNvPyzk9F9Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/[id]/statistics.vue").then(m => m.default || m)
  },
  {
    name: "creator-sponsors___ko",
    path: "/creator/sponsors",
    meta: sponsorshEIUcGX56nMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/sponsors.vue").then(m => m.default || m)
  },
  {
    name: "creator-sponsors___en",
    path: "/en/creator/sponsors",
    meta: sponsorshEIUcGX56nMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/sponsors.vue").then(m => m.default || m)
  },
  {
    name: "creator-sponsors___th",
    path: "/th/creator/sponsors",
    meta: sponsorshEIUcGX56nMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/sponsors.vue").then(m => m.default || m)
  },
  {
    name: "creator-sponsors___es",
    path: "/es/creator/sponsors",
    meta: sponsorshEIUcGX56nMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/sponsors.vue").then(m => m.default || m)
  },
  {
    name: "creator-subscribe-cancel-creatorUserSn___ko",
    path: "/creator/subscribe/cancel/:creatorUserSn()",
    meta: _91creatorUserSn_93UW3kt7OJCUMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/subscribe/cancel/[creatorUserSn].vue").then(m => m.default || m)
  },
  {
    name: "creator-subscribe-cancel-creatorUserSn___en",
    path: "/en/creator/subscribe/cancel/:creatorUserSn()",
    meta: _91creatorUserSn_93UW3kt7OJCUMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/subscribe/cancel/[creatorUserSn].vue").then(m => m.default || m)
  },
  {
    name: "creator-subscribe-cancel-creatorUserSn___th",
    path: "/th/creator/subscribe/cancel/:creatorUserSn()",
    meta: _91creatorUserSn_93UW3kt7OJCUMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/subscribe/cancel/[creatorUserSn].vue").then(m => m.default || m)
  },
  {
    name: "creator-subscribe-cancel-creatorUserSn___es",
    path: "/es/creator/subscribe/cancel/:creatorUserSn()",
    meta: _91creatorUserSn_93UW3kt7OJCUMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/subscribe/cancel/[creatorUserSn].vue").then(m => m.default || m)
  },
  {
    name: "creator-subscribe-opened-creatorUserSn___ko",
    path: "/creator/subscribe/opened/:creatorUserSn()",
    meta: _91creatorUserSn_93DukGOFgaIKMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/subscribe/opened/[creatorUserSn].vue").then(m => m.default || m)
  },
  {
    name: "creator-subscribe-opened-creatorUserSn___en",
    path: "/en/creator/subscribe/opened/:creatorUserSn()",
    meta: _91creatorUserSn_93DukGOFgaIKMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/subscribe/opened/[creatorUserSn].vue").then(m => m.default || m)
  },
  {
    name: "creator-subscribe-opened-creatorUserSn___th",
    path: "/th/creator/subscribe/opened/:creatorUserSn()",
    meta: _91creatorUserSn_93DukGOFgaIKMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/subscribe/opened/[creatorUserSn].vue").then(m => m.default || m)
  },
  {
    name: "creator-subscribe-opened-creatorUserSn___es",
    path: "/es/creator/subscribe/opened/:creatorUserSn()",
    meta: _91creatorUserSn_93DukGOFgaIKMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/subscribe/opened/[creatorUserSn].vue").then(m => m.default || m)
  },
  {
    name: "creator-subscribe-refund-creatorUserSn___ko",
    path: "/creator/subscribe/refund/:creatorUserSn()",
    meta: _91creatorUserSn_93cQxnH6mEwLMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/subscribe/refund/[creatorUserSn].vue").then(m => m.default || m)
  },
  {
    name: "creator-subscribe-refund-creatorUserSn___en",
    path: "/en/creator/subscribe/refund/:creatorUserSn()",
    meta: _91creatorUserSn_93cQxnH6mEwLMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/subscribe/refund/[creatorUserSn].vue").then(m => m.default || m)
  },
  {
    name: "creator-subscribe-refund-creatorUserSn___th",
    path: "/th/creator/subscribe/refund/:creatorUserSn()",
    meta: _91creatorUserSn_93cQxnH6mEwLMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/subscribe/refund/[creatorUserSn].vue").then(m => m.default || m)
  },
  {
    name: "creator-subscribe-refund-creatorUserSn___es",
    path: "/es/creator/subscribe/refund/:creatorUserSn()",
    meta: _91creatorUserSn_93cQxnH6mEwLMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/subscribe/refund/[creatorUserSn].vue").then(m => m.default || m)
  },
  {
    name: "creator-subscribe-support-creatorUserSn___ko",
    path: "/creator/subscribe/support/:creatorUserSn()",
    meta: _91creatorUserSn_933yno5KF3A5Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/subscribe/support/[creatorUserSn].vue").then(m => m.default || m)
  },
  {
    name: "creator-subscribe-support-creatorUserSn___en",
    path: "/en/creator/subscribe/support/:creatorUserSn()",
    meta: _91creatorUserSn_933yno5KF3A5Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/subscribe/support/[creatorUserSn].vue").then(m => m.default || m)
  },
  {
    name: "creator-subscribe-support-creatorUserSn___th",
    path: "/th/creator/subscribe/support/:creatorUserSn()",
    meta: _91creatorUserSn_933yno5KF3A5Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/subscribe/support/[creatorUserSn].vue").then(m => m.default || m)
  },
  {
    name: "creator-subscribe-support-creatorUserSn___es",
    path: "/es/creator/subscribe/support/:creatorUserSn()",
    meta: _91creatorUserSn_933yno5KF3A5Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creator/subscribe/support/[creatorUserSn].vue").then(m => m.default || m)
  },
  {
    name: "creators-all___ko",
    path: "/creators/all",
    meta: alluM4r9Ua0fMMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creators/all.vue").then(m => m.default || m)
  },
  {
    name: "creators-all___en",
    path: "/en/creators/all",
    meta: alluM4r9Ua0fMMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creators/all.vue").then(m => m.default || m)
  },
  {
    name: "creators-all___th",
    path: "/th/creators/all",
    meta: alluM4r9Ua0fMMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creators/all.vue").then(m => m.default || m)
  },
  {
    name: "creators-all___es",
    path: "/es/creators/all",
    meta: alluM4r9Ua0fMMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creators/all.vue").then(m => m.default || m)
  },
  {
    name: "creators-my___ko",
    path: "/creators/my",
    meta: myzzdD9zvF4aMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creators/my.vue").then(m => m.default || m)
  },
  {
    name: "creators-my___en",
    path: "/en/creators/my",
    meta: myzzdD9zvF4aMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creators/my.vue").then(m => m.default || m)
  },
  {
    name: "creators-my___th",
    path: "/th/creators/my",
    meta: myzzdD9zvF4aMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creators/my.vue").then(m => m.default || m)
  },
  {
    name: "creators-my___es",
    path: "/es/creators/my",
    meta: myzzdD9zvF4aMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/creators/my.vue").then(m => m.default || m)
  },
  {
    name: "daily-check___ko",
    path: "/daily-check",
    meta: indexnKssUqOVROMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/daily-check/index.vue").then(m => m.default || m)
  },
  {
    name: "daily-check___en",
    path: "/en/daily-check",
    meta: indexnKssUqOVROMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/daily-check/index.vue").then(m => m.default || m)
  },
  {
    name: "daily-check___th",
    path: "/th/daily-check",
    meta: indexnKssUqOVROMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/daily-check/index.vue").then(m => m.default || m)
  },
  {
    name: "daily-check___es",
    path: "/es/daily-check",
    meta: indexnKssUqOVROMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/daily-check/index.vue").then(m => m.default || m)
  },
  {
    name: "feed-id___ko",
    path: "/feed/:id()",
    component: () => import("/codebuild/output/src2404643912/src/pages/feed/[id].vue").then(m => m.default || m)
  },
  {
    name: "feed-id___en",
    path: "/en/feed/:id()",
    component: () => import("/codebuild/output/src2404643912/src/pages/feed/[id].vue").then(m => m.default || m)
  },
  {
    name: "feed-id___th",
    path: "/th/feed/:id()",
    component: () => import("/codebuild/output/src2404643912/src/pages/feed/[id].vue").then(m => m.default || m)
  },
  {
    name: "feed-id___es",
    path: "/es/feed/:id()",
    component: () => import("/codebuild/output/src2404643912/src/pages/feed/[id].vue").then(m => m.default || m)
  },
  {
    name: "feed___ko",
    path: "/feed",
    component: () => import("/codebuild/output/src2404643912/src/pages/feed/index.vue").then(m => m.default || m)
  },
  {
    name: "feed___en",
    path: "/en/feed",
    component: () => import("/codebuild/output/src2404643912/src/pages/feed/index.vue").then(m => m.default || m)
  },
  {
    name: "feed___th",
    path: "/th/feed",
    component: () => import("/codebuild/output/src2404643912/src/pages/feed/index.vue").then(m => m.default || m)
  },
  {
    name: "feed___es",
    path: "/es/feed",
    component: () => import("/codebuild/output/src2404643912/src/pages/feed/index.vue").then(m => m.default || m)
  },
  {
    name: "fund-gaudi-fund___ko",
    path: "/fund/gaudi-fund",
    meta: index1Ra92cKAKGMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/fund/gaudi-fund/index.vue").then(m => m.default || m)
  },
  {
    name: "fund-gaudi-fund___en",
    path: "/en/fund/gaudi-fund",
    meta: index1Ra92cKAKGMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/fund/gaudi-fund/index.vue").then(m => m.default || m)
  },
  {
    name: "fund-gaudi-fund___th",
    path: "/th/fund/gaudi-fund",
    meta: index1Ra92cKAKGMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/fund/gaudi-fund/index.vue").then(m => m.default || m)
  },
  {
    name: "fund-gaudi-fund___es",
    path: "/es/fund/gaudi-fund",
    meta: index1Ra92cKAKGMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/fund/gaudi-fund/index.vue").then(m => m.default || m)
  },
  {
    name: "fund-gaudi___ko",
    path: "/fund/gaudi",
    meta: gaudi7HPWwVcUOoMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/fund/gaudi.vue").then(m => m.default || m)
  },
  {
    name: "fund-gaudi___en",
    path: "/en/fund/gaudi",
    meta: gaudi7HPWwVcUOoMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/fund/gaudi.vue").then(m => m.default || m)
  },
  {
    name: "fund-gaudi___th",
    path: "/th/fund/gaudi",
    meta: gaudi7HPWwVcUOoMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/fund/gaudi.vue").then(m => m.default || m)
  },
  {
    name: "fund-gaudi___es",
    path: "/es/fund/gaudi",
    meta: gaudi7HPWwVcUOoMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/fund/gaudi.vue").then(m => m.default || m)
  },
  {
    name: "fund-history___ko",
    path: "/fund/history",
    meta: history4dKPj4YPyXMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/fund/history.vue").then(m => m.default || m),
    children: [
  {
    name: "fund-history-id___ko",
    path: ":id()",
    component: () => import("/codebuild/output/src2404643912/src/pages/fund/history/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "fund-history___en",
    path: "/en/fund/history",
    meta: history4dKPj4YPyXMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/fund/history.vue").then(m => m.default || m),
    children: [
  {
    name: "fund-history-id___en",
    path: ":id()",
    component: () => import("/codebuild/output/src2404643912/src/pages/fund/history/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "fund-history___th",
    path: "/th/fund/history",
    meta: history4dKPj4YPyXMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/fund/history.vue").then(m => m.default || m),
    children: [
  {
    name: "fund-history-id___th",
    path: ":id()",
    component: () => import("/codebuild/output/src2404643912/src/pages/fund/history/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "fund-history___es",
    path: "/es/fund/history",
    meta: history4dKPj4YPyXMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/fund/history.vue").then(m => m.default || m),
    children: [
  {
    name: "fund-history-id___es",
    path: ":id()",
    component: () => import("/codebuild/output/src2404643912/src/pages/fund/history/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "fund___ko",
    path: "/fund",
    meta: indexnY3X0YsHLZMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/fund/index.vue").then(m => m.default || m),
    children: [
  {
    name: "fund-index-id___ko",
    path: ":id()",
    component: () => import("/codebuild/output/src2404643912/src/pages/fund/index/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "fund___en",
    path: "/en/fund",
    meta: indexnY3X0YsHLZMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/fund/index.vue").then(m => m.default || m),
    children: [
  {
    name: "fund-index-id___en",
    path: ":id()",
    component: () => import("/codebuild/output/src2404643912/src/pages/fund/index/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "fund___th",
    path: "/th/fund",
    meta: indexnY3X0YsHLZMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/fund/index.vue").then(m => m.default || m),
    children: [
  {
    name: "fund-index-id___th",
    path: ":id()",
    component: () => import("/codebuild/output/src2404643912/src/pages/fund/index/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "fund___es",
    path: "/es/fund",
    meta: indexnY3X0YsHLZMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/fund/index.vue").then(m => m.default || m),
    children: [
  {
    name: "fund-index-id___es",
    path: ":id()",
    component: () => import("/codebuild/output/src2404643912/src/pages/fund/index/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "fund-notice-id___ko",
    path: "/fund/notice/:id()",
    meta: _91id_93Qc98Tv58CgMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/fund/notice/[id].vue").then(m => m.default || m)
  },
  {
    name: "fund-notice-id___en",
    path: "/en/fund/notice/:id()",
    meta: _91id_93Qc98Tv58CgMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/fund/notice/[id].vue").then(m => m.default || m)
  },
  {
    name: "fund-notice-id___th",
    path: "/th/fund/notice/:id()",
    meta: _91id_93Qc98Tv58CgMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/fund/notice/[id].vue").then(m => m.default || m)
  },
  {
    name: "fund-notice-id___es",
    path: "/es/fund/notice/:id()",
    meta: _91id_93Qc98Tv58CgMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/fund/notice/[id].vue").then(m => m.default || m)
  },
  {
    name: "fund-notice___ko",
    path: "/fund/notice",
    meta: indexIr0bASC4EJMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/fund/notice/index.vue").then(m => m.default || m)
  },
  {
    name: "fund-notice___en",
    path: "/en/fund/notice",
    meta: indexIr0bASC4EJMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/fund/notice/index.vue").then(m => m.default || m)
  },
  {
    name: "fund-notice___th",
    path: "/th/fund/notice",
    meta: indexIr0bASC4EJMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/fund/notice/index.vue").then(m => m.default || m)
  },
  {
    name: "fund-notice___es",
    path: "/es/fund/notice",
    meta: indexIr0bASC4EJMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/fund/notice/index.vue").then(m => m.default || m)
  },
  {
    name: "hashtag-id___ko",
    path: "/hashtag/:id()",
    meta: _91id_931wRAQOJgpOMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/hashtag/[id].vue").then(m => m.default || m)
  },
  {
    name: "hashtag-id___en",
    path: "/en/hashtag/:id()",
    meta: _91id_931wRAQOJgpOMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/hashtag/[id].vue").then(m => m.default || m)
  },
  {
    name: "hashtag-id___th",
    path: "/th/hashtag/:id()",
    meta: _91id_931wRAQOJgpOMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/hashtag/[id].vue").then(m => m.default || m)
  },
  {
    name: "hashtag-id___es",
    path: "/es/hashtag/:id()",
    meta: _91id_931wRAQOJgpOMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/hashtag/[id].vue").then(m => m.default || m)
  },
  {
    name: "hashtag___ko",
    path: "/hashtag",
    meta: indexqCHNqwRkXRMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/hashtag/index.vue").then(m => m.default || m)
  },
  {
    name: "hashtag___en",
    path: "/en/hashtag",
    meta: indexqCHNqwRkXRMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/hashtag/index.vue").then(m => m.default || m)
  },
  {
    name: "hashtag___th",
    path: "/th/hashtag",
    meta: indexqCHNqwRkXRMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/hashtag/index.vue").then(m => m.default || m)
  },
  {
    name: "hashtag___es",
    path: "/es/hashtag",
    meta: indexqCHNqwRkXRMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/hashtag/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ko",
    path: "/",
    meta: index3jcqk7rYV8Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    meta: index3jcqk7rYV8Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___th",
    path: "/th",
    meta: index3jcqk7rYV8Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    meta: index3jcqk7rYV8Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "nova-plus-id___ko",
    path: "/nova-plus/:id()",
    meta: _91id_93GzsQN5EdlWMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/nova-plus/[id].vue").then(m => m.default || m)
  },
  {
    name: "nova-plus-id___en",
    path: "/en/nova-plus/:id()",
    meta: _91id_93GzsQN5EdlWMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/nova-plus/[id].vue").then(m => m.default || m)
  },
  {
    name: "nova-plus-id___th",
    path: "/th/nova-plus/:id()",
    meta: _91id_93GzsQN5EdlWMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/nova-plus/[id].vue").then(m => m.default || m)
  },
  {
    name: "nova-plus-id___es",
    path: "/es/nova-plus/:id()",
    meta: _91id_93GzsQN5EdlWMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/nova-plus/[id].vue").then(m => m.default || m)
  },
  {
    name: "nova-plus___ko",
    path: "/nova-plus",
    meta: index697rPixpa5Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/nova-plus/index.vue").then(m => m.default || m)
  },
  {
    name: "nova-plus___en",
    path: "/en/nova-plus",
    meta: index697rPixpa5Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/nova-plus/index.vue").then(m => m.default || m)
  },
  {
    name: "nova-plus___th",
    path: "/th/nova-plus",
    meta: index697rPixpa5Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/nova-plus/index.vue").then(m => m.default || m)
  },
  {
    name: "nova-plus___es",
    path: "/es/nova-plus",
    meta: index697rPixpa5Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/nova-plus/index.vue").then(m => m.default || m)
  },
  {
    name: "oulim___ko",
    path: "/oulim",
    meta: index0f5S7emcnrMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/oulim/index.vue").then(m => m.default || m)
  },
  {
    name: "oulim___en",
    path: "/en/oulim",
    meta: index0f5S7emcnrMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/oulim/index.vue").then(m => m.default || m)
  },
  {
    name: "oulim___th",
    path: "/th/oulim",
    meta: index0f5S7emcnrMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/oulim/index.vue").then(m => m.default || m)
  },
  {
    name: "oulim___es",
    path: "/es/oulim",
    meta: index0f5S7emcnrMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/oulim/index.vue").then(m => m.default || m)
  },
  {
    name: "post-id___ko",
    path: "/post/:id()",
    meta: _91id_93Mk8FCcdyH7Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/post/[id].vue").then(m => m.default || m)
  },
  {
    name: "post-id___en",
    path: "/en/post/:id()",
    meta: _91id_93Mk8FCcdyH7Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/post/[id].vue").then(m => m.default || m)
  },
  {
    name: "post-id___th",
    path: "/th/post/:id()",
    meta: _91id_93Mk8FCcdyH7Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/post/[id].vue").then(m => m.default || m)
  },
  {
    name: "post-id___es",
    path: "/es/post/:id()",
    meta: _91id_93Mk8FCcdyH7Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/post/[id].vue").then(m => m.default || m)
  },
  {
    name: "referral-@id___ko",
    path: "/referral/@:id()",
    meta: _64_91id_93hqkWEIUxolMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/referral/@[id].vue").then(m => m.default || m)
  },
  {
    name: "referral-@id___en",
    path: "/en/referral/@:id()",
    meta: _64_91id_93hqkWEIUxolMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/referral/@[id].vue").then(m => m.default || m)
  },
  {
    name: "referral-@id___th",
    path: "/th/referral/@:id()",
    meta: _64_91id_93hqkWEIUxolMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/referral/@[id].vue").then(m => m.default || m)
  },
  {
    name: "referral-@id___es",
    path: "/es/referral/@:id()",
    meta: _64_91id_93hqkWEIUxolMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/referral/@[id].vue").then(m => m.default || m)
  },
  {
    name: "search-detail-comment-id___ko",
    path: "/search/detail/comment/:id()",
    meta: _91id_93iOcdjT3Xg2Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/detail/comment/[id].vue").then(m => m.default || m)
  },
  {
    name: "search-detail-comment-id___en",
    path: "/en/search/detail/comment/:id()",
    meta: _91id_93iOcdjT3Xg2Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/detail/comment/[id].vue").then(m => m.default || m)
  },
  {
    name: "search-detail-comment-id___th",
    path: "/th/search/detail/comment/:id()",
    meta: _91id_93iOcdjT3Xg2Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/detail/comment/[id].vue").then(m => m.default || m)
  },
  {
    name: "search-detail-comment-id___es",
    path: "/es/search/detail/comment/:id()",
    meta: _91id_93iOcdjT3Xg2Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/detail/comment/[id].vue").then(m => m.default || m)
  },
  {
    name: "search-detail-comment___ko",
    path: "/search/detail/comment",
    meta: indexQAEx4bd55FMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/detail/comment/index.vue").then(m => m.default || m)
  },
  {
    name: "search-detail-comment___en",
    path: "/en/search/detail/comment",
    meta: indexQAEx4bd55FMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/detail/comment/index.vue").then(m => m.default || m)
  },
  {
    name: "search-detail-comment___th",
    path: "/th/search/detail/comment",
    meta: indexQAEx4bd55FMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/detail/comment/index.vue").then(m => m.default || m)
  },
  {
    name: "search-detail-comment___es",
    path: "/es/search/detail/comment",
    meta: indexQAEx4bd55FMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/detail/comment/index.vue").then(m => m.default || m)
  },
  {
    name: "search-detail-hashtag___ko",
    path: "/search/detail/hashtag",
    meta: hashtagd3EkpgZk6wMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/detail/hashtag.vue").then(m => m.default || m)
  },
  {
    name: "search-detail-hashtag___en",
    path: "/en/search/detail/hashtag",
    meta: hashtagd3EkpgZk6wMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/detail/hashtag.vue").then(m => m.default || m)
  },
  {
    name: "search-detail-hashtag___th",
    path: "/th/search/detail/hashtag",
    meta: hashtagd3EkpgZk6wMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/detail/hashtag.vue").then(m => m.default || m)
  },
  {
    name: "search-detail-hashtag___es",
    path: "/es/search/detail/hashtag",
    meta: hashtagd3EkpgZk6wMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/detail/hashtag.vue").then(m => m.default || m)
  },
  {
    name: "search-detail-index-id___ko",
    path: "/search/detail/:id()",
    meta: _91id_934zAfAMy8v0Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/detail/index/[id].vue").then(m => m.default || m)
  },
  {
    name: "search-detail-index-id___en",
    path: "/en/search/detail/:id()",
    meta: _91id_934zAfAMy8v0Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/detail/index/[id].vue").then(m => m.default || m)
  },
  {
    name: "search-detail-index-id___th",
    path: "/th/search/detail/:id()",
    meta: _91id_934zAfAMy8v0Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/detail/index/[id].vue").then(m => m.default || m)
  },
  {
    name: "search-detail-index-id___es",
    path: "/es/search/detail/:id()",
    meta: _91id_934zAfAMy8v0Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/detail/index/[id].vue").then(m => m.default || m)
  },
  {
    name: "search-detail-index___ko",
    path: "/search/detail",
    meta: indexRzFI8bhJRMMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/detail/index/index.vue").then(m => m.default || m)
  },
  {
    name: "search-detail-index___en",
    path: "/en/search/detail",
    meta: indexRzFI8bhJRMMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/detail/index/index.vue").then(m => m.default || m)
  },
  {
    name: "search-detail-index___th",
    path: "/th/search/detail",
    meta: indexRzFI8bhJRMMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/detail/index/index.vue").then(m => m.default || m)
  },
  {
    name: "search-detail-index___es",
    path: "/es/search/detail",
    meta: indexRzFI8bhJRMMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/detail/index/index.vue").then(m => m.default || m)
  },
  {
    name: "search-detail-nova-plus-id___ko",
    path: "/search/detail/nova-plus/:id()",
    meta: _91id_93xZe47JHRKyMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/detail/nova-plus/[id].vue").then(m => m.default || m)
  },
  {
    name: "search-detail-nova-plus-id___en",
    path: "/en/search/detail/nova-plus/:id()",
    meta: _91id_93xZe47JHRKyMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/detail/nova-plus/[id].vue").then(m => m.default || m)
  },
  {
    name: "search-detail-nova-plus-id___th",
    path: "/th/search/detail/nova-plus/:id()",
    meta: _91id_93xZe47JHRKyMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/detail/nova-plus/[id].vue").then(m => m.default || m)
  },
  {
    name: "search-detail-nova-plus-id___es",
    path: "/es/search/detail/nova-plus/:id()",
    meta: _91id_93xZe47JHRKyMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/detail/nova-plus/[id].vue").then(m => m.default || m)
  },
  {
    name: "search-detail-nova-plus___ko",
    path: "/search/detail/nova-plus",
    meta: indexgk5uK9330MMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/detail/nova-plus/index.vue").then(m => m.default || m)
  },
  {
    name: "search-detail-nova-plus___en",
    path: "/en/search/detail/nova-plus",
    meta: indexgk5uK9330MMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/detail/nova-plus/index.vue").then(m => m.default || m)
  },
  {
    name: "search-detail-nova-plus___th",
    path: "/th/search/detail/nova-plus",
    meta: indexgk5uK9330MMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/detail/nova-plus/index.vue").then(m => m.default || m)
  },
  {
    name: "search-detail-nova-plus___es",
    path: "/es/search/detail/nova-plus",
    meta: indexgk5uK9330MMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/detail/nova-plus/index.vue").then(m => m.default || m)
  },
  {
    name: "search-detail-user___ko",
    path: "/search/detail/user",
    meta: userOU2QDmKuEkMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/detail/user.vue").then(m => m.default || m)
  },
  {
    name: "search-detail-user___en",
    path: "/en/search/detail/user",
    meta: userOU2QDmKuEkMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/detail/user.vue").then(m => m.default || m)
  },
  {
    name: "search-detail-user___th",
    path: "/th/search/detail/user",
    meta: userOU2QDmKuEkMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/detail/user.vue").then(m => m.default || m)
  },
  {
    name: "search-detail-user___es",
    path: "/es/search/detail/user",
    meta: userOU2QDmKuEkMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/detail/user.vue").then(m => m.default || m)
  },
  {
    name: "search___ko",
    path: "/search",
    meta: indexJJTRT1DpIUMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/index.vue").then(m => m.default || m),
    children: [
  {
    name: "search-index-id___ko",
    path: ":id()",
    meta: _91id_93RIiFevaZTbMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/index/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "search___en",
    path: "/en/search",
    meta: indexJJTRT1DpIUMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/index.vue").then(m => m.default || m),
    children: [
  {
    name: "search-index-id___en",
    path: ":id()",
    meta: _91id_93RIiFevaZTbMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/index/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "search___th",
    path: "/th/search",
    meta: indexJJTRT1DpIUMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/index.vue").then(m => m.default || m),
    children: [
  {
    name: "search-index-id___th",
    path: ":id()",
    meta: _91id_93RIiFevaZTbMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/index/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "search___es",
    path: "/es/search",
    meta: indexJJTRT1DpIUMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/index.vue").then(m => m.default || m),
    children: [
  {
    name: "search-index-id___es",
    path: ":id()",
    meta: _91id_93RIiFevaZTbMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/search/index/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "terms-id___ko",
    path: "/terms/:id()",
    meta: _91id_93xw62aMHv6EMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/terms/[id].vue").then(m => m.default || m)
  },
  {
    name: "terms-id___en",
    path: "/en/terms/:id()",
    meta: _91id_93xw62aMHv6EMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/terms/[id].vue").then(m => m.default || m)
  },
  {
    name: "terms-id___th",
    path: "/th/terms/:id()",
    meta: _91id_93xw62aMHv6EMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/terms/[id].vue").then(m => m.default || m)
  },
  {
    name: "terms-id___es",
    path: "/es/terms/:id()",
    meta: _91id_93xw62aMHv6EMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/terms/[id].vue").then(m => m.default || m)
  },
  {
    name: "user-id-activities___ko",
    path: "/user/:id()/activities",
    meta: activitiesD7Y4R3CaQOMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/user/[id]/activities.vue").then(m => m.default || m),
    children: [
  {
    name: "user-id-activities-postId___ko",
    path: ":postId()",
    meta: _91postId_93GGzcpImcEhMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/user/[id]/activities/[postId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "user-id-activities___en",
    path: "/en/user/:id()/activities",
    meta: activitiesD7Y4R3CaQOMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/user/[id]/activities.vue").then(m => m.default || m),
    children: [
  {
    name: "user-id-activities-postId___en",
    path: ":postId()",
    meta: _91postId_93GGzcpImcEhMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/user/[id]/activities/[postId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "user-id-activities___th",
    path: "/th/user/:id()/activities",
    meta: activitiesD7Y4R3CaQOMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/user/[id]/activities.vue").then(m => m.default || m),
    children: [
  {
    name: "user-id-activities-postId___th",
    path: ":postId()",
    meta: _91postId_93GGzcpImcEhMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/user/[id]/activities/[postId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "user-id-activities___es",
    path: "/es/user/:id()/activities",
    meta: activitiesD7Y4R3CaQOMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/user/[id]/activities.vue").then(m => m.default || m),
    children: [
  {
    name: "user-id-activities-postId___es",
    path: ":postId()",
    meta: _91postId_93GGzcpImcEhMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/user/[id]/activities/[postId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "user-id-collections___ko",
    path: "/user/:id()/collections",
    meta: collectionsK8bWUYkJY4Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/user/[id]/collections.vue").then(m => m.default || m),
    children: [
  {
    name: "user-id-collections-collectionId___ko",
    path: ":collectionId()",
    meta: _91collectionId_93lFa2uf7Z7HMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/user/[id]/collections/[collectionId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "user-id-collections___en",
    path: "/en/user/:id()/collections",
    meta: collectionsK8bWUYkJY4Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/user/[id]/collections.vue").then(m => m.default || m),
    children: [
  {
    name: "user-id-collections-collectionId___en",
    path: ":collectionId()",
    meta: _91collectionId_93lFa2uf7Z7HMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/user/[id]/collections/[collectionId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "user-id-collections___th",
    path: "/th/user/:id()/collections",
    meta: collectionsK8bWUYkJY4Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/user/[id]/collections.vue").then(m => m.default || m),
    children: [
  {
    name: "user-id-collections-collectionId___th",
    path: ":collectionId()",
    meta: _91collectionId_93lFa2uf7Z7HMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/user/[id]/collections/[collectionId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "user-id-collections___es",
    path: "/es/user/:id()/collections",
    meta: collectionsK8bWUYkJY4Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/user/[id]/collections.vue").then(m => m.default || m),
    children: [
  {
    name: "user-id-collections-collectionId___es",
    path: ":collectionId()",
    meta: _91collectionId_93lFa2uf7Z7HMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/user/[id]/collections/[collectionId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "user-id-donation___ko",
    path: "/user/:id()/donation",
    meta: donationM5UbjiPl3dMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/user/[id]/donation.vue").then(m => m.default || m)
  },
  {
    name: "user-id-donation___en",
    path: "/en/user/:id()/donation",
    meta: donationM5UbjiPl3dMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/user/[id]/donation.vue").then(m => m.default || m)
  },
  {
    name: "user-id-donation___th",
    path: "/th/user/:id()/donation",
    meta: donationM5UbjiPl3dMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/user/[id]/donation.vue").then(m => m.default || m)
  },
  {
    name: "user-id-donation___es",
    path: "/es/user/:id()/donation",
    meta: donationM5UbjiPl3dMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/user/[id]/donation.vue").then(m => m.default || m)
  },
  {
    name: "user-id-index-postId___ko",
    path: "/user/:id()/:postId()",
    meta: _91postId_93vA7sMxDr7YMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/user/[id]/index/[postId].vue").then(m => m.default || m)
  },
  {
    name: "user-id-index-postId___en",
    path: "/en/user/:id()/:postId()",
    meta: _91postId_93vA7sMxDr7YMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/user/[id]/index/[postId].vue").then(m => m.default || m)
  },
  {
    name: "user-id-index-postId___th",
    path: "/th/user/:id()/:postId()",
    meta: _91postId_93vA7sMxDr7YMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/user/[id]/index/[postId].vue").then(m => m.default || m)
  },
  {
    name: "user-id-index-postId___es",
    path: "/es/user/:id()/:postId()",
    meta: _91postId_93vA7sMxDr7YMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/user/[id]/index/[postId].vue").then(m => m.default || m)
  },
  {
    name: "user-id-index___ko",
    path: "/user/:id()",
    meta: indexr8n0WeqODhMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/user/[id]/index/index.vue").then(m => m.default || m)
  },
  {
    name: "user-id-index___en",
    path: "/en/user/:id()",
    meta: indexr8n0WeqODhMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/user/[id]/index/index.vue").then(m => m.default || m)
  },
  {
    name: "user-id-index___th",
    path: "/th/user/:id()",
    meta: indexr8n0WeqODhMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/user/[id]/index/index.vue").then(m => m.default || m)
  },
  {
    name: "user-id-index___es",
    path: "/es/user/:id()",
    meta: indexr8n0WeqODhMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/user/[id]/index/index.vue").then(m => m.default || m)
  },
  {
    name: "user-id-reward___ko",
    path: "/user/:id()/reward",
    meta: rewardSDlRBEdGZ7Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/user/[id]/reward.vue").then(m => m.default || m)
  },
  {
    name: "user-id-reward___en",
    path: "/en/user/:id()/reward",
    meta: rewardSDlRBEdGZ7Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/user/[id]/reward.vue").then(m => m.default || m)
  },
  {
    name: "user-id-reward___th",
    path: "/th/user/:id()/reward",
    meta: rewardSDlRBEdGZ7Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/user/[id]/reward.vue").then(m => m.default || m)
  },
  {
    name: "user-id-reward___es",
    path: "/es/user/:id()/reward",
    meta: rewardSDlRBEdGZ7Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/user/[id]/reward.vue").then(m => m.default || m)
  },
  {
    name: "user-sponsors___ko",
    path: "/user/sponsors",
    meta: indexml38Nc9A5FMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/user/sponsors/index.vue").then(m => m.default || m)
  },
  {
    name: "user-sponsors___en",
    path: "/en/user/sponsors",
    meta: indexml38Nc9A5FMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/user/sponsors/index.vue").then(m => m.default || m)
  },
  {
    name: "user-sponsors___th",
    path: "/th/user/sponsors",
    meta: indexml38Nc9A5FMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/user/sponsors/index.vue").then(m => m.default || m)
  },
  {
    name: "user-sponsors___es",
    path: "/es/user/sponsors",
    meta: indexml38Nc9A5FMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/user/sponsors/index.vue").then(m => m.default || m)
  },
  {
    name: "w3cf-detail-id___ko",
    path: "/w3cf/detail/:id()",
    meta: _91id_93sIfnqsjRV0Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/w3cf/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "w3cf-detail-id___en",
    path: "/en/w3cf/detail/:id()",
    meta: _91id_93sIfnqsjRV0Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/w3cf/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "w3cf-detail-id___th",
    path: "/th/w3cf/detail/:id()",
    meta: _91id_93sIfnqsjRV0Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/w3cf/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "w3cf-detail-id___es",
    path: "/es/w3cf/detail/:id()",
    meta: _91id_93sIfnqsjRV0Meta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/w3cf/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "w3cf___ko",
    path: "/w3cf",
    meta: index0eWe0JHz6vMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/w3cf/index.vue").then(m => m.default || m)
  },
  {
    name: "w3cf___en",
    path: "/en/w3cf",
    meta: index0eWe0JHz6vMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/w3cf/index.vue").then(m => m.default || m)
  },
  {
    name: "w3cf___th",
    path: "/th/w3cf",
    meta: index0eWe0JHz6vMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/w3cf/index.vue").then(m => m.default || m)
  },
  {
    name: "w3cf___es",
    path: "/es/w3cf",
    meta: index0eWe0JHz6vMeta || {},
    component: () => import("/codebuild/output/src2404643912/src/pages/w3cf/index.vue").then(m => m.default || m)
  }
]