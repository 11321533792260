/**
 * 숫자를 접미사 단위를 포함하여 포맷변경
 */
export default (
  number: number, // 변환 하려는 숫자
  decimal: number // 소수점 표시 단위
): string => {
  // 정규식
  const regex = /\.0+$|(\.[0-9]*[1-9])0+$/
  // 접미사 단위
  const suffix: { value: number; suffix: string }[] = [
    { value: 1, suffix: '' },
    { value: 1e3, suffix: 'K' }, // 1,000: Thousand
    { value: 1e6, suffix: 'M' }, // 1,000,000: Million
    { value: 1e9, suffix: 'G' },
    { value: 1e12, suffix: 'T' },
    { value: 1e15, suffix: 'P' },
    { value: 1e18, suffix: 'E' },
  ]

  const item = suffix
    .slice()
    .reverse()
    .find((item) => number >= item.value)

  return item
    ? (number / item.value).toFixed(decimal).replace(regex, '$1') + item.suffix
    : '0'
}
