<script setup lang="ts">
import type { RouteParams } from 'vue-router'
import type { RegCreatorGuestBookPayload } from '@store'

defineOptions({
  inheritAttrs: false,
})
const { gtEvent } = useGoogleTag()
const { t } = useI18n()
const { hide: modalHide } = useModal()
const creatorStore = useCreatorStore()
const show = ref(false)
const creatorGuestBook = ref('')
const creatorUserSn = ref(0)
const isLoading = ref(false)

// 방명록 작성시 필요한 파라미터 받기
const handleOnGetParams = (evt: RouteParams) => {
  creatorUserSn.value = (
    evt.value as unknown as RegCreatorGuestBookPayload
  ).creatorUserSn
}

// 팝업 닫기
const handleOnClose = async (complete?: boolean) => {
  if (complete !== true) {
    gtEvent('clickEvent', {
      eventCategory: '클릭',
      eventAction: '방명록 작성 취소',
      eventLabel: t(
        'creatorGuestBook.regCreatorGuestBookModal.actions.negative'
      ),
      eventSlot: '방명록 작성',
      eventI18nAddr: useKoreanTranslation(
        'creatorGuestBook.regCreatorGuestBookModal.actions.negative'
      ),
      eventComponent: 'Button',
    })
  }
  await modalHide(modalsName.MODAL_REG_CREATOR_GUEST_BOOK)
}

// 팝업 닫은 후 초기화
const handleOnClosed = () => {
  creatorGuestBook.value = ''
}

// 방명록 작성
const handleOnRegCreatorGuestBook = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '방명록 작성',
    eventLabel: t('creatorGuestBook.regCreatorGuestBookModal.actions.positive'),
    eventSlot: '',
    eventI18nAddr: useKoreanTranslation(
      'creatorGuestBook.regCreatorGuestBookModal.actions.positive'
    ),
    eventComponent: 'Button',
    creatorUserSn: creatorUserSn.value,
    gustCn: creatorGuestBook.value,
  })
  // 1. 내용 미입력시 토스트 메세지
  if (!creatorGuestBook.value.length) {
    useToast(t('creatorGuestBook.request.reg.validLength'))
    return
  }

  try {
    isLoading.value = true
    await creatorStore.regCreatorGuestBook({
      creatorUserSn: creatorUserSn.value,
      gustCn: creatorGuestBook.value,
    })
    gtEvent('userAction', {
      eventCategory: '사용자',
      eventAction: '방명록 작성',
      eventLabel: t(
        'creatorGuestBook.regCreatorGuestBookModal.actions.positive'
      ),
      eventSlot: '',
      eventI18nAddr: useKoreanTranslation(
        'creatorGuestBook.regCreatorGuestBookModal.actions.positive'
      ),
      eventComponent: 'Button',
      creatorUserSn: creatorUserSn.value,
      gustCn: creatorGuestBook.value,
    })
    await handleOnClose(true)
  } catch {
    useToast(t('creatorGuestBook.request.reg.error'))
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <VueFinalModal
    v-model="show"
    :name="modalsName.MODAL_REG_CREATOR_GUEST_BOOK"
    :content-class="'modal-content'"
    classes="modal-container"
    @before-open="(evt: any) => handleOnGetParams(evt.ref.params)"
    @click-outside="handleOnClose"
    @closed="handleOnClosed"
  >
    <ClientOnly>
      <NovaBoxBase v-if="show" class="modal-box">
        <div class="modal-header">
          <h4 class="title">
            {{ $t('creatorGuestBook.regCreatorGuestBookModal.title') }}
          </h4>

          <NovaButtonIcon
            :icon="{ type: 'outline', name: 'close-extend' }"
            :theme="'transparent'"
            :size="20"
            class="btn-close"
            @click="handleOnClose"
          />
        </div>

        <NovaLayoutScrollContainer class="modal-body">
          <NovaTextarea
            v-model="creatorGuestBook"
            :placeholder="
              $t(
                'creatorGuestBook.regCreatorGuestBookModal.textareaPlaceholder'
              )
            "
            :rows="6"
            :max-byte="4000"
            :disabled="isLoading"
          />
        </NovaLayoutScrollContainer>

        <div class="modal-actions">
          <NovaButtonText
            class="action"
            :label="
              $t('creatorGuestBook.regCreatorGuestBookModal.actions.negative')
            "
            :theme="'transparent'"
            :loading="isLoading"
            @click="handleOnClose"
          />
          <NovaButtonText
            class="action"
            :label="
              $t('creatorGuestBook.regCreatorGuestBookModal.actions.positive')
            "
            :theme="'primary-blue-light'"
            :loading="isLoading"
            @click="handleOnRegCreatorGuestBook"
          />
        </div>
      </NovaBoxBase>
    </ClientOnly>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
:deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.vfm--overlay) {
  background-color: hex-to-rgba($color-black, 0.65);
}

:deep(.modal-content) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: none;

  .modal-box {
    width: 100%;
    max-width: 420px;
    max-height: calc(100% - 80px);
    padding: 0;
    pointer-events: auto;
    overflow: hidden;

    .modal-header {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 56px;
      padding: 0 20px;
      color: $color-text-2;
      background-color: $color-bg-3;
      border-bottom: 1px solid $border-1;

      .title {
        @include text-style($text-display-bold);
      }
    }

    .modal-body {
      display: flex;
      flex-direction: column;
      padding: 20px;
    }

    .modal-actions {
      flex-shrink: 0;
      display: flex;
      justify-content: flex-end;
      gap: 4px;
      padding: 0 20px 20px;

      .action {
        width: 100%;
      }
    }

    @include mobile {
      border-radius: 0 !important;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
    }
  }
}
</style>
