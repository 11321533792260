/**
 * 프로필 편집(마이페이지)
 */
export const profileControl = {
  avatar: 'Edit Avatar',
  profile: 'Upload Your Photo',
  dialog: {
    title: 'Notifications',
    content:
      'The edited avatar will be adapted on all three platforms LM Wallet.',
    actions: {
      positive: 'OK',
    },
  },
}
