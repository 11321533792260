import type { MembershipService } from '@configs'

/**
 * 팝업 열기 액션의 유형
 * 이는 `MembershipService` 객체나 'pay' 문자열 중 하나가 될 수 있음
 *
 * @typedef {MembershipService | 'pay'} PopupOpenType
 */
type PopupOpenType = MembershipService | 'pay'

/**
 * 주어진 URL과 유형에 기반하여 팝업 창 열기
 *
 * @param {string} url - 팝업 창의 URL
 * @param {PopupOpenType} type - 팝업 창의 유형
 * @returns {void}
 */
export const useOpenPopupAtOnce = (url: string, type: PopupOpenType): void => {
  // if (window.popup && !window.popup.ref.closed) {
  //   if (window.popup.type === type) {
  //     window.popup.ref.focus()
  //   } else {
  //     window.popup.ref.close()
  //     delete window.popup
  //     creatPopup(url, type)
  //   }
  // } else {
  //   creatPopup(url, type)
  // }

  if (window.popup && !window.popup.ref.closed && window.popup.type === type) {
    window.popup.ref.focus()
  } else {
    if (window.popup) {
      window.popup.ref.close()
      delete window.popup
    }
    creatPopup(url, type)
  }
}

/**
 * 팝업 창을 생성하고 지정된 URL 열기
 *
 * @description Safari 윈도우 팝업 비동기 이슈 우회(https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari)
 * @param {string} url - 팝업 창에서 열 URL
 * @param {PopupOpenType} type - 열 팝업 창의 유형
 */
const creatPopup = (url: string, type: PopupOpenType) => {
  const layoutStore = useLayoutStore()

  const popup = window.open(undefined, type, 'width=420, height=800')
  popup!.location = url

  window.popup = {
    ref: popup!,
    type,
  }

  // 로딩 인디케이터 노출
  layoutStore.updateLoadingIndicatorGlobal({ show: true })
  const interval = setInterval(() => {
    if (popup?.closed) {
      clearInterval(interval)
      layoutStore.updateLoadingIndicatorGlobal({ show: false })
    }
  }, 200)
}
