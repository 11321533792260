import { FirebaseExceptionStatus } from './types'

/**
 * Firebase exception
 * @param message
 * @param cause
 */
export class FirebaseException extends Error {
  name: 'FIREBASE_EXCEPTION'
  message: string
  cause: {
    status: FirebaseExceptionStatus
    message: string
    data?: any
  }

  constructor({
    message,
    cause,
  }: {
    message: string
    cause: {
      status: FirebaseExceptionStatus
      data?: any
    }
  }) {
    super()
    this.name = 'FIREBASE_EXCEPTION'
    this.message = message
    this.cause = {
      ...cause,
      message,
    }
  }
}
