<script setup lang="ts">
import type { NovaDividerProps } from './NovaDivider.types'

const props = withDefaults(defineProps<NovaDividerProps>(), {
  direction: 'horizontal',
  gap: 0,
  height: 1,
  theme: 'default',
})

const dynamicHeight = computed(() =>
  props.direction === 'vertical' && props.height === 1 ? 30 : props.height
)
const gapStyle = computed(() => {
  switch (props.direction) {
    case 'horizontal':
      return { margin: `${props.gap}px 0` }
    case 'vertical':
      return { margin: `0 ${props.gap}px` }
    default: {
      throw new Error('divider direction is not match')
    }
  }
})
</script>

<template>
  <hr
    :class="['divider', direction, `theme-${theme}`]"
    :style="{ height: `${dynamicHeight}px`, ...gapStyle }"
  />
</template>

<style lang="scss" scoped>
.divider {
  border: 0;

  &.horizontal {
    width: 100%;
    height: 1px;
  }

  &.vertical {
    width: 1px;
    height: 100%;
  }

  &.theme {
    &-default {
      background-color: $color-black-10p;
    }

    &-blue {
      background-color: $color-text-5;
    }
  }
}
</style>
