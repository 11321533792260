import type { SearchState } from './type'

export const initSearchState: SearchState = {
  keyword: '',
  all: {
    post: [],
    novaPlusPost: [],
    comment: [],
    hash: [],
    users: [],
  },
  comments: {
    items: [],
    payload: {
      pageNum: 0,
      pageSize: 20,
      query: '',
    },
    refresh: false,
    last: false,
  },
  hashtags: {
    items: [],
    payload: {
      pageNum: 0,
      pageSize: 20,
      query: '',
    },
    refresh: false,
    last: false,
  },
  /* creators: {
    items: [],
    payload: {
      pageNum: 0,
      pageSize: 20,
      query: '',
    },
    refresh: false,
    last: false,
  }, */
  users: {
    items: [],
    payload: {
      pageNum: 0,
      pageSize: 20,
      query: '',
    },
    refresh: false,
    last: false,
  },
  premiumPosts: {
    items: [],
    payload: {
      pageNum: 0,
      pageSize: 20,
      nextPageSize: 20,
      searchNttSj: '',
    },
    refresh: false,
    last: false,
  },
}
