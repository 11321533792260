/**
 * 크리에이터 홈페이지 Url
 */
export const creatorHomeLink = {
  label: 'I LIKE LM 링크',
  edit: {
    placeholder: '공유할 링크를 입력하세요.',
  },
  tools: {
    clipboard: {
      tooltip: 'I LIKE LM 링크 복사',
      toastMessage: {
        success: '클립보드에 링크가 복사되었습니다.',
      },
    },
    edit: {
      tooltip: '수정',
      toastMessage: {
        unavailableLmNovaLink:
          '사용할 수 없는 문자가 포함되어 있습니다. 다른 I LIKE LM 링크를 사용해 주세요.',
        success: 'I LIKE LM 링크가 변경되었습니다.',
        failDefault:
          '요청 처리중 오류가 발생하였습니다. 잠시후 다시 시도해주세요.',
        failEqual: '기존 링크와 이름이 같습니다. 다른 링크를 입력하세요.',
        failUpdate: '링크를 저장할 수 없습니다. 잠시 후 다시 시도해 주세요.',
        failDuplicate: '이미 다른 크리에이터가 사용중인 링크입니다.',
        failLength: '최소 {min} 글자수 이상을 입력해 주세요.',
        failCharacter: '영문 + 숫자만 입력해 주세요.',
        checkDuplicateBeforeSave: '중복체크 후 저장해 주세요.',
      },
    },
  },
}
