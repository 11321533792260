<script setup lang="ts">
import { NotificationMarketingAgreeCode } from '@store'
import { type BooleanYn } from '@store/types'
const userStore = useUserStore()

const { hide: modalHide } = useModal()
const show = ref(false)
const isLoading = ref(false)
const isError = ref(false)
const title = ref('')
const titleError = ref('')
const allSelected = ref(false)
interface NotificationItem {
  selected: boolean
  ntcnNm: string
  ntcnBrfDc: string
  stplatAgreAt: BooleanYn
  stplatAgreDt: Date | null
  stplatTyCode: NotificationMarketingAgreeCode
}

const sourceWithSelected = ref<NotificationItem[]>([])

defineOptions({
  inheritAttrs: false,
})

watch(
  () => title.value,
  (cur) => {
    if (cur.length && cur.length <= 50) {
      titleError.value = ''
    }
  }
)

watch(sourceWithSelected, () => {
  allSelected.value = sourceWithSelected.value.every((item) => item.selected)
  // 전체 선택 버튼 업데이트
  // allSelected 값에 따라 전체 선택 버튼 상태를 설정
})

// 모달 오픈전 사전 작업 수행
const handleOnBeforeOpen = (data: any) => {
  sourceWithSelected.value = data.value.map((item) => ({
    ...item,
    selected: item.stplatAgreAt === 'Y',
  }))
  try {
    isLoading.value = true
    isError.value = false
  } catch (err) {
    isError.value = true
  } finally {
    isLoading.value = false
  }
}

// 팝업 닫기
const handleOnClose = async () => {
  await modalHide(modalsName.MODAL_RENT_COMPLETE_MARKETING)
}

// 팝업 닫은후 클리어
const handleOnClosed = () => {
  title.value = ''
  titleError.value = ''
}

// 마케팅 동의 체크 박스 클릭
const handelMarketingAgree = (
  marketingId: NotificationMarketingAgreeCode | undefined,
  selectAll?: boolean
) => {
  // 전체 동의
  if (selectAll) {
    if (allSelected.value) {
      allSelected.value = false
      sourceWithSelected.value = sourceWithSelected.value.map((item) => ({
        ...item,
        selected: false,
        stplatAgreAt: 'N',
      }))
    } else {
      allSelected.value = true
      sourceWithSelected.value = sourceWithSelected.value.map((item) => ({
        ...item,
        selected: true,
        stplatAgreAt: 'Y',
      }))
    }
  } else {
    // 각각 수신 동의 클릭
    const targetItem = sourceWithSelected.value.find(
      (item) => item.stplatTyCode === marketingId?.toString()
    )
    if (targetItem !== undefined) {
      targetItem.selected = !targetItem.selected
      targetItem.stplatAgreAt === 'Y'
        ? (targetItem.stplatAgreAt = 'N')
        : (targetItem.stplatAgreAt = 'Y')
      sourceWithSelected.value = [...sourceWithSelected.value]
    }
  }
}
// 알림 받을게요 버튼 클릭
const fetchMarketingAgree = () => {
  // 1. 선택한 항목이 있는지 검사
  const checkData = sourceWithSelected.value.some(
    (item) => item.selected && item.stplatAgreAt === 'Y'
  )
  if (!checkData) {
    useToast('마케팅 수신 동의 하실 항목을 선택해 주세요.')
    return
  }
  const today = new Date()
  const year = today.getFullYear()
  const month = today.getMonth() + 1
  const date = today.getDate()
  // 오늘 날짜
  const formattedDate = `${year}.${month < 10 ? '0' + month : month}.${
    date < 10 ? '0' + date : date
  }`
  // 토스트에 노출 할 마케팅 수신 동의 항목
  const resultString = sourceWithSelected.value
    .filter((item) => item.stplatAgreAt === 'Y')
    .map((item) => item.ntcnNm)
    .join(', ')
  // 마케팅 수신 동의 한 객체만 추출 // 정제
  const payloads = sourceWithSelected.value
    .map((item) => ({
      stplatAgreAt: item.stplatAgreAt,
      stplatTyCode: item.stplatTyCode,
    }))
    .filter((payload) => payload.stplatAgreAt === 'Y' && payload.stplatTyCode)
  // promises
  const promises = payloads.map((payload) =>
    userStore.updateNotificationMarketing({
      stplatAgreAt: payload.stplatAgreAt,
      stplatTyCode: payload.stplatTyCode,
    })
  )
  Promise.all(promises)
    .then(() => {
      // 모든 요청이 성공한 경우
      // console.log('All requests completed successfully:', results)
      useToast(
        `I LIKE LM 마케팅 알림(${resultString}) 수신에 동의하셨습니다.(${formattedDate})`
      )
      handleOnClose()
    })
    .catch((error) => {
      // 하나 이상의 요청이 실패한 경우
      console.error('Error occurred during requests:', error)
    })
}
// query: reviewReqBefore=Y
</script>

<template>
  <VueFinalModal
    v-model="show"
    :name="modalsName.MODAL_RENT_COMPLETE_MARKETING"
    :content-class="'modal-content'"
    classes="modal-container"
    @before-open="(data:any) => handleOnBeforeOpen(data.ref.params)"
    @click-outside="handleOnClose"
    @closed="handleOnClosed"
  >
    <ClientOnly>
      <NovaLoadingIndicator
        v-if="isLoading"
        :fill="true"
        :bg-bright="'dark'"
        class="async-status-message"
      />

      <NovaBoxBase v-if="!isLoading && show" class="modal-box">
        <div class="modal-header">
          <h4 class="title">
            마케팅 정보 수신에<br />
            동의 하시겠어요?
          </h4>

          <div class="sub-title">
            다양한 이벤트/혜택 정보를 <br />
            앱 푸시로 놓치지 않고 받으실 수 있어요!
          </div>
        </div>

        <NovaLayoutScrollContainer class="modal-body">
          <div v-if="!isError" class="agrees">
            <div
              class="radio-check"
              @click.stop="handelMarketingAgree(undefined, true)"
            >
              <NovaCheckbox
                :is-check="allSelected"
                :theme="'circle'"
                :label="'전체 동의하기'"
              />
            </div>
            <div
              v-for="(item, index) in sourceWithSelected"
              :key="index"
              class="radio-check"
              @click.stop="handelMarketingAgree(item.stplatTyCode)"
            >
              <NovaCheckbox
                :is-check="item.selected"
                :theme="'circle'"
                :label="`${item.ntcnNm} 수신 동의`"
              />
            </div>
          </div>
          <div class="help-message">
            *알림 수신 동의는 I LIKE LM 앱 설정 > <br />
            알림 설정에서 변경할 수 있습니다.
          </div>
        </NovaLayoutScrollContainer>

        <div v-if="!isError" class="actions">
          <NovaButtonText
            :label="'다음에요'"
            :theme="'secondary-gray'"
            :full-width="true"
            class="action"
            @click="handleOnClose"
          />
          <NovaButtonText
            :label="'알림 받을게요'"
            :theme="'secondary-green-light-80'"
            :full-width="true"
            class="action positive"
            @click="fetchMarketingAgree"
          />
        </div>
      </NovaBoxBase>
    </ClientOnly>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
.background-red {
  background-color: red;
}
:deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.vfm--overlay) {
  background-color: hex-to-rgba($color-black, 0.65);
}

:deep(.modal-content) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: none;

  @include mobile {
    padding: 16px;
  }

  .modal-box {
    height: auto;
    max-width: 380px;
    max-height: calc(100% - 24px - 24px);
    padding: 0;
    pointer-events: auto;
    overflow: hidden;

    .modal-header {
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px 20px 10px;
      color: $color-text-2;
      background-color: $color-bg-3;
      gap: 20px;

      .title {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 13px;
        font-size: 25px;
        line-height: 36px;
        letter-spacing: -5%;
        font-weight: 700;
        color: $color-primary-green;
        border-bottom: 1px solid $color-bg-1;
        padding-bottom: 20px;
        width: 100%;
        text-align: center;
      }

      .sub-title {
        font-size: 15px;
        font-weight: 700;
        line-height: 18px;
        text-align: center;
      }
    }

    .modal-body {
      padding: 0 20px 20px;

      .help-message {
        text-align: center;
        @include text-style($text-body-10-regular);
        color: $color-text-3;
      }

      .agrees {
        padding: 20px;
      }

      > .inner {
        display: flex;
        flex-direction: column;

        section.guide {
          .message {
            color: #727272;
            font-weight: 400;

            &.primary {
              font-size: 14px;
              line-height: 23.59px;
              letter-spacing: -0.2px;
            }

            &.secondary {
              font-size: 10px;
              line-height: 17px;
              letter-spacing: -0.2px;
            }
          }
        }

        section.section-wrap {
          display: flex;
          flex-direction: column;
          gap: 8px;
          margin-top: 22px;

          .section-title {
            display: flex;
            align-items: center;
            gap: 14px;
            font-size: 16px;
            font-weight: 700;
            line-height: 29.36px;
            letter-spacing: -0.2px;
            color: $color-black;
          }

          .section-contents {
          }
        }
      }

      .async-status-message {
        min-height: 200px;
      }
    }

    .actions {
      flex-shrink: 0;
      display: flex;
      justify-content: flex-end;
      width: 100%;

      .action {
        border-radius: 0;
        width: 100%;
        height: 55px;

        &.positive {
          background-color: $color-primary-green;
        }
      }
    }

    @include mobile {
      flex-grow: 1;
      max-width: 100%;
    }
  }
}

.error-message {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 10px;
  line-height: 12.1px;
  letter-spacing: -0.2px;
  color: $color-bg-custom-12;
  padding-left: 8px;
  margin-top: 4px;

  .error-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
    line-height: 1.2;
    border-radius: 50%;
    background-color: $color-bg-custom-12;
    color: $color-white;
    font-size: 9px;

    &:before {
      content: '!';
    }
  }
}

.nft-collections {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 12px;
  margin-left: 24px;
  border: 1px solid #727272;
  border-radius: 4px;
  margin-top: 4px;

  .collections {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-left: 24px;
  }
}

.radio-check {
  width: 100%;
  margin-bottom: 12px;
}
</style>
