<script setup lang="ts">
import { LottieAnimation } from 'lottie-web-vue'
import type { NovaFloatingScrollToTopButtonProps } from './NovaFloatingScrollToTopButton.types'
import * as scrollTopAnimation from 'assets/data/lottie/scroll-top.json'

withDefaults(defineProps<NovaFloatingScrollToTopButtonProps>(), {
  show: false,
})

const handleOnScrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}
</script>

<template>
  <button
    type="button"
    :class="['btn-scroll-to-top', { on: show }]"
    @click="handleOnScrollToTop"
  >
    <LottieAnimation
      v-if="show"
      class="lottie"
      :loop="false"
      :animation-data="scrollTopAnimation"
    />
  </button>
</template>

<style lang="scss" scoped>
$size: 48px;

.btn-scroll-to-top {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $size;
  height: $size;
  border-radius: calc($size / 2);
  background-color: $color-secondary-blue-light-70;
  opacity: 0;
  z-index: $z-index-scroll-top-button;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
  @include transition(opacity 0.2s ease-in-out, bottom 0.2s ease-in-out);

  &:before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      -45deg,
      $color-secondary-blue-light-40 0%,
      $color-secondary-blue-light-70 100%
    );
    transform: translate3d(0px, 6px, 0) scale(0.95);
    filter: blur(4px);
    opacity: 0.7;
    transition: opacity 0.3s;
    border-radius: inherit;
  }

  &::after {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
    border-radius: inherit;
  }

  .lottie {
    padding-top: 3px;
    width: $size;
    height: $size;
  }

  @include mobile {
    opacity: 1;
    pointer-events: auto;
  }
}
</style>
