<script setup lang="ts">
import type { NovaBoxPostCommentsAndRepliesProps } from './NovaBoxPostCommentsAndReplies.types'

defineProps<NovaBoxPostCommentsAndRepliesProps>()

const postStore = usePostStore()
const collapseCommentsAndReplies = ref(false)
const collapseLimit = ref(20)
</script>

<template>
  <section class="comments-and-replies">
    <p
      v-if="!postStore.commentsAndReplies.items.length"
      v-dompurify-html="$t('statusMessages.comments.empty')"
      class="empty-message"
    />

    <div v-else class="inner">
      <NovaBoxPostCommentsAndRepliesHeader />
      <NovaBoxPostCommentsAndRepliesContents
        :collapse="collapseCommentsAndReplies"
        :limit="collapseLimit"
        :view-type="viewType"
        :cmty-ntt-sn="cmtyNttSn"
        :answer-count="answerCount"
      />
      <NovaBoxPostCommentsAndRepliesFooter
        v-model="collapseCommentsAndReplies"
        :count="postStore.commentsAndReplies.items.length"
        :limit="collapseLimit"
      />
    </div>
  </section>
</template>

<style lang="scss" scoped>
.comments-and-replies {
  .empty-message {
    @include text-style($text-display-bold);
    line-height: 22px;
    text-align: center;
  }

  .inner {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
</style>
