import type { FundState } from './type'

export const initFundState: FundState = {
  fundBanner: null,
  fundSurvey: {
    data: null,
    payload: {
      orderBy: 'highest_turnout',
      pageNum: 0,
      size: 100,
    },
    refresh: false,
    last: false,
    loading: false,
  },
  fundProject: null,
  fundHistories: {
    items: [],
    payload: {
      orderBy: 'newest',
      pageNum: 0,
      size: 100,
    },
    refresh: false,
    loading: false,
    last: false,
  },
}
