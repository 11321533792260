import { $vfm, type DynamicModalOptions } from 'vue-final-modal'
import type { RouteLocationNormalized } from 'vue-router'
import { ROUTES } from '@configs'
import { bridgeToApp } from '@bridge'

export const modalsName = {
  MODAL_DIALOG: 'ModalDialog', // 일반 다이얼로그
  MODAL_CREATE_POST: 'ModalCreatePost', // 포스트 작성
  MODAL_POST_DETAIL: 'ModalPostDetail', // 포스트 상세
  MODAL_POST_PREVIEW: 'ModalPostPreview', // 포스트 미리보기
  MODAL_POST_TEMP_LIST: 'ModalPostTempList', // 포스트 임지저장 목록
  MODAL_REPORT_POST: 'ModalReportPost', // 포스트 신고하기
  MODAL_ALL_HASHTAGS: 'ModalAllHashtags', // 해시태그 페이지 > 모든 해시태그 목록
  MODAL_NOTIFY_POST_DETAIL_ERROR: 'ModalNotifyPostDetailError', // 포스트 상세 페이지로 접근 할 수 없을때 feed 리다이렉트 후 안내 다이얼로그
  MODAL_NOTIFY_POST_DETAIL_BLOCKED_ERROR: 'ModalNotifyPostDetailBlockError', // 차단된 사용자가 포스트 상세 페이지로 접근 할 수 없을때 리다이렉트 후 안내 다이얼로그
  MODAL_UN_SUBSCRIBED_POST_DETAIL_ERROR: 'ModalUnSubscrivedPostDetailError', // 구독 권한이 없어 포스트 상세 페이지로 접근 할 수 nova-plus 리다이렉트 후 안내 다이얼로그
  MODAL_TEMP_POST_DELETE_CONFIRM: 'ModalTempPostDeleteConfirm', // 임시 포스트 목록에서 아이템 삭제 컨펌
  MODAL_REQUIRE_SIGN_IN: 'ModalRequireSignIn', // 로그인 필요
  MODAL_MULTIPLE_SIGN_IN: 'ModalMultipleSignIn', // 같은 브라우저를 사용하고 다른 탭에서 다른 사용자로 로그인시 하나는 로그아웃 처리
  MODAL_MEMBERSHIP_OFF: 'ModalMembershipOff', // 회원 탈퇴
  MODAL_MEMBERSHIP_OFF_GUIDE: 'ModalMembershipOffGuide', // 탈퇴 가이드
  MODAL_MEMBERSHIP_OFF_QNA: 'ModalMembershipOffQnA', // 탈퇴 문의,
  MODAL_CS: 'ModalCS', // 사용자 문의
  MODAL_REPORT_COMMENT: 'ModalReportComment', // 댓글 신고
  MODAL_CONFIRM_DELETE_COMMENT: 'ModalConfirmDeleteComment', // 댓글 삭제 확인
  MODAL_UPLOAD_IMAGE: 'ModalUploadImage', // 이미지 업로드
  MODAL_AVATAR: 'ModalAvatar', // 아바타 편집
  MODAL_DONATE: 'ModalDonate', // 후원하기
  MODAL_CONTENTS_DETAIL: 'ModalContentsDetail', // 크리에이터 콘텐츠 상세
  MODAL_REG_CREATOR_GUEST_BOOK: 'ModalRegCreatorGuestBook', // 크리에이터 방명록 생성
  MODAL_REG_CREATOR_NOTICE: 'ModalRegCreatorNotice', // 크리에이터 공지사항 생성
  MODAL_CREATOR_NOTICE_DETAIL: 'ModalCreatorNoticeDetail', // 포스트 상세
  MODAL_CREATOR_NOTICE_PREVIEW: 'ModalreatorNoticePreview', // 포스트 미리보기
  MODAL_CREATOR_NOTICE_TEMP_LIST: 'ModalreatorNoticeTempList', // 포스트 임지저장 목록
  MODAL_NOTIFY_CREATOR_NOTICE_DETAIL_ERROR: 'ModalNotifyPostDetailError', // 포스트 상세 페이지로 접근 할 수 없을때 홈으로 리다이렉트 후 안내 다이얼로그
  MODAL_TEMP_PCREATOR_NOTICE_DELETE_CONFIRM: 'ModalTempPostDeleteConfirm', // 임시 포스트 목록에서 아이템 삭제 컨펌
  MODAL_OPTION_MENU: 'ModalOptionMenu',
  MODAL_REFERRAL_NOT_ABLE: 'ModalReferralNotAble', // 레퍼럴 회원가입을 이용할 수 없음
  MODAL_REFERRAL: 'ModalReferral', // 레퍼럴 회원가입 안내
  MODAL_SHARE_ACTION: 'ModalShareAction', // 모바일일때 포스트 공유 액션 시트
  MODAL_KYC_PROCESS: 'ModalKycProcess', // KYC 미인증 상태일때 가이드
  MODAL_W3CF_DETAIL: 'ModalW3cfDetail', // Web3.0 creator festival 후보작 상세 모달
  MODAL_TERM_DETAIL: 'ModalTermDetail', // 이용약관 모달
  MODAL_PAY: 'ModalPay', // 결제 모듈 모달
  MODAL_SELECT_BANK: 'ModalSelectBank', // 은행 선택 모달
  MODAL_FUND_PROJECT_DETAIL: 'ModalFundProjectDetail', // 펀드 프로젝트 상세 모달
  MODAL_PREMIUM_EXPIRED: 'ModalPremiumExpired', // 프리미엄 후원 만료 알림 모달
  MODAL_SPONSOR_DETAIL: 'ModalSponsorDetail', // 후원 상세 모달
  MODAL_MY_FOLLOWERS: 'ModalMyFollowers', // 팔로워 모달
  MODAL_MY_FOLLOWINGS: 'ModalMyFollowings', // 팔로잉 모달
  MODAL_MY_BLOCK: 'ModalMyBlock', // 차단 안내 모달
  MODAL_MOB_APP_GATE: 'ModalMobAppGate', // 모바일 웹화면 앱 이동 안내 팝업
  MODAL_REQUIRE_PERMISSION: 'ModalRequirePermission', // 모바일 권한 요청 안내 팝업
  MODAL_BEFORE_REQUEST_NOTIFICATION_PERMISSION:
    'ModalBeforeRequestNotificationPermission', // 알림 권한 요청 전 안내
  MODAL_CREATE_LIVE: 'ModalCreateLive', // 라이브 개설
  MODAL_NOT_AVAILABLE_ENTER_LIVE: 'ModalNotAvailableEnterLive', // 게스트로 라이브 진입 불가 안내
  MODAL_CREATE_OPEN_CHANNEL: 'ModalCreateOpenChannel', // 오픈 채널 개설
  MODAL_MARKETING_PUSH_AGREEMENT: 'ModalMarketingPushAgreement', // 마케팅 푸시 동의 모달
  MODAL_BLOCK_ACCESS: 'ModalBlockAccess', // 활동 정지 안내 모달
  MODAL_TERM_OF_FACILITY: 'NovaModalTermOfFacility', // 더문엔터 입주신청 약관 상세
  MODAL_RENT_COMPLETE_MARKETING: 'NovaModalRentCompleteMarketing', // 더문엔터 입주 완료 페이지 마케팅 동의
  MODAL_BONUS_REWARD_MONTHLY: 'NovaModalBonusRewardMonthly', // 월간 보너스 리워드
  MODAL_MY_COLLECTION_NFT_DETAIL: 'NovaModalMyCollectionNft', // 월간 보너스 리워드
  MODAL_TERM_OF_SUPER_MOON: 'NovaModalTermOfSuperMoon', // 슈퍼문 참가 신청 약관 사세
  MODAL_FEED_FOR_YOU_REFRESH: 'NovaModalFeedForYouRefresh', // 피드 추천순 목록 갱신중 안내
}

export type ModalNameKeys = keyof typeof modalsName
export type ModalName = (typeof modalsName)[ModalNameKeys]
export interface MappedDynamicModalOptions extends DynamicModalOptions {
  bind: {
    [key: string]: any
    name: string
  }
}

/**
 * useModal은 모달을 열고 닫는 매서드 제공
 * @param middlewareTo - 선택적 미들웨어 경로, 기본 값은 useRoute()
 * @returns 모달을 보여주고 숨기는 메서드가 있는 객체를 반환
 */
export const useModal = (middlewareTo?: RouteLocationNormalized) => {
  const layoutStore = useLayoutStore()
  const route = middlewareTo || useRoute()
  const appStore = useAppStore()

  /**
   * 모달 호출
   * @param modal
   * @param params
   * @param landingPath
   * @param routeSync
   */
  const show = async (
    modal: ModalName | MappedDynamicModalOptions,
    params?: any,
    landingPath?: string,
    routeSync = true
  ): Promise<void> => {
    // 모달 열때 라우터 이동
    const replaceRouter = (modalName: string) => {
      layoutStore['modal:show']({ modalNameKey: modalName, routeSync })

      if (routeSync) {
        const _landingPath = landingPath?.replace(/^(\/)/, '') || ''
        return useNavigations({
          url: _landingPath ? `/${_landingPath}` : route.path,
          query: {
            ...route.query,
            modals: [
              ...layoutStore.modals
                .filter((item) => item.routeSync)
                .map((item) => item.modalNameKey),
            ],
          },
          type: 'replace',
        })
      }
    }
    const handleUnsetPullToRefresh = (modalName: string) => {
      const modalsToUnsetPullToRefresh = [
        'ModalMyFollowers',
        'ModalMyFollowings',
        'ModalCreatePost',
      ]
      if (appStore.isApp && modalsToUnsetPullToRefresh.includes(modalName)) {
        bridgeToApp.setPullToRefreshEnabled('false')
      }
    }

    let modalName: string

    if (typeof modal === 'string') {
      modalName = modal
    } else if (typeof modal === 'object' && Object.keys(modal).length) {
      modalName = modal.bind.name
    } else {
      return
    }

    handleUnsetPullToRefresh(modalName)
    await replaceRouter(modalName)
    await $vfm.show(modal, params)
  }

  /**
   * 모달 닫기
   * @param modalName
   * @param routeSync
   * @param turnOffRouteBack
   * @param landingPath
   */
  const hide = async (
    modalName: ModalNameKeys | string,
    routeSync = true,
    turnOffRouteBack = false,
    landingPath = ''
  ): Promise<void> => {
    if (!routeSync) {
      await $vfm.hide(modalName)
      return
    }
    const isRouteSync = layoutStore.modals.find(
      (item) => item.modalNameKey === modalName
    )?.routeSync
    // 1. store 에서 모달 이력 삭제
    layoutStore['modal:hide'](modalName)
    if (isRouteSync === true) {
      // 2. 쿼리에서 모달 삭제
      const query = { ...route.query }
      if (layoutStore.modals.length) {
        query.modals = [
          ...layoutStore.modals
            .filter((item) => item.routeSync)
            .map((item) => item.modalNameKey),
        ]
      } else {
        delete query.modals
      }

      // 3. 뒤로가기
      if (!turnOffRouteBack) {
        await useNavigations({
          type: 'replace',
          url: landingPath || route.path,
          query,
        })
      }
      await $vfm.hide(modalName)
    }

    if (isRouteSync === false) {
      if (window.history.state.back) {
        const currentRoute = Object.values(ROUTES).find(
          (_route) => _route.name === useGetRouteName(String(route.name))
        )

        if (currentRoute?.isModalPage) {
          const parentRoute = route.matched[route.matched.length - 2]
          let path = parentRoute.path
          const pathVariables = useGetRoutePathVariables(path)

          pathVariables.forEach(({ key, match }) => {
            path = path.replace(match, String(route.params[key]))
          })
          await useNavigations({ url: path, query: route.query })
        }
      } else {
        // 모달 이름으로 열기 > 닫을때 여기를 타는데
        // 모달에서 다이나믹 모달을 열때 url이 생성되니까 여기서 뒤로가기를 하면
        // 뒤로가기 url이 다아니믹 모달의 url을 바라보게되기 때문에 에러가 발생
        // 위의 조건 수정 !window.history.state.back -> window.history.state.back
        await useNavigations({ type: 'back' })
      }
      await $vfm.hide(modalName)
    }
  }

  /**
   * 모든 모달 닫기
   */
  const hideAll = async (routeSync = true): Promise<void> => {
    // 1. store 에서 모든 모달 이력 삭제
    layoutStore['modal:hideAll']()

    if (routeSync) {
      // 2. 쿼리에서 모달 삭제
      const query = { ...route.query }
      delete query.modals

      // 3. 뒤로가기()
      await useNavigations({ url: route.path, type: 'replace', query })
    }

    // 4. 모달 닫기
    await $vfm.hideAll()
  }

  return { show, hide, hideAll }
}
