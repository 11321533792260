import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import calendar from 'dayjs/plugin/calendar'
import localeData from 'dayjs/plugin/localeData'
import duration from 'dayjs/plugin/duration'
import 'dayjs/locale/en'
import 'dayjs/locale/es'
import 'dayjs/locale/th'
import 'dayjs/locale/ko'

const useDayjs = () => {
  const { locale: i18nLocale } = useNuxtApp().$i18n
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.extend(calendar)
  dayjs.extend(localeData)
  dayjs.extend(duration)
  dayjs.locale(i18nLocale.value || 'ko')

  return { dayjs }
}

export default useDayjs
