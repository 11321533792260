<script setup lang="ts">
import { ChatButtonMode } from '@components/NovaChatButton/NovaChatButton.types'
import { ROUTES } from '@configs'
import { bridgeToApp } from '@bridge'

const appStore = useAppStore()
const config = useRuntimeConfig()
const layoutStore = useLayoutStore()
const { mainBgWhite } = storeToRefs(layoutStore)
const userStore = useUserStore()
const { gtSet } = useGoogleTag()
const rootRef = ref<Element | null>(null)
const { receiver } = usePostMessage()
const showDevTools = computed(() => config.public.MODE !== 'production')
const showChatButton = computed(() => {
  const routeName = useGetRouteName()
  return (
    !(
      routeName.includes(ROUTES.CREATOR.name) ||
      routeName.includes(ROUTES.CALLBACK_SIGNIN.name) ||
      routeName.includes(ROUTES.CALLBACK_SIGNUP.name) ||
      routeName.includes(ROUTES.CALLBACK_PAY.name) ||
      routeName.includes(ROUTES.REFERRAL.name)
    ) && !(userStore.user?.svcActSanctnsAt === 'Y')
  )
})

console.log('MODE:', config.public.MODE)
console.log('API_URL:', config.public.API_URL)
console.log('APP_NAME:', config.public.APP_NAME)

watch(
  () => mainBgWhite.value,
  (cur) => {
    const body = document.body
    if (body) {
      body.classList[cur ? 'add' : 'remove']('bg-white')
    }
  }
)

watch(
  () => layoutStore.modals.length,
  (cur) => {
    if (appStore.isApp) {
      setPullToRefresh(cur === 0)
    }
  }
)
// web to app pull to refresh control
const setPullToRefresh = (type: boolean) => {
  if (type) bridgeToApp.setPullToRefreshEnabled('true')
}

// Init AuthGate Listener
const initAuthGateListener = () => {
  const routeName = useGetRouteName()
  const disabledRouteNameList = [
    ROUTES.CALLBACK_SIGNUP.name,
    ROUTES.CALLBACK_SIGNIN.name,
    ROUTES.CALLBACK_PAY.name,
  ]
  if (!disabledRouteNameList.some((item) => routeName.includes(item))) {
    window.addEventListener('message', receiver)
  }
}

// Init BlockRouteBack
const initBlockRouteBack = () => {
  window.popStateDetected = false
  window.addEventListener('popstate', () => {
    window.popStateDetected = true
  })
}

// Init Device
const initDevice = () => {
  const isMobile = useCheckMobile()
  document.documentElement.classList.add(isMobile ? 'mobile' : 'desktop')
}

// GA config 세팅
const setGaUserInformation = () => {
  const data = userStore.userCommonInfo
    ? {
        userSn: userStore.userCommonInfo.userSn,
        userAge: userStore.userCommonInfo.birth
          ? useAgeCalculate(userStore.userCommonInfo.birth)
          : null,
        userGender: userStore.userCommonInfo.gender,
      }
    : {
        userSn: null,
        userAge: null,
        userGender: null,
      }

  gtSet('user_properties', data)
}

onMounted(() => {
  initAuthGateListener()
  initBlockRouteBack()
  initDevice()
  setGaUserInformation()
})
</script>

<template>
  <div id="root" ref="rootRef">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <!-- 전역 컴퍼넌트 -->
    <NovaLoadingIndicatorGlobal />
    <NovaFloating />

    <!-- 전역 컨테이너 -->
    <NovaContainerGlobalModals />
    <NovaContainerNaverAnalytics />
    <NovaContainerKakaoSdk />
    <NovaContainerPremiumExpiredNotification />
    <NovaContainerBridgeAndFirebase />
    <NovaContainerShowModal />
    <NovaContainerLayerControl />
    <NovaDevTools v-if="showDevTools" />
    <ClientOnly>
      <NovaChatButton v-if="showChatButton" :mode="ChatButtonMode.FLOATING" />
    </ClientOnly>
    <!--  <NovaContainerNaverSearchAdvisor />-->
  </div>
</template>
