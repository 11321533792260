import * as CommType from './type'
import CommService from './service'
import { initCommState } from './init'
import { BlockingTarget, type BooleanYn } from '@store/types'

export * from './init'
export * from './type'
export const useCommStore = defineStore('comm', () => {
  const postStore = usePostStore()
  const searchStore = useSearchStore()
  const creatorStore = useCreatorStore()
  const mypageStore = useMyPageStore()

  const appVersion = ref(initCommState.appVersion)

  /**
   * 외부 URL 크롤링
   * @param payload
   */
  const fetchCrawling = (payload: CommType.CrawlingPayload) =>
    CommService.fetchCrawling(payload)

  /**
   * 팔로우
   * @param payload
   */
  const reqFollow = async (payload: CommType.FollowPayload) => {
    await CommService.reqFollow(payload)

    postStore.updatePostFollowUnfollow({
      userSn: payload.flwUserSn,
      follow: true,
    })

    searchStore.updateSearchUserFollowUnfollow({
      userSn: payload.flwUserSn,
      follow: true,
    })

    creatorStore.updateCreatorContentsFollowUnfollow({
      userSn: payload.flwUserSn,
      follow: true,
    })

    mypageStore.updateUpdateFollowUnFollow({
      userSn: payload.flwUserSn,
      follow: true,
    })
  }

  /**
   * 언팔로우
   * @param payload
   */
  const reqUnfollow = async (payload: CommType.UnfollowPayload) => {
    await CommService.reqUnfollow(payload)

    postStore.updatePostFollowUnfollow({
      userSn: payload.flwUserSn,
      follow: false,
    })

    searchStore.updateSearchUserFollowUnfollow({
      userSn: payload.flwUserSn,
      follow: false,
    })

    creatorStore.updateCreatorContentsFollowUnfollow({
      userSn: payload.flwUserSn,
      follow: false,
    })

    mypageStore.updateUpdateFollowUnFollow({
      userSn: payload.flwUserSn,
      follow: false,
    })
  }

  /**
   * 크리에이터 후원하기
   * @param payload
   */
  const reqCreatorDonate = (payload: CommType.CreatorDonatePayload) =>
    CommService.reqCreatorDonate(payload)

  /**
   * 사용자 홈 url
   * @param payload
   */
  const fetchHomeUrl = async (payload: CommType.HomeUrlPayload) => {
    const { data } = await CommService.fetchHomeUrl(payload)
    return data
  }

  /**
   * 파일 등록: 이미지(멀티)
   * @param payload
   */
  const uploadFileImages = async (payload: FormData) => {
    const { data } = await CommService.uploadFileImages(payload)
    return data
  }

  /**
   * 파일 등록: 비디오
   * @param payload
   */
  const uploadFileVideo = async (payload: FormData) => {
    const { data } = await CommService.uploadFileVideo(payload)
    return data
  }

  /**
   * 앱 버전 조회
   */
  const fetchAppVersion = async () => {
    const { data } = await CommService.fetchAppVersion()
    appVersion.value = data
  }
  /**
   * 사용자 차단
   */
  const reqBlockingUser = async (
    payload: CommType.BlockingUserPayload,
    target: BlockingTarget
  ) => {
    await CommService.reqBlockingUser(payload)
    updateBlockingStatus('Y', target, payload.blckgUserSn)
  }

  /**
   * 사용자 차단 해제
   */
  const reqUnBlockingUser = async (
    payload: CommType.UnBlockingUserPayload,
    target: BlockingTarget
  ) => {
    await CommService.reqUnBlockingUser(payload)

    switch (target) {
      case BlockingTarget.USER:
        await mypageStore.fetchHomeProfile({
          userNcnm: mypageStore.homeProfile!.userNcnm,
        })
        break
      case BlockingTarget.CREATOR:
        await creatorStore.fetchProfile({
          creatorUserSn: creatorStore.profile!.userSn,
        })
        break
      default:
        break
    }

    updateBlockingStatus('N', target, payload.blckgUserSn)
  }

  /**
   * 사용자 차단 상태 변경
   */
  const updateBlockingStatus = (
    blocking: BooleanYn,
    target: BlockingTarget,
    userSn: number
  ) => {
    const { profile: creatorProfile, updateCreatorProfile } = creatorStore
    const {
      homeProfile: userProfile,
      myBlocks: blockingUsers,
      updateUserProfile,
    } = mypageStore

    if (target === BlockingTarget.CREATOR && creatorProfile) {
      updateCreatorProfile({
        ...creatorProfile,
        blockingAt: blocking,
        followerAt: blocking === 'Y' ? 'N' : creatorProfile.followerAt,
      })
    }

    if (target === BlockingTarget.USER && userProfile) {
      updateUserProfile({
        ...userProfile,
        blockingAt: blocking,
        followerAt: blocking === 'Y' ? 'N' : userProfile.followerAt,
      })
    }

    if (blocking === 'N') {
      const deleteTargetIdx = blockingUsers.items.findIndex(
        (user) => user.userSn === userSn
      )

      if (deleteTargetIdx !== -1) {
        blockingUsers.items.splice(deleteTargetIdx, 1)
      }
    }
  }

  return {
    appVersion,
    fetchCrawling,
    reqFollow,
    reqUnfollow,
    reqCreatorDonate,
    fetchHomeUrl,
    uploadFileImages,
    uploadFileVideo,
    fetchAppVersion,
    reqBlockingUser,
    reqUnBlockingUser,
  }
})
