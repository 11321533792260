import AuctionApi from './url'
import * as AuctionTypes from './type'
import { api } from '@api'

export default {
  /**
   * Auction 리스트 조회
   */
  fetchAuctions() {
    return api.get<AuctionTypes.AuctionItem[]>(AuctionApi.FETCH_AUCTIONS)
  },
}
