export const useRoutePathIdChange = (
  path: string,
  ids: Record<string, string>
) => {
  let newPath = path
  const newIds = { ...ids }

  Object.entries(ids).forEach(([key, value]) => {
    if (newPath.includes(`:${key}()`)) {
      // newPath = newPath.replace(`:${key}`, encodeURIComponent(String(value)))
      newPath = newPath.replace(`:${key}()`, value)
      delete newIds[key]
    }
  })

  return newPath
}
