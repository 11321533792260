type UseGetRoutePathVariables = Array<{ key: string; match: string }>

export const useGetRoutePathVariables = (
  url: string
): UseGetRoutePathVariables => {
  const pathVariables: UseGetRoutePathVariables = []
  const regex = /:(\w+)\(\)/g
  let match: RegExpExecArray | null

  while ((match = regex.exec(url)) != null) {
    pathVariables.push({ key: match[1], match: match[0] })
  }

  return pathVariables
}
