import apiPath from '@store/apiPath'

export default {
  /**
   * 출석 체크
   * @method POST
   */
  REQ_DAILY_CHECK: `${apiPath.ATTENDANCE}/v1/check`,

  /**
   * 출석 체크 상태 조회
   * @method GET
   */
  FETCH_DAILY_CHECK: `${apiPath.ATTENDANCE}/v1/:date`,
}
