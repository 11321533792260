/**
 * 모든 브릿지 헨들러 키
 * @description WEB > APP, APP > WEB
 */
export const handlerKey = {
  // toApp
  /** 브릿지 연결 준비 완료 알리기 */
  IS_READY_TO_BRIDGE: 'isReadyToBridge',
  /** 새로운 브라우저 열기 */
  OPEN_WEB: 'openWeb',
  /** 웹뷰 닫기 */
  CLOSE_WEB: 'closeWeb',
  /** 웹뷰 당으며 이전 웹뷰 화면 이동 */
  CLOSE_WEB_AND_REDIRECTION: 'closeWebAndRedirection',
  /** 홈으로 이동 */
  MOVE_HOME: 'moveHome',
  /** 앱 스토리지 저장 */
  SAVE_STORAGE: 'saveStorage',
  /** 앱 스토리지 조회 */
  GET_STORAGE: 'getStorage',
  /** 앱 스토리지 삭제 */
  CLEAR_STORAGE: 'clearStorage',
  /** 웹뷰 로컬 스토리지 삭제 */
  CLEAR_WEB_LOCAL_STORAGE: 'clearWebLocalStorage',
  /** 웹뷰 쿠키 삭제 */
  CLEAR_WEB_COOKIE: 'clearWebCookie',
  /** 앱 종료 */
  TERMINATE_APP: 'terminateApp',
  /** 권한 체크 */
  CHECK_PERMISSION: 'checkPermission',
  /** 권한 요청 */
  REQUEST_PERMISSION: 'requestPermission',
  /** 앱 강제 업데이트 */
  FORCE_UPDATE: 'forceUpdate',
  /** 사용자 위치 정보 조회 */
  GEO_LOCATION: 'geoLocation',
  /** 풀 투 리프레쉬 온 */
  SET_PULL_TO_REFRESH: 'setPullToRefreshEnabled',
  /** 웹 풀 스크린 상태 업데이트 */
  FULL_SCREEN_STATUS: 'fullscreenStatus',

  // toWeb
  // 항상 리스닝 하는 헨들러
  /** 네비게이션 뒤로가기 */
  GO_BACK: 'goBack',
  /** 화면 갱신 */
  REDRAW: 'redraw',
  /** 앱 상태 변경 알림 */
  ON_APP_STATUS_CHANGE: 'onAppStatusChange',
  /** 특정 딥링크로 연결된 URL을 전달 */
  DEEP_LINK: 'deepLink',
  /** 인앱 브라우저(네이티브 다이얼로그) 닫을때 호출 */
  ON_CLOSE_DIALOG: 'onCloseDialog',
  /** 푸시 메세지 전달 */
  PUSH_MESSAGE_TO_WEB: 'pushMessageToWeb',
} as const

/**
 * APP > WEB 브릿지 초기화 시 항상 리스너로 등록되는 헨들러 키
 * @description 비동기가 불가능한 상황에서 비동기 처리 하기 위해 리스너로 등록함
 */
export const alwaysListeningHandlerKey = [
  handlerKey.GO_BACK,
  handlerKey.REDRAW,
  handlerKey.ON_APP_STATUS_CHANGE,
  handlerKey.DEEP_LINK,
  handlerKey.PUSH_MESSAGE_TO_WEB,
]
