import { type Route, ROUTES } from '@configs'

/**
 * 주어진 라우트 이름에 대한 Page ID를 가져오기
 * 라우트 이름이 제공되지 않을 경우, 기본 라우트 이름이 사용
 *
 * @param {Route['name']} [routeName] - 선택적인 라우트 이름
 * @returns {string} - 라우트에 대한 Page ID
 */
export const useGetRoutePageID = (routeName?: Route['name']): string => {
  const name = useGetRouteName(routeName || '')
  return (
    Object.values(ROUTES).find((_route) => _route.name === name)?.pageID || ''
  )
}
