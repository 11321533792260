<script setup lang="ts">
import type { CellsNavigationBarProps } from './NovaHeaderCellsNavigationBar.types'

const emit = defineEmits(['goBack'])
withDefaults(defineProps<CellsNavigationBarProps>(), {
  label: '',
})

const handleOnGoBack = () => {
  emit('goBack')
}
</script>

<template>
  <div class="menus-navigation-bar">
    <NovaButtonIcon
      :icon="{ type: 'outline', name: 'arrow-left' }"
      :size="20"
      class="btn-back"
      @click="handleOnGoBack"
    />

    <span v-show="label" class="navigation-label">{{ label }}</span>
  </div>
</template>

<style lang="scss" scoped>
.menus-navigation-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding: 20px 20px 20px 15px;

  .btn-back {
    color: $color-text-2;
  }

  > .navigation-label {
    @include text-style($text-body-14-bold);
  }
}
</style>
