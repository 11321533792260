<script setup lang="ts">
import { Tippy } from 'vue-tippy'
import request from 'axios'
import { v4 } from 'uuid'
import { LayerType } from '@store'

const { gtEvent } = useGoogleTag()
const { t } = useI18n()
const userStore = useUserStore()
const creatorStore = useCreatorStore()
const mypageStore = useMyPageStore()
const layoutStore = useLayoutStore()
const appStore = useAppStore()
const { checkPermission, requestPermission } = useAppPermission()

const isOpen = computed(() =>
  layoutStore.layers.includes(LayerType.USER_MAIN_PROFILE_EDIT_PANEL)
)
const isReady = ref(false)
const categoryEditMode = ref(false)
const creatorCategoriesDropdownMenu = computed(() =>
  creatorStore.creatorCategories.map((item) => ({
    label: item.ctgryNm,
    value: item.crtrCtgrySn,
  }))
)
const creatorCategory = computed(() =>
  creatorStore.creatorCategories.find(
    (item) => item.crtrCtgrySn === (userStore.user?.crtrCtgrySn || -1)
  )
)

watch(
  () => isOpen.value,
  async (cur) => {
    if (cur) {
      await fetchMyProfile()
      await fetchCreatorCategories()
    }
  }
)

// 크리에이터 프로필 조회
const fetchMyProfile = async () => {
  try {
    isReady.value = false
    await creatorStore.fetchMyProfile({
      creatorLink: userStore.user!.creatorLink,
    })
    if (creatorStore.profile?.userSn === userStore.user?.userSn) {
      await creatorStore.fetchCreatorHome({
        creatorUserSn: userStore.user!.userSn,
      })
    }
  } catch {
  } finally {
    isReady.value = true
  }
}

const onModifyProfile = async () => {
  // 앱 일때 스토리지 권한 체크
  if (appStore.isApp) {
    const { data: permission } = await requestPermission('storage')

    if (permission.status === 'off') {
      const accessAble = await checkPermission({
        permission: 'storage',
        permissionType: 'mainProfileImage',
      })
      if (!accessAble) return
    }
  }
  gtEvent('userAction', {
    eventCategory: '사용자',
    eventAction: '메인 프로필 설정 > 이미지 클릭',
    eventLabel: 'image-container',
    eventSlot: '메인 프로필 설정 > 이미지',
    eventI18nAddr: '',
    eventComponent: 'Button',
  })
  try {
    const { formData } = await useGetFiles({
      accept: 'image/*',
      multiple: false,
      formDataName: 'profileImg',
    })
    await creatorStore.regCreatorImageUpdate(formData)
    await mypageStore.fetchProfile()
    gtEvent('fileAction', {
      eventCategory: '파일',
      eventAction: '메인 프로필 설정 > 파일 업로드',
      eventLabel: '',
      eventSlot: '메인 프로필 설정 > 백그라운드 이미지 업로드',
      eventI18nAddr: '',
      eventComponent: 'File Upload',
      fileName: userStore.user?.crtrProfileUrl,
    })
  } catch (err) {
    if (request.isAxiosError(err) && err.response) {
      switch (err.response.status) {
        case 2005:
          useToast(t('mypage.profile.toastMessage.imgError2005'))
          break
        default:
          useToast(t('mypage.profile.toastMessage.imgError'))
          break
      }
    }
  }
}

// 크리에이터 카테고리 조회
const fetchCreatorCategories = async () => {
  try {
    await creatorStore.fetchCreatorCategories({
      crtrOrgnztTyCode: userStore.user!.crtrOrgnztTyCode,
    })
  } catch {}
}

const closeMainProfileSetting = () => {
  layoutStore['layer:close'](LayerType.USER_MAIN_PROFILE_EDIT_PANEL)
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '메인 프로필 설정 닫기',
    eventLabel: '',
    eventSlot: '',
    eventI18nAddr: '',
    eventComponent: 'Button',
  })
}

const handleOnToggleCategoryEditMode = () => {
  categoryEditMode.value = !categoryEditMode.value
  gtEvent('userAction', {
    eventCategory: '사용자',
    eventAction: '카테고리 변경 버튼 클릭',
    eventLabel: t('category'),
    eventSlot: '메인 프로필 설정 > 카테고리',
    eventI18nAddr: useKoreanTranslation('category'),
    eventComponent: 'Button',
  })
}

const handleOnChangeCreatorCategory = async (crtrCtgrySn: number) => {
  try {
    await creatorStore.updateCreatorCategory({ crtrCtgrySn })
    gtEvent('selectEvent', {
      eventCategory: '선택',
      eventAction: '카테고리 변경 > 카테고리 선택',
      eventLabel: t('category'),
      eventSlot: '유저 패널 > 설정 > 메인 프로필 설정 > 카테고리',
      eventI18nAddr: useKoreanTranslation('category'),
      eventComponent: 'DropDown',
      creatorCategory: creatorCategory.value?.crtrCtgrySn,
    })
    useToast(
      t('creatorMainProfile.updateCreatorCategorySuccess', {
        categoryName: creatorCategory.value?.ctgryNm,
      })
    )
  } catch {
    useToast(t('creatorMainProfile.updateCreatorCategoryFail'))
  }
}
</script>

<template>
  <div :class="['page-setting', { on: isOpen, ready: isReady }]">
    <div class="dim" @click="closeMainProfileSetting" />

    <div class="tab-box">
      <ul>
        <li class="active">
          <a>{{ $t('creatorMainProfile.editMainProfile') }}</a>
        </li>
      </ul>
      <a class="btn-close-layer" @click="closeMainProfileSetting" />
    </div>

    <div class="setting-boxes">
      <div class="profile-image-box">
        <div class="thumbnail">
          <NovaImageContainer
            :key="mypageStore.profile?.crtrProfileUrl || v4()"
            :ratio="'1:1'"
            :image-url="mypageStore.profile?.crtrProfileUrl"
            class="image-container"
          />

          <button class="btn-img-setting" @click="onModifyProfile" />
        </div>
      </div>

      <p class="info-text">
        <NovaIcon :icon="{ type: 'outline', name: 'info' }" :size="16" />
        <span>{{ $t('creatorMainProfile.profileImageGuide') }}</span>
      </p>

      <div class="setting-box">
        <div class="title">
          <p>{{ $t('category') }}</p>

          <Tippy
            :append-to="'parent'"
            :content="$t(categoryEditMode ? 'cancel' : 'modify')"
            :interactive="true"
            :theme="'simple'"
            :placement="'bottom'"
          >
            <NovaButtonIcon
              :icon="{
                type: 'outline',
                name: categoryEditMode ? 'close-extend' : 'write',
              }"
              :size="16"
              @click="handleOnToggleCategoryEditMode"
            />
          </Tippy>
        </div>

        <div class="contents">
          <p v-if="!categoryEditMode" class="creator-category">
            {{ creatorCategory?.ctgryNm || '' }}
          </p>

          <NovaDropdown
            v-else
            :direction="'top'"
            :active-value="creatorCategory?.crtrCtgrySn"
            :menus="creatorCategoriesDropdownMenu"
            @update="
              (value) => {
                handleOnChangeCreatorCategory(value as number)
              }
            "
          />
        </div>
      </div>

      <p class="info-text">
        <NovaIcon :icon="{ type: 'outline', name: 'info' }" :size="16" />
        <span>{{ $t('creatorMainProfile.categoryGuide') }}</span>
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page-setting {
  position: absolute;
  top: calc(74px + 16px);
  right: 16px;
  width: 325px;
  z-index: $z-index-user-panel;
  background: #f7f7f7;
  border: 1px solid #eaeaea;
  box-shadow: 1px 2px 9px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
  @include transition(top 0.2s ease-in-out, opacity 0.2s ease-in-out);

  &.on {
    top: 74px;
    opacity: 1;
    pointer-events: auto;

    &.ready {
      .setting-boxes {
        opacity: 1;
      }
    }
  }

  > .dim {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: hex-to-rgba($color-black, 0.65);
    opacity: 0;
    @include transition(opacity 0.2s ease-in-out);
    z-index: -1;
  }

  .tab-box {
    padding: 0 20px;
    background-color: #fff;
    position: relative;

    .btn-close-layer {
      display: block;
      width: 18px;
      height: 18px;
      background: url('assets/images/icon-modal-close.png');
      background-size: 100% 100%;
      position: absolute;
      top: 50%;
      right: 20px;
      z-index: 10;
      transform: translateY(-50%);
      text-indent: -99999px;
      cursor: pointer;
    }

    ul {
      display: flex;
      align-items: center;

      li {
        a {
          display: block;
          position: relative;
          padding: 20px 0;
          font-size: 16px;
          font-weight: bold;
        }

        &.active {
          a {
            color: #347ae2;

            &:before {
              display: block;
              content: '';
              width: 100%;
              height: 2px;
              background-color: #347ae2;
              position: absolute;
              left: 0;
              bottom: 0;
            }
          }
        }
      }
    }
  }

  .setting-boxes {
    position: relative;
    display: flex;
    height: auto;
    flex-direction: column;
    gap: 18px;
    padding: 28px 18px 24px 18px;
    overflow: hidden;
    overflow-y: auto;
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;

    .btn-edit {
      width: 16px;
      height: 16px;
      background-image: url('assets/images/icon-edit.png');
      background-size: 100% 100%;
      text-indent: -99999px;
    }
    .info-text {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      span {
        @include text-style($text-body-14-medium);
        padding-left: 5px;
      }
    }

    .setting-box {
      background-color: #fff;
      border-radius: 16px;
      padding: 28px 19px;

      .title {
        display: flex;
        justify-content: space-between;
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 15px;
      }

      .contents {
      }
    }

    .setting-user-info {
      ul {
        display: flex;
        flex-direction: column;
        gap: 12px;

        li {
          display: flex;
          align-items: center;
          justify-content: left;
          word-break: break-all;

          strong {
            flex-shrink: 0;
            width: 114px;
            font-weight: bold;
            word-break: break-word;
          }

          .box-right {
            width: calc(100% - 90px);
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .box-right-edit {
            .input-box {
              border: 1px solid #ccc;
              height: 36px;
              padding: 0 5px;
              border-radius: 3px;
              display: flex;
              justify-content: space-between;
              align-items: center;

              input {
                width: 70%;
                height: 100%;
              }

              span {
                font-size: 11px;
              }
            }

            .box-btn {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              gap: 10px;
              margin-top: 10px;

              button {
                height: 28px;
                padding: 0 10px;
                font-size: 13px;
                font-weight: bold;
                letter-spacing: -0.3px;
                border-radius: 5px;
                color: $color-99;
                background-color: #f6f6f6;

                &.btn-save {
                  background: #347ae2;
                  color: #fff;
                }

                &.btn-cancel {
                  background: #347ae2;
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }

    .setting-link {
      .box-input {
        display: flex;
        align-items: center;
        gap: 5px;

        &.no-gap {
          gap: 0;
        }

        span {
          font-size: 13px;
          color: $color-99;
        }

        input {
          height: 28px;
          font-size: 13px;
          border: 1px solid #dddddd;
        }
      }
      .box-btn {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
        margin-top: 20px;

        button {
          padding: 0 10px;
          min-width: 44px;
          height: 27px;
          font-weight: 400;
          font-size: 13px;
          line-height: 19px;
          letter-spacing: -0.05em;
          color: #3f4354;
          background: #f4f6f8;
          border-radius: 5px;
          text-align: center;

          &.btn-save {
            background: #347ae2;
            color: #fff;
          }
        }
      }
    }

    .setting-description {
      .box-input {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span:first-of-type {
          display: block;
          width: 100%;
        }
      }

      .box-input-edit {
        border: 1px solid #dddddd;
        height: 60px;
        padding: 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;

        textarea {
          width: 100%;
          height: calc(100% - 25px);
          font-size: 13px;
        }

        span {
          font-size: 12px;
          align-items: flex-end;
        }
      }

      .box-btn {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
        margin-top: 20px;

        button {
          padding: 0 10px;
          min-width: 44px;
          height: 27px;
          font-weight: 400;
          font-size: 13px;
          line-height: 19px;
          letter-spacing: -0.05em;
          color: #3f4354;
          background: #f4f6f8;
          border-radius: 5px;
          text-align: center;

          &.btn-save {
            background: #347ae2;
            color: #fff;
          }
        }
      }
    }

    .account-loading-indicator {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      height: 100%;
    }
  }

  .profile-image-box {
    position: relative;
    background-color: #e3e3e3;
    border-radius: 35px;
    background: linear-gradient(97.77deg, #5d95e8 -5.67%, #ffd008 126.11%);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #cbcbcb;

    @include mobile {
      border-radius: 15px;
    }

    .btn-box,
    .btn-delete {
      position: absolute;
      right: 0;
      bottom: 0;
    }

    .btn-delete {
      color: $color-white;
      top: 0;
      bottom: auto;
    }

    .btn-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
    }

    .thumbnail {
      position: relative;

      .image-container {
        :deep(img) {
          border-radius: 35px;
          object-fit: contain;
          @include mobile {
            border-radius: 15px;
          }
        }
      }

      .btn-img-setting {
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
      }
    }

    .btn-img-setting {
      position: absolute;
      top: 16px;
      right: 16px;
      text-indent: -99999px;
      width: 50px;
      height: 50px;
      background-image: url('assets/images/icon-camera.png');
      background-size: 100% 100%;
      z-index: 10;
    }
  }

  @include mobile {
    width: 100%;
    position: fixed;
    top: 0;
    transform: translateY(0);
    border-radius: 0;
    left: 0;
    right: 0;

    &.on {
      top: 0;
    }

    .setting-boxes {
      padding-bottom: 156px;
      height: calc(100vh - 65px);
    }
  }
}
</style>
