import * as SearchType from './type'
import SearchApi from './url'
import { api } from '@api'
import type { PostsItem } from '@store/post/type'

export default {
  /**
   * 통합검색
   * @param payload
   */
  fetchSearchAll(payload: SearchType.SearchAllPayload) {
    return api.get<SearchType.SearchAll>(SearchApi.FETCH_SEARCH_ALL, {
      params: payload,
    })
  },

  /**
   * 통합검색: 댓글 전체 목록
   * @param payload
   */
  fetchSearchComments(payload: SearchType.SearchCommentsPayload) {
    return api.get<SearchType.SearchCommentsResponse>(
      SearchApi.FETCH_SEARCH_COMMENTS,
      { params: payload }
    )
  },

  /**
   * 통합검색: 해시태그 전체 목록
   * @param payload
   */
  fetchSearchHashtags(payload: SearchType.SearchHashtagsPayload) {
    return api.get<SearchType.SearchHashtagsResponse>(
      SearchApi.FETCH_SEARCH_HASHTAGS,
      { params: payload }
    )
  },

  /**
   * 통합검색: 크리에이터 전체 목록
   * @param payload
   */
  fetchSearchCreators(payload: SearchType.SearchCreatorsPayload) {
    return api.get<SearchType.SearchCreatorsResponse>(
      SearchApi.FETCH_SEARCH_CREATORS,
      { params: payload }
    )
  },
  /**
   * 통합검색: 사용자 전체 목록
   * @param payload
   */
  fetchSearchUsers(payload: SearchType.SearchUsersPayload) {
    return api.get<SearchType.SearchUsersResponse>(
      SearchApi.FETCH_SEARCH_USERS,
      { params: payload }
    )
  },

  /**
   * 통합검색: 노바+(프리미엄) 포스트 전체 목록
   * @param payload
   */
  fetchSearchPremiumPosts(payload: SearchType.SearchPremiumPostsPayload) {
    return api.get<PostsItem[]>(SearchApi.FETCH_SEARCH_PREMIUM_POSTS, {
      params: payload,
    })
  },
}
