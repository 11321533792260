<script setup lang="ts">
import type { NovaLogoProps } from '@components/NovaLogo/NovaLogo.types'

const props = withDefaults(defineProps<NovaLogoProps>(), {
  isGoHome: true,
  responsive: false,
})
const { gtEvent } = useGoogleTag()
const { locale, locales } = useI18n()
const route = useRoute()
const router = useRouter()

const goHome = () => {
  if (!props.isGoHome) return
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '로고 클릭 > 홈으로 이동',
    eventLabel: 'logo-button',
    eventSlot: '헤더',
    eventI18nAddr: '',
    eventComponent: 'Button',
  })
  const homePaths = ['/', ...locales.value.map((item) => `/${item.code}`)]

  homePaths.includes(route.path)
    ? router.go(0)
    : router.push({
        path: locale.value === 'ko' ? '/' : `/${locale.value}`,
      })
}
</script>

<template>
  <div :class="['logo-container', { responsive }]">
    <button
      type="button"
      :class="['logo-button', { 'click-able': isGoHome }]"
      @click="goHome"
    >
      <img
        src="@assets/images/logo-horizon-on-light.svg?url"
        alt="I LIKE LM"
        title="I LIKE LM"
      />
    </button>
  </div>
</template>

<style lang="scss" scoped>
.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .logo-button {
    max-width: 117px;
    width: 100%;
    height: auto;
    cursor: default;
    font-size: 0;

    &.click-able {
      cursor: pointer;
    }

    > img {
      width: 100%;
      height: auto;
    }
  }

  @include mobile {
    &.responsive .logo-button {
      width: 82px;
    }
  }
}
</style>
