import * as FacilityType from '@store/facility/type'
import FacilityApi from '@store/facility/url'
import { api } from '@api'

export default {
  /**
   * 시설물 임대 신청
   */
  reqFacilityRental(payload: FacilityType.FacilityRentalPayloadFormData) {
    return api.post<{}>(FacilityApi.REQ_RENTAL, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },

  /**
   * 시설물 임대 신청 요건 조회
   */
  fetchFacilityRentalCheckConditions() {
    return api.get<Array<FacilityType.FacilityRentalCheckCondition>>(
      FacilityApi.FETCH_RENTAL_CHECK_CONDITIONS
    )
  },

  /**
   * 시설물 임대 약관 상세 조회
   */
  fetchFacilityRentalTerm(payload: FacilityType.FacilityRentalTermPayload) {
    return api.get<Array<FacilityType.FacilityRentalTerm>>(
      FacilityApi.FETCH_RENTAL_TERM,
      { params: payload }
    )
  },

  /**
   * 시설물 임대 약관 목록 조회
   */
  fetchFacilityRentalTerms() {
    return api.get<Array<FacilityType.FacilityRentalTerms>>(
      FacilityApi.FETCH_RENTAL_TERMS
    )
  },
}
