<script setup lang="ts">
import type { NovaHashtagsEmits, NovaHashtagsProps } from './NovaHashtags.types'
import type { Hashtag } from '@store'

const emit = defineEmits<NovaHashtagsEmits>()
withDefaults(defineProps<NovaHashtagsProps>(), {
  prefix: '',
  ableAction: false,
  selectHashtag: null,
})

const handleOnChangeActiveHashtag = (hashtag: Hashtag) => {
  emit('onChangeActiveHashtag', hashtag)
}
</script>

<template>
  <div class="hashtags">
    <NovaHashtag
      v-for="(hashtag, index) in hashtags"
      :key="index"
      :hashtag="hashtag"
      :prefix="prefix"
      :on="
        ableAction && selectHashtag
          ? selectHashtag.hashtagSn === hashtag.hashtagSn
          : false
      "
      :able-action="ableAction"
      @click="() => handleOnChangeActiveHashtag(hashtag)"
    />
  </div>
</template>

<style lang="scss" scoped>
.hashtags {
  flex-wrap: wrap;
  display: flex;
  gap: 10px;
}
</style>
