<script setup lang="ts">
import { type MenuPanelId, LayerType } from '@store'

const emit = defineEmits(['goPanel', 'toggleShowPanel'])

const { gtEvent } = useGoogleTag()
const layoutStore = useLayoutStore()

const { t, locale, messages } = useI18n()
const boards = computed(() =>
  Object.keys(
    (messages.value[locale.value].board as { title: string }).title
  ).map((key) => ({
    path: key,
    label: t(`board.title.${key}`),
    type: key,
  }))
)

const handleOnGo = (target: any) => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: `메뉴 패널 > ${target.label}`,
    eventLabel: target.label,
    eventSlot: '헤더 > 메뉴 패널',
    eventI18nAddr: target.label,
    eventComponent: 'NovaHeaderMenuItem',
  })
  if (target.path === 'terms') {
    handleOnGoServices()
    return
  }
  useNavigations({ url: `/board/${target.path}` })
}

const handleOnGoServices = () => {
  const panelId: MenuPanelId = 'services'
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '서비스 및 정책',
    eventLabel: t('servicesAndPolicies'),
    eventSlot: '유저 패널',
    eventI18nAddr: useKoreanTranslation('servicesAndPolicies'),
    eventComponent: 'Button',
  })
  emit('goPanel', panelId)
}

onUnmounted(() => {
  layoutStore['layer:close'](LayerType.MENU_PANEL)
})
</script>

<template>
  <NovaHeaderMenuPanelMobileView v-if="layoutStore.responsive === 'mobile'" />
  <NovaHeaderMenuGroup v-else class="desktop-view">
    <template #menus>
      <NovaHeaderMenuItem
        v-for="board in boards"
        :key="board.type"
        :label="board.label"
        @click="handleOnGo(board)"
      />
    </template>
  </NovaHeaderMenuGroup>
</template>

<style lang="scss" scoped>
.desktop-view {
  padding: 20px;

  @include mobile {
    display: none;

    &.menu-group {
      display: none;
    }
  }
}
</style>
