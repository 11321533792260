<script setup lang="ts">
import { AxiosError } from 'axios'
import type { RouteParams } from 'vue-router'
import type { MyFollowPayload } from '@store'

defineOptions({
  inheritAttrs: false,
})

const { gtEvent } = useGoogleTag()
const { t, locale } = useI18n()
const isError = ref(false)
const router = useRouter()
const isLoading = ref(false)
const show = ref(false)
const userSn = ref(-1)
const { hide: modalHide } = useModal()
const mypageStore = useMyPageStore()
const { myFollowings } = storeToRefs(mypageStore)

/**
 * 탑 메뉴 이동
 */
const handleOnGoFollower = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '팔로우 할 사람 찾기 버튼 클릭',
    eventLabel: t('mypage.myFollow.following.noDate.button'),
    eventSlot: '팔로잉 모달',
    eventI18nAddr: useKoreanTranslation(
      'mypage.myFollow.following.noDate.button'
    ),
    eventComponent: 'Button',
  })
  await modalHide(modalsName.MODAL_MY_FOLLOWINGS)
  await router.push({
    path:
      locale.value === 'ko'
        ? '/creators/personal'
        : `/${locale.value}/creators/personal`,
  })
}
/**
 * 모달 열릴때 팔로워 목록 조회
 */
watch(
  () => [show.value, userSn.value],
  async ([curShow, curUserSn], [_prevShow, prevUserSn]) => {
    if (curShow && !(curUserSn === prevUserSn)) {
      await fetchMyFollowings(true)
    }
  }
)

/**
 * 팔로잉 목록 조회
 */
const fetchMyFollowings = async (
  refresh = false,
  payload?: MyFollowPayload,
  isRecycle?: boolean
) => {
  try {
    isLoading.value = true
    isError.value = false

    await mypageStore.fetchMyFollowings(
      {
        ...(payload || { ...myFollowings.value.payload }),
        userSn: userSn.value,
      },
      refresh
    )
    /*
    await mypageStore.fetchMyFollowings(
      payload || { ...myFollowings.value.payload },
      refresh
    ) */
    if (isRecycle) {
      gtEvent('scrollEvent', {
        eventCategory: '더보기',
        eventAction: '팔로잉 목록',
        eventLabel: 'loadMore',
        eventSlot: '팔로잉',
        eventI18nAddr: '',
        eventComponent: 'RecyclerView',
        lastUserCmtyFlwSn: myFollowings.value.payload?.lastUserCmtyFlwSn,
        pageNum: myFollowings.value.payload?.pageNum,
        pageSize: myFollowings.value.payload?.pageSize,
        userSn: myFollowings.value.payload?.userSn,
      })
    }
  } catch (err) {
    const _err = err as AxiosError
    switch (_err.response?.status) {
      case 4002:
      default:
        useToast(t('mypage.myFollow.message.error.unknown'))
        break
    }
  } finally {
    isLoading.value = false
  }
}

/**
 * 팝업 닫기: 버튼 클릭
 */
const handleOnClose = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '팔로잉 모달 > 닫기',
    eventLabel: 'close-extend',
    eventSlot: 'Modal',
    eventI18nAddr: 'close-extend',
    eventComponent: 'Button',
  })
  await modalHide(modalsName.MODAL_MY_FOLLOWINGS)
}

/**
 * 팝업 닫기전 훅
 */
const handleOnBeforeClose = async () => {
  console.log('BEFORE_CLOSE EVENT 2')
  mypageStore.clearFollow('followings')
  await useSleeper()

  return false
}

/**
 * 팝업 닫은 후 스토어에서 팔로우 초기화
 */
const handleOnClosed = () => {
  console.log('CLOSED EVENT 3')
  userSn.value = -1
  mypageStore.clearFollow('followings')
}

// 팔로잉 목록 조회 대상자 userSn 받기
const handleOnGetParams = (evt: RouteParams) => {
  userSn.value = (evt.value as unknown as { userSn: number }).userSn
}
</script>

<template>
  <VueFinalModal
    v-model="show"
    :name="modalsName.MODAL_MY_FOLLOWINGS"
    :content-class="'modal-content'"
    classes="modal-container"
    @before-close="handleOnBeforeClose"
    @closed="handleOnClosed"
    @before-open="(evt: any) => handleOnGetParams(evt.ref.params)"
    @click-outside="handleOnClose"
  >
    <ClientOnly>
      <NovaBoxBase v-if="show" class="modal-box">
        <div class="modal-header">
          <div class="title">
            <span>{{ $t('mypage.myFollow.title.following') }}</span>
          </div>
          <NovaButtonIcon
            :icon="{ type: 'outline', name: 'close-extend' }"
            :theme="'transparent'"
            :size="24"
            class="btn-close"
            @click="handleOnClose"
          />
        </div>

        <NovaLayoutScrollContainer class="modal-body">
          <NovaRecyclerGrid
            v-if="show && myFollowings.items.length"
            :items="myFollowings.items"
            :is-last="myFollowings.last"
            :is-loading="isLoading"
            :is-error="isError"
            :is-refresh="myFollowings.refresh"
            :key-name="'userSn'"
            :error-message="$t('mypage.myFollow.following.noDate.title')"
            :responsive="{
              mobile: { columns: 1, rowGap: 20, columnGap: 0 },
              tablet: { columns: 1, rowGap: 20, columnGap: 0 },
              desktop: { columns: 1, rowGap: 20, columnGap: 0 },
            }"
            :page-mode="false"
            :bottom-white-space="0"
            @load-more="() => fetchMyFollowings(false, undefined, true)"
          >
            <template #item="{ item, index }">
              <NovaBoxFollowUser
                :source="item"
                :view-type="'following'"
                :show-block-btn="null"
                :show-toggle-follow-btn="
                  myFollowings.items[index].userSn !==
                  mypageStore.profile?.userSn
                "
              />
            </template>
          </NovaRecyclerGrid>

          <NovaLoadingIndicator
            v-if="isLoading"
            :fill="true"
            :bg-bright="'light'"
            class="loading-indicator"
          />
          <NovaBoxEmptyMessage
            v-if="!isLoading && !myFollowings.items.length && isError"
            :message="t('mypage.myFollow.following.error')"
            :fill="true"
          />
          <div
            v-if="!isLoading && !myFollowings.items.length && !isError"
            :fill="true"
            class="no-data"
          >
            <p
              v-dompurify-html="$t('mypage.myFollow.following.noDate.title')"
              class="title"
            />
            <NovaButtonText
              v-if="userSn === mypageStore.profile?.userSn"
              :label="$t('mypage.myFollow.following.noDate.button')"
              :theme="'sky-blue'"
              :loading="isLoading"
              @click="handleOnGoFollower"
            />
          </div>
        </NovaLayoutScrollContainer>
      </NovaBoxBase>
    </ClientOnly>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
:deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.vfm--overlay) {
  background-color: hex-to-rgba($color-black, 0.65);
}

:deep(.modal-content) {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(30px + 24px + 12px) 32px 32px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  .modal-box {
    width: 100%;
    padding: 0;
    max-width: 670px;
    height: 100%;
    min-height: 360px;
    max-height: 480px;
    border-radius: 20px !important;
    pointer-events: auto;
    padding-bottom: 20px;
    .modal-header {
      position: relative;
      padding: 24px;
      @include mobile {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 20px;
      }
      .title {
        @include text-style($text-heading2-bold);
      }

      .btn-close {
        position: absolute;
        top: 30px;
        right: 30px;
        color: $color-black;
        @include mobile {
          top: 18px;
          right: 20px;
        }
      }
    }
    .modal-body {
      padding-top: 20px;
    }
  }
}

/* 노 데이터 */
.no-data {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  text-align: center;
  height: 100%;
  min-height: 260px;
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 60px;
    @include text-style($text-custom-24-bold);
  }

  .message {
    display: flex;
    justify-content: flex-start;
    padding: 0 40px;
    text-align: left;
    @include text-style($text-custom-18-bold);
    @include mobile {
      padding: 0 20px;
    }
  }
}

@include mobile {
  :deep(.modal-content) {
    width: 100%;
    height: 100%;
    padding: 0;

    .modal-box {
      width: 100%;
      max-width: 100%;
      height: 100%;
      border-radius: 0 !important;
      max-height: none;
      .modal-header {
        flex-shrink: 0;
        height: 56px;
        .on-mobile {
          display: flex;
        }

        .on-desktop {
          .btn-close {
            display: none;
          }
        }
      }

      .modal-body {
        border-radius: 0;

        > .post {
          max-height: initial;
        }
      }
    }
  }
}
</style>
