import * as AppTypes from './type'

/**
 * app store init state
 */
export const initAppState: AppTypes.AppState = {
  appType: null,
  currentVer: null,
  appBuildNo: null,
  forceAppMode: false,
  appStorage: null,
  appStatus: null,
  fcmTokenStatus: null,
}
