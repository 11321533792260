<script setup lang="ts">
import type { NovaBoxNotificationSettingStackSettingProps } from './NovaBoxNotificationSettingStackSetting.types'
import {
  NotificationMarketingAgreeCode,
  type NotificationMarketingAgree,
  type NotificationServiceSetting,
  type BooleanYn,
} from '@store'
import { CellGroupTheme } from '@components/NovaHeaderCellGroup/NovaHeaderCellGroup.types'
import { CellItemTheme } from '@components/NovaHeaderCellItem/NovaHeaderCellItem.types'
import { NotificationSettingViewType } from '@components/NovaBoxNotificationSetting/NovaBoxNotificationSetting.types'

const {
  goStack,
  onUpdateNotificationPush,
  onFetchNotificationServiceDetail,
  onUpdateNotificationService,
  onUpdateNotificationMarketing,
  source,
} = defineProps<NovaBoxNotificationSettingStackSettingProps>()

const { gtEvent } = useGoogleTag()
const { t } = useI18n()
const { dayjs } = useDayjs()
const appStore = useAppStore()
const isPushLoading = ref(false)
const isServiceLoadingId = ref<NotificationServiceSetting['ntcnSetupId']>()
const isMarketingLoadingCode = ref<NotificationMarketingAgreeCode>()

// 푸시 설정 로딩 상태 토글
const toggleIsPushLoading = (_loading: boolean) => {
  isPushLoading.value = _loading
}

// 서비스 설정 로딩 상태 토글
const toggleIsServiceId = (
  serviceId: NotificationServiceSetting['ntcnSetupId'] | undefined
) => {
  isServiceLoadingId.value = serviceId
}

// 마케팅 설정 로딩 상태 토글
const toggleIsMarketingLoadingCode = (
  marketingId: NotificationMarketingAgreeCode | undefined
) => {
  console.log(marketingId)
  isMarketingLoadingCode.value = marketingId
}

// 푸시 알림 설정 업데이트
const handleOnUpdateNotificationPush = (value: boolean) => {
  onUpdateNotificationPush(value, toggleIsPushLoading)
}

// 푸시 알림음 설정 스택 이동
const handleOnGoNotificationSound = () => {
  if (isPushLoading.value) return
  goStack(NotificationSettingViewType.PUSH_SOUND)
}

// 서비스 유닛 설정 업데이트
const handleOnUpdateService = (
  serviceId: NotificationServiceSetting['ntcnSetupId'],
  value: boolean,
  name?: string
) => {
  const payload = {
    ntcnSetupId: serviceId,
    svcNtcnRecptnSetupAt: (value ? 'Y' : 'N') as BooleanYn,
  }
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: `알림 설정 > ${name} > ${value ? '켜기' : '끄기'}`,
    eventLabel: name || '',
    eventSlot: '서비스 알림',
    eventI18nAddr: '',
    eventComponent: 'Toggle',
  })
  onUpdateNotificationService(payload, toggleIsServiceId)
}

// 서비스 유닛 설정 스택 이동
const handleOnGoServiceDetail = async (
  serviceId: NotificationServiceSetting['ntcnSetupId'],
  ntcnBrfDc: string
) => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '서비스 알림 > 알림 설정 > 상세 설정',
    eventLabel: ntcnBrfDc,
    eventSlot: '',
    eventI18nAddr: '',
    eventComponent: 'Button',
  })
  await onFetchNotificationServiceDetail(serviceId)
  goStack(NotificationSettingViewType.SERVICE_DETAIL)
}

// 마케팅 설정 업데이트
const handleOnUpdateMarketing = (
  marketingCode: NotificationMarketingAgreeCode,
  value: boolean,
  name: any
) => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: `알림 설정 > ${name} > ${value ? '켜기' : '끄기'}`,
    eventLabel: name || '',
    eventSlot: '마케팅 알림',
    eventI18nAddr: '',
    eventComponent: 'Toggle',
  })

  onUpdateNotificationMarketing(
    marketingCode,
    value ? 'Y' : 'N',
    toggleIsMarketingLoadingCode
  )
}
// 마케팅 동의 여부 디스크립션 변환
const convertToMarketingDescription = (
  marketing: NotificationMarketingAgree
) => {
  // const timezone = dayjs().format('Z')
  // const diff = Number(timezone.slice(1, 3))
  // const date = `${dayjs(marketing.stplatAgreDt).format('YYYY.MM.DD')} GMT${
  //   timezone[0]
  // }${diff}`
  //
  // return t(
  //   `notificationSetting.${
  //     marketing.stplatAgreAt === 'Y' ? 'marketingAgreeY' : 'marketingAgreeN'
  //   }`,
  //   { date }
  // )

  const diff = dayjs().utcOffset() / 60
  const date = `${dayjs(marketing.stplatAgreDt).format('YYYY.MM.DD')} GMT${
    diff >= 0 ? '+' : ''
  }${Math.abs(diff)}`

  return t(
    `notificationSetting.${
      marketing.stplatAgreAt === 'Y' ? 'marketingAgreeY' : 'marketingAgreeN'
    }`,
    { date }
  )
}
</script>

<template>
  <div class="notification-setting">
    <!-- setting: push -->
    <NovaHeaderCellGroup
      v-if="appStore.isApp"
      :theme="CellGroupTheme.ON_WHITE_BOX"
    >
      <template #cells>
        <NovaHeaderCellItem
          :theme="CellItemTheme.ON_WHITE_BOX"
          :label="source.push.ntcnNm"
          :contents="source.push.ntcnBrfDc"
        >
          <template #tools>
            <NovaSwitch
              :model-value="source.push.svcNtcnRecptnAt === 'Y'"
              :loading="isPushLoading"
              @update="handleOnUpdateNotificationPush"
            />
          </template>
        </NovaHeaderCellItem>

        <NovaHeaderCellItem
          :theme="CellItemTheme.ON_WHITE_BOX"
          :label="source.push.pushSound.ntcnNm"
          :depth="2"
        >
          <template #tools>
            <NovaButtonSimpleText
              :label="source.push.pushSound.ntcnChnnelAlarmTyCodeNm"
              :icon="{ type: 'outline', name: 'chev-compact-right' }"
              :disabled="isPushLoading"
              @click="handleOnGoNotificationSound"
            />
          </template>
        </NovaHeaderCellItem>
      </template>
    </NovaHeaderCellGroup>

    <!-- setting: service -->
    <NovaHeaderCellGroup :theme="CellGroupTheme.ON_WHITE_BOX">
      <template #cells>
        <NovaHeaderCellItem
          :theme="CellItemTheme.ON_WHITE_BOX"
          :label="source.service.ntcnNm"
          :contents="source.service.ntcnBrfDc"
        >
          <template #tools>
            <NovaSwitch
              :model-value="source.service.svcNtcnRecptnSetupAt === 'Y'"
              :loading="isServiceLoadingId === source.service.ntcnSetupId"
              @update="
                (value) =>
                  handleOnUpdateService(
                    source.service.ntcnSetupId,
                    value,
                    source.service.ntcnNm
                  )
              "
            />
          </template>
        </NovaHeaderCellItem>

        <NovaHeaderCellItem
          v-for="item in source.service.serviceSetupList"
          :key="item.ntcnSetupId"
          :theme="CellItemTheme.ON_WHITE_BOX"
          :label="item.ntcnNm"
          :contents="item.ntcnBrfDc"
          :depth="2"
        >
          <template #tools>
            <div class="cell-item-tools-group">
              <NovaSwitch
                :model-value="item.svcNtcnRecptnSetupAt === 'Y'"
                :loading="isServiceLoadingId === item.ntcnSetupId"
                style="height: 20px"
                @update="
                  (value) =>
                    handleOnUpdateService(item.ntcnSetupId, value, item.ntcnNm)
                "
              />

              <NovaButtonSimpleText
                v-if="item.lowestExpsrAt === 'Y'"
                :label="t('notificationSetting.goDetailSetting')"
                :icon="{ type: 'outline', name: 'chev-compact-right' }"
                :disabled="isServiceLoadingId === item.ntcnSetupId"
                style="height: 18px"
                @click="
                  () =>
                    handleOnGoServiceDetail(item.ntcnSetupId, item.ntcnBrfDc)
                "
              />
            </div>
          </template>
        </NovaHeaderCellItem>
      </template>
    </NovaHeaderCellGroup>

    <!-- setting: marketing -->
    <NovaHeaderCellGroup :theme="CellGroupTheme.ON_WHITE_BOX">
      <template #cells>
        <NovaHeaderCellItem
          :theme="CellItemTheme.ON_WHITE_BOX"
          :label="source.marketing.ntcnNm"
          :contents="source.marketing.ntcnBrfDc"
        />

        <NovaHeaderCellItem
          v-for="item in source.marketing.marketingAgreeList"
          :key="item.stplatTyCode"
          :theme="CellItemTheme.ON_WHITE_BOX"
          :label="item.ntcnNm"
          :label-sub="
            item.stplatTyCode === NotificationMarketingAgreeCode.PUSH
              ? t('notificationSetting.marketingPushTime')
              : ''
          "
          :contents="
            item.stplatAgreDt ? convertToMarketingDescription(item) : '&nbsp'
          "
          :depth="2"
        >
          <template #tools>
            <NovaSwitch
              :model-value="item.stplatAgreAt === 'Y'"
              :loading="isMarketingLoadingCode === item.stplatTyCode"
              @update="
                (value) =>
                  handleOnUpdateMarketing(item.stplatTyCode, value, item.ntcnNm)
              "
            />
          </template>
        </NovaHeaderCellItem>
      </template>
    </NovaHeaderCellGroup>
  </div>
</template>

<style lang="scss" scoped>
.cell-item-tools-group {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
}
</style>
