/**
 * 주어진 문자열이 URL에서 허용되지 않는 특수문자를 포함하고 있는지 확인합니다.
 *
 * @param str - 확인할 문자열
 * @returns 특수문자를 포함하고 있으면 true, 그렇지 않으면 false를 반환합니다.
 */
export const useCheckUrlSpecialCharacter = (str: string): boolean => {
  const pattern = /[!#$%&'()*+,:;=?@[_/]|{}]/g
  return pattern.test(str)
}
