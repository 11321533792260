/**
 * 다이얼로그
 */
export const dialog = {
  requireSignIn: {
    title: '알림',
    description: '로그인이 필요합니다. 로그인 하시겠습니까?',
    action: {
      negative: '취소',
      positive: '로그인',
    },
  },
  resultSignUp: {
    success: {
      title: '회원가입 완료',
      content: '회원가입을 축하합니다!',
    },
    fail: {
      title: '회원가입 실패',
      content:
        '요청 처리중 오류가 발생하였습니다.<br>서비스 이용에 불편을 드려 죄송합니다. 확인 후 다시 시도해주시기 바랍니다.',
    },
    confirm: '확인',
  },
  resultSignIn: {
    fail: {
      title: '로그인 실패',
      content:
        '요청 처리중 오류가 발생하였습니다.<br>서비스 이용에 불편을 드려 죄송합니다. 확인 후 다시 시도해주시기 바랍니다.',
    },
  },
  confirmCommentDelete: {
    title: '알림',
    description: '댓글을 삭제 하시겠습니까?',
    action: {
      negative: '취소',
      positive: '삭제',
    },
  },
  postDetailDrop: {
    title: '알림',
    description: '신고된 포스트 입니다.',
    action: {
      positive: '확인',
    },
  },
  postDetailBlocked: {
    title: '알림',
    description: '차단된 포스트 입니다.',
    action: {
      positive: '확인',
    },
  },
  postUnSubscribed: {
    title: '알림',
    description: '{subscribeGrade} 포스트에 대한 접근 권한이 없습니다.',
    action: {
      positive: '확인',
    },
  },
  confirmTempPostDelete: {
    title: '알림',
    description: '임시 포스트를 삭제 하시겠습니까?',
    action: {
      negative: '취소',
      positive: '삭제',
    },
  },
  multipleSignIn: {
    title: '알림',
    description:
      '다른 브라우저에서 다른 계정으로 로그인하였습니다. 기존 계정은 로그아웃됩니다.',
    action: {
      positive: '확인',
    },
  },
  referralReg: {
    title: '추천인 등록',
    placeholder: '이메일을 입력해주세요.',
    error: '추천인을 등록할 수 없습니다. 잠시 후 다시 시도해 주세요.',
    success: '추천인이 등록되었습니다.',
    validate: '유효한 이메일을 입력해주세요.',
  },
  event: {
    confirm: '확인',
    regist: {
      title: '알림',
      content: '앱에서 접수 가능합니다.',
    },
    apply: {
      title: '알림',
      content: '앱에서 신청 가능합니다.',
    },
  },
}
