<script setup lang="ts">
import ko from '@locales/ko'
import en from '@locales/en'
import th from '@locales/th'
import es from '@locales/es'
import type { LocaleCodeType } from '@configs/i18n.types'
import { type CategoryListItem, LayerType, useLayoutStore } from '@store'
import type { MegaMenuType } from '@components/NovaMegaMenu/NovaMegaMenu.types'
import type { MegaMenuItemType } from '@components/NovaMegaMenuItem/NovaMegaMenuItem.types'
import type {
  FeedMenu,
  CommunityMenu,
  EtcMenu,
} from '@components/NovaHeaderMenuPanelMobileView/NovaHeaderMenuPanelMobileView.types'
import { ROUTES } from '@configs'

const { t, locale, messages } = useI18n()
const languages = { ko, en, th, es }
const isFeed = computed(() => {
  const routeName = useGetRouteName()
  return routeName === ROUTES.FEED.name
})
const etcStore = useEtcStore()
const layoutStore = useLayoutStore()
const userStore = useUserStore()
const postStore = usePostStore()
const { openMembershipServicePopup } = useMembershipProcess()
const keyword = ref<string>('')
const language = computed(() => languages[locale.value as LocaleCodeType])

// 피드 매뉴
const feedsMenus = computed<MegaMenuType>(() => {
  const menuGroupKey = 'feeds'

  return {
    header: {
      icon: { type: 'fill', name: 'post' },
      label: language.value[menuGroupKey],
      theme: 'yellow',
      sticky: true,
    },
    menus: Object.keys(language.value.feedsMenu).map((key) => ({
      id: `${menuGroupKey}-${key}`,
      label: language.value.feedsMenu[key],
      onClick: () => handleOnGoPostsByOrder(key as FeedMenu),
    })),
  }
})

// 커뮤니티 메뉴
const communityMenus = computed<MegaMenuType>(() => {
  const menuGroupKey = 'community'

  return {
    header: {
      icon: { type: 'fill', name: 'feedback' },
      label: language.value[menuGroupKey],
      theme: 'red',
      sticky: true,
    },
    menus: Object.keys(language.value.communityMenus).map((key) => ({
      id: `${menuGroupKey}-${key}`,
      label: language.value.communityMenus[key],
      onClick: () => handleOnGoCommunity(key as CommunityMenu),
      children:
        key === 'categories'
          ? postStore.categories.map((item) => ({
              id: `community-${key}-${item.cmtyNttCtgrySn}`,
              label: item.nttCtgryNm,
              cmtyNttCtgrySn: item.cmtyNttCtgrySn,
              onClick: () => handleOnUpdatePostsPayload(item.cmtyNttCtgrySn),
              children:
                item.lowerCategoryList && item.lowerCategoryList.length
                  ? item.lowerCategoryList.map((child) => ({
                      id: `community-${key}-${item.cmtyNttCtgrySn}-${child.cmtyNttCtgrySn}`,
                      label: child.nttCtgryNm,
                      cmtyNttCtgrySn: child.cmtyNttCtgrySn,
                      onClick: () =>
                        handleOnUpdatePostsPayload(child.cmtyNttCtgrySn),
                    }))
                  : [],
            }))
          : [],
    })),
  }
})

// 기타 메뉴
const etcMenus = computed<MegaMenuType>(() => {
  const menuGroupKey = 'etc'

  return {
    header: {
      icon: { type: 'fill', name: 'message' },
      label: language.value[menuGroupKey],
      theme: 'blue',
      sticky: true,
    },
    menus: Object.keys((messages.value[locale.value].board as any).title)
      .filter((key) => key !== 'terms')
      .map((key) => ({
        id: `${menuGroupKey}-${key}`,
        label: t(`board.title.${key}`),
        onClick: () => handleOnGoOthers(key as EtcMenu),
      })),
  }
})

// 서비스 및 정책 메뉴
const servicesMenus = computed<MegaMenuType>(() => {
  const menuGroupKey = 'servicesAndPolicies'

  return {
    header: {
      icon: { type: 'outline', name: 'security-search' },
      label: language.value[menuGroupKey],
      theme: 'gray',
      sticky: true,
    },
    menus: Object.keys(
      messages.value[locale.value].servicesAndPoliciesMenus as any
    ).map((key) => ({
      id: `${menuGroupKey}-${key}`,
      label: t(`servicesAndPoliciesMenus.${key}`),
      onClick: () => handleOnGoServices(key),
      children:
        key === 'servicesMenu'
          ? Object.keys(messages.value[locale.value].servicesMenu as any).map(
              (key) => ({
                id: `servicesMenu-${key}`,
                label: t(`servicesMenu.${key}`),
                onClick: () => handleOnGoServices(key),
              })
            )
          : etcStore.terms.map((key) => ({
              id: `termsMenu-${key}`,
              label: key.stplatNm,
              onClick: () => handleOnGoServices(key.stplatId),
            })),
    })),
  }
})

const handleOnGoServices = (key: string) => {
  layoutStore['layer:close'](LayerType.MENU_PANEL)
  switch (key) {
    case 'leisureMetaVerse':
      return useNavigations({ external: true, url: 'https://leisuremeta.io' })
    case 'theMoonLabs':
      return useNavigations({ external: true, url: 'https://themoonlabs.net' })
    case 'playNomm':
      return useNavigations({ external: true, url: 'https://m.playnomm.com' })
    default:
      return useNavigations({
        url: useRoutePathIdChange(ROUTES.TERMS.path, { id: key }),
      })
  }
}

// 키워드로 필터링 된 메뉴모음
const filteredMenus = computed(() => ({
  feeds: {
    ...feedsMenus.value,
    menus: filterByKeyword(feedsMenus.value.menus, keyword.value),
  },
  community: {
    ...communityMenus.value,
    menus: filterByKeyword(communityMenus.value.menus, keyword.value),
  },
  etc: {
    ...etcMenus.value,
    menus: filterByKeyword(etcMenus.value.menus, keyword.value),
  },
  services: {
    ...servicesMenus.value,
    menus: filterByKeyword(servicesMenus.value.menus, keyword.value),
  },
}))

// 키워드로 하위 메뉴가 있는지 검사
const filterByKeyword = (menus: MegaMenuItemType[], newKeyword: string) => {
  return menus.flatMap((menu) => {
    const chd1 = menu.children?.filter((child) => {
      const chd2 =
        child.children?.filter((subChild) =>
          newKeyword ? subChild.label.includes(newKeyword) : true
        ) || []

      return newKeyword
        ? child.label.includes(newKeyword) || chd2.length > 0
        : true
    })

    if (
      (newKeyword
        ? menu.label.includes(newKeyword) && !menu.children?.length
        : true) ||
      chd1?.length
    ) {
      return [{ ...menu, children: chd1 }]
    }

    return []
  })
}

// 피드 클릭
const handleOnGoPostsByOrder = (feed: FeedMenu) => {
  const query = useGetFeedQuery()
  switch (feed) {
    case 'forYouPost':
      query.orderBy = 'for-you'
      break
    case 'followingPost':
      query.orderBy = 'follow-newest'
      break
    case 'recentPost':
      query.orderBy = 'newest'
      break
    default:
      throw new Error('feed type is not match')
  }
  layoutStore['layer:close'](LayerType.MENU_PANEL)

  if (!userStore.isSignIn && query.orderBy === 'follow-newest') {
    openMembershipServicePopup.signin()
    return
  }

  useNavigations({
    url: ROUTES.FEED.path,
    query,
    type: isFeed.value ? 'replace' : 'push',
  })
}

// 커뮤니티 1Depth 클릭
// TODO: 추후 1Depth 링크가 활성화 되면 이벤트 추가
const handleOnGoCommunity = (community: CommunityMenu) => {
  console.log(community)
  // layoutStore['layer:close'](LayerType.MENU_PANEL)
}

// 커뮤니티 2Depth 클릭
const handleOnUpdatePostsPayload = (
  cmtyNttCtgrySn: CategoryListItem['cmtyNttCtgrySn']
) => {
  layoutStore['layer:close'](LayerType.MENU_PANEL)
  const query = useGetFeedQuery()
  useNavigations({
    url: ROUTES.FEED.path,
    // query: cmtyNttCtgrySn === -1 ? {} : { cmtyNttCtgrySn },
    query: { ...query, cmtyNttCtgrySn },
    type: isFeed.value ? 'replace' : 'push',
  })
}

// 기타 클릭
const handleOnGoOthers = (key: EtcMenu) => {
  layoutStore['layer:close'](LayerType.MENU_PANEL)
  useNavigations({ external: false, url: `/board/${key}` })
}
</script>

<template>
  <div class="menus-container">
    <NovaLayoutScrollContainer class="menus">
      <ClientOnly>
        <NovaMegaMenu :prop="filteredMenus.feeds" />
        <NovaMegaMenu
          :prop="filteredMenus.community"
          :force-expand="!!keyword.length"
        />
        <NovaMegaMenu :prop="filteredMenus.etc" />
        <NovaMegaMenu :prop="filteredMenus.services" :force-expand="true" />

        <NovaBoxEmptyMessage
          v-if="
            !Object.keys(filteredMenus).filter(
              (key) => filteredMenus[key].menus.length
            ).length
          "
          :message="t('statusMessages.menus.empty', { menu: keyword })"
        />
      </ClientOnly>
    </NovaLayoutScrollContainer>
  </div>
</template>

<style lang="scss" scoped>
.menus-container {
  display: none;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 20px;

  .search-bar-container {
    padding: 20px 15px;
  }

  .menus {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 15px 24px;
  }

  @include mobile {
    display: flex;
  }
}
</style>
