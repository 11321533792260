/**
 * 프로필 편집(마이페이지)
 */
export const profileControl = {
  avatar: '아바타 편집',
  profile: '프로필 업로드',
  dialog: {
    title: '알림',
    content: '편집된 아바타는 I LIKE LM, LM Wallet에 모두 반영됩니다.',
    actions: {
      positive: '확인',
    },
  },
}
