type Path = string
type NamedValue<T = {}> = T & Record<string, any>

/**
 * 메시지 객체 내에서 주어진 경로에 위치한 메시지를 찾기
 *
 * @param {string} path - 메시지를 찾기 위한 경로
 * @param {object} messages - 메시지를 포함하고 있는 객체
 * @returns {string} - 주어진 경로에 위치한 메시지
 */
const locateMessage = (path: Path, messages: NamedValue): string => {
  return path
    .split('.')
    .reduce((prev, curr) => (prev ? prev[curr] : undefined), messages.ko)
}

/**
 * 메시지 내에 있는 named 객체를 해당 값으로 대체
 *
 * @param {string} message - 대체할 named 객체가 포함된 메시지
 * @param {NamedValue} named - named 객체와 그 값들
 * @returns {string} named 객체가 대체된 새로운 메시지
 */
const replaceNamedObjects = (
  message: ReturnType<typeof locateMessage>,
  named: NamedValue
): string => {
  const pattern = /\{(.*?)\}/g
  return message.replace(pattern, (_, key) => named[key.trim()])
}

/**
 * 현재 locale 이 'ko'로 설정되어 있으면 주어진 경로를 한국어로 번역
 * 그렇지 않으면 현재 locale 로 번역된 메시지를 반환
 * named 객체가 제공되면, 번역된 메시지의 placeholder 를 해당 값으로 대체
 *
 * @param {string} path - 번역을 원하는 경로
 * @param {Object} named - 번역된 메시지에서 placeholder 를 대체할 named 값이 포함된 선택적 객체
 * @returns {string} - 번역된 메시지
 */
export const useKoreanTranslation = (
  path: Path,
  named: NamedValue = {}
): string => {
  const nuxtApp = useNuxtApp()
  const { t, locale, messages } = nuxtApp.$i18n

  if (locale.value === 'ko') return t(path, named)

  const message = locateMessage(path, messages.value)
  return Object.keys(named).length === 0
    ? message
    : replaceNamedObjects(message, named)
}
