/**
 * 지정된 휘도 범위 내에서 16진수 형식의 랜덤 색상을 생성
 *
 * @param {number} [minLuminance=100] - 휘도의 최소값 (포함)
 * @param {number} [maxLuminance=180] - 휘도의 최대값 (포함)
 * @returns {string} 16진수 형식의 임의로 생성된 색상
 */
export const useGenRandomColor = (
  minLuminance = 100,
  maxLuminance = 180
): string => {
  let r = 0
  let g = 0
  let b = 0

  const isInvalid = () => {
    const luminance = useGetLuminance(r, g, b)

    return luminance < minLuminance || luminance > maxLuminance
  }

  do {
    r = Math.round(Math.random() * 255)
    g = Math.round(Math.random() * 255)
    b = Math.round(Math.random() * 255)
  } while (isInvalid())

  const toHex = (value: number) =>
    value.toString(16).padStart(2, '0').toUpperCase()

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`
}
