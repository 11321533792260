<script setup lang="ts">
import type { NovaButtonIconProps } from './NovaButtonIcon.types'

const props = withDefaults(defineProps<NovaButtonIconProps>(), {
  theme: 'transparent',
  size: 20,
  on: false,
  bullet: false,
  bulletCount: 0,
  fillIcon: false,
  loading: false,
  bgBright: 'dark',
  disabled: false,
  isModal: false,
})
const btnIcon = ref<HTMLElement | null>(null)
const themeClass = computed(() => `btn-${props.theme}`)
const sizeClass = computed(() => `btn-${props.size}`)
const iconSize = computed(() => {
  if (props.fillIcon) {
    return props.size
  }

  switch (props.theme) {
    case 'square':
    case 'square-bg':
    case 'circle':
    case 'primary-blue-light':
    case 'primary-red-light':
      return props.size / 2
    case 'transparent':
    default:
      return props.size
  }
})
</script>

<template>
  <button
    ref="btnIcon"
    type="button"
    :class="['btn-icon', themeClass, sizeClass, { on }, { loading }]"
    :disabled="disabled"
  >
    <span class="shade" />

    <span class="icon-wrap">
      <span :class="['bullet', { on: bullet }]"></span>
      <span :class="['bullet count', { on: bulletCount }]">
        {{ bulletCount }}
      </span>
      <NovaIcon
        :icon="icon"
        :size="iconSize"
        :class="isModal ? 'icon-modal' : 'icon'"
      />
    </span>

    <NovaLoadingIndicator
      v-if="loading"
      :bg-bright="bgBright"
      class="loading-indicator"
    />
  </button>
</template>

<style lang="scss" scoped>
.btn-icon {
  position: relative;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: 4px;
  color: inherit;
  @include transition(all 0.15s ease-in-out);

  @media (hover: hover) {
    &:hover {
      color: $color-text-3;
    }
  }

  &:active {
    color: $color-text-3;

    .shade {
      opacity: 1;
    }
  }

  &.loading {
    pointer-events: none;

    .icon {
      opacity: 0.2;
    }

    .label {
      opacity: 0.2;
    }
  }

  &.btn {
    // size
    &-14 {
      width: 14px;
      height: 14px;

      .shade {
        width: 20px;
        height: 20px;
        border-radius: 3px;
      }
    }

    &-16 {
      width: 16px;
      height: 16px;

      .shade {
        width: 22px;
        height: 22px;
        border-radius: 3px;
      }
    }

    &-20 {
      width: 20px;
      height: 20px;

      .shade {
        width: 26px;
        height: 26px;
        border-radius: 4px;
      }
    }

    &-24 {
      width: 20px;
      height: 20px;

      .shade {
        width: 24px;
        height: 24px;
        border-radius: 4px;
      }
    }

    &-26 {
      width: 26px;
      height: 26px;

      .shade {
        width: 24px;
        height: 24px;
        border-radius: 4px;
      }
    }

    &-32 {
      width: 32px;
      height: 32px;

      .shade {
        width: 100%;
        height: 100%;
        border-radius: 7px;
      }
    }

    &-38 {
      width: 38px;
      height: 38px;

      .shade {
        width: 100%;
        height: 100%;
        border-radius: 7px;
      }
    }

    &-40 {
      width: 40px;
      height: 40px;

      .shade {
        width: 100%;
        height: 100%;
        border-radius: 7px;
      }
    }

    // theme
    &-square {
      color: $color-text-5;
      background-color: transparent;

      .shade {
        display: none;
      }

      @media (hover: hover) {
        &:hover {
          color: $color-bg-3;
          background-color: $color-secondary-blue;
        }
      }

      &:active {
        color: $color-bg-3;
        background-color: $color-secondary-blue-dark-90;
      }

      &.on {
        color: $color-bg-3;
        background-color: $color-primary-blue;
      }
    }

    &-square-bg {
      color: $color-text-4;
      background-color: $color-bg-1;

      .shade {
        display: none;
      }

      @media (hover: hover) {
        &:hover {
          color: $color-text-7;
          background-color: $color-bg-custom-5;
        }
      }

      &:active {
        color: $color-text-8;
        background-color: $color-bg-custom-6;
      }

      &.on {
        color: $color-text-6;
      }
    }

    &-circle {
      .shade {
        border-radius: 50%;
      }
    }

    &-transparent {
      .shade {
        border-radius: 50%;
      }
    }

    &-primary-blue-light {
      background-color: $color-secondary-blue-light-70;
      color: $color-bg-3;

      @media (hover: hover) {
        &:hover {
          background-color: $color-secondary-blue-light-90;
        }
      }

      &:active {
        background-color: $color-secondary-blue-dark-90;
      }
    }

    &-primary-red-light {
      background-color: $color-secondary-red-light-70;
      color: $color-bg-3;

      @media (hover: hover) {
        &:hover {
          background-color: $color-secondary-red-light-90;
        }
      }

      &:active {
        background-color: $color-secondary-red-dark-90;
      }
    }
  }

  &.floating {
    background-color: $color-white;
    border-radius: 100%;
    box-shadow: 0 6px 12px rgb(0 0 0 / 10%);
    width: 38px;
    height: 38px;

    .icon {
      font-size: 20px;
    }
  }

  &.fill-icon {
    &.btn-14 {
      .icon {
        font-size: 14px;
      }
    }
    &.btn-16 {
      .icon {
        font-size: 16px;
      }
    }
    &.btn-20 {
      .icon {
        font-size: 20px;
      }
    }
    &.btn-26 {
      .icon {
        font-size: 26px;
      }
    }
    &.btn-32 {
      .icon {
        font-size: 60px;
      }
    }
    &.btn-40 {
      .icon {
        font-size: 40px;
      }
    }
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.2;
  }

  .shade {
    position: absolute;
    opacity: 0;
    background-color: $color-black-5p;
    @include transition(opacity 0.15s);
    pointer-events: none;
    z-index: 1;
  }

  .icon-wrap {
    position: relative;
    display: block;
    font-size: 0;
    line-height: 0;
    .icon {
      color: $color-text-4;
      &:hover {
        color: $color-text-2;
      }
    }
    .icon-modal {
      color: $color-bg-3;
      &:hover {
        color: $color-text-9;
      }
    }
    .bullet {
      position: absolute;
      display: block;
      top: 0;
      right: 0;
      width: 7px;
      height: 7px;
      background-color: $color-secondary-red;
      border: 1px solid $color-bg-3;
      border-radius: 50%;
      pointer-events: none;
      opacity: 0;
      @include transition(opacity 0.2s);

      &.on {
        opacity: 1;
      }

      &.count {
        top: -8px;
        right: -8px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        color: $color-white;
        @include text-style($text-body-8-bold);
      }
    }
  }

  > .loading-indicator {
    position: absolute;
  }
}
</style>
