import { useCreateHtmlByString } from './useCreateHtmlByString'
import type { Post, PostsItem } from '@store/post/type'
import { PostContentsType, ReplacePostContentsType } from '@store/types'

/**
 * 주어진 URL을 가진 iframe 비디오 요소를 생성
 *
 * @param {string} url - 비디오의 URL
 * @returns {string} - iframe 비디오 요소
 */
const createIframeVideo = (url: string): string => `
    <div class="video-container ratio-16x9">
      <iframe class="video" type="text/html" src="${url}" frameborder="0"></iframe>
    </div>
`

/**
 * DocumentFragment를 문자열 표현으로 변환
 *
 * @param {Node} fragment - 변환할 DocumentFragment
 * @returns {string} - DocumentFragment의 문자열 표현
 */
const getStringByDocumentFragment = (fragment: Node): string => {
  const container = document.createElement('div')
  container.appendChild(fragment)
  return container.innerHTML
}

/**
 * 앵커와 비디오 링크를 Open Graph와 비디오 iframes으로 교체
 * @param {Post['nttCn'] | PostsItem['nttCn']} postContents - 게시물의 내용
 * @param {PostContentsType} viewType - 게시물 내용 표시 유형
 * @param {boolean} ogErrException - Open Graph 오류 처리 여부
 * @returns {Promise<string>} - 변경된 게시물 내용
 * @throws {Error} - 교체 과정 중 오류가 발생할 경우
 */
export const useReplacerPostContents = async (
  postContents: Post['nttCn'] | PostsItem['nttCn'],
  viewType: PostContentsType,
  ogErrException = false
): Promise<string> => {
  try {
    // replace iframe to og:meta container
    postContents = await useHtmlContentsIframeToOG({
      htmlStr: postContents || '',
    })

    const els = useCreateHtmlByString(postContents || '')
    const videos = els.querySelectorAll(`a.${ReplacePostContentsType.VIDEO}`)
    const anchors = els.querySelectorAll(`a.${ReplacePostContentsType.LINK}`)

    // replace anchor to open graph
    for (const anchor of Array.from(anchors)) {
      const href = anchor.getAttribute('href')
      if (href) {
        const og = await useCreateOpenGraph(href, ogErrException)

        if (og) {
          anchor.parentNode?.insertBefore(
            useCreateHtmlByString(og),
            anchor.nextSibling
          )
          anchor.remove()
        }
      }
    }

    // replace anchor to video: only index view
    if (viewType === 'detail') {
      for (const video of Array.from(videos)) {
        const href = video.getAttribute('href')
        if (href) {
          const videoIframe = createIframeVideo(href)
          video.parentNode?.insertBefore(
            useCreateHtmlByString(videoIframe),
            video.nextSibling
          )
          video.remove()
        }
      }
    }

    return getStringByDocumentFragment(els)
  } catch (err) {
    return Promise.reject(err)
  }
}
