/**
 * 권한 요청
 */
export const requestAppPermission = {
  // permission: {
  //   camera: {
  //     IOS: '카메라',
  //     AOS: '카메라',
  //   },
  //   storage: {
  //     IOS: '사진',
  //     AOS: '갤러리',
  //   },
  //   address: {
  //     IOS: '주소록',
  //     AOS: '주소록',
  //   },
  //   location: {
  //     IOS: '위치',
  //     AOS: '위치',
  //   },
  // },
  requestModal: {
    description: {
      storage: {
        post: "포스트 작성 시 필요한 '사진/갤러리'에 대한 접근 권한이 없습니다. 설정으로 이동하여 권한 설정을 해주세요.",
        profileImage:
          "프로필 이미지 변경 시 필요한 '사진/갤러리'에 대한 접근 권한이 없습니다. 설정으로 이동하여 권한 설정을 해주세요.",
        mainProfileImage:
          "메인 프로필 이미지 변경 시 필요한 '사진/갤러리'에 대한 접근 권한이 없습니다. 설정으로 이동하여 권한 설정을 해주세요.",
        homeHeaderImage:
          "헤더 이미지 변경 시 필요한 '사진/갤러리'에 대한 접근 권한이 없습니다. 설정으로 이동하여 권한 설정을 해주세요.",
      },
    },
  },
}
