import type {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  RawAxiosRequestHeaders,
} from 'axios'
import { v4 as uuid } from 'uuid'

/**
 * Axios 요청에 대한 사용자 정의 헤더를 표현하는 인터페이스
 * `RawAxiosRequestHeaders` 인터페이스를 확장
 * @interface CustomExtendHeaders
 */
interface CustomExtendHeaders extends RawAxiosRequestHeaders {
  requestId: string
  color: string
}

/**
 * 고유한 요청 ID를 생성
 *
 * @function
 * @returns {string} 고유한 요청 ID를 반환
 *
 * @example
 * const requestId = genRequestId()
 * console.log(requestId); // "a3c91e6f"
 */
const genRequestId = (): string => uuid().split('-')[0]

/**
 * HTTP 요청의 세부 정보를 기록
 *
 * @param {AxiosRequestConfig} config - axios 요청 설정
 * @returns {void}
 */
const requestLog = (config: AxiosRequestConfig): void => {
  // console.log('requestLog:config', config)
  const requestId = (config.headers as CustomExtendHeaders)?.requestId
  const color = (config.headers as CustomExtendHeaders)?.color
  const method = config.method?.toUpperCase()
  const { url } = config
  const data = config.data || config.params

  console.log(
    `%c[REQ-${requestId}]%c ${method} ${url}`,
    'background-color: green; color: white',
    `background-color: transparent; color: ${color}`,
    data
  )
}

/**
 * 응답의 세부 내용을 콘솔에 기록
 *
 * @param {AxiosResponse} response - Axios에서의 응답 객체
 * @returns {void}
 */
const responseLog = (response: AxiosResponse): void => {
  const { config }: { [Properties in string]: any } = response
  const requestId = config.headers?.requestId
  const color = config.headers?.color
  const method = config.method?.toUpperCase()
  const { url } = config
  const { data } = response

  console.log(
    `%c[RES-${requestId}]%c ${method} ${url}`,
    'background-color: steelblue; color: white',
    `background-color: transparent; color: ${color}`,
    data
  )
}

/**
 * Axios 인스턴스에 대한 로깅 인터셉터를 설정
 * 이 인터셉터는 요청과 응답을 로깅
 *
 * @param {AxiosInstance} instance - 인터셉터를 설정할 Axios 인스턴스
 * @returns {void}
 */
export const setLogInterceptor = (instance: AxiosInstance): void => {
  instance.interceptors.request.use(
    (config) => {
      config.headers.requestId = genRequestId()
      config.headers.color = useGenRandomColor(100, 200)

      requestLog(config)

      return config
    },
    (error) => {
      console.log('setLogInterceptor:request:error', error)
    }
  )

  instance.interceptors.response.use(
    (response) => {
      responseLog(response)

      return response
    },
    (error) => {
      console.log('setLogInterceptor:response:error', error)
      return Promise.reject(error)
    }
  )
}
