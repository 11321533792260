import type { Routes } from '@configs/routes.types'

export const ROUTES: Routes = {
  HOME: {
    path: '/',
    name: 'index',
    pageID: '홈',
  },
  APPLY_CREATOR: {
    path: '/apply-creator',
    name: 'apply-creator',
    pageID: '크리에이터 신청',
  },
  APPLY_CREATOR_APPLY: {
    path: '/apply-creator/apply',
    name: 'apply-creator-apply',
    pageID: '크리에이터 신청 폼',
  },
  APPLY_RENT: {
    path: '/apply-rent',
    name: 'apply-rent',
    pageID: '입주 신청',
  },
  APPLY_RENT_FACILITY: {
    path: '/apply-rent/facility-:id()',
    name: 'apply-rent-facility-id',
    pageID: '입주 신청 폼',
  },
  APPLY_RENT_FACILITY_ADDITIONAL: {
    path: '/apply-rent/facility-:id()/additional',
    name: 'apply-rent-facility-id-additional',
    pageID: '입주 신청 일반 사용자',
  },
  APPLY_RENT_FACILITY_CREATOR: {
    path: '/apply-rent/facility-:id()/creator',
    name: 'apply-rent-facility-id-creator',
    pageID: '입주 신청 크리에이터',
  },
  APPLY_RENT_FACILITY_COMPLETE: {
    path: '/apply-rent/facility-:id()/complete',
    name: 'apply-rent-facility-id-complete',
    pageID: '입주 신청 완료',
  },
  APPLY_SUPER_MOON: {
    path: '/apply-super-moon',
    name: 'apply-super-moon',
    pageID: '슈퍼문 신청',
  },
  APPLY_SUPER_MOON_FORM: {
    path: '/apply-super-moon/form',
    name: 'apply-super-moon-form',
    pageID: '슈퍼문 신청 폼',
  },
  BOARD_EVENT: {
    path: '/board/event',
    name: 'board-event',
    pageID: '이벤트',
  },
  BOARD_EVENT_DETAIL: {
    path: '/board/event/:id()',
    name: 'board-event-id',
    pageID: '이벤트 상세',
  },
  BOARD_FAQ: {
    path: '/board/faq',
    name: 'board-faq',
    pageID: '자주 묻는 질문',
  },
  BOARD_FAQ_DETAIL: {
    path: '/board/faq/:id()',
    name: 'board-faq-id',
    pageID: '자주 묻는 질문 상세',
  },
  BOARD_NEWS: {
    path: '/board/news',
    name: 'board-news',
    pageID: '소식',
  },
  BOARD_NEWS_DETAIL: {
    path: '/board/news/:id()',
    name: 'board-news-id',
    pageID: '소식 상세',
  },
  BOARD_NOTICE: {
    path: '/board/notice',
    name: 'board-notice',
    pageID: '공지사항',
  },
  BOARD_NOTICE_DETAIL: {
    path: '/board/notice/:id()',
    name: 'board-notice-id',
    pageID: '공지사항 상세',
  },
  CALLBACK_PAY: {
    path: '/callback/pay',
    name: 'callback-pay',
    pageID: '콜백 페이',
    noRequireAppInit: true,
  },
  CALLBACK_SIGNIN: {
    path: '/callback/signin',
    name: 'callback-signin',
    pageID: '콜백 로그인',
    noRequireAppInit: true,
  },
  CALLBACK_SIGNUP: {
    path: '/callback/signup',
    name: 'callback-signup',
    pageID: '콜백 회원가입',
    noRequireAppInit: true,
  },
  CREATOR: {
    path: '/creator/:id()',
    name: 'creator-id',
    pageID: '크리에이터 홈',
  },
  CREATOR_HOME_DETAIL: {
    path: '/creator/:id()/:postId()',
    name: 'creator-id-index-postId',
    pageID: '크리에이터 홈 핫 포스트 상세',
    isModalPage: true,
  },
  CREATOR_ACTIVITIES: {
    path: '/creator/:id()/activities',
    name: 'creator-id-activities',
    pageID: '크리에이터 홈 활동',
  },
  CREATOR_ACTIVITIES_DETAIL: {
    path: '/creator/:id()/activities/:postId()',
    name: 'creator-id-activities-postId',
    pageID: '크리에이터 홈 활동 상세',
    isModalPage: true,
  },
  CREATOR_DONATION: {
    path: '/creator/:id()/donation',
    name: 'creator-id-donation',
    pageID: '크리에이터 홈 후원',
  },
  CREATOR_GUESTBOOK: {
    path: '/creator/:id()/guestbook',
    name: 'creator-id-guestbook',
    pageID: '크리에이터 홈 방명록',
  },
  CREATOR_NOTICE: {
    path: '/creator/:id()/notice',
    name: 'creator-id-notice',
    pageID: '크리에이터 홈 공지사항',
  },
  CREATOR_NOTICE_DETAIL: {
    path: '/creator/:id()/notice/:noticeId()',
    name: 'creator-id-notice-noticeId',
    pageID: '크리에이터 홈 공지사항 상세',
    isModalPage: true,
  },
  CREATOR_NOVAPLUS: {
    path: '/creator/:id()/nova-plus',
    name: 'creator-id-nova-plus',
    pageID: '크리에이터 홈 노바+',
  },
  CREATOR_NOVAPLUS_DETAIL: {
    path: '/creator/:id()/nova-plus/:postId()',
    name: 'creator-id-nova-plus-postId',
    parentName: 'creator-id-nova-plus',
    pageID: '크리에이터 홈 노바+ 상세',
  },
  CREATOR_POSTS: {
    path: '/creator/:id()/posts',
    name: 'creator-id-posts',
    pageID: '크리에이터 홈 포스트',
  },
  CREATOR_POSTS_DETAIL: {
    path: '/creator/:id()/posts/:postId()',
    name: 'creator-id-posts-postId',
    parentName: 'creator-id-posts',
    pageID: '크리에이터 홈 포스트 상세',
  },
  CREATOR_REWARD: {
    path: '/creator/:id()/reward',
    name: 'creator-id-reward',
    pageID: '크리에이터 홈 보상',
  },
  CREATOR_STATISTICS: {
    path: '/creator/:id()/statistics',
    name: 'creator-id-statistics',
    pageID: '크리에이터 홈 통계',
  },
  CREATOR_SPONSORS: {
    path: '/creator/sponsors',
    name: 'creator-sponsors',
    pageID: '크리에이터 노바+ 후원자 목록',
  },
  CREATOR_SUBSCRIBE_CANCEL: {
    path: '/creator/subscribe/cancel/:creatorUserSn',
    name: 'creator-subscribe-cancel-creatorUserSn',
    pageID: '크리에이터 노바+ 구독 취소',
  },
  CREATOR_SUBSCRIBE_OPENED: {
    path: '/creator/subscribe/opened/:creatorUserSn',
    name: 'creator-subscribe-opened-creatorUserSn',
    pageID: '크리에이터 노바+ 구독 개설',
  },
  CREATOR_SUBSCRIBE_REFUND: {
    path: '/creator/subscribe/refund/:creatorUserSn',
    name: 'creator-subscribe-refund-creatorUserSn',
    pageID: '크리에이터 노바+ 구독 환불 신청',
  },
  CREATOR_SUBSCRIBE_SUPPORT: {
    path: '/creator/subscribe/support/:creatorUserSn',
    name: 'creator-subscribe-support-creatorUserSn',
    pageID: '크리에이터 노바+ 구독 신청',
  },
  CREATOR_COLLECTIONS: {
    path: '/creator/:id()/collections',
    name: 'creator-id-collections',
    pageID: '크리에이터 홈 마이 컬렉션',
  },
  CREATOR_COLLECTIONS_DETAIL: {
    path: '/creator/:id()/collections/:collectionId()',
    name: 'creator-id-collections-collectionId',
    pageID: '크리에이터 홈 마이 컬렉션 상세',
    isModalPage: true,
  },
  CREATORS_MY: {
    path: '/creators/my',
    name: 'creators-my',
    pageID: '나의 크리에이터 목록',
  },
  CREATORS_ALL: {
    path: '/creators/all',
    name: 'creators-all',
    pageID: '크리에이터 목록',
  },
  DAILY_CHECK: {
    path: '/daily-check',
    name: 'daily-check',
    pageID: '출석체크',
  },
  FEED: {
    path: '/feed',
    name: 'feed',
    pageID: '피드',
  },
  FEED_DETAIL: {
    path: '/feed/:id()',
    name: 'feed-id',
    parentName: 'feed',
    pageID: '피드 상세',
  },
  FUND: {
    path: '/fund',
    name: 'fund',
    pageID: '펀드',
  },
  FUND_DETAIL: {
    path: '/fund/:id()',
    name: 'fund-index-id',
    pageID: '펀드 프로젝트 선정 상세',
    isModalPage: true,
  },
  FUND_GAUDI: {
    path: '/fund/gaudi',
    name: 'fund-gaudi',
    pageID: '펀드 가우디',
  },
  FUND_HISTORY: {
    path: '/fund/history',
    name: 'fund-history',
    pageID: '펀드 프로젝트 히스토리',
  },
  FUND_HISTORY_DETAIL: {
    path: '/fund/history/:id()',
    name: 'fund-history-id',
    pageID: '펀드 프로젝트 히스토리 상세',
    isModalPage: true,
  },
  FUND_NOTICE: {
    path: '/fund/notice',
    name: 'fund-notice',
    pageID: '펀드 공지사항',
  },
  FUND_NOTICE_DETAIL: {
    path: '/fund/notice/:id()',
    name: 'fund-notice-id',
    pageID: '펀드 공지사항 상세',
  },
  FUND_GAUDI_FUND: {
    path: '/fund/gaudi-fund',
    name: 'fund-gaudi-fund',
    pageID: '펀드 가우디',
  },
  HASHTAG: {
    path: '/hashtag',
    name: 'hashtag',
    pageID: '해시태그',
  },
  HASHTAG_DETAIL: {
    path: '/hashtag/:id()',
    name: 'hashtag-id',
    parentName: 'hashtag',
    pageID: '해시태그 상세',
  },
  NOVAPLUS: {
    path: '/nova-plus',
    name: 'nova-plus',
    pageID: '노바+',
  },
  NOVAPLUS_DETAIL: {
    path: '/nova-plus/:id()',
    name: 'nova-plus-id',
    parentName: 'nova-plus',
    pageID: '노바+ 상세',
  },
  OULIM: {
    path: '/oulim',
    name: 'oulim',
    pageID: 'Oulim X The Moon Labs',
  },
  POST: {
    path: '/post/:id()',
    name: 'post-id',
    pageID: '포스트 상세',
  },
  REFERRAL: {
    path: '/referral/@:id()',
    name: 'referral-@id',
    pageID: '추천인 회원가입',
    noRequireAppInit: true,
  },
  SEARCH: {
    path: '/search',
    name: 'search',
    pageID: '검색',
  },
  SEARCH_POST_MODAL: {
    path: '/search/:id()',
    name: 'search-index-id',
    isModalPage: true,
    pageID: '검색 포스트 상세',
  },
  SEARCH_DETAIL: {
    path: '/search/detail',
    name: 'search-detail',
    pageID: '검색 결과',
  },
  SEARCH_DETAIL_POST: {
    path: '/search/detail/:id()',
    name: 'search-detail-index-id',
    parentName: 'search-detail',
    pageID: '검색 결과 포스트 상세',
  },
  SEARCH_DETAIL_COMMENT: {
    path: '/search/detail/comment',
    name: 'search-detail-comment',
    pageID: '검색 결과 댓글 목록',
  },
  SEARCH_DETAIL_COMMENT_DETAIL: {
    path: '/search/detail/comment/:id()',
    name: 'search-detail-comment-id',
    parentName: 'search-detail-comment',
    pageID: '검색 결과 댓글 상세',
  },
  SEARCH_DETAIL_HASHTAG: {
    path: '/search/detail/hashtag',
    name: 'search-detail-hashtag',
    pageID: '검색 결과 해시태그 목록',
  },
  SEARCH_DETAIL_NOVAPLUS: {
    path: '/search/detail/nova-plus',
    name: 'search-detail-nova-plus',
    pageID: '검색 결과 노바+ 목록',
  },
  SEARCH_DETAIL_NOVAPLUS_DETAIL: {
    path: '/search/detail/nova-plus/:id()',
    name: 'search-detail-nova-plus-id',
    parentName: 'search-detail-nova-plus',
    pageID: '검색 결과 노바+ 상세',
  },
  SEARCH_DETAIL_USER: {
    path: '/search/detail/user',
    name: 'search-detail-user',
    pageID: '검색 결과 사용자',
  },
  TERMS: {
    path: '/terms/:id()',
    name: 'terms-id',
    pageID: '이용약관 상세',
  },
  USER: {
    path: '/user/:id()',
    name: 'user-id-index',
    pageID: '사용자 홈',
  },
  USER_HOME_DETAIL: {
    path: '/user/:id()/:postId()',
    name: 'user-id-index-postId',
    parentName: 'user-id-index',
    pageID: '사용자 홈 포스트 상세',
  },
  USER_REWARD: {
    path: '/user/:id()/reward',
    name: 'user-id-reward',
    pageID: '사용자 홈 보상',
  },
  USER_DONATION: {
    path: '/user/:id()/donation',
    name: 'user-id-donation',
    pageID: '사용자 홈 후원',
  },
  USER_ACTIVITIES: {
    path: '/user/:id()/activities',
    name: 'user-id-activities',
    pageID: '사용자 홈 활동',
  },
  USER_ACTIVITIES_DETAIL: {
    path: '/user/:id()/activities/:postId()',
    name: 'user-id-activities-postId',
    pageID: '사용자 홈 활동 상세',
    isModalPage: true,
  },
  USER_COLLECTIONS: {
    path: '/user/:id()/collections',
    name: 'user-id-collections',
    pageID: '사용자 홈 마이 컬렉션',
  },
  USER_COLLECTIONS_DETAIL: {
    path: '/user/:id()/collections/:collectionId()',
    name: 'user-id-collections-collectionId',
    pageID: '사용자 홈 마이 컬렉션 상세',
    isModalPage: true,
  },
  USER_SPONSORS: {
    path: '/user/sponsors',
    name: 'user-sponsors',
    pageID: '사용자 홈 후원자 목록',
  },
  W3CF: {
    path: '/w3cf',
    name: 'w3cf',
    pageID: 'W3CF',
  },
  W3CF_DETAIL: {
    path: '/w3cf/index/:id()',
    name: 'w3cf-index-id',
    pageID: 'W3CF 상세',
    isModalPage: true,
  },
}
