import * as SendbirdType from './type'
import SendbirdApi from './url'
import { api } from '@api'

export default {
  /**
   * 라이브 생성 권한 체크
   * @method GET
   */
  fetchCreateLivePermission() {
    return api.get<SendbirdType.CreateLivePermission>(
      SendbirdApi.FETCH_CREATE_LIVE_PERMISSION
    )
  },

  /**
   * 라이브 토큰 게이팅 대상 목록 조회 - 플레이놈 NFT Collection 목록 조회
   * @method GET
   */
  fetchLiveNftCollections() {
    return api.get<SendbirdType.LiveNftCollection[]>(
      SendbirdApi.FETCH_LIVE_NFT_COLLECTIONS
    )
  },

  /**
   * 라이브 생성
   * @method POST
   * @param payload
   */
  createLiveEvent(payload: SendbirdType.CreateLivePayload) {
    return api.post<SendbirdType.LiveEvent>(
      SendbirdApi.REQ_CREATE_LIVE,
      payload
    )
  },

  /**
   * 라이브 입장 가능 여부 확인: token & sndbrdLiveSn 조건
   * @method GET
   * @param payload
   */
  fetchEnterLiveAvailable(payload: SendbirdType.EnterLiveAvailablePayload) {
    return api.get<SendbirdType.EnterLiveAvailable>(
      SendbirdApi.FETCH_ENTER_LIVE_PERMISSION,
      { params: payload }
    )
  },

  /**
   * 라이브 입장 가능 여부 확인: sendbird user id & sendbird live event id 조건
   * @method GET
   * @param payload
   */
  fetchEnterLiveAvailableBySbUserId(
    payload: SendbirdType.EnterLiveAvailableBySbUserIdPayload
  ) {
    return api.get<SendbirdType.EnterLiveAvailable>(
      SendbirdApi.FETCH_ENTER_LIVE_PERMISSION_BY_SB_USER_ID,
      { params: payload }
    )
  },

  /**
   * Sendbird userId 생성
   * @method POST
   */
  createSendbirdUserId() {
    return api.post<SendbirdType.CreateSendbirdUserId>(
      SendbirdApi.CREATE_SENDBIRD_USER_ID
    )
  },

  /**
   * OnAir 라이브 목록 조회
   * @method GET
   * @param payload
   */
  fetchOnAirLives(payload: SendbirdType.OnAirLivesPayload) {
    return api.get<SendbirdType.OnAirLive[]>(SendbirdApi.FETCH_ON_AIR_LIVES, {
      params: payload,
    })
  },

  /**
   * 오픈 채널 생성
   * @method POST
   * @param payload
   */
  createOpenChannel(payload: SendbirdType.CreateOpenChannelPayload) {
    return api.post<SendbirdType.OpenChannel>(
      SendbirdApi.CREATE_OPEN_CHANNEL,
      payload
    )
  },
}
