import { v4 } from 'uuid'
import * as MyPageType from './type'
import MyPageService from './service'
import { initMyPageState } from './init'
import * as PostType from '@store/post/type'
import { BlockingTarget } from '@store/types'
import {
  APPLY_CREATOR_AVAILABLE_STATE_CODE,
  APPLY_CREATOR_STATE_CODE,
} from '@configs/creator'

export * from './init'
export * from './type'
export const useMyPageStore = defineStore('mypage', () => {
  const postStore = usePostStore()
  const userStore = useUserStore()
  const creatorStore = useCreatorStore()
  const mypageStore = useMyPageStore()
  const { gtEvent } = useGoogleTag()
  const profile = ref(initMyPageState.profile)
  const posts = ref(initMyPageState.posts)
  const postCount = ref(initMyPageState.postCount)
  const recommendPosts = ref(initMyPageState.recommendPosts)
  const recommendCount = ref(initMyPageState.recommendCount)
  const commentPosts = ref(initMyPageState.commentPosts)
  const commentCount = ref(initMyPageState.commentCount)
  const scrapPosts = ref(initMyPageState.scrapPosts)
  const scrapCount = ref(initMyPageState.scrapCount)
  const rewards = ref(initMyPageState.rewards)
  const rewardCount = ref(initMyPageState.rewardCount)
  const showRewards = ref(initMyPageState.showRewards)
  const donationQty = ref(initMyPageState.donationQty)
  const receivedDonations = ref(initMyPageState.receivedDonations)
  const sentDonations = ref(initMyPageState.sentDonations)
  const homeProfile = ref(initMyPageState.homeProfile)
  const premiumCreators = ref(initMyPageState.premiumCreators)
  const premiumSponsors = ref(initMyPageState.premiumSponsors)
  const myFollowers = ref(initMyPageState.myFollowers)
  const myFollowings = ref(initMyPageState.myFollowings)
  const myBlocks = ref(initMyPageState.myBlocks)
  const myCollections = ref(initMyPageState.myCollections)
  const myCollectionNft = ref(initMyPageState.myCollectionNft)

  /**
   * 크리에이터 신청 가능 상태
   * @param state
   */
  const applyCreatorStatus = computed(() => {
    if (!profile.value) return APPLY_CREATOR_AVAILABLE_STATE_CODE.REQUIRE_SIGNIN
    if (profile.value.cmtyUserSeCode === APPLY_CREATOR_STATE_CODE.IS_CREATOR)
      return APPLY_CREATOR_AVAILABLE_STATE_CODE.APPROVED

    /* 요청에 따라 반복되는 상황 체크를 수정 */
    const isRejectedAndAvailable =
      profile.value.creatorApplicationAvailableAt === 'Y' &&
      profile.value.crtfcProgrsSttusCode === APPLY_CREATOR_STATE_CODE.REJECT

    if (isRejectedAndAvailable)
      return APPLY_CREATOR_AVAILABLE_STATE_CODE.AVAILABLE

    /* 추후 상태 코드를 추가해야 하는 경우에 대비하여 메서드를 추가 */
    const isUnderReview = APPLY_CREATOR_STATE_CODE.IN_REVIEW.includes(
      profile.value.crtfcProgrsSttusCode || ''
    )
    const isApproved = APPLY_CREATOR_STATE_CODE.APPROVED.includes(
      profile.value.crtfcProgrsSttusCode || ''
    )

    if (isUnderReview) return APPLY_CREATOR_AVAILABLE_STATE_CODE.UNDER_REVIEW
    if (isApproved) return APPLY_CREATOR_AVAILABLE_STATE_CODE.APPROVED
    if (profile.value.crtfcProgrsSttusCode === APPLY_CREATOR_STATE_CODE.REJECT)
      return APPLY_CREATOR_AVAILABLE_STATE_CODE.REJECT

    return APPLY_CREATOR_AVAILABLE_STATE_CODE.AVAILABLE
  })

  /**
   * 프로필 정보 조회
   */
  const fetchProfile = async () => {
    const { data } = await MyPageService.fetchProfile()
    updateProfile(data)
    return data
  }

  /**
   * 프로필 정보 업데이트
   */
  const updateProfile = (payload: MyPageType.Profile | null) => {
    profile.value = payload
  }

  /**
   * 프로필 닉네임/소개 변경
   */
  const editProfile = async (payload: MyPageType.ProfilePayload) => {
    await MyPageService.editProfile(payload)
    await fetchProfile()
  }

  /**
   *  프로필 사진 등록 및 수정
   */
  const createProfilePhoto = async (payload: FormData) => {
    await MyPageService.createProfilePhoto(payload)
    gtEvent('fileAction', {
      eventCategory: '파일',
      eventAction: '프로필 이미지 파일 업로드',
      eventLabel: 'setting',
      eventSlot: '프로필 이미지 파일 업로드',
      eventI18nAddr: '',
      eventComponent: 'Button',
      fileName: mypageStore.profile!.userProflUrl,
    })
    // profile.value = { ...profile.value!, userProflUrl }
    gtEvent('userAction', {
      eventCategory: '사용자',
      eventAction: '프로필 이미지 변경',
      eventLabel: '',
      eventSlot: '',
      eventI18nAddr: '',
      eventComponent: 'Button',
    })
    await fetchProfile()
  }

  /**
   *  프로필 사진 삭제
   */
  const deleteProfilePhoto = async () => {
    const fileNameBeforeDelete = mypageStore.profile!.userProflUrl
    await MyPageService.deleteProfilePhoto()
    gtEvent('fileAction', {
      eventCategory: '파일',
      eventAction: '프로필 파일 삭제',
      eventLabel: '',
      eventSlot: '프로필 삭제 모달',
      eventI18nAddr: '',
      eventComponent: 'Button',
      fileName: fileNameBeforeDelete,
    })
    await fetchProfile()
    gtEvent('userAction', {
      eventCategory: '사용자',
      eventAction: '프로필 이미지 삭제',
      eventLabel: '',
      eventSlot: '프로필 삭제 모달',
      eventI18nAddr: '',
      eventComponent: 'Button',
    })
  }

  /**
   * 내 포스트 목록 조회
   */
  const fetchPosts = async (
    payload: MyPageType.UserPostsPayload,
    refresh = false
  ) => {
    // 1. 포스트 리스트 조회시 refresh 일때 필요없는 조회 조건 삭제
    if (refresh) {
      delete payload.lastCmtyNttSn
      delete payload.lastSumSvcActScore
    }

    posts.value.loading = true

    const { data, headers } = await MyPageService.fetchPosts({
      ...payload,
      pageNum: refresh ? 1 : payload.pageNum + 1,
    })

    posts.value = {
      items: refresh ? data : posts.value.items.concat(data),
      payload: {
        ...payload,
        pageNum: refresh ? 1 : payload.pageNum + 1,
        lastCmtyNttSn: data.length ? data[data.length - 1].cmtyNttSn : 0,
      },
      refresh,
      last: typeof headers['pagination-location'] === 'undefined',
    }

    if (payload.orderBy === 'hot') {
      posts.value.payload.lastSumSvcActScore =
        posts.value.items[posts.value.items.length - 1]?.sumSvcActScore || 0
    }

    posts.value.loading = false
  }

  /**
   * 내 게시물 개수 조회
   */
  const fetchPostCount = async (payload: MyPageType.PostCountPayload) => {
    const { data } = await MyPageService.fetchPostCount(payload)
    postCount.value = data
  }

  /**
   * 좋아요 받은 리스트 목록 조회
   */
  const fetchGetRecommendPosts = async (
    payload: MyPageType.GetRecommendsPayload,
    refresh = false
  ) => {
    const { data, headers } = await MyPageService.fetchGetRecommendPosts({
      ...payload,
      pageNum: refresh ? 1 : payload.pageNum + 1,
    })

    recommendPosts.value.get = {
      items: refresh ? data : recommendPosts.value.get.items.concat(data),
      payload: { ...payload, pageNum: refresh ? 1 : payload.pageNum + 1 },
      refresh,
      last: typeof headers['pagination-location'] === 'undefined',
    }
  }

  /**
   * 좋아요 한 리스트 목록 조회
   */
  const fetchSetRecommendPosts = async (
    payload: MyPageType.SetRecommendsPayload,
    refresh = false
  ) => {
    const { data, headers } = await MyPageService.fetchSetRecommendPosts({
      ...payload,
      pageNum: refresh ? 1 : payload.pageNum + 1,
    })

    recommendPosts.value.set = {
      items: refresh ? data : recommendPosts.value.set.items.concat(data),
      payload: { ...payload, pageNum: refresh ? 1 : payload.pageNum + 1 },
      refresh,
      last: typeof headers['pagination-location'] === 'undefined',
    }
  }

  /**
   * 내 좋아요 받은 수 조회
   */
  const fetchRecommendCount = async () => {
    const { data } = await MyPageService.fetchRecommendCount()
    recommendCount.value = data
    return data
  }

  /**
   * 내 스크랩 게시물 개수 조회
   */
  const fetchScrapCount = async () => {
    const { data } = await MyPageService.fetchScrapCount()
    scrapCount.value = data
    return data
  }

  /**
   * 내 스크랩 게시물 리스트 목록 조회
   */
  const fetchScrapPosts = async (
    payload: MyPageType.ScrapPostsPayload,
    refresh = false
  ) => {
    const { data, headers } = await MyPageService.fetchScrapPosts({
      ...payload,
      pageNum: refresh ? 1 : payload.pageNum + 1,
    })

    scrapPosts.value = {
      items: refresh ? data : scrapPosts.value.items.concat(data),
      payload: { ...payload, pageNum: refresh ? 1 : payload.pageNum + 1 },
      refresh,
      last: typeof headers['pagination-location'] === 'undefined',
    }
  }

  /**
   * 내 댓글 게시물 조회
   */
  const fetchCommentPosts = async (
    payload: MyPageType.MyCommentsPayload,
    refresh = false
  ) => {
    const { data, headers } = await MyPageService.fetchCommentPosts({
      ...payload,
      pageNum: refresh ? 1 : payload.pageNum + 1,
    })
    commentPosts.value = {
      items: refresh ? data : commentPosts.value.items.concat(data),
      payload: { ...payload, pageNum: refresh ? 1 : payload.pageNum + 1 },
      refresh,
      last: typeof headers['pagination-location'] === 'undefined',
    }
  }

  /**
   * 내 댓글 게시물 개수 조회
   */
  const fetchCommentCount = async () => {
    const { data } = await MyPageService.fetchCommentCount()
    commentCount.value = data
    return data
  }

  /**
   * 내 보상 게시물 조회
   */
  const fetchRewards = async (
    payload: MyPageType.RewardsPayload,
    refresh = false
  ) => {
    const { data, headers } = await MyPageService.fetchRewards({
      ...payload,
      pageNum: refresh ? 1 : payload.pageNum + 1,
    })

    // 유니크 아이디 생성
    data.forEach((item) => {
      item.id = v4()
    })

    rewards.value = {
      items: refresh ? data : rewards.value.items.concat(data),
      payload: { ...payload, pageNum: refresh ? 1 : payload.pageNum + 1 },
      refresh,
      last: typeof headers['pagination-location'] === 'undefined',
    }
  }

  /**
   * 내 보상 상세내역 조회
   */
  const fetchRewardDetail = async (payload: MyPageType.RewardDetailPayload) => {
    const { data } = await MyPageService.fetchRewardDetail(payload)

    // 보상 리스트 아이템에 상세 정보 주입
    rewards.value.items.some((item) => {
      if (item.id === payload.id) {
        item.detail = data
        return true
      }
      return false
    })
  }

  /**
   * 내 보상 게시물 개수 조회
   */
  const fetchRewardCount = async (payload: MyPageType.RewardCountPayload) => {
    if (rewardCount.value.rwardSmLM > 0) {
      return
    }
    const { data } = await MyPageService.fetchRewardCount(payload)
    rewardCount.value = data
  }

  /**
   * 마이페이지 포스트 목록 업데이트
   */
  const updatePostModel = (payload: {
    cmtyNttSn: PostType.PostsItem['cmtyNttSn']
    replace: any
  }) => {
    // 1. 나의 포스트 목록 업데이트
    const postsItemIdx = posts.value.items.findIndex(
      (item) => item.cmtyNttSn === payload.cmtyNttSn
    )
    if (postsItemIdx > -1) {
      posts.value.items[postsItemIdx] = {
        ...posts.value.items[postsItemIdx],
        ...payload.replace,
      }
    }

    // 2. 댓글 단 포스트 목록 업데이트
    const commentPostItemIdx = commentPosts.value.items.findIndex(
      (item) => item.cmtyNttSn === payload.cmtyNttSn
    )
    if (commentPostItemIdx > -1) {
      commentPosts.value.items[commentPostItemIdx] = {
        ...commentPosts.value.items[commentPostItemIdx],
        ...payload.replace,
      }
    }

    // 3-1. 좋아요 받은 포스트 목록 업데이트
    const recommendGetPostItemIdx = recommendPosts.value.get.items.findIndex(
      (item) => item.cmtyNttSn === payload.cmtyNttSn
    )
    if (recommendGetPostItemIdx > -1) {
      recommendPosts.value.get.items[recommendGetPostItemIdx] = {
        ...recommendPosts.value.get.items[recommendGetPostItemIdx],
        ...payload.replace,
      }
    }

    // 3-2. 좋아요 한 포스트 목록 업데이트(좋아요 해제시 삭제)
    const recommendSetPostItemIdx = recommendPosts.value.set.items.findIndex(
      (item) => item.cmtyNttSn === payload.cmtyNttSn
    )
    if (recommendSetPostItemIdx > -1) {
      recommendPosts.value.set.items[recommendSetPostItemIdx] = {
        ...recommendPosts.value.set.items[recommendSetPostItemIdx],
        ...payload.replace,
      }

      if (payload.replace.rcFlag === '0') {
        recommendPosts.value.set.items.splice(recommendSetPostItemIdx, 1)

        postStore.initPost()
        postStore.initCommentsAndReplies()
      }
    }

    // 4-1. 스크랩 포스트 목록 업데이트(스크랩 해제시 목록에서 삭제)
    const scrapPostItemIdx = scrapPosts.value.items.findIndex(
      (item) => item.cmtyNttSn === payload.cmtyNttSn
    )
    if (scrapPostItemIdx > -1) {
      scrapPosts.value.items[scrapPostItemIdx] = {
        ...scrapPosts.value.items[scrapPostItemIdx],
        ...payload.replace,
      }

      if (payload.replace.scFlag === '0') {
        scrapPosts.value.items.splice(scrapPostItemIdx, 1)

        postStore.initPost()
        postStore.initCommentsAndReplies()
      }
    }
  }

  /**
   * 마이페이지 포스트 목록 삭제
   */
  const removePost = (payload: PostType.PostActionPayload) => {
    // 1. 나의 포스트 목록에서 아이템 삭제
    const postsItemIdx = posts.value.items.findIndex(
      (item) => item.cmtyNttSn === payload.cmtyNttSn
    )
    if (postsItemIdx > -1) {
      posts.value.items.splice(postsItemIdx, 1)
    }

    // 2. 댓글 단 포스트 목록에서 아이템 삭제
    const commentPostItemIdx = commentPosts.value.items.findIndex(
      (item) => item.cmtyNttSn === payload.cmtyNttSn
    )
    if (commentPostItemIdx > -1) {
      commentPosts.value.items.splice(commentPostItemIdx, 1)
    }
    // 3-1. 좋아요 받은 포스트 목록에서 아이템 삭제
    const recommendGetPostItemIdx = recommendPosts.value.get.items.findIndex(
      (item) => item.cmtyNttSn === payload.cmtyNttSn
    )
    if (recommendGetPostItemIdx > -1) {
      recommendPosts.value.get.items.splice(recommendGetPostItemIdx, 1)
    }
    // 3-2. 좋아요 한 포스트 목록에서 아이템 삭제
    const recommendSetPostItemIdx = recommendPosts.value.set.items.findIndex(
      (item) => item.cmtyNttSn === payload.cmtyNttSn
    )
    if (recommendSetPostItemIdx > -1) {
      recommendPosts.value.set.items.splice(recommendSetPostItemIdx, 1)
    }

    // 4. 스크랩 포스트 목록에서 아이템 삭제
    const scrapPostItemIdx = scrapPosts.value.items.findIndex(
      (item) => item.cmtyNttSn === payload.cmtyNttSn
    )
    if (scrapPostItemIdx > -1) {
      scrapPosts.value.items.splice(scrapPostItemIdx, 1)
    }
  }

  /**
   * 레퍼럴 코드 등록
   */
  const regReferralCode = (payload: MyPageType.RegReferralCodePayload) =>
    MyPageService.regReferralCode(payload)

  /**
   * 후원 금액 조회
   */
  const fetchDonationQty = async () => {
    const { data } = await MyPageService.fetchDonationQty()
    donationQty.value = data
  }

  /**
   * 후원 내역(received) 조회
   */
  const fetchReceivedDonations = async (
    payload: MyPageType.DonationsPayload,
    refresh = false
  ) => {
    // 1. 중복 호출을 막기위해 로딩중이면 이벤트 막음
    if (receivedDonations.value.loading) return

    // 2. 로딩
    receivedDonations.value.loading = true

    // 3. 최초 조회시 페이징 추적을 위한 항목 삭제
    if (refresh) {
      delete payload.lastUserCrtrDntnSn
      delete payload.lastDonationQty
    }

    // 4. 조회
    const { data, headers } = await MyPageService.fetchReceivedDonations({
      ...payload,
      pageNum: refresh ? 1 : payload.pageNum + 1,
    })

    // 5. 저장소 업데이트
    receivedDonations.value = {
      items: refresh ? data : receivedDonations.value.items.concat(data),
      payload: {
        ...payload,
        pageNum: refresh ? 1 : payload.pageNum + 1,
        lastUserCrtrDntnSn: data.length
          ? data[data.length - 1].userCrtrDntnSn
          : 0,
      },
      refresh,
      last: typeof headers['pagination-location'] === 'undefined',
    }

    // 6. 조회 조건이 후원 금액 순 일때 다음 조회 조건을 위해 조건 추가
    if (payload.orderBy === 'donationQty') {
      receivedDonations.value.payload.lastDonationQty =
        data[data.length - 1].cxDntnQty
    }

    // 7. 모든 프로세스 끝나고 로딩 상태 끔
    receivedDonations.value.loading = false
  }

  /**
   * 후원 내역(sent) 조회
   */
  const fetchSentDonations = async (
    payload: MyPageType.DonationsPayload,
    refresh = false
  ) => {
    // 1. 중복 호출을 막기위해 로딩중이면 이벤트 막음
    if (sentDonations.value.loading) return

    // 2. 로딩
    sentDonations.value.loading = true

    // 3. 최초 조회시 페이징 추적을 위한 항목 삭제
    if (refresh) {
      delete payload.lastUserCrtrDntnSn
      delete payload.lastDonationQty
    }

    // 4. 조회
    const { data, headers } = await MyPageService.fetchSentDonations({
      ...payload,
      pageNum: refresh ? 1 : payload.pageNum + 1,
    })

    // 5. 저장소 업데이트
    sentDonations.value = {
      items: refresh ? data : sentDonations.value.items.concat(data),
      payload: {
        ...payload,
        pageNum: refresh ? 1 : payload.pageNum + 1,
        lastUserCrtrDntnSn: data.length
          ? data[data.length - 1].userCrtrDntnSn
          : 0,
      },
      refresh,
      last: typeof headers['pagination-location'] === 'undefined',
    }

    // 6. 조회 조건이 후원 금액 순 일때 다음 조회 조건을 위해 조건 추가
    if (payload.orderBy === 'donationQty') {
      sentDonations.value.payload.lastDonationQty =
        data[data.length - 1].cxDntnQty
    }

    // 7. 모든 프로세스 끝나고 로딩 상태 끔
    sentDonations.value.loading = false
  }

  /**
   * 마이홈 프로필 조회
   */
  const fetchHomeProfile = async (payload: MyPageType.HomeProfilePayload) => {
    const { data } = await MyPageService.fetchHomeProfile(payload)
    homeProfile.value = data
    return data
  }

  /**
   *  프로필 사진 등록 및 수정
   */
  const regProfileBgImg = async (formData: FormData) => {
    const { data } = await MyPageService.regProfileBgImg(formData)

    // 1. 일반 유저 프로필 이미지 업데이트
    if (
      homeProfile.value &&
      homeProfile.value.userSn === profile.value?.userSn
    ) {
      homeProfile.value.backgroundImage = data
    }

    // 2. 크리에이터 유저 프로필 이미지 업데이트
    if (
      creatorStore.profile &&
      creatorStore.profile.userSn === profile.value?.userSn
    ) {
      creatorStore.profile.backgroundImage = data
    }

    return data
  }

  /**
   * 나의 프리미엄 가입 크리에이터 목록 조회
   * @description 로그인 하지 않고 호출하면 401에러 발생
   */
  const fetchPremiumCreators = async () => {
    if (premiumCreators.value.loading) return

    try {
      premiumCreators.value.loading = true
      premiumCreators.value.error = false
      const { data } = await MyPageService.fetchPremiumCreators()

      // 1. 로그인 한 상황, 2. 내가 크리에이터 3. 개설 한 상황
      if (
        userStore.user &&
        userStore.user.cmtyUserSeCode === 'C' &&
        userStore.user.prmbrshAt === 'Y'
      ) {
        const creator: MyPageType.PremiumCreator = {
          crtrProfileUrl: userStore.user.userProflUrl, // 크리에이터 프로필 사진 URL
          crtrUserNcnm: userStore.user.userNcnm, // 크리에이터 닉네임
          crtrUserSn: userStore.user.userSn, // 크리에이터 사용자 일련번호
          newPostAt: 'N', // 크리에이터 신규 게시물 여부 Y/N
        }
        data.unshift(creator)
      }
      premiumCreators.value.data = data
    } catch {
      premiumCreators.value.error = false
    } finally {
      premiumCreators.value.loading = false
    }
    // premiumCreators.value.data = data
  }

  /**
   * 노바+ 후원 상세내역 조회
   */
  const fetchSponsorDetail = async (
    payload: MyPageType.PremiumSponsorDetailPayload
  ) => {
    const { data } = await MyPageService.fetchSponsorDetail(payload)
    return data
  }

  /**
   * 노바+ 후원 목록 조회
   */
  const fetchPremiumSponsors = async (
    payload: MyPageType.PremiumSponsorsPayload
  ) => {
    if (!payload.searchKeyword) delete payload.searchKeyword
    const { data } = await MyPageService.fetchPremiumSponsors(payload)

    // 후원 상태 업데이트
    premiumSponsors.value = {
      items: data.list,
      payload,
      pagination: {
        currentPage: payload.pageNum,
        itemPerPage: payload.pageSize,
        pagePerScope: premiumSponsors.value.pagination.pagePerScope,
        totals: data.totalCount,
        pageStartIdx: (payload.pageNum - 1) * payload.pageSize,
      },
    }
  }

  /**
   * 프리미엄 구독(후원) 만료 목록 조회
   * @description 전역 스토에서 상태관리 하지 않음
   */
  const fetchPremiumExpiredList = () => MyPageService.fetchPremiumExpiredList()

  /**
   * 팔로워 삭제
   * @param payload
   * @param target
   */
  const deleteMyFollower = async (
    payload: MyPageType.DeleteFollowerPayload,
    target: BlockingTarget
  ) => {
    await MyPageService.deleteMyFollower(payload)

    // 1. 팔로워 목록에서 팔로워 삭제
    const myFollowersItemIdx = myFollowers.value.items.findIndex(
      (item) => item.userSn === payload.flwUserSn
    )
    if (myFollowersItemIdx !== -1) {
      myFollowers.value.items.splice(myFollowersItemIdx, 1)
    }

    // 2.크리에이터 홈 혹은 일반 유저홈 following 디스카운트
    switch (target) {
      case BlockingTarget.CREATOR:
        if (creatorStore.profile) creatorStore.profile.followingCount -= 1
        break
      case BlockingTarget.USER:
        if (homeProfile.value) homeProfile.value.followingCount -= 1
        break
      default:
        break
    }
  }

  /**
   * 팔로워 목록 조회
   */
  const fetchMyFollowers = async (
    payload: MyPageType.MyFollowPayload,
    refresh = false
  ) => {
    const _payload: MyPageType.MyFollowPayload = {
      ...payload,
      pageNum: refresh ? 1 : payload.pageNum + 1,
    }

    if (refresh) {
      delete _payload.lastUserCmtyFlwSn
    } else {
      _payload.lastUserCmtyFlwSn =
        myFollowers.value.items[
          myFollowers.value.items.length - 1
        ].userCmtyFlwSn
    }

    const { data, headers } = await MyPageService.fetchMyFollowers(_payload)

    myFollowers.value = {
      items: refresh ? data : myFollowers.value.items.concat(data),
      payload: _payload,
      refresh,
      last: typeof headers['pagination-location'] === 'undefined',
    }
  }

  /**
   * 팔로잉 목록 조회
   */
  const fetchMyFollowings = async (
    payload: MyPageType.MyFollowPayload,
    refresh = false
  ) => {
    const _payload: MyPageType.MyFollowPayload = {
      ...payload,
      pageNum: refresh ? 1 : payload.pageNum + 1,
    }

    if (refresh) {
      delete _payload.lastUserCmtyFlwSn
    } else {
      _payload.lastUserCmtyFlwSn =
        myFollowings.value.items[
          myFollowings.value.items.length - 1
        ].userCmtyFlwSn
    }

    const { data, headers } = await MyPageService.fetchMyFollowings(_payload)

    myFollowings.value = {
      items: refresh ? data : myFollowings.value.items.concat(data),
      payload: _payload,
      refresh,
      last: typeof headers['pagination-location'] === 'undefined',
    }
  }

  /**
   * 사용자 차단 목록 조회
   */
  const fetchMyBlocks = async (
    payload: MyPageType.MyBlockPayload,
    refresh = false
  ) => {
    const _payload: MyPageType.MyBlockPayload = { ...payload }

    if (refresh) {
      delete _payload.lastUserCmtyFlwSn
    } else {
      _payload.lastUserCmtyFlwSn =
        myBlocks.value.items[myBlocks.value.items.length - 1].userSn
    }

    const { data, headers } = await MyPageService.fetchMyBlocks(_payload)

    myBlocks.value = {
      items: refresh ? data : myBlocks.value.items.concat(data),
      payload: { ..._payload, pageNum: refresh ? 1 : _payload.pageNum + 1 },
      refresh,
      last: typeof headers['pagination-location'] === 'undefined',
    }
  }

  /**
   * 팔로워 리스트 초기화
   * @param target
   */
  const clearFollow = (target: 'followers' | 'followings' | 'blocks') => {
    switch (target) {
      case 'followers':
        myFollowers.value = initMyPageState.myFollowers
        break
      case 'followings':
        myFollowings.value = initMyPageState.myFollowings
        break
      case 'blocks':
        myBlocks.value = initMyPageState.myBlocks
        break
      default:
        break
    }
  }

  /**
   * 나의 팔로우, 언팔로우 업데이트
   */
  const updateUpdateFollowUnFollow = (payload: {
    userSn: number
    follow: boolean
  }) => {
    // 1. 사용자 홈 상태 업데이트
    if (homeProfile.value?.userSn === payload.userSn) {
      homeProfile.value.followFlag = payload.follow ? '1' : '0'
    }

    // 2. 나의 팔로워 리스트 상태 업데이트
    myFollowers.value.items.forEach((item) => {
      if (item.userSn === payload.userSn) {
        item.followFlag = payload.follow ? '1' : '0'
      }
    })

    // 3. 나의 팔로잉 리스트 상태 업데이트
    myFollowings.value.items.forEach((item) => {
      if (item.userSn === payload.userSn) {
        item.followFlag = payload.follow ? '1' : '0'
      }
    })
  }

  /**
   * 크리에이터 라이브 상태 업데이트
   */
  const updateLiveStatus = (payload: MyPageType.UpdateLiveStatusPayload) => {
    if (profile.value) {
      profile.value = {
        ...profile.value,
        ...payload,
      }
    }
  }

  /**
   * 크리에이터 채팅 상태 업데이트
   */
  const updateChatStatus = (payload: MyPageType.UpdateChatStatusPayload) => {
    if (profile.value) {
      profile.value = {
        ...profile.value,
        ...payload,
      }
    }
  }

  /**
   * 일반 사용자(일반 사용자 홈) 업데이트
   */
  const updateUserProfile = (payload: MyPageType.HomeProfile) => {
    homeProfile.value = payload
  }

  /**
   * 내활동 카운터 업데이트
   */
  const updateMyActivitiesCount = ({
    target,
    increment,
  }: MyPageType.UpdateMyActivitiesCountPayload) => {
    switch (target) {
      case 'recommend':
        recommendCount.value.recommendPostCount =
          recommendCount.value.recommendPostCount + increment
        break
      case 'comment':
        commentCount.value = commentCount.value + increment
        break
      case 'scrap':
        scrapCount.value = scrapCount.value + increment
        break
      default:
        throw new Error('update my activities count target is not match')
    }
  }

  /**
   * 마이 컬렉션 목록 조회
   */
  const fetchMyCollections = async (
    payload: MyPageType.MyCollectionsPayload,
    refresh = false
  ) => {
    // 중복 호출 막음
    if (myCollections.value.loading) return

    myCollections.value.loading = true

    const _payload = {
      ...payload,
      pageNum: refresh ? 1 : payload.pageNum + 1,
    }
    const { data, headers } = await MyPageService.fetchMyCollections(_payload)
    myCollections.value = {
      items: refresh ? data : myCollections.value.items.concat(data),
      payload: _payload,
      refresh,
      last: typeof headers['pagination-location'] === 'undefined',
      loading: false,
    }

    return data
  }

  /**
   * 마이 컬렉션 > NTF 조회
   */
  const fetchMyCollectionNft = async (
    payload: MyPageType.MyCollectionNftPayload
  ) => {
    const { data } = await MyPageService.fetchMyCollectionNft(payload)
    myCollectionNft.value = data
    return data
  }

  return {
    profile,
    posts,
    postCount,
    recommendPosts,
    recommendCount,
    commentPosts,
    commentCount,
    scrapPosts,
    scrapCount,
    rewards,
    rewardCount,
    showRewards,
    donationQty,
    receivedDonations,
    sentDonations,
    homeProfile,
    premiumCreators,
    premiumSponsors,
    myFollowers,
    myFollowings,
    myBlocks,
    myCollections,
    myCollectionNft,
    applyCreatorStatus,
    fetchProfile,
    updateProfile,
    editProfile,
    createProfilePhoto,
    deleteProfilePhoto,
    fetchPosts,
    fetchPostCount,
    fetchGetRecommendPosts,
    fetchSetRecommendPosts,
    fetchRecommendCount,
    fetchScrapCount,
    fetchScrapPosts,
    fetchCommentPosts,
    fetchCommentCount,
    fetchRewards,
    fetchRewardDetail,
    fetchRewardCount,
    updatePostModel,
    removePost,
    regReferralCode,
    fetchDonationQty,
    fetchReceivedDonations,
    fetchSentDonations,
    fetchHomeProfile,
    regProfileBgImg,
    fetchPremiumCreators,
    fetchSponsorDetail,
    fetchPremiumSponsors,
    fetchPremiumExpiredList,
    deleteMyFollower,
    fetchMyFollowers,
    fetchMyFollowings,
    fetchMyBlocks,
    clearFollow,
    updateUpdateFollowUnFollow,
    updateLiveStatus,
    updateChatStatus,
    updateUserProfile,
    updateMyActivitiesCount,
    fetchMyCollections,
    fetchMyCollectionNft,
  }
})
