<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Pagination } from 'swiper/modules'
import type {
  NovaBoxPostContentsSnapshotImageEmits,
  NovaBoxPostContentsSnapshotImageProps,
} from './NovaBoxPostContentsSnapshotImage.types'
import type { FileImage } from '@store'

const emit = defineEmits<NovaBoxPostContentsSnapshotImageEmits>()
const props = withDefaults(
  defineProps<NovaBoxPostContentsSnapshotImageProps>(),
  {}
)
const { gtEvent } = useGoogleTag()
const { images } = toRefs(props)
const postContentsByCardsRef = ref<Element>()
const currentSwiper = ref<any>(null)
const currentSwiperIndex = ref<number>(0)
const snapshotMode = ref(false)
const filteredImages = computed(
  () => images.value?.filter((item) => item.deleteAt === 'N') || []
)

const handleChangeIndex = (direction: 'left' | 'right') => {
  let newImages = [...filteredImages.value]
  const deletedImages =
    images.value?.filter((item) => item.deleteAt === 'Y') || []
  const fromIdx = currentSwiperIndex.value

  // 위치 변경
  const arrMove = (arr: any, _fromIdx: number, _toIdx: number) => {
    const element = arr[_fromIdx]
    arr.splice(_fromIdx, 1)
    arr.splice(_toIdx, 0, element)
  }

  switch (direction) {
    case 'left':
      arrMove(newImages, fromIdx, fromIdx - 1)
      newImages = reOrder([...newImages, ...deletedImages])
      emit('change:images', newImages)
      currentSwiper.value.slidePrev(0, true)
      break
    case 'right':
      arrMove(newImages, fromIdx, fromIdx + 1)
      newImages = reOrder([...newImages, ...deletedImages])
      emit('change:images', newImages)
      currentSwiper.value.slideNext(0, true)
      break
    default:
      break
  }
}

// 이미지 추가
const handleAdd = async () => {
  const fileImageContainer = await uploadFileImages(true)
  let newImages = props.images
    ? [...fileImageContainer.images, ...props.images]
    : fileImageContainer.images

  newImages = reOrder(newImages)

  emit('change:images', newImages)
  currentSwiper.value.slideTo(0)
}

// 이미지 삭제
const handleDelete = () => {
  const fileName = filteredImages.value[currentSwiperIndex.value].fileName
  let newImages = props.images ? [...props.images] : []
  const targetIdx = newImages.findIndex((item) => item.fileName === fileName)

  if (targetIdx !== -1) {
    newImages[targetIdx].deleteAt = 'Y'
    newImages[targetIdx].expsrOrdr = 0
  }

  newImages = reOrder(newImages)

  emit('change:images', newImages)
}

// 오더 넘버 세팅
const reOrder = (arr: FileImage[]) => {
  const displayImages: FileImage[] = []
  const deletedImages: FileImage[] = []

  arr.forEach((item) => {
    switch (item.deleteAt) {
      case 'N':
        displayImages.push(item)
        break
      case 'Y':
        item.expsrOrdr = 0
        deletedImages.push(item)
        break
      default:
        break
    }
  })

  displayImages.forEach((item, index) => {
    item.expsrOrdr = index + 1
  })

  return [...displayImages, ...deletedImages]
}

const onSlideChange = (swiper: any) => {
  currentSwiper.value = swiper
  currentSwiperIndex.value = swiper.activeIndex
  gtEvent('scrollEvent', {
    eventCategory: '스크롤',
    eventAction: '카드형 이미지 스크롤',
    eventLabel: '',
    eventSlot: '카드형 이미지 스크롤',
    eventI18nAddr: '',
    eventComponent: 'Swiper',
    activeIndex: swiper.activeIndex,
  })
}

const onSwiper = (swiper: any) => {
  currentSwiper.value = swiper
}
</script>

<template>
  <div
    ref="postContentsByCardsRef"
    :class="['post-contents-by-cards', `view-type-${viewType}`, { disabled }]"
  >
    <div
      v-if="!snapshotMode"
      class="pagination-frac"
      :style="editMode ? { bottom: '10px' } : { top: '10px' }"
    >
      {{ currentSwiperIndex + 1 + '/' + filteredImages.length }}
    </div>

    <Swiper
      class="swiper"
      :slides-per-view="1"
      :space-between="0"
      :pagination="true"
      :modules="[Pagination]"
      @swiper="onSwiper"
      @slide-change="onSlideChange"
    >
      <SwiperSlide v-for="item in filteredImages" :key="item.fileName">
        <NovaImageContainer :ratio="'auto'" :image-url="item.s3Url" />
      </SwiperSlide>
    </Swiper>

    <div v-if="!snapshotMode && editMode" class="btn-card-controls">
      <button
        :disabled="currentSwiperIndex === 0"
        class="btn-card-control"
        @click="() => handleChangeIndex('left')"
      >
        <NovaIcon :icon="{ type: 'outline', name: 'chev-left' }" :size="14" />
      </button>

      <button
        :disabled="currentSwiperIndex >= filteredImages.length - 1"
        class="btn-card-control"
        @click="() => handleChangeIndex('right')"
      >
        <NovaIcon :icon="{ type: 'outline', name: 'chev-right' }" :size="14" />
      </button>

      <button :disabled="!editMode" class="btn-card-control" @click="handleAdd">
        <NovaIcon :icon="{ type: 'outline', name: 'add' }" />
      </button>

      <button
        :disabled="filteredImages.length <= 1"
        class="btn-card-control"
        @click="handleDelete"
      >
        <NovaIcon
          :icon="{ type: 'outline', name: 'close-extend' }"
          :size="14"
        />
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@styles/base/post-contents-snapshot';

// 스냅샷 기능으로 인해 전역 인스턴스화: post-contents-snapshot > mixin 스타일 수정할것
// .post-contents-by-cards 클래스명 바꾸지 말것
@include post-contents-by-cards;
</style>
