<script setup lang="ts">
import type {
  NovaInputSimpleEmits,
  NovaInputSimpleProps,
} from './NovaInputSimple.types'

const emit = defineEmits<NovaInputSimpleEmits>()
const props = withDefaults(defineProps<NovaInputSimpleProps>(), {
  fullWidth: false,
  placeholder: '',
  readonly: false,
  disabled: false,
  error: false,
  size: 32,
})

const { modelValue } = toRefs(props)
const value = ref(modelValue.value)

watch(
  () => modelValue.value,
  (cur) => (value.value = cur)
)

watch(
  () => value.value,
  (cur) => emit('update:modelValue', cur)
)
</script>

<template>
  <div
    :class="[
      'input-wrap',
      `size-${size}`,
      { 'full-width': fullWidth },
      { error },
    ]"
  >
    <input
      v-model="value"
      type="text"
      :placeholder="placeholder"
      :readonly="readonly"
      :disabled="disabled"
    />

    <slot name="tools" />
  </div>
</template>

<style lang="scss" scoped>
.input-wrap {
  display: flex;
  align-items: center;
  gap: 12px;
  width: auto;
  max-width: 180px;
  border: 1px solid $color-bg-custom-2;
  border-radius: 5px;
  transition: all 0.2s;
  color: $color-text-3;

  &:active,
  &:focus-within {
    color: $color-black-90p;
    border-color: $color-primary-blue;
  }

  &.error {
    border-color: $color-primary-red;
  }

  &.full-width {
    width: 100%;
    max-width: 100%;
  }

  &.size {
    &-32 {
      height: 32px;
      padding: 0 6px;

      > input {
        @include text-style($text-body-12-regular);
      }
    }

    &-40 {
      height: 40px;
      padding: 0 10px;
    }

    > input {
      @include text-style($text-body-14-regular);
    }
  }

  > input {
    width: 100%;

    &::placeholder {
      color: $color-text-3;
    }
  }
}
</style>
