import { DAY } from '@configs'

interface LocalStorageItem<Data> {
  data: Data
  expiry: number | null
}

export const useLocalStorage = () => {
  const localStorageItems = {
    PRIVATE_KEY: {
      key: 'bc/privateKey-{userSn}',
      expireDays: 30,
    },
    FCM_TOKEN_STATUS_KEY: {
      key: 'fcmTokenStatus',
      expireDays: null,
    },
  }

  /**
   * 로컬 스토리지: 저장하기
   * @param key
   * @param data
   * @param expireDays
   */
  const set = <Key extends string, Data = any>(
    key: Key,
    data: Data,
    expireDays: LocalStorageItem<Data>['expiry'] = null
  ): Promise<true> => {
    return new Promise<true>((resolve) => {
      console.log(key)
      console.log(data)
      console.log(expireDays)

      const item: LocalStorageItem<Data> = {
        data,
        expiry: expireDays ? Date.now() + DAY * expireDays : null,
      }

      localStorage.setItem(key, JSON.stringify(item))

      resolve(true)
    })
  }

  /**
   * 로컬 스토리지: 가져오기
   * @param key
   */
  const get = <Key extends string, Data = any>(key: Key): Promise<Data> => {
    return new Promise<Data>((resolve, reject) => {
      const str = localStorage.getItem(key)

      if (!str) {
        reject(new Error(`${key} is not found in localStorage`))
        return
      }

      const item: LocalStorageItem<Data> = JSON.parse(str)
      const now = Date.now()

      if (typeof item.expiry === 'number' && now > item.expiry) {
        localStorage.removeItem(key)
        reject(new Error(`${key} is expired`))
        return
      }

      resolve(item.data)
    })
  }

  /**
   * 로컬 스토리지: 삭제하기
   */
  const remove = <Key extends string>(key: Key): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      const str = localStorage.getItem(key)

      if (!str) {
        reject(new Error(`${key} is not found in localStorage`))
        return
      }

      localStorage.removeItem(key)
      resolve(true)
    })
  }

  return { localStorageItems, set, get, remove }
}
