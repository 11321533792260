<script setup lang="ts">
import { v4 as uuid } from 'uuid'
import type { NovaBoxPostContentsSnapshotProps } from './NovaBoxPostContentsSnapshot.types'

const props = withDefaults(defineProps<NovaBoxPostContentsSnapshotProps>(), {
  editorContents: '',
  isResizingImages: false,
})

const postContentsSnapshotRef = ref<Element>()
const calcBiggerInterval = ref<ReturnType<typeof setInterval>>()
const maxHeight = 100
const isBigger = ref(false)

// set card image
// const cardImages = computed(() =>
//   (props.imageOrVideoList?.images || []).map((image) => image.s3Url)
// )

onMounted(() => {
  calcBigger()
})

onBeforeUnmount(() => {
  clearInterval(calcBiggerInterval.value)
})
// calc bigger inner contents then snapshot
const calcBigger = () => {
  if (postContentsSnapshotRef.value) {
    calcBiggerInterval.value = setInterval(() => {
      setBigger()
    }, 100)

    const setBigger = () => {
      if (
        props.viewType === 'list' &&
        postContentsSnapshotRef.value!.clientHeight >= maxHeight
      ) {
        isBigger.value = true
        clearInterval(calcBiggerInterval.value)
      }
    }
  }
}
</script>

<template>
  <div
    ref="postContentsSnapshotRef"
    :class="[
      'post-contents-snapshot',
      { 'show-guard': isBigger },
      { 'limit-height': viewType === 'list' },
    ]"
  >
    <NovaBoxPostContentsSnapshotImage
      v-if="nttTyCode === 'CARD' && imageOrVideoList?.images?.length"
      :images="imageOrVideoList?.images"
      :view-type="viewType"
    />

    <NovaBoxPostContentsSnapshotVideo
      v-if="nttTyCode === 'CVOD' && imageOrVideoList?.video?.length"
      :videos="imageOrVideoList?.video || []"
      :view-type="viewType"
    />

    <NovaBoxPostContentsSnapshotContents
      :key="`${editorContents}-${uuid()}`"
      :contents="editorContents"
      :view-type="viewType"
      :is-resizing-images="isResizingImages"
    />
  </div>
</template>

<style lang="scss" scoped>
@import '@styles/base/post-contents-snapshot';

// 스냅샷 기능으로 인해 전역 인스턴스화: post-contents-snapshot > mixin 스타일 수정할것
// .post-contents-snapshot 클래스명 바꾸지 말것
@include post-contents-snapshot;
</style>
